
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, ReceiptCutoff, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Tree, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import NationalOceanicAndAtmosphericAdministration from './NationalOceanicAndAtmosphericAdministration';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import NationalScienceFoundation from './NationalScienceFoundation';
import HouseCommitteeOnTransportationAndInfrastructure from './HouseCommitteeOnTransportationAndInfrastructure';
import { createElectionAssistancCommissioneNews, createFederalElectionCommissionNews, CreateFeedAPI, CreateFeedAPIElection, CreateFeedElections, CreateFeedItem, CreateFeedItemStateRepsAll, Speak } from './Functions';
import DesktopNavbar from './DesktopNavbar';
import FederalElectionCommissionNews from './FederalElectionCommissionNews';
import ElectionAssistanceComissionNews from './ElectionAssistanceComissionNews';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import UpcomingElections from './UpcomingElections';
import GenerateFeed from './GenerateFeed';
import GenerateFeedStateRepsAll from './GenerateFeedStateRepsAll';
import GenerateFeedElection from './GenerateFeedElection';

const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;


 



//https://evening-plains-21303.herokuapp.com/
function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <Card>{props.value}</Card>;
}
function createFullBillFeed() {

  function NumberList(props) {
    const numbers = props.numbers;
    const listItems = numbers.map((number) =>
      // Correct! Key should be specified inside the array.
      <ListItem key={number.toString()} value={number} />
    );
    return (

      <Container><Card>{listItems}</Card></Container>

    );
  }
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];


  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/bills.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const linkChunk = links.toString().substring(36);
      //console.log(billTitle[99])
      const viewBillLink = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
      //console.log(viewBillLink)


      ReactDOM.render(
        <NumberList numbers={billTitle} />,
        document.getElementById('root')
      );

      //render(<BillFeed />);



    }













    //inner html <br>





  })();
}










function saveToCollection(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionNote) {
  collectionCounter = localStorage.getItem('collectionNumber')
  collectionCounter++;

  var collectionTitles = []
  var collectionDetailLinks = []
  var collectionDocLinks = []
  var collectionNote = []
  //collectionTitles.push(saveCollectionTitle)
  //collectionDetailLinks.push(saveCollectionDetailLink)
  //collectionDocLinks.push(saveCollectionDocLink)








  var title = 'title'.concat(collectionCounter)
  var detailLink = 'detailLink'.concat(collectionCounter)
  var docLink = 'docLink'.concat(collectionCounter)
  var itemNote = 'itemNote'.concat(collectionCounter)

  localStorage.setItem(title, saveCollectionTitle)

  localStorage.setItem(detailLink, saveCollectionDetailLink)

  localStorage.setItem(docLink, saveCollectionDocLink)

  localStorage.setItem('collectionNumber', collectionCounter)
  localStorage.setItem(itemNote,saveCollectionNote)


  return collectionCounter;



}

function createUSCourtNewsFeed() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []

  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.uscourts.gov/news/rss');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      feedContent.push(item.content);
      feed.item = item.title + ':' + item.link;
      //console.log(item.content)

    });


    for (let index = billsFeed.length; index > -1; index--) {
      //console.log(index)
      const titles = billTitle[index];
      const links = billLink[index];
      const content = feedContent[index];


      const titles2 = billTitle[index + 1];
      const links2 = billLink[index + 1];
      const content2 = feedContent[index + 1];
      //const linkChunk = links.toString().substring(36);

      const viewBillLink = "https://www.uscourts.gov/news/rss"





      //render(<BillFeed />);

      ReactDOM.render(
        < div id='legislativeBills' >
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>

            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(titles, billLink[index], null, null)




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div >, document.getElementById('courtNews'));

      ReactDOM.render(
        < div id='legislativeBills' >
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles2}</Card.Title>

            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index + 1], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(titles, billLink[index - 1], null, null)




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div >, document.getElementById('courtNews2'));
    }

  })();







}


function createCourtAppeals() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []

  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/uscourts-ca13.xml');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      feedContent.push(item.content);
      feed.item = item.title + ':' + item.link;
      //console.log(item.content)

    });


    for (let index = billsFeed.length; index > -1; index--) {
      //console.log(index)
      const titles = billTitle[index];
      const titles2 = billTitle[index + 1];
      const links = billLink[index];
      const content = feedContent[index];
      //const linkChunk = links.toString().substring(36);

      const viewBillLink = "https://www.uscourts.gov/news/rss"



      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>

            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='viewBillButton' onClick={(e) => {

              e.preventDefault();
              window.open(
                "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), "_blank")
            }}><FileText></FileText> Document</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(titles, billLink[index], null, null)




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('courtAppeals')



      )



      //render(<BillFeed />);


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles2}</Card.Title>

            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index + 1], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='viewBillButton' onClick={(e) => {

              e.preventDefault();
              window.open(
                "https://www.govinfo.gov/content/pkg/".concat(billLink[index + 1].toString().substring(36)).concat("/pdf/").concat(billLink[index + 1].toString().substring(36)).concat("-0.pdf"), "_blank")
            }}><FileText></FileText> Document</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(titles, billLink[index], null, null)




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('courtAppeals2')



      )



    }

  })();





}

//Bills Presented to President
//Currently dorment
function createBillsPresentedFeed() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = [];

  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.congress.gov/rss/presented-to-president.xml');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      feedContent.push(item.content);
      //console.log(billTitle)
      feed.item = item.title + ':' + item.link;

    });


    for (let index = 0; index < billTitle.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const content = feedContent[index];

      const linkChunk = links.toString().substring(36);

      const viewBillLink = "https://www.congress.gov/rss/presented-to-president.xml"



      const BillFeed = () => (
        <div id='legislativeBills'>
          <Container>

            <Card.Title id='billText'>{titles}</Card.Title>
            <h6>{content}</h6>
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.location.href = billLink[index]
            }}><InfoCircle></InfoCircle> Details</Button>


          </Container>

          <br></br>

        </div>


      );
      ReactDOM.render(<BillFeed />, document.getElementById('presented'));

      //render(<BillFeed />);



    }

  })();





}

function createWhiteHouseDocuments() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const pubDate = []

  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/dcpd.xml');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate)
      feedContent.push(item.summary);

      feed.item = item.title + ':' + item.link;

    });


    for (let index = 0; index < billTitle.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const links2 = billLink[index - 1];
      const content = feedContent[index];
      const date = pubDate[index];


      const linkChunk = links.toString().substring(36);



      const viewDocLink = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf")



      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <h6>{content}</h6>
            <h6>{(date.toString().substring(0, 17))}</h6>
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='viewBillButton' onClick={(e) => {
              e.preventDefault();
              window.open(viewDocLink, "_blank")
            }}><FileText></FileText> Document</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(titles, billLink[index], viewDocLink, null)




            }><FolderPlus></FolderPlus></Button>


          </Container>

          <br></br>

        </div>, document.getElementById('whdocs'));


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{billTitle[index - 1]}</Card.Title>
            <h6>{content}</h6>
            <h6>{(date.toString().substring(0, 17))}</h6>
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index - 1], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='viewBillButton' onClick={(e) => {
              
              e.preventDefault();
              window.open("https://www.govinfo.gov/content/pkg/".concat(billLink[index - 1].toString().substring(36)).concat("/pdf/").concat(billLink[index - 1].toString().substring(36)).concat(".pdf"), "_blank")
            }}><FileText></FileText> Document</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(titles, billLink[index - 1], viewDocLink, null)




            }><FolderPlus></FolderPlus></Button>


          </Container>

          <br></br>

        </div>, document.getElementById('whdocs2'));

      //render(<BillFeed />);



    }

  })();


  function getLocalStorage() {






  }


}

//White House Feed
function createWhiteHouseFeed() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = [];
  const pubDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.whitehouse.gov/feed/');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      feedContent.push(item.content.replace('&#8230;', '...'));
      feed.item = item.title + ':' + item.link;

    });


    for (let index = 0; index < billTitle.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const content = feedContent[index]
      const linkChunk = links.toString().substring(36);
      const date = pubDate[index].toString().substring(0, 25)

      const viewBillLink = "https://www.govinfo.gov/rss/dcpd.xml"

      const BillFeed = () => (
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <h6>{content}</h6>
            <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blanl")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(titles, billLink[index], null, null)




            }><FolderPlus></FolderPlus></Button>


          </Container>

          <br></br>

        </div>


      );
      ReactDOM.render(<BillFeed />, document.getElementById('wh'));

      //render(<BillFeed />);



    }

  })();





}

function createWhiteHouseFeedMobile() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = [];
  const pubDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.whitehouse.gov/feed/');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      feedContent.push(item.content.replace('&#8230;', '...'));
      feed.item = item.title + ':' + item.link;

    });


    for (let index = 0; index < billTitle.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const content = feedContent[index]
      const linkChunk = links.toString().substring(36);
      const date = pubDate[index].toString().substring(0, 25)

      const viewBillLink = "https://www.govinfo.gov/rss/dcpd.xml"

      const BillFeed = () => (
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>

            <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blanl")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(titles, billLink[index], null, null)




            }><FolderPlus></FolderPlus></Button>


          </Container>

          <br></br>

        </div>


      );
      ReactDOM.render(<BillFeed />, document.getElementById('wh'));



      //render(<BillFeed />);



    }

  })();





}
//Legislative Bills
function createBillFeed() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];

  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/bills.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billTitle.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];

      const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      const viewBillLink = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
      //console.log(viewBillLink)
      const BillFeed = () => (
        <div id='legislativeBills'>
          <Container>

            <Card.Title id='billText'>{titles}</Card.Title>
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>
            <Button id='viewBillButton' onClick={(e) => {
              e.preventDefault();
              window.open(viewBillLink, "_blank")
            }}><FileText></FileText> View Bill</Button>

          </Container>

          <br></br>

        </div>


      );
      ReactDOM.render(<BillFeed />, document.getElementById('bills'));

      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createBillFeedTwo() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/bills.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[index]}</Card.Title><Card.Body id='billBodyText'>{pubDate[0]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
        e.preventDefault();
        window.open(viewBillLink[index], "_blank")
      }}><FileText></FileText> View Bill</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('bills'));





      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[index - 2]}</Card.Title><Card.Body id='billBodyText'>{pubDate[1]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index - 2], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
        e.preventDefault();
        window.open(viewBillLink[index - 2], "_blank")
      }}><FileText></FileText> View Bill</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index - 2], billLink[index - 2], viewBillLink[index - 2], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('bills2'));














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createReports() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/crpt.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[index]}</Card.Title><Card.Body id='billBodyText'>{pubDate[0]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
        e.preventDefault();
        
        window.open(viewBillLink[index], "_blank")
      }}><FileText></FileText> View Report</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('reports'));





      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[index - 2]}</Card.Title><Card.Body id='billBodyText'>{pubDate[1]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index - 2], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
        e.preventDefault();
        window.open(viewBillLink[index - 2], "_blank")
      }}><FileText></FileText> View Report</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index - 2], billLink[index - 2], viewBillLink[index - 2], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('reports2'));














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createHouseCommitteeOnAgriculture() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=AG00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], viewBillLink[0], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseComitteeOnAgriculture'));





 














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createHouseCommitteeOnTransportationAndInfrastructure() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=PW00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], viewBillLink[0], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnTransportationAndInfrastructure'));




















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createHouseCommitteeOnAppropriations() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=AP00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[1]}</Card.Title><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[1], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[1], billLink[1], viewBillLink[1], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnAppropriations'));





      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[2]}</Card.Title><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[2], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[2], billLink[2], viewBillLink[2], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnAppropriations2'));














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}


function createGAOReports() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.gao.gov/rss/reports.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(29)
      viewBillLink.push("https://www.gao.gov/assets/".concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[index]}</Card.Title><Card.Body id='billBodyText'>{pubDate[0]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
        e.preventDefault();
        console.log(viewBillLink[index])
        window.open(viewBillLink[index], "_blank")
      }}><FileText></FileText> View Report</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('gaoReports'));





      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[index - 2]}</Card.Title><Card.Body id='billBodyText'>{pubDate[1]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index - 2], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
        e.preventDefault();
       // console.log(viewBillLink[index - 2])
        window.open(viewBillLink[index - 2], "_blank")
      }}><FileText></FileText> View Report</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index - 2], billLink[index - 2], viewBillLink[index - 2], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('gaoReports2'));














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}


function createNewLaws() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/plaw.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/uslm/").concat(linkChunk).concat(".xml"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[index].substring(0, 150) + "..."}</Card.Title><Card.Body id='billBodyText'>{pubDate[0]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
        e.preventDefault();
        window.open(viewBillLink[index], "_blank")
      }}><FileText></FileText> View Law</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('newLaws'));





      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[98].substring(0, 150) + "..."}</Card.Title><Card.Body id='billBodyText'>{pubDate[1]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index - 1], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
        e.preventDefault();
        window.open(viewBillLink[index - 1], "_blank")
      }}><FileText></FileText> View Bill</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index - 1], billLink[index - 1], viewBillLink[index - 1], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('newLaws2'));














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}


function createBillsEnrolled() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/bills-enr.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(< Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[index]}</Card.Title><Card.Body id='billBodyText'>{pubDate[0]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
        e.preventDefault();
        window.open(viewBillLink[index], "_blank")
      }}><FileText></FileText> View Bill</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('billsEnrolled'));





      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[index - 1]}</Card.Title><Card.Body id='billBodyText'>{pubDate[1]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index - 1], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
        e.preventDefault();
        window.open(viewBillLink[index - 1], "_blank")
      }}><FileText></FileText> View Bill</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index - 1], billLink[index - 1], viewBillLink[index - 1], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('billsEnrolled2'));














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}


function createDepartmentOfState() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.state.gov/rss-feed/press-releases/feed/');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title><Card.Body id='billBodyText'>{pubDate[0]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('stateDepartment'));





      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index - 1]}</Card.Title><Card.Body id='billBodyText'>{pubDate[1]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index - 1], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index - 1], billLink[index - 1], viewBillLink[index - 1], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('stateDepartment2'));














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createDepartmentOfTreasury() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://home.treasury.gov/system/files/126/ofac.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title><Card.Body id='billBodyText'>{pubDate[0]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('treasuryDepartment'));





      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index - 1]}</Card.Title><Card.Body id='billBodyText'>{pubDate[1]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index - 1], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index - 1], billLink[index - 1], viewBillLink[index - 1], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('treasuryDepartment2'));














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createDepartmentOfDefense() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.defense.gov/DesktopModules/ArticleCS/RSS.ashx?max=10&ContentType=1&Site=945');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title><Card.Body id='billBodyText'>{pubDate[0]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('defenseDepartment'));





      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index - 1]}</Card.Title><Card.Body id='billBodyText'>{pubDate[1]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index - 1], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index - 1], billLink[index - 1], viewBillLink[index - 1], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('defenseDepartment2'));














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createDepartmentOfJustice() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.justice.gov/feeds/opa/justice-news.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title><Card.Body id='billBodyText'>{pubDate[0]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('justiceDepartment'));





      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index - 1]}</Card.Title><Card.Body id='billBodyText'>{pubDate[1]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index - 1], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index - 1], billLink[index - 1], viewBillLink[index - 1], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('justiceDepartment2'));














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}


function createDepartmentOfTheInterior() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.doi.gov/feeds/list/22038/rss.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('theInteriorDepartment'));





   














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createDepartmentOfAgriculture() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.usda.gov/rss/home.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('agricultureDepartment'));





      














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createDepartmentOfCommerce() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.commerce.gov/feeds/news');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title><Card.Body id='billBodyText'>{pubDate[0]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('commerceDepartment'));





      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index - 1]}</Card.Title><Card.Body id='billBodyText'>{pubDate[1]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index - 1], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index - 1], billLink[index - 1], viewBillLink[index - 1], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('commerceDepartment2'));














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createDepartmentOfLabor() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.dol.gov/rss/releases.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('laborDepartment'));





      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index - 1]}</Card.Title><Card.Body id='billBodyText'>{pubDate[1]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index - 1], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index - 1], billLink[index - 1], viewBillLink[index - 1], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('laborDepartment2'));














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createDepartmentOfHealthAndHumanServices() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/health-xml/health_and_human_services.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('healthAndHumanServicesDepartment'));





      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index - 1]}</Card.Title><Card.Body id='billBodyText'>{pubDate[1]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index - 1], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index - 1], billLink[index - 1], viewBillLink[index - 1], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('healthAndHumanServicesDepartment2'));














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createDepartmentOfHousingAndUrbanDevelopment() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.hud.gov/sites/dfiles/Main/documents/hudrss.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('housingAndUrbanDevelopmentDepartment'));





    














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createDepartmentOfEducation() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.ed.gov/feed');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('educationDepartment'));





      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index - 1]}</Card.Title><Card.Body id='billBodyText'>{pubDate[1]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index - 1], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index - 1], billLink[index - 1], viewBillLink[index - 1], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('educationDepartment2'));














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createDepartmentOfVeteransAffairs() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.va.gov/oig/rss/press-releases.asp');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('veteransAffairsDepartment'));





      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index - 1]}</Card.Title><Card.Body id='billBodyText'>{pubDate[1]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index - 1], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index - 1], billLink[index - 1], viewBillLink[index - 1], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('veteransAffairsDepartment2'));














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createDepartmentOfHomelandSecurity() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.dhs.gov/news_and_updates/rss.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]





      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('homelandSecurityDepartment'));





      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index - 1]}</Card.Title><Card.Body id='billBodyText'>{pubDate[1]}</Card.Body><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index - 1], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index - 1], billLink[index - 1], viewBillLink[index - 1], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('homelandSecurityDepartment2'));














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}


function createEnvironmentalProtectionAgency() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.epa.gov/newsreleases/search/rss');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = billsFeed.length; index >= 0; index--) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]


      

      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('epa'));



















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createEnvironmentalProtectionAgencyMobile() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.epa.gov/newsreleases/search/rss');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = billsFeed.length; index >= 0; index--) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]


      

      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('epaMobile'));





      














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createNationalOceanicAndAtmosphericAdministration(){

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.climate.gov/feeds/news-features/casestudies.rss');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
      
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('noaa'));





    














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createNationalScienceFoundation() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.nsf.gov/rss/rss_www_discoveries.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('nsf'));





      
















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createNationWeatherServices(){

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.weather.gov/rss_page.php?site_name=nws');






    feed.items.forEach(item => {

      



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>

        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open('https://www.wpc.ncep.noaa.gov/discussions/hpcdiscussions.php?disc=pmdspd&version=0&fmt=reg', "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], 'https://www.wpc.ncep.noaa.gov/discussions/hpcdiscussions.php?disc=pmdspd&version=0&fmt=reg', null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('nws'));





      













      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createNationalOceanicAndAtmosphericAdministrationOSN() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://oceanservice.noaa.gov/rss/nosnews.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title>

        <Card.Body id='billBodyText'></Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('noaaOSN'));





      














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}



function createNationalAeronauticsAndSpaceAdministration() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.nasa.gov/rss/dyn/breaking_news.rss');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('nasa'));





  














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

function createTrendingBills() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.congress.gov/rss/most-viewed-bills.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title>
      
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('trendingBills'));




















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}


function createSenateFloorToday() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.congress.gov/rss/senate-floor-today.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      //console.log(billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container><Card.Title id='billText'>{billTitle[index]}</Card.Title><Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('senateFloor'));




















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}



function billFeedModal() {



  //console.log(FullBillFeed())
  render(
    <Modal show='true'></Modal>
  )
}

function congressSearch() {

  var searchItem = document.getElementById('congressSearch').value.replace(" ", "%20")


  var searchURL = "https://www.congress.gov/search?q=%7B%22congress%22%3A%5B%22117%22%5D%2C%22source%22%3A%22all%22%2C%22search%22%3A%22".concat(searchItem).concat("%22%7D")
  window.open(searchURL, "_blank")




}

function whiteHouseSearch() {

  var searchItem = document.getElementById('whiteHouseSearch').value.replace(" ", "+")


  var searchURL = "https://www.whitehouse.gov/?s=".concat(searchItem)
  window.open(searchURL, "_blank")




}

function supremeCourtSearch() {

  var searchItem = document.getElementById('supremeCourtSearch').value.replace(" ", "+")


  var searchURL = "https://www.supremecourt.gov/search.aspx?Search=".concat(searchItem).concat("&type=Site")
  window.open(searchURL, "_blank")




}

function createHouseCommitteeOnNaturalResources() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=II00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], viewBillLink[0], null)




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnNaturalResources'));



   
















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}










export const Election = () =>
 {
   render()
   
   {
  const [show, setShow] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);

  const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)

  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)

  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)


  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [noaaModalIsOpen, setNOAAModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
  const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
  const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
  const [houseCommitteeOnTransportationAndInfrastructureModalIsOpen, setHouseCommitteeOnTransportationAndInfrastructureModalIsOpen] = useState(false)


  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)



  const [fecModalIsOpen, setFECModalIsOpen] = useState(false)
  const [eacModalIsOpen, setEACModalIsOpen] = useState(false)


  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };

  return(
    <>
      <BrowserView>
        <div id='homePage'>


        <div id='homebg'>

        <DesktopNavbar/>          
               <div id='sectionHeading'>      
            
              <h3 id='branchHeading' ><ReceiptCutoff> </ReceiptCutoff> <b> Elections</b></h3> 

       
              <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />

<Button id='navbarAboutButtonDesktop' variant='primary' onClick={(e) => {
                e.preventDefault();
                window.open('https://www.archives.gov/electoral-college/about', "_blank")
              }} >Electoral College</Button>
{/*
              <Button size='md' id='whiteHouseButton' variant='primary' onClick={(e) => {
                e.preventDefault();
                window.open('https://www.fec.gov ', "_blank")
              }} >Federal Election Commission</Button>

              <Button size='md' id='usCourtsButton' variant='primary' onClick={(e) => {
                e.preventDefault();
                window.open('https://www.eac.gov', "_blank")
              }} >Election Assistance Commission</Button>

<Button size='md' id='usCourtsButton' variant='primary' onClick={(e) => {
                e.preventDefault();
                window.open('https://www.archives.gov/electoral-college/about', "_blank")
              }} >Electoral College</Button>
            */}             
             
              </div> 
         
                     
            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>

                  <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                      <h4 id='departmentOfDefenseHeadingInCol'onClick={(e) => {
              e.preventDefault();
              window.open('/us/election2024', "_self")}}><b>🇺🇸 2024 General Election</b><ChevronRight id='clickChevron'></ChevronRight></h4>

                       <Carousel id='carouselInColumn' controls={false}>

                       <Carousel.Item interval={carouselInterval}>




<h4 id='electionsHeading' onClick={() =>
       openModal(` 2024 U.S. House Candidates`,
       <GenerateFeedStateRepsAll
         stateAbbreviation=''
         candidateType='House' />
     )}><b>2024 U.S. House Candidates</b></h4>

<div  id='house'></div>

{CreateFeedItemStateRepsAll('house', 'House', '', 0)}





</Carousel.Item>

                       <Carousel.Item interval={carouselInterval}>




                       <h4 id='electionsHeading' onClick={() =>
                              openModal(` 2024 Senate Candidates`,
                              <GenerateFeedStateRepsAll
                                stateAbbreviation=''
                                candidateType='Senate' />
                            )}><b>2024 U.S. Senate Candidates</b></h4>

<div  id='senate'></div>

{CreateFeedItemStateRepsAll('senate', 'Senate', '', 0)}
  




</Carousel.Item>

<Carousel.Item interval={carouselInterval}>




<h4 id='electionsHeading' onClick={() =>
       openModal(` 2024 President Candidates`,
       <GenerateFeedStateRepsAll
         stateAbbreviation=''
         candidateType='President' />
     )}><b>2024 President Candidates</b></h4>

<div  id='president'></div>

{CreateFeedItemStateRepsAll('president', 'President', '', 0)}





</Carousel.Item>


                       <Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/election2024' >View all 2024 General Election news</Button>
</div>
</Col>

</div>



</Carousel.Item>

                       </Carousel>
                       
                         
                      </div>
                    </Col>
                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                        <h4 id='departmentOfJusticeHeading' onClick={() => setBillsEnrolledModalIsOpen(true)}><b>Election Results</b></h4>

                        <div id='fedElections'></div>

{CreateFeedAPIElection('&skip=0&schema=united_states_of_america&table=federal_election_results&order_by=year', 'fedElections', 
                            'Election Results', 'N/A', 'Election', GenerateFeedElection)}

<Modal id='departmentOfStateModal' show={billsEnrolledModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillsEnrolledModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Election Results</h3></Modal.Header>
  <GenerateFeedElection url='&skip=0&schema=united_states_of_america&table=federal_election_results&order_by=year' 
                            name='Election Results' branch='N/A' topic='Elections' buttonName=''  />
</Modal>



                

                        <Modal id='departmentOfStateModal' show={fecModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFECModalIsOpen(false)}>
                          <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Federal Election Commission</h3></Modal.Header>
                          <FederalElectionCommissionNews />
                        </Modal>
                       
                         
                      </div>
                    </Col>


                





                  </Row>
                </Container>
              </Container>
            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
                  <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                        <h4 id='departmentOfJusticeHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/eac', "_self")}}><b>Election Assistance Commission<ChevronRight id='clickChevron'></ChevronRight></b></h4>

                

<div  id='bills'></div> 
              {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=election_assistance_commission&order_by=created_at', 'bills', 
                            'Election Assistance Commission', 'Legislative', 
                            'N/A', 'Doc', GenerateFeed)}
                        <Modal id='departmentOfStateModal' show={eacModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setEACModalIsOpen(false)}>
                          <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Election Assistance Commission</h3></Modal.Header>
                          <GenerateFeed
                                url='&skip=0&schema=united_states_of_america&table=election_assistance_commission&order_by=created_at'
                                name='Election Assistance Commission News' branch='N/A' topic='Election' buttonName='Doc' />
                        </Modal>



                      </div>
                    </Col>


                    <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                      <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/fec', "_self")}}><b>Federal Election Commission</b><ChevronRight id='clickChevron'></ChevronRight></h4>

                        <div id='fec'></div>
                    
                        
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=federal_election_commission&order_by=created_at', 'fec',
                            'Federal Election Commission News', 'N/A',
                            'Elections', 'Doc', GenerateFeed)}


                

                        <Modal id='departmentOfStateModal' show={fecModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFECModalIsOpen(false)}>
                          <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Federal Election Commission</h3></Modal.Header>
                          <GenerateFeed url='&skip=0&schema=united_states_of_america&table=federal_election_commission&order_by=created_at'
                                name='Federal Election Commission News' branch='N/A' topic='Election' buttonName='Doc' />
                        </Modal>
                       
                         
                      </div>
                    </Col>





                  </Row>
                </Container>
              </Container>
            </div>
         
            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
   <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>

            




</div>
         </div>
          

              </BrowserView>

              <MobileView>

              <MobileTopNavbar/>


        <div id='homebg'>
         
          
        
            <h5 id='branchHeadingMobile' ><ReceiptCutoff id='electionIconPadding'></ReceiptCutoff>   Elections</h5>

<Col>

            <h5 id='branchHeadingMobile' onClick={() => window.open('/us/election2024', "_self")}> 🇺🇸 2024 General Election<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>
<Carousel.Item id='legislativeCarousel' interval={carouselInterval} >


<Col >
  <div id='columnLefttPadding'>
   
    <div id='president'></div>
    {CreateFeedItemStateRepsAll('president', 'President', '', 0)}

  </div>
</Col>

</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>
    <div id='judicalConMobile'>
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/election2024' >View all 🇺🇸 2024 General Election news</Button>
</div>
  </Col>
      </div>
      </Carousel.Item>

      </Carousel>
      </Col>
              <Col>

<h5 id='presidentialFeedHeadingMobile' onClick={(e) => {
              e.preventDefault();
              window.open('/us/fec', "_self")}}><b>Federal Election Commission</b><ChevronRight id='clickChevron'></ChevronRight></h5>


                

                  <div class='courtNewsMobile' id='fec'></div>

                  {CreateFeedItem('http://fetchrss.com/rss/5f8e367522b58768783d2392636be5a56ae84d1bcf0121d2.xml', 'fec', 'Federal Election Commission', 'N/A', 'Election', FederalElectionCommissionNews)}

<Modal id='whiteHouseDocsModal' show={fecModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFECModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Election Commission</h3></Modal.Header>
  <GenerateFeed url='&skip=0&schema=united_states_of_america&table=federal_election_commission&order_by=created_at'
                                name='Federal Election Commission News' branch='N/A' topic='Election' buttonName='Doc' />
</Modal>
                </Col>


               



            
    
           
           
         
              <Col>


              <h5 id='presidentialFeedHeadingMobile'  onClick={(e) => {
              e.preventDefault();
              window.open('/us/eac', "_self")}}>Election Assistance Commission<ChevronRight id='clickChevron'></ChevronRight></h5>


                

                  <div class='courtNewsMobile' id='eac'></div>

                  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=election_assistance_commission&order_by=created_at', 'eac', 
                            'Election Assistance Commission', 'N/A', 'Election', GenerateFeed)}

<Modal id='departmentOfStateModal' show={eacModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setEACModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Election Assistance Commission</h3></Modal.Header>
  <GenerateFeed url='&skip=0&schema=united_states_of_america&table=election_assistance_commission&order_by=created_at' 
                            name='Election Assistance Commission' branch='N/A' topic='Elections' buttonName=''  />
</Modal>
                </Col>




             
                <Col>


<h5 id='presidentialFeedHeadingMobile'onClick={() => setBillsEnrolledModalIsOpen(true)}>Election Results</h5>


<div class='courtNewsMobile' id='fedElections'></div>

{CreateFeedAPIElection('&skip=0&schema=united_states_of_america&table=federal_election_results&order_by=year', 'fedElections', 
                            'Election Results', 'N/A', 'Election', GenerateFeedElection)}

<Modal id='departmentOfStateModal' show={billsEnrolledModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillsEnrolledModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Election Results</h3></Modal.Header>
  <GenerateFeedElection url='&skip=0&schema=united_states_of_america&table=federal_election_results&order_by=year' 
                            name='Election Results' branch='N/A' topic='Elections' buttonName=''  />
</Modal>
  </Col>





            <Col>

            </Col>

          
         <div id='mobileNavbarSpacer'></div>
            <Navbar id='navbarMobileBottom' fixed="bottom">  

          
 


<div id='navbarSpacer'></div><div id='navbarSpacer'></div>




       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
              
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
          </div>

              </MobileView>
              
    </>
  )
}
 }



  






export default Election;