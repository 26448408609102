function ItemDetailPage({ title, link, date, description }) {
    console.log(title)
    return (
      <div>
        <h2>{title}</h2>
        <p>Date: {date}</p>
        <p>Description: {description}</p>
        <a href={link} target="_blank" rel="noopener noreferrer">Details</a>
      </div>
    );
  }

  export default ItemDetailPage;