import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, Link45deg } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';

import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';


import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
    createWhiteHouseFeedMobile,createDepartmentOfState,
    createDepartmentOfDefense, 
    createDepartmentOfJustice, createDepartmentOfTheInterior,
    createDepartmentOfAgriculture, createDepartmentOfCommerce,
    createDepartmentOfCommerceMobile, createDepartmentOfLabor,
    createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
    ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
    , createEnvironmentalProtectionAgency,
    createDepartmentOfTreasury, createNationalScienceFoundation, 
     createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, 
     createFederalRegisterStateDepartment, createFederalRegisterDefense,
    CreateFeedItem, CreateFeedItemPresidentialDocuments, CreateExecutiveOrders,
    CreateFeedItemExecutiveOrders,
    CreateFeedItemBillUpdates,
    CreateFeedItemExecutiveOffice,
    CreateFeedItemBillWithDescription,
    CreateFeedAPIDepartmentUS,  } from './Functions'
import NationalScienceFoundation from './NationalScienceFoundation';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';

import DepartmentOfHealthAndHumanServicesDocs from './DepartmentOfHealthAndHumanServicesDocs';
import AgencyForHealthcareResearchAndQuality from './AgencyForHealthcareResearchAndQuality';
import AgencyForToxicSubstancesAndDiseaseRegistry from './AgencyForToxicSubstancesAndDiseaseRegistry';
import AgingAdministration from './AgingAdministration';
import CentersForDiseaseControlAndPrevention from './CentersForDiseaseControlAndPrevention';
import CentersForMedicareAndMedicaid from './CentersForMedicareAndMedicaid';
import ChildrenAndFamiliesAdministration from './ChildrenAndFamiliesAdministration';
import FoodAndDrugAdministration from './FoodAndDrugAdministration';
import HealthResourcesAndServicesAdmin from './HealthResourcesAndServicesAdmin';
import IndianHealthService from './IndianHealthService';
import NationalInstitutesOfHealth from './NationalInstitutesOfHealth';
import PublicHealthService from './PublicHealthService';
import RefugeeResettlementOffice from './RefugeeResettlementOffice';
import SubstanceAbuseAndMentalServicesAdmin from './SubstanceAbuseAndMentalServicesAdmin';
import HealthCareFinanceAdministration from './HealthCareFinanceAdministration';
import CommunityLivingAdmin from './CommunityLivingAdmin';
import GenerateFeedDepartment from './GenerateFeedDepartment';



var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 30000;



  

 


  

export const HealthAndHumanServicesMobile = () =>   {
    render()
    
    {
        const [show, setShow] = useState(true);

        
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
        const [showBioModal, setBioModal] = useState(false);
        const [showMissionModal, setMissionModal] = useState(false);
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
     
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
      
      
        const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

        const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
        const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
        const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
        const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
        const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
        const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
        const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
        const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
        const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
        const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
        const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
        const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)

        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
        const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
        const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
        const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
        const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
        const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
        const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
        const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
        const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
        const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)
        const [modalInfo, setModalInfo] = useState({
          isOpen: false,
          title: '',
          content: null
        });
      
        const openModal = (title, content) => {
          setModalInfo({
            isOpen: true,
            title,
            content
          });
        };
      
        const closeModal = () => {
          setModalInfo({
            isOpen: false,
            title: '',
            content: null
          });
        };
        var mobileCarouselInterval = 60000;
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);

    var branch = "all";
  
    return(
       <>
   <MobileTopNavbar/>
  
  
  
  
  <div id='homebg'>
  <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
               
  
  <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div id="profileImageOnPageDepartment">
                <Image id="profileImage-imageOnPageDepartment" src="/departmentOfHealth.png" />
              </div>
              <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                <h5 id="branchHeadingMobile" > Department of Health and Human Service</h5>
              </div>
            </div>
            <Dropdown drop="down-centered">
              <Dropdown.Toggle id="searchCountry"><Link45deg /></Dropdown.Toggle>
              <Dropdown.Menu id="docsDropdown" drop="down-centered">
                <Dropdown.Item id="docsDropdownItem" onClick={(e) => { e.preventDefault(); window.open("https://www.hhs.gov/", "_blank") }}>
                  Official Site
                </Dropdown.Item>

               
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
          
          The Department of Health and Human Services (HHS) is the United States government’s principal agency for protecting the health of all Americans and providing essential human services, especially for those who are least able to help themselves. Agencies of HHS conduct health and social science research, work to prevent disease outbreaks, assure food and drug safety, and provide health insurance.
          </div>

     
        
        </div>
        <Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>


       
<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
<Modal.Header id='billModalHeader' closeButton><h3> Department of Health and Human Services</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>The Department of Health and Human Services (HHS) is the United States government’s principal agency for protecting the health of all Americans and providing essential human services, especially for those who are least able to help themselves. Agencies of HHS conduct health and social science research, work to prevent disease outbreaks, assure food and drug safety, and provide health insurance.</p>

<p>In addition to administering Medicare and Medicaid, which together provide health coverage to one in four Americans, HHS also oversees the National Institutes of Health, the Food and Drug Administration, and the Centers for Disease Control.</p>

<p>The Secretary of Health and Human Services oversees a budget of approximately $700 billion and approximately 65,000 employees. The Department’s programs are administered by 11 operating divisions, including eight agencies in the U.S. Public Health Service, two human services agencies, and the Centers for Medicare and Medicaid Services.</p>


<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=and%20contract%20employees.-,DEPARTMENT%20OF%20HEALTH%20AND%20HUMAN%20SERVICES,-The%20Department%20of", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=and%20contract%20employees.-,DEPARTMENT%20OF%20HEALTH%20AND%20HUMAN%20SERVICES,-The%20Department%20of
     </Link>
 </div>

       </Modal>
 
            <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}>News</h5>




  <div class='courtNewsMobile' id='defenseDepartment'></div>

  {CreateFeedItem('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/health-xml/health_and_human_services.xml', 'defenseDepartment', 'Department of Health and Human Services', 'Executive', 'N/A', DepartmentOfHealthAndHumanServices)}

  <Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Health and Human Services</h3></Modal.Header>
                  <DepartmentOfHealthAndHumanServices/>
                </Modal>

  </Col>


  <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDefenseDocumentsModalIsOpen(true)}>Documents</h5>


  <div class='courtNewsMobile' id='frDefense'></div>

  {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=health-and-human-services-department', 'frDefense', 'Department of Health and Human Services Documents', 'Executive', 'N/A', DepartmentOfHealthAndHumanServicesDocs)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Health and Human Services Documents</h3></Modal.Header>
                  <DepartmentOfHealthAndHumanServicesDocs/>
                </Modal>

                <Col>
                <Link  id='pageLink' to="/us/healthcare"> <h5 id='presidentialFeedHeadingMobile'>Agency for Healthcare Research and Quality<ChevronRight id='clickChevron'></ChevronRight></h5></Link>
                        <Carousel controls={false} >
                        
              

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<Row id = 'mobileFeedHeadingRow'><h5 id='billFeedHeadingMobileHome' onClick={() =>
                            openModal('Agency for Healthcare Research and Quality',
                            <GenerateFeedDepartment   url='&table_name=health_and_human_services&agency=Agency%20for%20Healthcare%20Research%20and%20Quality'
                            name='Agency for Healthcare Research and Quality' branch='Executive' topic='Health' buttonName='Doc'  />)}>Documents</h5></Row>




  <div class='courtNewsMobile' id='defAq'></div>
                              
  {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Agency%20for%20Healthcare%20Research%20and%20Quality', 'defAq', 
                            'Agency for Healthcare Research and Quality Documents', 'Executive', 
                            'Health', 'Doc', GenerateFeedDepartment)}
</Col>
              
               


    
  
    </div>
   


</Carousel.Item>



<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/healthcare' >View all Agency for Healthcare Research and Quality news</Button>
</div>
</Col>

</div>



</Carousel.Item>

                        
                        
                        </Carousel>
                        
                        </Col>
                        
                        <Col>
  <Link  id='pageLink' to="/us/toxic-substances">
    <h5 id='presidentialFeedHeadingMobile'>Agency for Toxic Substances and Disease Registry<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Agency for Toxic Substances and Disease Registry',
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Agency%20for%20Toxic%20Substances%20and%20Disease%20Registry'
              name='Agency for Toxic Substances and Disease Registry' branch='Executive' topic='Health' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defTsd'></div>
          {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Agency%20for%20Toxic%20Substances%20and%20Disease%20Registry', 'defTsd',
            'Agency for Toxic Substances and Disease Registry Documents', 'Executive',
            'Health', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/toxic-substances' >View all Agency for Toxic Substances and Disease Registry news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/aging-administration">
    <h5 id='presidentialFeedHeadingMobile'>Aging Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Aging Administration',
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Aging%20Administration'
              name='Aging Administration' branch='Executive' topic='Health' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defAa'></div>
          {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Aging%20Administration', 'defAa',
            'Aging Administration Documents', 'Executive',
            'Health', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/aging-administration' >View all Aging Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/cdc">
    <h5 id='presidentialFeedHeadingMobile'>Centers for Disease Control and Prevention<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Centers for Disease Control and Prevention',
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Centers%20for%20Disease%20Control%20and%20Prevention'
              name='Centers for Disease Control and Prevention' branch='Executive' topic='Health' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defCdc'></div>
          {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Centers%20for%20Disease%20Control%20and%20Prevention', 'defCdc',
            'Centers for Disease Control and Prevention Documents', 'Executive',
            'Health', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/cdc' >View all Centers for Disease Control and Prevention news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>



<Col>
  <Link  id='pageLink' to="/us/children-families">
    <h5 id='presidentialFeedHeadingMobile'>Children and Families Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Children and Families Administration',
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Children%20and%20Families%20Administration'
              name='Children and Families Administration' branch='Executive' topic='Health' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defCfa'></div>
          {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Children%20and%20Families%20Administration', 'defCfa',
            'Children and Families Administration Documents', 'Executive',
            'Health', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/children-families' >View all Children and Families Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/community-living">
    <h5 id='presidentialFeedHeadingMobile'>Community Living Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Community Living Administration',
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Community%20Living%20Administration'
              name='Community Living Administration' branch='Executive' topic='Health' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defCla'></div>
          {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Community%20Living%20Administration', 'defCla',
            'Community Living Administration Documents', 'Executive',
            'Health', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/community-living' >View all Community Living Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/fda">
    <h5 id='presidentialFeedHeadingMobile'>Food and Drug Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Food and Drug Administration',
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Food%20and%20Drug%20Administration'
              name='Food and Drug Administration' branch='Executive' topic='Health' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFda'></div>
          {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Food%20and%20Drug%20Administration', 'defFda',
            'Food and Drug Administration Documents', 'Executive',
            'Health', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/fda' >View all Food and Drug Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/health-care-finance">
    <h5 id='presidentialFeedHeadingMobile'>Health Care Finance Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Health Care Finance Administration',
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Health%20Care%20Finance%20Administration'
              name='Health Care Finance Administration' branch='Executive' topic='Health' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defHcfa'></div>
          {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Health%20Care%20Finance%20Administration', 'defHcfa',
            'Health Care Finance Administration Documents', 'Executive',
            'Health', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/health-care-finance' >View all Health Care Finance Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/health-resources">
    <h5 id='presidentialFeedHeadingMobile'>Health Resources and Services Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Health Resources and Services Administration',
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Health%20Resources%20and%20Services%20Administration'
              name='Health Resources and Services Administration' branch='Executive' topic='Health' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defHrsa'></div>
          {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Health%20Resources%20and%20Services%20Administration', 'defHrsa',
            'Health Resources and Services Administration Documents', 'Executive',
            'Health', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/health-resources' >View all Health Resources and Services Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/indian-health">
    <h5 id='presidentialFeedHeadingMobile'>Indian Health Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Indian Health Service',
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Indian%20Health%20Service'
              name='Indian Health Service' branch='Executive' topic='Health' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defIhs'></div>
          {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Indian%20Health%20Service', 'defIhs',
            'Indian Health Service Documents', 'Executive',
            'Health', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/indian-health' >View all Indian Health Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/nih">
    <h5 id='presidentialFeedHeadingMobile'>National Institutes of Health<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('National Institutes of Health',
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=National%20Institutes%20of%20Health'
              name='National Institutes of Health' branch='Executive' topic='Health' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defNih'></div>
          {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=National%20Institutes%20of%20Health', 'defNih',
            'National Institutes of Health Documents', 'Executive',
            'Health', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/nih' >View all National Institutes of Health news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/national-library-medicine">
    <h5 id='presidentialFeedHeadingMobile'>National Library of Medicine<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('National Library of Medicine',
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=National%20Library%20of%20Medicine'
              name='National Library of Medicine' branch='Executive' topic='Health' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defNlm'></div>
          {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=National%20Library%20of%20Medicine', 'defNlm',
            'National Library of Medicine Documents', 'Executive',
            'Health', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/national-library-medicine' >View all National Library of Medicine news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/program-support-center">
    <h5 id='presidentialFeedHeadingMobile'>Program Support Center<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false} >
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Program Support Center',
              <GenerateFeedDepartment url='&table_name=health_and_human_services&agency=Program%20Support%20Center'
              name='Program Support Center' branch='Executive' topic='Health' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defPsc'></div>
          {CreateFeedAPIDepartmentUS('&table_name=health_and_human_services&agency=Program%20Support%20Center', 'defPsc',
            'Program Support Center Documents', 'Executive',
            'Health', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/program-support-center' >View all Program Support Center news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<br></br>
          
          
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
    
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
          </div>

          </>
  )
}
 }



  






export default HealthAndHumanServicesMobile;

