import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, useHistory,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter, Spinner } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, ChevronDoubleRight, BoxArrowUp, ClipboardCheck, Clipboard } from 'react-bootstrap-icons'
import { render } from '@testing-library/react';
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import AddNoteModal from './AddNoteModal';
import HouseFloor from './HouseFloor';
import BillUpdates from './BillUpdates';
import BuildMaterialsFeed from './BuildMaterialsFeed';
import GenerateFeedRecent from './GenerateFeedRecent';
import useHandleContainerClickRecents from './UseHandleContainerClickRecents';
import useHandleContainerClickRecentsCanada from './UseHandleContainerClickRecentsCanada';
import GenerateFeedRecentCanada from './GenerateFeedRecentCanada';
import useHandleContainerClickRecentsMexico from './UseHandleContainerClickRecentsMexico';
import UseHandleContainerClickCandidate from './UseHandleContainerClickCandidate';
import GenerateFeedRecentMexico from './GenerateFeedRecentMexico';
import GenerateFeedRecentAll from './GenerateFeedRecentAll';
import GenerateFeedStateRepsAll from './GenerateFeedStateRepsAll';
import { set } from 'mobx';
import UseHandleContainerClickRecentsUK from './UseHandleContainerClickRecentsUK';
import GenerateFeedRecentUK from './GenerateFeedRecentUK';
import GenerateFeedElection from './GenerateFeedElection';
require('dotenv').config();
const apiKey = process.env.REACT_APP_API_KEY;


var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;
var varTitle = [];
var pet = pet
var test = 'test'

var billTitle = billTitle
var billTitle2 = billTitle2
var billTitle3 = billTitle3
var secTitle = secTitle
var doCTitle = doCTitle
var weatherTitle = weatherTitle 
var epaTitle = epaTitle
var doSTitle = doSTitle
var unTitle = unTitle
var cdcTitle = cdcTitle
var doHAndHTitle = doHAndHTitle

export function ListItem(props) {
    // Correct! There is no need to specify the key here:
    return <Card>{props.value}</Card>;
  }
export  function createFullBillFeed() {
    
    function NumberList(props) {
      const numbers = props.numbers;
      const listItems = numbers.map((number) =>
        // Correct! Key should be specified inside the array.
        <ListItem key={number.toString()} value={number} />
      );
      return (
  
        <Container><Card>{listItems}</Card></Container>
  
      );
    }
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
   
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/bills.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const linkChunk = links.toString().substring(36);
        //console.log(billTitle[99])
        const viewBillLink = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
        //console.log(viewBillLink)
  
  
        ReactDOM.render(
          <NumberList numbers={billTitle} />,
          document.getElementById('root')
        );
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  }

  
  
  function OpenNoteModal(){

    const [noteModalIsOpen, setNoteModalIsOpen] = useState(false);
    

setNoteModalIsOpen(true)
  


  }


  
 function SetNoteModalStatus(){



 }
 export function returnNote(saveCollectionNote){
    
  
  console.log(saveCollectionNote)
    }
  
  
  
  export function saveToCollection(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
    
  
console.log(saveCollectionNote)
  }

  export function DisplaySaveToCollectionModal(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription, modalState )
  {
console.log(saveCollectionTitle)
       
       
         
       
       
       
       
       }
   
     
       function formatDate2(pubDate) {
        const date = new Date(pubDate);
        const day = date.getUTCDate();
        const month = date.getUTCMonth() + 1; // Months are zero-indexed
        const year = date.getUTCFullYear();
        return `${month}-${day}-${year}`;
      }

  export function createUSCourtNewsFeed() {
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const pubDate = [];
    const feedContent = []
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.uscourts.gov/news/rss');
  
      feed.items.forEach(item => {
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        feedContent.push(item.content);
        pubDate.push(item.pubDate.substring(0,17));
        feed.item = item.title + ':' + item.link;
        //console.log(item.content)
  
      });
  
  
      for (let index = billsFeed.length; index > -1; index--) {
        //console.log(index)
        const titles = billTitle[index];
        const links = billLink[index];
        const content = feedContent[index];
        
  
        const titles2 = billTitle[index+1];
        const links2 = billLink[index+1];
        const content2 = feedContent[index+1];
        //const linkChunk = links.toString().substring(36);
  
        const viewBillLink = "https://www.uscourts.gov/news/rss"
  
  
  
       
  
        //render(<BillFeed />);
  
        ReactDOM.render(
          < div id = 'legislativeBills' >
            <Container id='feedContainerNormalView' >
  
              <Card.Title id='billText'>{titles}</Card.Title>
             {/* <Card.Body id='billBodyText'>{content}</Card.Body>*/}
              <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
              <Button id='billLink'  onClick={(e) => {
                e.preventDefault();
                window.open( billLink[index],"_blank")
              }}><InfoCircle></InfoCircle> Details</Button>
  
              <Button id='addToCollectionButton' onClick={() =>saveToCollection(titles, billLink[index], null, pubDate[0], null, "U.S. Court News", "Judicial", "N/A" )
  
  
  
  
              }><FolderPlus></FolderPlus></Button>
  
  
  
            </Container>
  
            <br></br>
  
          </div >,document.getElementById('courtNews'));
  
       /* ReactDOM.render(
          < div id='legislativeBills' >
            <Container id='feedContainerNormalView' >
  
              <Card.Title id='billText'>{titles2}</Card.Title>
              <Card.Body id='billBodyText'>{content2}</Card.Body>
              <Card.Body id='billBodyText'>{pubDate[1]}
              </Card.Body>
              <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(billLink[index+1], "_blank")
              }}><InfoCircle></InfoCircle> Details</Button>
  
              <Button id='addToCollectionButton' onClick={() => saveToCollection(titles, billLink[index-1], null)
  
  
  
  
              }><FolderPlus></FolderPlus></Button>
  
  
  
            </Container>
  
            <br></br>
  
          </div >, document.getElementById('courtNews2'));
          */
      }
  
    })();
  
  
  
  
  
  
  
  }


  export function createCourtOfInternationalTrade() {
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const pubDate = [];
    const feedContent = []
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/uscourts-cit.xml');
  
      feed.items.forEach(item => {
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        feedContent.push(item.content);
        pubDate.push(item.pubDate.substring(0,17));
        feed.item = item.title + ':' + item.link;
        //console.log(item.content)
  
      });
  
  
      for (let index = billsFeed.length; index > -1; index--) {
        //console.log(index)
        const titles = billTitle[index];
        const links = billLink[index];
        const content = feedContent[index];
        
  
        const titles2 = billTitle[index+1];
        const links2 = billLink[index+1];
        const content2 = feedContent[index+1];
        //const linkChunk = links.toString().substring(36);
  
        const viewBillLink = "https://www.uscourts.gov/news/rss"
  
  
  
       
  
        //render(<BillFeed />);
  
        ReactDOM.render(
          < div id = 'legislativeBills' >
            <Container id='feedContainerNormalView' >
  
              <Card.Title id='billText'>{titles}</Card.Title>
             {/* <Card.Body id='billBodyText'>{content}</Card.Body>*/}
             <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
              <Button id='billLink'  onClick={(e) => {
                e.preventDefault();
                window.open( billLink[index],"_blank")
              }}><InfoCircle></InfoCircle> Details</Button>
  
              <Button id='addToCollectionButton' onClick={() =>saveToCollection(titles, billLink[index], null, pubDate[0], null, "U.S. Court of International Trade", "Judicial", "N/A")
  
  
  
  
              }><FolderPlus></FolderPlus></Button>
  
  
  
            </Container>
  
            <br></br>
  
          </div >,document.getElementById('cit'));
  
        
      }
  
    })();
  
  
  
  
  
  
  
  }


  
  export function createUSCourtNewsFeedMobile() {
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const pubDate = [];
    const feedContent = []
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.uscourts.gov/news/rss');
  
      feed.items.forEach(item => {
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        feedContent.push(item.content);
        pubDate.push(item.pubDate.substring(0,17));
        feed.item = item.title + ':' + item.link;
        //console.log(item.content)
  
      });
  
  
      for (let index = billsFeed.length; index > -1; index--) {
        //console.log(index)
        const titles = billTitle[index];
        const links = billLink[index];
        const content = feedContent[index];
  
  
        const titles2 = billTitle[index + 1];
        const links2 = billLink[index + 1];
        const content2 = feedContent[index + 1];
        //const linkChunk = links.toString().substring(36);
  
        const viewBillLink = "https://www.uscourts.gov/news/rss"
  
  
  
  
  
        //render(<BillFeed />);
  
        ReactDOM.render(
          < div id='legislativeBills' >
            <Container id='feedContainerNormalView' >
  
              <Card.Title id='billText'>{titles}</Card.Title>
              <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
  
              <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(billLink[index], "_blank")
              }}><InfoCircle></InfoCircle> Details</Button>
  
              <Button id='addToCollectionButton' onClick={() => saveToCollection(titles, billLink[index], null, pubDate[0], null, "U.S. Court News", "Judicial", "N/A" )
  
  
  
  
              }><FolderPlus></FolderPlus></Button>
  
  
  
            </Container>
  
            <br></br>
  
          </div >, document.getElementById('courtNewsMobile'));
  
   
      }
  
    })();
  
  
  
  
  
  
  
  }
  
  
  export function createCourtAppeals() {
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const feedContent = [];
    const pubDate = [];
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/uscourts-ca13.xml');
  
      feed.items.forEach(item => {
        pubDate.push(item.pubDate.substring(0,17));
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        feedContent.push(item.content);
        feed.item = item.title + ':' + item.link;
        //console.log(item.content)
  
      });
  
  
      for (let index = billsFeed.length; index > -1; index--) {
        //console.log(index)
        const titles = billTitle[index];
        const titles2 = billTitle[index+1];
        const links = billLink[index];
        const content = feedContent[index];
        //const linkChunk = links.toString().substring(36);
  
        const viewBillLink = "https://www.uscourts.gov/news/rss"
  
  
  
        ReactDOM.render(
          <div id='legislativeBills'>
            <Container id='feedContainerNormalView' >
  
              <Card.Title id='billText'>{titles}</Card.Title>
              
              <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
              <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(billLink[index], "_blank")
              }}><InfoCircle></InfoCircle> Details</Button>
  
              <Button id='viewBillButton' onClick={(e) => {
  
                e.preventDefault();
                window.open(
                  "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), "_blank")
              }}><FileText></FileText> Document</Button>
  
              <Button id='addToCollectionButton' onClick={() => saveToCollection(titles, billLink[index], "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), pubDate[0], null, "U.S. Court of Appeals", "Judicial", "N/A")
  
  
  
  
              }><FolderPlus></FolderPlus></Button>
  
  
  
            </Container>
  
            <br></br>
  
          </div>, document.getElementById('courtAppeals')
  
  
        
        )
  
        
  
        //render(<BillFeed />);
  
  
       
  
  
  
      }
  
    })();
  
  
  
  
  
  }
  
  export function createCourtAppealsMobile() {
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const feedContent = []
    const pubDate = []
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/uscourts-ca13.xml');
  
      feed.items.forEach(item => {
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        feedContent.push(item.content);
        feed.item = item.title + ':' + item.link;
        //console.log(item.content)
        pubDate.push(item.pubDate.substring(0,17));
      });
  
  
      for (let index = billsFeed.length; index > -1; index--) {
        //console.log(index)
        const titles = billTitle[index];
        const titles2 = billTitle[index + 1];
        const links = billLink[index];
        const content = feedContent[index];
        //const linkChunk = links.toString().substring(36);
  
        const viewBillLink = "https://www.uscourts.gov/news/rss"
  
  
  
        ReactDOM.render(
          <div id='legislativeBills'>
            <Container id='feedContainerNormalView' >
  
              <Card.Title id='billText'>{titles}</Card.Title>
  
              <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
              <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(billLink[index], "_blank")
              }}><InfoCircle></InfoCircle> Details</Button>
  
              <Button id='viewBillButton' onClick={(e) => {
  
                e.preventDefault();
                window.open(
                  "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), "_blank")
              }}><FileText></FileText> Document</Button>
  
              <Button id='addToCollectionButton' onClick={() => saveToCollection(titles, billLink[index], null, pubDate[0], null, "U.S. Court of Appeals", "Judicial", "N/A")
  
  
  
  
              }><FolderPlus></FolderPlus></Button>
  
  
  
            </Container>
  
            <br></br>
  
          </div>, document.getElementById('courtAppealsMobile')
  
  
  
        )
  
  
  
        //render(<BillFeed />);
  
  
  
  
  
  
  
  
  
  
      }
  
    })();
  
  
  
  
  
  }
  
  
  
  //Bills Presented to President
  //Currently dorment
  export function createBillsPresentedFeed() {
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const feedContent = [];
    const pubDate = [];
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.congress.gov/rss/presented-to-president.xml');
  
      feed.items.forEach(item => {
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        feedContent.push(item.content);
        //console.log(billTitle)
        feed.item = item.title + ':' + item.link;
        pubDate.push(item.pubDate.substring(0,17));
      });
  
  
      for (let index = 0; index < billTitle.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const content = feedContent[index];
        
        const linkChunk = links.toString().substring(36);
  
        const viewBillLink = "https://www.congress.gov/rss/presented-to-president.xml"
  
  
  
        const BillFeed = () => (
          <div id='legislativeBills'>
            <Container>
  
              <Card.Title id='billText'>{titles}</Card.Title>
              {/* <Card.Body id='billBodyText'>{content}</Card.Body>*/}
              <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
              <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.location.href = billLink[index]
              }}><InfoCircle></InfoCircle> Details</Button>
  
  
            </Container>
  
            <br></br>
  
          </div>
  
  
        );
        ReactDOM.render(<BillFeed />, document.getElementById('presented'));
  
        //render(<BillFeed />);
  
  
  
      }
  
    })();
  
  
  
  
  
  }
  
  export function createWhiteHouseDocuments() {
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const feedContent = []
    const pubDate = []
    
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/dcpd.xml');
  
      feed.items.forEach(item => {
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        
        feedContent.push(item.summary);
        
        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billTitle.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const links2 = billLink[index-1];
        const content = feedContent[index];
        const date = pubDate[index];
          const linkChunk = links.toString().substring(36);
     
  
  
        const viewDocLink = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf")
        
  
     
        ReactDOM.render(
          <div id='legislativeBills'>
            <Container id='feedContainerNormalView' >
  
              <Card.Title id='billText'>{titles}</Card.Title>
              
              <Card.Body id='billBodyText'>{pubDate[index]}
              </Card.Body>
              <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(billLink[index],"_blank")
              }}><InfoCircle></InfoCircle> Details</Button>
  
             <Button id='viewBillButton' onClick={(e) => {
                e.preventDefault();
                window.open(viewDocLink,"_blank")
              }}><FileText></FileText> Document</Button>
  
              <Button id='addToCollectionButton' onClick={() => saveToCollection(titles, billLink[index], viewDocLink, pubDate[index], null, "Presidential Documents", "Executive", "N/A")
  
  
  
  
              }><FolderPlus></FolderPlus></Button>
  
  
            </Container>
  
            <br></br>
  
          </div>, document.getElementById('whdocs'));
  
  
       
  
        //render(<BillFeed />);
  
  
  
      }
  
    })();
  
  
  function getLocalStorage(){
  
    
  
  
  
  
  }
  
  
  }
  
  //White House Feed
  export function createWhiteHouseFeed() {
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const feedContent = [];
    const pubDate = [];
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.whitehouse.gov/feed/');
  
      feed.items.forEach(item => {
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        feedContent.push(item.content.replace('&#8230;','...'));
        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billTitle.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const content = feedContent[index]
        const linkChunk = links.toString().substring(36);
        const date = pubDate[index].toString().substring(0,25)
  
        const viewBillLink = "https://www.govinfo.gov/rss/dcpd.xml"
  
        const BillFeed = () => (
          <div id='legislativeBills'>
            <Container id='feedContainerNormalView' >
  
              <Card.Title id='billText'>{titles}</Card.Title>
            {/* <Card.Body id='billBodyText'>{content}</Card.Body>*/}
              
              
<Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
              <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(billLink[index], "_blank")
              }}><InfoCircle></InfoCircle> Details</Button>
  
               <Button id='addToCollectionButton' onClick={() => saveToCollection(titles, billLink[index], null, pubDate[0], null, "White House Breifing Room", "Executive", "N/A")
          
            
           
            
          }><FolderPlus></FolderPlus></Button>
  
  
            </Container>
  
            <br></br>
  
          </div>
  
  
        );
        ReactDOM.render(<BillFeed />, document.getElementById('wh'));
  
        
  
        //render(<BillFeed />);
  
  
  
      }
  
    })();
  
  
  
  
  
  }
  
  
  export function createWhiteHouseFeedMobile() {
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const feedContent = [];
    const pubDate = [];
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.whitehouse.gov/feed/');
  
      feed.items.forEach(item => {
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        feedContent.push(item.content.replace('&#8230;', '...'));
        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billTitle.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const content = feedContent[index]
        const linkChunk = links.toString().substring(36);
        const date = pubDate[index].toString().substring(0, 25)
  
        const viewBillLink = "https://www.govinfo.gov/rss/dcpd.xml"
  
        const BillFeed = () => (
          <div id='legislativeBills'>
            <Container id='feedContainerNormalView' >
  
              <Card.Title id='billText'>{titles}</Card.Title>
              
             
<Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
              <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(billLink[index], "_blanl")
              }}><InfoCircle></InfoCircle> Details</Button>
  
              <Button id='addToCollectionButton' onClick={() => saveToCollection(titles, billLink[index], null, pubDate[0], null, "White House Breifing Room", "Executive", "N/A")
  
  
  
  
              }><FolderPlus></FolderPlus></Button>
  
  
            </Container>
  
            <br></br>
  
          </div>
  
  
        );
        ReactDOM.render(<BillFeed />, document.getElementById('wh'));
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
    })();
  
  
  
  
  
  }
  
  
  
  //Legislative Bills
  //Doesn't get used
  export function createBillFeed() {
    
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const pubDate = []
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/bills.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
        billTitle.push(item.title.replace(/\&nbsp;/g, ''));
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17))
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billTitle.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const date = pubDate[index].toString().substring(0,25)
        const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        const viewBillLink = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
        //console.log(viewBillLink)
        const BillFeed = () => (
          <div id='legislativeBills'>
            <Container>
  
              <Card.Title id='billText'>{titles}</Card.Title>
              <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
              <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(billLink[index], "_blank")
              }}><InfoCircle></InfoCircle> Details</Button>
              <Button id='viewBillButton' onClick={(e) => {
                e.preventDefault();
                window.open( viewBillLink,"_blank")
              }}><FileText></FileText> Bill</Button>
  
            </Container>
  
            <br></br>
  
          </div>
  
  
        );
        ReactDOM.render(<BillFeed />, document.getElementById('bills'));
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createBillFeedTwo() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    
   
    
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/bills.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
        //Feed Item Text cap code
        if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml"));
        
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = billsFeed.length; index >= 0; index--) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
   
        const date = pubDate[index]
  
        
        
        
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
       
        //console.log(linkChunk)
       // viewBillLinkArray.push(F)
        
    
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[index]}
              </Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index],"_blank")
        }}> <InfoCircle></InfoCircle> Details</Button> 
        
        <Button id='viewBillButton' onClick={(e) => {
          e.preventDefault();
            window.open(viewBillLink[index], "_blank")
          }}><FileText></FileText> Bill</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index],billLink[index],viewBillLink[index], pubDate[0], null, "Bill Updates", "Legislative", "N/A")
          
            
           
            
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('bills'));
  
        
  
  
  
       
  
  
  
  
  
  
  
  
  
       
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createBillFeedTwoMobile() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    
   
    
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/bills.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
        billTitle.push(item.title.replace(/\&nbsp;/g, ''));
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml"));
        
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = billsFeed.length; index >= 0; index--) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
        
        
        
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
       
        //console.log(linkChunk)
       // viewBillLinkArray.push(F)
        
    
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[index]}</Card.Title>
        
        <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index],"_blank")
        }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
          e.preventDefault();
            window.open(viewBillLink[index], "_blank")
          }}><FileText></FileText> Bill</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index],billLink[index],viewBillLink[index], pubDate[0], "Bill Updates", "Legislative", "N/A")
          
            
           
            
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('billsIntroducedMobile'));
  
        
  
  
  
        
  
  
  
  
  
  
  
  
  
       
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  
  export function createReports() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/crpt.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
        billTitle.push(item.title.replace(/&nbsp;/g,' '));
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
        
        
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[index]}
              </Card.Body>
        
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
          e.preventDefault();
          //console.log(viewBillLink[index])
          window.open(viewBillLink[index], "_blank")
        }}><FileText></FileText> Report</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], pubDate[index], "Congressional Reports", "Legislative", "N/A")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('reports'));
  
  
  
  
  
       
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createHouseCommitteeOnAgriculture() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=AG00');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
        
        <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button> 
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], "House Committee on Agriculture", "Legislative", "Environment")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseComitteeOnAgriculture'));
  
  
  
  
  
        
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }

  

export function createEnvironmentalProtectionAgencyMobile() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.epa.gov/newsreleases/search/rss');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = billsFeed.length; index >= 0; index--) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]


      

      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title>
      
      <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
      
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], "Environmental Protection Agency", "Executive", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('epaMobile'));





      














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}
  

export function createDepartmentOfCommerceMobile() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.commerce.gov/feeds/news');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = billsFeed.length; index >= 0; index--) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title>
      <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Commerce", "Executive", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('commerceDepartmentMobile'));





      














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

  
export function createNationalScienceFoundation() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.nsf.gov/rss/rss_www_discoveries.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title>
      <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "National Science Foundation Research News", "Executive", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('nsf'));





     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

  

  export function createCongressionalBudgetOfficeMobile() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const billContent = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.cbo.gov/publications/all/rss.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
        billContent.push(item.content);
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        //console.log('ETHICS: ' + billTitle)
       //console.log(item.content)
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
       
       <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>

        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "Congressional Budget Office", "Legislative", "Economy")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('congressionalBudgetOfficeMobile'));
  
  
  
  
  
      
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  
  export function createCongressionalHearings() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/chrg.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[billTitle.length-1]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[billTitle.length - 1], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[billTitle.length - 1], billLink[billTitle.length - 1], null, pubDate[0], null, "Congressional Hearings", "Legislative", "N/A")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('congressionalHearings'));
  
  
  
  
  
        
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createPresidentialProclamations() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bcorrection%5D=0&conditions%5Bpresident%5D=&conditions%5Bpresidential_document_type%5D=proclamation&conditions%5Bsigning_date%5D%5Byear%5D=&conditions%5Btype%5D%5B%5D=PRESDOCU');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        //console.log('ETHICS: ' + billTitle)
        billLink.push(item.link);
        //console.log('test: ' + billLink.toString().substring(42,46)+'-'+ billLink.toString().substring(47,49)+'-'+ billLink.toString().substring(50,52).concat('/pdf/')+ billLink.toString().substring(53,57)+'-'+billLink.toString().substring(58,63).concat('.pdf'))
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(42.53)
        //viewBillLink.push("https://www.govinfo.gov/content/pkg/FR-"+billLink.toString().substring(42,46)+'-'+ billLink.toString().substring(47,49)+'-'+ billLink.toString().substring(50,52).concat('/pdf/')+ billLink.toString().substring(53,57)+'-'+billLink.toString().substring(58,63).concat('.pdf'));
  
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
  <Button id='viewBillButton' onClick={(e) => {
                e.preventDefault();
                window.open("https://www.govinfo.gov/content/pkg/FR-"+billLink[0].toString().substring(42,46)+'-'+ billLink[0].toString().substring(47,49)+'-'+ billLink[0].toString().substring(50,52).concat('/pdf/')+ billLink[0].toString().substring(53,57)+'-'+billLink[0].toString().substring(58,63).concat('.pdf'),"_blank")
              }}><FileText></FileText> Document</Button>
  
  
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], "https://www.govinfo.gov/content/pkg/FR-"+billLink[0].toString().substring(42,46)+'-'+ billLink[0].toString().substring(47,49)+'-'+ billLink[0].toString().substring(50,52).concat('/pdf/')+ billLink[0].toString().substring(53,57)+'-'+billLink[0].toString().substring(58,63).concat('.pdf'), pubDate[0], null, "Presidential Proclamations", "Executive", "N/A")
  
  
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('presidentialProclomations'));
  
  
  
       
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createExecutiveOrders() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bcorrection%5D=0&conditions%5Bpresident%5D=&conditions%5Bpresidential_document_type%5D=executive_order&conditions%5Bsigning_date%5D%5Byear%5D=&conditions%5Btype%5D%5B%5D=PRESDOCU');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
      //  console.log(item.title.substring(0,177)+'...')
        if (item.title.length >= 180)
        {
          billTitle.push(item.title.substring(0,177)+'...');
        }
        else{
          billTitle.push(item.title);
        }
        //console.log('ETHICS: ' + billTitle)
        billLink.push(item.link);
        //console.log('test: ' + billLink.toString().substring(42,46)+'-'+ billLink.toString().substring(47,49)+'-'+ billLink.toString().substring(50,52).concat('/pdf/')+ billLink.toString().substring(53,57)+'-'+billLink.toString().substring(58,63).concat('.pdf'))
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(42.53)
        //viewBillLink.push("https://www.govinfo.gov/content/pkg/FR-"+billLink.toString().substring(42,46)+'-'+ billLink.toString().substring(47,49)+'-'+ billLink.toString().substring(50,52).concat('/pdf/')+ billLink.toString().substring(53,57)+'-'+billLink.toString().substring(58,63).concat('.pdf'));
  
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'>
          <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
        
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
  <Button id='viewBillButton' onClick={(e) => {
                e.preventDefault();
                window.open("https://www.govinfo.gov/content/pkg/FR-"+billLink[0].toString().substring(42,46)+'-'+ billLink[0].toString().substring(47,49)+'-'+ billLink[0].toString().substring(50,52).concat('/pdf/')+ billLink[0].toString().substring(53,57)+'-'+billLink[0].toString().substring(58,63).concat('.pdf'),"_blank")
              }}><FileText></FileText> Document</Button>
  
  
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], "https://www.govinfo.gov/content/pkg/FR-"+billLink[0].toString().substring(42,46)+'-'+ billLink[0].toString().substring(47,49)+'-'+ billLink[0].toString().substring(50,52).concat('/pdf/')+ billLink[0].toString().substring(53,57)+'-'+billLink[0].toString().substring(58,63).concat('.pdf'), pubDate[0], "Executive Orders", "Executive", "N/A")
  
  
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('executiveOrders'));
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  
  
  export function createGAOReports() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.gao.gov/rss/reports.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(29)
        viewBillLink.push("https://www.gao.gov/assets/".concat(linkChunk).concat(".pdf"));
  
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = billsFeed.length; index >= 0; index--) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          <Card.Body id='billBodyText'>{pubDate[index]}
              </Card.Body><Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
          e.preventDefault();
          //console.log(viewBillLink[index])
          window.open(viewBillLink[index], "_blank")
        }}><FileText></FileText> Report</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], pubDate[index], null, "Government Accountability Office Reports", "Legislative", "N/A")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('gaoReports'));
  
  
  
  
  
        
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }

  export function createGAOReportsMobile() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.gao.gov/rss/reports.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(29)
        viewBillLink.push("https://www.gao.gov/assets/".concat(linkChunk).concat(".pdf"));
  
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = billsFeed.length; index >= 0; index--) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          <Card.Body id='billBodyText'>{pubDate[index]}
              </Card.Body><Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
          e.preventDefault();
          //console.log(viewBillLink[index])
          window.open(viewBillLink[index], "_blank")
        }}><FileText></FileText> Report</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], pubDate[index], null, "Government Accountability Office Reports", "Legislative", "N/A")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('gaoReportsMobile'));
  
  
  
  
  
        
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  
  export function createNewLaws() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/plaw.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);

        if (item.title.length >= 180)
        {
          billTitle.push(item.isoDate.substring(0, 10) + item.pubDate.substring(0, 17) + " " + item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
        else{
          billTitle.push(item.isoDate.substring(0, 10) + item.pubDate.substring(0, 17) + " " + item.title);
        }

        
        billTitle.sort()
  
        billTitle.reverse()
        billTitle.toString().substring(0, 4)
        
        
        billLink.push(item.isoDate.substring(0, 10) + item.link);
        billLink.sort()
        billLink.reverse()
  
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/uslm/").concat(linkChunk).concat(".xml"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
        //console.log("https://www.govinfo.gov/content/pkg/".concat(billLink[0].substring(10).toString().substring(36)).concat("/uslm/").concat(billLink[0].substring(10).toString().substring(36)).concat(".xml"))
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText'>{billTitle[0].substring(26)}</Card.Title>
          <Card.Body id='billBodyText'>{pubDate[index]}
              </Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0].substring(10), "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
          e.preventDefault();
          window.open( 
            "https://www.govinfo.gov/content/pkg/".concat(billLink[0].substring(10).toString().substring(36)).concat("/uslm/").concat(billLink[0].substring(10).toString().substring(36)).concat(".xml"), "_blank")
        }}><FileText></FileText> View Law</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], "https://www.govinfo.gov/content/pkg/".concat(billLink[0].substring(10).toString().substring(36)).concat("/uslm/").concat(billLink[0].substring(10).toString().substring(36)).concat(".xml"), pubDate[index], null, "Laws", "Legislative", "N/A")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('newLaws'));
  
  
  
  
  
        
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  
  export function createBillsEnrolled() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/bills-enr.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);

        if (item.title.length >= 180)
        {
          billTitle.push(item.isoDate.substring(0, 10) + item.pubDate.substring(0, 17) + " " + item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
        else{
          billTitle.push(item.isoDate.substring(0, 10) + item.pubDate.substring(0, 17) + " " + item.title);
        }
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
        
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billTitle.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
        

  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(< Container id = 'feedContainerNormalView'><Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[index]}
              </Card.Body>
        
        
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
          e.preventDefault();
          window.open(viewBillLink[index], "_blank")
        }}><FileText></FileText> Bill</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], pubDate[index], null, "Bills Enrolled (Sent to President)", "Legislative", "N/A")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('billsEnrolled'));
  
  
  
  
  
       
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  
  export function createBillsEnrolledMobile() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/bills-enr.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(< Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[index]}</Card.Title>
        
        <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body><Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button> <Button id='viewBillButton' onClick={(e) => {
          e.preventDefault();
          window.open(viewBillLink[index], "_blank")
        }}><FileText></FileText> Bill</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index], pubDate[index], null, "Bills Enrolled (Sent to President)", "Legislative", "N/A")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('billsEnrolledMobile'));
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  
  export function createDepartmentOfState() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const feedContent = [];
    const feedContentSnippet = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.state.gov/rss-feed/press-releases/feed/');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        feedContent.push(item.content);
        feedContentSnippet.push(item.contentSnippet);
  
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const content = feedContent[index]
        const contentSnippet = feedContentSnippet[index]
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title>
        
        <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
        
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button> 
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of State", "Executive", "Foreign Affairs")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('stateDepartment'));
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
     function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }


  export function createBillStatus() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const feedContent = [];
    const feedContentSnippet = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/billstatus-bulkdata.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        feedContent.push(item.content);
        feedContentSnippet.push(item.contentSnippet);
  
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const content = feedContent[index]
        const contentSnippet = feedContentSnippet[index]
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title>
        
        <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
        
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button> 
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Bill Status", "Legislative", "N/A")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('billStatus'));
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
     function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  
  
  export function createDepartmentOfEnergy() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const feedContent = [];
    const feedContentSnippet = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=energy-department');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        feedContent.push(item.content);
        feedContentSnippet.push(item.contentSnippet);
  
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const content = feedContent[index]
        const contentSnippet = feedContentSnippet[index]
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
              <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Energy", "Executive", "Environment")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('energyDepartment'));
  
  
  
  
  
        
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createDepartmentOfTreasury() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://home.treasury.gov/system/files/126/ofac.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.toString().substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
              <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[index], null, "Department of Treasury", "Executive", "Economy" )
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('treasuryDepartment'));
  
  
  
  
  
        
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createDepartmentOfDefense() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.defense.gov/DesktopModules/ArticleCS/RSS.ashx?max=10&ContentType=1&Site=945');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.toString().substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Defense", "Executive", "Foreign Affairs")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('defenseDepartment'));
  
  
  
  
  
        
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createDepartmentOfTransportation() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=transportation-department');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.toString().substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title>
<Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body><Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Transportation", "Executive", "N/A")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('transportationDepartment'));
  
  
  
  
  
      
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  
  
  export function createDepartmentOfJustice() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.justice.gov/feeds/opa/justice-news.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title>
<Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body><Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Justice", "Executive", "N/A")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('justiceDepartment'));
  
  
  
  
  

  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  
  export function createDepartmentOfTheInterior() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.doi.gov/feeds/list/22038/rss.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title>
<Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body><Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of the Interior", "Executive", "Environment")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('theInteriorDepartment'));
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createDepartmentOfAgriculture() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.usda.gov/rss/home.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title>
<Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body><Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Agriculture", "Executive", "Environment" )
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('agricultureDepartment'));
  
  
  
  

  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createDepartmentOfCommerce() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.commerce.gov/feeds/news');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = billsFeed.length; index >= 0; index--) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          <Card.Title id='billText'>{billTitle[0]}</Card.Title>
<Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body><Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Commerce", "Executive", "Economy")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('commerceDepartment'));
  
  
  
  
  
      
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createDepartmentOfLabor() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.dol.gov/rss/releases.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.toString().substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>

          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Labor", "Executive", "Economy")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('laborDepartment'));
  
  
  
  

  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  

  
  export function createDepartmentOfHousingAndUrbanDevelopment() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.hud.gov/sites/dfiles/Main/documents/hudrss.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          
<Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Housing and Urban Development", "Executive", "Environment")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('housingAndUrbanDevelopmentDepartment'));
  
  
  
  
  
     
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createDepartmentOfEducation() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.ed.gov/feed');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>

          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Education", "Executive", "N/A")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('educationDepartment'));
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createDepartmentOfVeteransAffairs() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.va.gov/oig/rss/press-releases.asp');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.toString().substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Veterans Affairs", "Executive", "N/A")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('veteransAffairsDepartment'));
  
  
  
  
  
        
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createDepartmentOfHomelandSecurity() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.dhs.gov/news_and_updates/rss.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.toString().substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
        
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Homeland Security", "Executive", "N/A")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('homelandSecurityDepartment'));
  
  
  
  
  
     
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  
  export function createEnvironmentalProtectionAgency() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.epa.gov/newsreleases/search/rss');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = billsFeed.length; index >= 0; index--) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
        
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], "EPA", "Executive", "Environment")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('epa'));
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
 export function createNationalAeronauticsAndSpaceAdministration() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.nasa.gov/rss/dyn/breaking_news.rss');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "NASA", "Executive", "Environment")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('nasa'));
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
export  function createTrendingBills() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.congress.gov/rss/most-viewed-bills.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          
          <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index])
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('trendingBills'));
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  
  export function createSenateFloorToday() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.congress.gov/rss/senate-floor-today.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        //console.log(billTitle)
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container>
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index])
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('senateFloor'));
  
  
  
  
  
   
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  
 export function createHouseFloorToday() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://clerk.house.gov/Home/Feed');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
       
  
  
  
        billsFeed.push(item.title + ':' + item.link);
        billTitle.push(item.content);
        //console.log(billTitle)
        billLink.push(item.contentSnippet);
        
        pubDate.push(item.pubDate.substring(0,17));
        //const linkChunk = item.link.toString().substring(36)
       // viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index].substring(0,16)
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
            window.open("https://live.house.gov/", "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index],"https://live.house.gov/", null, pubDate[0], null, "House Floor Activity Today", "Legislative", "N/A")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseFloor'));
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  
 export function billFeedModal(){
  
  
    
    //console.log(FullBillFeed())
    render(
      <Modal show='true'></Modal>
    )
  }
  
  export function congressSearch() {
  
    var searchItem = document.getElementById('congressSearch').value.replace(" ", "%20")
  
  
    var searchURL = "https://www.congress.gov/search?q=%7B%22congress%22%3A%5B%22117%22%5D%2C%22source%22%3A%22all%22%2C%22search%22%3A%22".concat(searchItem).concat("%22%7D")
    window.open(searchURL, "_blank")
  
  
  
  
  }
  
 export function whiteHouseSearch() {
  
    var searchItem = document.getElementById('whiteHouseSearch').value.replace(" ", "+")
  
  
    var searchURL = "https://www.whitehouse.gov/?s=".concat(searchItem)
    window.open(searchURL, "_blank")
  
  
  
  
  }
  
  export function supremeCourtSearch() {
  
    var searchItem = document.getElementById('supremeCourtSearch').value.replace(" ", "+")
  
  
    var searchURL = "https://www.supremecourt.gov/search.aspx?Search=".concat(searchItem).concat("&type=Site")
    window.open(searchURL, "_blank")
  
  
  
  

  }

  export function createCDCNewsroom() {

    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/health-xml/cdc_newsroom.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "CDC Newsroom", "N/A", "Health")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('cdcNewsroom'));
  
  
  
  
  
       
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }


  export function createDepartmentOfHealthAndHumanServices() {

    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/health-xml/health_and_human_services.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Department of Health and Human Services", "Executive", "Health")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('healthAndHumanServicesDepartment'));
  
  
  
  
  
       
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createCDCOutbreaks() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://tools.cdc.gov/api/v2/resources/media/285676.rss');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          
          <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "CDC Outbreaks", "N/A", "Health")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('outbreaks'));
  
  
  
  
  

  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createCDCTravelNotices() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://tools.cdc.gov/api/v2/resources/media/285740.rss');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "CDC Travel Notices", "N/A", "Health")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('travelNotices'));
  
  
  
  
  
   
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  export function createFoodSafety() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/health-xml/food_recalls.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          
          <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Food Safety", "N/A", "Health")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('foodSafety'));
  
  
  
  
  
        
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createFoodRecalls() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/health-xml/food_recall.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Food Recalls", "N/A", "Health")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('foodRecalls'));
  
  
  
  
  

  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createMedWatch() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/health-xml/med_watch.xml');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Med Watch", "N/A", "Health")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('medWatch'));
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  
  
  export function createHealthyLivingFeatures() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://tools.cdc.gov/api/v2/resources/media/132036.rss');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Healthy Living Features", "N/A", "Health")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('healthyLivingFeatures'));
  
  
  
 
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  
  export function createPreventingChronicDiseases() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://tools.cdc.gov/api/v2/resources/media/322083.rss');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Preventing Chronic Diseases", "N/A", "Health")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('preventingChronicDisease'));
  
  
  
  
  
     
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createCovid19() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://tools.cdc.gov/api/v2/resources/media/404952.rss');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Covid-19", "N/A", "Health")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('covid19'));
  
  
  
  
  
       
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createNovelCoronavirus() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://tools.cdc.gov/api/v2/resources/media/403372.rss');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
<Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Novel Coronavirus", "N/A", "Health")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('novelCoronavirus'));
  
  
  
  
  
        
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createVaccineUpdates() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://tools.cdc.gov/api/v2/resources/media/415826.rss');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Vaccine Updates", "N/A", "Health")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('vaccineUpdates'));
  
  
  
  
  
        
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }
  
  export function createSeasonalFlu() {
  
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://tools.cdc.gov/api/v2/resources/media/277692.rss');
  
  
  
  
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,17));
        const linkChunk = item.link.toString().substring(36)
        viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
        //console.log(item.title);
        //console.log(billsFeed);
        //console.log(billLink[3])
  
  
  
  
  
      });
  
      //Remove: https://www.govinfo.gov/app/details/
      //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
  
      //Titles
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[99]
        const dateThree = pubDate[98]
  
  
  
  
        //const linkChunk = links.toString().substring(36);
        //console.log(linkChunk)
        //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
  
        //console.log(linkChunk)
        // viewBillLinkArray.push(F)
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView' >
          <Card.Title id='billText'>{billTitle[index]}</Card.Title>
          
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
          <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[index], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
          <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "Seasonal Flu", "N/A", "Health")
  
  
  
  
          }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('seasonalFlu'));
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
        //render(<BillFeed />);
  
  
  
      }
  
  
  
  
  
  
  
  
  
  
  
  
      //inner html <br>
  
  
  
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
      //console.log(linkTo)
    }
  }

  export function variableTest()
{
 


  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {
    /*Three Branches */
    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/bills.xml');
  
    feed.items.forEach(item => {



      
       varTitle.push(item.title);
      
      
     
      

      





    });

    
    return (varTitle)

    
    






  })();
  
 
  }  

export function Speak(response) {


  if (speechSynthesis.speaking ==false) {
  speechSynthesis.speak(new SpeechSynthesisUtterance(response));
} else{

  speechSynthesis.cancel()

}
}
export function feedText()
{
 
    var billTitle = [];
    var billTitle2 = [];
    var billTitle3 = [];
    var secTitle = [];
    var doCTitle = [];
    var weatherTitle = [];
    var epaTitle = [];
    var doSTitle = [];
    var unTitle = [];
    var cdcTitle = [];
    var doHAndHTitle = [];
  
  
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
      /*Three Branches */
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/bills.xml');
      let feed2 = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.whitehouse.gov/feed/');
      let feed3 = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.uscourts.gov/news/rss');
      /*Economy */
      let sec = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.sec.gov/news/pressreleases.rss');
      let departmentOfCommerce = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.commerce.gov/feeds/news');
      /*Environment */
      let weather = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.weather.gov/rss_page.php?site_name=nws');
      let epa = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.epa.gov/newsreleases/search/rss');
       /*Foreign Affairs */
       let doS = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.state.gov/rss-feed/press-releases/feed/');
       let un = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://news.un.org/feed/subscribe/en/news/all/rss.xml');
       /*Health */
       let cdc = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/health-xml/cdc_newsroom.xml');
       let doHandH = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/health-xml/health_and_human_services.xml');
      feed.items.forEach(item => {
  
  
  
        
        billTitle.push(item.title.replace(/\&nbsp;/g, ''));
        
        
       
        
  
        
  
  
  
  
  
      });

      feed2.items.forEach(item => {
  
  
  
        
        billTitle2.push(item.title.replace(/\&nbsp;/g, ''));
        
        
       
        
  
        
  
  
  
  
  
      });

      feed3.items.forEach(item => {
  
  
  
        
        billTitle3.push(item.title.replace(/\&nbsp;/g, ''));
        
        
       
        
  
        
  
  
  
  
  
      });

      sec.items.forEach(item => {
  
        secTitle.push(item.title.replace(/\&nbsp;/g, ''));
      });

      departmentOfCommerce.items.forEach(item => {
  
        doCTitle.push(item.title.replace(/\&nbsp;/g, ''));
        
      });

      weather.items.forEach(item => {
  
        weatherTitle.push(item.title.replace(/\&nbsp;/g, ''));
      });

      epa.items.forEach(item => {
  
        epaTitle.push(item.title.replace(/\&nbsp;/g, ''));
      });

      doS.items.forEach(item => {
  
        doSTitle.push(item.title.replace(/\&nbsp;/g, ''));
      });

      un.items.forEach(item => {
  
        unTitle.push(item.title.replace(/\&nbsp;/g, ''));
      });
      cdc.items.forEach(item => {
  
        cdcTitle.push(item.title.replace(/\&nbsp;/g, ''));
      });
      doHandH.items.forEach(item => {
  
        doHAndHTitle.push(item.title.replace(/\&nbsp;/g, ''));
      });
      
  
  
      Speak("Today's recent Government updates. Congress introduced the bill, " + billTitle[0] + 
      ". The white house released the statement: " + billTitle2[0] + "... and US Courts published, " + billTitle3[0] +
      "..., Economy, S.E.C...., " + secTitle[0] + ', Department of Commerce..., ' + doSTitle[0] 
      + ",...Environment..., National Weather Service Update..., " + weatherTitle[0] + '..., EPA..., ' + epaTitle[0]
     + "..., Foreign Affairs...., Department of State...., " + doSTitle[0] + "..., United Nations....," + unTitle[0]
     + "...., Health...., CDC...., " + cdcTitle[0] + "...., Department of Health and Human Services...., " + doHAndHTitle[0])
      
  
  
  
  

    })();
  
 
  }


export function createHouseCommitteeOversightAndReform() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=GO00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on on Oversight and Reform", "Legislative", "N/A")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnOversightAndReform'));



      
















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createHouseCommitteeOnRules() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=RU00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
      
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Rules", "Legislative", "N/A")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnRules'));



   
















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createHouseCommitteeOnSmallBusiness() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=SM00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}

      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Small Business", "Legislative", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnSmallBusiness'));



    
















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createHouseCommitteeOnScienceSpaceAndTechnology() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=SY00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Science, Space, and Technology", "Legislative", "N/A")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnScienceSpaceAndTechnology'));



     













      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createCongressionalBudgetOffice() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const billContent = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.cbo.gov/publications/all/rss.xml');






    feed.items.forEach(item => {


      billContent.push(item.content);
      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
     //console.log(item.content)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
     
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "Congressional Budget Office", "Legislative", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('congressionalBudgetOffice'));



    
















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createHouseCommitteeOnTransportationAndInfrastructure() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=PW00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Transportation and Infrastructure", "Legislative", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnTransportationAndInfrastructure'));



      













      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}


export function createHouseCommitteeOnVeteransAffairs() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=VR00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Veterans Affairs", "Legislative", "N/A")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnVeteransAffairs'));



     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}



export function createHouseCommitteeOnAppropriations() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=AP00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Appropriations", "Legislative", "N/A")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnAppropriations'));




















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createHouseCommitteeOnArmedServices() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=AS00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      pubDate.push(item.pubDate
        .substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Armed Services", "Legislative", "Foreign Affairs")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseComitteeOnArmedServices'));





      














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createHouseCommitteeOnTheBudget() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=BU00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on the Budget", "Legislative", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnTheBudget'));





    














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createHouseCommitteeOnEducationAndLabor() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=ED00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
      <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Education and Labor", "Legislative", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnEducationAndLabor'));





      














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createHouseCommitteeOnEnergyAndCommerce() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=IF00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Energy and Commerce", "Legislative", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnEnergyAndCommmerce'));





 













      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createHouseCommitteeOnEthics() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=SO00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Ethics", "Legislative", "N/A")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnEthics'));




















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createHouseCommitteeOnFinancialServices() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=BA00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Financial Services", "Legislative", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnFinancialServices'));



      
















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createHouseCommitteeOnForeignAffairs() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=FA00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
      <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Foreign Affairs", "Legislative", "N/A")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnForeignAffairs'));



      
















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createHouseCommitteeOnHomelandSecurity() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=HM00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
      <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Homeland Security", "Legislative", "N/A")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnHomelandSecurity'));



      
















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createHouseCommitteeOnHouseAdministration() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=HA00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
      
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on House Administration", "Legislative", "N/A")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnHouseAdministration'));



    
















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createHouseCommitteeOnTheJudiciary() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=JU00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
     
     <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on the Judiciary", "Legislative", "N/A")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnTheJudiciary'));




















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createHouseCommitteeOnNaturalResources() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=II00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      <Card.Body id='billBodyText'>{pubDate[0]}
              </Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Natural Resources", "Legislative", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnNaturalResources'));



 
















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createHouseCommitteeOnNaturalResourcesMobile() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=II00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee on Natural Resources", "Legislative", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnNaturalResourceMobile'));



      
















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createEconomicIndicators() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/econi.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);

      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[billTitle.length -2]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[billTitle.length -2]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[billTitle.length - 2], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>
        <Button id='viewBillButton' onClick={(e) => {
              e.preventDefault();
              window.open(viewBillLink[billTitle.length - 2],"_blank")
            }}><FileText></FileText> Document</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[billTitle.length - 2], billLink[billTitle.length - 2], viewBillLink[billTitle.length - 2], pubDate[billTitle.length -2], null, "Economic Indicators", "N/A", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('economicIndicators'));





 














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createSecuritiesAndExchangeCommissionPressReleases() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.sec.gov/news/pressreleases.rss');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "SEC Press Releases", "N/A", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('secPR'));




















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}


export function createNationWeatherServices(){

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.weather.gov/rss_page.php?site_name=nws');






    feed.items.forEach(item => {

      



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
     
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]
      //console.log(billLink[index])



      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' ><Card.Title id='billText'>{billTitle[index]}</Card.Title>

        
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
<Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open("https://www.wpc.ncep.noaa.gov/discussions/hpcdiscussions.php?disc=pmdspd", "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, pubDate[0], null, "National Weather Service Updates", "N/A", "Environment")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('nws'));





      













      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createSecuritiesAndExchangeCommissionSpeechesAndStatements() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.sec.gov/news/speeches-statements.rss');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      

      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "SEC Speeches and Statements", "N/A", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('sec'));




















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createHouseCommitteeOnIntelligence()
{

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://docs.house.gov/Committee/RSS.ashx?Code=IG00');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "House Committee On Intelligence", "N/A", "Foreign Affairs")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('houseCommitteeOnIntelligence'));



  
















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

 export function createUNTopStories() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://news.un.org/feed/subscribe/en/news/all/rss.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
<Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "United Nations News", "N/A", "Foreign Affairs")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('unTopStories'));





     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createCourtAppealsFirstCircuit() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const pubDate = []
  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/uscourts-ca1.xml');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      feedContent.push(item.content);
    pubDate.push(item.pubDate.substring(0,17))
      feed.item = item.title + ':' + item.link;
      //console.log(item.content)

    });


    for (let index = billsFeed.length; index > -1; index--) {
      //console.log(index)
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
      const content = feedContent[index];
      //const linkChunk = links.toString().substring(36);

      const viewBillLink = "https://www.uscourts.gov/news/rss"



      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='viewBillButton' onClick={(e) => {

              e.preventDefault();
              window.open(
                "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), "_blank")
            }}><FileText></FileText> Document</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), pubDate[0], null, "Court of Appeals for the First Circuit", "Judicial", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('appealsFC')


      
      )

      

      //render(<BillFeed />);


     



    



    }

  })();





}






export function createCourtAppealsSecondCircuit() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const pubDate = []
  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/uscourts-ca2.xml');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      feedContent.push(item.content);
      feed.item = item.title + ':' + item.link;
      //console.log(item.content)
      pubDate.push(item.pubDate.substring(0,17))
    });


    for (let index = billsFeed.length; index > -1; index--) {
      //console.log(index)
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
      const content = feedContent[index];
      
      //const linkChunk = links.toString().substring(36);

      const viewBillLink = "https://www.uscourts.gov/news/rss"



      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='viewBillButton' onClick={(e) => {

              e.preventDefault();
              window.open(
                "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), "_blank")
            }}><FileText></FileText> Document</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), pubDate[0], null, "Court of Appeals for the Second Circuit", "Judicial", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('appealsSC')


      
      )

      

      //render(<BillFeed />);


     



    }

  })();





}

export function createCourtAppealsThirdCircuit() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const pubDate = []

  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/uscourts-ca3.xml');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      feedContent.push(item.content);
      feed.item = item.title + ':' + item.link;
      //console.log(item.content)
      pubDate.push(item.pubDate.substring(0,17))
    });


    for (let index = billsFeed.length; index > -1; index--) {
      //console.log(index)
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
      const content = feedContent[index];
      //const linkChunk = links.toString().substring(36);

      const viewBillLink = "https://www.uscourts.gov/news/rss"



      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='viewBillButton' onClick={(e) => {

              e.preventDefault();
              window.open(
                "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), "_blank")
            }}><FileText></FileText> Document</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), pubDate[0], null, "Court of Appeals for the Third Circuit", "Judicial", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('appealsTC')


      
      )

      

      //render(<BillFeed />);


     



    }

  })();





}

export function createCourtAppealsFourthCircuit() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const pubDate = []
  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/uscourts-ca4.xml');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      feedContent.push(item.content);
      feed.item = item.title + ':' + item.link;
      //console.log(item.content)
      pubDate.push(item.pubDate.substring(0,17))
    });


    for (let index = billsFeed.length; index > -1; index--) {
      //console.log(index)
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
      const content = feedContent[index];
      //const linkChunk = links.toString().substring(36);

      const viewBillLink = "https://www.uscourts.gov/news/rss"



      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='viewBillButton' onClick={(e) => {

              e.preventDefault();
              window.open(
                "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), "_blank")
            }}><FileText></FileText> Document</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), pubDate[0], null, "Court of Appeals for the Fourth Circuit", "Judicial", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('appealsFourthC')


      
      )

      

      //render(<BillFeed />);


     



    }

  })();





}

export function createCourtAppealsFifthCircuit() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const pubDate = []
  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/uscourts-ca5.xml');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      feedContent.push(item.content);
      feed.item = item.title + ':' + item.link;
      //console.log(item.content)
      pubDate.push(item.pubDate.substring(0,17))
    });


    for (let index = billsFeed.length; index > -1; index--) {
      //console.log(index)
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
      const content = feedContent[index];
      //const linkChunk = links.toString().substring(36);

      const viewBillLink = "https://www.uscourts.gov/news/rss"



      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='viewBillButton' onClick={(e) => {

              e.preventDefault();
              window.open(
                "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), "_blank")
            }}><FileText></FileText> Document</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), pubDate[0], null, "Court of Appeals for the Fifth Circuit", "Judicial", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('appealsFifthC')


      
      )

      

      //render(<BillFeed />);


    



    }

  })();





}

export function createCourtAppealsSixthCircuit() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const pubDate = []
  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/uscourts-ca6.xml');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      feedContent.push(item.content);
      feed.item = item.title + ':' + item.link;
      //console.log(item.content)
      pubDate.push(item.pubDate.substring(0,17))
    });


    for (let index = billsFeed.length; index > -1; index--) {
      //console.log(index)
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
      const content = feedContent[index];
      //const linkChunk = links.toString().substring(36);

      const viewBillLink = "https://www.uscourts.gov/news/rss"



      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='viewBillButton' onClick={(e) => {

              e.preventDefault();
              window.open(
                "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), "_blank")
            }}><FileText></FileText> Document</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), pubDate[0], null, "Court of Appeals for the Sixth Circuit", "Judicial", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('appealsSixthC')


      
      )

      

      //render(<BillFeed />);


     



    }

  })();





}

export function createCourtAppealsSeventhCircuit() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const pubDate = []
  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/uscourts-ca7.xml');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      feedContent.push(item.content);
      feed.item = item.title + ':' + item.link;
      //console.log(item.content)
      pubDate.push(item.pubDate.substring(0,17))
    });


    for (let index = billsFeed.length; index > -1; index--) {
      //console.log(index)
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
      const content = feedContent[index];
      //const linkChunk = links.toString().substring(36);

      const viewBillLink = "https://www.uscourts.gov/news/rss"



      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            
            
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='viewBillButton' onClick={(e) => {

              e.preventDefault();
              window.open(
                "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), "_blank")
            }}><FileText></FileText> Document</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), pubDate[0], null, "Court of Appeals for the Seventh Circuit", "Judicial", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('appealsSeventhC')


      
      )

      

      //render(<BillFeed />);


     



    }

  })();





}

export function createCourtAppealsEighthCircuit() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const pubDate = []
  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/uscourts-ca8.xml');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      feedContent.push(item.content);
      feed.item = item.title + ':' + item.link;
      //console.log(item.content)
      pubDate.push(item.pubDate.substring(0,17))
    });


    for (let index = billsFeed.length; index > -1; index--) {
      //console.log(index)
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
      const content = feedContent[index];
      //const linkChunk = links.toString().substring(36);

      const viewBillLink = "https://www.uscourts.gov/news/rss"



      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            
            
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='viewBillButton' onClick={(e) => {

              e.preventDefault();
              window.open(
                "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), "_blank")
            }}><FileText></FileText> Document</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), pubDate[0], null, "Court of Appeals for the Eighth Circuit", "Judicial", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('appealsEC')


      
      )

      

      //render(<BillFeed />);


    


    }

  })();





}

export function createCourtAppealsNinthCircuit() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const pubDate = []
  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/uscourts-ca9.xml');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      feedContent.push(item.content);
      feed.item = item.title + ':' + item.link;
      //console.log(item.content)
      pubDate.push(item.pubDate.substring(0,17))
    });


    for (let index = billsFeed.length; index > -1; index--) {
      //console.log(index)
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
      const content = feedContent[index];
      //const linkChunk = links.toString().substring(36);

      const viewBillLink = "https://www.uscourts.gov/news/rss"



      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='viewBillButton' onClick={(e) => {

              e.preventDefault();
              window.open(
                "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), "_blank")
            }}><FileText></FileText> Document</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), pubDate[0], null, "Court of Appeals for the Ninth Circuit", "Judicial", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('appealsNC')


      
      )

      

      //render(<BillFeed />);


     



    }

  })();





}

export function createCourtAppealsTenthCircuit() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const pubDate = []
  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/uscourts-ca10.xml');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      feedContent.push(item.content);
      feed.item = item.title + ':' + item.link;
      //console.log(item.content)
      pubDate.push(item.pubDate.substring(0,17))
    });


    for (let index = billsFeed.length; index > -1; index--) {
      //console.log(index)
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
      const content = feedContent[index];
      //const linkChunk = links.toString().substring(36);

      const viewBillLink = "https://www.uscourts.gov/news/rss"



      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='viewBillButton' onClick={(e) => {

              e.preventDefault();
              window.open(
                "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), "_blank")
            }}><FileText></FileText> Document</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), pubDate[0], null, "Court of Appeals for the Tenth Circuit", "Judicial", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('appealsTenthC')


      
      )

      

      //render(<BillFeed />);


      



    }

  })();





}

export function createCourtAppealsEleventhCircuit() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const pubDate = []
  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/uscourts-ca11.xml');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      feedContent.push(item.content);
      feed.item = item.title + ':' + item.link;
      //console.log(item.content)
      pubDate.push(item.pubDate.substring(0,17))
    });


    for (let index = billsFeed.length; index > -1; index--) {
      //console.log(index)
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
      const content = feedContent[index];
      //const linkChunk = links.toString().substring(36);

      const viewBillLink = "https://www.uscourts.gov/news/rss"



      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            
            
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='viewBillButton' onClick={(e) => {

              e.preventDefault();
              window.open(
                "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), "_blank")
            }}><FileText></FileText> Document</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), pubDate[0], null, "Court of Appeals for the Eleventh Circuit", "Judicial", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('appealsEleventhC')


      
      )

      

      //render(<BillFeed />);


     



    }

  })();





}

export function createCourtAppealsDCCircuit() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
const pubDate = []
  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/uscourts-cadc.xml');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      feedContent.push(item.content);
      feed.item = item.title + ':' + item.link;
      //console.log(item.content)
      pubDate.push(item.pubDate.substring(0,17))
    });


    for (let index = billsFeed.length; index > -1; index--) {
      //console.log(index)
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
      const content = feedContent[index];
      //const linkChunk = links.toString().substring(36);

      const viewBillLink = "https://www.uscourts.gov/news/rss"



      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            
            
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>

            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='viewBillButton' onClick={(e) => {

              e.preventDefault();
              window.open(
                "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), "_blank")
            }}><FileText></FileText> Document</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), pubDate[0], null, "Court of Appeals for the District of Columbia Circuit", "Judicial", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('appealsDC')


      
      )

      

      //render(<BillFeed />);


      



    }

  })();





}

export function createCourtAppealsFederalCircuit() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const pubDate = []
  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.govinfo.gov/rss/uscourts-ca13.xml');

    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      feedContent.push(item.content);
      feed.item = item.title + ':' + item.link;
      //console.log(item.content)
      pubDate.push(item.pubDate.substring(0,17))
    });


    for (let index = billsFeed.length; index > -1; index--) {
      //console.log(index)
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
      const content = feedContent[index];
      //const linkChunk = links.toString().substring(36);

      const viewBillLink = "https://www.uscourts.gov/news/rss"



      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            
            
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

            <Button id='viewBillButton' onClick={(e) => {

              e.preventDefault();
              window.open(
                "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), "_blank")
            }}><FileText></FileText> Document</Button>

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36)).concat("/pdf/").concat(billLink[index].toString().substring(36)).concat("-0.pdf"), pubDate[0], null, "Court of Appeals for the Federal Circuit", "Judicial", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('appealsFederalC')


      
      )

      

      //render(<BillFeed />);


     



    }

  })();





}





export function createAlaskaBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/AK_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('alaskaBills')


      
      )


      

    

    }

  })();





}

export function createArizonaBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/AL_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Arizona Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('arizonaBills')


      
      )


 


    

    }

  })();





}

export function createArkansasBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/AR_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('arkansasBills')


      
      )


  

    

    }

  })();





}

export function createCaliforniaBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/CA_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "California Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('californiaBills')


      
      )


      


    

    }

  })();





}

export function createColoradoBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/CO_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Colorado Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('coloradoBills')


      
      )


     


    

    }

  })();





}

export function createConnecticutBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/CT_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Connecticut Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('connecticutBills')


      
      )




    

    }

  })();





}

export function createDelawareBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/DE_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Delaware Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('delawareBills')


      
      )


    


    

    }

  })();





}

export function createFloridaBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/FL_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Florida Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('floridaBills')


      
      )


      


    

    }

  })();





}
export function createGeorgiaBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/GA_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Georgia Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('georgiaBills')


      
      )


      


    

    }

  })();





}

export function createHawaiiBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/HI_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Hawaii Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('hawaiiBills')


      
      )


    


    

    }

  })();





}

export function createIdahoBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/ID_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Idaho Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('idahoBills')


      
      )


      


    

    }

  })();





}

export function createIllinoisBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/IL_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Illinois Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('illinoisBills')


      
      )


     


    

    }

  })();





}

export function createIndianaBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/IN_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Indiana Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('indianaBills')


      
      )


      


    

    }

  })();





}

export function createIowaBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/IA_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Iowa Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('iowaBills')


      
      )


      


    

    }

  })();





}

export function createKansasBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/KS_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Kansas Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('kansasBills')


      
      )


    


    

    }

  })();





}

export function createKentuckyBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/KY_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Kentucky Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('kentuckyBills')


      
      )


      


    

    }

  })();





}

export function createLouisianaBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/LA_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Louisiana Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('louisianaBills')


      
      )


     


    

    }

  })();





}

export function createMaineBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/ME_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Maine Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('maineBills')


      
      )


  


    

    }

  })();





}

export function createMarylandBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/MD_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Maryland Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('marylandBills')


      
      )


      


    

    }

  })();





}

export function createMassachusettsBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/MA_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Massachusetts Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('massachusettsBills')


      
      )


   

    

    }

  })();





}

export function createMichiganBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/MI_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Michigan Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('michiganBills')


      
      )


     

    

    }

  })();





}

export function createMinnesotaBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/MN_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Minnesota Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('minnesotaBills')


      
      )


      

    

    }

  })();





}

export function createMississippiBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/MS_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Mississippi Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('mississippiBills')


      
      )


      


    

    }

  })();





}

export function createMissouriBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/MO_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Missouri Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('missouriBills')


      
      )


      


    

    }

  })();





}

export function createMontanaBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/MT_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Montana Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('montanaBills')


      
      )


      


    

    }

  })();





}

export function createNebraskaBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/NE_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Nebraska Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('nebraskaBills')


      
      )


     


    

    }

  })();





}

export function createNevadaBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/NV_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Nevada Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('nevadaBills')


      
      )


      


    

    }

  })();





}

export function createNewHampshireBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/NH_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "New Hampshire Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('newHampshireBills')


      
      )


    

    

    }

  })();





}

export function createNewJerseyBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/NJ_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "New Jersey Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('newJerseyBills')


      
      )


      

    

    }

  })();





}

export function createNewMexicoBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/NM_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "New Mexico Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('newMexicoBills')


      
      )


      


    

    }

  })();





}

export function createNewYorkBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/NY_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "New York Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('newYorkBills')


      
      )


      


    

    }

  })();





}

export function createNorthCarolinaBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/NC_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "North Carolina Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('northCarolinaBills')


      
      )


      


    

    }

  })();





}

export function createNorthDakotaBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/ND_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "North Dakota Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('northDakotaBills')


      
      )


      


    

    }

  })();





}

export function createOhioBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/OH_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Ohio Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('ohioBills')


      
      )


      


    

    }

  })();





}

export function createOklahomaBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/OK_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Oklahoma Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('oklahomaBills')


      
      )


   


    

    }

  })();





}

export function createOregonBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/OR_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Oregon Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('oregonBills')


      
      )


      


    

    }

  })();





}

export function createPennsylvaniaBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/PA_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Pennslyvania Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('pennsylvaniaBills')


      
      )


      


    

    }

  })();





}

export function createRhodeIslandBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/RI_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Rhode Island Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('rhodeIslandBills')


      
      )


      

    

    }

  })();





}

export function createSouthCarolinaBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/SC_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "South Carolina Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('southCarolinaBills')


      
      )


     

    

    }

  })();





}

export function createSouthDakotaBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/SD_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "South Dakota Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('southDakotaBills')


      
      )


      


    

    }

  })();





}

export function createTennesseeBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/TN_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Tennessee Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('tennesseeBills')


      
      )


      


    

    }

  })();





}

export function createTexasBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/TX_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Texas Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('texasBills')


      
      )


          


    

    }

  })();





}

export function createUtahBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/UT_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Utah Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('utahBills')


      
      )


     


    

    }

  })();





}

export function createVermontBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/VT_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Vermont Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('vermontBills')


      
      )


     


    

    }

  })();





}

export function createVirginiaBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/VA_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Virginia Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('virginiaBills')


      
      )


      


    

    }

  })();





}

export function createWashingtonBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/WA_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Washington Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('washingtonBills')


      
      )


     


    

    }

  })();





}

export function createWestVirginiaBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/WV_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "West Virginia Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('westVirginiaBills')


      
      )


      


    

    }

  })();





}

export function createWisconsinBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/WI_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Wisconsin Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('wisconsinBills')


      
      )


    


    

    }

  })();





}

export function createWyomingBills() {
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const feedContent = []
  const billDate = [];

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
      feed: ['data'],
    
      item:['row', 'openstates_url']
    }
    
  
  });

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/WY_json.xml');
 
    feed.data.row.forEach(row => {
//feed.items.forEach(item => {

//item.title ... etc yadda yadda yadda 
//})
    
      if (row.title.toString().length >= 180)
      {
              
              billTitle.push(row.title.toString().substring(0,177)+'...');
      }
           else{
               billTitle.push(row.title)
        }

      billLink.push(row.openstates_url);
      billDate.push(row.updated_at.toString().substring(0,10));
       
      
      
     

    });


    for (let index = billsFeed.length; index > -1; index--) {
      
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
    const date = billDate[index];
      
  

     
   


      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>

          

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, billDate[0], null, "Wyoming Bill Updates", "Legislative", "N/A")




            }><FolderPlus></FolderPlus></Button>



          </Container>

          <br></br>

        </div>, document.getElementById('wyomingBills')


      
      )


      

    

    }

  })();





}

export function createEIAPressReleases() {
  
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.eia.gov/rss/press_rss.xml');





    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[99]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView' >
        <Card.Title id='billText'>{billTitle[index]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[index], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], viewBillLink[index])




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('eia'));



















      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}






export function StateAlert() {
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <Alert id='statesAlert'  onClose={() => setShow(false)} dismissible>
      <Alert.Heading>More State News Coming Soon!</Alert.Heading>
 
    </Alert>
    );
  }

}


export function createFederalRegisterDefenseDepartment() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=defense-department');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "Federal Register Defense Department", "N/A", "Foreign Affairs")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('frDefense'));



     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createFederalRegisterStateDepartment() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=state-department');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "Federal Register State Department", "N/A", "Foreign Affairs")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('frState'));



     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createFederalRegisterTreasuryDepartment() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=treasury-department');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "Federal Register Treasury Department", "N/A", "Economy")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('frTreasury'));



     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createFederalRegisterJusticeDepartment() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=justice-department');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], viewBillLink[0])




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('frJustice'));



     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}


export function createFederalRegisterInteriorDepartment() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=interior-department');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], viewBillLink[0])




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('frInterior'));



     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}


export function createFederalRegisterAgricultureDepartment() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=agriculture-department');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], viewBillLink[0])




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('frAgriculture'));



     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}


export function createFederalRegisterCommerceDepartment() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=commerce-department');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], viewBillLink[0])




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('frCommerce'));



     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createFederalRegisterLaborDepartment() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=labor-department');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], viewBillLink[0])




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('frLabor'));



     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createFederalRegisterHealthAndHumanServicesDepartment() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=health-and-human-services-department');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], viewBillLink[0])




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('frHAHS'));



     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}


export function createFederalRegisterHousingAndUrbanDevelopmentDepartment() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=housing-and-urban-development-department');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], viewBillLink[0])




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('frHAUD'));



     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}


export function createFederalRegisterVeteransAffairsDepartment() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=veterans-affairs-department');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], viewBillLink[0])




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('frVeteransAffairs'));



     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}


export function createFederalRegisterHomelandSecurityDepartment() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=homeland-security-department');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], viewBillLink[0])




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('frHomelandSecurity'));



     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createFederalRegisterEducationDepartment() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=education-department');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], viewBillLink[0])




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('frEducation'));



     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}




export function createAlabamaAgencies() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/http://www.media.alabama.gov/rss/rss.aspx');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,10));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "Alabama Agencies", "Legislative", "N/A")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('alAgencies'));



     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createAlabamaSoS() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.sos.alabama.gov/newsroom/rss?_ga=2.44886628.1762786429.1626009166-1870547084.1626009166');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,10));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "Alabama Secretary of State", "Executive", "N/A")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('alabamaSoS'));



     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function createAlaskaGovernor() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/http://fetchrss.com/rss/5f8e367522b58768783d23926331ce34e302674ff00a77e2.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,10));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "Alaska Governor", "Executive", "N/A")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('alaskaGovernor'));



     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}




export function createFederalElectionCommissionNews() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/http://fetchrss.com/rss/5f8e367522b58768783d2392636be5a56ae84d1bcf0121d2.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,16));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "Federal Election Commission", "N/A", "Election")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('fec'));



     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}


export function createElectionAssistancCommissioneNews() {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];




  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/http://fetchrss.com/rss/5f8e367522b58768783d2392636be3fe9f9b8c1494779a12.xml');






    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,16));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));


      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])





    });

    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml

    //Titles

    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]




      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");

      //console.log(linkChunk)
      // viewBillLinkArray.push(F)




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "Election Assistance Commission", "N/A", "Election")




        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('eac'));



     














      //render(<BillFeed />);



    }












    //inner html <br>





  })();

  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
}

export function TemplateFunctionNoDocument(url, divID) {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];


  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);


    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      //console.log('ETHICS: ' + billTitle)
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,16));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;

    });


    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]

      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "Election Assistance Commission", "N/A", "Election")

        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById(divID.toString()));

    }


  })();

  function linkToBill() {
    const linkTo = billLink[0]

  }
}

export function createBIllItem(url, divID, feedName, feedBranch, feedTopic) {

  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];


  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);


    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
    
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,16));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;

    });


    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]


      //Details and Doc Button Check:
      //  if docButton == true
      //  render document button and value into collection
      //  if docButton == false 
      //  render what is below
    
   
      
              ReactDOM.render(
              
              <Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
            
      <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[0], "_blank")
            }}> <InfoCircle></InfoCircle> Details</Button>
      
  
      
              <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], "https://www.govinfo.gov/content/pkg/FR-"+billLink[0].toString().substring(42,46)+'-'+ billLink[0].toString().substring(47,49)+'-'+ billLink[0].toString().substring(50,52).concat('/pdf/')+ billLink[0].toString().substring(53,57)+'-'+billLink[0].toString().substring(58,63).concat('.pdf'), pubDate[0], null, feedName, feedBranch, feedTopic)
      
              }><FolderPlus></FolderPlus></Button></Container >, document.getElementById(divID.toString()));
    
                 
            
      
    
      
                 



   
        

   



 

  }


  })();

  function linkToBill() {
    const linkTo = billLink[0]

  }
}

export function createArmsControl() {


  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];
 
 
 
 
 
 
 
 
  let Parser = require('rss-parser');
  let parser = new Parser();
  (async () => {
 
 
    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.state.gov/rss-feed/arms-control-and-international-security/feed/');
 
 
 
 
 
 
 
 
 
 
 
 
    feed.items.forEach(item => {
 
 
 
 
 
 
      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
 
 
 
 
      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])
 
 
 
 
 
 
 
 
 
 
    });
 
 
    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
 
 
    //Titles
 
 
    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]
 
 
 
 
 
 
 
 
      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
 
 
      //console.log(linkChunk)
      // viewBillLinkArray.push(F)
 
 
 
 
 
 
 
 
      ReactDOM.render(<Container id='feedContainerNormalView'>
       
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>
 
 
        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "Arms Control", "N/A", "Foreign Affairs")
 
 
 
 
 
 
 
 
        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('armsControl'));
 
 
 
 
 
 
 
 
 
 
    
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
      //render(<BillFeed />);
 
 
 
 
 
 
    }
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
    //inner html <br>
 
 
 
 
 
 
 
 
 
 
  })();
 
 
  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
 }
 

 export function createCounterterrorism() {


  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];
 
 
 
 
 
 
 
 
  let Parser = require('rss-parser');
  let parser = new Parser();
 
 
  (async () => {
 
 
    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.state.gov/rss-feed/counterterrorism/feed/');
 
 
 
 
 
 
 
 
 
 
 
 
    feed.items.forEach(item => {
 
 
 
 
 
 
      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
 
 
 
 
      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])
 
 
 
 
 
 
 
 
 
 
    });
 
 
    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
 
 
    //Titles
 
 
    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]
 
 
 
 
 
 
 
 
      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
 
 
      //console.log(linkChunk)
      // viewBillLinkArray.push(F)
 
 
 
 
 
 
 
 
      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>
 
 
        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "Counterterrorism", "N/A", "Foreign Affairs")
 
 
 
 
 
 
 
 
        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('counterterrorism'));




         
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
      //render(<BillFeed />);
 
 
 
 
 
 
    }
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
    //inner html <br>
 
 
 
 
 
 
 
 
 
 
  })();
 
 
  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
 }


 export function createDemocracyHumanRightsLabor() {


  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];
 
 
 
 
 
 
 
 
  let Parser = require('rss-parser');
  let parser = new Parser();
 
 
  (async () => {
 
 
    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.state.gov/rss-feed/democracy-human-rights-and-labor/feed/');
 
 
 
 
 
 
 
 
 
 
 
 
    feed.items.forEach(item => {
 
 
 
 
 
 
      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
 
 
 
 
      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])
 
 
 
 
 
 
 
 
 
 
    });
 
 
    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
 
 
    //Titles
 
 
    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]
 
 
 
 
 
 
 
 
      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
 
 
      //console.log(linkChunk)
      // viewBillLinkArray.push(F)
 
 
 
 
 
 
 
 
      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>
 
 
        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "Democracy, Human Rights, and Labor", "N/A", "Foreign Affairs")
 
 
 
 
 
 
 
 
        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('democracyHumanRightsLabor'));
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
      //render(<BillFeed />);
 
 
 
 
 
 
    }
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
    //inner html <br>
 
 
 
 
 
 
 
 
 
 
  })();
 
 
  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
 }


 export function createDiplomaticSecurity() {


  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];
 
 
 
 
 
 
 
 
  let Parser = require('rss-parser');
  let parser = new Parser();
 
 
  (async () => {
 
 
    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.state.gov/rss-feed/diplomatic-security/feed/');
 
 
 
 
 
 
 
 
 
 
 
 
    feed.items.forEach(item => {
 
 
 
 
 
 
      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
 
 
 
 
      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])
 
 
 
 
 
 
 
 
 
 
    });
 
 
    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
 
 
    //Titles
 
 
    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]
 
 
 
 
 
 
 
 
      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
 
 
      //console.log(linkChunk)
      // viewBillLinkArray.push(F)
 
 
 
 
 
 
 
 
      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>
 
 
        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "Diplomatic Security", "N/A", "Foreign Affairs")
 
 
 
 
 
 
 
 
        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('diplomaticSecurity'));
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
      //render(<BillFeed />);
 
 
 
 
 
 
    }
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
    //inner html <br>
 
 
 
 
 
 
 
 
 
 
  })();
 
 
  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
 }


 export function createDoSPress() {


  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];
 
 
 
 
 
 
 
 
  let Parser = require('rss-parser');
  let parser = new Parser();
 
 
  (async () => {
 
 
    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.state.gov/rss-feed/department-press-briefings/feed/');
 
 
 
 
 
 
 
 
 
 
 
 
    feed.items.forEach(item => {
 
 
 
 
 
 
      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
 
 
 
 
      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])
 
 
 
 
 
 
 
 
 
 
    });
 
 
    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
 
 
    //Titles
 
 
    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]
 
 
 
 
 
 
 
 
      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
 
 
      //console.log(linkChunk)
      // viewBillLinkArray.push(F)
 
 
 
 
 
 
 
 
      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>
 
 
        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "Department of State Press Briefing", "N/A", "Foreign Affairs")
 
 
 
 
 
 
 
 
        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('dosPress'));
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
      //render(<BillFeed />);
 
 
 
 
 
 
    }
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
    //inner html <br>
 
 
 
 
 
 
 
 
 
 
  })();
 
 
  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
 }
 
 
 export function createUNJournal() {


  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];
  ;
 
 
 
 
 
 
 
 
  let Parser = require('rss-parser');
  let parser = new Parser();
 
 
  (async () => {
 
 
    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://undocs.org/rss/unjournal.xml');
 
 
 
 
 
 
 
 
 
 
 
 
    feed.items.forEach(item => {
 
 
 
 
     
      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.content.substring(225,265));
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
 
 
 
 
      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])
 
 
 
 
 
 
 
 
 
 
    });
 
 
    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
 
 
    //Titles
 
 
    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]
 
 
 
 
 
 
 
 
      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
     
      //console.log(linkChunk)
      // viewBillLinkArray.push(F)
 
 
 
 
 
 
 
 
      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>
 
 
        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "United Nations Journal", "N/A", "Foreign Affairs")
 
 
 
 
 
 
 
 
        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('unJournal'));
 
 
 
 
 
 
 
 
 
 
    
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
      //render(<BillFeed />);
 
 
 
 
 
 
    }
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
    //inner html <br>
 
 
 
 
 
 
 
 
 
 
  })();
 
 
  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
 }

 export function createUNNewsAmericas() {


  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];
 
 
 
 
 
 
 
 
  let Parser = require('rss-parser');
  let parser = new Parser();
 
 
  (async () => {
 
 
    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://news.un.org/feed/subscribe/en/news/region/americas/feed/rss.xml');
 
 
 
 
 
 
 
 
 
 
 
 
    feed.items.forEach(item => {
 
 
 
 
 
 
      billsFeed.push(item.title + ':' + item.link);
      billTitle.push(item.title);
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,17));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
 
 
 
 
      feed.item = item.title + ':' + item.link;
      //console.log(item.title);
      //console.log(billsFeed);
      //console.log(billLink[3])
 
 
 
 
 
 
 
 
 
 
    });
 
 
    //Remove: https://www.govinfo.gov/app/details/
    //   https://www.govinfo.gov/content/pkg/**Insert Chunk Here**/xml/**Insert Chunk Here**.xml
 
 
    //Titles
 
 
    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]
 
 
 
 
 
 
 
 
      //const linkChunk = links.toString().substring(36);
      //console.log(linkChunk)
      //const F = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/xml/").concat(linkChunk).concat(".xml");
 
 
      //console.log(linkChunk)
      // viewBillLinkArray.push(F)
 
 
 
 
 
 
 
 
      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{billTitle[0]}</Card.Title>
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>
 
 
        <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[0], billLink[0], null, pubDate[0], null, "United Nations News Americas", "N/A", "Foreign Affairs")
 
 
 
 
 
 
 
 
        }><FolderPlus></FolderPlus></Button></Container >, document.getElementById('unNewsAmericas'));
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
      //render(<BillFeed />);
 
 
 
 
 
 
    }
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
    //inner html <br>
 
 
 
 
 
 
 
 
 
 
  })();
 
 
  function linkToBill() {
    const linkTo = billLink[0]
    //console.log(linkTo)
  }
 }
 

 export function CreateFeedItem(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
  const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
  const [loading, setLoading] = useState(true);
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];
  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
    
    var searchItem = document.getElementById('noteModalFeedItem').value
  
       collectionCounter = localStorage.getItem('collectionNumber')
       //console.log(collectionCounter)
     collectionCounter++;
       //console.log(collectionCounter)
     
     var collectionTitles = []
      var collectionDetailLinks = []
     var collectionDocLinks = []
     var collectionNote = []
     
     //collectionTitles.push(saveCollectionTitle)
     //collectionDetailLinks.push(saveCollectionDetailLink)
     //collectionDocLinks.push(saveCollectionDocLink)
     
     
     
      
       
     
      
       
       var title = 'title'.concat(collectionCounter)
       var detailLink = 'detailLink'.concat(collectionCounter)
       var docLink = 'docLink'.concat(collectionCounter)
       var itemNote = 'itemNote'.concat(collectionCounter)
       var itemDate = 'itemDate'.concat(collectionCounter)
       var feedName = 'feedName'.concat(collectionCounter)
       var branchName = 'branchName'.concat(collectionCounter)
       var topicName = 'topicName'.concat(collectionCounter)
       var itemDescription = 'itemDescription'.concat(collectionCounter)
   
   
     
     
       localStorage.setItem(title,saveCollectionTitle)
       
       localStorage.setItem(detailLink,saveCollectionDetailLink)
      
       localStorage.setItem(docLink,saveCollectionDocLink)
       localStorage.setItem(itemNote,searchItem)
   
       localStorage.setItem(itemDate,saveCollectionDate)
       localStorage.setItem(feedName,saveCollectionFeedName)
       localStorage.setItem(branchName,saveCollectionBranchName)
       localStorage.setItem(topicName,saveCollectionTopicName)
   
       
     
       localStorage.setItem('collectionNumber', collectionCounter)
      
      
     
       window.location.reload()
  
       
  
  
  
  
  }

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
  
    
     
    }

    
  
  });

  (async () => {
    try{
    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);

 
    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
    
      billLink.push(item.link);
      pubDate.push(formatDate2(item.pubDate).toString().substring(0,16));

 
      feed.item = item.title + ':' + item.link;

    });

    


    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]


      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

<Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], null, pubDate[0], null, feedName, feedBranch, feedTopic)



} }><FolderPlus></FolderPlus></Button>

<Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                     <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                     <Modal.Body id='descriptionModalBody'>
                                 
                                 {pubDate[0]}

                    

                     <div id='fullBill'>


                <Form id='noteModalForm'>

<Form.Group id='noteModalForm'  className="mb-3" >





 


  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
    type="text"
    as={"textarea"}
    rows={2}
    
    />

    <br></br>

  


</Form.Group>
</Form>

<Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], null, pubDate[0], feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
Save To Collection
</Button>
</Modal.Footer> 

  </div>
  </Modal.Body>
                   </Modal>
        
        <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                          <FeedFunction />
                        </Modal>
        
        </Container >, document.getElementById(divID.toString()));
  }

  setLoading(false); // Set loading to false once data is processed
} catch (error) {
  console.error('Error fetching or processing data:', error);
  setLoading(false); // Set loading to false in case of an error
}
})();

if (loading) {
return ;
}


  function linkToBill() {
    const linkTo = billLink[0]

  }
}


export function CreateFeedItemFuckLife(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
  const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
  const [loading, setLoading] = useState(true);
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];
  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
    
    var searchItem = document.getElementById('noteModalFeedItem').value
  
       collectionCounter = localStorage.getItem('collectionNumber')
       //console.log(collectionCounter)
     collectionCounter++;
       //console.log(collectionCounter)
     
     var collectionTitles = []
      var collectionDetailLinks = []
     var collectionDocLinks = []
     var collectionNote = []
     
     //collectionTitles.push(saveCollectionTitle)
     //collectionDetailLinks.push(saveCollectionDetailLink)
     //collectionDocLinks.push(saveCollectionDocLink)
     
     
     
      
       
     
      
       
       var title = 'title'.concat(collectionCounter)
       var detailLink = 'detailLink'.concat(collectionCounter)
       var docLink = 'docLink'.concat(collectionCounter)
       var itemNote = 'itemNote'.concat(collectionCounter)
       var itemDate = 'itemDate'.concat(collectionCounter)
       var feedName = 'feedName'.concat(collectionCounter)
       var branchName = 'branchName'.concat(collectionCounter)
       var topicName = 'topicName'.concat(collectionCounter)
       var itemDescription = 'itemDescription'.concat(collectionCounter)
   
   
     
     
       localStorage.setItem(title,saveCollectionTitle)
       
       localStorage.setItem(detailLink,saveCollectionDetailLink)
      
       localStorage.setItem(docLink,saveCollectionDocLink)
       localStorage.setItem(itemNote,searchItem)
   
       localStorage.setItem(itemDate,saveCollectionDate)
       localStorage.setItem(feedName,saveCollectionFeedName)
       localStorage.setItem(branchName,saveCollectionBranchName)
       localStorage.setItem(topicName,saveCollectionTopicName)
   
       
     
       localStorage.setItem('collectionNumber', collectionCounter)
      
      
     
       window.location.reload()
  
       
  
  
  
  
  }

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{
  
    
     
    }

    
  
  });

  (async () => {
    try{
    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);


    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
    
      billLink.push(item.link);
      pubDate.push(item.pubDate.toString().substring(0,16));

 
      feed.item = item.title + ':' + item.link;

    });

    


    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]


      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

<Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], null, pubDate[0], null, feedName, feedBranch, feedTopic)



} }><FolderPlus></FolderPlus></Button>

<Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                     <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                     <Modal.Body id='descriptionModalBody'>
                                 
                                 {pubDate[0]}

                    

                     <div id='fullBill'>


                <Form id='noteModalForm'>

<Form.Group id='noteModalForm'  className="mb-3" >





 


  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
    type="text"
    as={"textarea"}
    rows={2}
    
    />

    <br></br>

  


</Form.Group>
</Form>

<Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], null, pubDate[0], feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
Save To Collection
</Button>
</Modal.Footer> 

  </div>
  </Modal.Body>
                   </Modal>
        
        <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                          <FeedFunction />
                        </Modal>
        
        </Container >, document.getElementById(divID.toString()));
  }

  setLoading(false); // Set loading to false once data is processed
} catch (error) {
  console.error('Error fetching or processing data:', error);
  setLoading(false); // Set loading to false in case of an error
}
})();

if (loading) {
return <div id='fullBillFeed'><Spinner animation="grow" variant="light" /></div>;
}


  function linkToBill() {
    const linkTo = billLink[0]

  }
}


{/*let parser = new Parser({
      customFields:{
        feed: ['data'],
      
        item:['row', 'openstates_url', 'updated_at', 'description']
      }
  
      
    
    }); */}




export function CreateFeedItemWithDocButton(url, divID, feedName, feedBranch, feedTopic,  FeedFunction) {
  const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)

  
function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
    
    var searchItem = document.getElementById('noteModalFeedItem').value
  
       collectionCounter = localStorage.getItem('collectionNumber')
       //console.log(collectionCounter)
     collectionCounter++;
       //console.log(collectionCounter)
     
     var collectionTitles = []
      var collectionDetailLinks = []
     var collectionDocLinks = []
     var collectionNote = []
     
     //collectionTitles.push(saveCollectionTitle)
     //collectionDetailLinks.push(saveCollectionDetailLink)
     //collectionDocLinks.push(saveCollectionDocLink)
     
     
     
      
       
     
      
       
       var title = 'title'.concat(collectionCounter)
       var detailLink = 'detailLink'.concat(collectionCounter)
       var docLink = 'docLink'.concat(collectionCounter)
       var itemNote = 'itemNote'.concat(collectionCounter)
       var itemDate = 'itemDate'.concat(collectionCounter)
       var feedName = 'feedName'.concat(collectionCounter)
       var branchName = 'branchName'.concat(collectionCounter)
       var topicName = 'topicName'.concat(collectionCounter)
       var itemDescription = 'itemDescription'.concat(collectionCounter)
   
   
     
     
       localStorage.setItem(title,saveCollectionTitle)
       
       localStorage.setItem(detailLink,saveCollectionDetailLink)
      
       localStorage.setItem(docLink,saveCollectionDocLink)
       localStorage.setItem(itemNote,searchItem)
   
       localStorage.setItem(itemDate,saveCollectionDate)
       localStorage.setItem(feedName,saveCollectionFeedName)
       localStorage.setItem(branchName,saveCollectionBranchName)
       localStorage.setItem(topicName,saveCollectionTopicName)
   
       localStorage.setItem(itemDescription,saveCollectionDescription)
     
       localStorage.setItem('collectionNumber', collectionCounter)
      
       
     
       window.location.reload()
  
       
  
  
  
  
  }


  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);


    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
    
      billLink.push(item.link);
      pubDate.push(item.pubDate.toString().substring(0,16));

   
    const linkChunk = item.link.toString().substring(36)
     viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;

    });


    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]


      //Details and Doc Button Check:
      //  if docButton == true
      //  render document button and value into collection
      //  if docButton == false 
      //  render what is below
   




      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>


<Button id='viewBillButton' onClick={(e) => {
          e.preventDefault();
            window.open(viewBillLink[0], "_blank")
          }}><FileText></FileText> Bill</Button>

<Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], viewBillLink[0], pubDate[0], null, feedName, feedBranch, feedTopic, "")



} }><FolderPlus></FolderPlus></Button>

<Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                     <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                     <Modal.Body id='descriptionModalBody'>
                                
                                 {pubDate[0]}

                    

                     <div id='fullBill'>


                <Form id='noteModalForm'>

<Form.Group id='noteModalForm'  className="mb-3" >





 


  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
    type="text"
    as={"textarea"}
    rows={2}
    
    />

    <br></br>

  


</Form.Group>
</Form>

<Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], viewBillLink[0], pubDate[0], feedName, feedBranch, feedTopic, "");setCollectionModalIsOpen(false) }} >
Save To Collection
</Button>
</Modal.Footer> 

  </div>
  </Modal.Body>
                   </Modal>

        
        <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                          <FeedFunction />
                        </Modal>
        
        </Container >, document.getElementById(divID.toString()));

        

      
      

  }


  })();

  function linkToBill() {
    const linkTo = billLink[0]

  }
}


export function CreateFeedItemWithLawButton(url, divID, feedName, feedBranch, feedTopic,  FeedFunction) {
  const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)

  
  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //console.log(collectionCounter)
       collectionCounter++;
         //console.log(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         localStorage.setItem(itemDescription,saveCollectionDescription)
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
         
       
         window.location.reload()
    
         
    
    
    
    
    }

  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);


    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
    
      billLink.push(item.link);
      pubDate.push(item.pubDate.toString().substring(0,16));

   
    const linkChunk = item.link.toString().substring(36)
     viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;

    });


    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]



      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>


<Button id='viewBillButton' onClick={(e) => {
          e.preventDefault();
            window.open(viewBillLink[0], "_blank")
          }}><FileText></FileText> Law</Button>

<Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], viewBillLink[0], pubDate[0], null, feedName, feedBranch, feedTopic, "")



} }><FolderPlus></FolderPlus></Button>

<Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                     <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                     <Modal.Body id='descriptionModalBody'>
                                
                                 {pubDate[0]}

                    

                     <div id='fullBill'>


                <Form id='noteModalForm'>

<Form.Group id='noteModalForm'  className="mb-3" >





 


  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
    type="text"
    as={"textarea"}
    rows={2}
    
    />

    <br></br>

  


</Form.Group>
</Form>

<Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], viewBillLink[0], pubDate[0], feedName, feedBranch, feedTopic, "");setCollectionModalIsOpen(false) }} >
Save To Collection
</Button>
</Modal.Footer> 

  </div>
  </Modal.Body>
                   </Modal>
        
        <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                          <FeedFunction />
                        </Modal>
        
        </Container >, document.getElementById(divID.toString()));


  }


  })();

  function linkToBill() {
    const linkTo = billLink[0]

  }
}

{/*Unique #1 */}
export function CreateFeedItemGAOReports(url, divID, feedName, feedBranch, feedTopic,  FeedFunction) {
  const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)

  
  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //console.log(collectionCounter)
       collectionCounter++;
         //console.log(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         localStorage.setItem(itemDescription,saveCollectionDescription)
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
         
       
         window.location.reload()
    
         
    
    
    
    
    }

  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);


    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
    
      billLink.push(item.link);
      pubDate.push(item.pubDate.toString().substring(0,16));
      const linkChunk = item.link.toString().substring(29)
      viewBillLink.push("https://www.gao.gov/assets/".concat(linkChunk).concat(".pdf"));

   
    
      feed.item = item.title + ':' + item.link;

    });


    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]



      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>


<Button id='viewBillButton' onClick={(e) => {
          e.preventDefault();
            window.open(viewBillLink[0], "_blank")
          }}><FileText></FileText> Report</Button>

<Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], viewBillLink[0], pubDate[0], null, feedName, feedBranch, feedTopic, "")



} }><FolderPlus></FolderPlus></Button>

<Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                     <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                     <Modal.Body id='descriptionModalBody'>
                                
                                 {pubDate[0]}

                    

                     <div id='fullBill'>


                <Form id='noteModalForm'>

<Form.Group id='noteModalForm'  className="mb-3" >





 


  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
    type="text"
    as={"textarea"}
    rows={2}
    
    />

    <br></br>

  


</Form.Group>
</Form>

<Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], viewBillLink[0], pubDate[0], feedName, feedBranch, feedTopic, "");setCollectionModalIsOpen(false) }} >
Save To Collection
</Button>
</Modal.Footer> 

  </div>
  </Modal.Body>
                   </Modal>
        
        <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                          <FeedFunction />
                        </Modal>
        
        </Container >, document.getElementById(divID.toString()));

        

      
      

  }


  })();

  function linkToBill() {
    const linkTo = billLink[0]

  }
}

{/*Unique #2 */}
export function CreateFeedItemCongressionalReports(url, divID, feedName, feedBranch, feedTopic,  FeedFunction) {
  const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)

  
  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //console.log(collectionCounter)
       collectionCounter++;
         //console.log(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         localStorage.setItem(itemDescription,saveCollectionDescription)
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
         
       
         window.location.reload()
    
         
    
    
    
    
    }

  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);


    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
    
      billLink.push(item.link);
      pubDate.push(item.pubDate.toString().substring(0,16));
      const linkChunk = item.link.toString().substring(36)
      viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

   
    
      feed.item = item.title + ':' + item.link;

    });


    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]



      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>


<Button id='viewBillButton' onClick={(e) => {
          e.preventDefault();
            window.open(viewBillLink[0], "_blank")
          }}><FileText></FileText> Report</Button>

<Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], viewBillLink[0], pubDate[0], null, feedName, feedBranch, feedTopic, "")



} }><FolderPlus></FolderPlus></Button>

<Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                     <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                     <Modal.Body id='descriptionModalBody'>
                                
                                 {pubDate[0]}

                    

                     <div id='fullBill'>


                <Form id='noteModalForm'>

<Form.Group id='noteModalForm'  className="mb-3" >





 


  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
    type="text"
    as={"textarea"}
    rows={2}
    
    />

    <br></br>

  


</Form.Group>
</Form>

<Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], viewBillLink[0], pubDate[0], feedName, feedBranch, feedTopic, "");setCollectionModalIsOpen(false) }} >
Save To Collection
</Button>
</Modal.Footer> 

  </div>
  </Modal.Body>
                   </Modal>
        
        <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                          <FeedFunction />
                        </Modal>
        
        </Container >, document.getElementById(divID.toString()));

        

      
      

  }


  })();

  function linkToBill() {
    const linkTo = billLink[0]

  }
}

{/*Unique #3*/}
export function CreateFeedItemPresidentialDocuments(url, divID, feedName, feedBranch, feedTopic,  FeedFunction) {
  const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)

  
  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //(collectionCounter)
       collectionCounter++;
         //(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         localStorage.setItem(itemDescription,saveCollectionDescription)
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
         
       
         
    
         
    
    
    
    
    }
  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);


    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}

billTitle.reverse()
    
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,16));
      pubDate.reverse()
      billLink.reverse()
  


    
      feed.item = item.title + ':' + item.link;

    });


    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]

      const linkChunk = links.toString().substring(36);
 
  
  
      const viewDocLink = "https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf")
      //(viewDocLink)
      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText' onClick={(e) => {
        e.preventDefault();
          window.open("https://www.govinfo.gov/content/pkg/".concat(billLink[0].toString().substring(36)).concat("/pdf/").concat(billLink[0].toString().substring(36)).concat(".pdf"), "_blank")
        }}>{billTitle[0]}</Card.Title>


      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>


<Button id='viewBillButton' onClick={(e) => {
          e.preventDefault();
            window.open("https://www.govinfo.gov/content/pkg/".concat(billLink[0].toString().substring(36)).concat("/pdf/").concat(billLink[0].toString().substring(36)).concat(".pdf"), "_blank")
          }}><FileText></FileText> Document</Button>

<Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], viewDocLink, pubDate[0], '', feedName, feedBranch, feedTopic, "")



} }><FolderPlus></FolderPlus></Button>

<Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                     <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                     <Modal.Body id='descriptionModalBody'>
                                
                                 {pubDate[0]}

                    

                     <div id='fullBill'>


                <Form id='noteModalForm'>

<Form.Group id='noteModalForm'  className="mb-3" >





 


  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
    type="text"
    as={"textarea"}
    rows={2}
    
    />

    <br></br>

  


</Form.Group>
</Form>

<Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], viewDocLink, pubDate[0], feedName, feedBranch, feedTopic, "");setCollectionModalIsOpen(false) }} >
Save To Collection
</Button>
</Modal.Footer> 

  </div>
  </Modal.Body>
                   </Modal>
        
        <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                          <FeedFunction />
                        </Modal>
        
        </Container >, document.getElementById(divID.toString()));


  }


  })();

  function linkToBill() {
    const linkTo = billLink[0]

  }
}

{/*Unique #4 - Works for both Presidential Feeds (Executive Orders and Presidential Proclamations */}
export function CreateFeedItemExecutiveOrders(url, divID, feedName, feedBranch, feedTopic,  FeedFunction) {
  const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)

  
  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //(collectionCounter)
       collectionCounter++;
         //(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         localStorage.setItem(itemDescription,saveCollectionDescription)
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
         
       
         
    
         
    
    
    
    
    }

  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);


    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
    
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,16));
    
     
  


    
      feed.item = item.title + ':' + item.link;

    });


    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]

     
 
  
  
      
      //(viewDocLink)
      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'onClick={(e) => {
        e.preventDefault();
          window.open("https://www.govinfo.gov/content/pkg/FR-"+billLink[0].toString().substring(42,46)+'-'+ billLink[0].toString().substring(47,49)+'-'+ billLink[0].toString().substring(50,52).concat('/pdf/')+ billLink[0].toString().substring(53,57)+'-'+billLink[0].toString().substring(58,63).concat('.pdf'), "_blank")
        }}>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
       window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>




<Button id='viewBillButton' onClick={(e) => {
          e.preventDefault();
            window.open("https://www.govinfo.gov/content/pkg/FR-"+billLink[0].toString().substring(42,46)+'-'+ billLink[0].toString().substring(47,49)+'-'+ billLink[0].toString().substring(50,52).concat('/pdf/')+ billLink[0].toString().substring(53,57)+'-'+billLink[0].toString().substring(58,63).concat('.pdf'), "_blank")
          }}><FileText></FileText> Document</Button>

<Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], "https://www.govinfo.gov/content/pkg/FR-"+billLink[0].toString().substring(42,46)+'-'+ billLink[0].toString().substring(47,49)+'-'+ billLink[0].toString().substring(50,52).concat('/pdf/')+ billLink[0].toString().substring(53,57)+'-'+billLink[0].toString().substring(58,63).concat('.pdf'), pubDate[0], '', feedName, feedBranch, feedTopic, "")



} }><FolderPlus></FolderPlus></Button>

<Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                     <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                     <Modal.Body id='descriptionModalBody'>
                                
                                 {pubDate[0]}

                    

                     <div id='fullBill'>


                <Form id='noteModalForm'>

<Form.Group id='noteModalForm'  className="mb-3" >





 


  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
    type="text"
    as={"textarea"}
    rows={2}
    
    />

    <br></br>

  


</Form.Group>
</Form>

<Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], "https://www.govinfo.gov/content/pkg/FR-"+billLink[0].toString().substring(42,46)+'-'+ billLink[0].toString().substring(47,49)+'-'+ billLink[0].toString().substring(50,52).concat('/pdf/')+ billLink[0].toString().substring(53,57)+'-'+billLink[0].toString().substring(58,63).concat('.pdf'), pubDate[0], feedName, feedBranch, feedTopic, "");setCollectionModalIsOpen(false) }} >
Save To Collection
</Button>
</Modal.Footer> 

  </div>
  </Modal.Body>
                   </Modal>
        
        <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                          <FeedFunction />
                        </Modal>
        
        </Container >, document.getElementById(divID.toString()));

        

      
      

  }


  })();

  function linkToBill() {
    const linkTo = billLink[0]

  }
}


{/*Unique #5*/}
export function CreateFeedItemCourtAppeals(url, divID, feedName, feedBranch, feedTopic,  FeedFunction) {
  const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)

  
  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //(collectionCounter)
       collectionCounter++;
         //(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         localStorage.setItem(itemDescription,saveCollectionDescription)
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
         
       
         
    
         
    
    
    
    
    }

  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);


    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
    
      billLink.push(item.link);
      pubDate.push(formatDate2(item.pubDate));

   
    const linkChunk = item.link.toString().substring(36)
     viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf//"));

      feed.item = item.title + ':' + item.link;

    });


    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]


      //Details and Doc Button Check:
      //  if docButton == true
      //  render document button and value into collection
      //  if docButton == false 
      //  render what is below
   



      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'onClick={(e) => {
        e.preventDefault();
          window.open("https://www.govinfo.gov/content/pkg/".concat(billLink[billsFeed.length-1].toString().substring(66)).concat(billLink[billsFeed.length-1].toString().substring(36,58)).concat("/pdf/").concat(billLink[billsFeed.length-1].toString().substring(36,58)).concat("-0.pdf"), "_blank")
        }}>{billTitle[billsFeed.length-1]}</Card.Title>

      
<Card.Body id='billBodyText'>{pubDate[billsFeed.length-1]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[billsFeed.length-1], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>


<Button id='viewBillButton' onClick={(e) => {
          e.preventDefault();
            window.open("https://www.govinfo.gov/content/pkg/".concat(billLink[billsFeed.length-1].toString().substring(66)).concat(billLink[billsFeed.length-1].toString().substring(36,58)).concat("/pdf/").concat(billLink[billsFeed.length-1].toString().substring(36,58)).concat("-0.pdf"), "_blank")
          }}><FileText></FileText> Document</Button>

        
<Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[billsFeed.length-1], billLink[billsFeed.length-1],"https://www.govinfo.gov/content/pkg/".concat(billLink[0].toString().substring(66)).concat(billLink[0].toString().substring(36,58)).concat("/pdf/").concat(billLink[billsFeed.length-1].toString().substring(36,58)).concat("-0.pdf") , pubDate[0], '', feedName, feedBranch, feedTopic, "")



} }><FolderPlus></FolderPlus></Button>

<Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                     <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[billsFeed.length-1]}</h3></Modal.Header>
                     <Modal.Body id='descriptionModalBody'>
                                
                                 {pubDate[billsFeed.length-1]}

                    

                     <div id='fullBill'>


                <Form id='noteModalForm'>

<Form.Group id='noteModalForm'  className="mb-3" >





 


  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
    type="text"
    as={"textarea"}
    rows={2}
    
    />

    <br></br>

  


</Form.Group>
</Form>

<Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[billsFeed.length-1], billLink[billsFeed.length-1], "https://www.govinfo.gov/content/pkg/".concat(billLink[billsFeed.length-1].toString().substring(66)).concat(billLink[billsFeed.length-1].toString().substring(36,58)).concat("/pdf/").concat(billLink[billsFeed.length-1].toString().substring(36,58)).concat("-0.pdf"), pubDate[0], feedName, feedBranch, feedTopic, "");setCollectionModalIsOpen(false) }} >
Save To Collection
</Button>
</Modal.Footer> 

  </div>
  </Modal.Body>
                   </Modal>
        
        <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                          <FeedFunction />
                        </Modal>
        
        </Container >, document.getElementById(divID.toString()));

        

      
      

  }


  })();

  function linkToBill() {
    const linkTo = billLink[0]

  }
}

{/*Unique #6*/}
//https://www.govinfo.gov/rss/econi.xml
export function CreateFeedItemEconomicIndicators(url, divID, feedName, feedBranch, feedTopic,  FeedFunction) {
  const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)

  
  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //console.log(collectionCounter)
       collectionCounter++;
         //console.log(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         localStorage.setItem(itemDescription,saveCollectionDescription)
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
         
       
         window.location.reload()
    
         
    
    
    
    
    }

  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);


    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
    
      billLink.push(item.link);
      pubDate.push(item.pubDate.substring(0,16));

   
    const linkChunk = item.link.toString().substring(36)
     viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));

      feed.item = item.title + ':' + item.link;

    });


    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]



      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>


<Button id='viewBillButton' onClick={(e) => {
          e.preventDefault();
            window.open(viewBillLink[0], "_blank")
          }}><FileText></FileText> Document</Button>

<Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0],viewBillLink[0] , pubDate[0], null, feedName, feedBranch, feedTopic, "")



} }><FolderPlus></FolderPlus></Button>

<Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                     <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                     <Modal.Body id='descriptionModalBody'>
                                
                                 {pubDate[0]}

                    

                     <div id='fullBill'>


                <Form id='noteModalForm'>

<Form.Group id='noteModalForm'  className="mb-3" >





 


  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
    type="text"
    as={"textarea"}
    rows={2}
    
    />

    <br></br>

  


</Form.Group>
</Form>

<Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], viewBillLink[0], pubDate[0], feedName, feedBranch, feedTopic, "");setCollectionModalIsOpen(false) }} >
Save To Collection
</Button>
</Modal.Footer> 

  </div>
  </Modal.Body>
                   </Modal>
        
        <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                          <FeedFunction />
                        </Modal>
        
        </Container >, document.getElementById(divID.toString()));

        

      
      

  }


  })();

  function linkToBill() {
    const linkTo = billLink[0]

  }
}

export function CreateFeedItemBillUpdates(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
  const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
  const [billDescriptionModalIsOpen, setBillDescriptionModalIsOpen] = useState(false)
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];
  const billDescription = [];
  const billDocumentLink = [];
  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
    
    var searchItem = document.getElementById('noteModalFeedItem').value
  
       collectionCounter = localStorage.getItem('collectionNumber')
       //console.log(collectionCounter)
     collectionCounter++;
       //console.log(collectionCounter)
     
     var collectionTitles = []
      var collectionDetailLinks = []
     var collectionDocLinks = []
     var collectionNote = []
     
     //collectionTitles.push(saveCollectionTitle)
     //collectionDetailLinks.push(saveCollectionDetailLink)
     //collectionDocLinks.push(saveCollectionDocLink)
     
     
     
      
       
     
      
       
       var title = 'title'.concat(collectionCounter)
       var detailLink = 'detailLink'.concat(collectionCounter)
       var docLink = 'docLink'.concat(collectionCounter)
       var itemNote = 'itemNote'.concat(collectionCounter)
       var itemDate = 'itemDate'.concat(collectionCounter)
       var feedName = 'feedName'.concat(collectionCounter)
       var branchName = 'branchName'.concat(collectionCounter)
       var topicName = 'topicName'.concat(collectionCounter)
       var itemDescription = 'itemDescription'.concat(collectionCounter)
   
   
     
     
       localStorage.setItem(title,saveCollectionTitle)
       
       localStorage.setItem(detailLink,saveCollectionDetailLink)
      
       localStorage.setItem(docLink,saveCollectionDocLink)
       localStorage.setItem(itemNote,searchItem)
   
       localStorage.setItem(itemDate,saveCollectionDate)
       localStorage.setItem(feedName,saveCollectionFeedName)
       localStorage.setItem(branchName,saveCollectionBranchName)
       localStorage.setItem(topicName,saveCollectionTopicName)
   
       localStorage.setItem(itemDescription,saveCollectionDescription)
     
       localStorage.setItem('collectionNumber', collectionCounter)
      
       function saveNote() {
     
       window.location.reload()
  
       }
  
  
  
  
  }

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{


      item:['row',  'description', 'documentLink']
    }

  });
  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);


    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
    
      billLink.push(item.link);
      pubDate.push(item.pubDate.toString().substring(0,10));
      billDescription.push(item.description)
     
    const linkChunk = item.link.toString().substring(36)
     viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
    billDocumentLink.push(item.documentLink)
      feed.item = item.title + ':' + item.link;

    });


    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
      const viewBillDetails = viewBillLink[index]
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]

      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[0], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

<Button id='viewBillButton' onClick={(e) => {
          e.preventDefault();
            window.open(billDocumentLink[0], "_blank")
          }}><FileText></FileText> Bill</Button>


<Button id='viewBillButton' onClick={() => setBillDescriptionModalIsOpen(true)
              }><FileText></FileText> Description</Button>

<Modal id='fullBillModal' show={billDescriptionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillDescriptionModalIsOpen(false)}>
                              <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]} Description</h3></Modal.Header>
                              <Modal.Body id='descriptionModalBody'>
                                          {billDescription[0]}

                              </Modal.Body>

                            </Modal>

        <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], billDocumentLink[0], pubDate[0], null, feedName, feedBranch, feedTopic, billDescription[0])



         } }><FolderPlus></FolderPlus></Button>
 
<Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                              <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                              <Modal.Body id='descriptionModalBody'>
                                          {billDescription[0]}
                                          {pubDate[0]}

                             

                              <div id='fullBill'>


                         <Form id='noteModalForm'>
        
         <Form.Group id='noteModalForm'  className="mb-3" >





          


           <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
             type="text"
             as={"textarea"}
             rows={2}
             
             />

             <br></br>

           


         </Form.Group>
         </Form>

         <Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], billDocumentLink[0], pubDate[0], feedName, feedBranch, feedTopic, billDescription[0]);setCollectionModalIsOpen(false) }} >
    Save To Collection
  </Button>
  </Modal.Footer> 

           </div>
           </Modal.Body>
                            </Modal>
        
        <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                          <FeedFunction />
                        </Modal>
        
        </Container >, document.getElementById(divID.toString()));

        

      
      

  }


  })();

  function linkToBill() {
    const linkTo = billLink[0]

  }
}

export function CreateCourtAppealsCircuit(url, divID, feedName, feedBranch, feedTopic,  FeedFunction) {
  const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
  const [billDescriptionModalIsOpen, setBillDescriptionModalIsOpen] = useState(false)
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];
  const viewBillLink = [];
  const pubDate = [];
  const billDescription = [];
  const billDocumentLink = [];
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)

  
  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //console.log(collectionCounter)
       collectionCounter++;
         //console.log(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         localStorage.setItem(itemDescription,saveCollectionDescription)
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
         
       
         window.location.reload()
    
         
    
    
    
    
    }
  let Parser = require('rss-parser');
  let parser = new Parser();

  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
    feed.items.forEach(item => {

      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
      billLink.push(item.link);
     
    pubDate.push(formatDate2(item.pubDate))
      feed.item = item.title + ':' + item.link;
      //console.log(item.content)

    });


    for (let index = billsFeed.length; index > -1; index--) {
      //console.log(index)
      const titles = billTitle[index];
      const titles2 = billTitle[index+1];
      const links = billLink[index];
     
      //const linkChunk = links.toString().substring(36);

      const viewBillLink = "https://www.uscourts.gov/news/rss"



      ReactDOM.render(
        <div id='legislativeBills'>
          <Container id='feedContainerNormalView' >

            <Card.Title id='billText'>{titles}</Card.Title>
            
<Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
            
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>

<Button id='viewBillButton' onClick={(e) => {

e.preventDefault();
window.open(
  "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36,billLink[index].length-8)).concat("/pdf/").concat(billLink[index].toString().substring(36,billLink[index].length-8)).concat("-0.pdf"), "_blank")
}}><FileText></FileText> Document</Button>

<Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true)



} }><FolderPlus></FolderPlus></Button>

<Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                     <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                     <Modal.Body id='descriptionModalBody'>
                                
                                 {pubDate[0]}

                    

                     <div id='fullBill'>


                <Form id='noteModalForm'>

<Form.Group id='noteModalForm'  className="mb-3" >





 


  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
    type="text"
    as={"textarea"}
    rows={2}
    
    />

    <br></br>

  


</Form.Group>
</Form>

<Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], "https://www.govinfo.gov/content/pkg/".concat(billLink[index].toString().substring(36,billLink[index].length-8)).concat("/pdf/").concat(billLink[index].toString().substring(36,billLink[index].length-8)).concat("-0.pdf"), pubDate[0], feedName, feedBranch, feedTopic, "");setCollectionModalIsOpen(false) }} >
Save To Collection
</Button>
</Modal.Footer> 

  </div>
  </Modal.Body>
                   </Modal>

<Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                  <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                  <FeedFunction />
                </Modal>
          



          </Container>

          <br></br>

        </div>, document.getElementById(divID.toString())


      
      )

      

      //render(<BillFeed />);


     



    



    }

  })();


 


}


export function CreateFeedItemStateBills(url, divID, feedName, feedBranch, feedTopic,  FeedFunction) {
  const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
  const [billDescriptionModalIsOpen, setBillDescriptionModalIsOpen] = useState(false)
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
  const billsFeed = [];
  const billTitle = [];
  const billLink = [];

  const pubDate = [];
  const billDescription = [];
  const billDocumentLink = [];
  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
    
    var searchItem = document.getElementById('noteModalFeedItem').value
  
       collectionCounter = localStorage.getItem('collectionNumber')
       //console.log(collectionCounter)
     collectionCounter++;
       //console.log(collectionCounter)
     
     var collectionTitles = []
      var collectionDetailLinks = []
     var collectionDocLinks = []
     var collectionNote = []
     
     //collectionTitles.push(saveCollectionTitle)
     //collectionDetailLinks.push(saveCollectionDetailLink)
     //collectionDocLinks.push(saveCollectionDocLink)
     
     
     
      
       
     
      
       
       var title = 'title'.concat(collectionCounter)
       var detailLink = 'detailLink'.concat(collectionCounter)
       var docLink = 'docLink'.concat(collectionCounter)
       var itemNote = 'itemNote'.concat(collectionCounter)
       var itemDate = 'itemDate'.concat(collectionCounter)
       var feedName = 'feedName'.concat(collectionCounter)
       var branchName = 'branchName'.concat(collectionCounter)
       var topicName = 'topicName'.concat(collectionCounter)
       var itemDescription = 'itemDescription'.concat(collectionCounter)
   
   
     
     
       localStorage.setItem(title,saveCollectionTitle)
       
       localStorage.setItem(detailLink,saveCollectionDetailLink)
      
       localStorage.setItem(docLink,saveCollectionDocLink)
       localStorage.setItem(itemNote,searchItem)
   
       localStorage.setItem(itemDate,saveCollectionDate)
       localStorage.setItem(feedName,saveCollectionFeedName)
       localStorage.setItem(branchName,saveCollectionBranchName)
       localStorage.setItem(topicName,saveCollectionTopicName)
   
       localStorage.setItem(itemDescription,saveCollectionDescription)
     
       localStorage.setItem('collectionNumber', collectionCounter)
      
       function saveNote() {
     
       window.location.reload()
  
       }
  
  
  
  
  }

  let Parser = require('rss-parser');
  let parser = new Parser({
    customFields:{


      item:['description', 'openstate_url', 'state_url']
    }

  });
  (async () => {

    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);


    feed.items.forEach(item => {



      billsFeed.push(item.title + ':' + item.link);
          if (item.title.length >= 180)
        {
          billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
        }
         else{
          billTitle.push(item.title.replace(/\&nbsp;/g, ''))
}
    
      billLink.push(item.openstate_url);
      pubDate.push(item.pubDate.substring(0,10));
      billDescription.push(item.description)
     

     
    billDocumentLink.push(item.state_url)
      feed.item = item.title + ':' + item.link;

    });


    for (let index = 0; index < billsFeed.length; index++) {
      const titles = billTitle[index];
      const links = billLink[index];
   
      const date = pubDate[index]
      const dateTwo = pubDate[98]
      const dateThree = pubDate[98]

      ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[billsFeed.length-1]}</Card.Title>
      
<Card.Body id='billBodyText'>{pubDate[billsFeed.length-1]}</Card.Body>
      <Button id='billLink' onClick={(e) => {
        e.preventDefault();
        window.open(billLink[billsFeed.length-1], "_blank")
      }}> <InfoCircle></InfoCircle> Details</Button>

<Button id='viewBillButton' onClick={(e) => {
          e.preventDefault();
            window.open(billDocumentLink[billsFeed.length-1], "_blank")
          }}><FileText></FileText> Bill</Button>


<Button id='viewBillButton' onClick={() => setBillDescriptionModalIsOpen(true)
              }><FileText></FileText> Description</Button>

<Modal id='fullBillModal' show={billDescriptionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillDescriptionModalIsOpen(false)}>
                              <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[billsFeed.length-1]} </h3></Modal.Header>
                              <Modal.Body id='descriptionModalBody'>Description:
                              {<br></br>}
                                          {billDescription[billsFeed.length-1]}

                              </Modal.Body>

                            </Modal>

        <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], billDocumentLink[0], pubDate[0], null, feedName, feedBranch, feedTopic, billDescription[0])



         } }><FolderPlus></FolderPlus></Button>
         <br></br>

<Button  id='statesViewMore'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
 
<Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                              <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[billsFeed.length-1]}</h3></Modal.Header>
                              <Modal.Body id='descriptionModalBody'>
                                          {billDescription[billsFeed.length-1]}
                                          {<br></br>}
                                          {pubDate[billsFeed.length-1]}

                             

                              <div id='fullBill'>


                         <Form id='noteModalForm'>
        
         <Form.Group id='noteModalForm'  className="mb-3" >





          


           <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
             type="text"
             as={"textarea"}
             rows={2}
             
             />

             <br></br>

           


         </Form.Group>
         </Form>

         <Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[billsFeed.length-1], billLink[billsFeed.length-1], billDocumentLink[billsFeed.length-1], pubDate[billsFeed.length-1], feedName, feedBranch, feedTopic, billDescription[billsFeed.length-1]);setCollectionModalIsOpen(false) }} >
    Save To Collection
  </Button>
  </Modal.Footer> 

           </div>
           </Modal.Body>
                            </Modal>
        
      
                        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                          <FeedFunction />
                        </Modal>
        
        </Container >, document.getElementById(divID.toString()));

        

      
      

  }


  })();

  function linkToBill() {
    const linkTo = billLink[0]

  }
}



export function CreateComingSoonText(divID) {
 

      ReactDOM.render(<Container id='feedContainerNormalView'>
        <Card.Title id='billText'>Coming Soon</Card.Title>
      

        
        </Container >, document.getElementById(divID.toString()));

        

      
      

  }



  export function CreateFeedItemSupremeCourtArguments(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
    const [billDescriptionModalIsOpen, setBillDescriptionModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const billDocumentLink = [];
    const viewBillLink = [];
    const pubDate = [];
    const billDescription = [];
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //console.log(collectionCounter)
       collectionCounter++;
         //console.log(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
        
       
         window.location.reload()
    
         
    
    
    
    
    }
  
    let Parser = require('rss-parser');
    let parser = new Parser({
      customFields:{
  
  
        item:['documentLink', 'description']
      }
  
    });
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(item => {
  
  

        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
          {
            billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
          }
           else{
            billTitle.push(item.title.replace(/\&nbsp;/g, ''))
  }
      
        billLink.push(item.link);
        pubDate.push(item.pubDate.toString().substring(0,16));

        billDocumentLink.push(item.documentLink)
        billDescription.push(item.description)
        //have to comment this part out for now
        // v v v v
       // const linkChunk = item.link.toString().substring(36)
       // viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
        //Details and Doc Button Check:
        //  if docButton == true
        //  render document button and value into collection
        //  if docButton == false 
        //  render what is below
     
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>


        
  <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>




        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}> <PlayCircle></PlayCircle> Audio</Button>

<Button id='viewBillButton' onClick={(e) => {
          e.preventDefault();
            window.open(billDocumentLink[0], "_blank")
          }}><FileText></FileText> Document</Button>


<Button id='viewBillButton' onClick={() => setBillDescriptionModalIsOpen(true)
              }><FileText></FileText> Description</Button>

<Modal id='fullBillModal' show={billDescriptionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillDescriptionModalIsOpen(false)}>
                              <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                              <Modal.Body id='descriptionModalBody'>
                                          {billDescription[0]}

                              </Modal.Body>

                            </Modal>
  
  <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], null, pubDate[0], null, feedName, feedBranch, feedTopic)
  
  
  
  } }><FolderPlus></FolderPlus></Button>


  
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                   
                                   {pubDate[0]}
                                   <br></br>
                                   {billDescription[0]}
  
                      
  
                       <div id='fullBill'>
  
  
                  <Form id='noteModalForm'>
  
  <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
   
  
  
    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
  
      <br></br>
  
    
  
  
  </Form.Group>
  </Form>
  
  <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], null, pubDate[0], feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
  Save To Collection
  </Button>
  </Modal.Footer> 
  
    </div>
    </Modal.Body>
                     </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction />
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
          
  
        
        
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }



 


  export function CreateFeedItemExecutiveOffice(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //console.log(collectionCounter)
       collectionCounter++;
         //console.log(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
        
       
         window.location.reload()
    
         
    
    
    
    
    }
  
    let Parser = require('rss-parser');
    let parser = new Parser({
      customFields:{
    
      item: ['pubdate']
       
      }
  
      
    
    });
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
          {
            billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
          }
           else{
            billTitle.push(item.title.replace(/\&nbsp;/g, ''))
  }
      
        billLink.push(item.link);
        pubDate.push(formatDate2(item.pubdate).toString());
  
   
        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
        //Details and Doc Button Check:
        //  if docButton == true
        //  render document button and value into collection
        //  if docButton == false 
        //  render what is below
     
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
        
  <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
  <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], null, pubDate[0], null, feedName, feedBranch, feedTopic)
  
  
  
  } }><FolderPlus></FolderPlus></Button>
  
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                   
                                   {pubDate[0]}
  
                      
  
                       <div id='fullBill'>
  
  
                  <Form id='noteModalForm'>
  
  <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
   
  
  
    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
  
      <br></br>
  
    
  
  
  </Form.Group>
  </Form>
  
  <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], null, pubDate[0], feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
  Save To Collection
  </Button>
  </Modal.Footer> 
  
    </div>
    </Modal.Body>
                     </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction />
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
          
  
        
        
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }


  export function CreateFeedItemSupremeCourtOpinions(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //console.log(collectionCounter)
       collectionCounter++;
         //console.log(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
        
       
         window.location.reload()
    
         
    
    
    
    
    }
  
    let Parser = require('rss-parser');
    let parser = new Parser({
      customFields:{
    
      
       
      }
  
      
    
    });
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(item => {
  
  
  
    
       
  
      
        billLink.push(item.link);
      
  
   
 
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
        //Details and Doc Button Check:
        //  if docButton == true
        //  render document button and value into collection
        //  if docButton == false 
        //  render what is below
     
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billLink[0]}</Card.Title>
        

  
  <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], null, pubDate[0], null, feedName, feedBranch, feedTopic)
  
  
  
  } }><FolderPlus></FolderPlus></Button>
  
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                   
                                   {pubDate[0]}
  
                      
  
                       <div id='fullBill'>
  
  
                  <Form id='noteModalForm'>
  
  <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
   
  
  
    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
  
      <br></br>
  
    
  
  
  </Form.Group>
  </Form>
  
  <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], null, pubDate[0], feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
  Save To Collection
  </Button>
  </Modal.Footer> 
  
    </div>
    </Modal.Body>
                     </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction />
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
          
  
        
        
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }
  
  export function CreateFeedItemTest(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //console.log(collectionCounter)
       collectionCounter++;
         //console.log(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
        
       
         window.location.reload()
    
         
    
    
    
    
    }
  
    let Parser = require('rss-parser');
    let parser = new Parser({
      customFields:{
    
      
       
      }
  
      
    
    });
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(item => {
  
  
        
        billTitle.push(item.title)
        billsFeed.push(item.title + ':' + item.link);
          
        billTitle.push(item.title)
  
        pubDate.push(item.pubDate)
        billLink.push(item.link);
        
  
   
        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
        //Details and Doc Button Check:
        //  if docButton == true
        //  render document button and value into collection
        //  if docButton == false 
        //  render what is below
     
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
        
  <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
  <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], null, pubDate[0], null, feedName, feedBranch, feedTopic)
  
  
  
  } }><FolderPlus></FolderPlus></Button>
  
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                   
                                   {pubDate[0]}
  
                      
  
                       <div id='fullBill'>
  
  
                  <Form id='noteModalForm'>
  
  <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
   
  
  
    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
  
      <br></br>
  
    
  
  
  </Form.Group>
  </Form>
  
  <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], null, pubDate[0], feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
  Save To Collection
  </Button>
  </Modal.Footer> 
  
    </div>
    </Modal.Body>
                     </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction />
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
          
  
        
        
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }



  export function CreateFeedItemBuild(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //console.log(collectionCounter)
       collectionCounter++;
         //console.log(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
        
       
         window.location.reload()
    
         
    
    
    
    
    }
  
    let Parser = require('rss-parser');
    let parser = new Parser({
      customFields:{
    
      
       
      }
  
      
    
    });
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
          {
            billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
          }
           else{
            billTitle.push(item.title.replace(/\&nbsp;/g, ''))
  }
      
        billLink.push(item.link);
        pubDate.push(item.pubDate.toString().substring(0,16));
  
   
        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
        //Details and Doc Button Check:
        //  if docButton == true
        //  render document button and value into collection
        //  if docButton == false 
        //  render what is below
     
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
        
  <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
  <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], null, pubDate[0], null, feedName, feedBranch, feedTopic)
  
  
  
  } }><FolderPlus></FolderPlus></Button>
  
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                   
                                   {pubDate[0]}
  
                      
  
                       <div id='fullBill'>
  
  
                  <Form id='noteModalForm'>
  
  <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
   
  
  
    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
  
      <br></br>
  
    
  
  
  </Form.Group>
  </Form>
  
  <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], null, pubDate[0], feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
  Save To Collection
  </Button>
  </Modal.Footer> 
  
    </div>
    </Modal.Body>
                     </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                        
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
          
  
        
        
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }

  export function CreateFeedItemBillWithDescription(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const [billDescriptionModalIsOpen, setBillDescriptionModalIsOpen] = useState(false)
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    const billDescription = [];
    const billDocumentLink = [];
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //(collectionCounter)
       collectionCounter++;
         //(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         localStorage.setItem(itemDescription,saveCollectionDescription)
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
         function saveNote() {
       
         
    
         }
    
    
    
    
    }
  
    let Parser = require('rss-parser');
    let parser = new Parser({
      customFields:{
  
  
        item:['row',  'description', 'documentLink']
      }
  
    });
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
          {
            billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
          }
           else{
            billTitle.push(item.title.replace(/\&nbsp;/g, ''))
  }
      
        billLink.push(item.link);
        pubDate.push(item.pubDate.toString().substring(0,16));
        billDescription.push(item.description)
       
      const linkChunk = item.link.toString().substring(36)
       viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
 
        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText' onClick={(e) => {
          e.preventDefault();
            window.open(billLink[0], "_blank")
          }}>{billTitle[0]} </Card.Title>
        
  <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  

  
  

  
  <Modal id='fullBillModal' show={billDescriptionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillDescriptionModalIsOpen(false)}>
                                <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]} Description</h3></Modal.Header>
                                <Modal.Body id='descriptionModalBody'>
                                            {billDescription[0]}
  
                                </Modal.Body>
  
                              </Modal>
  
          <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], "", pubDate[0], '', feedName, feedBranch, feedTopic, billDescription[0])
  
  
  
           } }><FolderPlus></FolderPlus></Button>
   
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                                <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                                <Modal.Body id='descriptionModalBody'>
                                           
                                            {pubDate[0]}
  
                               
  
                                <div id='fullBill'>
  
  
                           <Form id='noteModalForm'>
          
           <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
            
  
  
             <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
               type="text"
               as={"textarea"}
               rows={2}
               
               />
  
               <br></br>
  
             
  
  
           </Form.Group>
           </Form>
  
           <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], "", pubDate[0], feedName, feedBranch, feedTopic, "");setCollectionModalIsOpen(false) }} >
      Save To Collection
    </Button>
    </Modal.Footer> 
  
             </div>
             </Modal.Body>
                              </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction />
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
          
  
        
        
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }

  export function CreateFeedItemBillUpdatesFlip(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const [billDescriptionModalIsOpen, setBillDescriptionModalIsOpen] = useState(false)
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    const billDescription = [];
    const billDocumentLink = [];
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //console.log(collectionCounter)
       collectionCounter++;
         //console.log(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         localStorage.setItem(itemDescription,saveCollectionDescription)
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
         function saveNote() {
       
         window.location.reload()
    
         }
    
    
    
    
    }
  
    let Parser = require('rss-parser');
    let parser = new Parser({
      customFields:{
  
  
        item:['row',  'description', 'documentLink']
      }
  
    });
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
          {
            billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
          }
           else{
            billTitle.push(item.title.replace(/\&nbsp;/g, ''))
  }
      
        billLink.push(item.link);
        pubDate.push(item.pubDate.toString().substring(0,16));
        billDescription.push(item.description)
       
      const linkChunk = item.link.toString().substring(36)
       viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
      billDocumentLink.push(item.documentLink)
        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[billsFeed.length-1]}</Card.Title>
        
  <Card.Body id='billBodyText'>{pubDate[billsFeed.length-1]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[billsFeed.length-1], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
  <Button id='viewBillButton' onClick={(e) => {
            e.preventDefault();
              window.open(billDocumentLink[billsFeed.length-1], "_blank")
            }}><FileText></FileText> Bill</Button>
  
  
  <Button id='viewBillButton' onClick={() => setBillDescriptionModalIsOpen(true)
                }><FileText></FileText> Description</Button>
  
  <Modal id='fullBillModal' show={billDescriptionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillDescriptionModalIsOpen(false)}>
                                <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[billsFeed.length-1]} Description</h3></Modal.Header>
                                <Modal.Body id='descriptionModalBody'>
                                            {billDescription[billsFeed.length-1]}
  
                                </Modal.Body>
  
                              </Modal>
  
          <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], billDocumentLink[0], pubDate[0], null, feedName, feedBranch, feedTopic, billDescription[0])
  
  
  
           } }><FolderPlus></FolderPlus></Button>
   
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                                <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                                <Modal.Body id='descriptionModalBody'>
                                            {billDescription[0]}
                                            {pubDate[0]}
  
                               
  
                                <div id='fullBill'>
  
  
                           <Form id='noteModalForm'>
          
           <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
            
  
  
             <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
               type="text"
               as={"textarea"}
               rows={2}
               
               />
  
               <br></br>
  
             
  
  
           </Form.Group>
           </Form>
  
           <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], billDocumentLink[0], pubDate[0], feedName, feedBranch, feedTopic, billDescription[0]);setCollectionModalIsOpen(false) }} >
      Save To Collection
    </Button>
    </Modal.Footer> 
  
             </div>
             </Modal.Body>
                              </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction />
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
          
  
        
        
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }

  export function CreateFeedItemMaterials(url, divID, feedName, feedBranch, feedTopic,  FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
  
    function formatDate(inputDate) {
      const date = new Date(inputDate);
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const day = utcDate.getDate();
      const month = utcDate.getMonth() + 1;
      const year = utcDate.getFullYear();
      return `${month}-${day}-${year}`;
    }

  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //(collectionCounter)
       collectionCounter++;
         //(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         localStorage.setItem(itemDescription,saveCollectionDescription)
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
         
       
         
    
         
    
    
    
    
    }
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
          {
            billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
          }
           else{
            billTitle.push(item.title.replace(/\&nbsp;/g, ''))
  }
      
        billLink.push(item.link);
        pubDate.push(formatDate(item.pubDate));
   
     
      const linkChunk = item.link.toString().substring(36)
       viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
        //Details and Doc Button Check:
        //  if docButton == true
        //  render document button and value into collection
        //  if docButton == false 
        //  render what is below
     
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'onClick={(e) => {
          e.preventDefault();
            window.open(viewBillLink[0], "_blank")
          }}>{billTitle[0]}</Card.Title>
        
  <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
  
  <Button id='viewBillButton' onClick={(e) => {
            e.preventDefault();
              window.open(viewBillLink[0], "_blank")
            }}><FileText></FileText> Document</Button>
  
  <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], viewBillLink[0], pubDate[0], '', feedName, feedBranch, feedTopic, "")
  
  
  
  } }><FolderPlus></FolderPlus></Button>
  
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                  
                                   {pubDate[0]}
  
                      
  
                       <div id='fullBill'>
  
  
                  <Form id='noteModalForm'>
  
  <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
   
  
  
    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
  
      <br></br>
  
    
  
  
  </Form.Group>
  </Form>
  
  <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], viewBillLink[0], pubDate[0], feedName, feedBranch, feedTopic, "");setCollectionModalIsOpen(false) }} >
  Save To Collection
  </Button>
  </Modal.Footer> 
  
    </div>
    </Modal.Body>
                     </Modal>
  
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction />
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
          
  
        
        
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }

  export function CreateFeedItemWithDocumentFlip(url, divID, feedName, feedBranch, feedTopic,  FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
  
    
  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //(collectionCounter)
       collectionCounter++;
         //(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         localStorage.setItem(itemDescription,saveCollectionDescription)
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
         
       
   
    
         
    
    
    
    
    }
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
          {
            billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
          }
           else{
            billTitle.push(item.title.replace(/\&nbsp;/g, ''))
  }
      
        billLink.push(item.link);
        pubDate.push(item.pubDate.toString().substring(0,16));
  
     
      const linkChunk = item.link.toString().substring(36)
       viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
        
  
        //Details and Doc Button Check:
        //  if docButton == true
        //  render document button and value into collection
        //  if docButton == false 
        //  render what is below
     
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title  id='billText' onClick={(e) => {
          e.preventDefault();
            window.open(viewBillLink[billsFeed.length-1], "_blank")
          }}>{billTitle[billsFeed.length -1]}</Card.Title>
        
  <Card.Body id='billBodyText'>{pubDate[billsFeed.length -1 ]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[billsFeed.length -1 ], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
  
  <Button id='viewBillButton' onClick={(e) => {
            e.preventDefault();
              window.open(viewBillLink[billsFeed.length -1 ], "_blank")
            }}><FileText></FileText> Document</Button>
  
  <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[billsFeed.length -1 ], billLink[billsFeed.length -1 ], viewBillLink[billsFeed.length -1 ], pubDate[billsFeed.length -1 ], '', feedName, feedBranch, feedTopic, "")
  
  
  
  } }><FolderPlus></FolderPlus></Button>
  
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[billsFeed.length -1 ]}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                  
                                   {pubDate[billsFeed.length -1 ]}
  
                      
  
                       <div id='fullBill'>
  
  
                  <Form id='noteModalForm'>
  
  <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
   
  
  
    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
  
      <br></br>
  
    
  
  
  </Form.Group>
  </Form>
  
  <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], viewBillLink[0], pubDate[0], feedName, feedBranch, feedTopic, "");setCollectionModalIsOpen(false) }} >
  Save To Collection
  </Button>
  </Modal.Footer> 
  
    </div>
    </Modal.Body>
                     </Modal>
  
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction />
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
          
  
        
        
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }

  export function CreateFeedItemWithDocButtonDateFix(url, divID, feedName, feedBranch, feedTopic,  FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
  
    
  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //(collectionCounter)
       collectionCounter++;
         //(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         localStorage.setItem(itemDescription,saveCollectionDescription)
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
         
       
         
    
         
    
    
    
    
    }
  
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
          {
            billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
          }
           else{
            billTitle.push(item.title.replace(/\&nbsp;/g, ''))
  }
      
        billLink.push(item.link);
        pubDate.push(item.pubDate.toString().substring(0,10));
  
     
      const linkChunk = item.link.toString().substring(36)
       viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
        //Details and Doc Button Check:
        //  if docButton == true
        //  render document button and value into collection
        //  if docButton == false 
        //  render what is below
     
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'onClick={(e) => {
          e.preventDefault();
            window.open(viewBillLink[0], "_blank")
          }}>{billTitle[0]}</Card.Title>
        
  <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0],"_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
  
  <Button id='viewBillButton' onClick={(e) => {
            e.preventDefault();
              window.open(viewBillLink[0], "_blank")
            }}><FileText></FileText> Bill</Button>
  
  <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], viewBillLink[0], pubDate[0], '', feedName, feedBranch, feedTopic, "")
  
  
  
  } }><FolderPlus></FolderPlus></Button>
  
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                  
                                   {pubDate[0]}
  
                      
  
                       <div id='fullBill'>
  
  
                  <Form id='noteModalForm'>
  
  <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
   
  
  
    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
  
      <br></br>
  
    
  
  
  </Form.Group>
  </Form>
  
  <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], viewBillLink[0], pubDate[0], feedName, feedBranch, feedTopic, "");setCollectionModalIsOpen(false) }} >
  Save To Collection
  </Button>
  </Modal.Footer> 
  
    </div>
    </Modal.Body>
                     </Modal>
  
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction />
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
          
  
        
        
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }


  export function CreateFeedItemWithLawButtonFlip(url, divID, feedName, feedBranch, feedTopic,  FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
  
    
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
        
        var searchItem = document.getElementById('noteModalFeedItem').value
      
           collectionCounter = localStorage.getItem('collectionNumber')
           //(collectionCounter)
         collectionCounter++;
           //(collectionCounter)
         
         var collectionTitles = []
          var collectionDetailLinks = []
         var collectionDocLinks = []
         var collectionNote = []
         
         //collectionTitles.push(saveCollectionTitle)
         //collectionDetailLinks.push(saveCollectionDetailLink)
         //collectionDocLinks.push(saveCollectionDocLink)
         
         
         
          
           
         
          
           
           var title = 'title'.concat(collectionCounter)
           var detailLink = 'detailLink'.concat(collectionCounter)
           var docLink = 'docLink'.concat(collectionCounter)
           var itemNote = 'itemNote'.concat(collectionCounter)
           var itemDate = 'itemDate'.concat(collectionCounter)
           var feedName = 'feedName'.concat(collectionCounter)
           var branchName = 'branchName'.concat(collectionCounter)
           var topicName = 'topicName'.concat(collectionCounter)
           var itemDescription = 'itemDescription'.concat(collectionCounter)
       
       
         
         
           localStorage.setItem(title,saveCollectionTitle)
           
           localStorage.setItem(detailLink,saveCollectionDetailLink)
          
           localStorage.setItem(docLink,saveCollectionDocLink)
           localStorage.setItem(itemNote,searchItem)
       
           localStorage.setItem(itemDate,saveCollectionDate)
           localStorage.setItem(feedName,saveCollectionFeedName)
           localStorage.setItem(branchName,saveCollectionBranchName)
           localStorage.setItem(topicName,saveCollectionTopicName)
       
           localStorage.setItem(itemDescription,saveCollectionDescription)
         
           localStorage.setItem('collectionNumber', collectionCounter)
          
           
         
           
      
           
      
      
      
      
      }
  
    let Parser = require('rss-parser');
    let parser = new Parser();
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
          {
            billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
          }
           else{
            billTitle.push(item.title.replace(/\&nbsp;/g, ''))
  }
      
        billLink.push(item.link);
        pubDate.push(item.pubDate.toString().substring(0,16));
  
     
      const linkChunk = item.link.toString().substring(36)
       viewBillLink.push("https://www.govinfo.gov/content/pkg/".concat(linkChunk).concat("/pdf/").concat(linkChunk).concat(".pdf"));
  
        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText' onClick={(e) => {
          e.preventDefault();
            window.open(viewBillLink[billsFeed.length-1], "_blank")
          }}>{billTitle[billsFeed.length -1]}</Card.Title>
        
  <Card.Body id='billBodyText'>{pubDate[billsFeed.length -1]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[billsFeed.length -1], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
  
  <Button id='viewBillButton' onClick={(e) => {
            e.preventDefault();
              window.open(viewBillLink[billsFeed.length -1], "_blank")
            }}><FileText></FileText> Law</Button>
  
  <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[billsFeed.length -1], billLink[billsFeed.length -1], viewBillLink[billsFeed.length -1], pubDate[billsFeed.length -1], '', feedName, feedBranch, feedTopic, "")
  
  
  
  } }><FolderPlus></FolderPlus></Button>
  
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[billsFeed.length -1]}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                  
                                   {pubDate[billsFeed.length -1]}
  
                      
  
                       <div id='fullBill'>
  
  
                  <Form id='noteModalForm'>
  
  <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
   
  
  
    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
  
      <br></br>
  
    
  
  
  </Form.Group>
  </Form>
  
  <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], viewBillLink[0], pubDate[0], feedName, feedBranch, feedTopic, "");setCollectionModalIsOpen(false) }} >
  Save To Collection
  </Button>
  </Modal.Footer> 
  
    </div>
    </Modal.Body>
                     </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction />
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }

  export function CreateFeedItemNoDate(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //(collectionCounter)
       collectionCounter++;
         //(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
        
       
         
    
         
    
    
    
    
    }
  
    let Parser = require('rss-parser');
    let parser = new Parser({
      customFields:{
    
      
       
      }
  
      
    
    });
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
          {
            billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
          }
           else{
            billTitle.push(item.title.replace(/\&nbsp;/g, ''))
  }
      
        billLink.push(item.link);
       
  
   
        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
        //Details and Doc Button Check:
        //  if docButton == true
        //  render document button and value into collection
        //  if docButton == false 
        //  render what is below
     
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }} >{billTitle[0]}</Card.Title>
        
  
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
  <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], null, '', null, feedName, feedBranch, feedTopic)
  
  
  
  } }><FolderPlus></FolderPlus></Button>
  
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                   
                                   
  
                      
  
                       <div id='fullBill'>
  
  
                  <Form id='noteModalForm'>
  
  <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
   
  
  
    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
  
      <br></br>
  
    
  
  
  </Form.Group>
  </Form>
  
  <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], null, pubDate[0], feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
  Save To Collection
  </Button>
  </Modal.Footer> 
  
    </div>
    </Modal.Body>
                     </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction />
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
          
  
        
        
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }
  
  export function CreateFeedItemStateCourt(url, divID, feedName, feedBranch, feedTopic,  FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const [billDescriptionModalIsOpen, setBillDescriptionModalIsOpen] = useState(false)
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
  
    const pubDate = [];
    const billDescription = [];
    const billDocumentLink = [];
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //(collectionCounter)
       collectionCounter++;
         //(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         localStorage.setItem(itemDescription,saveCollectionDescription)
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
         function saveNote() {
       
         
    
         }
    
    
    
    
    }
  
    let Parser = require('rss-parser');
    let parser = new Parser({
      customFields:{
  
  
        item:['description', 'openstate_url', 'documentLink']
      }
  
    });
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
          {
            billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
          }
           else{
            billTitle.push(item.title.replace(/\&nbsp;/g, ''))
  }
      
        billLink.push(item.link);
        pubDate.push(item.pubDate.substring(0,10));
       
       
  
       
      billDocumentLink.push(item.documentLink)
        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
     
        const date = pubDate[index]
       
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'onClick={(e) => {
          e.preventDefault();
          window.open(billDocumentLink[0], "_blank")
          }}>{billTitle[0]}</Card.Title>
        
  <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
  <Button id='viewBillButton' onClick={(e) => {
            e.preventDefault();
            window.open(billDocumentLink[0], "_blank")
            }}><FileText></FileText> Document</Button>
  
  
  
  
  <Modal id='fullBillModal' show={billDescriptionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillDescriptionModalIsOpen(false)}>
                                <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]} </h3></Modal.Header>
                                <Modal.Body id='descriptionModalBody'>Description:
                                {<br></br>}
                                            
  
                                </Modal.Body>
  
                              </Modal>
  
          <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], billDocumentLink[0], pubDate[0], '', feedName, feedBranch, feedTopic, billDescription[0])
  
  
  
           } }><FolderPlus></FolderPlus></Button>
           <br></br>
  
  <Button  id='statesViewMore'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
   
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                                <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                                <Modal.Body id='descriptionModalBody'>
                                            
                                            {<br></br>}
                                            {pubDate[0]}
  
                               
  
                                <div id='fullBill'>
  
  
                           <Form id='noteModalForm'>
          
           <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
            
  
  
             <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
               type="text"
               as={"textarea"}
               rows={2}
               
               />
  
               <br></br>
  
             
  
  
           </Form.Group>
           </Form>
  
           <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[billsFeed.length-1], billLink[billsFeed.length-1], billDocumentLink[billsFeed.length-1], pubDate[billsFeed.length-1], feedName, feedBranch, feedTopic, billDescription[billsFeed.length-1]);setCollectionModalIsOpen(false) }} >
      Save To Collection
    </Button>
    </Modal.Footer> 
  
             </div>
             </Modal.Body>
                              </Modal>
          
        
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction />
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
          
  
        
        
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }

  export function CreateFeedItemStateGovernor(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //(collectionCounter)
       collectionCounter++;
         //(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
        
       
         
    
         
    
    
    
    
    }
  
    let Parser = require('rss-parser');
    let parser = new Parser({
      customFields:{
      
      
        item:['pubdate']
      }
      
    
    });
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title);
            if (item.title.length >= 180)
          {
            billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
          }
           else{
            billTitle.push(item.title.replace(/\&nbsp;/g, ''))
  }
  if (item.link) {
        billLink.push(item.link);
  }
        pubDate.push(item.pubDate);
  
   
        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
        //Details and Doc Button Check:
        //  if docButton == true
        //  render document button and value into collection
        //  if docButton == false 
        //  render what is below
     
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}>{billTitle[0]}</Card.Title>
        
  <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
  <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], null, pubDate[0], '', feedName, feedBranch, feedTopic)
  
  
  
  } }><FolderPlus></FolderPlus></Button>
  
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                   
                                   {pubDate[0]}
  
                      
  
                       <div id='fullBill'>
  
  
                  <Form id='noteModalForm'>
  
  <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
   
  
  
    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
  
      <br></br>
  
    
  
  
  </Form.Group>
  </Form>
  
  <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], null, pubDate[0], feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
  Save To Collection
  </Button>
  </Modal.Footer> 
  
    </div>
    </Modal.Body>
                     </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction />
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
          
  
        
        
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }


  export function CreateFeedElections(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //(collectionCounter)
       collectionCounter++;
         //(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
        
       
         
    
         
    
    
    
    
    }
  
    let Parser = require('rss-parser');
    let parser = new Parser({
      customFields:{
      
      
        item:['name', 'url', 'meeting_date', 'electionDay']
      }
      
    
    });
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.name + ':' + item.url);
            if (item.name.length >= 180)
          {
            billTitle.push(item.name.replace(/\&nbsp;/g, '').substring(0,177)+'...');
          }
           else{
            billTitle.push(item.name.replace(/\&nbsp;/g, ''))
  }
      
       
        pubDate.push(item.electionDay);
  
   
        
  
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
        //Details and Doc Button Check:
        //  if docButton == true
        //  render document button and value into collection
        //  if docButton == false 
        //  render what is below
     
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText' onClick={(e) => {
          e.preventDefault();
         window.open(billLink[0], "_blank")
        }}>{billTitle[0]}</Card.Title>
        
  <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
       
  
  <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], "", null, pubDate[0], '', feedName, feedBranch, feedTopic)
  
  
  
  } }><FolderPlus></FolderPlus></Button>
  
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                   
                                   {pubDate[0]}
  
                      
  
                       <div id='fullBill'>
  
  
                  <Form id='noteModalForm'>
  
  <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
   
  
  
    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
  
      <br></br>
  
    
  
  
  </Form.Group>
  </Form>
  
  <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], "", null, pubDate[0], feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
  Save To Collection
  </Button>
  </Modal.Footer> 
  
    </div>
    </Modal.Body>
                     </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction />
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
          
  
        
        
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }


  export function CreateFeedItemCanada(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //(collectionCounter)
       collectionCounter++;
         //(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
        
       
         
    
         
    
    
    
    
    }

    function formatDate2(pubDate) {
      const date = new Date(pubDate);
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1; // Months are zero-indexed
      const year = date.getUTCFullYear();
      return `${month}-${day}-${year}`;
    }
  
    let Parser = require('rss-parser');
    let parser = new Parser({
      customFields:{
    
      item: ['decision:date']
       
      }
  
      
    
    });
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
          {
            billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
          }
           else{
            billTitle.push(item.title.replace(/\&nbsp;/g, ''))
  }
      
        billLink.push(item.link);
        pubDate.push(item['decision:date'].toString().substring(0,16));
  
   
        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
        //Details and Doc Button Check:
        //  if docButton == true
        //  render document button and value into collection
        //  if docButton == false 
        //  render what is below
     
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}>{billTitle[0]}</Card.Title>
        
  <Card.Body id='billBodyText'>{formatDate2(pubDate[0])}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
  <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], null, pubDate[0], '', feedName, feedBranch, feedTopic)
  
  
  
  } }><FolderPlus></FolderPlus></Button>
  
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                   
                                   {pubDate[0]}
  
                      
  
                       <div id='fullBill'>
  
  
                  <Form id='noteModalForm'>
  
  <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
   
  
  
    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
  
      <br></br>
  
    
  
  
  </Form.Group>
  </Form>
  
  <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], null, pubDate[0], feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
  Save To Collection
  </Button>
  </Modal.Footer> 
  
    </div>
    </Modal.Body>
                     </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction />
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
          
  
        
        
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }

  export function CreateFeedItemCanadaDateFix(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //(collectionCounter)
       collectionCounter++;
         //(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
        
       
         
    
         
    
    
    
    
    }
  
    let Parser = require('rss-parser');
    let parser = new Parser({
      customFields: {
        item: ['pubDate'],
      },
    });
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
          {
            billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
          }
           else{
            billTitle.push(item.title.replace(/\&nbsp;/g, ''))
  }
      
  const pubDateObject = item.pubDate;
  const timeArray = pubDateObject && pubDateObject.time;

  // Check if timeArray exists and is an array
  if (Array.isArray(timeArray) && timeArray.length > 0) {
    const datetime = timeArray[0].$ && timeArray[0].$.datetime;

    // Convert the date string to a JavaScript Date object
    const dateObject = new Date(datetime);

    // Format the date using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: 'UTC', // Adjust the timeZone according to your requirements
    }).format(dateObject);

 

    pubDate.push(formattedDate);
  } else {
    console.error('Unexpected structure for pubDate:', pubDateObject);
    pubDate.push(null); // or handle accordingly if the structure is unexpected
  }
  
   
        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
        //Details and Doc Button Check:
        //  if docButton == true
        //  render document button and value into collection
        //  if docButton == false 
        //  render what is below
     
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}>{billTitle[0]}</Card.Title>
        
  <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
  <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], null, pubDate[0], '', feedName, feedBranch, feedTopic)
  
  
  
  } }><FolderPlus></FolderPlus></Button>
  
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                   
                                   {pubDate[0]}
  
                      
  
                       <div id='fullBill'>
  
  
                  <Form id='noteModalForm'>
  
  <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
   
  
  
    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
  
      <br></br>
  
    
  
  
  </Form.Group>
  </Form>
  
  <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], null, pubDate[0], feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
  Save To Collection
  </Button>
  </Modal.Footer> 
  
    </div>
    </Modal.Body>
                     </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction />
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
          
  
        
        
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }

  export function CreateFeedItemHouseOfCommons(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //(collectionCounter)
       collectionCounter++;
         //(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
        
       
         
    
         
    
    
    
    
    }

    function formatDate2(pubDate) {
      const date = new Date(pubDate);
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1; // Months are zero-indexed
      const year = date.getUTCFullYear();
      return `${month}-${day}-${year}`;
    }
  
    let Parser = require('rss-parser');
    let parser = new Parser({
      customFields:{
    
      item: ['url']
       
      }
  
      
    
    });
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
          {
            billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
          }
           else{
            billTitle.push(item.title.replace(/\&nbsp;/g, ''))
  }
      
        billLink.push(item.url);
        pubDate.push(item.pubDate.toString().substring(0,16));
  
   
        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
  
        //Details and Doc Button Check:
        //  if docButton == true
        //  render document button and value into collection
        //  if docButton == false 
        //  render what is below
     
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}>{billTitle[0]}</Card.Title>
        
  <Card.Body id='billBodyText'>{formatDate2(pubDate[0])}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
  <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], null, pubDate[0], '', feedName, feedBranch, feedTopic)
  
  
  
  } }><FolderPlus></FolderPlus></Button>
  
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                   
                                   {pubDate[0]}
  
                      
  
                       <div id='fullBill'>
  
  
                  <Form id='noteModalForm'>
  
  <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
   
  
  
    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
  
      <br></br>
  
    
  
  
  </Form.Group>
  </Form>
  
  <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], null, pubDate[0], feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
  Save To Collection
  </Button>
  </Modal.Footer> 
  
    </div>
    </Modal.Body>
                     </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction />
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
          
  
        
        
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }




  export function CreateFeedItemCanadaProvence(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const [billDescriptionModalIsOpen, setBillDescriptionModalIsOpen] = useState(false)
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    const billDescription = [];
    const billDocumentLink = [];
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //(collectionCounter)
       collectionCounter++;
         //(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         localStorage.setItem(itemDescription,saveCollectionDescription)
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
         function saveNote() {
       
         
    
         }
    
    
    
    
    }
  
    let Parser = require('rss-parser');
    let parser = new Parser({
      customFields:{
  
  
        item:['summary']
      }
  
    });
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
          {
            billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
          }
           else{
            billTitle.push(item.title.replace(/\&nbsp;/g, ''))
  }
      
        billLink.push(item.link);
        pubDate.push(item.pubDate.toString().substring(0,10));
        billDescription.push(item.summary)
       

        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
          }}>{billTitle[0]} </Card.Title>
        
  <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  

  
  
  <Button id='viewBillButton' onClick={() => setBillDescriptionModalIsOpen(true)
                }><FileText></FileText> Description</Button>
  
  <Modal id='fullBillModal' show={billDescriptionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillDescriptionModalIsOpen(false)}>
                                <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]} Description</h3></Modal.Header>
                                <Modal.Body id='descriptionModalBody'>
                                            {billDescription[0]}
  
                                </Modal.Body>
  
                              </Modal>
  
          <Button id='addToCollectionButtonMobile' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], billDocumentLink[0], pubDate[0], '', feedName, feedBranch, feedTopic, billDescription[0])
  
  
  
           } }><FolderPlus></FolderPlus></Button>
   
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                                <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                                <Modal.Body id='descriptionModalBody'>
                                            {billDescription[0]}
                                            {pubDate[0]}
  
                               
  
                                <div id='fullBill'>
  
  
                           <Form id='noteModalForm'>
          
           <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
            
  
  
             <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
               type="text"
               as={"textarea"}
               rows={2}
               
               />
  
               <br></br>
  
             
  
  
           </Form.Group>
           </Form>
  
           <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], billDocumentLink[0], pubDate[0], feedName, feedBranch, feedTopic, billDescription[0]);setCollectionModalIsOpen(false) }} >
      Save To Collection
    </Button>
    </Modal.Footer> 
  
             </div>
             </Modal.Body>
                              </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction />
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
          
  
        
        
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }


  export function CreateFeedItemMexico(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const [billDescriptionModalIsOpen, setBillDescriptionModalIsOpen] = useState(false)
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    const billDescription = [];
    const billDocumentLink = [];
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //(collectionCounter)
       collectionCounter++;
         //(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         localStorage.setItem(itemDescription,saveCollectionDescription)
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
         function saveNote() {
       
         
    
         }
    
    
    
    
    }
  
    let Parser = require('rss-parser');
    let parser = new Parser({
      customFields:{
  
  
        item:['created_at', 'url']
      }
  
    });
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(item => {
  
  
  
        billsFeed.push(item.title + ':' + item.url);
            if (item.title.length >= 180)
          {
            billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
          }
           else{
            billTitle.push(item.title.replace(/\&nbsp;/g, ''))
  }
      
        billLink.push(item.url);
        pubDate.push(item.created_at.toString().substring(0,10));
        
       

        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
          }}>{billTitle[0]} </Card.Title>
        
  <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>

  
  <Modal id='fullBillModal' show={billDescriptionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillDescriptionModalIsOpen(false)}>
                                <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]} Description</h3></Modal.Header>
                                <Modal.Body id='descriptionModalBody'>
                                         
  
                                </Modal.Body>
  
                              </Modal>
  
          <Button id='addToCollectionButtonMobile' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], billDocumentLink[0], pubDate[0], '', feedName, feedBranch, feedTopic, billDescription[0])
  
  
  
           } }><FolderPlus></FolderPlus></Button>
   
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                                <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                                <Modal.Body id='descriptionModalBody'>
                                            {billDescription[0]}
                                            {pubDate[0]}
  
                               
  
                                <div id='fullBill'>
  
  
                           <Form id='noteModalForm'>
          
           <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
            
  
  
             <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
               type="text"
               as={"textarea"}
               rows={2}
               
               />
  
               <br></br>
  
             
  
  
           </Form.Group>
           </Form>
  
           <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], billDocumentLink[0], pubDate[0], feedName, feedBranch, feedTopic, billDescription[0]);setCollectionModalIsOpen(false) }} >
      Save To Collection
    </Button>
    </Modal.Footer> 
  
             </div>
             </Modal.Body>
                              </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction />
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
          
  
        
        
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }

  export function CreateFeedItemUK(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
    const [loading, setLoading] = useState(true);
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
    const [billTitle, setBillTitle] = useState('');
    const [billLink, setBillLink] = useState('');
    const [theDate, setPubDate] = useState('');
    const [testData, setTestData] = useState('');
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //(collectionCounter)
       collectionCounter++;
         //(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
        
       
         
    
         
    
    
    
    
    }
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          let Parser = require('rss-parser');
          let parser = new Parser();
  
          let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
          if (feed.items.length > 0) {
            let item = feed.items[0];
            setBillTitle(item.title);
            setBillLink(item.link);
            setTestData(item.pubDate);
            console.log(testData)
            setLoading(false); // Set loading to false when data is fetched
          } else {
            setLoading(false); // Set loading to false if no items are found
          }
        } catch (error) {
          console.error('Error fetching feed:', error);
          setLoading(false); // Set loading to false even if there's an error
        }
      };
  
      fetchData();
    }, [url]); // Fetch data when URL changes
  
    if (loading) {
      return <Spinner animation="grow" variant="light" />;
    }
  
    return (
      <Container id='feedContainerNormalView'>
        <Card.Title id='billText' onClick={(e) => { e.preventDefault(); window.open(billLink, "_blank"); }}>{billTitle}</Card.Title>
        <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(billLink, "_blank"); }}> <InfoCircle></InfoCircle> Details</Button>
        <Button id='addToCollectionButton' onClick={() => { setCollectionModalIsOpen(true); }}>
          <FolderPlus></FolderPlus>
        </Button>
        <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>{billTitle}</h3></Modal.Header>
          <Modal.Body id='descriptionModalBody'>
            {testData}
            <div id='fullBill'>
              <Form id='noteModalForm'>
                <Form.Group id='noteModalForm' className="mb-3">
                  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as={"textarea"} rows={2} />
                  <br></br>
                </Form.Group>
              </Form>
              <Modal.Footer id='addNoteModalFooter'>
                <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => { saveNote(billTitle, billLink, null, '', feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false) }}>
                  Save To Collection
                </Button>
              </Modal.Footer>
            </div>
          </Modal.Body>
        </Modal>
        <Button id='whDocsViewMoreButton' onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
          <FeedFunction />
        </Modal>
      </Container>
    );
  }

  export function CreateFeedItemUKAtom(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //(collectionCounter)
       collectionCounter++;
         //(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
        
       
         
    
         
    
    
    
    
    }
  
    let Parser = require('rss-parser');
    let parser = new Parser({
      customFields:{
        feed: ['entry'],
  
        item:['updated']
      }
  
    });
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
  
      feed.items.forEach(entry => {
  
        billsFeed.push(entry.title + ':' + entry.link);
            if (entry.title.length >= 180)
          {
            billTitle.push(entry.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
          }
           else{
            billTitle.push(entry.title.replace(/\&nbsp;/g, ''))
  }
      
        billLink.push(entry.link);

        pubDate.push(entry.updated.substring(0,10));
    
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
   
  
  
        //Details and Doc Button Check:
        //  if docButton == true
        //  render document button and value into collection
        //  if docButton == false 
        //  render what is below
     
  
  
  
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}>{billTitle[0]}</Card.Title>
        
        <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
  <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], null, pubDate[0], '', feedName, feedBranch, feedTopic)
  
  
  
  } }><FolderPlus></FolderPlus></Button>
  
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                   
                                   {pubDate[0]}
  
                      
  
                       <div id='fullBill'>
  
  
                  <Form id='noteModalForm'>
  
  <Form.Group id='noteModalForm'  className="mb-3" >
  
  
  
  
  
   
  
  
    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
  
      <br></br>
  
    
  
  
  </Form.Group>
  </Form>
  
  <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], null, '', feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
  Save To Collection
  </Button>
  </Modal.Footer> 
  
    </div>
    </Modal.Body>
                     </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction />
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));
  
          
  
        
        
  
    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }

  // export function CreateFeedAPI(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
  //   const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
  //   const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
  //   const [title, setTitle] = useState('');
  //   const [link, setLink] = useState('');
  //   const [docLink, setDocLink] = useState('');
  //   const [textLink, setTextLink] = useState('');
  //   const [date, setDate] = useState('');
  
  //   function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
  //     var searchItem = document.getElementById('noteModalFeedItem').value;
  //     var collectionCounter = localStorage.getItem('collectionNumber');
  //     collectionCounter++;
      
  //     var title = 'title'.concat(collectionCounter);
  //     var detailLink = 'detailLink'.concat(collectionCounter);
  //     var docLink = 'docLink'.concat(collectionCounter);
  //     var itemNote = 'itemNote'.concat(collectionCounter);
  //     var itemDate = 'itemDate'.concat(collectionCounter);
  //     var feedName = 'feedName'.concat(collectionCounter);
  //     var branchName = 'branchName'.concat(collectionCounter);
  //     var topicName = 'topicName'.concat(collectionCounter);
  
  //     localStorage.setItem(title, saveCollectionTitle);
  //     localStorage.setItem(detailLink, saveCollectionDetailLink);
  //     localStorage.setItem(docLink, saveCollectionDocLink);
  //     localStorage.setItem(itemNote, searchItem);
  //     localStorage.setItem(itemDate, saveCollectionDate);
  //     localStorage.setItem(feedName, saveCollectionFeedName);
  //     localStorage.setItem(branchName, saveCollectionBranchName);
  //     localStorage.setItem(topicName, saveCollectionTopicName);
  //     localStorage.setItem('collectionNumber', collectionCounter);
  //     window.location.reload();
  //   }
  
  //   function formatDate(inputDate) {
  //     const date = new Date(inputDate);
  //     const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  //     const day = utcDate.getDate();
  //     const month = utcDate.getMonth() + 1;
  //     const year = utcDate.getFullYear();
  //     return `${month}-${day}-${year}`;
  //   }


  
  //   const requestOptions = {
  //     method: 'GET',
  //     headers: { 
  //       'Content-Type': 'application/json',
  //       "Authorization": "Bearer " + apiKey,
  //     },
  //   };
  
  //   const displayData = (cachedData) => {
  //     const firstItem = cachedData[0];
  //     let title = firstItem.status_title || firstItem.title;
  //     if (title.length > 180) {
  //       title = title.substring(0, 177) + '...';
  //     }
  //     setTitle(title);
  //     setLink(firstItem.url);
  //     if (firstItem.document_url) {
  //       setDocLink(firstItem.document_url);
  //       setTextLink(firstItem.document_url);
  //     } else if (firstItem.download_link) {
  //       setDocLink(firstItem.download_link);
  //       setTextLink(firstItem.document_link);
  //     } else if (firstItem.document_link) {
  //       setDocLink(firstItem.document_link);
  //       setTextLink(firstItem.document_link);
  //     } else if (firstItem.download && firstItem.download.pdfLink) {
  //       setDocLink(firstItem.download.pdfLink);
  //       setTextLink(firstItem.download.pdfLink);
  //     }
  //       else if (firstItem.pdf_url) {
  //         setDocLink(firstItem.pdf_url);
  //         setTextLink(firstItem.pdf_url);
  //     } else {
  //       setTextLink(firstItem.url);
  //     }
  //     setDate(formatDate(firstItem.created_at));
  //   };
  
  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         const cachedData = localStorage.getItem(feedName + 'cachedData');
  
  //         if (cachedData) {
  //           displayData(JSON.parse(cachedData));
  //         }
  
  //         const response = await fetch(`https://api.govglance.org/posts/recent?limit=1${apiURL}`, requestOptions);
  //         if (!response.ok) {
  //           throw new Error(`HTTP error! status: ${response.status}`);
  //         }
  //         const data = await response.json();
  //         // console.log(feedName + '------' + data)
  //         if (!cachedData || JSON.stringify(data) !== cachedData) {
  //           localStorage.setItem(feedName + 'cachedData', JSON.stringify(data));
  //         }
    
  //         displayData(data);
  //       } catch (error) {
  //         console.error('Error fetching data:', error);
  //       }
  //     };
  
  //     fetchData();
  //   }, [apiURL]);
  
  //   const containerElement = document.getElementById(divID.toString());
  
  //   if (!containerElement) {
  //     return null; // Or return a fallback UI, like a spinner or an error message
  //   }
  
  //   if (title.length === 0) {
  //     return <Spinner animation="grow" variant="light" />;
  //   }
  
  //   if (title === "Coming Soon") {
  //     return ReactDOM.createPortal(
  //       <Container id='feedContainerNormalView'>
  //         <Card.Title id='billText'>{title}</Card.Title>
  //         <br /><br /><br /><br /><br /><br />
  //       </Container>, containerElement
  //     );
  //   }
  
  //   const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
  //     <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
  //       <FileText /> {feedButtonName}
  //     </Button>
  //   ) : null;
  
  //   return ReactDOM.createPortal(
  //     <Container id='feedContainerNormalView'>
  //       <Card.Title id='billText' onClick={(e) => { e.preventDefault(); window.open(textLink, "_blank") }}>{title}</Card.Title>
  //       <Card.Body id='billBodyText'>{date}</Card.Body>
  //       <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
  //         <InfoCircle /> Details
  //       </Button>
  //       {viewBillButton}
  //       <Button id='addToCollectionButton' onClick={() => { setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic); }}>
  //         <FolderPlus />
  //       </Button>
  //       <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
  //         <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
  //         <Modal.Body id='descriptionModalBody'>
  //           {date}
  //           <div id='fullBill'>
  //             <Form id='noteModalForm'>
  //               <Form.Group id='noteModalForm' className="mb-3">
  //                 <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as={"textarea"} rows={2} />
  //                 <br />
  //               </Form.Group>
  //             </Form>
  //             <Modal.Footer id='addNoteModalFooter'>
  //               <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => { saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false); }}>
  //                 Save To Collection
  //               </Button>
  //             </Modal.Footer>
  //           </div>
  //         </Modal.Body>
  //       </Modal>
  //       <Button id='whDocsViewMoreButton' onClick={() => setFeedItemModalIsOpen(true)}>
  //         <Files size={20} /> View More
  //       </Button>
  //       <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
  //         <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
  //         <FeedFunction name={feedName} url={apiURL} branch={feedBranch} topic={feedTopic} buttonName={feedButtonName} />
  //       </Modal>
  //     </Container>, containerElement
  //   );
  // }

  export function CreateFeedAPIBefore(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [docLink, setDocLink] = useState('');
    const [textLink, setTextLink] = useState('');
    const [date, setDate] = useState('');
  
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
      var searchItem = document.getElementById('noteModalFeedItem').value;
      var collectionCounter = localStorage.getItem('collectionNumber');
      collectionCounter++;
      
      var title = 'title'.concat(collectionCounter);
      var detailLink = 'detailLink'.concat(collectionCounter);
      var docLink = 'docLink'.concat(collectionCounter);
      var itemNote = 'itemNote'.concat(collectionCounter);
      var itemDate = 'itemDate'.concat(collectionCounter);
      var feedName = 'feedName'.concat(collectionCounter);
      var branchName = 'branchName'.concat(collectionCounter);
      var topicName = 'topicName'.concat(collectionCounter);
  
      localStorage.setItem(title, saveCollectionTitle);
      localStorage.setItem(detailLink, saveCollectionDetailLink);
      localStorage.setItem(docLink, saveCollectionDocLink);
      localStorage.setItem(itemNote, searchItem);
      localStorage.setItem(itemDate, saveCollectionDate);
      localStorage.setItem(feedName, saveCollectionFeedName);
      localStorage.setItem(branchName, saveCollectionBranchName);
      localStorage.setItem(topicName, saveCollectionTopicName);
      localStorage.setItem('collectionNumber', collectionCounter);
      window.location.reload();
    }
  
    function formatDate(inputDate) {
      const date = new Date(inputDate);
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const day = utcDate.getDate();
      const month = utcDate.getMonth() + 1;
      const year = utcDate.getFullYear();
      return `${month}-${day}-${year}`;
    }


  
    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + apiKey,
      },
    };
  
    const displayData = (cachedData) => {
      const firstItem = cachedData[0];
      let title = firstItem.status_title || firstItem.title;
      if (title.length > 180) {
        title = title.substring(0, 177) + '...';
      }
      setTitle(title);
      setLink(firstItem.url);
      if (firstItem.document_url) {
        setDocLink(firstItem.document_url);
        setTextLink(firstItem.document_url);
      } else if (firstItem.download_link) {
        setDocLink(firstItem.download_link);
        setTextLink(firstItem.document_link);
      } else if (firstItem.document_link) {
        setDocLink(firstItem.document_link);
        setTextLink(firstItem.document_link);
      } else if (firstItem.download && firstItem.download.pdfLink) {
        setDocLink(firstItem.download.pdfLink);
        setTextLink(firstItem.download.pdfLink);
      }
        else if (firstItem.pdf_url) {
          setDocLink(firstItem.pdf_url);
          setTextLink(firstItem.pdf_url);
      } else {
        setTextLink(firstItem.url);
      }
      setDate(formatDate(firstItem.created_at));
    };
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const cachedData = localStorage.getItem(feedName + 'cachedData');
  
          if (cachedData) {
            displayData(JSON.parse(cachedData));
          }
  
          const response = await fetch(`https://api.govglance.org/posts/recent?limit=1${apiURL}`, requestOptions);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          // console.log(feedName + '------' + data)
          if (!cachedData || JSON.stringify(data) !== cachedData) {
            localStorage.setItem(feedName + 'cachedData', JSON.stringify(data));
          }
    
          displayData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, [apiURL]);
  
    const containerElement = document.getElementById(divID.toString());
  
    if (!containerElement) {
      return null; // Or return a fallback UI, like a spinner or an error message
    }
  
    if (title.length === 0) {
      return <Spinner animation="grow" variant="light" />;
    }
  
    if (title === "Coming Soon") {
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText'>{title}</Card.Title>
          <br /><br /><br /><br /><br /><br />
        </Container>, containerElement
      );
    }
  
    const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
      <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
        <FileText /> {feedButtonName}
      </Button>
    ) : null;
  
    return ReactDOM.createPortal(
      <Container id='feedContainerNormalView'>
        <Card.Title id='billText' onClick={(e) => { e.preventDefault(); window.open(textLink, "_blank") }}>{title}</Card.Title>
        <Card.Body id='billBodyText'>{date}</Card.Body>
        <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
          <InfoCircle /> Details
        </Button>
        {viewBillButton}
        <Button id='addToCollectionButton' onClick={() => { setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic); }}>
          <FolderPlus />
        </Button>
        <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
          <Modal.Body id='descriptionModalBody'>
            {date}
            <div id='fullBill'>
              <Form id='noteModalForm'>
                <Form.Group id='noteModalForm' className="mb-3">
                  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as={"textarea"} rows={2} />
                  <br />
                </Form.Group>
              </Form>
              <Modal.Footer id='addNoteModalFooter'>
                <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => { saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false); }}>
                  Save To Collection
                </Button>
              </Modal.Footer>
            </div>
          </Modal.Body>
        </Modal>
        <Button id='whDocsViewMoreButton' onClick={() => setFeedItemModalIsOpen(true)}>
          <Files size={20} /> View More
        </Button>
        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
          <FeedFunction name={feedName} url={apiURL} branch={feedBranch} topic={feedTopic} buttonName={feedButtonName} />
        </Modal>
      </Container>, containerElement
    );
  }

//   export function CreateFeedAPIMobile(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
//     const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
//     const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
//     const [title, setTitle] = useState('');
//   const [link, setLink] = useState('');
//   const [docLink, setDocLink] = useState('');
//   const [textLink, setTextLink] = useState('');
//   const [date, setDate] = useState('');
 


//     function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      
//       var searchItem = document.getElementById('noteModalFeedItem').value
    
//        collectionCounter = localStorage.getItem('collectionNumber')
//        collectionCounter++;
   
       
//        var collectionTitles = []
//         var collectionDetailLinks = []
//        var collectionDocLinks = []
//        var collectionNote = []
       
 
//          var title = 'title'.concat(collectionCounter)
//          var detailLink = 'detailLink'.concat(collectionCounter)
//          var docLink = 'docLink'.concat(collectionCounter)
//          var itemNote = 'itemNote'.concat(collectionCounter)
//          var itemDate = 'itemDate'.concat(collectionCounter)
//          var feedName = 'feedName'.concat(collectionCounter)
//          var branchName = 'branchName'.concat(collectionCounter)
//          var topicName = 'topicName'.concat(collectionCounter)
//         var itemDescription = 'itemDescription'.concat(collectionCounter)
     

//          localStorage.setItem(title,saveCollectionTitle)
//          localStorage.setItem(detailLink,saveCollectionDetailLink)
//          localStorage.setItem(docLink,saveCollectionDocLink)
//          localStorage.setItem(itemNote,searchItem)
//          localStorage.setItem(itemDate,saveCollectionDate)
//          localStorage.setItem(feedName,saveCollectionFeedName)
//          localStorage.setItem(branchName,saveCollectionBranchName)
//          localStorage.setItem(topicName,saveCollectionTopicName)
//          localStorage.setItem('collectionNumber', collectionCounter)
//          window.location.reload()
//     }

    

//     function formatDate(inputDate) {
//       const date = new Date(inputDate);
//       const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
//       const day = utcDate.getDate();
//       const month = utcDate.getMonth() + 1;
//       const year = utcDate.getFullYear();
    
//       return `${month}-${day}-${year}`;
//     }

//     const requestOptions = {
//       method: 'GET',
//       headers: { 
//         'Content-Type': 'application/json',
//         "Authorization": "Bearer "+apiKey,
//     },
//     };

      
//     const displayData = (cachedData) => {
//       const firstItem = cachedData[0];
//       let title = firstItem.status_title || firstItem.title;
//       if (title.length > 180) {
//           title = title.substring(0, 177) + '...';
//       }
//       setTitle(title);
//       setLink(firstItem.url);
   
//       if (firstItem.document_link) {
//           setDocLink(firstItem.document_link);
//           setTextLink(firstItem.document_link);
//       } else if (firstItem.download && firstItem.download.pdfLink) {
//           setDocLink(firstItem.download.pdfLink);
//           setTextLink(firstItem.download.pdfLink);
//       } else {
//         setTextLink(firstItem.url)
//       }
     
//       setDate(formatDate(firstItem.created_at));
//   };


//   useEffect(() => {
//     const fetchData = async () => {
//         try {
//             const cachedData = localStorage.getItem(feedName + 'cachedData');

//             // Display cached data if available
//             if (cachedData) {
//                 displayData(JSON.parse(cachedData));
//             }

//             const response = await fetch(`https://api.govglance.org/posts/recent?limit=1${apiURL}`, requestOptions);
//             if (!response.ok) {
//                 throw new Error(`HTTP error! status: ${response.status}`);
//             }
//             const data = await response.json();

//             // Update cache with fetched data if no existing cached data or fetched data differs from cached data
//             if (!cachedData || JSON.stringify(data) !== cachedData) {
//                 localStorage.setItem(feedName + 'cachedData', JSON.stringify(data));
//             }

//             // Display the fetched data
//             displayData(data);
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };

//     fetchData();
// }, [apiURL]);
  
  
  
//     if (title.length === 0) {
//       return <Spinner animation="grow" variant="light" />
//     }

//     if (title === "Coming Soon") {
//       return ReactDOM.createPortal(
//         <Container id='feedContainerNormalView'>
//           <Card.Title id='billText'>{title}</Card.Title>
//           <br></br><br></br><br></br>
//           <br></br><br></br><br></br>
//         </Container>,document.getElementById(divID.toString())
//       );
//     }
   
//     const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
//       <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
//         <FileText></FileText> {feedButtonName}
//       </Button>
//     ) : null;

  
  
  
//     return ReactDOM.createPortal(
//       <Container id='feedContainerNormalView'>
//         <Card.Title id='billText' onClick={(e) => { e.preventDefault(); window.open(textLink, "_blank") }}>{title}</Card.Title>
//         <Card.Body id='billBodyText'>{date}</Card.Body>
//         <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
//           <InfoCircle /> Details
//         </Button>
  
//         {viewBillButton}

//         <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic)
// } }><FolderPlus></FolderPlus></Button>

// <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
//                      <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
//                      <Modal.Body id='descriptionModalBody'>
                                 
//                                  {date}

//                      <div id='fullBill'>

//                 <Form id='noteModalForm'>

// <Form.Group id='noteModalForm'  className="mb-3" >

//   <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
//     type="text"
//     as={"textarea"}
//     rows={2}
    
//     />

//     <br></br>
// </Form.Group>
// </Form>

// <Modal.Footer id='addNoteModalFooter'> 

// <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
// Save To Collection
// </Button>
// </Modal.Footer> 

//   </div>
//   </Modal.Body>
//                    </Modal>
        
//         <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
//                         <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
//                           <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
//                           <FeedFunction name={feedName} url={apiURL} branch={feedBranch} topic={feedTopic} buttonName={feedButtonName}/>
//                         </Modal>
  
//       </Container>,
//       document.getElementById(divID.toString())
//     );
//   }


  export function CreateFeedItemAPITest(url, divID, feedName, feedBranch, feedTopic, apiURL, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false)
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
    const billsFeed = [];
    const billTitle = [];
    const billLink = [];
    const viewBillLink = [];
    const pubDate = [];

    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
       collectionCounter = localStorage.getItem('collectionNumber')
       collectionCounter++;
   
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
 
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
        var itemDescription = 'itemDescription'.concat(collectionCounter)
     

         localStorage.setItem(title,saveCollectionTitle)
         localStorage.setItem(detailLink,saveCollectionDetailLink)
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
         localStorage.setItem('collectionNumber', collectionCounter)
         window.location.reload()
    }
  
    let Parser = require('rss-parser');
    let parser = new Parser({
      customFields:{

      }

    });
  
    (async () => {
  
      let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);

      feed.items.forEach(item => {

        billsFeed.push(item.title + ':' + item.link);
            if (item.title.length >= 180)
          {
            billTitle.push(item.title.replace(/\&nbsp;/g, '').substring(0,177)+'...');
          }
           else{
            billTitle.push(item.title.replace(/\&nbsp;/g, ''))
  }
        billLink.push(item.link);
        pubDate.push(item.pubDate.toString().substring(0,16));
        feed.item = item.title + ':' + item.link;
  
      });
  
  
      for (let index = 0; index < billsFeed.length; index++) {
        const titles = billTitle[index];
        const links = billLink[index];
        const viewBillDetails = viewBillLink[index]
        const date = pubDate[index]
        const dateTwo = pubDate[98]
        const dateThree = pubDate[98]
  
        ReactDOM.render(<Container id='feedContainerNormalView'><Card.Title id='billText'>{billTitle[0]}</Card.Title>
        
  <Card.Body id='billBodyText'>{pubDate[0]}</Card.Body>
        <Button id='billLink' onClick={(e) => {
          e.preventDefault();
          window.open(billLink[0], "_blank")
        }}> <InfoCircle></InfoCircle> Details</Button>
  
  <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(billTitle[0], billLink[0], null, pubDate[0], null, feedName, feedBranch, feedTopic)
  
  
  
  } }><FolderPlus></FolderPlus></Button>
  
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{billTitle[0]}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                   
                                   {pubDate[0]}

                       <div id='fullBill'>

                  <Form id='noteModalForm'>
  
  <Form.Group id='noteModalForm'  className="mb-3" >

    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
  
      <br></br>
  </Form.Group>
  </Form>
  
  <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(billTitle[0], billLink[0], null, pubDate[0], feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
  Save To Collection
  </Button>
  </Modal.Footer> 
  
    </div>
    </Modal.Body>
                     </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction name={feedName} url={apiURL} branch={feedBranch} topic={feedTopic}/>
                          </Modal>
          
          </Container >, document.getElementById(divID.toString()));

    }
  
  
    })();
  
    function linkToBill() {
      const linkTo = billLink[0]
  
    }
  }



  export function CreateFeedAPIAudio(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
    const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [docLink, setDocLink] = useState('');
  const [date, setDate] = useState('');


    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
       collectionCounter = localStorage.getItem('collectionNumber')
       collectionCounter++;
   
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
 
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
        var itemDescription = 'itemDescription'.concat(collectionCounter)
     

         localStorage.setItem(title,saveCollectionTitle)
         localStorage.setItem(detailLink,saveCollectionDetailLink)
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
         localStorage.setItem('collectionNumber', collectionCounter)
         window.location.reload()
    }

    function formatDate(inputDate) {
      const date = new Date(inputDate);
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const day = utcDate.getDate();
      const month = utcDate.getMonth() + 1;
      const year = utcDate.getFullYear();
    
      return `${month}-${day}-${year}`;
    }

    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo2LCJleHAiOjE3Mzc1MTYwOTV9.egtySkGqYkegQfYD0msRJDmLXsAGMZFIjd4SfEUqTR4",
    },
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`https://api.govglance.org/posts/recent?limit=1${apiURL}`, requestOptions);
  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          const data = await response.json();
  
          if (data.length > 0) {
            const firstItem = data[0];
            setTitle(firstItem.title);
            setLink(firstItem.url);
            setDocLink(firstItem.pdf_url);
            setDate(formatDate(firstItem.created_at));
            
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, [apiURL]);
  
    if (title.length === 0) {
      return null; // spinner
    }

    const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
      <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
        <FileText></FileText> {feedButtonName}
      </Button>
    ) : null;
  
    return ReactDOM.createPortal(
      <Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{title}</Card.Title>
        <Card.Body id='billBodyText'>{date}</Card.Body>
        <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(link, "_blank");
              }}><PlayCircle/> Audio</Button>
  
        {viewBillButton}

        <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic)
} }><FolderPlus></FolderPlus></Button>

<Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                     <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
                     <Modal.Body id='descriptionModalBody'>
                                 
                                 {date}

                     <div id='fullBill'>

                <Form id='noteModalForm'>

<Form.Group id='noteModalForm'  className="mb-3" >

  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
    type="text"
    as={"textarea"}
    rows={2}
    
    />

    <br></br>
</Form.Group>
</Form>

<Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
Save To Collection
</Button>
</Modal.Footer> 

  </div>
  </Modal.Body>
                   </Modal>
        
        <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                          <FeedFunction name={feedName} url={apiURL} branch={feedBranch} topic={feedTopic} buttonName={feedButtonName}/>
                        </Modal>
  
      </Container>,
      document.getElementById(divID.toString())
    );
  }
  

  export function CreateFeedAPICache(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
    const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [docLink, setDocLink] = useState('');
  const [date, setDate] = useState('');
 


    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
       collectionCounter = localStorage.getItem('collectionNumber')
       collectionCounter++;
   
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
 
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
        var itemDescription = 'itemDescription'.concat(collectionCounter)
     

         localStorage.setItem(title,saveCollectionTitle)
         localStorage.setItem(detailLink,saveCollectionDetailLink)
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
         localStorage.setItem('collectionNumber', collectionCounter)
         window.location.reload()
    }

    

    function formatDate(inputDate) {
      const date = new Date(inputDate);
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const day = utcDate.getDate();
      const month = utcDate.getMonth() + 1;
      const year = utcDate.getFullYear();
    
      return `${month}-${day}-${year}`;
    }

    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        "Authorization": "Bearer "+apiKey,
    },
    };

    const displayData = (cachedData) => {
      const firstItem = cachedData[0];
      let title = firstItem.status_title || firstItem.title;
      if (title.length > 180) {
          title = title.substring(0, 177) + '...';
      }
      setTitle(title);
      setLink(firstItem.url);
      if (firstItem.document_link) {
          setDocLink(firstItem.document_link);
      } else if (firstItem.download && firstItem.download.pdfLink) {
          setDocLink(firstItem.download.pdfLink);
      }
      setDate(formatDate(firstItem.created_at));
  };
    
  useEffect(() => {
    const fetchData = async () => {
        try {
            // Check local storage for cached data
            const cachedData = localStorage.getItem(feedName + 'cachedData');
            if (cachedData) {
                const parsedData = JSON.parse(cachedData);
                const firstCachedItem = parsedData[0];
                // console.log('Cached data:', parsedData);
                // console.log('First cached item:', firstCachedItem);

                // Fetch data from the API
                const response = await fetch(`https://api.govglance.org/posts/recent?limit=1${apiURL}`, requestOptions);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
               // console.log('Fetched data:', data);
            
                if (data.length > 0) {
                    const firstItem = data[0];
                    // console.log('First fetched item:', firstItem);

                    // Compare first item from API with cached data
                    if (firstItem.url === firstCachedItem.url) {
                        // If first items are the same, display cached data
                        displayData(parsedData);
                        // console.log('Using cached data');
                        return;
                    }
                }
            }
            // Fetch new data from the API
            const response = await fetch(`https://api.govglance.org/posts/recent?limit=1${apiURL}`, requestOptions);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            // console.log('Fetched data:', data);
            if (data.length > 0) {
                const firstItem = data[0];
                let title = firstItem.status_title || firstItem.title;
                if (title.length > 180) {
                    title = title.substring(0, 177) + '...';
                }
                setTitle(title);
                setLink(firstItem.url);
                if (firstItem.document_link) {
                    setDocLink(firstItem.document_link);
                } else if (firstItem.download && firstItem.download.pdfLink) {
                    setDocLink(firstItem.download.pdfLink);
                }
                setDate(formatDate(firstItem.created_at));
                localStorage.setItem(feedName + 'cachedData', JSON.stringify(data));
                // console.log('Using fetched data');
            } else {
                setTitle("Coming Soon");
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchData();
}, [apiURL]);
  
  
  
    if (title.length === 0) {
      return <Spinner animation="grow" variant="light" />
    }

    if (title === "Coming Soon") {
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText'>{title}</Card.Title>
          <br></br><br></br><br></br>
          <br></br><br></br><br></br>
        </Container>,document.getElementById(divID.toString())
      );
    }
   
    const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
      <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink); }}>
        <FileText></FileText> {feedButtonName}
      </Button>
    ) : null;

  
  
  
    return ReactDOM.createPortal(
      <Container id='feedContainerNormalView'>
        <Card.Title id='billText' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>{title}</Card.Title>
        <Card.Body id='billBodyText'>{date}</Card.Body>
        <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
          <InfoCircle /> Details
        </Button>
  
        {viewBillButton}

        <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic)
} }><FolderPlus></FolderPlus></Button>

<Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                     <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
                     <Modal.Body id='descriptionModalBody'>
                                 
                                 {date}

                     <div id='fullBill'>

                <Form id='noteModalForm'>

<Form.Group id='noteModalForm'  className="mb-3" >

  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
    type="text"
    as={"textarea"}
    rows={2}
    
    />

    <br></br>
</Form.Group>
</Form>

<Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
Save To Collection
</Button>
</Modal.Footer> 

  </div>
  </Modal.Body>
                   </Modal>
        
        <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                          <FeedFunction name={feedName} url={apiURL} branch={feedBranch} topic={feedTopic} buttonName={feedButtonName}/>
                        </Modal>
  
      </Container>,
      document.getElementById(divID.toString())
    );
  }

  export function CreateFeedAPIRecentCache(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
    const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // Share modal state
    const [linkCopied, setLinkCopied] = useState(false); // Track if the link is copied
    const [shareLink, setShareLink] = useState(''); // Link to share
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [docLink, setDocLink] = useState('');
    const [date, setDate] = useState('');
    const [source, setSource] = useState('');   
    const [nameForAPI, setNameForAPI] = useState('');
    const [itemSchema, setSchema] = useState('');
    const [itemID, setItemID] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingNewData, setLoadingNewData] = useState(false);
    const handleContainerClick = useHandleContainerClickRecents();
    const history = useHistory(); // Using react-router-dom's useHistory hook
    const handleNativeShare = async () => {
      if (navigator.share) {
        try {
          await navigator.share({
            title: 'Check this out!',
            text: 'Here’s something interesting I found:',
            url: shareLink,
          });
          console.log('Content shared successfully!');
        } catch (error) {
          console.error('Error sharing:', error);
        }
      } else {
        alert('Sharing is not supported on this device.');
      }
    };
    // Function to open the share modal
    const handleShareModalOpen = () => {
      const baseUrl = 'https://govglance.org';
      const fullUrl = `${baseUrl}/feed/united_states_of_america/${itemSchema}/${itemID}`;
      setShareLink(fullUrl);
      setShareModalIsOpen(true);
      setLinkCopied(false); // Reset copy state when opening the modal
    };
  
    // Function to copy the share link
    const handleCopyLink = () => {
      navigator.clipboard.writeText(shareLink)
        .then(() => setLinkCopied(true))
        .catch((error) => console.error('Error copying link:', error));
    };
  
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      var searchItem = document.getElementById('noteModalFeedItem').value;
      let collectionCounter = localStorage.getItem('collectionNumber');
      collectionCounter++;
      var title = 'title'.concat(collectionCounter);
      var detailLink = 'detailLink'.concat(collectionCounter);
      var docLink = 'docLink'.concat(collectionCounter);
      var itemNote = 'itemNote'.concat(collectionCounter);
      var itemDate = 'itemDate'.concat(collectionCounter);
      var feedName = 'feedName'.concat(collectionCounter);
      var branchName = 'branchName'.concat(collectionCounter);
      var topicName = 'topicName'.concat(collectionCounter);
        
      localStorage.setItem(title, saveCollectionTitle);
      localStorage.setItem(detailLink, saveCollectionDetailLink);
      localStorage.setItem(docLink, saveCollectionDocLink);
      localStorage.setItem(itemNote, searchItem);
      localStorage.setItem(itemDate, saveCollectionDate);
      localStorage.setItem(feedName, saveCollectionFeedName);
      localStorage.setItem(branchName, saveCollectionBranchName);
      localStorage.setItem(topicName, saveCollectionTopicName);
      localStorage.setItem('collectionNumber', collectionCounter);
      window.location.reload();
    }
  
    function formatDate(inputDate) {
      const date = new Date(inputDate);
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const day = utcDate.getDate();
      const month = utcDate.getMonth() + 1;
      const year = utcDate.getFullYear();
      
      return `${month}-${day}-${year}`;
    }
  
    const displayData = (data) => {
      const firstItem = data[0];
      let title = firstItem.status_title || firstItem.title;
      if (title.length > 135) {
        title = title.substring(0, 135) + '...';
      }
      setTitle(title);
      setItemID(firstItem.id);
      setLink(firstItem.url);
      setNameForAPI(firstItem.source);
      setSource(firstItem.collection_name);
      setSchema(firstItem.source);
      if (firstItem.document_link) {
        setDocLink(firstItem.document_link);
      } else if (firstItem.download && firstItem.download.pdfLink) {
        setDocLink(firstItem.download.pdfLink);
      } 
      setDate(formatDate(firstItem.created_at));
    };
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(apiURL, { method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+apiKey }});
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          displayData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, [apiURL]);
  
    if (title.length === 0) {
      return <Spinner animation="grow" variant="light" />;
    }
  
    if (title === "Coming Soon") {
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText'>{title}</Card.Title>
          <br></br><br></br><br></br>
          <br></br><br></br><br></br>
        </Container>,document.getElementById(divID.toString())
      );
    }
  
    const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
      <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
        <FileText></FileText> {feedButtonName}
      </Button>
    ) : null;
  
    return ReactDOM.createPortal(
      <Container id='feedContainerNormalViewRecents'>
        <Card.Title 
          onClick={() => {
            history.push({
              pathname: `/feed/united_states_of_america/${itemSchema}`,
              state: {
                apiURL,
                feedName,
                feedBranch,
                feedTopic,
                feedButtonName,
              },
            });
          }} 
          id='billBodyTextSource'
        >
          {source}
        </Card.Title>
    
        <Card.Title id='billText' onClick={(e) => {
          e.preventDefault();
          const linkToOpen = docLink || link;
          window.open(linkToOpen, "_blank");
        }}>{title}</Card.Title>
  
        <Card.Body id='billBodyText'>{date}</Card.Body>
        
        <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
          <InfoCircle /> Details
        </Button>
  
        {viewBillButton}
  
        <Button id='addToCollectionButton' onClick={() => { setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic) }}>
          <FolderPlus></FolderPlus>
        </Button>
  
        {/* Share Button */}
        <Button id="shareButton" onClick={handleShareModalOpen}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
          </svg>
        </Button>
  
        {loadingNewData && <Spinner animation="grow" variant="light" />}
  
        {/* Share Modal */}
    <Modal id="fullBillModal" show={shareModalIsOpen} size="xl" centered onHide={() => setShareModalIsOpen(false)}>
        <Modal.Header id="billModalHeader" closeButton>
          <h3>Share</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          <Form.Group id="noteModalForm">
            <Form.Control id="noteModalFeedItem" type="text" readOnly value={shareLink} />
          </Form.Group>
          <Modal.Footer id="shareFooter">
          <Button 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>
            <Button id="addToCollectionButton" onClick={handleCopyLink}>
              {linkCopied ? <ClipboardCheck /> : <Clipboard />}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
  
        <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
          <Modal.Body id='descriptionModalBody'>
            {date}
            <div id='fullBill'>
              <Form id='noteModalForm'>
                <Form.Group id='noteModalForm' className="mb-3">
                  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as={"textarea"} rows={2} />
                  <br />
                </Form.Group>
              </Form>
              <Modal.Footer id='addNoteModalFooter'> 
                <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => { saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false); }} >
                  Save To Collection
                </Button>
              </Modal.Footer> 
            </div>
          </Modal.Body>
        </Modal>
        
        <Button id='whDocsViewMoreButton' onClick={(e) => { e.preventDefault(); window.open('/us/recents', "_self") }}>
          <Files size={20}></Files> View More
        </Button>
        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
          <GenerateFeedRecent/>
        </Modal>
      </Container>,
      document.getElementById(divID.toString())
    );
  }

  export function CreateFeedAPIRecentCacheEnvironment(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
    const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // Share modal state
    const [linkCopied, setLinkCopied] = useState(false); // Track if the link is copied
    const [shareLink, setShareLink] = useState(''); // Link to share
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [docLink, setDocLink] = useState('');
    const [date, setDate] = useState('');
    const [source, setSource] = useState('');   
    const [nameForAPI, setNameForAPI] = useState('');
    const [itemSchema, setSchema] = useState('');
    const [itemID, setItemID] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingNewData, setLoadingNewData] = useState(false);
    const handleContainerClick = useHandleContainerClickRecents();
    const history = useHistory(); // Using react-router-dom's useHistory hook
  

    const handleNativeShare = async () => {
      if (navigator.share) {
        try {
          await navigator.share({
            title: 'Check this out!',
            text: 'Here’s something interesting I found:',
            url: shareLink,
          });
          console.log('Content shared successfully!');
        } catch (error) {
          console.error('Error sharing:', error);
        }
      } else {
        alert('Sharing is not supported on this device.');
      }
    };
    // Function to open the share modal
    const handleShareModalOpen = () => {
      const baseUrl = 'https://govglance.org';
      const fullUrl = `${baseUrl}/feed/united_states_of_america/${itemSchema}/${itemID}`;
      setShareLink(fullUrl);
      setShareModalIsOpen(true);
      setLinkCopied(false); // Reset copy state when opening the modal
    };
  
    // Function to copy the share link
    const handleCopyLink = () => {
      navigator.clipboard.writeText(shareLink)
        .then(() => setLinkCopied(true))
        .catch((error) => console.error('Error copying link:', error));
    };
  
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      var searchItem = document.getElementById('noteModalFeedItem').value;
      let collectionCounter = localStorage.getItem('collectionNumber');
      collectionCounter++;
      var title = 'title'.concat(collectionCounter);
      var detailLink = 'detailLink'.concat(collectionCounter);
      var docLink = 'docLink'.concat(collectionCounter);
      var itemNote = 'itemNote'.concat(collectionCounter);
      var itemDate = 'itemDate'.concat(collectionCounter);
      var feedName = 'feedName'.concat(collectionCounter);
      var branchName = 'branchName'.concat(collectionCounter);
      var topicName = 'topicName'.concat(collectionCounter);
        
      localStorage.setItem(title, saveCollectionTitle);
      localStorage.setItem(detailLink, saveCollectionDetailLink);
      localStorage.setItem(docLink, saveCollectionDocLink);
      localStorage.setItem(itemNote, searchItem);
      localStorage.setItem(itemDate, saveCollectionDate);
      localStorage.setItem(feedName, saveCollectionFeedName);
      localStorage.setItem(branchName, saveCollectionBranchName);
      localStorage.setItem(topicName, saveCollectionTopicName);
      localStorage.setItem('collectionNumber', collectionCounter);
      window.location.reload();
    }
  
    function formatDate(inputDate) {
      const date = new Date(inputDate);
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const day = utcDate.getDate();
      const month = utcDate.getMonth() + 1;
      const year = utcDate.getFullYear();
      
      return `${month}-${day}-${year}`;
    }
  
    const displayData = (data) => {
      const firstItem = data[0];
      let title = firstItem.status_title || firstItem.title;
      if (title.length > 135) {
        title = title.substring(0, 135) + '...';
      }
      setTitle(title);
      setItemID(firstItem.id);
      setLink(firstItem.url);
      setNameForAPI(firstItem.source);
      setSource(firstItem.collection_name);
      setSchema(firstItem.source);
      if (firstItem.document_link) {
        setDocLink(firstItem.document_link);
      } else if (firstItem.download && firstItem.download.pdfLink) {
        setDocLink(firstItem.download.pdfLink);
      } 
      setDate(formatDate(firstItem.created_at));
    };
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(apiURL, { method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+apiKey }});
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          displayData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, [apiURL]);
  
    if (title.length === 0) {
      return <Spinner animation="grow" variant="light" />;
    }
  
    if (title === "Coming Soon") {
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText'>{title}</Card.Title>
          <br></br><br></br><br></br>
          <br></br><br></br><br></br>
        </Container>,document.getElementById(divID.toString())
      );
    }
  
    const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
      <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
        <FileText></FileText> {feedButtonName}
      </Button>
    ) : null;
  
    return ReactDOM.createPortal(
      <Container id='feedContainerNormalViewRecents'>
        <Card.Title 
          onClick={() => {
            history.push({
              pathname: `/feed/united_states_of_america/${itemSchema}`,
              state: {
                apiURL,
                feedName,
                feedBranch,
                feedTopic,
                feedButtonName,
              },
            });
          }} 
          id='billBodyTextSource'
        >
          {source}
        </Card.Title>
    
        <Card.Title id='billText' onClick={(e) => {
          e.preventDefault();
          const linkToOpen = docLink || link;
          window.open(linkToOpen, "_blank");
        }}>{title}</Card.Title>
  
        <Card.Body id='billBodyText'>{date}</Card.Body>
        
        <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
          <InfoCircle /> Details
        </Button>
  
        {viewBillButton}
  
        <Button id='addToCollectionButton' onClick={() => { setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic) }}>
          <FolderPlus></FolderPlus>
        </Button>
  
        {/* Share Button */}
        <Button id="shareButton" onClick={handleShareModalOpen}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
          </svg>
        </Button>
  
        {loadingNewData && <Spinner animation="grow" variant="light" />}
  
        {/* Share Modal */}
    <Modal id="fullBillModal" show={shareModalIsOpen} size="xl" centered onHide={() => setShareModalIsOpen(false)}>
        <Modal.Header id="billModalHeader" closeButton>
          <h3>Share</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          <Form.Group id="noteModalForm">
            <Form.Control id="noteModalFeedItem" type="text" readOnly value={shareLink} />
          </Form.Group>
          <Modal.Footer id="shareFooter">
          <Button 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>
            <Button id="addToCollectionButton" onClick={handleCopyLink}>
              {linkCopied ? <ClipboardCheck /> : <Clipboard />}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
  
        <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
          <Modal.Body id='descriptionModalBody'>
            {date}
            <div id='fullBill'>
              <Form id='noteModalForm'>
                <Form.Group id='noteModalForm' className="mb-3">
                  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as={"textarea"} rows={2} />
                  <br />
                </Form.Group>
              </Form>
              <Modal.Footer id='addNoteModalFooter'> 
                <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => { saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false); }} >
                  Save To Collection
                </Button>
              </Modal.Footer> 
            </div>
          </Modal.Body>
        </Modal>
        
        <Button id='whDocsViewMoreButton' onClick={(e) => { e.preventDefault(); window.open('/us/recents/environment', "_self") }}>
          <Files size={20}></Files> View More
        </Button>
        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
          <GenerateFeedRecent/>
        </Modal>
      </Container>,
      document.getElementById(divID.toString())
    );
  }

    export function CreateFeedAPIRecentCacheEconomy(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
    const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // Share modal state
    const [linkCopied, setLinkCopied] = useState(false); // Track if the link is copied
    const [shareLink, setShareLink] = useState(''); // Link to share
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [docLink, setDocLink] = useState('');
    const [date, setDate] = useState('');
    const [source, setSource] = useState('');   
    const [nameForAPI, setNameForAPI] = useState('');
    const [itemSchema, setSchema] = useState('');
    const [itemID, setItemID] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingNewData, setLoadingNewData] = useState(false);
    const handleContainerClick = useHandleContainerClickRecents();
    const history = useHistory(); // Using react-router-dom's useHistory hook
    const handleNativeShare = async () => {
      if (navigator.share) {
        try {
          await navigator.share({
            title: 'Check this out!',
            text: 'Here’s something interesting I found:',
            url: shareLink,
          });
          console.log('Content shared successfully!');
        } catch (error) {
          console.error('Error sharing:', error);
        }
      } else {
        alert('Sharing is not supported on this device.');
      }
    };
    // Function to open the share modal
    const handleShareModalOpen = () => {
      const baseUrl = 'https://govglance.org';
      const fullUrl = `${baseUrl}/feed/united_states_of_america/${itemSchema}/${itemID}`;
      setShareLink(fullUrl);
      setShareModalIsOpen(true);
      setLinkCopied(false); // Reset copy state when opening the modal
    };
  
    // Function to copy the share link
    const handleCopyLink = () => {
      navigator.clipboard.writeText(shareLink)
        .then(() => setLinkCopied(true))
        .catch((error) => console.error('Error copying link:', error));
    };
  
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      var searchItem = document.getElementById('noteModalFeedItem').value;
      let collectionCounter = localStorage.getItem('collectionNumber');
      collectionCounter++;
      var title = 'title'.concat(collectionCounter);
      var detailLink = 'detailLink'.concat(collectionCounter);
      var docLink = 'docLink'.concat(collectionCounter);
      var itemNote = 'itemNote'.concat(collectionCounter);
      var itemDate = 'itemDate'.concat(collectionCounter);
      var feedName = 'feedName'.concat(collectionCounter);
      var branchName = 'branchName'.concat(collectionCounter);
      var topicName = 'topicName'.concat(collectionCounter);
        
      localStorage.setItem(title, saveCollectionTitle);
      localStorage.setItem(detailLink, saveCollectionDetailLink);
      localStorage.setItem(docLink, saveCollectionDocLink);
      localStorage.setItem(itemNote, searchItem);
      localStorage.setItem(itemDate, saveCollectionDate);
      localStorage.setItem(feedName, saveCollectionFeedName);
      localStorage.setItem(branchName, saveCollectionBranchName);
      localStorage.setItem(topicName, saveCollectionTopicName);
      localStorage.setItem('collectionNumber', collectionCounter);
      window.location.reload();
    }
  
    function formatDate(inputDate) {
      const date = new Date(inputDate);
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const day = utcDate.getDate();
      const month = utcDate.getMonth() + 1;
      const year = utcDate.getFullYear();
      
      return `${month}-${day}-${year}`;
    }
  
    const displayData = (data) => {
      const firstItem = data[0];
      let title = firstItem.status_title || firstItem.title;
      if (title.length > 135) {
        title = title.substring(0, 135) + '...';
      }
      setTitle(title);
      setItemID(firstItem.id);
      setLink(firstItem.url);
      setNameForAPI(firstItem.source);
      setSource(firstItem.collection_name);
      setSchema(firstItem.source);
      if (firstItem.document_link) {
        setDocLink(firstItem.document_link);
      } else if (firstItem.download && firstItem.download.pdfLink) {
        setDocLink(firstItem.download.pdfLink);
      } 
      setDate(formatDate(firstItem.created_at));
    };
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(apiURL, { method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+apiKey }});
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          displayData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, [apiURL]);
  
    if (title.length === 0) {
      return <Spinner animation="grow" variant="light" />;
    }
  
    if (title === "Coming Soon") {
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText'>{title}</Card.Title>
          <br></br><br></br><br></br>
          <br></br><br></br><br></br>
        </Container>,document.getElementById(divID.toString())
      );
    }
  
    const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
      <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
        <FileText></FileText> {feedButtonName}
      </Button>
    ) : null;
  
    return ReactDOM.createPortal(
      <Container id='feedContainerNormalViewRecents'>
        <Card.Title 
          onClick={() => {
            history.push({
              pathname: `/feed/united_states_of_america/${itemSchema}`,
              state: {
                apiURL,
                feedName,
                feedBranch,
                feedTopic,
                feedButtonName,
              },
            });
          }} 
          id='billBodyTextSource'
        >
          {source}
        </Card.Title>
    
        <Card.Title id='billText' onClick={(e) => {
          e.preventDefault();
          const linkToOpen = docLink || link;
          window.open(linkToOpen, "_blank");
        }}>{title}</Card.Title>
  
        <Card.Body id='billBodyText'>{date}</Card.Body>
        
        <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
          <InfoCircle /> Details
        </Button>
  
        {viewBillButton}
  
        <Button id='addToCollectionButton' onClick={() => { setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic) }}>
          <FolderPlus></FolderPlus>
        </Button>
  
        {/* Share Button */}
        <Button id="shareButton" onClick={handleShareModalOpen}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
          </svg>
        </Button>
  
        {loadingNewData && <Spinner animation="grow" variant="light" />}
  
        {/* Share Modal */}
    <Modal id="fullBillModal" show={shareModalIsOpen} size="xl" centered onHide={() => setShareModalIsOpen(false)}>
        <Modal.Header id="billModalHeader" closeButton>
          <h3>Share</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          <Form.Group id="noteModalForm">
            <Form.Control id="noteModalFeedItem" type="text" readOnly value={shareLink} />
          </Form.Group>
          <Modal.Footer id="shareFooter">
          <Button 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>
            <Button id="addToCollectionButton" onClick={handleCopyLink}>
              {linkCopied ? <ClipboardCheck /> : <Clipboard />}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
  
        <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
          <Modal.Body id='descriptionModalBody'>
            {date}
            <div id='fullBill'>
              <Form id='noteModalForm'>
                <Form.Group id='noteModalForm' className="mb-3">
                  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as={"textarea"} rows={2} />
                  <br />
                </Form.Group>
              </Form>
              <Modal.Footer id='addNoteModalFooter'> 
                <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => { saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false); }} >
                  Save To Collection
                </Button>
              </Modal.Footer> 
            </div>
          </Modal.Body>
        </Modal>
        
        <Button id='whDocsViewMoreButton' onClick={(e) => { e.preventDefault(); window.open('/us/recents/economy', "_self") }}>
          <Files size={20}></Files> View More
        </Button>
        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
          <GenerateFeedRecent/>
        </Modal>
      </Container>,
      document.getElementById(divID.toString())
    );
  }

  export function CreateFeedAPIRecentCacheForeignAffairs(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
    const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // Share modal state
    const [linkCopied, setLinkCopied] = useState(false); // Track if the link is copied
    const [shareLink, setShareLink] = useState(''); // Link to share
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [docLink, setDocLink] = useState('');
    const [date, setDate] = useState('');
    const [source, setSource] = useState('');   
    const [nameForAPI, setNameForAPI] = useState('');
    const [itemSchema, setSchema] = useState('');
    const [itemID, setItemID] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingNewData, setLoadingNewData] = useState(false);
    const handleContainerClick = useHandleContainerClickRecents();
    const history = useHistory(); // Using react-router-dom's useHistory hook
    const handleNativeShare = async () => {
      if (navigator.share) {
        try {
          await navigator.share({
            title: 'Check this out!',
            text: 'Here’s something interesting I found:',
            url: shareLink,
          });
          console.log('Content shared successfully!');
        } catch (error) {
          console.error('Error sharing:', error);
        }
      } else {
        alert('Sharing is not supported on this device.');
      }
    };
    // Function to open the share modal
    const handleShareModalOpen = () => {
      const baseUrl = 'https://govglance.org';
      const fullUrl = `${baseUrl}/feed/united_states_of_america/${itemSchema}/${itemID}`;
      setShareLink(fullUrl);
      setShareModalIsOpen(true);
      setLinkCopied(false); // Reset copy state when opening the modal
    };
  
    // Function to copy the share link
    const handleCopyLink = () => {
      navigator.clipboard.writeText(shareLink)
        .then(() => setLinkCopied(true))
        .catch((error) => console.error('Error copying link:', error));
    };
  
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      var searchItem = document.getElementById('noteModalFeedItem').value;
      let collectionCounter = localStorage.getItem('collectionNumber');
      collectionCounter++;
      var title = 'title'.concat(collectionCounter);
      var detailLink = 'detailLink'.concat(collectionCounter);
      var docLink = 'docLink'.concat(collectionCounter);
      var itemNote = 'itemNote'.concat(collectionCounter);
      var itemDate = 'itemDate'.concat(collectionCounter);
      var feedName = 'feedName'.concat(collectionCounter);
      var branchName = 'branchName'.concat(collectionCounter);
      var topicName = 'topicName'.concat(collectionCounter);
        
      localStorage.setItem(title, saveCollectionTitle);
      localStorage.setItem(detailLink, saveCollectionDetailLink);
      localStorage.setItem(docLink, saveCollectionDocLink);
      localStorage.setItem(itemNote, searchItem);
      localStorage.setItem(itemDate, saveCollectionDate);
      localStorage.setItem(feedName, saveCollectionFeedName);
      localStorage.setItem(branchName, saveCollectionBranchName);
      localStorage.setItem(topicName, saveCollectionTopicName);
      localStorage.setItem('collectionNumber', collectionCounter);
      window.location.reload();
    }
  
    function formatDate(inputDate) {
      const date = new Date(inputDate);
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const day = utcDate.getDate();
      const month = utcDate.getMonth() + 1;
      const year = utcDate.getFullYear();
      
      return `${month}-${day}-${year}`;
    }
  
    const displayData = (data) => {
      const firstItem = data[0];
      let title = firstItem.status_title || firstItem.title;
      if (title.length > 135) {
        title = title.substring(0, 135) + '...';
      }
      setTitle(title);
      setItemID(firstItem.id);
      setLink(firstItem.url);
      setNameForAPI(firstItem.source);
      setSource(firstItem.collection_name);
      setSchema(firstItem.source);
      if (firstItem.document_link) {
        setDocLink(firstItem.document_link);
      } else if (firstItem.download && firstItem.download.pdfLink) {
        setDocLink(firstItem.download.pdfLink);
      } 
      setDate(formatDate(firstItem.created_at));
    };
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(apiURL, { method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+apiKey }});
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          displayData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, [apiURL]);
  
    if (title.length === 0) {
      return <Spinner animation="grow" variant="light" />;
    }
  
    if (title === "Coming Soon") {
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText'>{title}</Card.Title>
          <br></br><br></br><br></br>
          <br></br><br></br><br></br>
        </Container>,document.getElementById(divID.toString())
      );
    }
  
    const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
      <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
        <FileText></FileText> {feedButtonName}
      </Button>
    ) : null;
  
    return ReactDOM.createPortal(
      <Container id='feedContainerNormalViewRecents'>
        <Card.Title 
          onClick={() => {
            history.push({
              pathname: `/feed/united_states_of_america/${itemSchema}`,
              state: {
                apiURL,
                feedName,
                feedBranch,
                feedTopic,
                feedButtonName,
              },
            });
          }} 
          id='billBodyTextSource'
        >
          {source}
        </Card.Title>
    
        <Card.Title id='billText' onClick={(e) => {
          e.preventDefault();
          const linkToOpen = docLink || link;
          window.open(linkToOpen, "_blank");
        }}>{title}</Card.Title>
  
        <Card.Body id='billBodyText'>{date}</Card.Body>
        
        <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
          <InfoCircle /> Details
        </Button>
  
        {viewBillButton}
  
        <Button id='addToCollectionButton' onClick={() => { setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic) }}>
          <FolderPlus></FolderPlus>
        </Button>
  
        {/* Share Button */}
        <Button id="shareButton" onClick={handleShareModalOpen}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
          </svg>
        </Button>
  
        {loadingNewData && <Spinner animation="grow" variant="light" />}
  
        {/* Share Modal */}
    <Modal id="fullBillModal" show={shareModalIsOpen} size="xl" centered onHide={() => setShareModalIsOpen(false)}>
        <Modal.Header id="billModalHeader" closeButton>
          <h3>Share</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          <Form.Group id="noteModalForm">
            <Form.Control id="noteModalFeedItem" type="text" readOnly value={shareLink} />
          </Form.Group>
          <Modal.Footer id="shareFooter">
          <Button 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>
            <Button id="addToCollectionButton" onClick={handleCopyLink}>
              {linkCopied ? <ClipboardCheck /> : <Clipboard />}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
  
        <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
          <Modal.Body id='descriptionModalBody'>
            {date}
            <div id='fullBill'>
              <Form id='noteModalForm'>
                <Form.Group id='noteModalForm' className="mb-3">
                  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as={"textarea"} rows={2} />
                  <br />
                </Form.Group>
              </Form>
              <Modal.Footer id='addNoteModalFooter'> 
                <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => { saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false); }} >
                  Save To Collection
                </Button>
              </Modal.Footer> 
            </div>
          </Modal.Body>
        </Modal>
        
        <Button id='whDocsViewMoreButton' onClick={(e) => { e.preventDefault(); window.open('/us/recents/foreign_affairs', "_self") }}>
          <Files size={20}></Files> View More
        </Button>
        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
          <GenerateFeedRecent/>
        </Modal>
      </Container>,
      document.getElementById(divID.toString())
    );
  }

  export function CreateFeedAPIRecentCacheHealth(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
    const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // Share modal state
    const [linkCopied, setLinkCopied] = useState(false); // Track if the link is copied
    const [shareLink, setShareLink] = useState(''); // Link to share
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [docLink, setDocLink] = useState('');
    const [date, setDate] = useState('');
    const [source, setSource] = useState('');   
    const [nameForAPI, setNameForAPI] = useState('');
    const [itemSchema, setSchema] = useState('');
    const [itemID, setItemID] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingNewData, setLoadingNewData] = useState(false);
    const handleContainerClick = useHandleContainerClickRecents();
    const history = useHistory(); // Using react-router-dom's useHistory hook
    const handleNativeShare = async () => {
      if (navigator.share) {
        try {
          await navigator.share({
            title: 'Check this out!',
            text: 'Here’s something interesting I found:',
            url: shareLink,
          });
          console.log('Content shared successfully!');
        } catch (error) {
          console.error('Error sharing:', error);
        }
      } else {
        alert('Sharing is not supported on this device.');
      }
    };
    // Function to open the share modal
    const handleShareModalOpen = () => {
      const baseUrl = 'https://govglance.org';
      const fullUrl = `${baseUrl}/feed/united_states_of_america/${itemSchema}/${itemID}`;
      setShareLink(fullUrl);
      setShareModalIsOpen(true);
      setLinkCopied(false); // Reset copy state when opening the modal
    };
  
    // Function to copy the share link
    const handleCopyLink = () => {
      navigator.clipboard.writeText(shareLink)
        .then(() => setLinkCopied(true))
        .catch((error) => console.error('Error copying link:', error));
    };
  
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      var searchItem = document.getElementById('noteModalFeedItem').value;
      let collectionCounter = localStorage.getItem('collectionNumber');
      collectionCounter++;
      var title = 'title'.concat(collectionCounter);
      var detailLink = 'detailLink'.concat(collectionCounter);
      var docLink = 'docLink'.concat(collectionCounter);
      var itemNote = 'itemNote'.concat(collectionCounter);
      var itemDate = 'itemDate'.concat(collectionCounter);
      var feedName = 'feedName'.concat(collectionCounter);
      var branchName = 'branchName'.concat(collectionCounter);
      var topicName = 'topicName'.concat(collectionCounter);
        
      localStorage.setItem(title, saveCollectionTitle);
      localStorage.setItem(detailLink, saveCollectionDetailLink);
      localStorage.setItem(docLink, saveCollectionDocLink);
      localStorage.setItem(itemNote, searchItem);
      localStorage.setItem(itemDate, saveCollectionDate);
      localStorage.setItem(feedName, saveCollectionFeedName);
      localStorage.setItem(branchName, saveCollectionBranchName);
      localStorage.setItem(topicName, saveCollectionTopicName);
      localStorage.setItem('collectionNumber', collectionCounter);
      window.location.reload();
    }
  
    function formatDate(inputDate) {
      const date = new Date(inputDate);
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const day = utcDate.getDate();
      const month = utcDate.getMonth() + 1;
      const year = utcDate.getFullYear();
      
      return `${month}-${day}-${year}`;
    }
  
    const displayData = (data) => {
      const firstItem = data[0];
      let title = firstItem.status_title || firstItem.title;
      if (title.length > 135) {
        title = title.substring(0, 135) + '...';
      }
      setTitle(title);
      setItemID(firstItem.id);
      setLink(firstItem.url);
      setNameForAPI(firstItem.source);
      setSource(firstItem.collection_name);
      setSchema(firstItem.source);
      if (firstItem.document_link) {
        setDocLink(firstItem.document_link);
      } else if (firstItem.download && firstItem.download.pdfLink) {
        setDocLink(firstItem.download.pdfLink);
      } 
      setDate(formatDate(firstItem.created_at));
    };
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(apiURL, { method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+apiKey }});
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          displayData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, [apiURL]);
  
    if (title.length === 0) {
      return <Spinner animation="grow" variant="light" />;
    }
  
    if (title === "Coming Soon") {
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText'>{title}</Card.Title>
          <br></br><br></br><br></br>
          <br></br><br></br><br></br>
        </Container>,document.getElementById(divID.toString())
      );
    }
  
    const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
      <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
        <FileText></FileText> {feedButtonName}
      </Button>
    ) : null;
  
    return ReactDOM.createPortal(
      <Container id='feedContainerNormalViewRecents'>
        <Card.Title 
          onClick={() => {
            history.push({
              pathname: `/feed/united_states_of_america/${itemSchema}`,
              state: {
                apiURL,
                feedName,
                feedBranch,
                feedTopic,
                feedButtonName,
              },
            });
          }} 
          id='billBodyTextSource'
        >
          {source}
        </Card.Title>
    
        <Card.Title id='billText' onClick={(e) => {
          e.preventDefault();
          const linkToOpen = docLink || link;
          window.open(linkToOpen, "_blank");
        }}>{title}</Card.Title>
  
        <Card.Body id='billBodyText'>{date}</Card.Body>
        
        <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
          <InfoCircle /> Details
        </Button>
  
        {viewBillButton}
  
        <Button id='addToCollectionButton' onClick={() => { setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic) }}>
          <FolderPlus></FolderPlus>
        </Button>
  
        {/* Share Button */}
        <Button id="shareButton" onClick={handleShareModalOpen}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
          </svg>
        </Button>
  
        {loadingNewData && <Spinner animation="grow" variant="light" />}
  
        {/* Share Modal */}
    <Modal id="fullBillModal" show={shareModalIsOpen} size="xl" centered onHide={() => setShareModalIsOpen(false)}>
        <Modal.Header id="billModalHeader" closeButton>
          <h3>Share</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          <Form.Group id="noteModalForm">
            <Form.Control id="noteModalFeedItem" type="text" readOnly value={shareLink} />
          </Form.Group>
          <Modal.Footer id="shareFooter">
          <Button 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>
            <Button id="addToCollectionButton" onClick={handleCopyLink}>
              {linkCopied ? <ClipboardCheck /> : <Clipboard />}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
  
        <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
          <Modal.Body id='descriptionModalBody'>
            {date}
            <div id='fullBill'>
              <Form id='noteModalForm'>
                <Form.Group id='noteModalForm' className="mb-3">
                  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as={"textarea"} rows={2} />
                  <br />
                </Form.Group>
              </Form>
              <Modal.Footer id='addNoteModalFooter'> 
                <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => { saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false); }} >
                  Save To Collection
                </Button>
              </Modal.Footer> 
            </div>
          </Modal.Body>
        </Modal>
        
        <Button id='whDocsViewMoreButton' onClick={(e) => { e.preventDefault(); window.open('/us/recents/health', "_self") }}>
          <Files size={20}></Files> View More
        </Button>
        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
          <GenerateFeedRecent/>
        </Modal>
      </Container>,
      document.getElementById(divID.toString())
    );
  }

  export function CreateFeedAPIRecentCacheScienceTech(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
    const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // Share modal state
    const [linkCopied, setLinkCopied] = useState(false); // Track if the link is copied
    const [shareLink, setShareLink] = useState(''); // Link to share
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [docLink, setDocLink] = useState('');
    const [date, setDate] = useState('');
    const [source, setSource] = useState('');   
    const [nameForAPI, setNameForAPI] = useState('');
    const [itemSchema, setSchema] = useState('');
    const [itemID, setItemID] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingNewData, setLoadingNewData] = useState(false);
    const handleContainerClick = useHandleContainerClickRecents();
    const history = useHistory(); // Using react-router-dom's useHistory hook
    const handleNativeShare = async () => {
      if (navigator.share) {
        try {
          await navigator.share({
            title: 'Check this out!',
            text: 'Here’s something interesting I found:',
            url: shareLink,
          });
          console.log('Content shared successfully!');
        } catch (error) {
          console.error('Error sharing:', error);
        }
      } else {
        alert('Sharing is not supported on this device.');
      }
    };
    // Function to open the share modal
    const handleShareModalOpen = () => {
      const baseUrl = 'https://govglance.org';
      const fullUrl = `${baseUrl}/feed/united_states_of_america/${itemSchema}/${itemID}`;
      setShareLink(fullUrl);
      setShareModalIsOpen(true);
      setLinkCopied(false); // Reset copy state when opening the modal
    };
  
    // Function to copy the share link
    const handleCopyLink = () => {
      navigator.clipboard.writeText(shareLink)
        .then(() => setLinkCopied(true))
        .catch((error) => console.error('Error copying link:', error));
    };
  
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      var searchItem = document.getElementById('noteModalFeedItem').value;
      let collectionCounter = localStorage.getItem('collectionNumber');
      collectionCounter++;
      var title = 'title'.concat(collectionCounter);
      var detailLink = 'detailLink'.concat(collectionCounter);
      var docLink = 'docLink'.concat(collectionCounter);
      var itemNote = 'itemNote'.concat(collectionCounter);
      var itemDate = 'itemDate'.concat(collectionCounter);
      var feedName = 'feedName'.concat(collectionCounter);
      var branchName = 'branchName'.concat(collectionCounter);
      var topicName = 'topicName'.concat(collectionCounter);
        
      localStorage.setItem(title, saveCollectionTitle);
      localStorage.setItem(detailLink, saveCollectionDetailLink);
      localStorage.setItem(docLink, saveCollectionDocLink);
      localStorage.setItem(itemNote, searchItem);
      localStorage.setItem(itemDate, saveCollectionDate);
      localStorage.setItem(feedName, saveCollectionFeedName);
      localStorage.setItem(branchName, saveCollectionBranchName);
      localStorage.setItem(topicName, saveCollectionTopicName);
      localStorage.setItem('collectionNumber', collectionCounter);
      window.location.reload();
    }
  
    function formatDate(inputDate) {
      const date = new Date(inputDate);
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const day = utcDate.getDate();
      const month = utcDate.getMonth() + 1;
      const year = utcDate.getFullYear();
      
      return `${month}-${day}-${year}`;
    }
  
    const displayData = (data) => {
      const firstItem = data[0];
      let title = firstItem.status_title || firstItem.title;
      if (title.length > 135) {
        title = title.substring(0, 135) + '...';
      }
      setTitle(title);
      setItemID(firstItem.id);
      setLink(firstItem.url);
      setNameForAPI(firstItem.source);
      setSource(firstItem.collection_name);
      setSchema(firstItem.source);
      if (firstItem.document_link) {
        setDocLink(firstItem.document_link);
      } else if (firstItem.download && firstItem.download.pdfLink) {
        setDocLink(firstItem.download.pdfLink);
      } 
      setDate(formatDate(firstItem.created_at));
    };
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(apiURL, { method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+apiKey }});
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          displayData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, [apiURL]);
  
    if (title.length === 0) {
      return <Spinner animation="grow" variant="light" />;
    }
  
    if (title === "Coming Soon") {
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText'>{title}</Card.Title>
          <br></br><br></br><br></br>
          <br></br><br></br><br></br>
        </Container>,document.getElementById(divID.toString())
      );
    }
  
    const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
      <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
        <FileText></FileText> {feedButtonName}
      </Button>
    ) : null;
  
    return ReactDOM.createPortal(
      <Container id='feedContainerNormalViewRecents'>
        <Card.Title 
          onClick={() => {
            history.push({
              pathname: `/feed/united_states_of_america/${itemSchema}`,
              state: {
                apiURL,
                feedName,
                feedBranch,
                feedTopic,
                feedButtonName,
              },
            });
          }} 
          id='billBodyTextSource'
        >
          {source}
        </Card.Title>
    
        <Card.Title id='billText' onClick={(e) => {
          e.preventDefault();
          const linkToOpen = docLink || link;
          window.open(linkToOpen, "_blank");
        }}>{title}</Card.Title>
  
        <Card.Body id='billBodyText'>{date}</Card.Body>
        
        <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
          <InfoCircle /> Details
        </Button>
  
        {viewBillButton}
  
        <Button id='addToCollectionButton' onClick={() => { setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic) }}>
          <FolderPlus></FolderPlus>
        </Button>
  
        {/* Share Button */}
        <Button id="shareButton" onClick={handleShareModalOpen}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
          </svg>
        </Button>
  
        {loadingNewData && <Spinner animation="grow" variant="light" />}
  
        {/* Share Modal */}
    <Modal id="fullBillModal" show={shareModalIsOpen} size="xl" centered onHide={() => setShareModalIsOpen(false)}>
        <Modal.Header id="billModalHeader" closeButton>
          <h3>Share</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          <Form.Group id="noteModalForm">
            <Form.Control id="noteModalFeedItem" type="text" readOnly value={shareLink} />
          </Form.Group>
          <Modal.Footer id="shareFooter">
          <Button 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>
            <Button id="addToCollectionButton" onClick={handleCopyLink}>
              {linkCopied ? <ClipboardCheck /> : <Clipboard />}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
  
        <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
          <Modal.Body id='descriptionModalBody'>
            {date}
            <div id='fullBill'>
              <Form id='noteModalForm'>
                <Form.Group id='noteModalForm' className="mb-3">
                  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as={"textarea"} rows={2} />
                  <br />
                </Form.Group>
              </Form>
              <Modal.Footer id='addNoteModalFooter'> 
                <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => { saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false); }} >
                  Save To Collection
                </Button>
              </Modal.Footer> 
            </div>
          </Modal.Body>
        </Modal>
        
        <Button id='whDocsViewMoreButton' onClick={(e) => { e.preventDefault(); window.open('/us/recents/science&tech', "_self") }}>
          <Files size={20}></Files> View More
        </Button>
        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
          <GenerateFeedRecent/>
        </Modal>
      </Container>,
      document.getElementById(divID.toString())
    );
  }

  export function CreateFeedAPIRecentCacheDefense(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
    const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // Share modal state
    const [linkCopied, setLinkCopied] = useState(false); // Track if the link is copied
    const [shareLink, setShareLink] = useState(''); // Link to share
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [docLink, setDocLink] = useState('');
    const [date, setDate] = useState('');
    const [source, setSource] = useState('');   
    const [nameForAPI, setNameForAPI] = useState('');
    const [itemSchema, setSchema] = useState('');
    const [itemID, setItemID] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingNewData, setLoadingNewData] = useState(false);
    const handleContainerClick = useHandleContainerClickRecents();
    const history = useHistory(); // Using react-router-dom's useHistory hook
    const handleNativeShare = async () => {
      if (navigator.share) {
        try {
          await navigator.share({
            title: 'Check this out!',
            text: 'Here’s something interesting I found:',
            url: shareLink,
          });
          console.log('Content shared successfully!');
        } catch (error) {
          console.error('Error sharing:', error);
        }
      } else {
        alert('Sharing is not supported on this device.');
      }
    };
    // Function to open the share modal
    const handleShareModalOpen = () => {
      const baseUrl = 'https://govglance.org';
      const fullUrl = `${baseUrl}/feed/united_states_of_america/${itemSchema}/${itemID}`;
      setShareLink(fullUrl);
      setShareModalIsOpen(true);
      setLinkCopied(false); // Reset copy state when opening the modal
    };
  
    // Function to copy the share link
    const handleCopyLink = () => {
      navigator.clipboard.writeText(shareLink)
        .then(() => setLinkCopied(true))
        .catch((error) => console.error('Error copying link:', error));
    };
  
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      var searchItem = document.getElementById('noteModalFeedItem').value;
      let collectionCounter = localStorage.getItem('collectionNumber');
      collectionCounter++;
      var title = 'title'.concat(collectionCounter);
      var detailLink = 'detailLink'.concat(collectionCounter);
      var docLink = 'docLink'.concat(collectionCounter);
      var itemNote = 'itemNote'.concat(collectionCounter);
      var itemDate = 'itemDate'.concat(collectionCounter);
      var feedName = 'feedName'.concat(collectionCounter);
      var branchName = 'branchName'.concat(collectionCounter);
      var topicName = 'topicName'.concat(collectionCounter);
        
      localStorage.setItem(title, saveCollectionTitle);
      localStorage.setItem(detailLink, saveCollectionDetailLink);
      localStorage.setItem(docLink, saveCollectionDocLink);
      localStorage.setItem(itemNote, searchItem);
      localStorage.setItem(itemDate, saveCollectionDate);
      localStorage.setItem(feedName, saveCollectionFeedName);
      localStorage.setItem(branchName, saveCollectionBranchName);
      localStorage.setItem(topicName, saveCollectionTopicName);
      localStorage.setItem('collectionNumber', collectionCounter);
      window.location.reload();
    }
  
    function formatDate(inputDate) {
      const date = new Date(inputDate);
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const day = utcDate.getDate();
      const month = utcDate.getMonth() + 1;
      const year = utcDate.getFullYear();
      
      return `${month}-${day}-${year}`;
    }
  
    const displayData = (data) => {
      const firstItem = data[0];
      let title = firstItem.status_title || firstItem.title;
      if (title.length > 135) {
        title = title.substring(0, 135) + '...';
      }
      setTitle(title);
      setItemID(firstItem.id);
      setLink(firstItem.url);
      setNameForAPI(firstItem.source);
      setSource(firstItem.collection_name);
      setSchema(firstItem.source);
      if (firstItem.document_link) {
        setDocLink(firstItem.document_link);
      } else if (firstItem.download && firstItem.download.pdfLink) {
        setDocLink(firstItem.download.pdfLink);
      } 
      setDate(formatDate(firstItem.created_at));
    };
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(apiURL, { method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+apiKey }});
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          displayData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, [apiURL]);
  
    if (title.length === 0) {
      return <Spinner animation="grow" variant="light" />;
    }
  
    if (title === "Coming Soon") {
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText'>{title}</Card.Title>
          <br></br><br></br><br></br>
          <br></br><br></br><br></br>
        </Container>,document.getElementById(divID.toString())
      );
    }
  
    const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
      <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
        <FileText></FileText> {feedButtonName}
      </Button>
    ) : null;
  
    return ReactDOM.createPortal(
      <Container id='feedContainerNormalViewRecents'>
        <Card.Title 
          onClick={() => {
            history.push({
              pathname: `/feed/united_states_of_america/${itemSchema}`,
              state: {
                apiURL,
                feedName,
                feedBranch,
                feedTopic,
                feedButtonName,
              },
            });
          }} 
          id='billBodyTextSource'
        >
          {source}
        </Card.Title>
    
        <Card.Title id='billText' onClick={(e) => {
          e.preventDefault();
          const linkToOpen = docLink || link;
          window.open(linkToOpen, "_blank");
        }}>{title}</Card.Title>
  
        <Card.Body id='billBodyText'>{date}</Card.Body>
        
        <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
          <InfoCircle /> Details
        </Button>
  
        {viewBillButton}
  
        <Button id='addToCollectionButton' onClick={() => { setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic) }}>
          <FolderPlus></FolderPlus>
        </Button>
  
        {/* Share Button */}
        <Button id="shareButton" onClick={handleShareModalOpen}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
          </svg>
        </Button>
  
        {loadingNewData && <Spinner animation="grow" variant="light" />}
  
        {/* Share Modal */}
    <Modal id="fullBillModal" show={shareModalIsOpen} size="xl" centered onHide={() => setShareModalIsOpen(false)}>
        <Modal.Header id="billModalHeader" closeButton>
          <h3>Share</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          <Form.Group id="noteModalForm">
            <Form.Control id="noteModalFeedItem" type="text" readOnly value={shareLink} />
          </Form.Group>
          <Modal.Footer id="shareFooter">
          <Button 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>
            <Button id="addToCollectionButton" onClick={handleCopyLink}>
              {linkCopied ? <ClipboardCheck /> : <Clipboard />}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
  
        <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
          <Modal.Body id='descriptionModalBody'>
            {date}
            <div id='fullBill'>
              <Form id='noteModalForm'>
                <Form.Group id='noteModalForm' className="mb-3">
                  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as={"textarea"} rows={2} />
                  <br />
                </Form.Group>
              </Form>
              <Modal.Footer id='addNoteModalFooter'> 
                <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => { saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false); }} >
                  Save To Collection
                </Button>
              </Modal.Footer> 
            </div>
          </Modal.Body>
        </Modal>
        
        <Button id='whDocsViewMoreButton' onClick={(e) => { e.preventDefault(); window.open('/us/recents/defense', "_self") }}>
          <Files size={20}></Files> View More
        </Button>
        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
          <GenerateFeedRecent/>
        </Modal>
      </Container>,
      document.getElementById(divID.toString())
    );
  }
  
  

  export function CreateFeedAPIRecentCacheCanada(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
    const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // Share modal state
    const [linkCopied, setLinkCopied] = useState(false); // Track if the link is copied
    const [shareLink, setShareLink] = useState(''); // Link to share
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [docLink, setDocLink] = useState('');
    const [date, setDate] = useState('');
    const [source, setSource] = useState('');   
    const [nameForAPI, setNameForAPI] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingNewData, setLoadingNewData] = useState(false);
    const handleContainerClick = useHandleContainerClickRecentsCanada();
    const history = useHistory(); // Using react-router-dom's useHistory hook
  
    // Function to open the share modal
    const handleShareModalOpen = () => {
      const baseUrl = 'https://govglance.org';
      const fullUrl = `${baseUrl}/feed/canada/${source}`;
      setShareLink(fullUrl);
      setShareModalIsOpen(true);
      setLinkCopied(false); // Reset copy state when opening the modal
    };
  
    // Function to copy the share link
    const handleCopyLink = () => {
      navigator.clipboard.writeText(shareLink)
        .then(() => setLinkCopied(true))
        .catch((error) => console.error('Error copying link:', error));
    };
  
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      var searchItem = document.getElementById('noteModalFeedItem').value;
      let collectionCounter = localStorage.getItem('collectionNumber');
      collectionCounter++;
      var title = 'title'.concat(collectionCounter);
      var detailLink = 'detailLink'.concat(collectionCounter);
      var docLink = 'docLink'.concat(collectionCounter);
      var itemNote = 'itemNote'.concat(collectionCounter);
      var itemDate = 'itemDate'.concat(collectionCounter);
      var feedName = 'feedName'.concat(collectionCounter);
      var branchName = 'branchName'.concat(collectionCounter);
      var topicName = 'topicName'.concat(collectionCounter);
        
      localStorage.setItem(title, saveCollectionTitle);
      localStorage.setItem(detailLink, saveCollectionDetailLink);
      localStorage.setItem(docLink, saveCollectionDocLink);
      localStorage.setItem(itemNote, searchItem);
      localStorage.setItem(itemDate, saveCollectionDate);
      localStorage.setItem(feedName, saveCollectionFeedName);
      localStorage.setItem(branchName, saveCollectionBranchName);
      localStorage.setItem(topicName, saveCollectionTopicName);
      localStorage.setItem('collectionNumber', collectionCounter);
      window.location.reload();
    }
  
    function formatDate(inputDate) {
      const date = new Date(inputDate);
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const day = utcDate.getDate();
      const month = utcDate.getMonth() + 1;
      const year = utcDate.getFullYear();
      
      return `${month}-${day}-${year}`;
    }
  
    const displayData = (data) => {
      const firstItem = data[0];
      let title = firstItem.status_title || firstItem.title;
      if (title.length > 180) {
        title = title.substring(0, 177) + '...';
      }
      setTitle(title);
      setLink(firstItem.url);
      setNameForAPI(firstItem.source);
      setSource(firstItem.collection_name);
      if (firstItem.document_link) {
        setDocLink(firstItem.document_link);
      } else if (firstItem.download && firstItem.download.pdfLink) {
        setDocLink(firstItem.download.pdfLink);
      }
      setDate(formatDate(firstItem.created_at));
    };
    const handleNativeShare = async () => {
      if (navigator.share) {
        try {
          await navigator.share({
            title: 'Check this out!',
            text: 'Here’s something interesting I found:',
            url: shareLink,
          });
          console.log('Content shared successfully!');
        } catch (error) {
          console.error('Error sharing:', error);
        }
      } else {
        alert('Sharing is not supported on this device.');
      }
    };
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(apiURL, { method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+apiKey }});
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          displayData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, [apiURL]);
  
    if (title.length === 0) {
      return <Spinner animation="grow" variant="light" />;
    }
  
    if (title === "Coming Soon") {
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText'>{title}</Card.Title>
          <br></br><br></br><br></br>
          <br></br><br></br><br></br>
        </Container>,document.getElementById(divID.toString())
      );
    }
  
    const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
      <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, '_blank'); }}>
        <FileText></FileText> {feedButtonName}
      </Button>
    ) : null;
  
    return ReactDOM.createPortal(
      <Container id='feedContainerNormalView'>
        <Card.Title 
          onClick={() => {
            history.push({
              pathname: `/feed/canada/${source}`,
              state: {
                apiURL,
                feedName,
                feedBranch,
                feedTopic,
                feedButtonName,
              },
            });
          }} 
          id='billBodyTextSource'
        >
          {source}
        </Card.Title>
    
        <Card.Title id='billText' onClick={(e) => {
          e.preventDefault();
          const linkToOpen = docLink || link;
          window.open(linkToOpen, '_blank');
        }}>{title}</Card.Title>
  
        <Card.Body id='billBodyText'>{date}</Card.Body>
        
        <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
          <InfoCircle /> Details
        </Button>
  
        {viewBillButton}
  
        <Button id='addToCollectionButton' onClick={() => { setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic) }}>
          <FolderPlus></FolderPlus>
        </Button>
  
        {/* Share Button */}
        <Button id="shareButton" onClick={handleShareModalOpen}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
          </svg>
        </Button>
  
        {loadingNewData && <Spinner animation="grow" variant="light" />}
  
        {/* Share Modal */}
    <Modal id="fullBillModal" show={shareModalIsOpen} size="xl" centered onHide={() => setShareModalIsOpen(false)}>
        <Modal.Header id="billModalHeader" closeButton>
          <h3>Share</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          <Form.Group id="noteModalForm">
            <Form.Control id="noteModalFeedItem" type="text" readOnly value={shareLink} />
          </Form.Group>
          <Modal.Footer id="shareFooter">
          <Button 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>
            <Button id="addToCollectionButton" onClick={handleCopyLink}>
              {linkCopied ? <ClipboardCheck /> : <Clipboard />}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
  
        <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
          <Modal.Body id='descriptionModalBody'>
            {date}
            <div id='fullBill'>
              <Form id='noteModalForm'>
                <Form.Group id='noteModalForm' className="mb-3">
                  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as={"textarea"} rows={2} />
                  <br />
                </Form.Group>
              </Form>
              <Modal.Footer id='addNoteModalFooter'> 
                <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => { saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false); }} >
                  Save To Collection
                </Button>
              </Modal.Footer> 
            </div>
          </Modal.Body>
        </Modal>
        
        <Button id='whDocsViewMoreButton' onClick={() => setFeedItemModalIsOpen(true)}>
          <Files size={20}></Files> View More
        </Button>
        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
          <GenerateFeedRecentCanada/>
        </Modal>
      </Container>,
      document.getElementById(divID.toString())
    );
  }
  


  export function CreateFeedAPIRecentCacheMexico(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [docLink, setDocLink] = useState('');
    const [date, setDate] = useState('');
    const [source, setSource] = useState('');   
    const [sourceURL, setSourceURL] = useState(''); 
    const [nameForAPI, setNameForAPI] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingNewData, setLoadingNewData] = useState(false);
    const handleContainerClick = useHandleContainerClickRecentsMexico();
  
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      var searchItem = document.getElementById('noteModalFeedItem').value;
       let collectionCounter = localStorage.getItem('collectionNumber');
      collectionCounter++;
  
      var title = 'title'.concat(collectionCounter);
      var detailLink = 'detailLink'.concat(collectionCounter);
      var docLink = 'docLink'.concat(collectionCounter);
      var itemNote = 'itemNote'.concat(collectionCounter);
      var itemDate = 'itemDate'.concat(collectionCounter);
      var feedName = 'feedName'.concat(collectionCounter);
      var branchName = 'branchName'.concat(collectionCounter);
      var topicName = 'topicName'.concat(collectionCounter);
      
      localStorage.setItem(title, saveCollectionTitle);
      localStorage.setItem(detailLink, saveCollectionDetailLink);
      localStorage.setItem(docLink, saveCollectionDocLink);
      localStorage.setItem(itemNote, searchItem);
      localStorage.setItem(itemDate, saveCollectionDate);
      localStorage.setItem(feedName, saveCollectionFeedName);
      localStorage.setItem(branchName, saveCollectionBranchName);
      localStorage.setItem(topicName, saveCollectionTopicName);
      localStorage.setItem('collectionNumber', collectionCounter);
      window.location.reload();
    }
  
    function formatDate(inputDate) {
      const date = new Date(inputDate);
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const day = utcDate.getDate();
      const month = utcDate.getMonth() + 1;
      const year = utcDate.getFullYear();
    
      return `${month}-${day}-${year}`;
    }
  
    const displayData = (data) => {
      const firstItem = data[0];
      let title = firstItem.status_title || firstItem.title;
      if (title.length > 180) {
        title = title.substring(0, 177) + '...';
      }
      setTitle(title);
      setLink(firstItem.url);
      setNameForAPI(firstItem.source)
      setSource(firstItem.collection_name)
      if (firstItem.document_link) {
        setDocLink(firstItem.document_link);
      } else if (firstItem.download && firstItem.download.pdfLink) {
        setDocLink(firstItem.download.pdfLink);
      } else {
        // Handle no document link case
      }
      setDate(formatDate(firstItem.created_at));
    };
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(apiURL, { method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+apiKey }});
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          displayData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, [apiURL]);
  
    if (title.length === 0) {
      return <Spinner animation="grow" variant="light" />;
    }
  

  
    if (title === "Coming Soon") {
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText'>{title}</Card.Title>
          <br></br><br></br><br></br>
          <br></br><br></br><br></br>
        </Container>,document.getElementById(divID.toString())
      );
    }
   
    const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
      <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
        <FileText></FileText> {feedButtonName}
      </Button>
    ) : null;
  
  
  
  
    return ReactDOM.createPortal(
      <Container id='feedContainerNormalView'>
   <Card.Title onClick={() => handleContainerClick(nameForAPI, source, "", "", "")} id='billBodyTextSource'>{source}</Card.Title>
  
        <Card.Title id='billText' onClick={(e) => {
    e.preventDefault();
    const linkToOpen = docLink || link;
    window.open(linkToOpen, "_blank");
  }}>{title}</Card.Title>
        <Card.Body id='billBodyText'>{date}</Card.Body>
      
        <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
          <InfoCircle /> Details
        </Button>
  
        {viewBillButton}
  
        <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic)
  } }><FolderPlus></FolderPlus></Button>
  
  {loadingNewData && <Spinner animation="grow" variant="light" />}
  
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                     <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
                     <Modal.Body id='descriptionModalBody'>
                                 
                                 {date}
  
                     <div id='fullBill'>
  
                <Form id='noteModalForm'>
  
  <Form.Group id='noteModalForm'  className="mb-3" >
  
  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
    type="text"
    as={"textarea"}
    rows={2}
    
    />
  
    <br></br>
  </Form.Group>
  </Form>
  
  <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
  Save To Collection
  </Button>
  </Modal.Footer> 
  
  </div>
  </Modal.Body>
                   </Modal>
        
        <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                          <GenerateFeedRecentMexico/>
                        </Modal>
  
      </Container>,
      document.getElementById(divID.toString())
    );
  }

  export function CreateFeedAPIRecentCacheUK(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [docLink, setDocLink] = useState('');
    const [date, setDate] = useState('');
    const [source, setSource] = useState('');   
    const [sourceURL, setSourceURL] = useState(''); 
    const [nameForAPI, setNameForAPI] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingNewData, setLoadingNewData] = useState(false);
    const handleContainerClick = UseHandleContainerClickRecentsUK();
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
      
      var searchItem = document.getElementById('noteModalFeedItem').value
    
       collectionCounter = localStorage.getItem('collectionNumber')
       collectionCounter++;
   
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
  
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
        var itemDescription = 'itemDescription'.concat(collectionCounter)
     
  
         localStorage.setItem(title,saveCollectionTitle)
         localStorage.setItem(detailLink,saveCollectionDetailLink)
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
         localStorage.setItem('collectionNumber', collectionCounter)
         window.location.reload()
    }
  
    function formatDate(inputDate) {
      const date = new Date(inputDate);
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const day = utcDate.getDate();
      const month = utcDate.getMonth() + 1;
      const year = utcDate.getFullYear();
    
      return `${month}-${day}-${year}`;
    }
  
    const displayData = (cachedData) => {
      const firstItem = cachedData[0];
      let title = firstItem.status_title || firstItem.title;
      if (title.length > 180) {
        title = title.substring(0, 177) + '...';
      }
      setTitle(title);
      setLink(firstItem.url);
      setNameForAPI(firstItem.source)
      setSource(firstItem.collection_name)
      if (firstItem.document_link) {
        setDocLink(firstItem.document_link);
    
      } else if (firstItem.download && firstItem.download.pdfLink) {
        setDocLink(firstItem.download.pdfLink);
    
      } else {
      
      }
      setDate(formatDate(firstItem.created_at));
    };
  
    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        "Authorization": "Bearer "+apiKey,
    },
    };
  
    // useEffect(() => {
    //   const fetchData = async () => {
    //     try {
    //       const cachedData = localStorage.getItem(feedName + 'cachedData');
    //       if (cachedData) {
    //         displayData(JSON.parse(cachedData));
    //       }
    
    //       const response = await fetch(`https://api.govglance.org/posts/recent/schema?limit=1&skip=0&schema=united_states_of_america`, requestOptions);
    //       if (!response.ok) {
    //         throw new Error(`HTTP error! status: ${response.status}`);
    //       }
    //       const data = await response.json();
          
    //       // Check if fetched data is different from cached data
    //       if (cachedData && JSON.stringify(data) !== cachedData) {
    //         // Save fetched data as new cached data
    //         localStorage.setItem(feedName + 'cachedData', JSON.stringify(data));
    //         console.log('lets check the local storage: ')
    //         console.log(localStorage.getItem(feedName + 'cachedData'))
    //         displayData(JSON.stringify(data));
    //       }
    //     } catch (error) {
    //       console.error('Error fetching data:', error);
    //     }
    //   };
    
    //   fetchData();
    // }, [apiURL]);
    
    useEffect(() => {
      const fetchData = async () => {
        try {
          const cachedData = localStorage.getItem(feedName + 'cachedData');
          if (!cachedData) {
            // If no cached data exists, fetch new data
            const response = await fetch(`https://api.govglance.org/posts/recent/schema?limit=1&skip=0&schema=united_kingdom`, requestOptions);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
    
            // Save fetched data as new cached data
            localStorage.setItem(feedName + 'cachedData', JSON.stringify(data));
            //console.log('Data fetched and saved to cache:', data);
    
            // Display fetched data
            displayData(data);
          } else {
            // If cached data exists, display it
            displayData(JSON.parse(cachedData));
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
    }, [apiURL]);
  
  
    if (title.length === 0) {
      return <Spinner animation="grow" variant="light" />
    }
  
    if (title === "Coming Soon") {
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText'>{title}</Card.Title>
          <br></br><br></br><br></br>
          <br></br><br></br><br></br>
        </Container>,document.getElementById(divID.toString())
      );
    }
   
    const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
      <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
        <FileText></FileText> {feedButtonName}
      </Button>
    ) : null;
  
  
  
  
    return ReactDOM.createPortal(
      <Container id='feedContainerNormalView'>
   <Card.Title onClick={() => handleContainerClick(nameForAPI, source, "", "", "")} id='billBodyTextSource'>{source}</Card.Title>
  
        <Card.Title id='billText' onClick={(e) => {
    e.preventDefault();
    const linkToOpen = docLink || link;
    window.open(linkToOpen,' _blank');
  }}>{title}</Card.Title>
        <Card.Body id='billBodyText'>{date}</Card.Body>
      
        <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
          <InfoCircle /> Details
        </Button>
  
        {viewBillButton}
  
        <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic)
  } }><FolderPlus></FolderPlus></Button>
  
  {loadingNewData && <Spinner animation="grow" variant="light" />}
  
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                     <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
                     <Modal.Body id='descriptionModalBody'>
                                 
                                 {date}
  
                     <div id='fullBill'>
  
                <Form id='noteModalForm'>
  
  <Form.Group id='noteModalForm'  className="mb-3" >
  
  <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
    type="text"
    as={"textarea"}
    rows={2}
    
    />
  
    <br></br>
  </Form.Group>
  </Form>
  
  <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
  Save To Collection
  </Button>
  </Modal.Footer> 
  
  </div>
  </Modal.Body>
                   </Modal>
        
        <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                        <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                          <GenerateFeedRecentUK/>
                        </Modal>
  
      </Container>,
      document.getElementById(divID.toString())
    );
  }




  export function CreateFeedAPIDepartmentUS(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
    const [shareModalIsOpen, setShareModalIsOpen] = useState(false);
    const [linkCopied, setLinkCopied] = useState(false);
    const [shareLink, setShareLink] = useState('');
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [docLink, setDocLink] = useState('');
    const [textLink, setTextLink] = useState('');
    const [date, setDate] = useState('');
    const [itemID, setItemID] = useState('');
    const [table, setTable] = useState('');
    const history = useHistory(); // Using react-router-dom's useHistory hook
  
    // Extract table from apiURL
    useEffect(() => {
      const urlParams = new URLSearchParams(apiURL);
      const tableParam = urlParams.get('table_name') || 'default_table';
      setTable(tableParam);
    }, [apiURL]);
                                                                                                    // &table_name=homeland_security&agency=U.S.%20Immigration%20and%20Customs%20Enforcement
    const fullFeedUrl = `https://api.govglance.org/departments/?schema_name=united_states_of_america${apiURL}`;
  
    const handleShareModalOpen = (id) => {
      const fullUrl = `https://govglance.org/feed/united_states_of_america/${table}/${id}`;
      setShareLink(fullUrl);
      setShareModalIsOpen(true);
      setLinkCopied(false);
     
    };
  
    const handleCopyLink = async () => {
      try {
        await navigator.clipboard.writeText(shareLink);
        setLinkCopied(true);
      } catch (error) {
        console.error('Error copying link:', error);
      }
    };
  
    const handleNativeShare = async () => {
      if (navigator.share) {
        try {
          await navigator.share({ url: shareLink });
          console.log('Content shared successfully!');
        } catch (error) {
          console.error('Error sharing:', error);
        }
      } else {
        alert('Sharing is not supported on this device.');
      }
    };

      // Function to handle "View More" button click
  const handleViewMoreClick = () => {
    history.push({
      pathname: `/feed/united_states_of_america/${table}`,
      state: {
        apiURL,
        feedName,
        feedBranch,
        feedTopic,
        feedButtonName
      }
    });
  };
  
    const saveNote = () => {
      const collectionCounter = parseInt(localStorage.getItem('collectionNumber') || '0', 10) + 1;
      localStorage.setItem('collectionNumber', collectionCounter.toString());
      localStorage.setItem(`title${collectionCounter}`, title);
      localStorage.setItem(`detailLink${collectionCounter}`, link);
      localStorage.setItem(`docLink${collectionCounter}`, docLink);
      localStorage.setItem(`itemNote${collectionCounter}`, document.getElementById('noteModalFeedItem').value);
      localStorage.setItem(`itemDate${collectionCounter}`, date);
      localStorage.setItem(`feedName${collectionCounter}`, feedName);
      localStorage.setItem(`branchName${collectionCounter}`, feedBranch);
      localStorage.setItem(`topicName${collectionCounter}`, feedTopic);
      window.location.reload();
    };
  
    function formatDate(inputDate) {
      const date = new Date(inputDate);
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      const day = utcDate.getDate();
      const month = utcDate.getMonth() + 1;
      const year = utcDate.getFullYear();
      return `${month}-${day}-${year}`;
    }
  
    const displayData = (data) => {
      if (data && data.length > 0) {
        const firstItem = data[0];
        let title = firstItem.status_title || firstItem.title;
        if (title.length > 180) title = title.substring(0, 177) + '...';
  
        setTitle(title);
        setLink(firstItem.url);
        setDocLink(firstItem.document_link || firstItem.download?.pdfLink || '');
        setTextLink(firstItem.document_link || firstItem.download?.pdfLink || firstItem.url);
        setDate(formatDate(firstItem.created_at));
        setItemID(firstItem.id);
      }
    };
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`${fullFeedUrl}&limit=1&skip=0`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${apiKey}`,
            },
          });
          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
          const data = await response.json();
          displayData(data);
         
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, [apiURL]);
  
    const targetElement = document.getElementById(divID);
    if (!targetElement) {
      console.error(`Element with id ${divID} not found`);
      return null;
    }
  
    const viewBillButton = docLink && feedButtonName ? (
      <Button id="viewBillButton" onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
        <FileText /> {feedButtonName}
      </Button>
    ) : null;
  
    return ReactDOM.createPortal(
      <Container id="feedContainerNormalView">
        <Card.Title id="billText" onClick={() => window.open(textLink, "_blank")}>{title}</Card.Title>
        <Card.Body id="billBodyText">{date}</Card.Body>
        <Button id="billLink" onClick={() => window.open(link, "_blank")}>
          <FileText /> Document
        </Button>
        {viewBillButton}
        <Button id="addToCollectionButton" onClick={() => setCollectionModalIsOpen(true)}>
          <FolderPlus />
        </Button>
        <Button id="shareButton" onClick={() => handleShareModalOpen(itemID)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
          <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
        </svg>
        </Button>
  
        <Modal id="fullBillModal" show={collectionModalIsOpen} size="xl" centered onHide={() => setCollectionModalIsOpen(false)}>
          <Modal.Header id="billModalHeader" closeButton>
            <h3>{feedName}</h3>
          </Modal.Header>
          <Modal.Body id="descriptionModalBody">
            {date}
            <Form id="noteModalForm">
              <Form.Group className="mb-3">
                <Form.Control id="noteModalFeedItem" placeholder="Add a note here" as="textarea" rows={2} />
              </Form.Group>
            </Form>
            <Modal.Footer id="addNoteModalFooter">
              <Button variant="primary" id="noteSaveToCollectionButton" onClick={saveNote}>
                Save To Collection
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
  
        <Modal id="fullBillModal" show={shareModalIsOpen} size="xl" centered onHide={() => setShareModalIsOpen(false)}>
          <Modal.Header id="billModalHeader" closeButton>
            <h3>Share</h3>
          </Modal.Header>
          <Modal.Body id="descriptionModalBody">
            <Form.Group id="noteModalForm">
              <Form.Control id="noteModalFeedItem" type="text" readOnly value={shareLink} />
            </Form.Group>
            <Modal.Footer id="shareFooter">
              <Button id="addToCollectionButton" onClick={handleNativeShare}>
                <BoxArrowUp />
              </Button>
              <Button id="addToCollectionButton" onClick={handleCopyLink}>
                {linkCopied ? <ClipboardCheck /> : <Clipboard />}
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
  
        <Button id="whDocsViewMoreButton" onClick={handleViewMoreClick}>
          <Files size={20} /> View More
        </Button>
        <Modal id="fullBillModal" show={feedItemModalIsOpen} size="xl" centered onHide={() => setFeedItemModalIsOpen(false)}>
          <Modal.Header id="billModalHeader" closeButton>
            <h3>{feedName}</h3>
          </Modal.Header>
          <FeedFunction url={apiURL} name={feedName} branch="Executive" topic={feedTopic} buttonName="Doc" />
        </Modal>
      </Container>,
      targetElement
    );
  }
  
  


  
  export function CreateFeedItemExecutiveOrdersCache(url, divID, feedName, feedBranch, feedTopic,  FeedFunction) {
    const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [billTitle, setBillTitle] = useState('');
    const [billLink, setBillLink] = useState('');
    const [pubDate, setPubDate] = useState('');
  
 
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
        
        var searchItem = document.getElementById('noteModalFeedItem').value
      
           collectionCounter = localStorage.getItem('collectionNumber')
           //(collectionCounter)
         collectionCounter++;
           //(collectionCounter)
         
         var collectionTitles = []
          var collectionDetailLinks = []
         var collectionDocLinks = []
         var collectionNote = []
         
         //collectionTitles.push(saveCollectionTitle)
         //collectionDetailLinks.push(saveCollectionDetailLink)
         //collectionDocLinks.push(saveCollectionDocLink)
         
         
         
          
           
         
          
           
           var title = 'title'.concat(collectionCounter)
           var detailLink = 'detailLink'.concat(collectionCounter)
           var docLink = 'docLink'.concat(collectionCounter)
           var itemNote = 'itemNote'.concat(collectionCounter)
           var itemDate = 'itemDate'.concat(collectionCounter)
           var feedName = 'feedName'.concat(collectionCounter)
           var branchName = 'branchName'.concat(collectionCounter)
           var topicName = 'topicName'.concat(collectionCounter)
           var itemDescription = 'itemDescription'.concat(collectionCounter)
       
       
         
         
           localStorage.setItem(title,saveCollectionTitle)
           
           localStorage.setItem(detailLink,saveCollectionDetailLink)
          
           localStorage.setItem(docLink,saveCollectionDocLink)
           localStorage.setItem(itemNote,searchItem)
       
           localStorage.setItem(itemDate,saveCollectionDate)
           localStorage.setItem(feedName,saveCollectionFeedName)
           localStorage.setItem(branchName,saveCollectionBranchName)
           localStorage.setItem(topicName,saveCollectionTopicName)
       
           localStorage.setItem(itemDescription,saveCollectionDescription)
         
           localStorage.setItem('collectionNumber', collectionCounter)
          
           
         
           
      
           
      
      
      
      
      }
  
      useEffect(() => {
        const fetchData = async () => {
            try {
                // Check local storage for cached data
                const cachedData = localStorage.getItem(feedName + 'cachedData');
                const cachedTimestamp = localStorage.getItem(feedName + 'cachedTimestamp');
                const currentTime = new Date().getTime();
  
                // If cached data exists and is not older than 1 hour, use it
                if (cachedData && cachedTimestamp && currentTime - parseInt(cachedTimestamp) < 3600000) {
                    const parsedData = JSON.parse(cachedData);
                    setBillTitle(parsedData.billTitle);
                    setBillLink(parsedData.billLink);
                    setPubDate(formatDate2(parsedData.pubDate));
                    setLoading(false);
                } else {
                    // Fetch new data from the API if cached data is not available or outdated
                    let Parser = require('rss-parser');
                    let parser = new Parser();
  
                    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/' + url);
  
                    if (feed.items.length > 0) {
                        let item = feed.items[0];
                        setBillTitle(item.title);
                        setBillLink(item.link);
                        setPubDate(formatDate2(item.pubDate));
                        setLoading(false);
                        
                        // Cache the fetched data
                        localStorage.setItem(feedName + 'cachedData', JSON.stringify({ billTitle: item.title, billLink: item.link, pubDate: item.pubDate.substring(0, 16) }));
                        localStorage.setItem(feedName + 'cachedTimestamp', currentTime.toString());
                    } else {
                        setLoading(false);
                    }
                }
            } catch (error) {
                console.error('Error fetching feed:', error);
                setLoading(false);
            }
        };
  
        fetchData();
    }, [feedName, url]);
    
      if (loading) {
        return <Spinner animation="grow" variant="light" />;
      }
    
      return (
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText' onClick={(e) => {
            e.preventDefault();
            window.open("https://www.govinfo.gov/content/pkg/FR-" + billLink.substring(42, 46) + '-' + billLink.substring(47, 49) + '-' + billLink.substring(50, 52).concat('/pdf/') + billLink.substring(53, 57) + '-' + billLink.substring(58, 63).concat('.pdf'))
          }}>{billTitle}</Card.Title>
          <Card.Body id='billBodyText'>{pubDate}</Card.Body>
          <Button id='billLink' onClick={(e) => {
            e.preventDefault();
            window.open(billLink)
          }}> <InfoCircle></InfoCircle> Details</Button>
          <Button id='viewBillButton' onClick={(e) => {
            e.preventDefault();
            window.open("https://www.govinfo.gov/content/pkg/FR-" + billLink.substring(42, 46) + '-' + billLink.substring(47, 49) + '-' + billLink.substring(50, 52).concat('/pdf/') + billLink.substring(53, 57) + '-' + billLink.substring(58, 63).concat('.pdf'))
          }}><FileText></FileText> Document</Button>
          <Button id='addToCollectionButton' onClick={() => {
            setCollectionModalIsOpen(true);
            DisplaySaveToCollectionModal(billTitle, billLink, "https://www.govinfo.gov/content/pkg/FR-" + billLink.substring(42, 46) + '-' + billLink.substring(47, 49) + '-' + billLink.substring(50, 52).concat('/pdf/') + billLink.substring(53, 57) + '-' + billLink.substring(58, 63).concat('.pdf'), pubDate, '', feedName, feedBranch, feedTopic, "");
          }}><FolderPlus></FolderPlus></Button>
          <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>{billTitle}</h3></Modal.Header>
            <Modal.Body id='descriptionModalBody'>
              {pubDate}
              <div id='fullBill'>
                <Form id='noteModalForm'>
                  <Form.Group id='noteModalForm' className="mb-3">
                    <Form.Control id="noteModalFeedItem" placeholder="Add a note here"
                      type="text"
                      as={"textarea"}
                      rows={2}
                    />
                    <br></br>
                  </Form.Group>
                </Form>
                <Modal.Footer id='addNoteModalFooter'>
                  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => { saveNote(billTitle, billLink, "https://www.govinfo.gov/content/pkg/FR-" + billLink.substring(42, 46) + '-' + billLink.substring(47, 49) + '-' + billLink.substring(50, 52).concat('/pdf/') + billLink.substring(53, 57) + '-' + billLink.substring(58, 63).concat('.pdf'), pubDate, feedName, feedBranch, feedTopic, ""); setCollectionModalIsOpen(false) }}>
                    Save To Collection
                  </Button>
                </Modal.Footer>
              </div>
            </Modal.Body>
          </Modal>
          <Button id='whDocsViewMoreButton' onClick={() => setFeedItemModalIsOpen(true)}>
            <Files size={20}></Files> View More
          </Button>
          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
            <FeedFunction />
          </Modal>
        </Container>
      );
    }

    export function CreateFeedAPIRecentAll(divID, feedName, feedBranch, feedTopic, feedButtonName) {
      const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
      const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
      const [title, setTitle] = useState('');
      const [link, setLink] = useState('');
      const [docLink, setDocLink] = useState('');
      const [date, setDate] = useState('');
      const [source, setSource] = useState('');
      const [itemSchema, setSchema] = useState('');
      const [itemID, setItemID] = useState('');
      const [nameForAPI, setNameForAPI] = useState('');
      const [country, setCountry] = useState('');
      const [loading, setLoading] = useState(true);
      const [loadingNewData, setLoadingNewData] = useState(false);
      const handleContainerClickUS = useHandleContainerClickRecents();
      const handleContainerClickCanada = useHandleContainerClickRecentsCanada();
      const handleContainerClickMexico = useHandleContainerClickRecentsMexico();
      const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // Share modal state
      const [linkCopied, setLinkCopied] = useState(false); // Track if the link is copied
      const [shareLink, setShareLink] = useState(''); // Link to share
  
      const handleNativeShare = async () => {
        if (navigator.share) {
          try {
            await navigator.share({
              title: 'Check this out!',
              text: 'Here’s something interesting I found:',
              url: shareLink,
            });
            console.log('Content shared successfully!');
          } catch (error) {
            console.error('Error sharing:', error);
          }
        } else {
          alert('Sharing is not supported on this device.');
        }
      };
   const history = useHistory(); // Using react-router-dom's useHistory hook
    
      // Function to open the share modal
      const handleShareModalOpen = () => {
        const baseUrl = 'https://govglance.org';
        let countryPath;
      
        switch (country) {
          case 'United States':
            countryPath = 'united_states_of_america';
            break;
          case 'Canada':
            countryPath = 'canada';
            break;
          case 'Mexico':
            countryPath = 'mexico';
            break;
          case 'United Kingdom':
            countryPath = 'united_kingdom';
            break;
          default:
            countryPath = ''; // default or error handling
        }
      
        // Replace `itemSchema` and `itemID` with your dynamic values as needed
        const fullUrl = `${baseUrl}/feed/${countryPath}/${itemSchema}/${itemID}`;
        setShareLink(fullUrl);
        setShareModalIsOpen(true);
        setLinkCopied(false); // Reset copy state when opening the modal
      };
      
    
      // Function to copy the share link
      const handleCopyLink = () => {
        navigator.clipboard.writeText(shareLink)
          .then(() => setLinkCopied(true))
          .catch((error) => console.error('Error copying link:', error));
      };
      function getCountryEmoji(country) {
        switch (country) {
          case 'United States':
            return '🇺🇸';
          case 'Canada':
            return '🇨🇦';
          case 'Mexico':
            return '🇲🇽';
          default:
            return '';
        }
      }
      function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
        var searchItem = document.getElementById('noteModalFeedItem').value;
        let collectionCounter = localStorage.getItem('collectionNumber');
        collectionCounter++;
    
        var title = 'title'.concat(collectionCounter);
        var detailLink = 'detailLink'.concat(collectionCounter);
        var docLink = 'docLink'.concat(collectionCounter);
        var itemNote = 'itemNote'.concat(collectionCounter);
        var itemDate = 'itemDate'.concat(collectionCounter);
        var feedName = 'feedName'.concat(collectionCounter);
        var branchName = 'branchName'.concat(collectionCounter);
        var topicName = 'topicName'.concat(collectionCounter);
    
        localStorage.setItem(title, saveCollectionTitle);
        localStorage.setItem(detailLink, saveCollectionDetailLink);
        localStorage.setItem(docLink, saveCollectionDocLink);
        localStorage.setItem(itemNote, searchItem);
        localStorage.setItem(itemDate, saveCollectionDate);
        localStorage.setItem(feedName, saveCollectionFeedName);
        localStorage.setItem(branchName, saveCollectionBranchName);
        localStorage.setItem(topicName, saveCollectionTopicName);
        localStorage.setItem('collectionNumber', collectionCounter);
        window.location.reload();
      }
    
      function formatDate(inputDate) {
        const date = new Date(inputDate);
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const day = utcDate.getDate();
        const month = utcDate.getMonth() + 1;
        const year = utcDate.getFullYear();
        return `${month}-${day}-${year}`;
      }
    
      const displayData = (data) => {
        if (data.length === 0) return;
    
        // Sort the data by date (newest first)
        const sortedData = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        const firstItem = sortedData[0];
        
        let title = firstItem.status_title || firstItem.title;
        if (title.length > 180) {
          title = title.substring(0, 177) + '...';
        }
        setTitle(title);
        setLink(firstItem.url);
        setNameForAPI(firstItem.source);
        setSource(firstItem.collection_name);
        setItemID(firstItem.id)
        setSchema(firstItem.source)
        setCountry(firstItem.country);
        if (firstItem.document_link) {
          setDocLink(firstItem.document_link);
        } else if (firstItem.download && firstItem.download.pdfLink) {
          setDocLink(firstItem.download.pdfLink);
        }
        setDate(formatDate(firstItem.created_at));
      };
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            const apiURLUS = `https://api.govglance.org/posts/recent/schema?limit=1&skip=0&schema=united_states_of_america`;
            const apiURLCanada = `https://api.govglance.org/posts/recent/schema?limit=1&skip=0&schema=canada`;
            const apiURLMexico = `https://api.govglance.org/posts/recent/schema?limit=1&skip=0&schema=mexico`;
    
            const requests = [
              fetch(apiURLUS, { method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${apiKey}` }}).then(response => response.json()).then(data => data.map(item => ({ ...item, country: 'United States' }))),
              fetch(apiURLCanada, { method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${apiKey}` }}).then(response => response.json()).then(data => data.map(item => ({ ...item, country: 'Canada' }))),
              fetch(apiURLMexico, { method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${apiKey}` }}).then(response => response.json()).then(data => data.map(item => ({ ...item, country: 'Mexico' })))
            ];
    
            const responses = await Promise.all(requests);
            const mergedData = [].concat(...responses);
    
            displayData(mergedData);
            setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
        fetchData();
      }, []);
    
      const handleContainerClick = (country, nameForAPI, collectionName) => {
        switch (country) {
          case 'United States':
            handleContainerClickUS(nameForAPI, collectionName, "", "", "");
            break;
          case 'Canada':
            handleContainerClickCanada(nameForAPI, collectionName, "", "", "");
            break;
          case 'Mexico':
            handleContainerClickMexico(nameForAPI, collectionName, "", "", "");
            break;
          default:
            break;
        }
      };

      function getCountryRecentsLink(country) {
        switch (country) {
          case 'United States':
            return '/us/recents';
          case 'Canada':
            return '/canada/recents';
          case 'Mexico':
            return '/mexico/recents';
          case 'United Kingdom':
            return '/uk/recents';
          default:
            return '#';
        }
      }

      function getCountryLink(country) {
        switch (country) {
          case 'United States':
            return '/us';
          case 'Canada':
            return '/canada';
          case 'Mexico':
            return '/mexico';
          default:
            return '#';
        }
      }
    
      if (loading) {
        return <Spinner animation="grow" variant="light" />;
      }
    
      if (title === "Coming Soon") {
        return ReactDOM.createPortal(
          <Container id='feedContainerNormalViewRecents'>
            <Card.Title id='billText'>{title}</Card.Title>
            <br></br><br></br><br></br>
            <br></br><br></br><br></br>
          </Container>, document.getElementById(divID.toString())
        );
      }
    
      const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
        <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
          <FileText></FileText> {feedButtonName}
        </Button>
      ) : null;
    
      const countryEmoji = getCountryEmoji(country);
      const countryLink = getCountryLink(country);
    
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          <Card.Title
            id="billBodyTextSource"
            onClick={(e) => {
              e.preventDefault();
              window.open(countryLink, "_self");
            }}
          >
            {countryEmoji} {country}<ChevronRight id='clickChevron'></ChevronRight>
          </Card.Title>
          <Card.Title onClick={() => handleContainerClick(nameForAPI, source)} id='billBodyTextSource'>{source}</Card.Title>
          <Card.Title id='billText' onClick={(e) => {
            e.preventDefault();
            const linkToOpen = docLink || link;
            window.open(linkToOpen, "_blank");
          }}>{title}</Card.Title>
          <Card.Body id='billBodyText'>{date}</Card.Body>
          <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
            <InfoCircle /> Details
          </Button>
          {viewBillButton}
          <Button id='addToCollectionButton' onClick={() => {
            setCollectionModalIsOpen(true);
            DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic);
          }}><FolderPlus></FolderPlus></Button>

<Button id="shareButton" onClick={handleShareModalOpen}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
          </svg>
        </Button>

    <Modal id="fullBillModal" show={shareModalIsOpen} size="xl" centered onHide={() => setShareModalIsOpen(false)}>
        <Modal.Header id="billModalHeader" closeButton>
          <h3>Share</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          <Form.Group id="noteModalForm">
            <Form.Control id="noteModalFeedItem" type="text" readOnly value={shareLink} />
          </Form.Group>
          <Modal.Footer id="shareFooter">
          <Button 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>
            <Button id="addToCollectionButton" onClick={handleCopyLink}>
              {linkCopied ? <ClipboardCheck /> : <Clipboard />}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>

          {loadingNewData && <Spinner animation="grow" variant="light" />}
          <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
            <Modal.Body id='descriptionModalBody'>
              {date}
              <div id='fullBill'>
                <Form id='noteModalForm'>
                  <Form.Group id='noteModalForm' className="mb-3">
                    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as={"textarea"} rows={2} />
                    <br></br>
                  </Form.Group>
                </Form>
                <Modal.Footer id='addNoteModalFooter'> 
                  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false);}}>
                    Save To Collection
                  </Button>
                </Modal.Footer> 
              </div>
            </Modal.Body>
          </Modal>
          <Button id='whDocsViewMoreButton'  onClick={() => {
    const countryLink = getCountryRecentsLink(country);
    window.open(countryLink, "_self");
  }}><Files size={20}></Files> View More</Button>
          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
            <GenerateFeedRecentAll />
          </Modal>
        </Container>,
        document.getElementById(divID.toString())
      );
    }

    export function CreateFeedAPIRecentUSHome(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
      const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
      const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
      const [title, setTitle] = useState('');
      const [link, setLink] = useState('');
      const [docLink, setDocLink] = useState('');
      const [date, setDate] = useState('');
      const [source, setSource] = useState('');   
      const [sourceURL, setSourceURL] = useState(''); 
      const [nameForAPI, setNameForAPI] = useState('');
      const [loading, setLoading] = useState(true);
      const [loadingNewData, setLoadingNewData] = useState(false);
      const handleContainerClick = useHandleContainerClickRecents();
    
      function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
        var searchItem = document.getElementById('noteModalFeedItem').value;
         let collectionCounter = localStorage.getItem('collectionNumber');
        collectionCounter++;
    
        var title = 'title'.concat(collectionCounter);
        var detailLink = 'detailLink'.concat(collectionCounter);
        var docLink = 'docLink'.concat(collectionCounter);
        var itemNote = 'itemNote'.concat(collectionCounter);
        var itemDate = 'itemDate'.concat(collectionCounter);
        var feedName = 'feedName'.concat(collectionCounter);
        var branchName = 'branchName'.concat(collectionCounter);
        var topicName = 'topicName'.concat(collectionCounter);
        
        localStorage.setItem(title, saveCollectionTitle);
        localStorage.setItem(detailLink, saveCollectionDetailLink);
        localStorage.setItem(docLink, saveCollectionDocLink);
        localStorage.setItem(itemNote, searchItem);
        localStorage.setItem(itemDate, saveCollectionDate);
        localStorage.setItem(feedName, saveCollectionFeedName);
        localStorage.setItem(branchName, saveCollectionBranchName);
        localStorage.setItem(topicName, saveCollectionTopicName);
        localStorage.setItem('collectionNumber', collectionCounter);
        window.location.reload();
      }
    
      function formatDate(inputDate) {
        const date = new Date(inputDate);
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const day = utcDate.getDate();
        const month = utcDate.getMonth() + 1;
        const year = utcDate.getFullYear();
      
        return `${month}-${day}-${year}`;
      }
    
      const displayData = (data) => {
        const firstItem = data[0];
        let title = firstItem.status_title || firstItem.title;
        if (title.length > 135) {
          title = title.substring(0, 135) + '...';
        }
        setTitle(title);
        setLink(firstItem.url);
        setNameForAPI(firstItem.source)
        setSource(firstItem.collection_name)
        if (firstItem.document_link) {
          setDocLink(firstItem.document_link);
        } else if (firstItem.download && firstItem.download.pdfLink) {
          setDocLink(firstItem.download.pdfLink);
        } else {
          // Handle no document link case
        }
        setDate(formatDate(firstItem.created_at));
      };
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(apiURL, { method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+apiKey }});
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            displayData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchData();
      }, [apiURL]);
    
      if (title.length === 0) {
        return <Spinner animation="grow" variant="light" />;
      }
    
      if (title === "Coming Soon") {
        return ReactDOM.createPortal(
          <Container id='feedContainerNormalView'>
            <Card.Title id='billText'>{title}</Card.Title>
            <br></br><br></br><br></br>
            <br></br><br></br><br></br>
          </Container>,document.getElementById(divID.toString())
        );
      }
     
      const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
        <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
          <FileText></FileText> {feedButtonName}
        </Button>
      ) : null;
    
    
    
    
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
           <Card.Title
            id="billBodyTextSource"
            onClick={(e) => {
              e.preventDefault();
              window.open('/us', "_self");
            }}
          >
            🇺🇸 United States<ChevronRight id='clickChevron'></ChevronRight>
          </Card.Title>
     <Card.Title onClick={() => handleContainerClick(nameForAPI, source, "", "", "")} id='billBodyTextSource'>{source}</Card.Title>
    
          <Card.Title id='billText' onClick={(e) => {
      e.preventDefault();
      const linkToOpen = docLink || link;
      window.open(linkToOpen, "_blank");
    }}>{title}</Card.Title>
          <Card.Body id='billBodyText'>{date}</Card.Body>
        
          <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
            <InfoCircle /> Details
          </Button>
    
          {viewBillButton}
    
          <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic)
    } }><FolderPlus></FolderPlus></Button>
    
    {loadingNewData && <Spinner animation="grow" variant="light" />}
    
    <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                   
                                   {date}
    
                       <div id='fullBill'>
    
                  <Form id='noteModalForm'>
    
    <Form.Group id='noteModalForm'  className="mb-3" >
    
    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
    
      <br></br>
    </Form.Group>
    </Form>
    
    <Modal.Footer id='addNoteModalFooter'> 
    
    <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
    Save To Collection
    </Button>
    </Modal.Footer> 
    
    </div>
    </Modal.Body>
                     </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <GenerateFeedRecent/>
                          </Modal>
    
        </Container>,
        document.getElementById(divID.toString())
      );
    }
    export function CreateFeedAPIRecentMexicoHome(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
      const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
      const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
      const [title, setTitle] = useState('');
      const [link, setLink] = useState('');
      const [docLink, setDocLink] = useState('');
      const [date, setDate] = useState('');
      const [source, setSource] = useState('');   
      const [sourceURL, setSourceURL] = useState(''); 
      const [nameForAPI, setNameForAPI] = useState('');
      const [loading, setLoading] = useState(true);
      const [loadingNewData, setLoadingNewData] = useState(false);
      const handleContainerClick = useHandleContainerClickRecents();
    
      function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
        var searchItem = document.getElementById('noteModalFeedItem').value;
         let collectionCounter = localStorage.getItem('collectionNumber');
        collectionCounter++;
    
        var title = 'title'.concat(collectionCounter);
        var detailLink = 'detailLink'.concat(collectionCounter);
        var docLink = 'docLink'.concat(collectionCounter);
        var itemNote = 'itemNote'.concat(collectionCounter);
        var itemDate = 'itemDate'.concat(collectionCounter);
        var feedName = 'feedName'.concat(collectionCounter);
        var branchName = 'branchName'.concat(collectionCounter);
        var topicName = 'topicName'.concat(collectionCounter);
        
        localStorage.setItem(title, saveCollectionTitle);
        localStorage.setItem(detailLink, saveCollectionDetailLink);
        localStorage.setItem(docLink, saveCollectionDocLink);
        localStorage.setItem(itemNote, searchItem);
        localStorage.setItem(itemDate, saveCollectionDate);
        localStorage.setItem(feedName, saveCollectionFeedName);
        localStorage.setItem(branchName, saveCollectionBranchName);
        localStorage.setItem(topicName, saveCollectionTopicName);
        localStorage.setItem('collectionNumber', collectionCounter);
        window.location.reload();
      }
    
      function formatDate(inputDate) {
        const date = new Date(inputDate);
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const day = utcDate.getDate();
        const month = utcDate.getMonth() + 1;
        const year = utcDate.getFullYear();
      
        return `${month}-${day}-${year}`;
      }
    
      const displayData = (data) => {
        const firstItem = data[0];
        let title = firstItem.status_title || firstItem.title;
        if (title.length > 135) {
          title = title.substring(0, 135) + '...';
        }
        setTitle(title);
        setLink(firstItem.url);
        setNameForAPI(firstItem.source)
        setSource(firstItem.collection_name)
        if (firstItem.document_link) {
          setDocLink(firstItem.document_link);
        } else if (firstItem.download && firstItem.download.pdfLink) {
          setDocLink(firstItem.download.pdfLink);
        } else {
          // Handle no document link case
        }
        setDate(formatDate(firstItem.created_at));
      };
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(apiURL, { method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+apiKey }});
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            displayData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchData();
      }, [apiURL]);
    
      if (title.length === 0) {
        return <Spinner animation="grow" variant="light" />;
      }
    
      if (title === "Coming Soon") {
        return ReactDOM.createPortal(
          <Container id='feedContainerNormalView'>
            <Card.Title id='billText'>{title}</Card.Title>
            <br></br><br></br><br></br>
            <br></br><br></br><br></br>
          </Container>,document.getElementById(divID.toString())
        );
      }
     
      const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
        <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
          <FileText></FileText> {feedButtonName}
        </Button>
      ) : null;
    
    
    
    
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
           <Card.Title
            id="billBodyTextSource"
            onClick={(e) => {
              e.preventDefault();
              window.open('/mexico', "_self");
            }}
          >
            🇲🇽 Mexico<ChevronRight id='clickChevron'></ChevronRight>
          </Card.Title>
     <Card.Title onClick={() => handleContainerClick(nameForAPI, source, "", "", "")} id='billBodyTextSource'>{source}</Card.Title>
    
          <Card.Title id='billText' onClick={(e) => {
      e.preventDefault();
      const linkToOpen = docLink || link;
      window.open(linkToOpen, "_blank");
    }}>{title}</Card.Title>
          <Card.Body id='billBodyText'>{date}</Card.Body>
        
          <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
            <InfoCircle /> Details
          </Button>
    
          {viewBillButton}
    
          <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic)
    } }><FolderPlus></FolderPlus></Button>
    
    {loadingNewData && <Spinner animation="grow" variant="light" />}
    
    <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                   
                                   {date}
    
                       <div id='fullBill'>
    
                  <Form id='noteModalForm'>
    
    <Form.Group id='noteModalForm'  className="mb-3" >
    
    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
    
      <br></br>
    </Form.Group>
    </Form>
    
    <Modal.Footer id='addNoteModalFooter'> 
    
    <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
    Save To Collection
    </Button>
    </Modal.Footer> 
    
    </div>
    </Modal.Body>
                     </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <GenerateFeedRecent/>
                          </Modal>
    
        </Container>,
        document.getElementById(divID.toString())
      );
    }

    export function CreateFeedAPIRecentCanadaHome(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
      const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
      const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
      const [title, setTitle] = useState('');
      const [link, setLink] = useState('');
      const [docLink, setDocLink] = useState('');
      const [date, setDate] = useState('');
      const [source, setSource] = useState('');   
      const [sourceURL, setSourceURL] = useState(''); 
      const [nameForAPI, setNameForAPI] = useState('');
      const [loading, setLoading] = useState(true);
      const [loadingNewData, setLoadingNewData] = useState(false);
      const handleContainerClick = useHandleContainerClickRecents();
    
      function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
        var searchItem = document.getElementById('noteModalFeedItem').value;
         let collectionCounter = localStorage.getItem('collectionNumber');
        collectionCounter++;
    
        var title = 'title'.concat(collectionCounter);
        var detailLink = 'detailLink'.concat(collectionCounter);
        var docLink = 'docLink'.concat(collectionCounter);
        var itemNote = 'itemNote'.concat(collectionCounter);
        var itemDate = 'itemDate'.concat(collectionCounter);
        var feedName = 'feedName'.concat(collectionCounter);
        var branchName = 'branchName'.concat(collectionCounter);
        var topicName = 'topicName'.concat(collectionCounter);
        
        localStorage.setItem(title, saveCollectionTitle);
        localStorage.setItem(detailLink, saveCollectionDetailLink);
        localStorage.setItem(docLink, saveCollectionDocLink);
        localStorage.setItem(itemNote, searchItem);
        localStorage.setItem(itemDate, saveCollectionDate);
        localStorage.setItem(feedName, saveCollectionFeedName);
        localStorage.setItem(branchName, saveCollectionBranchName);
        localStorage.setItem(topicName, saveCollectionTopicName);
        localStorage.setItem('collectionNumber', collectionCounter);
        window.location.reload();
      }
    
      function formatDate(inputDate) {
        const date = new Date(inputDate);
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const day = utcDate.getDate();
        const month = utcDate.getMonth() + 1;
        const year = utcDate.getFullYear();
      
        return `${month}-${day}-${year}`;
      }
    
      const displayData = (data) => {
        const firstItem = data[0];
        let title = firstItem.status_title || firstItem.title;
        if (title.length > 135) {
          title = title.substring(0, 135) + '...';
        }
        setTitle(title);
        setLink(firstItem.url);
        setNameForAPI(firstItem.source)
        setSource(firstItem.collection_name)
        if (firstItem.document_link) {
          setDocLink(firstItem.document_link);
        } else if (firstItem.download && firstItem.download.pdfLink) {
          setDocLink(firstItem.download.pdfLink);
        } else {
          // Handle no document link case
        }
        setDate(formatDate(firstItem.created_at));
      };
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(apiURL, { method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": "Bearer "+apiKey }});
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            displayData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchData();
      }, [apiURL]);
    
      if (title.length === 0) {
        return <Spinner animation="grow" variant="light" />;
      }
    
      if (title === "Coming Soon") {
        return ReactDOM.createPortal(
          <Container id='feedContainerNormalView'>
            <Card.Title id='billText'>{title}</Card.Title>
            <br></br><br></br><br></br>
            <br></br><br></br><br></br>
          </Container>,document.getElementById(divID.toString())
        );
      }
     
      const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
        <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
          <FileText></FileText> {feedButtonName}
        </Button>
      ) : null;
    
    
    
    
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
           <Card.Title
            id="billBodyTextSource"
            onClick={(e) => {
              e.preventDefault();
              window.open('/canada', "_self");
            }}
          >
            🇨🇦 Canada<ChevronRight id='clickChevron'></ChevronRight>
          </Card.Title>
     <Card.Title onClick={() => handleContainerClick(nameForAPI, source, "", "", "")} id='billBodyTextSource'>{source}</Card.Title>
    
          <Card.Title id='billText' onClick={(e) => {
      e.preventDefault();
      const linkToOpen = docLink || link;
      window.open(linkToOpen, "_blank");
    }}>{title}</Card.Title>
          <Card.Body id='billBodyText'>{date}</Card.Body>
        
          <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
            <InfoCircle /> Details
          </Button>
    
          {viewBillButton}
    
          <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic)
    } }><FolderPlus></FolderPlus></Button>
    
    {loadingNewData && <Spinner animation="grow" variant="light" />}
    
    <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                   
                                   {date}
    
                       <div id='fullBill'>
    
                  <Form id='noteModalForm'>
    
    <Form.Group id='noteModalForm'  className="mb-3" >
    
    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
    
      <br></br>
    </Form.Group>
    </Form>
    
    <Modal.Footer id='addNoteModalFooter'> 
    
    <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
    Save To Collection
    </Button>
    </Modal.Footer> 
    
    </div>
    </Modal.Body>
                     </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <GenerateFeedRecent/>
                          </Modal>
    
        </Container>,
        document.getElementById(divID.toString())
      );
    }

    export function CreateFeedAPIRecentAllDesk(divID, feedName, feedBranch, feedTopic, feedButtonName) {
      const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
      const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
      const [title, setTitle] = useState('');
      const [link, setLink] = useState('');
      const [docLink, setDocLink] = useState('');
      const [date, setDate] = useState('');
      const [source, setSource] = useState('');
      const [nameForAPI, setNameForAPI] = useState('');
      const [itemSchema, setSchema] = useState('');
      const [itemID, setItemID] = useState('');
      const [country, setCountry] = useState('');
      const [loading, setLoading] = useState(true);
      const [loadingNewData, setLoadingNewData] = useState(false);
      const handleContainerClickUS = useHandleContainerClickRecents();
      const handleContainerClickCanada = useHandleContainerClickRecentsCanada();
      const handleContainerClickMexico = useHandleContainerClickRecentsMexico();
      const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // Share modal state
      const [linkCopied, setLinkCopied] = useState(false); // Track if the link is copied
      const [shareLink, setShareLink] = useState(''); // Link to share
  
      const handleNativeShare = async () => {
        if (navigator.share) {
          try {
            await navigator.share({
              title: 'Check this out!',
              text: 'Here’s something interesting I found:',
              url: shareLink,
            });
            console.log('Content shared successfully!');
          } catch (error) {
            console.error('Error sharing:', error);
          }
        } else {
          alert('Sharing is not supported on this device.');
        }
      };
   const history = useHistory(); // Using react-router-dom's useHistory hook
    
      // Function to open the share modal
      const handleShareModalOpen = () => {
        const baseUrl = 'https://govglance.org';
        let countryPath;
      
        switch (country) {
          case 'United States':
            countryPath = 'united_states_of_america';
            break;
          case 'Canada':
            countryPath = 'canada';
            break;
          case 'Mexico':
            countryPath = 'mexico';
            break;
          case 'United Kingdom':
            countryPath = 'united_kingdom';
            break;
          default:
            countryPath = ''; // default or error handling
        }
      
        // Replace `itemSchema` and `itemID` with your dynamic values as needed
        const fullUrl = `${baseUrl}/feed/${countryPath}/${itemSchema}/${itemID}`;
        setShareLink(fullUrl);
        setShareModalIsOpen(true);
        setLinkCopied(false); // Reset copy state when opening the modal
      };
      
    
      // Function to copy the share link
      const handleCopyLink = () => {
        navigator.clipboard.writeText(shareLink)
          .then(() => setLinkCopied(true))
          .catch((error) => console.error('Error copying link:', error));
      };
      function getCountryEmoji(country) {
        switch (country) {
          case 'United States':
            return '🇺🇸';
          case 'Canada':
            return '🇨🇦';
          case 'Mexico':
            return '🇲🇽';
          default:
            return '';
        }
      }
      function getCountryEmoji(country) {
        switch (country) {
          case 'United States':
            return '🇺🇸';
          case 'Canada':
            return '🇨🇦';
          case 'Mexico':
            return '🇲🇽';
          default:
            return '';
        }
      }
      function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
        var searchItem = document.getElementById('noteModalFeedItem').value;
        let collectionCounter = localStorage.getItem('collectionNumber');
        collectionCounter++;
    
        var title = 'title'.concat(collectionCounter);
        var detailLink = 'detailLink'.concat(collectionCounter);
        var docLink = 'docLink'.concat(collectionCounter);
        var itemNote = 'itemNote'.concat(collectionCounter);
        var itemDate = 'itemDate'.concat(collectionCounter);
        var feedName = 'feedName'.concat(collectionCounter);
        var branchName = 'branchName'.concat(collectionCounter);
        var topicName = 'topicName'.concat(collectionCounter);
    
        localStorage.setItem(title, saveCollectionTitle);
        localStorage.setItem(detailLink, saveCollectionDetailLink);
        localStorage.setItem(docLink, saveCollectionDocLink);
        localStorage.setItem(itemNote, searchItem);
        localStorage.setItem(itemDate, saveCollectionDate);
        localStorage.setItem(feedName, saveCollectionFeedName);
        localStorage.setItem(branchName, saveCollectionBranchName);
        localStorage.setItem(topicName, saveCollectionTopicName);
        localStorage.setItem('collectionNumber', collectionCounter);
        window.location.reload();
      }
    
      function formatDate(inputDate) {
        const date = new Date(inputDate);
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const day = utcDate.getDate();
        const month = utcDate.getMonth() + 1;
        const year = utcDate.getFullYear();
        return `${month}-${day}-${year}`;
      }
    
      const displayData = (data) => {
        if (data.length === 0) return;
    
        // Sort the data by date (newest first)
        const sortedData = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        const firstItem = sortedData[0];
        
        let title = firstItem.status_title || firstItem.title;
        if (title.length > 180) {
          title = title.substring(0, 177) + '...';
        }
        setTitle(title);
        setLink(firstItem.url);
        setNameForAPI(firstItem.source);
        setSource(firstItem.collection_name);
        setItemID(firstItem.id)
        setSchema(firstItem.source)
        setCountry(firstItem.country);
        if (firstItem.document_link) {
          setDocLink(firstItem.document_link);
        } else if (firstItem.download && firstItem.download.pdfLink) {
          setDocLink(firstItem.download.pdfLink);
        }
        setDate(formatDate(firstItem.created_at));
      };
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            const apiURLUS = `https://api.govglance.org/posts/recent/schema?limit=1&skip=0&schema=united_states_of_america`;
            const apiURLCanada = `https://api.govglance.org/posts/recent/schema?limit=1&skip=0&schema=canada`;
            const apiURLMexico = `https://api.govglance.org/posts/recent/schema?limit=1&skip=0&schema=mexico`;
    
            const requests = [
              fetch(apiURLUS, { method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${apiKey}` }}).then(response => response.json()).then(data => data.map(item => ({ ...item, country: 'United States' }))),
              fetch(apiURLCanada, { method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${apiKey}` }}).then(response => response.json()).then(data => data.map(item => ({ ...item, country: 'Canada' }))),
              fetch(apiURLMexico, { method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${apiKey}` }}).then(response => response.json()).then(data => data.map(item => ({ ...item, country: 'Mexico' })))
            ];
    
            const responses = await Promise.all(requests);
            const mergedData = [].concat(...responses);
    
            displayData(mergedData);
            setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
        fetchData();
      }, []);
    
      const handleContainerClick = (country, nameForAPI, collectionName) => {
        switch (country) {
          case 'United States':
            handleContainerClickUS(nameForAPI, collectionName, "", "", "");
            break;
          case 'Canada':
            handleContainerClickCanada(nameForAPI, collectionName, "", "", "");
            break;
          case 'Mexico':
            handleContainerClickMexico(nameForAPI, collectionName, "", "", "");
            break;
          default:
            break;
        }
      };

      function getCountryLink(country) {
        switch (country) {
          case 'United States':
            return '/us';
          case 'Canada':
            return '/canada';
          case 'Mexico':
            return '/mexico';
          default:
            return '#';
        }
      }

      function getCountryRecentsLink(country) {
        switch (country) {
          case 'United States':
            return '/us/recents';
          case 'Canada':
            return '/canada/recents';
          case 'Mexico':
            return '/mexico/recents';
          case 'United Kingdom':
            return '/uk/recents';
          default:
            return '#';
        }
      }

    
      if (loading) {
        return <Spinner animation="grow" variant="light" />;
      }
    
      if (title === "Coming Soon") {
        return ReactDOM.createPortal(
          <Container id='feedContainerNormalViewRecents'>
            <Card.Title id='billText'>{title}</Card.Title>
            <br></br><br></br><br></br>
            <br></br><br></br><br></br>
          </Container>, document.getElementById(divID.toString())
        );
      }
    
      const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
        <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
          <FileText></FileText> {feedButtonName}
        </Button>
      ) : null;
    
      const countryEmoji = getCountryEmoji(country);
      const countryLink = getCountryLink(country);
    
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalViewRecentHome'>
          <Card.Title
            id="billBodyTextSource"
            onClick={(e) => {
              e.preventDefault();
              window.open(countryLink, "_self");
            }}
          >
            {countryEmoji} {country}<ChevronRight id='clickChevron'></ChevronRight>
          </Card.Title>
          <Card.Title onClick={() => handleContainerClick(nameForAPI, source)} id='billBodyTextSource'>{source}</Card.Title>
          <Card.Title id='billText' onClick={(e) => {
            e.preventDefault();
            const linkToOpen = docLink || link;
            window.open(linkToOpen, "_blank");
          }}>{title}</Card.Title>
          <Card.Body id='billBodyText'>{date}</Card.Body>
          <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
            <InfoCircle /> Details
          </Button>
          {viewBillButton}
          <Button id='addToCollectionButton' onClick={() => {
            setCollectionModalIsOpen(true);
            DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic);
          }}><FolderPlus></FolderPlus></Button>

<Button id="shareButton" onClick={handleShareModalOpen}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
          </svg>
        </Button>
          {loadingNewData && <Spinner animation="grow" variant="light" />}
          <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
            <Modal.Body id='descriptionModalBody'>
              {date}
              <div id='fullBill'>
                <Form id='noteModalForm'>
                  <Form.Group id='noteModalForm' className="mb-3">
                    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as={"textarea"} rows={2} />
                    <br></br>
                  </Form.Group>
                </Form>
                <Modal.Footer id='addNoteModalFooter'> 
                  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false);}}>
                    Save To Collection
                  </Button>
                </Modal.Footer> 
              </div>
            </Modal.Body>
          </Modal>

      <Modal id="fullBillModal" show={shareModalIsOpen} size="xl" centered onHide={() => setShareModalIsOpen(false)}>
        <Modal.Header id="billModalHeader" closeButton>
          <h3>Share</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          <Form.Group id="noteModalForm">
            <Form.Control id="noteModalFeedItem" type="text" readOnly value={shareLink} />
          </Form.Group>
          <Modal.Footer id="shareFooter">
          <Button 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>
            <Button id="addToCollectionButton" onClick={handleCopyLink}>
              {linkCopied ? <ClipboardCheck /> : <Clipboard />}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>

          <Button id='whDocsViewMoreButton' onClick={() => {
    const countryLink = getCountryRecentsLink(country);
    window.open(countryLink, "_self");
  }}><Files size={20}></Files> View more recent news</Button>
          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
            <GenerateFeedRecentAll />
          </Modal>
        </Container>,
        document.getElementById(divID.toString())
      );
    }

    export function CreateFeedItemStateRepsAll(divID, candidateType, stateAbbreviation, skip) {
      const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
      const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
      const [title, setTitle] = useState('');
      const [link, setLink] = useState('');
      const [docLink, setDocLink] = useState('');
      const [textLink, setTextLink] = useState('');
      const [date, setDate] = useState('');
      const [state, setState] = useState('');
      const [district, setDistrict] = useState('');
      const [party, setParty] = useState('');
      const [office, setOffice] = useState('');
      const [id, setID] = useState('');
      const handleContainerClick = UseHandleContainerClickCandidate();
      let storedTitle = '';
let storedDetailsLink = '';
let storedDocLink = '';
let storedDate = '';
    
function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
  var searchItem = document.getElementById('noteModalFeedItem').value;
  var collectionCounter = localStorage.getItem('collectionNumber');
  collectionCounter++;
  
  var title = 'title'.concat(collectionCounter);
  var detailLink = 'detailLink'.concat(collectionCounter);
  var docLink = 'docLink'.concat(collectionCounter);
  var itemNote = 'itemNote'.concat(collectionCounter);
  var itemDate = 'itemDate'.concat(collectionCounter);
  var feedName = 'feedName'.concat(collectionCounter);
  var branchName = 'branchName'.concat(collectionCounter);
  var topicName = 'topicName'.concat(collectionCounter);

  localStorage.setItem(title, saveCollectionTitle);
  localStorage.setItem(detailLink, saveCollectionDetailLink);
  localStorage.setItem(docLink, saveCollectionDocLink);
  localStorage.setItem(itemNote, searchItem);
  localStorage.setItem(itemDate, saveCollectionDate);
  localStorage.setItem(feedName, saveCollectionFeedName);
  localStorage.setItem(branchName, saveCollectionBranchName);
  localStorage.setItem(topicName, saveCollectionTopicName);
  localStorage.setItem('collectionNumber', collectionCounter);
  window.location.reload();
}
      
      function formatDate(inputDate) {
        const date = new Date(inputDate);
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const day = utcDate.getDate();
        const month = utcDate.getMonth() + 1;
        const year = utcDate.getFullYear();
        return `${month}-${day}-${year}`;
      }

      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${apiKey}`,
        },
      };
    
      const displayData = (data) => {
     
        const firstItem = data[0];
        let title = firstItem.name || firstItem.name;
        if (title.length > 180) {
          title = title.substring(0, 177) + '...';
        }
        setTitle(title);
        setLink('');
        if (firstItem.document_link) {
          setDocLink("");
          setTextLink("");
        } else if (firstItem.download && firstItem.download.pdfLink) {
          setDocLink("");
          setTextLink("");
        } else {
          setTextLink("");
        }
        setDate(formatDate(firstItem.last_file_date));
        setParty(firstItem.party_full);
        setState(firstItem.state);
        setDistrict(firstItem.district);
        setOffice(firstItem.office_full);
        setID(firstItem.candidate_id);
      };
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            let url;
            if (stateAbbreviation) {
              url = `https://api.govglance.org/elections/election_candidates?state=${stateAbbreviation}&election_type=${candidateType}&order_by=load_date&limit=1&skip=${skip}`;
            } else {

                      
              url = `https://api.govglance.org/elections/election_candidates?election_type=${candidateType}&order_by=last_file_date&limit=1&skip=${skip}`;
            }
    
            const response = await fetch(url, requestOptions);
        
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
    
            displayData(data);
            // console.log(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [stateAbbreviation, candidateType, skip]);
    
      const containerElement = document.getElementById(divID.toString());
    
      if (!containerElement) {
        return null; // Or return a fallback UI, like a spinner or an error message
      }
    
      if (title.length === 0) {
        return ReactDOM.createPortal(
          <Spinner animation="grow" variant="light" />,
          containerElement
        );
      }
    
      if (title === "Coming Soon") {
        return ReactDOM.createPortal(
          <Container id='feedContainerNormalView'>
            <Card.Title id='billText'>{title}</Card.Title>
            <br /><br /><br /><br /><br /><br />
          </Container>, containerElement
        );
      }
    
      const viewBillButton = docLink ? (
        <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
          <FileText /> View Document
        </Button>
      ) : null;
    
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>                        
          <Card.Title id='billText' onClick={() => handleContainerClick(title, office, state, party, district, date, id)}>{title}</Card.Title>
          <Card.Body id='billBodyText'>{office} (Candidate)  </Card.Body>
          <Card.Body id='billBodyText'>
        {candidateType !== 'President' && `State: ${state} `}
        {party}
        {candidateType !== 'Senate' && candidateType !== 'President' && ` - District: ${district}`}
      </Card.Body>
          <Card.Body id='billBodyText'>Filing date: {date}</Card.Body>
          {/* <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
            <InfoCircle /> Details
          </Button>
          {viewBillButton}
          <Button id='addToCollectionButton' onClick={() => { setCollectionModalIsOpen(true); saveNote(title, link, '', date); }}>
            <FolderPlus />
          </Button> */}
          <Button id='whDocsViewMoreButton' onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>2024 {candidateType} Candidates</h3></Modal.Header>
            <GenerateFeedStateRepsAll
              stateAbbreviation={stateAbbreviation}
              candidateType={candidateType} />
          </Modal>
          <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
            <Modal.Body id='descriptionModalBody'>
              {date}
              <div id='fullBill'>
                <Form id='noteModalForm'>
                  <Form.Group id='noteModalForm' className="mb-3">
                    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as="textarea" rows={2} />
                    <br />
                  </Form.Group>
                </Form>
                <Modal.Footer id='addNoteModalFooter'>
                  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => { 
                    saveNote(title, link, "", date); 
                    setCollectionModalIsOpen(false); 
                  }}>
                    Save To Collection
                  </Button>
                </Modal.Footer>
              </div>
            </Modal.Body>
          </Modal>
        </Container>, containerElement
      );
    }

        export function CreateFeedItemLegacy(url, divID, feedName, feedBranch, feedTopic, FeedFunction) {
    }

       export function CreateFeedAPIDepartmentCanada(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
      const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
      const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
      const [title, setTitle] = useState('');
      const [link, setLink] = useState('');
      const [docLink, setDocLink] = useState('');
      const [textLink, setTextLink] = useState('');
      const [date, setDate] = useState('');
      const [loading, setLoading] = useState(true);
      const fullFeedUrl = `https://api.govglance.org/departments/?schema_name=canada${apiURL}`;
      
      useEffect(() => {
        //console.log(fullFeedUrl);
      }, [apiURL]);
    
      function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
        var searchItem = document.getElementById('noteModalFeedItem').value;
        let collectionCounter = localStorage.getItem('collectionNumber');
        collectionCounter++;
      
        var title = 'title'.concat(collectionCounter);
        var detailLink = 'detailLink'.concat(collectionCounter);
        var docLink = 'docLink'.concat(collectionCounter);
        var itemNote = 'itemNote'.concat(collectionCounter);
        var itemDate = 'itemDate'.concat(collectionCounter);
        var feedName = 'feedName'.concat(collectionCounter);
        var branchName = 'branchName'.concat(collectionCounter);
        var topicName = 'topicName'.concat(collectionCounter);
        
        localStorage.setItem(title, saveCollectionTitle);
        localStorage.setItem(detailLink, saveCollectionDetailLink);
        localStorage.setItem(docLink, saveCollectionDocLink);
        localStorage.setItem(itemNote, searchItem);
        localStorage.setItem(itemDate, saveCollectionDate);
        localStorage.setItem(feedName, saveCollectionFeedName);
        localStorage.setItem(branchName, saveCollectionBranchName);
        localStorage.setItem(topicName, saveCollectionTopicName);
        localStorage.setItem('collectionNumber', collectionCounter);
        window.location.reload();
      }
    
      function formatDate(inputDate) {
        const date = new Date(inputDate);
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const day = utcDate.getDate();
        const month = utcDate.getMonth() + 1;
        const year = utcDate.getFullYear();
        return `${month}-${day}-${year}`;
      }
    
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${apiKey}`,
        },
      };
    
      const displayData = (data) => {
        if (data && data.length > 0) {
          const firstItem = data[0];
          let title = firstItem.status_title || firstItem.title;
          if (title.length > 180) {
            title = title.substring(0, 177) + '...';
          }
          setTitle(title);
          setLink(firstItem.url);
          setDocLink(firstItem.document_link || firstItem.download?.pdfLink || '');
          setTextLink(firstItem.document_link || firstItem.download?.pdfLink || firstItem.url);
          setDate(formatDate(firstItem.created_at));
        }
        setLoading(false);
      };
    
      useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {                            
            const response = await fetch(`https://api.govglance.org/posts/recent?limit=10&skip=0&schema=canada${apiURL}&limit=1&skip=0`, requestOptions);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            displayData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
    
        fetchData();
      }, [apiURL]);
    
      const targetElement = document.getElementById(divID);
      if (!targetElement) {
        console.error(`Element with id ${divID} not found`);
        return null;
      }
    
      const viewBillButton = docLink && feedButtonName ? (
        <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink); }}>
          <FileText /> {feedButtonName}
        </Button>
      ) : null;
    
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          {loading ? (
            <Spinner animation="grow" variant="light" />
          ) : (
            <>
              <Card.Title id='billText' onClick={(e) => { e.preventDefault(); window.open(textLink, "_blank") }}>{title}</Card.Title>
              <Card.Body id='billBodyText'>{date}</Card.Body>
              <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
                <InfoCircle /> Details
              </Button>
              {viewBillButton}
              <Button id='addToCollectionButton' onClick={() => { setCollectionModalIsOpen(true); }}>
                <FolderPlus />
              </Button>
              <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
                <Modal.Body id='descriptionModalBody'>
                  {date}
                  <Form id='noteModalForm'>
                    <Form.Group className="mb-3">
                      <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as="textarea" rows={2} />
                    </Form.Group>
                  </Form>
                  <Modal.Footer>
                    <Button variant="primary" onClick={() => { saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false); }}>
                      Save To Collection
                    </Button>
                  </Modal.Footer>
                </Modal.Body>
              </Modal>
              <Button id='whDocsViewMoreButton' onClick={() => { setFeedItemModalIsOpen(true); }}>
                <Files size={20} /> View More
              </Button>
              <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                <FeedFunction url={apiURL} name={feedName} branch='Executive' topic={feedTopic} buttonName='Doc' />
              </Modal>
            </>
          )}
        </Container>,
        targetElement
      );
    }
    export function CreateFeedAPIDepartmentUK(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
      const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
      const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
      const [title, setTitle] = useState('');
      const [link, setLink] = useState('');
      const [docLink, setDocLink] = useState('');
      const [textLink, setTextLink] = useState('');
      const [date, setDate] = useState('');
      const [loading, setLoading] = useState(true);
      const fullFeedUrl = `https://api.govglance.org/posts/recent?limit=10&skip=0&schema=united_kingdom${apiURL}`;

      
      useEffect(() => {
       // console.log(fullFeedUrl);
      }, [apiURL]);
    
      function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
        var searchItem = document.getElementById('noteModalFeedItem').value;
        let collectionCounter = localStorage.getItem('collectionNumber');
        collectionCounter++;
      
        var title = 'title'.concat(collectionCounter);
        var detailLink = 'detailLink'.concat(collectionCounter);
        var docLink = 'docLink'.concat(collectionCounter);
        var itemNote = 'itemNote'.concat(collectionCounter);
        var itemDate = 'itemDate'.concat(collectionCounter);
        var feedName = 'feedName'.concat(collectionCounter);
        var branchName = 'branchName'.concat(collectionCounter);
        var topicName = 'topicName'.concat(collectionCounter);
        
        localStorage.setItem(title, saveCollectionTitle);
        localStorage.setItem(detailLink, saveCollectionDetailLink);
        localStorage.setItem(docLink, saveCollectionDocLink);
        localStorage.setItem(itemNote, searchItem);
        localStorage.setItem(itemDate, saveCollectionDate);
        localStorage.setItem(feedName, saveCollectionFeedName);
        localStorage.setItem(branchName, saveCollectionBranchName);
        localStorage.setItem(topicName, saveCollectionTopicName);
        localStorage.setItem('collectionNumber', collectionCounter);
        window.location.reload();
      }
    
      function formatDate(inputDate) {
        const date = new Date(inputDate);
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const day = utcDate.getDate();
        const month = utcDate.getMonth() + 1;
        const year = utcDate.getFullYear();
        return `${month}-${day}-${year}`;
      }
    
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${apiKey}`,
        },
      };
    
      const displayData = (data) => {
        if (data && data.length > 0) {
          const firstItem = data[0];
          let title = firstItem.status_title || firstItem.title;
          if (title.length > 180) {
            title = title.substring(0, 177) + '...';
          }
          setTitle(title);
          setLink(firstItem.url);
          setDocLink(firstItem.document_link || firstItem.download?.pdfLink || '');
          setTextLink(firstItem.document_link || firstItem.download?.pdfLink || firstItem.url);
          setDate(formatDate(firstItem.created_at));
        }
        setLoading(false);
      };
    
      useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {                            
            const response = await fetch(`https://api.govglance.org/posts/recent?limit=10&skip=0&schema=united_kingdom${apiURL}`, requestOptions);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            displayData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
    
        fetchData();
      }, [apiURL]);
    
      const targetElement = document.getElementById(divID);
      if (!targetElement) {
        console.error(`Element with id ${divID} not found`);
        return null;
      }
    
      const viewBillButton = docLink && feedButtonName ? (
        <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink); }}>
          <FileText /> {feedButtonName}
        </Button>
      ) : null;
    
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          {loading ? (
            <Spinner animation="grow" variant="light" />
          ) : (
            <>
              <Card.Title id='billText' onClick={(e) => { e.preventDefault(); window.open(textLink, "_blank") }}>{title}</Card.Title>
              <Card.Body id='billBodyText'>{date}</Card.Body>
              <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
                <InfoCircle /> Details
              </Button>
              {viewBillButton}
              <Button id='addToCollectionButton' onClick={() => { setCollectionModalIsOpen(true); }}>
                <FolderPlus />
              </Button>
              <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
                <Modal.Body id='descriptionModalBody'>
                  {date}
                  <Form id='noteModalForm'>
                    <Form.Group className="mb-3">
                      <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as="textarea" rows={2} />
                    </Form.Group>
                  </Form>
                  <Modal.Footer>
                    <Button variant="primary" onClick={() => { saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false); }}>
                      Save To Collection
                    </Button>
                  </Modal.Footer>
                </Modal.Body>
              </Modal>
              <Button id='whDocsViewMoreButton' onClick={() => { setFeedItemModalIsOpen(true); }}>
                <Files size={20} /> View More
              </Button>
              <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                <FeedFunction url={apiURL} name={feedName} branch='Executive' topic={feedTopic} buttonName='Doc' />
              </Modal>
            </>
          )}
        </Container>,
        targetElement
      );
    }


    export function CreateFeedAPIArguments(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
      const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
      const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
      const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // Share Modal State
      const [linkCopied, setLinkCopied] = useState(false); // To track if the link is copied
      const [shareLink, setShareLink] = useState(''); // The link to share
      const [title, setTitle] = useState('');
      const [link, setLink] = useState('');
      const [docLink, setDocLink] = useState('');
      const [textLink, setTextLink] = useState('');
      const [date, setDate] = useState('');
      const [itemID, setItemID] = useState('');
    
      // Function to handle opening the share modal
      const handleShareModalOpen = (id) => {
        const baseUrl = 'https://govglance.org';
        const fullUrl = `${baseUrl}/feed/united_states_of_america/supreme_court_arguments/${id}`;
        setShareLink(fullUrl);
        setShareModalIsOpen(true);
        setLinkCopied(false);
      };
    
      // Function to copy the share link
      const handleCopyLink = () => {
        navigator.clipboard.writeText(shareLink)
          .then(() => setLinkCopied(true))
          .catch((error) => console.error('Error copying link:', error));
      };
    
      function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
        var searchItem = document.getElementById('noteModalFeedItem').value;
        var collectionCounter = localStorage.getItem('collectionNumber');
        collectionCounter++;
    
        var title = 'title'.concat(collectionCounter);
        var detailLink = 'detailLink'.concat(collectionCounter);
        var docLink = 'docLink'.concat(collectionCounter);
        var itemNote = 'itemNote'.concat(collectionCounter);
        var itemDate = 'itemDate'.concat(collectionCounter);
        var feedName = 'feedName'.concat(collectionCounter);
        var branchName = 'branchName'.concat(collectionCounter);
        var topicName = 'topicName'.concat(collectionCounter);
    
        localStorage.setItem(title, saveCollectionTitle);
        localStorage.setItem(detailLink, saveCollectionDetailLink);
        localStorage.setItem(docLink, saveCollectionDocLink);
        localStorage.setItem(itemNote, searchItem);
        localStorage.setItem(itemDate, saveCollectionDate);
        localStorage.setItem(feedName, saveCollectionFeedName);
        localStorage.setItem(branchName, saveCollectionBranchName);
        localStorage.setItem(topicName, saveCollectionTopicName);
        localStorage.setItem('collectionNumber', collectionCounter);
        window.location.reload();
      }
    
      function formatDate(inputDate) {
        const date = new Date(inputDate);
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const day = utcDate.getDate();
        const month = utcDate.getMonth() + 1;
        const year = utcDate.getFullYear();
    
        return `${month}-${day}-${year}`;
      }
    
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + apiKey,
        },
      };
    
      const displayData = (cachedData) => {
        const firstItem = cachedData[0];
        let title = firstItem.status_title || firstItem.title;
        if (title.length > 180) {
          title = title.substring(0, 177) + '...';
        }
        setTitle(title);
        setLink(firstItem.url);
        setItemID(firstItem.id);
    
        if (firstItem.document_link) {
          setDocLink(firstItem.pdf_url);
          setTextLink(firstItem.pdf_url);
        } else if (firstItem.download && firstItem.download.pdfLink) {
          setDocLink(firstItem.download.pdf_url);
          setTextLink(firstItem.download.pdf_url);
        } else {
          setTextLink(firstItem.pdf_url);
        }
        setDate(formatDate(firstItem.created_at));
      };
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            const cachedData = localStorage.getItem(feedName + 'cachedData');
    
            // Display cached data if available
            if (cachedData) {
              displayData(JSON.parse(cachedData));
            }
    
            const response = await fetch(`https://api.govglance.org/posts/recent?limit=1${apiURL}`, requestOptions);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
    
            // Update cache with fetched data if no existing cached data or fetched data differs from cached data
            if (!cachedData || JSON.stringify(data) !== cachedData) {
              localStorage.setItem(feedName + 'cachedData', JSON.stringify(data));
            }
    
            // Display the fetched data
            displayData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [apiURL]);
    
      if (title.length === 0) {
        return <Spinner animation="grow" variant="light" />;
      }
    
      if (title === 'Coming Soon') {
        return ReactDOM.createPortal(
          <Container id="feedContainerNormalView">
            <Card.Title id="billText">{title}</Card.Title>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </Container>,
          document.getElementById(divID.toString())
        );
      }
    
      const viewBillButton = docLink && feedButtonName ? (
        <Button id="viewBillButton" onClick={(e) => { e.preventDefault(); window.open(docLink, '_blank'); }}>
          <FileText /> {feedButtonName}
        </Button>
      ) : null;
    
      return ReactDOM.createPortal(
        <Container id="feedContainerNormalView">
          <Card.Title id="billText" onClick={(e) => { e.preventDefault(); window.open(docLink, '_blank'); }}>{title}</Card.Title>
          <Card.Body id="billBodyText">{date}</Card.Body>
          <Button id="billLink" onClick={(e) => { e.preventDefault(); window.open(link, '_blank'); }}>
            <PlayCircle /> Audio
          </Button>
    
          <Button id="viewBillButton" onClick={(e) => { e.preventDefault(); window.open(link, '_blank'); }}>
            <FileText /> Document
          </Button>
    
          {/* Add to collection button */}
          <Button id="addToCollectionButton" onClick={() => { setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic); }}>
            <FolderPlus />
          </Button>
    
          {/* Share button */}
          <Button id="shareButton" onClick={() => handleShareModalOpen(itemID)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
              <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
            </svg>
          </Button>
    
          {/* Share Modal */}
          <Modal id="fullBillModal" show={shareModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setShareModalIsOpen(false)}>
            <Modal.Header id="billModalHeader" closeButton>
              <h3>Share</h3>
            </Modal.Header>
            <Modal.Body id="descriptionModalBody">
              <Form.Group id="noteModalForm">
                <Form.Control
                  style={{
                    backgroundColor: 'transparent',
                    color: 'white',
                    outline: 'none',
                  }}
                  id="noteModalFeedItem"
                  type="text"
                  readOnly
                  value={shareLink}
                />
              </Form.Group>
              <Modal.Footer id="shareFooter">
                <Button id="addToCollectionButton" onClick={handleCopyLink}>
                  {linkCopied ? <ClipboardCheck /> : <Clipboard />}
                </Button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>
    
          {/* Collection Modal */}
          <Modal id="fullBillModal" show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
            <Modal.Header id="billModalHeader" closeButton>
              <h3>{title}</h3>
            </Modal.Header>
            <Modal.Body id="descriptionModalBody">
              {date}
              <div id="fullBill">
                <Form id="noteModalForm">
                  <Form.Group className="mb-3">
                    <Form.Control
                      id="noteModalFeedItem"
                      placeholder="Add a note here"
                      type="text"
                      as="textarea"
                      rows={2}
                    />
                    <br />
                  </Form.Group>
                </Form>
                <Modal.Footer id="addNoteModalFooter">
                  <Button variant="primary" id="noteSaveToCollectionButton" onClick={() => { saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false); }}>
                    Save To Collection
                  </Button>
                </Modal.Footer>
              </div>
            </Modal.Body>
          </Modal>
    
          {/* View More Button */}
          <Button id="whDocsViewMoreButton" onClick={(e) => { e.preventDefault(); window.open('/feed/united_states_of_america/supreme_court_arguments', "_self"); }}>
            <Files size={20} /> View More
          </Button>
          <Modal id="fullBillModal" show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
            <Modal.Header id="billModalHeader" closeButton>
              <h3>{feedName}</h3>
            </Modal.Header>
            <FeedFunction name={feedName} url={apiURL} branch={feedBranch} topic={feedTopic} buttonName={feedButtonName} />
          </Modal>
        </Container>,
        document.getElementById(divID.toString())
      );
    }
    
    
    
    

    export function CreateFeedAPIOpinions(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
      const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
      const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
      const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // Share Modal State
      const [linkCopied, setLinkCopied] = useState(false); // To track if the link is copied
      const [shareLink, setShareLink] = useState(''); // The link to share
      const [title, setTitle] = useState('');
      const [link, setLink] = useState('');
      const [docLink, setDocLink] = useState('');
      const [textLink, setTextLink] = useState('');
      const [date, setDate] = useState('');
      const [itemID, setItemID] = useState('');
    
      // Function to handle opening the share modal
      const handleShareModalOpen = (id) => {
        const baseUrl = 'https://govglance.org';
        const fullUrl = `${baseUrl}/feed/united_states_of_america/supreme_court_opinions/${id}`;
        setShareLink(fullUrl);
        setShareModalIsOpen(true);
        setLinkCopied(false);
      };
    
      // Function to copy the share link
      const handleCopyLink = () => {
        navigator.clipboard.writeText(shareLink)
          .then(() => setLinkCopied(true))
          .catch((error) => console.error('Error copying link:', error));
      };
    
      function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
        var searchItem = document.getElementById('noteModalFeedItem').value;
        var collectionCounter = localStorage.getItem('collectionNumber');
        collectionCounter++;
    
        var title = 'title'.concat(collectionCounter);
        var detailLink = 'detailLink'.concat(collectionCounter);
        var docLink = 'docLink'.concat(collectionCounter);
        var itemNote = 'itemNote'.concat(collectionCounter);
        var itemDate = 'itemDate'.concat(collectionCounter);
        var feedName = 'feedName'.concat(collectionCounter);
        var branchName = 'branchName'.concat(collectionCounter);
        var topicName = 'topicName'.concat(collectionCounter);
    
        localStorage.setItem(title, saveCollectionTitle);
        localStorage.setItem(detailLink, saveCollectionDetailLink);
        localStorage.setItem(docLink, saveCollectionDocLink);
        localStorage.setItem(itemNote, searchItem);
        localStorage.setItem(itemDate, saveCollectionDate);
        localStorage.setItem(feedName, saveCollectionFeedName);
        localStorage.setItem(branchName, saveCollectionBranchName);
        localStorage.setItem(topicName, saveCollectionTopicName);
        localStorage.setItem('collectionNumber', collectionCounter);
        window.location.reload();
      }
    
      function formatDate(inputDate) {
        const date = new Date(inputDate);
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const day = utcDate.getDate();
        const month = utcDate.getMonth() + 1;
        const year = utcDate.getFullYear();
    
        return `${month}-${day}-${year}`;
      }
    
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + apiKey,
        },
      };
    
      const displayData = (cachedData) => {
        const firstItem = cachedData[0];
        let title = firstItem.status_title || firstItem.title;
        if (title.length > 180) {
          title = title.substring(0, 177) + '...';
        }
        setTitle(title);
        setLink(firstItem.url);
        setItemID(firstItem.id);
        if (firstItem.document_link) {
          setDocLink(firstItem.document_link);
          setTextLink(firstItem.document_link);
        } else if (firstItem.download && firstItem.download.pdfLink) {
          setDocLink(firstItem.download.pdfLink);
          setTextLink(firstItem.download.pdfLink);
        } else {
          setTextLink(firstItem.url);
        }
        setDate(formatDate(firstItem.created_at));
      };
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            const cachedData = localStorage.getItem(feedName + 'cachedData');
    
            if (cachedData) {
              displayData(JSON.parse(cachedData));
            }
    
            const response = await fetch(`https://api.govglance.org/posts/recent?limit=1${apiURL}`, requestOptions);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
    
            if (!cachedData || JSON.stringify(data) !== cachedData) {
              localStorage.setItem(feedName + 'cachedData', JSON.stringify(data));
            }
    
            displayData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [apiURL]);
    
      if (title.length === 0) {
        return <Spinner animation="grow" variant="light" />;
      }
    
      if (title === 'Coming Soon') {
        return ReactDOM.createPortal(
          <Container id="feedContainerNormalView">
            <Card.Title id="billText">{title}</Card.Title>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </Container>,
          document.getElementById(divID.toString())
        );
      }
    
      const viewBillButton = docLink && feedButtonName ? (
        <Button id="viewBillButton" onClick={(e) => { e.preventDefault(); window.open(link, '_blank'); }}>
          <FileText /> {feedButtonName}
        </Button>
      ) : null;
    
      return ReactDOM.createPortal(
        <Container id="feedContainerNormalView">
          <Card.Title id="billText" onClick={(e) => { e.preventDefault(); window.open(textLink, '_blank'); }}>{title}</Card.Title>
          <Card.Body id="billBodyText">{date}</Card.Body>
          <Button id="billLink" onClick={(e) => { e.preventDefault(); window.open(link, '_blank'); }}>
            <FileText /> Document
          </Button>
          {viewBillButton}
          
          {/* Add to collection button */}
          <Button id="addToCollectionButton" onClick={() => { setCollectionModalIsOpen(true); }}>
            <FolderPlus /> 
          </Button>
    
          {/* Share button */}
          <Button id="shareButton" onClick={() => handleShareModalOpen(itemID)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
              <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
            </svg>
          </Button>
    
          {/* View More button */}
          <Button id="whDocsViewMoreButton" onClick={(e) => { e.preventDefault(); window.open('/feed/united_states_of_america/supreme_court_opinions', "_self"); }}>
            <Files size={20} /> View More
          </Button>
    
          {/* Share Modal */}
          <Modal id="fullBillModal" show={shareModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setShareModalIsOpen(false)}>
            <Modal.Header id="billModalHeader" closeButton>
              <h3>Share</h3>
            </Modal.Header>
            <Modal.Body id="descriptionModalBody">
              <Form.Group id="noteModalForm">
                <Form.Control
                  style={{
                    backgroundColor: 'transparent',
                    color: 'white',
                    outline: 'none',
                  }}
                  id="noteModalFeedItem"
                  type="text"
                  readOnly
                  value={shareLink}
                />
              </Form.Group>
              <Modal.Footer id="shareFooter">
                <Button id="addToCollectionButton" onClick={handleCopyLink}>
                  {linkCopied ? <ClipboardCheck /> : <Clipboard />}
                </Button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>
    
          {/* Collection Modal */}
          <Modal id="fullBillModal" show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
            <Modal.Header id="billModalHeader" closeButton>
              <h3>{title}</h3>
            </Modal.Header>
            <Modal.Body id="descriptionModalBody">
              {date}
              <div id="fullBill">
                <Form id="noteModalForm">
                  <Form.Group className="mb-3">
                    <Form.Control
                      id="noteModalFeedItem"
                      placeholder="Add a note here"
                      type="text"
                      as="textarea"
                      rows={2}
                    />
                    <br />
                  </Form.Group>
                </Form>
                <Modal.Footer id="addNoteModalFooter">
                  <Button variant="primary" id="noteSaveToCollectionButton" onClick={() => { saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false); }}>
                    Save To Collection
                  </Button>
                </Modal.Footer>
              </div>
            </Modal.Body>
          </Modal>
        </Container>,
        document.getElementById(divID.toString())
      );
    }
    
    


    // export function CreateFeedAPIWithPageTest(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
    //   const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
    //   const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
    //   const [title, setTitle] = useState('');
    //   const [link, setLink] = useState('');
    //   const [docLink, setDocLink] = useState('');
    //   const [textLink, setTextLink] = useState('');
    //   const [date, setDate] = useState('');
    //   const [itemID, setItemID] = useState('');
    //   const history = useHistory(); // Using react-router-dom's useHistory hook
    
    //   function displayData(cachedData) {
    //     const firstItem = cachedData[0];
    //     let title = firstItem.status_title || firstItem.title;
      
    //     if (title.length > 180) {
    //       title = title.substring(0, 177) + '...';
    //     }
    //     setItemID(firstItem.id);
    //     setTitle(title);
    //     setLink(firstItem.url);
      
    //     if (firstItem.document_link) {
    //       setDocLink(firstItem.document_link);
    //       setTextLink(firstItem.document_link);
    //     } else if (firstItem.download && firstItem.download.pdfLink) {
    //       setDocLink(firstItem.download.pdfLink);
    //       setTextLink(firstItem.download.pdfLink);
    //     } else {
    //       setTextLink(firstItem.url);
    //     }
      
    //     setDate(formatDate(firstItem.created_at));
    //   }
    
    //   // Save note function
    //   function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
    //     var searchItem = document.getElementById('noteModalFeedItem').value;
    //     var collectionCounter = localStorage.getItem('collectionNumber');
    //     collectionCounter++;
      
    //     var title = 'title'.concat(collectionCounter);
    //     var detailLink = 'detailLink'.concat(collectionCounter);
    //     var docLink = 'docLink'.concat(collectionCounter);
    //     var itemNote = 'itemNote'.concat(collectionCounter);
    //     var itemDate = 'itemDate'.concat(collectionCounter);
    //     var feedName = 'feedName'.concat(collectionCounter);
    //     var branchName = 'branchName'.concat(collectionCounter);
    //     var topicName = 'topicName'.concat(collectionCounter);
      
    //     localStorage.setItem(title, saveCollectionTitle);
    //     localStorage.setItem(detailLink, saveCollectionDetailLink);
    //     localStorage.setItem(docLink, saveCollectionDocLink);
    //     localStorage.setItem(itemNote, searchItem);
    //     localStorage.setItem(itemDate, saveCollectionDate);
    //     localStorage.setItem(feedName, saveCollectionFeedName);
    //     localStorage.setItem(branchName, saveCollectionBranchName);
    //     localStorage.setItem(topicName, saveCollectionTopicName);
    //     localStorage.setItem('collectionNumber', collectionCounter);
    //     window.location.reload();
    //   }
    
    //   function formatDate(inputDate) {
    //     const date = new Date(inputDate);
    //     const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    //     const day = utcDate.getDate();
    //     const month = utcDate.getMonth() + 1;
    //     const year = utcDate.getFullYear();
    //     return `${month}-${day}-${year}`;
    //   }
    
    //   // Fetch and display data
    //   useEffect(() => {
    //     const fetchData = async () => {
    //       try {
    //         const cachedData = localStorage.getItem(feedName + 'cachedData');
    
    //         if (cachedData) {
    //           displayData(JSON.parse(cachedData));
    //         }
    
    //         const response = await fetch(`https://api.govglance.org/posts/recent?limit=1${apiURL}`, {
    //           method: 'GET',
    //           headers: { 
    //             'Content-Type': 'application/json',
    //             "Authorization": "Bearer " + apiKey,
    //           },
    //         });
    
    //         if (!response.ok) {
    //           throw new Error(`HTTP error! status: ${response.status}`);
    //         }
    
    //         const data = await response.json();
    
    //         if (!cachedData || JSON.stringify(data) !== cachedData) {
    //           localStorage.setItem(feedName + 'cachedData', JSON.stringify(data));
    //         }
    
    //         displayData(data);
    //       } catch (error) {
    //         console.error('Error fetching data:', error);
    //       }
    //     };
    
    //     fetchData();
    //   }, [apiURL]);
    
    //   // Open the feed page on button click
    //   const handleViewMoreClick = () => {
    //     const schema = new URLSearchParams(apiURL).get('schema');
    //     const table = new URLSearchParams(apiURL).get('table');
    
    //     history.push({
    //       pathname: `/feed/${schema}/${table}`,
    //       state: {
    //         apiURL: apiURL,
    //         feedName: feedName,
    //         feedBranch: feedBranch,
    //         feedTopic: feedTopic,
    //         feedButtonName: feedButtonName,
    //       },
    //     });
    //   };
    
    //   const handleDetailClick = (id) => {
    //     const schema = new URLSearchParams(apiURL).get('schema');
    //     const table = new URLSearchParams(apiURL).get('table');
    
    //     history.push({
    //       pathname: `/feed/${schema}/${table}/${id}`,
    //       state: {
    //         apiURL: apiURL,
    //         feedName: feedName,
    //         feedBranch: feedBranch,
    //         feedTopic: feedTopic,
    //         feedButtonName: feedButtonName,
    //       },
    //     });
    //   };
    
    //   return document.getElementById(divID.toString()) ? ReactDOM.createPortal(
    //     <Container id='feedContainerNormalView'>
    //       <Card.Title id='billText' onClick={(e) => { e.preventDefault(); window.open(textLink, "_blank") }}>{title}</Card.Title>
    //       <Card.Body id='billBodyText'>{date}</Card.Body>
    //       {/* Passing the function correctly to the More button */}
    //       <Button id='billLink' onClick={() => handleDetailClick(itemID)}>
    //         <InfoCircle /> More
    //       </Button>
    //       {docLink && feedButtonName && (
    //         <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
    //           <FileText /> {feedButtonName}
    //         </Button>
    //       )}
    //       <Button id='addToCollectionButton' onClick={() => setCollectionModalIsOpen(true)}>
    //         <FolderPlus />
    //       </Button>
    //       <Button id='whDocsViewMoreButton' onClick={handleViewMoreClick}>
    //         <Files size={20} /> View More
    //       </Button>
    //     </Container>,
    //     document.getElementById(divID.toString())
    //   ) : null;
    // }
//ABOVE IS THE SAME FUNCTION

export function CreateFeedAPIREAL(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
  const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // Share Modal State
  const [linkCopied, setLinkCopied] = useState(false); // To track if the link is copied
  const [shareLink, setShareLink] = useState(''); // The link to share
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [docLink, setDocLink] = useState('');
  const [textLink, setTextLink] = useState('');
  const [date, setDate] = useState('');
  const [itemID, setItemID] = useState('');
  const history = useHistory(); // Using react-router-dom's useHistory hook

  const urlParams = new URLSearchParams(apiURL);
  const schema = urlParams.get('schema');
  const table = urlParams.get('table');

  // Function to handle opening the share modal
  const handleShareModalOpen = (id) => {
    const baseUrl = 'https://govglance.org';
    const fullUrl = `${baseUrl}/feed/${schema}/${table}/${id}`;

    setShareLink(fullUrl);
    setShareModalIsOpen(true);
    setLinkCopied(false);
  };



  // Function to copy the share link
  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink)
      .then(() => setLinkCopied(true))
      .catch((error) => console.error('Error copying link:', error));
  };

  // Function to display data
  function displayData(cachedData) {
    const firstItem = cachedData[0];
    let title = firstItem.status_title || firstItem.title;

    if (title.length > 180) {
      title = title.substring(0, 177) + '...';
    }
    setItemID(firstItem.id);
    setTitle(title);
    setLink(firstItem.url);

    if (firstItem.document_link) {
      setDocLink(firstItem.document_link);
      setTextLink(firstItem.document_link);
    } else if (firstItem.download?.pdfLink) {
      setDocLink(firstItem.download.pdfLink);
      setTextLink(firstItem.download.pdfLink);
    } else {
      setTextLink(firstItem.url);
    }

    setDate(formatDate(firstItem.created_at));
  }

  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
    var searchItem = document.getElementById('noteModalFeedItem').value;
    var collectionCounter = localStorage.getItem('collectionNumber');
    collectionCounter++;
  
    var title = 'title'.concat(collectionCounter);
    var detailLink = 'detailLink'.concat(collectionCounter);
    var docLink = 'docLink'.concat(collectionCounter);
    var itemNote = 'itemNote'.concat(collectionCounter);
    var itemDate = 'itemDate'.concat(collectionCounter);
    var feedName = 'feedName'.concat(collectionCounter);
    var branchName = 'branchName'.concat(collectionCounter);
    var topicName = 'topicName'.concat(collectionCounter);
  
    localStorage.setItem(title, saveCollectionTitle);
    localStorage.setItem(detailLink, saveCollectionDetailLink);
    localStorage.setItem(docLink, saveCollectionDocLink);
    localStorage.setItem(itemNote, searchItem);
    localStorage.setItem(itemDate, saveCollectionDate);
    localStorage.setItem(feedName, saveCollectionFeedName);
    localStorage.setItem(branchName, saveCollectionBranchName);
    localStorage.setItem(topicName, saveCollectionTopicName);
    localStorage.setItem('collectionNumber', collectionCounter);
    window.location.reload();
  }

  const handleNativeShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: '',
          text: '',
          url: shareLink,
        });
        console.log('Content shared successfully!');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert('Sharing is not supported on this device.');
    }
  };
  

  // Function to format date
  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    const day = utcDate.getDate();
    const month = utcDate.getMonth() + 1;
    const year = utcDate.getFullYear();
    return `${month}-${day}-${year}`;
  };

  // Fetch and display data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const cachedData = localStorage.getItem(feedName + 'cachedData');

        if (cachedData) {
          displayData(JSON.parse(cachedData));
        }

        const response = await fetch(`https://api.govglance.org/posts/recent?limit=1${apiURL}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + apiKey,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (!cachedData || JSON.stringify(data) !== cachedData) {
          localStorage.setItem(feedName + 'cachedData', JSON.stringify(data));
        }

        displayData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [apiURL]);

  // Function to handle "View More" button click
  const handleViewMoreClick = () => {
  
    history.push({
      pathname: `/feed/${schema}/${table}`,
      state: {
        apiURL,
        feedName,
        feedBranch,
        feedTopic,
        feedButtonName
      }
    });
  };

  return document.getElementById(divID.toString()) ? ReactDOM.createPortal(
    <Container id="feedContainerNormalView">
   <Card.Title
  id="billText"
  onClick={(e) => {
    e.preventDefault();
    // Check if the table matches the specific tables
    if (
      table === 'congressional_bills_enrolled' ||
      table === 'congressional_bills' ||
      table === 'public_and_private_laws'
    ) {
      history.push(`/feed/${schema}/${table}/${itemID}`);
    } else {
      window.open(textLink, '_blank');
    }
  }}
>
  {title}
</Card.Title>
      <Card.Body id="billBodyText">{date}</Card.Body>

        {/* Conditionally render "More" or "Details" button based on the table value */}
        {(table === 'congressional_bills' || table === 'congressional_bills_enrolled' || table === 'public_and_private_laws') ? (
      <Button id="billLink" onClick={() => history.push(`/feed/${schema}/${table}/${itemID}`)}>
        <InfoCircle /> More
      </Button>
    ) : (
      <Button id="billLink" onClick={(e) => { e.preventDefault(); window.open(link, "_blank"); }}>
        <InfoCircle /> Details
      </Button>
    )}
      {docLink && feedButtonName && (
        <Button id="viewBillButton" onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
          <FileText /> {feedButtonName}
        </Button>
      )}

      <Button id="addToCollectionButton" onClick={() => setCollectionModalIsOpen(true)}>
        <FolderPlus />
      </Button>

      {/* Share Button */}
      <Button id="shareButton" onClick={() => handleShareModalOpen(itemID)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
          <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
        </svg>
      </Button>

      {/* View More Button */}
      <Button id='whDocsViewMoreButton' onClick={handleViewMoreClick}>
      <Files size={20} />   View More
      </Button>

    {/* Share Modal */}
    <Modal id="fullBillModal" show={shareModalIsOpen} size="xl" centered onHide={() => setShareModalIsOpen(false)}>
        <Modal.Header id="billModalHeader" closeButton>
          <h3>Share</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          <Form.Group id="noteModalForm">
            <Form.Control id="noteModalFeedItem" type="text" readOnly value={shareLink} />
          </Form.Group>
          <Modal.Footer id="shareFooter">
          <Button 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>
            <Button id="addToCollectionButton" onClick={handleCopyLink}>
              {linkCopied ? <ClipboardCheck /> : <Clipboard />}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>

      {/* Collection Modal */}
      <Modal id="fullBillModal" show={collectionModalIsOpen} size="xl" centered onHide={() => setCollectionModalIsOpen(false)}>
        <Modal.Header id="billModalHeader" closeButton>
          <h3>{feedName}</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          {formatDate(date)}
          <Form id="noteModalForm">
            <Form.Group className="mb-3">
              <Form.Control id="noteModalFeedItem" placeholder="Add a note here" as="textarea" rows={2} />
            </Form.Group>
          </Form>
          <Modal.Footer id="addNoteModalFooter">
            <Button variant="primary" id="noteSaveToCollectionButton" onClick={saveNote}>
              Save To Collection
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </Container>,
    document.getElementById(divID.toString())
  ) : null;
}


export function CreateFeedAPI(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
  const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // Share Modal State
  const [linkCopied, setLinkCopied] = useState(false); // To track if the link is copied
  const [shareLink, setShareLink] = useState(''); // The link to share
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [docLink, setDocLink] = useState('');
  const [textLink, setTextLink] = useState('');
  const [date, setDate] = useState('');
  const [itemID, setItemID] = useState('');
  const history = useHistory(); // Using react-router-dom's useHistory hook

  const urlParams = new URLSearchParams(apiURL);
  const schema = urlParams.get('schema');
  const table = urlParams.get('table');
  const filterColumn = urlParams.get('filter_column');
  const filterString = urlParams.get('filter_string');
  // Function to handle opening the share modal
  const handleShareModalOpen = (id) => {
    const baseUrl = 'https://govglance.org';
    const fullUrl = `${baseUrl}/feed/${schema}/${table}/${id}`;

    setShareLink(fullUrl);
    setShareModalIsOpen(true);
    setLinkCopied(false);
  };



  // Function to copy the share link
  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink)
      .then(() => setLinkCopied(true))
      .catch((error) => console.error('Error copying link:', error));
  };

  // Function to display data
  function displayData(cachedData) {
    const firstItem = cachedData[0];
    let title = firstItem.status_title || firstItem.title;

    if (title.length > 180) {
      title = title.substring(0, 177) + '...';
    }
    setItemID(firstItem.id);
    setTitle(title);
    setLink(firstItem.url);

    if (firstItem.document_link) {
      setDocLink(firstItem.document_link);
      setTextLink(firstItem.document_link);
    } else if (firstItem.download?.pdfLink) {
      setDocLink(firstItem.download.pdfLink);
      setTextLink(firstItem.download.pdfLink);
    } else {
      setTextLink(firstItem.url);
    }

    setDate(formatDate(firstItem.created_at));
  }

  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
    var searchItem = document.getElementById('noteModalFeedItem').value;
    var collectionCounter = localStorage.getItem('collectionNumber');
    collectionCounter++;
  
    var title = 'title'.concat(collectionCounter);
    var detailLink = 'detailLink'.concat(collectionCounter);
    var docLink = 'docLink'.concat(collectionCounter);
    var itemNote = 'itemNote'.concat(collectionCounter);
    var itemDate = 'itemDate'.concat(collectionCounter);
    var feedName = 'feedName'.concat(collectionCounter);
    var branchName = 'branchName'.concat(collectionCounter);
    var topicName = 'topicName'.concat(collectionCounter);
  
    localStorage.setItem(title, saveCollectionTitle);
    localStorage.setItem(detailLink, saveCollectionDetailLink);
    localStorage.setItem(docLink, saveCollectionDocLink);
    localStorage.setItem(itemNote, searchItem);
    localStorage.setItem(itemDate, saveCollectionDate);
    localStorage.setItem(feedName, saveCollectionFeedName);
    localStorage.setItem(branchName, saveCollectionBranchName);
    localStorage.setItem(topicName, saveCollectionTopicName);
    localStorage.setItem('collectionNumber', collectionCounter);
    window.location.reload();
  }

  const handleNativeShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: '',
          text: '',
          url: shareLink,
        });
        console.log('Content shared successfully!');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert('Sharing is not supported on this device.');
    }
  };
  

  // Function to format date
  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    const day = utcDate.getDate();
    const month = utcDate.getMonth() + 1;
    const year = utcDate.getFullYear();
    return `${month}-${day}-${year}`;
  };

  // Fetch and display data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const cachedData = localStorage.getItem(feedName + 'cachedData');

        if (cachedData) {
          displayData(JSON.parse(cachedData));
        }

        const response = await fetch(`https://api.govglance.org/posts/recent?limit=1${apiURL}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + apiKey,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (!cachedData || JSON.stringify(data) !== cachedData) {
          localStorage.setItem(feedName + 'cachedData', JSON.stringify(data));
        }

        displayData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [apiURL]);

  // Function to handle "View More" button click
  const handleViewMoreClick = () => {
    const state = {
      apiURL,
      feedName,
      feedBranch,
      feedTopic,
      feedButtonName,
      ...(filterColumn && { filterColumn }),
      ...(filterString && { filterString }),
    };

    history.push({
      pathname: `/feed/${schema}/${table}`,
      state,
    });
  };


  return document.getElementById(divID.toString()) ? ReactDOM.createPortal(
    <Container id="feedContainerNormalView">
   <Card.Title
  id="billText"
  onClick={(e) => {
    e.preventDefault();
    // Check if the table matches the specific tables
    if (
      table === 'congressional_bills_enrolled' ||
      table === 'congressional_bills' ||
      table === 'public_and_private_laws'
    ) {
      history.push(`/feed/${schema}/${table}/${itemID}`);
    } else {
      window.open(textLink, '_blank');
    }
  }}
>
  {title}
</Card.Title>
      <Card.Body id="billBodyText">{date}</Card.Body>

        {/* Conditionally render "More" or "Details" button based on the table value */}
        {(table === 'congressional_bills' || table === 'congressional_bills_enrolled' || table === 'public_and_private_laws') ? (
      <Button id="billLink" onClick={() => history.push(`/feed/${schema}/${table}/${itemID}`)}>
        <InfoCircle /> More
      </Button>
    ) : (
      <Button id="billLink" onClick={(e) => { e.preventDefault(); window.open(link, "_blank"); }}>
        <InfoCircle /> Details
      </Button>
    )}
      {docLink && feedButtonName && (
        <Button id="viewBillButton" onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
          <FileText /> {feedButtonName}
        </Button>
      )}

      <Button id="addToCollectionButton" onClick={() => setCollectionModalIsOpen(true)}>
        <FolderPlus />
      </Button>

      {/* Share Button */}
      <Button id="shareButton" onClick={() => handleShareModalOpen(itemID)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
          <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
        </svg>
      </Button>

      {/* View More Button */}
      <Button id='whDocsViewMoreButton' onClick={handleViewMoreClick}>
      <Files size={20} />   View More
      </Button>

    {/* Share Modal */}
    <Modal id="fullBillModal" show={shareModalIsOpen} size="xl" centered onHide={() => setShareModalIsOpen(false)}>
        <Modal.Header id="billModalHeader" closeButton>
          <h3>Share</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          <Form.Group id="noteModalForm">
            <Form.Control id="noteModalFeedItem" type="text" readOnly value={shareLink} />
          </Form.Group>
          <Modal.Footer id="shareFooter">
          <Button 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>
            <Button id="addToCollectionButton" onClick={handleCopyLink}>
              {linkCopied ? <ClipboardCheck /> : <Clipboard />}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>

      {/* Collection Modal */}
      <Modal id="fullBillModal" show={collectionModalIsOpen} size="xl" centered onHide={() => setCollectionModalIsOpen(false)}>
        <Modal.Header id="billModalHeader" closeButton>
          <h3>{feedName}</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          {formatDate(date)}
          <Form id="noteModalForm">
            <Form.Group className="mb-3">
              <Form.Control id="noteModalFeedItem" placeholder="Add a note here" as="textarea" rows={2} />
            </Form.Group>
          </Form>
          <Modal.Footer id="addNoteModalFooter">
            <Button variant="primary" id="noteSaveToCollectionButton" onClick={saveNote}>
              Save To Collection
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </Container>,
    document.getElementById(divID.toString())
  ) : null;
}




export function CreateFeedAPIMobile(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
  const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // Share Modal State
  const [linkCopied, setLinkCopied] = useState(false); // To track if the link is copied
  const [shareLink, setShareLink] = useState(''); // The link to share
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [docLink, setDocLink] = useState('');
  const [textLink, setTextLink] = useState('');
  const [date, setDate] = useState('');
  const [itemID, setItemID] = useState('');
  const history = useHistory(); // Using react-router-dom's useHistory hook

  const urlParams = new URLSearchParams(apiURL);
  const schema = urlParams.get('schema');
  const table = urlParams.get('table');

  // Function to handle opening the share modal
  const handleShareModalOpen = (id) => {
    const baseUrl = 'https://govglance.org';
    const fullUrl = `${baseUrl}/feed/${schema}/${table}/${id}`;

    setShareLink(fullUrl);
    setShareModalIsOpen(true);
    setLinkCopied(false);
  };



  // Function to copy the share link
  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink)
      .then(() => setLinkCopied(true))
      .catch((error) => console.error('Error copying link:', error));
  };

  // Function to display data
  function displayData(cachedData) {
    const firstItem = cachedData[0];
    let title = firstItem.status_title || firstItem.title;

    if (title.length > 180) {
      title = title.substring(0, 177) + '...';
    }
    setItemID(firstItem.id);
    setTitle(title);
    setLink(firstItem.url);

    if (firstItem.document_link) {
      setDocLink(firstItem.document_link);
      setTextLink(firstItem.document_link);
    } else if (firstItem.download?.pdfLink) {
      setDocLink(firstItem.download.pdfLink);
      setTextLink(firstItem.download.pdfLink);
    } else {
      setTextLink(firstItem.url);
    }

    setDate(formatDate(firstItem.created_at));
  }

  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
    var searchItem = document.getElementById('noteModalFeedItem').value;
    var collectionCounter = localStorage.getItem('collectionNumber');
    collectionCounter++;
  
    var title = 'title'.concat(collectionCounter);
    var detailLink = 'detailLink'.concat(collectionCounter);
    var docLink = 'docLink'.concat(collectionCounter);
    var itemNote = 'itemNote'.concat(collectionCounter);
    var itemDate = 'itemDate'.concat(collectionCounter);
    var feedName = 'feedName'.concat(collectionCounter);
    var branchName = 'branchName'.concat(collectionCounter);
    var topicName = 'topicName'.concat(collectionCounter);
  
    localStorage.setItem(title, saveCollectionTitle);
    localStorage.setItem(detailLink, saveCollectionDetailLink);
    localStorage.setItem(docLink, saveCollectionDocLink);
    localStorage.setItem(itemNote, searchItem);
    localStorage.setItem(itemDate, saveCollectionDate);
    localStorage.setItem(feedName, saveCollectionFeedName);
    localStorage.setItem(branchName, saveCollectionBranchName);
    localStorage.setItem(topicName, saveCollectionTopicName);
    localStorage.setItem('collectionNumber', collectionCounter);
    window.location.reload();
  }

  // Function to format date
  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    const day = utcDate.getDate();
    const month = utcDate.getMonth() + 1;
    const year = utcDate.getFullYear();
    return `${month}-${day}-${year}`;
  };

  // Fetch and display data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const cachedData = localStorage.getItem(feedName + 'cachedData');

        if (cachedData) {
          displayData(JSON.parse(cachedData));
        }

        const response = await fetch(`https://api.govglance.org/posts/recent?limit=1${apiURL}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + apiKey,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (!cachedData || JSON.stringify(data) !== cachedData) {
          localStorage.setItem(feedName + 'cachedData', JSON.stringify(data));
        }

        displayData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [apiURL]);

  // Function to handle "View More" button click
  const handleViewMoreClick = () => {
    history.push({
      pathname: `/feed/${schema}/${table}`,
      state: {
        apiURL,
        feedName,
        feedBranch,
        feedTopic,
        feedButtonName
      }
    });
  };

  return document.getElementById(divID.toString()) ? ReactDOM.createPortal(
    <Container id="feedContainerNormalView">
      <Card.Title id="billText" onClick={(e) => { e.preventDefault(); window.open(textLink, "_blank"); }}>
        {title}
      </Card.Title>
      <Card.Body id="billBodyText">{date}</Card.Body>

      <Button id="billLink" onClick={() => history.push(`/feed/${schema}/${table}/${itemID}`)}>
        <InfoCircle /> More
      </Button>
      {docLink && feedButtonName && (
        <Button id="viewBillButton" onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
          <FileText /> {feedButtonName}
        </Button>
      )}

      <Button id="addToCollectionButton" onClick={() => setCollectionModalIsOpen(true)}>
        <FolderPlus />
      </Button>

      {/* Share Button */}
      <Button id="shareButton" onClick={() => handleShareModalOpen(itemID)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
          <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
        </svg>
      </Button>

      {/* View More Button */}
      <Button id='viewMoreButton' onClick={handleViewMoreClick}>
        View More
      </Button>

      {/* Share Modal */}
      <Modal id="fullBillModal" show={shareModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setShareModalIsOpen(false)}>
        <Modal.Header id="billModalHeader" closeButton>
          <h3>Share</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          <Form.Group id="noteModalForm">
            <Form.Control
              style={{
                backgroundColor: 'transparent',
                color: 'white',
                outline: 'none',
              }}
              id="noteModalFeedItem"
              type="text"
              readOnly
              value={shareLink}
            />
          </Form.Group>
          <Modal.Footer id="shareFooter">
            <Button id="addToCollectionButton" onClick={handleCopyLink}>
              {linkCopied ? <ClipboardCheck /> : <Clipboard />}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>

      {/* Collection Modal */}
      <Modal
        id="fullBillModal"
        show={collectionModalIsOpen}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setCollectionModalIsOpen(false)}
      >
        <Modal.Header id="billModalHeader" closeButton>
          <h3>{title}</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          {date}
          <div id="fullBill">
            <Form id="noteModalForm">
              <Form.Group className="mb-3">
                <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as="textarea" rows={2} />
                <br />
              </Form.Group>
            </Form>
            <Modal.Footer id="addNoteModalFooter">
              <Button variant="primary" id="noteSaveToCollectionButton" onClick={() => saveNote()}>
                Save To Collection
              </Button>
            </Modal.Footer>
          </div>
        </Modal.Body>
      </Modal>
    </Container>,
    document.getElementById(divID.toString())
  ) : null;
}

    export function CreateFeedAPIWithPageTest(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
      const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
      const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
      const [title, setTitle] = useState('');
      const [link, setLink] = useState('');
      const [docLink, setDocLink] = useState('');
      const [textLink, setTextLink] = useState('');
      const [date, setDate] = useState('');
      const [itemID, setItemID] = useState('');
      const history = useHistory(); // Using react-router-dom's useHistory hook
    
      function displayData(cachedData) {
        const firstItem = cachedData[0];
        let title = firstItem.status_title || firstItem.title;
      
        if (title.length > 180) {
          title = title.substring(0, 177) + '...';
        }
        setItemID(firstItem.id);
        setTitle(title);
        setLink(firstItem.url);
      
        if (firstItem.document_link) {
          setDocLink(firstItem.document_link);
          setTextLink(firstItem.document_link);
        } else if (firstItem.download && firstItem.download.pdfLink) {
          setDocLink(firstItem.download.pdfLink);
          setTextLink(firstItem.download.pdfLink);
        } else {
          setTextLink(firstItem.url);
        }
      
        setDate(formatDate(firstItem.created_at));
      }
    
      // Save note function
      function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
        var searchItem = document.getElementById('noteModalFeedItem').value;
        var collectionCounter = localStorage.getItem('collectionNumber');
        collectionCounter++;
      
        var title = 'title'.concat(collectionCounter);
        var detailLink = 'detailLink'.concat(collectionCounter);
        var docLink = 'docLink'.concat(collectionCounter);
        var itemNote = 'itemNote'.concat(collectionCounter);
        var itemDate = 'itemDate'.concat(collectionCounter);
        var feedName = 'feedName'.concat(collectionCounter);
        var branchName = 'branchName'.concat(collectionCounter);
        var topicName = 'topicName'.concat(collectionCounter);
      
        localStorage.setItem(title, saveCollectionTitle);
        localStorage.setItem(detailLink, saveCollectionDetailLink);
        localStorage.setItem(docLink, saveCollectionDocLink);
        localStorage.setItem(itemNote, searchItem);
        localStorage.setItem(itemDate, saveCollectionDate);
        localStorage.setItem(feedName, saveCollectionFeedName);
        localStorage.setItem(branchName, saveCollectionBranchName);
        localStorage.setItem(topicName, saveCollectionTopicName);
        localStorage.setItem('collectionNumber', collectionCounter);
        window.location.reload();
      }
    
      function formatDate(inputDate) {
        const date = new Date(inputDate);
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const day = utcDate.getDate();
        const month = utcDate.getMonth() + 1;
        const year = utcDate.getFullYear();
        return `${month}-${day}-${year}`;
      }
    
      // Fetch and display data
      useEffect(() => {
        const fetchData = async () => {
          try {
            const cachedData = localStorage.getItem(feedName + 'cachedData');
    
            if (cachedData) {
              displayData(JSON.parse(cachedData));
            }
    
            const response = await fetch(`https://api.govglance.org/posts/recent?limit=1${apiURL}`, {
              method: 'GET',
              headers: { 
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + apiKey,
              },
            });
    
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
    
            if (!cachedData || JSON.stringify(data) !== cachedData) {
              localStorage.setItem(feedName + 'cachedData', JSON.stringify(data));
            }
    
            displayData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [apiURL]);
    
      // Open the feed page on button click
      const handleViewMoreClick = () => {
        const schema = new URLSearchParams(apiURL).get('schema');
        const table = new URLSearchParams(apiURL).get('table');
    
        history.push({
          pathname: `/feed/${schema}/${table}`,
          state: {
            apiURL: apiURL,
            feedName: feedName,
            feedBranch: feedBranch,
            feedTopic: feedTopic,
            feedButtonName: feedButtonName,
          },
        });
      };
    
      const handleDetailClick = (id) => {
        const schema = new URLSearchParams(apiURL).get('schema');
        const table = new URLSearchParams(apiURL).get('table');
    
        history.push({
          pathname: `/feed/${schema}/${table}/${id}`,
          state: {
            apiURL: apiURL,
            feedName: feedName,
            feedBranch: feedBranch,
            feedTopic: feedTopic,
            feedButtonName: feedButtonName,
          },
        });
      };
    
      return document.getElementById(divID.toString()) ? ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText' onClick={(e) => { e.preventDefault(); window.open(textLink, "_blank") }}>{title}</Card.Title>
          <Card.Body id='billBodyText'>{date}</Card.Body>
          {/* Passing the function correctly to the More button */}
          <Button id='billLink' onClick={() => handleDetailClick(itemID)}>
            <InfoCircle /> More
          </Button>
          {docLink && feedButtonName && (
            <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
              <FileText /> {feedButtonName}
            </Button>
          )}
          <Button id='addToCollectionButton' onClick={() => setCollectionModalIsOpen(true)}>
            <FolderPlus />
          </Button>
          <Button id='whDocsViewMoreButton' onClick={handleViewMoreClick}>
            <Files size={20} /> View More
          </Button>
        </Container>,
        document.getElementById(divID.toString())
      ) : null;
    }


    export function CreateFeedAPIWithPageTestBefore(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
      const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
      const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
      const [title, setTitle] = useState('');
      const [link, setLink] = useState('');
      const [docLink, setDocLink] = useState('');
      const [textLink, setTextLink] = useState('');
      const [date, setDate] = useState('');
    
      const history = useHistory(); // Using react-router-dom's useHistory hook
    
      

      function displayData(cachedData) {
        const firstItem = cachedData[0];
        let title = firstItem.status_title || firstItem.title;
      
        if (title.length > 180) {
          title = title.substring(0, 177) + '...';
        }
      
        setTitle(title);
        setLink(firstItem.url);
      
        if (firstItem.document_link) {
          setDocLink(firstItem.document_link);
          setTextLink(firstItem.document_link);
        } else if (firstItem.download && firstItem.download.pdfLink) {
          setDocLink(firstItem.download.pdfLink);
          setTextLink(firstItem.download.pdfLink);
        } else {
          setTextLink(firstItem.url);
        }
      
        setDate(formatDate(firstItem.created_at));
      }
      

      // Save note function
      function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
        var searchItem = document.getElementById('noteModalFeedItem').value;
        var collectionCounter = localStorage.getItem('collectionNumber');
        collectionCounter++;
    
        var title = 'title'.concat(collectionCounter);
        var detailLink = 'detailLink'.concat(collectionCounter);
        var docLink = 'docLink'.concat(collectionCounter);
        var itemNote = 'itemNote'.concat(collectionCounter);
        var itemDate = 'itemDate'.concat(collectionCounter);
        var feedName = 'feedName'.concat(collectionCounter);
        var branchName = 'branchName'.concat(collectionCounter);
        var topicName = 'topicName'.concat(collectionCounter);
    
        localStorage.setItem(title, saveCollectionTitle);
        localStorage.setItem(detailLink, saveCollectionDetailLink);
        localStorage.setItem(docLink, saveCollectionDocLink);
        localStorage.setItem(itemNote, searchItem);
        localStorage.setItem(itemDate, saveCollectionDate);
        localStorage.setItem(feedName, saveCollectionFeedName);
        localStorage.setItem(branchName, saveCollectionBranchName);
        localStorage.setItem(topicName, saveCollectionTopicName);
        localStorage.setItem('collectionNumber', collectionCounter);
        window.location.reload();
      }
    
      function formatDate(inputDate) {
        const date = new Date(inputDate);
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const day = utcDate.getDate();
        const month = utcDate.getMonth() + 1;
        const year = utcDate.getFullYear();
        return `${month}-${day}-${year}`;
      }
    
      // Fetch and display data
      useEffect(() => {
        const fetchData = async () => {
          try {
            const cachedData = localStorage.getItem(feedName + 'cachedData');
    
            if (cachedData) {
              displayData(JSON.parse(cachedData));
            }
    
            const response = await fetch(`https://api.govglance.org/posts/recent?limit=1${apiURL}`, {
              method: 'GET',
              headers: { 
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + apiKey,
              },
            });
    
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
    
            if (!cachedData || JSON.stringify(data) !== cachedData) {
              localStorage.setItem(feedName + 'cachedData', JSON.stringify(data));
            }
    
            displayData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [apiURL]);
    
      // Open the feed page on button click
      const handleViewMoreClick = () => {
        history.push({
          pathname: '/feed',
          state: {
            apiURL: apiURL,
            feedName: feedName,
            feedBranch: feedBranch,
            feedTopic: feedTopic,
            feedButtonName: feedButtonName,
          },
        });
      };
    
      return document.getElementById(divID.toString()) ? ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText' onClick={(e) => { e.preventDefault(); window.open(textLink, "_blank") }}>{title}</Card.Title>
          <Card.Body id='billBodyText'>{date}</Card.Body>
          <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
            <InfoCircle /> Details
          </Button>
          {docLink && feedButtonName && (
            <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
              <FileText /> {feedButtonName}
            </Button>
          )}
          <Button id='addToCollectionButton' onClick={() => setCollectionModalIsOpen(true)}>
            <FolderPlus />
          </Button>
          <Button id='whDocsViewMoreButton' onClick={handleViewMoreClick}>
            <Files size={20} /> View More
          </Button>
        </Container>,
        document.getElementById(divID.toString())
      ) : null;
      
    }
    

    export function CreateFeedAPIWithPage(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
      const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
      const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
      const [title, setTitle] = useState('');
      const [link, setLink] = useState('');
      const [docLink, setDocLink] = useState('');
      const [textLink, setTextLink] = useState('');
      const [date, setDate] = useState('');
        
      const history = useHistory(); // Using react-router-dom's useHistory hook
    
      const handleViewMoreClick = () => {
        history.push({
          pathname: '/feed',
          state: {
            apiURL: apiURL,
            feedName: feedName,
            feedBranch: feedBranch,
            feedTopic: feedTopic,
            feedButtonName: feedButtonName,
          },
        });
      };
      function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
        var searchItem = document.getElementById('noteModalFeedItem').value;
        var collectionCounter = localStorage.getItem('collectionNumber');
        collectionCounter++;
        
        var title = 'title'.concat(collectionCounter);
        var detailLink = 'detailLink'.concat(collectionCounter);
        var docLink = 'docLink'.concat(collectionCounter);
        var itemNote = 'itemNote'.concat(collectionCounter);
        var itemDate = 'itemDate'.concat(collectionCounter);
        var feedName = 'feedName'.concat(collectionCounter);
        var branchName = 'branchName'.concat(collectionCounter);
        var topicName = 'topicName'.concat(collectionCounter);
    
        localStorage.setItem(title, saveCollectionTitle);
        localStorage.setItem(detailLink, saveCollectionDetailLink);
        localStorage.setItem(docLink, saveCollectionDocLink);
        localStorage.setItem(itemNote, searchItem);
        localStorage.setItem(itemDate, saveCollectionDate);
        localStorage.setItem(feedName, saveCollectionFeedName);
        localStorage.setItem(branchName, saveCollectionBranchName);
        localStorage.setItem(topicName, saveCollectionTopicName);
        localStorage.setItem('collectionNumber', collectionCounter);
        window.location.reload();
      }
    
      function formatDate(inputDate) {
        const date = new Date(inputDate);
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const day = utcDate.getDate();
        const month = utcDate.getMonth() + 1;
        const year = utcDate.getFullYear();
        return `${month}-${day}-${year}`;
      }
  
  
    
      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + apiKey,
        },
      };
    
      const displayData = (cachedData) => {
        const firstItem = cachedData[0];
        let title = firstItem.status_title || firstItem.title;
        if (title.length > 180) {
          title = title.substring(0, 177) + '...';
        }
        setTitle(title);
        setLink(firstItem.url);
        if (firstItem.document_link) {
          setDocLink(firstItem.document_link);
          setTextLink(firstItem.document_link);
        } else if (firstItem.download && firstItem.download.pdfLink) {
          setDocLink(firstItem.download.pdfLink);
          setTextLink(firstItem.download.pdfLink);
        } else {
          setTextLink(firstItem.url);
        }
        setDate(formatDate(firstItem.created_at));
      };
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            const cachedData = localStorage.getItem(feedName + 'cachedData');
    
            if (cachedData) {
              displayData(JSON.parse(cachedData));
            }
    
            const response = await fetch(`https://api.govglance.org/posts/recent?limit=1${apiURL}`, requestOptions);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
    
            if (!cachedData || JSON.stringify(data) !== cachedData) {
              localStorage.setItem(feedName + 'cachedData', JSON.stringify(data));
            }
    
            displayData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [apiURL]);
    
      const containerElement = document.getElementById(divID.toString());
    
      if (!containerElement) {
        return null; // Or return a fallback UI, like a spinner or an error message
      }
    
      if (title.length === 0) {
        return <Spinner animation="grow" variant="light" />;
      }
    
      if (title === "Coming Soon") {
        return ReactDOM.createPortal(
          <Container id='feedContainerNormalView'>
            <Card.Title id='billText'>{title}</Card.Title>
            <br /><br /><br /><br /><br /><br />
          </Container>, containerElement
        );
      }
    
      const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
        <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
          <FileText /> {feedButtonName}
        </Button>
      ) : null;
    
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText' onClick={(e) => { e.preventDefault(); window.open(textLink, "_blank") }}>{title}</Card.Title>
          <Card.Body id='billBodyText'>{date}</Card.Body>
          <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
            <InfoCircle /> Details
          </Button>
          {viewBillButton}
          <Button id='addToCollectionButton' onClick={() => { setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic); }}>
            <FolderPlus />
          </Button>
          <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
            <Modal.Body id='descriptionModalBody'>
              {date}
              <div id='fullBill'>
                <Form id='noteModalForm'>
                  <Form.Group id='noteModalForm' className="mb-3">
                    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as={"textarea"} rows={2} />
                    <br />
                  </Form.Group>
                </Form>
                <Modal.Footer id='addNoteModalFooter'>
                  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => { saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false); }}>
                    Save To Collection
                  </Button>
                </Modal.Footer>
              </div>
            </Modal.Body>
          </Modal>
          <Button id='whDocsViewMoreButton' onClick={() => handleViewMoreClick()}>
            <Files size={20} /> View More
          </Button>
          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
            <FeedFunction name={feedName} url={apiURL} branch={feedBranch} topic={feedTopic} buttonName={feedButtonName} />
          </Modal>
        </Container>, containerElement
      );
    }

    // export function CreateFeedAPIState(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
    //   const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
    //   const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
    //   const [title, setTitle] = useState('');
    //   const [link, setLink] = useState('');
    //   const [docLink, setDocLink] = useState('');
    //   const [textLink, setTextLink] = useState('');
    //   const [date, setDate] = useState('');
    
    //   function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
    //     var searchItem = document.getElementById('noteModalFeedItem').value;
    //     var collectionCounter = localStorage.getItem('collectionNumber');
    //     collectionCounter++;
        
    //     var title = 'title'.concat(collectionCounter);
    //     var detailLink = 'detailLink'.concat(collectionCounter);
    //     var docLink = 'docLink'.concat(collectionCounter);
    //     var itemNote = 'itemNote'.concat(collectionCounter);
    //     var itemDate = 'itemDate'.concat(collectionCounter);
    //     var feedName = 'feedName'.concat(collectionCounter);
    //     var branchName = 'branchName'.concat(collectionCounter);
    //     var topicName = 'topicName'.concat(collectionCounter);
    
    //     localStorage.setItem(title, saveCollectionTitle);
    //     localStorage.setItem(detailLink, saveCollectionDetailLink);
    //     localStorage.setItem(docLink, saveCollectionDocLink);
    //     localStorage.setItem(itemNote, searchItem);
    //     localStorage.setItem(itemDate, saveCollectionDate);
    //     localStorage.setItem(feedName, saveCollectionFeedName);
    //     localStorage.setItem(branchName, saveCollectionBranchName);
    //     localStorage.setItem(topicName, saveCollectionTopicName);
    //     localStorage.setItem('collectionNumber', collectionCounter);
    //     window.location.reload();
    //   }
    
    //   function formatDate(inputDate) {
    //     const date = new Date(inputDate);
    //     const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    //     const day = utcDate.getDate();
    //     const month = utcDate.getMonth() + 1;
    //     const year = utcDate.getFullYear();
    //     return `${month}-${day}-${year}`;
    //   }
  
  
    
    //   const requestOptions = {
    //     method: 'GET',
    //     headers: { 
    //       'Content-Type': 'application/json',
    //       "Authorization": "Bearer " + apiKey,
    //     },
    //   };
    
    //   const displayData = (cachedData) => {
    //     const firstItem = cachedData[0];
    //     let title = firstItem.status_title || firstItem.title;
    //     if (title.length > 180) {
    //       title = title.substring(0, 177) + '...';
    //     }
    //     setTitle(title);
    //     setLink(firstItem.url);
    //     if (firstItem.document_url) {
    //       setDocLink(firstItem.document_url);
    //       setTextLink(firstItem.document_url);
    //     } else if (firstItem.download_link) {
    //       setDocLink(firstItem.download_link);
    //       setTextLink(firstItem.document_link);
    //     } else if (firstItem.document_link) {
    //       setDocLink(firstItem.document_link);
    //       setTextLink(firstItem.document_link);
    //     } else if (firstItem.download && firstItem.download.pdfLink) {
    //       setDocLink(firstItem.download.pdfLink);
    //       setTextLink(firstItem.download.pdfLink);
    //     } else {
    //       setTextLink(firstItem.url);
    //     }
    //     setDate(formatDate(firstItem.updated_at));
    //   };
    
    //   useEffect(() => {
    //     const fetchData = async () => {
    //       try {
    //         const cachedData = localStorage.getItem(feedName + 'cachedData');
    
    //         if (cachedData) {
    //           displayData(JSON.parse(cachedData));
    //         }
    
    //         const response = await fetch(`https://api.govglance.org/posts/recent?limit=1${apiURL}`, requestOptions);
    //         if (!response.ok) {
    //           throw new Error(`HTTP error! status: ${response.status}`);
    //         }
    //         const data = await response.json();
  
    //         if (!cachedData || JSON.stringify(data) !== cachedData) {
    //           localStorage.setItem(feedName + 'cachedData', JSON.stringify(data));
    //         }
            
    //         displayData(data);
    //       } catch (error) {
    //         console.error('Error fetching data:', error);
    //       }
    //     };
    
    //     fetchData();
    //   }, [apiURL]);
    
    //   const containerElement = document.getElementById(divID.toString());
    
    //   if (!containerElement) {
    //     return null; // Or return a fallback UI, like a spinner or an error message
    //   }
    
    //   if (title.length === 0) {
    //     return <Spinner animation="grow" variant="light" />;
    //   }
    
    //   if (title === "Coming Soon") {
    //     return ReactDOM.createPortal(
    //       <Container id='feedContainerNormalView'>
    //         <Card.Title id='billText'>{title}</Card.Title>
    //         <br /><br /><br /><br /><br /><br />
    //       </Container>, containerElement
    //     );
    //   }
    
    //   const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
    //     <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
    //       <FileText /> {feedButtonName}
    //     </Button>
    //   ) : null;
    
    //   return ReactDOM.createPortal(
    //     <Container id='feedContainerNormalView'>
    //       <Card.Title id='billText' onClick={(e) => { e.preventDefault(); window.open(textLink, "_blank") }}>{title}</Card.Title>
    //       <Card.Body id='billBodyText'>{date}</Card.Body>
    //       <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
    //         <InfoCircle /> Details
    //       </Button>
    //       {viewBillButton}
    //       <Button id='addToCollectionButton' onClick={() => { setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic); }}>
    //         <FolderPlus />
    //       </Button>
    //       <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
    //         <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    //         <Modal.Body id='descriptionModalBody'>
    //           {date}
    //           <div id='fullBill'>
    //             <Form id='noteModalForm'>
    //               <Form.Group id='noteModalForm' className="mb-3">
    //                 <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as={"textarea"} rows={2} />
    //                 <br />
    //               </Form.Group>
    //             </Form>
    //             <Modal.Footer id='addNoteModalFooter'>
    //               <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => { saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false); }}>
    //                 Save To Collection
    //               </Button>
    //             </Modal.Footer>
    //           </div>
    //         </Modal.Body>
    //       </Modal>
    //       <Button id='whDocsViewMoreButton' onClick={() => setFeedItemModalIsOpen(true)}>
    //         <Files size={20} /> View More
    //       </Button>
    //       <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
    //         <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
    //         <FeedFunction name={feedName} url={apiURL} branch={feedBranch} topic={feedTopic} buttonName={feedButtonName} />
    //       </Modal>
    //     </Container>, containerElement
    //   );
    // }
  
    export function CreateFeedAPIState(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
      const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
      const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
      const [title, setTitle] = useState('');
      const [link, setLink] = useState('');
      const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // Share Modal State
      const [linkCopied, setLinkCopied] = useState(false); // To track if the link is copied
      const [shareLink, setShareLink] = useState(''); // The link to share
      const [docLink, setDocLink] = useState('');
      const [textLink, setTextLink] = useState('');
      const [date, setDate] = useState('');
      const [itemID, setItemID] = useState('');
      const urlParams = new URLSearchParams(apiURL);
      const history = useHistory(); // Using react-router-dom's useHistory hook
  const schema = urlParams.get('schema');
  const table = urlParams.get('table');
      function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
        var searchItem = document.getElementById('noteModalFeedItem').value;
        var collectionCounter = localStorage.getItem('collectionNumber');
        collectionCounter++;
        
        var title = 'title'.concat(collectionCounter);
        var detailLink = 'detailLink'.concat(collectionCounter);
        var docLink = 'docLink'.concat(collectionCounter);
        var itemNote = 'itemNote'.concat(collectionCounter);
        var itemDate = 'itemDate'.concat(collectionCounter);
        var feedName = 'feedName'.concat(collectionCounter);
        var branchName = 'branchName'.concat(collectionCounter);
        var topicName = 'topicName'.concat(collectionCounter);
    
        localStorage.setItem(title, saveCollectionTitle);
        localStorage.setItem(detailLink, saveCollectionDetailLink);
        localStorage.setItem(docLink, saveCollectionDocLink);
        localStorage.setItem(itemNote, searchItem);
        localStorage.setItem(itemDate, saveCollectionDate);
        localStorage.setItem(feedName, saveCollectionFeedName);
        localStorage.setItem(branchName, saveCollectionBranchName);
        localStorage.setItem(topicName, saveCollectionTopicName);
        localStorage.setItem('collectionNumber', collectionCounter);
        window.location.reload();
      }

      const handleViewMoreClick = () => {
        history.push({
          pathname: `/feed/${schema}/${table}`,
          state: {
            apiURL,
            feedName,
            feedBranch,
            feedTopic,
            feedButtonName
          }
        });
      };
    
      function formatDate(inputDate) {
        const date = new Date(inputDate);
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const day = utcDate.getDate();
        const month = utcDate.getMonth() + 1;
        const year = utcDate.getFullYear();
        return `${month}-${day}-${year}`;
      }

      const handleShareModalOpen = (id) => {
        const baseUrl = 'https://govglance.org';
        const fullUrl = `${baseUrl}/feed/${schema}/${table}/${id}`;
    
        setShareLink(fullUrl);
        setShareModalIsOpen(true);
        setLinkCopied(false);
      };
    
    
    
      // Function to copy the share link
      const handleCopyLink = () => {
        navigator.clipboard.writeText(shareLink)
          .then(() => setLinkCopied(true))
          .catch((error) => console.error('Error copying link:', error));
      };
  

      const handleNativeShare = async () => {
        if (navigator.share) {
          try {
            await navigator.share({
              title: 'Check this out!',
              text: 'Here’s something interesting I found:',
              url: shareLink,
            });
            console.log('Content shared successfully!');
          } catch (error) {
            console.error('Error sharing:', error);
          }
        } else {
          alert('Sharing is not supported on this device.');
        }
      };
      
  
    
      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + apiKey,
        },
      };
    
      const displayData = (cachedData) => {
        const firstItem = cachedData[0];
        let title = firstItem.status_title || firstItem.title;
        if (title.length > 180) {
          title = title.substring(0, 177) + '...';
        }
        setTitle(title);
        setLink(firstItem.url);
        setItemID(firstItem.id)
        if (firstItem.document_url) {
          setDocLink(firstItem.document_url);
          setTextLink(firstItem.document_url);
        } else if (firstItem.download_link) {
          setDocLink(firstItem.download_link);
          setTextLink(firstItem.document_link);
        } else if (firstItem.document_link) {
          setDocLink(firstItem.document_link);
          setTextLink(firstItem.document_link);
        } else if (firstItem.download && firstItem.download.pdfLink) {
          setDocLink(firstItem.download.pdfLink);
          setTextLink(firstItem.download.pdfLink);
        } else {
          setTextLink(firstItem.url);
        }
        setDate(formatDate(firstItem.updated_at));
      };
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            const cachedData = localStorage.getItem(feedName + 'cachedData');
    
            if (cachedData) {
              displayData(JSON.parse(cachedData));
            }
    
            const response = await fetch(`https://api.govglance.org/posts/recent?limit=1${apiURL}`, requestOptions);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
  
            if (!cachedData || JSON.stringify(data) !== cachedData) {
              localStorage.setItem(feedName + 'cachedData', JSON.stringify(data));
            }
            
            displayData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [apiURL]);
    
      const containerElement = document.getElementById(divID.toString());
    
      if (!containerElement) {
        return null; // Or return a fallback UI, like a spinner or an error message
      }
    
      if (title.length === 0) {
        return <Spinner animation="grow" variant="light" />;
      }
    
      if (title === "Coming Soon") {
        return ReactDOM.createPortal(
          <Container id='feedContainerNormalView'>
            <Card.Title id='billText'>{title}</Card.Title>
            <br /><br /><br /><br /><br /><br />
          </Container>, containerElement
        );
      }
    
      const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
        <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
          <FileText /> {feedButtonName}
        </Button>
      ) : null;
    
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText' onClick={(e) => { e.preventDefault(); window.open(textLink, "_blank") }}>{title}</Card.Title>
          <Card.Body id='billBodyText'>{date}</Card.Body>
          <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
            <InfoCircle/> Details
          </Button>
          {viewBillButton}
          <Button id='addToCollectionButton' onClick={() => { setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic); }}>
            <FolderPlus />
          </Button>

          <Button id="shareButton" onClick={() => handleShareModalOpen(itemID)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
          <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
        </svg>
      </Button>
          <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
            <Modal.Body id='descriptionModalBody'>
              {date}
              <div id='fullBill'>
                <Form id='noteModalForm'>
                  <Form.Group id='noteModalForm' className="mb-3">
                    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as={"textarea"} rows={2} />
                    <br />
                  </Form.Group>
                </Form>
                <Modal.Footer id='addNoteModalFooter'>
                  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => { saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false); }}>
                    Save To Collection
                  </Button>
                </Modal.Footer>
              </div>
            </Modal.Body>
          </Modal>
          <Button id='whDocsViewMoreButton' onClick={handleViewMoreClick}>
            <Files size={20} /> View More
          </Button>
          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
            <FeedFunction name={feedName} url={apiURL} branch={feedBranch} topic={feedTopic} buttonName={feedButtonName} />
          </Modal>

          <Modal id="fullBillModal" show={shareModalIsOpen} size="xl" centered onHide={() => setShareModalIsOpen(false)}>
        <Modal.Header id="billModalHeader" closeButton>
          <h3>Share</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          <Form.Group id="noteModalForm">
            <Form.Control id="noteModalFeedItem" type="text" readOnly value={shareLink} />
          </Form.Group>
          <Modal.Footer id="shareFooter">
          <Button 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>

            <Button id="addToCollectionButton" onClick={handleCopyLink}>
              {linkCopied ? <ClipboardCheck /> : <Clipboard />}
            </Button>


          </Modal.Footer>
        </Modal.Body>
      </Modal>
        </Container>, containerElement
      );
    }

    export function CreateFeedAPICommittee(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
      const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
      const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
      const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [docLink, setDocLink] = useState('');
    const [textLink, setTextLink] = useState('');
    const [date, setDate] = useState('');
   
  
  
      function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
        
        var searchItem = document.getElementById('noteModalFeedItem').value
      
         collectionCounter = localStorage.getItem('collectionNumber')
         collectionCounter++;
     
         
         var collectionTitles = []
          var collectionDetailLinks = []
         var collectionDocLinks = []
         var collectionNote = []
         
   
           var title = 'title'.concat(collectionCounter)
           var detailLink = 'detailLink'.concat(collectionCounter)
           var docLink = 'docLink'.concat(collectionCounter)
           var itemNote = 'itemNote'.concat(collectionCounter)
           var itemDate = 'itemDate'.concat(collectionCounter)
           var feedName = 'feedName'.concat(collectionCounter)
           var branchName = 'branchName'.concat(collectionCounter)
           var topicName = 'topicName'.concat(collectionCounter)
          var itemDescription = 'itemDescription'.concat(collectionCounter)
       
  
           localStorage.setItem(title,saveCollectionTitle)
           localStorage.setItem(detailLink,saveCollectionDetailLink)
           localStorage.setItem(docLink,saveCollectionDocLink)
           localStorage.setItem(itemNote,searchItem)
           localStorage.setItem(itemDate,saveCollectionDate)
           localStorage.setItem(feedName,saveCollectionFeedName)
           localStorage.setItem(branchName,saveCollectionBranchName)
           localStorage.setItem(topicName,saveCollectionTopicName)
           localStorage.setItem('collectionNumber', collectionCounter)
           window.location.reload()
      }
  
     
  
      function formatDate(inputDate) {
        const date = new Date(inputDate);
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const day = utcDate.getDate();
        const month = utcDate.getMonth() + 1;
        const year = utcDate.getFullYear();
      
        return `${month}-${day}-${year}`;
      }
  
      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json',
          "Authorization": "Bearer "+apiKey,
      },
      };

        
      const displayData = (cachedData) => {
        const firstItem = cachedData[0];
        let title = firstItem.status_title || firstItem.title;
        if (title.length > 180) {
            title = title.substring(0, 177) + '...';
        }
        setTitle(title);
        setLink(firstItem.url);
     
        if (firstItem.document_link) {
            setDocLink(firstItem.document_link);
            setTextLink(firstItem.document_link);
        } else if (firstItem.download && firstItem.download.pdfLink) {
            setDocLink(firstItem.download.pdfLink);
            setTextLink(firstItem.download.pdfLink);
        } else {
          setTextLink(firstItem.url)
        }
        setDate(formatDate(firstItem.created_at));
    };


    useEffect(() => {
      const fetchData = async () => {
          try {
              const cachedData = localStorage.getItem(feedName + 'cachedData');
  
              // Display cached data if available
              if (cachedData) {
                  displayData(JSON.parse(cachedData));
              }
                                            // &skip=0&committee=Committee%20on%20Agriculture&chamber=HOUSE
              const response = await fetch(`https://api.govglance.org/committees/?limit=1${apiURL}`, requestOptions);
            
              if (!response.ok) {
                  throw new Error(`HTTP error! status: ${response.status}`);
              }
              const data = await response.json();
             
              // Update cache with fetched data if no existing cached data or fetched data differs from cached data
              if (!cachedData || JSON.stringify(data) !== cachedData) {
                  localStorage.setItem(feedName + 'cachedData', JSON.stringify(data));
              }
             
              // Display the fetched data
              displayData(data);
          } catch (error) {
              console.error('Error fetching data:', error);
          }
      };
  
      fetchData();
  }, [apiURL]);
    
    
    
      if (title.length === 0) {
        return <Spinner animation="grow" variant="light" />
      }
  
      if (title === "Coming Soon") {
        return ReactDOM.createPortal(
          <Container id='feedContainerNormalView'>
            <Card.Title id='billText'>{title}</Card.Title>
            <br></br><br></br><br></br>
            <br></br><br></br><br></br>
          </Container>,document.getElementById(divID.toString())
        );
      }
     
      const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
        <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink); }}>
          <FileText></FileText> {feedButtonName}
        </Button>
      ) : null;
  
    
    
    
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText' onClick={(e) => { e.preventDefault(); window.open(textLink, "_blank") }}>{title}</Card.Title>
          <Card.Body id='billBodyText'>{date}</Card.Body>
          <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
            <InfoCircle /> Details
          </Button>
    
          {viewBillButton}
       
          <Button id='addToCollectionButton' onClick={() =>     {setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic)
  } }><FolderPlus></FolderPlus></Button>
  
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
                       <Modal.Body id='descriptionModalBody'>
                                   
                                   {date}
  
                       <div id='fullBill'>
  
                  <Form id='noteModalForm'>
  
  <Form.Group id='noteModalForm'  className="mb-3" >
  
    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
      type="text"
      as={"textarea"}
      rows={2}
      
      />
  
      <br></br>
  </Form.Group>
  </Form>
  
  <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic);setCollectionModalIsOpen(false) }} >
  Save To Collection
  </Button>
  </Modal.Footer> 
  
    </div>
    </Modal.Body>
                     </Modal>
          
          <Button  id='whDocsViewMoreButton'  onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
                          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
                            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
                            <FeedFunction name={feedName} url={apiURL} branch={feedBranch} topic={feedTopic} buttonName={feedButtonName}/>
                          </Modal>
    
        </Container>,
        document.getElementById(divID.toString())
      );
    }



    export function CreateFeedAPIElection(apiURL, divID, feedName, feedBranch, feedTopic, feedButtonName, FeedFunction) {
      const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
      const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
      const [title, setTitle] = useState('');
      const [link, setLink] = useState('');
      const [docLink, setDocLink] = useState('');
      const [textLink, setTextLink] = useState('');
      const [date, setDate] = useState('');
    
      function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
        var searchItem = document.getElementById('noteModalFeedItem').value;
        var collectionCounter = localStorage.getItem('collectionNumber');
        collectionCounter++;
        
        var title = 'title'.concat(collectionCounter);
        var detailLink = 'detailLink'.concat(collectionCounter);
        var docLink = 'docLink'.concat(collectionCounter);
        var itemNote = 'itemNote'.concat(collectionCounter);
        var itemDate = 'itemDate'.concat(collectionCounter);
        var feedName = 'feedName'.concat(collectionCounter);
        var branchName = 'branchName'.concat(collectionCounter);
        var topicName = 'topicName'.concat(collectionCounter);
    
        localStorage.setItem(title, saveCollectionTitle);
        localStorage.setItem(detailLink, saveCollectionDetailLink);
        localStorage.setItem(docLink, saveCollectionDocLink);
        localStorage.setItem(itemNote, searchItem);
        localStorage.setItem(itemDate, saveCollectionDate);
        localStorage.setItem(feedName, saveCollectionFeedName);
        localStorage.setItem(branchName, saveCollectionBranchName);
        localStorage.setItem(topicName, saveCollectionTopicName);
        localStorage.setItem('collectionNumber', collectionCounter);
        window.location.reload();
      }
    
      function formatDate(inputDate) {
        const date = new Date(inputDate);
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const day = utcDate.getDate();
        const month = utcDate.getMonth() + 1;
        const year = utcDate.getFullYear();
        return `${month}-${day}-${year}`;
      }
  
  
    
      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + apiKey,
        },
      };
    
      const displayData = (cachedData) => {
        const firstItem = cachedData[0];
        let title = firstItem.status_title || firstItem.title;
        if (title.length > 180) {
          title = title.substring(0, 177) + '...';
        }
        setTitle(title);
        setLink(firstItem.url);
        if (firstItem.document_url) {
          setDocLink(firstItem.document_url);
          setTextLink(firstItem.document_url);
        } else if (firstItem.download_link) {
          setDocLink(firstItem.download_link);
          setTextLink(firstItem.document_link);
        } else if (firstItem.document_link) {
          setDocLink(firstItem.document_link);
          setTextLink(firstItem.document_link);
        } else if (firstItem.download && firstItem.download.pdfLink) {
          setDocLink(firstItem.download.pdfLink);
          setTextLink(firstItem.download.pdfLink);
        }
          else if (firstItem.pdf_url) {
            setDocLink(firstItem.pdf_url);
            setTextLink(firstItem.pdf_url);
        } else {
          setTextLink(firstItem.url);
        }
        setDate(firstItem.year);
      };
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            const cachedData = localStorage.getItem(feedName + 'cachedData');
    
            if (cachedData) {
              displayData(JSON.parse(cachedData));
            }
    
            const response = await fetch(`https://api.govglance.org/posts/recent?limit=1${apiURL}`, requestOptions);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
  
            if (!cachedData || JSON.stringify(data) !== cachedData) {
              localStorage.setItem(feedName + 'cachedData', JSON.stringify(data));
            }
            
            displayData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [apiURL]);
    
      const containerElement = document.getElementById(divID.toString());
    
      if (!containerElement) {
        return null; // Or return a fallback UI, like a spinner or an error message
      }
    
      if (title.length === 0) {
        return <Spinner animation="grow" variant="light" />;
      }
    
      if (title === "Coming Soon") {
        return ReactDOM.createPortal(
          <Container id='feedContainerNormalView'>
            <Card.Title id='billText'>{title}</Card.Title>
            <br /><br /><br /><br /><br /><br />
          </Container>, containerElement
        );
      }
    
      const viewBillButton = docLink && feedButtonName !== undefined && feedButtonName !== '' ? (
        <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
          <FileText /> {feedButtonName}
        </Button>
      ) : null;
    
      return ReactDOM.createPortal(
        <Container id='feedContainerNormalView'>
          <Card.Title id='billText' onClick={(e) => { e.preventDefault(); window.open(textLink, "_blank") }}>{title}</Card.Title>
          <Card.Body id='billBodyText'>{date}</Card.Body>
          <Button id='billLink' onClick={(e) => { e.preventDefault(); window.open(link, "_blank") }}>
          <FileText /> Results
          </Button>
          {viewBillButton}
          <Button id='addToCollectionButton' onClick={() => { setCollectionModalIsOpen(true); DisplaySaveToCollectionModal(title, link, docLink, date, '', feedName, feedBranch, feedTopic); }}>
            <FolderPlus />
          </Button>
          <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
            <Modal.Body id='descriptionModalBody'>
              {date}
              <div id='fullBill'>
                <Form id='noteModalForm'>
                  <Form.Group id='noteModalForm' className="mb-3">
                    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as={"textarea"} rows={2} />
                    <br />
                  </Form.Group>
                </Form>
                <Modal.Footer id='addNoteModalFooter'>
                  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => { saveNote(title, link, docLink, date, feedName, feedBranch, feedTopic); setCollectionModalIsOpen(false); }}>
                    Save To Collection
                  </Button>
                </Modal.Footer>
              </div>
            </Modal.Body>
          </Modal>
          <Button id='whDocsViewMoreButton' onClick={() => setFeedItemModalIsOpen(true)}>
            <Files size={20} /> View More
          </Button>
          <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
            <GenerateFeedElection name={feedName} url={apiURL} branch={feedBranch} topic={feedTopic} buttonName={feedButtonName} />
          </Modal>
        </Container>, containerElement
      );
    }

    export function CreateFeedAPIRegulations(
      apiURL,
      divID,
      feedName,
      feedBranch,
      feedTopic,
      feedButtonName,
      FeedFunction
    ) {
      const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
      const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
      const [shareModalIsOpen, setShareModalIsOpen] = useState(false);
      const [linkCopied, setLinkCopied] = useState(false);
      const [shareLink, setShareLink] = useState('');
      const [title, setTitle] = useState('');
      const [link, setLink] = useState('');
      const [docLink, setDocLink] = useState('');
      const [date, setDate] = useState('');
      const [itemID, setItemID] = useState('');
      const history = useHistory();
    
      const urlParams = new URLSearchParams(apiURL);
      const schema = urlParams.get('schema');
      const table = urlParams.get('table');
      const filterColumn = urlParams.get('filter_column'); // New filter param
      const filterString = urlParams.get('filter_string'); // New filter param
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(
              `https://api.govglance.org/posts/recent?limit=1${apiURL}`, 
              {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + process.env.REACT_APP_API_KEY,
                },
              }
            );
    
            if (!response.ok) throw new Error(`HTTP error: ${response.status}`);
    
            const data = await response.json();
            displayData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [apiURL]);
    
      const displayData = (data) => {
        const firstItem = data[0];
        setItemID(firstItem.id);
        setTitle(firstItem.status_title || firstItem.title.slice(0, 177) + '...');
        setLink(firstItem.url || '');
        setDocLink(firstItem.document_link || firstItem.download?.pdfLink || '');
        setDate(formatDate(firstItem.created_at));
      };
    
      const handleViewMoreClick = () => {
        history.push({
          pathname: `/feed/${schema}/${table}`,
          state: {
            apiURL,
            feedName,
            feedBranch,
            feedTopic,
            feedButtonName,
          },
        });
      };
    
      const formatDate = (inputDate) => {
        const date = new Date(inputDate);
        return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
      };
    
      if (!document.getElementById(divID.toString())) return null;
    
      return ReactDOM.createPortal(
        <Container id="feedContainerNormalView">
          <Card.Title
            id="billText"
            onClick={() => history.push(`/feed/${schema}/${table}/${itemID}`)}
          >
            {title}
          </Card.Title>
          <Card.Body id="billBodyText">{date}</Card.Body>
          <Button id="billLink" onClick={(e) => { e.preventDefault(); window.open(link, "_blank"); }}>
        <InfoCircle /> Details
      </Button>

          {docLink && (
            <Button
              id="viewBillButton"
              onClick={() => window.open(docLink, '_blank')}
            >
              <FileText /> {feedButtonName}
            </Button>
          )}
                {/* View More Button */}
      <Button id='whDocsViewMoreButton' onClick={handleViewMoreClick}>
      <Files size={20} />   View More
      </Button>
        </Container>,
        document.getElementById(divID.toString())
      );
    }