import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Label,
  Legend,
} from 'recharts';
import DesktopNavbar from './DesktopNavbar';

// Formatter for numbers with commas
const numberFormatter = new Intl.NumberFormat();

// Mapping of month abbreviations to numbers
const monthAbbrevToNumber = {
  JAN: 1,
  FEB: 2,
  MAR: 3,
  APR: 4,
  MAY: 5,
  JUN: 6,
  JUL: 7,
  AUG: 8,
  SEP: 9,
  OCT: 10,
  NOV: 11,
  DEC: 12,
};

// Colors for Pie Chart slices
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF6384', '#36A2EB', '#9966FF', '#FF9F40'];

const USImigrationEncounters = () => {
  const [data, setData] = useState([]);
  const [availableYears, setAvailableYears] = useState([]);
  const [availableMonths, setAvailableMonths] = useState([]);
  const [availableRegions, setAvailableRegions] = useState([]);
  const [availableDemographics, setAvailableDemographics] = useState([]);
  const [availableCitizenships, setAvailableCitizenships] = useState([]);
  const [availableTitles, setAvailableTitles] = useState([]);

  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedDemographic, setSelectedDemographic] = useState('');
  const [selectedCitizenship, setSelectedCitizenship] = useState('');
  const [selectedTitle, setSelectedTitle] = useState('');

  const [chartData, setChartData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [totalEncounters, setTotalEncounters] = useState(0);

  // New state for chart type
  const [chartType, setChartType] = useState('line'); // Default to 'line'; options: 'bar', 'line', 'pie'

  const styles = {
	container: {
	  
	  color: '#ffffff', // White text
	  minHeight: '100vh',
	  padding: '2rem',
	  boxSizing: 'border-box',
	  fontFamily: 'Arial, sans-serif',
	},
	heading: {
	  textAlign: 'center',
	  marginBottom: '2rem',
	  fontSize: '2rem',
	},
	filters: {
	  display: 'flex',
	  flexWrap: 'wrap',
	  gap: '1rem',
	  marginBottom: '1rem',
	},
	filterItem: {
	  display: 'flex',
	  flexDirection: 'column',
	  minWidth: '150px',
	},
	select: {
	  padding: '0.5rem',
	  borderRadius: '4px',

	  backgroundColor: '#002031',
	  color: '#ffffff',
	  appearance: 'none',
	  cursor: 'pointer',
	},
	label: {
	  marginBottom: '0.5rem',
	  fontWeight: 'bold',
	},
	totalEncounters: {
	  marginTop: '1rem',
	  fontSize: '1.2rem',
	  textAlign: 'center',
	},
	chartTypeSelector: {
	  display: 'flex',
	  justifyContent: 'center',
	  alignItems: 'center',
	  gap: '1rem',
	  marginBottom: '1.5rem',
	},
	radioLabel: {
	  display: 'flex',
	  alignItems: 'center',
	  cursor: 'pointer',
	  fontWeight: 'bold',
	},
	radioInput: {
	  marginRight: '0.5rem',
	  cursor: 'pointer',
	},
	resetButton: {
	  padding: '0.5rem 1rem',
	  borderRadius: '4px',
	  border: 'none',
	  backgroundColor: '#FF4136', // Red color
	  color: '#ffffff',
	  cursor: 'pointer',
	  fontWeight: 'bold',
	  marginTop: '1rem',
	  display: 'block',
	  marginLeft: 'auto',
	  marginRight: 'auto',
	},
  };

  useEffect(() => {
	// Path to your CSV file in the public folder
	const csvFilePath = `/datasets/nationwide-encounters-fy21-fy24-aug-state.csv`;

	// Fetch the CSV file
	fetch(csvFilePath)
		  .then((response) => {
			if (!response.ok) {
			  throw new Error(`HTTP error! status: ${response.status}`);
			}
			return response.text();
		  })
		  .then((csvData) => {
			// Parse the CSV data
			Papa.parse(csvData, {
			  header: true,
			  skipEmptyLines: true,
			  complete: function (results) {
				const parsedData = results.data;
	
				// Extract available options for filters
				const years = Array.from(new Set(parsedData.map((item) => item['Fiscal Year']))).sort();
				const months = Array.from(new Set(parsedData.map((item) => item['Month (abbv)']))).sort(
				  (a, b) => monthAbbrevToNumber[a] - monthAbbrevToNumber[b]
				);
				const regions = Array.from(new Set(parsedData.map((item) => item['Land Border Region']))).sort();
				const demographics = Array.from(new Set(parsedData.map((item) => item['Demographic']))).sort();
				const citizenships = Array.from(new Set(parsedData.map((item) => item['Citizenship']))).sort();
				const titles = Array.from(new Set(parsedData.map((item) => item['Title of Authority']))).sort();
	
				setAvailableYears(years);
				setAvailableMonths(months);
				setAvailableRegions(regions);
				setAvailableDemographics(demographics);
				setAvailableCitizenships(citizenships);
				setAvailableTitles(titles);
	
				// Set default selected values to 'All' (empty string)
				setSelectedYear('');
				setSelectedMonth('');
				setSelectedRegion('');
				setSelectedDemographic('');
				setSelectedCitizenship('');
				setSelectedTitle('');
	
				setData(parsedData);
			  },
			  error: function (error) {
				console.error('Error parsing CSV:', error);
			  },
			});
		  })
		  .catch((error) => {
			console.error('Error fetching the CSV file:', error);
		  });
	  }, []);
	
	  useEffect(() => {
		if (data.length > 0) {
		  // Filter data based on the selected filters
		  const filteredData = data.filter((item) => {
			return (
			  (selectedYear ? item['Fiscal Year'] === selectedYear : true) &&
			  (selectedMonth ? item['Month (abbv)'] === selectedMonth : true) &&
			  (selectedRegion ? item['Land Border Region'] === selectedRegion : true) &&
			  (selectedDemographic ? item['Demographic'] === selectedDemographic : true) &&
			  (selectedCitizenship ? item['Citizenship'] === selectedCitizenship : true) &&
			  (selectedTitle ? item['Title of Authority'] === selectedTitle : true)
			);
		  });
	
		  // Aggregate data for Bar Chart (by State)
		  const aggregatedData = filteredData.reduce((acc, curr) => {
			const state = curr.State;
			const encounterCount = parseInt(curr['Encounter Count'], 10) || 0;
			if (acc[state]) {
			  acc[state] += encounterCount;
			} else {
			  acc[state] = encounterCount;
			}
			return acc;
		  }, {});
	
		  // Convert aggregated data into an array for the Bar Chart
		  const chartDataArray = Object.keys(aggregatedData).map((state) => ({
			state,
			encounters: aggregatedData[state],
		  }));
	
		  setChartData(chartDataArray);
	
		  // Aggregate data for Line Chart (by Date)
		  const dateAggregatedData = {};
	
		  filteredData.forEach((curr) => {
			const fiscalYear = parseInt(curr['Fiscal Year'], 10);
			const monthAbbrev = curr['Month (abbv)'];
			const monthNumber = monthAbbrevToNumber[monthAbbrev];
	
			let calendarYear;
			if (monthNumber >= 10) {
			  calendarYear = fiscalYear - 1;
			} else {
			  calendarYear = fiscalYear;
			}
	
			// Format the month number with leading zero
			const monthString = monthNumber.toString().padStart(2, '0');
	
			// Create a date string in 'YYYY-MM' format
			const dateString = `${calendarYear}-${monthString}`;
	
			const encounterCount = parseInt(curr['Encounter Count'], 10) || 0;
	
			if (dateAggregatedData[dateString]) {
			  dateAggregatedData[dateString] += encounterCount;
			} else {
			  dateAggregatedData[dateString] = encounterCount;
			}
		  });
	
		  // Convert dateAggregatedData into an array and sort by date
		  const lineChartDataArray = Object.keys(dateAggregatedData)
			.map((date) => ({
			  date,
			  encounters: dateAggregatedData[date],
			}))
			.sort((a, b) => new Date(a.date) - new Date(b.date));
	
		  setLineChartData(lineChartDataArray);
	
		  // Aggregate data for Pie Chart (by State)
		  const pieAggregatedData = filteredData.reduce((acc, curr) => {
			const state = curr.State;
			const encounterCount = parseInt(curr['Encounter Count'], 10) || 0;
			if (acc[state]) {
			  acc[state] += encounterCount;
			} else {
			  acc[state] = encounterCount;
			}
			return acc;
		  }, {});
	
		  // Convert pieAggregatedData into an array
		  const pieChartDataArray = Object.keys(pieAggregatedData).map((state) => ({
			name: state,
			value: pieAggregatedData[state],
		  }));
	
		  setPieChartData(pieChartDataArray);
	
		  // Calculate total encounters
		  const totalEncounters = filteredData.reduce((sum, curr) => {
			const encounterCount = parseInt(curr['Encounter Count'], 10) || 0;
			return sum + encounterCount;
		  }, 0);
	
		  setTotalEncounters(totalEncounters);
		}
	  }, [
		data,
		selectedYear,
		selectedMonth,
		selectedRegion,
		selectedDemographic,
		selectedCitizenship,
		selectedTitle,
	  ]);
	
	  // Handlers for filter changes
	  const handleYearChange = (event) => {
		setSelectedYear(event.target.value);
	  };
	
	  const handleMonthChange = (event) => {
		setSelectedMonth(event.target.value);
	  };
	
	  const handleRegionChange = (event) => {
		setSelectedRegion(event.target.value);
	  };
	
	  const handleDemographicChange = (event) => {
		setSelectedDemographic(event.target.value);
	  };
	
	  const handleCitizenshipChange = (event) => {
		setSelectedCitizenship(event.target.value);
	  };
	
	  const handleTitleChange = (event) => {
		setSelectedTitle(event.target.value);
	  };
	
	  // Handler for chart type change
	  const handleChartTypeChange = (event) => {
		setChartType(event.target.value);
	  };
	
	  // Handler to reset all filters
	  const handleResetFilters = () => {
		setSelectedYear('');
		setSelectedMonth('');
		setSelectedRegion('');
		setSelectedDemographic('');
		setSelectedCitizenship('');
		setSelectedTitle('');
	  };
	
	  return (
		<div id='homebg' style={styles.container}>
            {/* <DesktopNavbar/> */}
		  <h2 style={styles.heading}>U.S. Border Encounters</h2>
		  {/* Chart Type Selector */}
		  <div style={styles.chartTypeSelector}>
			<label style={styles.radioLabel}>
			  <input
				type="radio"
				value="bar"
				checked={chartType === 'bar'}
				onChange={handleChartTypeChange}
				style={styles.radioInput}
			  />
			  Bar Chart (by state)
			</label>
			<label style={styles.radioLabel}>
			  <input
				type="radio"
				value="line"
				checked={chartType === 'line'}
				onChange={handleChartTypeChange}
				style={styles.radioInput}
			  />
			  Line Chart
			</label>
			<label style={styles.radioLabel}>
			  <input
				type="radio"
				value="pie"
				checked={chartType === 'pie'}
				onChange={handleChartTypeChange}
				style={styles.radioInput}
			  />
			  Pie Chart
			</label>
		  </div>
		  {/* Filters */}
		  <div style={styles.filters}>
			{/* Year Filter */}
			<div style={styles.filterItem}>
			  <label htmlFor="yearSelect" style={styles.label}>
				Year:
			  </label>
			  <select
				id="yearSelect"
				value={selectedYear}
				onChange={handleYearChange}
				style={styles.select}
				aria-label="Select Year"
			  >
				<option value="">All</option>
				{availableYears.map((year) => (
				  <option key={year} value={year}>
					{year}
				  </option>
				))}
			  </select>
			</div>
			{/* Month Filter */}
			<div style={styles.filterItem}>
			  <label htmlFor="monthSelect" style={styles.label}>
				Month:
			  </label>
			  <select
				id="monthSelect"
				value={selectedMonth}
				onChange={handleMonthChange}
				style={styles.select}
				aria-label="Select Month"
			  >
				<option value="">All</option>
				{availableMonths.map((month) => (
				  <option key={month} value={month}>
					{month}
				  </option>
				))}
			  </select>
			</div>
			{/* Region Filter */}
			<div style={styles.filterItem}>
			  <label htmlFor="regionSelect" style={styles.label}>
				Region:
			  </label>
			  <select
				id="regionSelect"
				value={selectedRegion}
				onChange={handleRegionChange}
				style={styles.select}
				aria-label="Select Region"
			  >
				<option value="">All</option>
				{availableRegions.map((region) => (
				  <option key={region} value={region}>
					{region}
				  </option>
				))}
			  </select>
			</div>
			{/* Demographic Filter */}
			<div style={styles.filterItem}>
			  <label htmlFor="demographicSelect" style={styles.label}>
				Demographic:
			  </label>
			  <select
				id="demographicSelect"
				value={selectedDemographic}
				onChange={handleDemographicChange}
				style={styles.select}
				aria-label="Select Demographic"
			  >
				<option value="">All</option>
				{availableDemographics.map((demographic) => (
				  <option key={demographic} value={demographic}>
					{demographic}
				  </option>
				))}
			  </select>
			</div>
			{/* Citizenship Filter */}
			<div style={styles.filterItem}>
			  <label htmlFor="citizenshipSelect" style={styles.label}>
				Citizenship:
			  </label>
			  <select
				id="citizenshipSelect"
				value={selectedCitizenship}
				onChange={handleCitizenshipChange}
				style={styles.select}
				aria-label="Select Citizenship"
			  >
				<option value="">All</option>
				{availableCitizenships.map((citizenship) => (
				  <option key={citizenship} value={citizenship}>
					{citizenship}
				  </option>
				))}
			  </select>
			</div>
			{/* Title of Authority Filter */}
			<div style={styles.filterItem}>
			  <label htmlFor="titleSelect" style={styles.label}>
				Title of Authority:
			  </label>
			  <select
				id="titleSelect"
				value={selectedTitle}
				onChange={handleTitleChange}
				style={styles.select}
				aria-label="Select Title of Authority"
			  >
				<option value="">All</option>
				{availableTitles.map((title) => (
				  <option key={title} value={title}>
					{title}
				  </option>
				))}
			  </select>
			</div>
		  </div>
		  {/* Reset Filters Button */}
		  {/* <button style={styles.resetButton} onClick={handleResetFilters}>
			Reset Filters
		  </button> */}
		  {/* Total Encounters */}
		  <div style={styles.totalEncounters}>
			<strong>Total Encounter Count: {numberFormatter.format(totalEncounters)}</strong>
		  </div>
		  {/* Chart */}
		  {chartType === 'bar' && chartData.length > 0 ? (
			<ResponsiveContainer width="100%" height={400}>
			  <BarChart data={chartData} margin={{ top: 20, right: 30, bottom: 40, left: 20 }}>
				<XAxis dataKey="state" stroke="#ffffff">
				  <Label value="State" offset={-10} position="insideBottom" fill="#ffffff" />
				</XAxis>
				<YAxis
				  stroke="#ffffff"
				  tickFormatter={(value) => numberFormatter.format(value)}
				  label={{
					value: 'Encounter Count',
					angle: -90,
					position: 'insideLeft',
					fill: '#ffffff',
				  }}
				/>
				<Tooltip
				  formatter={(value) => numberFormatter.format(value)}
				  contentStyle={{
					backgroundColor: '#001f3f',
					border: '1px solid #ffffff',
					color: '#ffffff',
				  }}
				/>
				<Legend verticalAlign="top" height={36} />
				<Bar dataKey="encounters" fill="#7FDBFF" name="Encounters" />
			  </BarChart>
			</ResponsiveContainer>
		  ) : chartType === 'line' && lineChartData.length > 0 ? (
			<ResponsiveContainer width="100%" height={400}>
			  <LineChart data={lineChartData} margin={{ top: 20, right: 30, bottom: 40, left: 20 }}>
				<XAxis dataKey="date" stroke="#ffffff">
				  <Label value="Date" offset={-10} position="insideBottom" fill="#ffffff" />
				</XAxis>
				<YAxis
				  stroke="#ffffff"
				  tickFormatter={(value) => numberFormatter.format(value)}
				  label={{
					value: 'Encounter Count',
					angle: -90,
					position: 'insideLeft',
					fill: '#ffffff',
				  }}
				/>
				<Tooltip
				  formatter={(value) => numberFormatter.format(value)}
				  labelFormatter={(label) => `Date: ${label}`}
				  contentStyle={{
					backgroundColor: '#001f3f',
					border: '1px solid #ffffff',
					color: '#ffffff',
				  }}
				/>
				<Legend verticalAlign="top" height={36} />
				<Line
				  type="monotone"
				  dataKey="encounters"
				  stroke="#7FDBFF"
				  strokeWidth={2}
				  name="Encounters"
				  dot={{ r: 3 }}
				  activeDot={{ r: 5 }}
				/>
			  </LineChart>
			</ResponsiveContainer>
		  ) : chartType === 'pie' && pieChartData.length > 0 ? (
			<ResponsiveContainer width="100%" height={400}>
			  <PieChart>
				<Pie
				  data={pieChartData}
				  dataKey="value"
				  nameKey="name"
				  cx="50%"
				  cy="50%"
				  outerRadius={150}
				  fill="#8884d8"
				  label={(entry) => `${entry.name}: ${numberFormatter.format(entry.value)}`}
				>
				  {pieChartData.map((entry, index) => (
					<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
				  ))}
				</Pie>
				<Tooltip
				  formatter={(value) => numberFormatter.format(value)}
				  contentStyle={{
					backgroundColor: '#001f3f',
					border: '1px solid #ffffff',
					color: '#ffffff',
				  }}
				/>
				<Legend verticalAlign="top" height={36} />
			  </PieChart>
			</ResponsiveContainer>
		  ) : (
			<p style={{ textAlign: 'center', marginTop: '2rem' }}>
			  No data available for the selected filters.
			</p>
		  )}
		</div>
	  );
	};
	
	export default USImigrationEncounters;