import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import { Navbar, Row, Col, Modal, Container, Nav } from 'react-bootstrap';
import { Pen, HeartFill } from 'react-bootstrap-icons';
import StateDesktopNavbar from './States/StateDesktopNavbar';
import GenerateFeedState from './GenerateFeedState';
import { CreateFeedAPIState } from './Functions';
import './App.css';
import MobileTopNavbar from './MobileTopNavbar';
import MobileOffCanvas from './MobileOffCanvas';

// State abbreviations mapping with duplicate-friendly keys
const stateAbbreviations = {
  alabama: 'al',
  alaska: 'ak',
  arizona: 'az',
  arkansas: 'ar',
  california: 'ca',
  colorado: 'co',
  connecticut: 'ct',
  delaware: 'de',
  florida: 'fl',
  georgia: 'ga',
  hawaii: 'hi',
  idaho: 'id',
  illinois: 'il',
  indiana: 'in',
  iowa: 'ia',
  kansas: 'ks',
  kentucky: 'ky',
  louisiana: 'la',
  maine: 'me',
  maryland: 'md',
  massachusetts: 'ma',
  michigan: 'mi',
  minnesota: 'mn',
  mississippi: 'ms',
  missouri: 'mo',
  montana: 'mt',
  nebraska: 'ne',
  nevada: 'nv',
  new_hampshire: 'nh',
  newhampshire: 'nh',
  new_jersey: 'nj',
  newjersey: 'nj',
  new_mexico: 'nm',
  newmexico: 'nm',
  new_york: 'ny',
  newyork: 'ny',
  north_carolina: 'nc',
  northcarolina: 'nc',
  north_dakota: 'nd',
  northdakota: 'nd',
  ohio: 'oh',
  oklahoma: 'ok',
  oregon: 'or',
  pennsylvania: 'pa',
  rhode_island: 'ri',
  rhodeisland: 'ri',
  south_carolina: 'sc',
  southcarolina: 'sc',
  south_dakota: 'sd',
  southdakota: 'sd',
  tennessee: 'tn',
  texas: 'tx',
  utah: 'ut',
  vermont: 'vt',
  virginia: 'va',
  washington: 'wa',
  west_virginia: 'wv',
  westvirginia: 'wv',
  wisconsin: 'wi',
  wyoming: 'wy',
};

// Utility function to format state names: 'newyork' or 'new_york' -> 'New York'
const knownStatesWithSpaces = [
  "newyork",
  "newjersey",
  "newhampshire",
  "northcarolina",
  "southcarolina",
  "northdakota",
  "southdakota",
  "westvirginia",
  "rhodeisland"
];

const formatStateName = (state) => {
  console.log("Original state:", state); // Log the raw state from the URL

  let replacedState = state.replace(/[_\s]+/g, ' '); // Replace underscores with spaces
  console.log("After replacing underscores with spaces:", replacedState);

  if (knownStatesWithSpaces.includes(replacedState.toLowerCase())) {
    // Insert a space between known conjoined state names
    replacedState = replacedState.replace(/(new|north|south|west|rhode)(?=[a-z])/i, "$1 ");
    console.log("After handling conjoined names:", replacedState);
  }

  const lowerCasedState = replacedState.toLowerCase(); // Convert to lowercase
  console.log("After converting to lowercase:", lowerCasedState);

  const splitWords = lowerCasedState.split(' '); // Split into words
  console.log("After splitting into words:", splitWords);

  const capitalizedWords = splitWords.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  ); // Capitalize each word
  console.log("After capitalizing each word:", capitalizedWords);

  const finalStateName = capitalizedWords.join(' '); // Join the words back into a string
  console.log("Final formatted state name:", finalStateName);

  return finalStateName;
};

const StateLegislativePage = () => {
  const { stateName } = useParams(); // Extract stateName from the URL
  const history = useHistory(); // For programmatic navigation

  // Format the state name from the URL for display
  const formattedStateName = formatStateName(stateName);

  // Find the corresponding abbreviation, using normalized state names
  const stateKey = stateName.replace(/[_\s]+/g, '').toLowerCase(); // Normalize the state key
  const stateAbbreviation = stateAbbreviations[stateKey] || 'N/A'; // Get abbreviation or 'N/A'

  // Redirect if the state name is invalid
  useEffect(() => {
    if (stateAbbreviation === 'N/A') {
      history.replace('/'); // Redirect to home if state name is invalid
    }
  }, [stateAbbreviation, history]);

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null,
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content,
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null,
    });
  };

  // Render nothing if the state abbreviation is invalid
  if (stateAbbreviation === 'N/A') {
    return null;
  }

  return (
    <>
      <BrowserView>
        <div id="homePage">
          <div id="homebg">
            <Modal
              id="fullBillModal"
              show={modalInfo.isOpen}
              size="xl"
              centered
              onHide={closeModal}
            >
              <Modal.Header id="billModalHeader" closeButton>
                <h3>{modalInfo.title}</h3>
              </Modal.Header>
              {modalInfo.content}
            </Modal>

            {/* Use the formatted state name in StateDesktopNavbar */}
            <StateDesktopNavbar state={formattedStateName} stateFlag="" />

            <div id="sectionHeading">
              <Row>
                <h3 id="branchHeading">
                  <Pen /> <b>Legislative</b>
                </h3>
              </Row>
            </div>

            <div id="branchPage">
              <Container className="bgcolor">
                <Container id="carouselBufferTwo">
                  <Row>
                    <Col id="legislativeColLeft">
                      <div id="columnLeftPadding">
                        <h4
                          id="whBreifingFeedHeading"
                          onClick={() =>
                            openModal(
                              'Bills',
                              <GenerateFeedState
                                url={`&skip=0&schema=united_states_of_america&table=${stateAbbreviation}_bills&order_by=created_at`}
                                name={formattedStateName}
                                branch="Legislative"
                                topic="N/A"
                                buttonName="Bill"
                              />
                            )
                          }
                        >
                          <b>Bill Updates</b>
                        </h4>
                        <div id="bills"></div>

                        {CreateFeedAPIState(
                          `&skip=0&schema=united_states_of_america&table=${stateAbbreviation}_bills&order_by=created_at`,
                          'bills',
                          'Bill',
                          'Legislative',
                          'N/A',
                          'Bill',
                          GenerateFeedState
                        )}
                      </div>
                    </Col>

                    <Col id="legislativeColRight">
                      <div id="columnRightPadding"></div>
                    </Col>
                  </Row>
                </Container>
              </Container>
            </div>
          </div>
        </div>
      </BrowserView>

      <MobileView>
        <MobileTopNavbar />
        <div id="homebg">
          <h5 id="branchHeadingMobile">
            <Pen /> Legislative
          </h5>

          <Col>
            <h5 id="presidentialFeedHeadingMobile">Bill Updates</h5>
            <div id="bills"></div>

            {CreateFeedAPIState(
              `&skip=0&schema=united_states_of_america&table=${stateAbbreviation}_bills&order_by=created_at`,
              'bills',
              'Bill',
              'Legislative',
              'N/A',
              'Bill',
              GenerateFeedState
            )}
          </Col>
        </div>
      </MobileView>
    </>
  );
};

export default StateLegislativePage;
