import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import { Spinner, Card, Button, Container, Modal, Form } from 'react-bootstrap';
import { InfoCircle, FileText, FolderPlus, Search } from 'react-bootstrap-icons';
import './App.css';

import useHandleContainerClickRecentsMexico from './UseHandleContainerClickRecentsMexico';



require('dotenv').config();
const apiKey = process.env.REACT_APP_API_KEY;
const requestOptions = {
    method: 'GET',
    headers: { 
      'Content-Type': 'application/json',
      "Authorization": "Bearer "+apiKey,
  },
  };

  var storedTitle = ''// title
var storedDetailsLink = ''// details link
var storedDocLink = ''// doc link *
var storedDate = ''// date
var storedNote = ''// note
var storedFeedName = ''// feed name
var storedBranchName = ''// branch name 
var storedTopicName = ''// topic name
var storedDescription = ''// description
var collectionCounter = localStorage.getItem('collectionNumber')


function SaveFeedItem(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, 
  saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription)
{

storedTitle = saveCollectionTitle;
storedDetailsLink = saveCollectionDetailLink;
storedDocLink = saveCollectionDocLink;
storedDate = saveCollectionDate;
storedNote = saveCollectionNote;
storedFeedName = saveCollectionFeedName;
storedBranchName = saveCollectionBranchName;
storedTopicName = saveCollectionTopicName;
storedDescription = saveCollectionDescription;
}

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
}

function GenerateFeedRecentMexico() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  var [feedLength, setFeedLength] = useState(10);
  const handleContainerClick = useHandleContainerClickRecentsMexico();
  
  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
    
    var searchItem = document.getElementById('noteModalFeedItem').value
  
       collectionCounter = localStorage.getItem('collectionNumber')
   
     collectionCounter++;

     
     var collectionTitles = []
      var collectionDetailLinks = []
     var collectionDocLinks = []
     var collectionNote = []

       var title = 'title'.concat(collectionCounter)
       var detailLink = 'detailLink'.concat(collectionCounter)
       var docLink = 'docLink'.concat(collectionCounter)
       var itemNote = 'itemNote'.concat(collectionCounter)
       var itemDate = 'itemDate'.concat(collectionCounter)
       var feedName = 'feedName'.concat(collectionCounter)
       var branchName = 'branchName'.concat(collectionCounter)
       var topicName = 'topicName'.concat(collectionCounter)
       var itemDescription = 'itemDescription'.concat(collectionCounter)
   
   
     
     
       localStorage.setItem(title,saveCollectionTitle)
       
       localStorage.setItem(detailLink,saveCollectionDetailLink)
      
       localStorage.setItem(docLink,saveCollectionDocLink)
       localStorage.setItem(itemNote,searchItem)
   
       localStorage.setItem(itemDate,saveCollectionDate)
       localStorage.setItem(feedName,saveCollectionFeedName)
       localStorage.setItem(branchName,saveCollectionBranchName)
       localStorage.setItem(topicName,saveCollectionTopicName)
   
       localStorage.setItem(itemDescription,saveCollectionDescription)
     
       localStorage.setItem('collectionNumber', collectionCounter)

  }

  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
    
  const apiURL = `https://api.govglance.org/posts/recent/schema?limit=${feedLength}&skip=0&schema=mexico`;

  useEffect(() => {
    setLoading(true);
    fetch(apiURL, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setData(data);
        //console.log(data)
        setLoading(false);
        setError(null);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, [feedLength]);



  const performSearch = () => {
    // Filter data based on search term
    // Assuming 'data' is an array of objects with a 'title' property
    const filteredData = data.filter(item =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setData(filteredData);
  };

  const handleSearchInputChange = event => {
    setSearchTerm(event.target.value);
  };

  const renderData = () => {
    let filteredData = data;
  
    // If there's a search term, filter the data
    if (searchTerm) {
      filteredData = data.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  
    if (loading) {
      return <Spinner animation="grow" variant="light" />;
    }
  
    if (error) {
      return <p>Error fetching data.</p>;
    }
  
    if (!filteredData || filteredData.length === 0) {
      return <p>No results found.</p>;
    }
  
    return filteredData.map((item, index) => {
      const title = item.title;
      const link = item.url;
      const date = formatDate(item.created_at);
      const source = item.collection_name;
      const nameForAPI = item.source.toLowerCase().replace(/\s+/g, '_');
    
      return (
        <div key={index}>
          <div id='fullBillFeed'>
            <Container id='feedContainer'>
            {/* onClick={() => handleHapticsClick(() => {})} */}
            <Card.Title id='billBodyTextSource' onClick={() => handleContainerClick(nameForAPI, item.collection_name, "", "", "")}>{source}</Card.Title>
            <Card.Title id='billText' onClick={(e) => {
  e.preventDefault();
  const linkToOpen = item.document_link || item.download?.pdfLink || link;
  window.open(linkToOpen, "_blank");
}}>
  {title}
</Card.Title>

              <Card.Body id='billBodyText'>{date}</Card.Body>
            
              <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(link, "_blank");
              }}><InfoCircle></InfoCircle> Details</Button>
              {(item.document_link || item.download?.pdfLink) && (
                <Button id='viewBillButton' onClick={(e) => {
                  e.preventDefault();
                  const linkToOpen = item.document_link || item.download?.pdfLink;
                  window.open(linkToOpen, "_blank");
                }}>
                  <FileText></FileText> Doc
                </Button>
              )}
              <Button id='addToCollectionButton' onClick={() => {
                setCollectionModalIsOpen(true);
                SaveFeedItem(title, link, "N/A", date, '', item.source, "", "", "");
              }}><FolderPlus></FolderPlus></Button>
            </Container>
          </div>
        </div>
      );
    });
  };

  return (

     
        <div id='homebg'>
                              <div id='searchForm'>

<Form className="text-center" onSubmit={(e) => { e.preventDefault(); }}>
<Form.Group className="d-flex justify-content-center align-items-center">
<Form.Control
variant="light"
id="searchInput"
placeholder="Search..."
type="text"
value={searchTerm}
onChange={handleSearchInputChange}

color="white"
/>
<Button id='ggButton' onClick={performSearch}>
<Search /> 
</Button>
</Form.Group>
</Form>

</div>

        {renderData()}
       
        <Button id='standardButton' onClick={() => {
               setFeedLength(feedLength+=20)
              }}>More</Button>


      <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
centered onHide={setCollectionModalIsOpen}>
                             
                              <Modal.Header id='billModalHeader' closeButton ><h3>{storedTitle}</h3></Modal.Header>

                              <Modal.Body id='descriptionModalBody'>
                                          {storedDescription}
                                          {storedDate}
                              <div id='fullBill'>
                         <Form id='noteModalForm'>
         <Form.Group id='noteModalForm'  className="mb-3" >
           <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
             type="text"
             as={"textarea"}
             rows={2}
             />
             <br></br>

         </Form.Group>
         </Form>

         <Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(storedTitle, storedDetailsLink, storedDocLink, 
  storedDate, storedFeedName, storedBranchName, storedTopicName, storedDescription) ;setCollectionModalIsOpen(false) }} >
    Save To Collection
  </Button>
  </Modal.Footer> 

           </div>
           </Modal.Body>
                              </Modal>
    </div>
  );
}

export default GenerateFeedRecentMexico;