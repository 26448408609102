import React, { useState } from 'react';
import { Container, Row, Col, Carousel, Button, Modal } from 'react-bootstrap';
import { ChevronRight, ReceiptCutoff } from 'react-bootstrap-icons';
import CreateFeedItemStateRepsForPage from './CreateFeedItemStateRepsForPage';
import GenerateFeedStateRepsAll from './GenerateFeedStateRepsAll';
var carouselInterval = 30000;
const StateFeedComponent = ({ stateName }) => {
  // Function to convert state name to URL-friendly format
  const getFormattedStateName = (stateName) => {
    return stateName.toLowerCase().replace(/\s+/g, '_');
  };

  // Map state names to abbreviations
  const stateAbbreviations = {
    alabama: 'AL',
    alaska: 'AK',
    arizona: 'AZ',
    arkansas: 'AR',
    california: 'CA',
    colorado: 'CO',
    connecticut: 'CT',
    delaware: 'DE',
    florida: 'FL',
    georgia: 'GA',
    hawaii: 'HI',
    idaho: 'ID',
    illinois: 'IL',
    indiana: 'IN',
    iowa: 'IA',
    kansas: 'KS',
    kentucky: 'KY',
    louisiana: 'LA',
    maine: 'ME',
    maryland: 'MD',
    massachusetts: 'MA',
    michigan: 'MI',
    minnesota: 'MN',
    mississippi: 'MS',
    missouri: 'MO',
    montana: 'MT',
    nebraska: 'NE',
    nevada: 'NV',
    new_hampshire: 'NH',
    new_jersey: 'NJ',
    new_mexico: 'NM',
    new_york: 'NY',
    north_carolina: 'NC',
    north_dakota: 'ND',
    ohio: 'OH',
    oklahoma: 'OK',
    oregon: 'OR',
    pennsylvania: 'PA',
    rhode_island: 'RI',
    south_carolina: 'SC',
    south_dakota: 'SD',
    tennessee: 'TN',
    texas: 'TX',
    utah: 'UT',
    vermont: 'VT',
    virginia: 'VA',
    washington: 'WA',
    west_virginia: 'WV',
    wisconsin: 'WI',
    wyoming: 'WY',
  };

  // Get the formatted name for the URL and the abbreviation
  const formattedStateName = getFormattedStateName(stateName);
  const stateAbbreviation = stateAbbreviations[formattedStateName];

  // Modal state management
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };

  const CustomModal = ({ isOpen, onClose, title, content }) => (
    <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
      <Modal.Header id='billModalHeader' closeButton>
        <h3>{title}</h3>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
    </Modal>
  );

  return (
    <>
      <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
      <h3 id='branchHeading' onClick={(e) => {
        e.preventDefault();
        window.open(`/us/${formattedStateName}/elections`, "_blank"); // Now uses formatted state name
      }}>
        <ReceiptCutoff /> <b id='branchHeadingBold'>Elections</b><ChevronRight id='clickChevron'></ChevronRight>
      </h3>
      <div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        {/* House Candidates Carousel */}
        <Col id='legislativeColLeft'>
          <div id='columnLefttPadding'>
            <h4 id='billFeedHeadingElection' onClick={() =>
              openModal(`2024 House Candidates`,
                <GenerateFeedStateRepsAll
                  stateAbbreviation={stateAbbreviation}
                  candidateType='House' />
            )}>
              <b>2024 House Candidates</b>
            </h4>
            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                <Col id='homeCol'>
                  <div id='columnLefttPadding'>
                    <div id='house'></div>
                    <CreateFeedItemStateRepsForPage divID="house" candidateType="House" stateAbbreviation={stateAbbreviation} skip={0} />
                  </div>
                </Col>
              </Carousel.Item>
              <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                <Col id='homeCol'>
                  <div id='columnLefttPadding'>
                    <div id='house2'></div>
                    <CreateFeedItemStateRepsForPage divID="house2" candidateType="House" stateAbbreviation={stateAbbreviation} skip={1} />
                  </div>
                </Col>
              </Carousel.Item>
              <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                <Col id='homeCol'>
                  <div id='columnLefttPadding'>
                    <div id='house3'></div>
                    <CreateFeedItemStateRepsForPage divID="house3" candidateType="House" stateAbbreviation={stateAbbreviation} skip={2} />
                  </div>
                </Col>
              </Carousel.Item>
              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg' onClick={() =>
                        openModal(`2024 House Candidates`,
                          <GenerateFeedStateRepsAll
                            stateAbbreviation={stateAbbreviation}
                            candidateType='House' />
                      )}>View all 2024 House Candidates</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>

        {/* Senate Candidates Carousel */}
        <Col id='legislativeColLeft'>
          <div id='columnLefttPadding'>
            <h4 id='billFeedHeading' onClick={() =>
              openModal(`2024 Senate Candidates`,
                <GenerateFeedStateRepsAll
                  stateAbbreviation={stateAbbreviation}
                  candidateType='Senate' />
            )}>
              <b>2024 Senate Candidates</b>
            </h4>
            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                <Col id='homeCol'>
                  <div id='columnLefttPadding'>
                    <div id='senate'></div>
                    <CreateFeedItemStateRepsForPage divID="senate" candidateType="Senate" stateAbbreviation={stateAbbreviation} skip={0} />
                  </div>
                </Col>
              </Carousel.Item>
              <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                <Col id='homeCol'>
                  <div id='columnLefttPadding'>
                    <div id='senate2'></div>
                    <CreateFeedItemStateRepsForPage divID="senate2" candidateType="Senate" stateAbbreviation={stateAbbreviation} skip={1} />
                  </div>
                </Col>
              </Carousel.Item>
              <Carousel.Item id='legislativeCarousel' interval={carouselInterval}>
                <Col id='homeCol'>
                  <div id='columnLefttPadding'>
                    <div id='senate3'></div>
                    <CreateFeedItemStateRepsForPage divID="senate3" candidateType="Senate" stateAbbreviation={stateAbbreviation} skip={2} />
                  </div>
                </Col>
              </Carousel.Item>
              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg' onClick={() =>
                        openModal(`2024 Senate Candidates`,
                          <GenerateFeedStateRepsAll
                            stateAbbreviation={stateAbbreviation}
                            candidateType='Senate' />
                      )}>View all 2024 Senate Candidates</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

    </>
  );
};

export default StateFeedComponent;
