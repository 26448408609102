
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand,  ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Tree, FileMedical, FileMedicalFill, ChevronRight  } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import { CreateFeedAPI, CreateFeedAPIRegulations } from './Functions';
import GenerateFeed from './GenerateFeed';
import RegulationsMobile from './RegulationsMobile';
import DesktopNavbar from './DesktopNavbar';
var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);
export const Regulations = () =>
 {
   render()
   
   {
    const [modalInfo, setModalInfo] = useState({
      isOpen: false,
      title: '',
      content: null
    });
  
    const openModal = (title, content) => {
      setModalInfo({
        isOpen: true,
        title,
        content
      });
    };
  
    const closeModal = () => {
      setModalInfo({
        isOpen: false,
        title: '',
        content: null
      });
    };
  return(
    <>
      <BrowserView>
        <div id='homePage'>
        <div id='homebg'>
        <DesktopNavbar/>
      
               <div id='sectionHeading'>      
               <Row>
              <h3 id='branchHeading' > <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16">
  <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"/>
  <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
</svg><b>Regulations</b></h3> 
              
              </Row>
              </div> 
         
                     
            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
                    <Col id='legislativeColLeft'>
                    <div id='columnRightPadding'>

<h4 id='departmentOfJusticeHeading'onClick={() => openModal(
'Rules',
<GenerateFeed 
url='&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Rule' 
name='Rules' 
branch='N/A' 
topic='Regulations' 
buttonName='Document' 
/>
)}><b>Rules</b></h4>

<div id='rules'></div>

{/* Display rules feed */}
{CreateFeedAPI(
'&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Rule', 
'rules', 
'Rules', 
'N/A', 
'Regulations', 
'Document', 
GenerateFeed
)}



</div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='departmentOfJusticeHeading'onClick={() => openModal(
      'Proposed Rules',
      <GenerateFeed 
        url='&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Proposed%20Rule' 
        name='Proposed Rules' 
        branch='N/A' 
        topic='Regulations' 
        buttonName='Document' 
      />
    )}><b>Proposed Rules</b></h4>

<div id='pr'></div>

{CreateFeedAPI(
'&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Proposed%20Rule', 
'pr', 
'Rules', 
'N/A', 
'Regulations', 
'Document', 
GenerateFeed
)}



                      </div>
                    </Col>


                    <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />



                  </Row>
                </Container>
              </Container>
            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
                    <Col id='legislativeColLeft'>
                    <div id='columnRightPadding'>

<h4 id='departmentOfJusticeHeading'onClick={() => openModal(
'Supporting & Related Material',
<GenerateFeed 
url='&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Supporting%20%26%20Related%20Material' 
name='Supporting & Related Material' 
branch='N/A' 
topic='Regulations' 
buttonName='Document' 
/>
)}><b>Supporting & Related Material</b></h4>

<div id='supporting'></div>

{/* Display rules feed */}
{CreateFeedAPIRegulations(
'&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Supporting%20%26%20Related%20Material', 
'supporting', 
'Supporting & Related Material', 
'N/A', 
'Regulations', 
'Document', 
GenerateFeed
)}



</div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='departmentOfJusticeHeading'onClick={() => openModal(
      'Notices',
      <GenerateFeed 
        url='&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Notice' 
        name='Notices' 
        branch='N/A' 
        topic='Regulations' 
        buttonName='Document' 
      />
    )}><b>Notices</b></h4>

<div id='notices'></div>

{CreateFeedAPI(
'&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Notice', 
'notices', 
'Notices', 
'N/A', 
'Regulations', 
'Document', 
GenerateFeed
)}



                      </div>
                    </Col>


                    <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />



                  </Row>
                </Container>
              </Container>
            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
                    <Col id='legislativeColLeft'>
                    <div id='columnRightPadding'>

<h4 id='departmentOfJusticeHeading'onClick={() => openModal(
'Other',
<GenerateFeed 
url='&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Other' 
name='Other' 
branch='N/A' 
topic='Regulations' 
buttonName='Document' 
/>
)}><b>Other</b></h4>

<div id='other'></div>

{/* Display rules feed */}
{CreateFeedAPI(
'&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Other', 
'other', 
'Other', 
'N/A', 
'Regulations', 
'Document', 
GenerateFeed
)}



</div>
                    </Col>


                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                   



                      </div>
                    </Col>


                    <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />



                  </Row>
                </Container>
              </Container>
            </div>

      

  


 

      


            

   

            <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>
</div>

         </div>
          

              </BrowserView>

              <MobileView>

<RegulationsMobile/>
              </MobileView>
              
    </>
  )
}
 }



  






export default Regulations;