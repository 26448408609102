import React, { useState } from 'react';
import {
  Image, Navbar, Dropdown, Button, Col, Modal, Card,
} from 'react-bootstrap';
import { Link45deg, HeartFill } from 'react-bootstrap-icons';
import { BrowserView, MobileView } from 'react-device-detect';
import { Link } from 'react-router-dom';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import DesktopNavbar from './DesktopNavbar';
import { CreateFeedAPI } from './Functions';
import GenerateFeed from './GenerateFeed';

const AgencyModal = ({ show, onHide, title, url, feedName, topic }) => (
  <Modal
    show={show}
    size="xl"
    centered
    onHide={onHide}
    aria-labelledby="contained-modal-title-vcenter"
  >
    <Modal.Header closeButton>
      <h3>{title}</h3>
    </Modal.Header>
    <GenerateFeed
      url={url}
      name={feedName}
      topic={topic}
      buttonName="Doc"
    />
  </Modal>
);

const AgencyTemplate2 = ({
  imageName, agencyName, officialURL, missionStatement, rssURL,
  feedName, topic, departmentType,
}) => {
  const [feedModalIsOpen, setFeedModalIsOpen] = useState(false);

  const openExternalLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <MobileView>
        <MobileTopNavbar />
        <div id="homebg">
          <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div id="profileImageOnPageDepartment">
                  <Image id="profileImage-imageOnPageDepartment" src={imageName} />
                </div>
                <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold' }}>
                  <h5 id="branchHeadingMobile">{agencyName}</h5>
                </div>
              </div>
              <Dropdown drop="down-centered">
                <Dropdown.Toggle id="searchCountry"><Link45deg /></Dropdown.Toggle>
                <Dropdown.Menu id="docsDropdown">
                  <Dropdown.Item onClick={() => openExternalLink(officialURL)}>
                    Official Site
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
              Mission Statement: {missionStatement}
            </div>
          </div>

          <Col>
            <h5 id="presidentialFeedHeadingMobile" onClick={() => setFeedModalIsOpen(true)}>{feedName}</h5>
            <div className="courtNewsMobile" id="agency"></div>
            {CreateFeedAPI(rssURL, 'agency', feedName, departmentType, topic, 'Doc', GenerateFeed)}
          </Col>

          <AgencyModal
            show={feedModalIsOpen}
            onHide={() => setFeedModalIsOpen(false)}
            title={feedName}
            url={rssURL}
            feedName={feedName}
            topic={topic}
          />

          {/* <Col>
            <h5 id="headingSelectHomeCountry">
              Support the Gov Glance Foundation
              <Button id="donateButton" onClick={() => openExternalLink('https://donate.stripe.com/28o3fXbdvbQO2RO288')}>
                <HeartFill id="donateIcon" /> Donate
              </Button>
            </h5>
          </Col> */}

          <Navbar id="navbarMobileBottom" fixed="bottom">
            <MobileOffCanvas />
          </Navbar>
        </div>
      </MobileView>

      <BrowserView>
        <div id="homebg">
          <DesktopNavbar />
          <div id="feedDisplay" style={{ padding: '20px', borderRadius: '18px', marginTop: '0em' }}>
            <div style={{ position: 'relative' }}>
              {imageName && (
                <div id="profileImage" style={{ width: '130px', height: '130px', borderRadius: '100%', overflow: 'hidden', marginLeft: '60px' }}>
                  <Image id="profileImage-image" src={imageName} />
                </div>
              )}
              <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
                <div style={{ color: 'white', fontSize: '20px' }}>
                  <h3>{feedName}</h3>
                  <p>{missionStatement}</p>
                  {officialURL && (
                    <Link style={{ color: '#21cff5' }} onClick={() => openExternalLink(officialURL)}>
                      {officialURL}
                    </Link>
                  )}
                </div>
              </div>
            </div>

            <Card.Body id="cardBodyRep"><h3>News</h3></Card.Body>
            <div id="agency"></div>
            {CreateFeedAPI(rssURL, 'agency', feedName, departmentType, topic, 'Doc', GenerateFeed)}
          </div>
        </div>
      </BrowserView>
    </>
  );
};

export default AgencyTemplate2;
