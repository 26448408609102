// useHandleContainerClickRecnts.js
import { useHistory } from 'react-router-dom';

const useHandleContainerClickRecentsCanada = () => {
  const history = useHistory();

  const handleContainerClickRecentsCanada = (apiRequest, name, branch, topic, buttonName) => {


    // Navigate to the representative page with bioGuideId and name as state
    window.scrollTo(0, 0);
    history.push(`/canada/feed`, { 
      apiRequest, name, branch, topic, buttonName
    });
  };

  return handleContainerClickRecentsCanada;
};

export default useHandleContainerClickRecentsCanada;
