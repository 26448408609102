import React, { useState } from 'react';
import {
  Image, Navbar, Dropdown, Button, Col, Modal, Card,
} from 'react-bootstrap';
import { Link45deg, HeartFill } from 'react-bootstrap-icons';
import { BrowserView, MobileView } from 'react-device-detect';
import { Link } from 'react-router-dom';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import DesktopNavbar from './DesktopNavbar';
import { CreateFeedAPI } from './Functions';
import GenerateFeed from './GenerateFeed';


const SEC = () => {
  const [feedModalIsOpen, setFeedModalIsOpen] = useState(false);

  const openExternalLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <MobileView>
        <MobileTopNavbar />
        <div id="homebg">
          <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div id="profileImageOnPageDepartment">
                  <Image id="profileImage-imageOnPageDepartment" src='/sec.svg' />
                </div>
                <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold' }}>
                  <h5 id="branchHeadingMobile">Securities and Exchange Commission</h5>
                </div>
              </div>
              <Dropdown drop="down-centered">
                <Dropdown.Toggle id="searchCountry"><Link45deg /></Dropdown.Toggle>
                <Dropdown.Menu id="docsDropdown">
                  <Dropdown.Item onClick={() => openExternalLink('https://www.sec.gov/', '_blank')}>
                    Official Site
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
              Mission Statement: The federal securities laws empower the Securities and Exchange Commission with broad authority over all aspects of the securities industry.
            </div>
          </div>

          <Col>

<h5 id='presidentialFeedHeadingMobile' >SEC Press Releases</h5>

     <div class='courtNewsMobile' id='secPR'></div>
                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=sec_updates&order_by=created_at', 'secPR', 
                            'SEC Press Releases', 'Executive', 
                            'Economy', 'Document', GenerateFeed)}

                       
   </Col>

   <Col>

<h5 id='presidentialFeedHeadingMobile' >SEC Speeches and Statements</h5>

     <div class='courtNewsMobile' id='sec'></div>
             

             {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=sec_speeches_statements&order_by=created_at', 'sec', 
                 'SEC Speeches and Statements', 'Executive', 
                 'Economy', 'Document', GenerateFeed)}

       
   </Col>

        

          {/* <Col>
            <h5 id="headingSelectHomeCountry">
              Support the Gov Glance Foundation
              <Button id="donateButton" onClick={() => openExternalLink('https://donate.stripe.com/28o3fXbdvbQO2RO288')}>
                <HeartFill id="donateIcon" /> Donate
              </Button>
            </h5>
          </Col> */}

          <Navbar id="navbarMobileBottom" fixed="bottom">
            <MobileOffCanvas />
          </Navbar>
        </div>
      </MobileView>

      <BrowserView>
        <div id="homebg">
          <DesktopNavbar />
          <div id="feedDisplay" style={{ padding: '20px', borderRadius: '18px', marginTop: '0em' }}>
            <div style={{ position: 'relative' }}>
      
                <div id="profileImage" style={{ width: '130px', height: '130px', borderRadius: '100%', overflow: 'hidden', marginLeft: '60px' }}>
                  <Image id="profileImage-image" src='/sec.svg' />
                </div>
  
              <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
                <div style={{ color: 'white', fontSize: '20px' }}>
                  <h3>Securities and Exchange Commission</h3>
                  <p> Mission Statement: The federal securities laws empower the Securities and Exchange Commission with broad authority over all aspects of the securities industry.</p>
                 
                    <Link style={{ color: '#21cff5' }} onClick={() => openExternalLink('https://www.sec.gov/')}>
                    https://www.sec.gov/
                    </Link>
              
                </div>
              </div>
            </div>



            <Card.Body id="cardBodyRep"><h3>SEC Press Releases</h3></Card.Body>
            <div  id='secPR'></div>
{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=sec_updates&order_by=created_at', 'secPR', 
    'SEC Press Releases', 'Executive', 
    'Economy', 'Document', GenerateFeed)}

<Card.Body id="cardBodyRep"><h3>SEC Speeches and Statements</h3></Card.Body>
            <div  id='sec'></div>
             

             {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=sec_speeches_statements&order_by=created_at', 'sec', 
                 'SEC Speeches and Statements', 'Executive', 
                 'Economy', 'Document', GenerateFeed)}
          </div>
        </div>
      </BrowserView>
    </>
  );
};

export default SEC;
