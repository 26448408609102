
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand,  ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Tree, FileMedical, FileMedicalFill, ChevronRight  } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import GenerateFeed from './GenerateFeed';
import { CreateFeedAPI } from './Functions';

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);
export const RegulationsMobile = () =>
 {
   render()
   {
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };
  return(
    <>

      


<MobileTopNavbar/>


        <div id='homebg'>
    

            <h5 id='branchHeadingMobile' > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16">
  <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"/>
  <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
</svg>Regulations</h5>
     


                 <Col>
  <h5 
    id='presidentialFeedHeadingMobile' 
    onClick={() => openModal(
      'Rules',
      <GenerateFeed 
        url='&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Rule' 
        name='Rules' 
        branch='N/A' 
        topic='Regulations' 
        buttonName='Document' 
      />
    )}
  >
    Rules
  </h5>
  <div className='courtNewsMobile' id='rules'></div>

  {/* Display rules feed */}
  {CreateFeedAPI(
    '&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Rule', 
    'rules', 
    'Rules', 
    'N/A', 
    'Regulations', 
    'Document', 
    GenerateFeed
  )}
</Col>

<Col>
  <h5 
    id='presidentialFeedHeadingMobile' 
    onClick={() => openModal(
      'Rules',
      <GenerateFeed 
        url='&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Proposed%20Rule' 
        name='Rules' 
        branch='N/A' 
        topic='Regulations' 
        buttonName='Document' 
      />
    )}
  >
    Proposed Rules
  </h5>
  <div className='courtNewsMobile' id='pr'></div>

{CreateFeedAPI(
'&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Proposed%20Rule', 
'pr', 
'Rules', 
'N/A', 
'Regulations', 
'Document', 
GenerateFeed
)}
</Col>

<Col>
  <h5 
    id='presidentialFeedHeadingMobile' 
    onClick={() => openModal(
      'Supporting & Related Materials News',
      <GenerateFeed 
        url='&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Supporting%20%26%20Related%20Material' 
        name='Supporting & Related Materials' 
        branch='N/A' 
        topic='Regulations' 
        buttonName='Document' 
      />
    )}
  >
    Supporting & Related Materials
  </h5>
  <div className='courtNewsMobile' id='supporting'></div>

  {/* Display Supporting & Related Materials feed */}
  {CreateFeedAPI(
    '&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Supporting%20%26%20Related%20Material', 
    'supporting', 
    'Supporting & Related Materials', 
    'N/A', 
    'Regulations', 
    'Document', 
    GenerateFeed
  )}
</Col>
<Col>
  <h5 
    id='presidentialFeedHeadingMobile' 
    onClick={() => openModal(
      'Notices',
      <GenerateFeed 
        url='&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Notice' 
        name='Notices' 
        branch='N/A' 
        topic='Regulations' 
        buttonName='Document' 
      />
    )}
  >
    Notices
  </h5>
  <div className='courtNewsMobile' id='notices'></div>

  {/* Display Supporting & Related Materials feed */}
  {CreateFeedAPI(
    '&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Notice', 
    'notices', 
    'Notices', 
    'N/A', 
    'Regulations', 
    'Document', 
    GenerateFeed
  )}
</Col>

<Col>
  <h5 
    id='presidentialFeedHeadingMobile' 
    onClick={() => openModal(
      'Other',
      <GenerateFeed 
        url='&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Other' 
        name='Other' 
        branch='N/A' 
        topic='Regulations' 
        buttonName='Document' 
      />
    )}
  >
    Other
  </h5>
  <div className='courtNewsMobile' id='other'></div>

  {/* Display Other regulations feed */}
  {CreateFeedAPI(
    '&skip=0&schema=united_states_of_america&table=regulations&order_by=created_at&filter_column=document_type&filter_string=Other', 
    'other', 
    'Other Regulations', 
    'N/A', 
    'Regulations', 
    'Document', 
    GenerateFeed
  )}
</Col>





                        <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />

          <Col>

            </Col>


            <Col>
            <br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
     
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
</div>




             
              
    </>
  )
}
 }



  






export default RegulationsMobile;