import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnOversightAndReform from './HouseCommitteeOnOversightAndReform';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnArmedServices from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnTheBudget from './HouseCommitteeOnTheBudget';
import HouseCommitteeOnEducationAndLabor from './HouseCommitteeOnEducationAndLabor';
import HouseFloor from './HouseFloor';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEthics from './HouseCommitteeOnEthics';
import CongressionalHearings from './CongressionalHearings';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import HouseCommitteeOnForeignAffairs from './HouseCommitteeOnForeignAffairs';
import HouseCommitteeOnHomelandSecurity from './HouseCommitteeOnHomelandSecurity';
import HouseCommitteeOnTheJudiciary from './HouseCommitteeOnTheJudiciary';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import HouseCommitteeOnHouseAdministration from './HouseCommitteeOnHouseAdministration';
import CongressionalBudgetOffice from './CongressionalBudgetOffice';
import PresidentialProclamations from './PresidentialProclamations';
import ExecutiveOrders from './ExecutiveOrders';
import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
    createWhiteHouseFeedMobile,createDepartmentOfState,
    createDepartmentOfDefense, 
    createDepartmentOfJustice, createDepartmentOfTheInterior,
    createDepartmentOfAgriculture, createDepartmentOfCommerce,
    createDepartmentOfCommerceMobile, createDepartmentOfLabor,
    createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
    ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
    , createEnvironmentalProtectionAgency,
    createDepartmentOfTreasury, createNationalScienceFoundation, 
     createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, 
     createFederalRegisterStateDepartment, createFederalRegisterDefense,
    CreateFeedItem, CreateFeedItemPresidentialDocuments, CreateExecutiveOrders,
    CreateFeedItemExecutiveOrders,
    CreateFeedItemBillUpdates,
    CreateFeedItemExecutiveOffice, CreateFeedItemMaterials, CreateFeedAPI, CreateFeedAPICommittee  } from './Functions'
import NationalScienceFoundation from './NationalScienceFoundation';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import BillUpdates from './BillUpdates';
import CouncilOfEconomicAdvisers from './CouncilOfEconomicAdvisers';
import CouncilOnEnvironmentQuality from './CouncilOnEnvironmentQuality';
import DomesticPolicyCouncil from './DomesticPolicyCouncil';
import GenderPolicyCouncil from './GenderPolicyCouncil';
import NationalEconomicCouncil from './NationalEconomicCouncil';
import NationalSecurityCouncil from './NationalSecurityCouncil';
import OfficeOfIntergovernmentalAffairs from './OfficeOfIntergovernmentalAffairs';
import OfficeOfManagmentAndBudget from './OfficeOfManagmentAndBudget';
import OfficeOfNationalDrugControlPolicy from './OfficeOfNationalDrugControlPolicy';
import OfficeOfPublicEngagement from './OfficeOfPublicEngagement';
import OfficeOfScienceAndTechnologyPolicy from './OfficeOfScienceAndTechnologyPolicy';
import OfficeOfNationalCyberDirector from './OfficeOfNationalCyberDirector';
import PresidentialPersonnelOffice from './PresidentialPersonnelOffice';
import HouseFeed from './HouseFeed';
import FederalElectionCommissionNews from './FederalElectionCommissionNews';
import HouseCommitteeOnAppropriationsMaterials from './HouseCommitteeOnAppropriationsMaterials';
import HouseCommitteeToInvestigateTheCapitolMaterials from './HouseCommitteeToInvestigateTheCapitolMaterials';
import HouseCommitteeOnIntelligenceMaterials from './HouseCommitteeOnIntelligenceMaterials';
import HouseCommitteeOnIntelligence from './HouseCommitteeOnIntelligence';
import HouseCommitteeOnTheJudiciaryMaterials from './HouseCommitteeOnTheJudiciaryMaterials';
import HouseCommitteeOnTheBudgetMaterials from './HouseCommitteeOnTheBudgetMaterials';
import HouseCommitteeOnWaysAndMeans from './HouseCommitteeOnWaysAndMeans';
import HouseCommitteeOnWaysAndMeansMaterials from './HouseCommitteeOnWaysAndMeansMaterials';
import HouseCommitteeOnVeteransAffairsMaterials from './HouseCommitteeOnVeteransAffairsMaterials';
import HouseCommitteeOnTransportationAndInfrastructure from './HouseCommitteeOnTransportationAndInfrastructure';
import HouseCommitteeOnSmallBusinessMaterials from './HouseCommitteeOnSmallBusinessMaterials';
import HouseCommitteeOnSmallBusiness from './HouseCommitteeOnSmallBusiness';
import HouseCommitteeOnScienceSpaceAndTechnology from './HouseCommitteeOnScienceSpaceAndTechnology';
import HouseCommitteeOnScienceSpaceAndTechnologyMaterials from './HouseCommitteeOnScienceSpaceAndTechnologyMaterials';
import HouseCommitteeOnRulesMaterials from './HouseCommitteeOnRulesMaterials';
import HouseCommitteeOnRules from './HouseCommitteeOnRules';
import HouseCommitteeOnOversightAndReformMaterials from './HouseCommitteeOnOversightAndReformMaterials';
import HouseCommitteeOnHouseAdministrationMaterials from './HouseCommitteeOnHouseAdministrationMaterials';
import HouseCommitteeOnHomelandSecurityMaterials from './HouseCommitteeOnHomelandSecurityMaterials';
import HouseCommitteeOnForeignAffairsMaterials from './HouseCommitteeOnForeignAffairsMaterials';
import HouseCommitteeOnFinacialServicesMaterials from './HouseCommitteeOnFinacialServicesMaterials';
import HouseCommitteeOnEthicsMaterials from './HouseCommitteeOnEthicsMaterials';
import HouseCommitteeOnEnergyAndCommerceMaterials from './HouseCommitteeOnEnergyAndCommerceMaterials';
import HouseCommitteeOnEducationAndLaborMaterials from './HouseCommitteeOnEducationAndLaborMaterials';
import HouseCommitteeOnVeteransAffairs from './HouseCommitteeOnVeteransAffairs';
import HouseCommitteeToInvestigateTheCapitol from './HouseCommitteeToInvestigateTheCapitol';
import HouseComitteeOnAgricultureMaterials from './HouseComitteeOnAgricultureMaterials';
import HouseCommitteeOnArmedServicesMaterials from './HouseCommitteeOnArmedServicesMaterials';
import HouseCommitteeOnInterstateAndForeignCommerceMaterials from './HouseCommitteeOnInterstateAndForeignCommerceMaterials';
import HouseCommitteeOnTransportationAndInfrastructureMaterials from './HouseCommitteeOnTransportationAndInfrastructureMaterials';
import HouseCommitteeOnNaturalResourcesMaterials from './HouseCommitteeOnNaturalResourcesMaterials';
import GenerateFeed from './GenerateFeed';
import HouseOfRepsDisplay from './HouseOfRepsDisplay';
import GenerateFeedCommittee from './GenerateFeedCommittee';


var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 30000;



  

const CustomModal = ({ isOpen, onClose, title, content }) => (
        <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
          <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
          {content}
        </Modal>
      );


  

export const HouseMobile = () =>   {
    render()
    
    {

      const [houseFloorModalIsOpen, setHouseFloorModalIsOpen] = useState(false)
      const [committeeOnAgModalIsOpen, setCommitteeOnAgModalIsOpen] = useState(false)
      const [agMaterialsModalIsOpen, setAgMaterialsModalIsOpen] = useState(false)
      const [committeeOnAppropriationsModalIsOpen, setCommitteeOnAppropriationsModalIsOpen] = useState(false)
      const [appropriationsMaterialsModalIsOpen, setAppropriationsMaterialsModalIsOpen] = useState(false)
      const [committeeOnArmedServicesModalIsOpen, setCommitteeOnArmedServicesModalIsOpen] = useState(false)
      const [armedServicesMaterialsModalIsOpen, setArmedServicesMaterialsModalIsOpen] = useState(false)
      const [committeeOnEducationAndLaborModalIsOpen, setCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
      const [educationAndLaborMaterialsModalIsOpen, setEducationAndLaborMaterialsModalIsOpen] = useState(false)
      const [committeeOnEnergyAndCommerceModalIsOpen, setCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
      const [energyAndCommerceMaterialsModalIsOpen, setEnergyAndCommerceMaterialsModalIsOpen] = useState(false)
      const [committeeOnEthicsModalIsOpen, setCommitteeOnEthicsModalIsOpen] = useState(false)
      const [ethicsMaterialsModalIsOpen, setEthicsMaterialsModalIsOpen] = useState(false)
      const [committeeOnFinancialServicesModalIsOpen, setCommitteeOnFinacialServicesModalIsOpen] = useState(false)
      const [financialServicesMaterialsModalIsOpen, setFinancialServicesMaterialsModalIsOpen] = useState(false)
      const [committeeOnForeignAffairsModalIsOpen, setCommitteeOnForeignAffairsModalIsOpen] = useState(false)
      const [foreignAffairsMaterialsModalIsOpen, setForeignAffairsMaterialsModalIsOpen] = useState(false)
      const [committeeOnHomelandSecurityModalIsOpen, setCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
      const [homelandSecurityMaterialsModalIsOpen, setHomelandSecurityMaterialsModalIsOpen] = useState(false)
      const [committeeOnHouseAdministrationModalIsOpen, setCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
      const [houseAdministrationMaterialsModalIsOpen, setHouseAdministrationMaterialsModalIsOpen] = useState(false)
      const [foreignCommerceMaterialsModalIsOpen, setForeignCommerceMaterialsModalIsOpen] = useState(false)
      const [committeeOnNaturalResourcesModalIsOpen, setCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
      const [naturalResourcesMaterialsModalIsOpen, setNaturalResourcesMaterialsModalIsOpen] = useState(false)
      const [committeeOnOversightModalIsOpen, setCommitteeOnOversightModalIsOpen] = useState(false)
      const [oversightMaterialsModalIsOpen, setOversightMaterialsModalIsOpen] = useState(false)
      const [committeeOnRulesModalIsOpen, setCommitteeOnRulesModalIsOpen] = useState(false)
      const [rulesMaterialsModalIsOpen, setRulesMaterialsModalIsOpen] = useState(false)
      const [committeeOnScienceAndTechModalIsOpen, setCommitteeOnScienceAndTechModalIsOpen] = useState(false)
      const [scienceAndTechMaterialsModalIsOpen, setScienceAndTechMaterialsModalIsOpen] = useState(false)
      const [committeeOnSmallBusinessModalIsOpen, setCommitteeOnSmallBusinessModalIsOpen] = useState(false)
      const [smallBusinessMaterialsModalIsOpen, setSmallBusinessMaterialsModalIsOpen] = useState(false)
      const [committeeOnTransportationModalIsOpen, setCommitteeOnTransportationModalIsOpen] = useState(false)
      const [transportationMaterialsModalIsOpen, setTransportationMaterialsModalIsOpen] = useState(false)
      const [veteransAffairsModalIsOpen, setVeteransAffairsModalIsOpen] = useState(false)
      const [veteransAffairsMaterialsModalIsOpen, setVeteransAffairsMaterialsModalIsOpen] = useState(false)
      const [committeeOnWaysAndMeansModalIsOpen, setCommitteeOnWaysAndMeansModalIsOpen] = useState(false)
      const [waysAndMeansMaterialsModalIsOpen, setWaysAndMeansMaterialsModalIsOpen] = useState(false)
      const [committeeOnTheBudgetModalIsOpen, setCommitteeOnTheBudgetModalIsOpen] = useState(false)
      const [budgetMaterialsModalIsOpen, setBudgetMaterialsModalIsOpen] = useState(false)
      const [committeeOnTheJudiciaryModalIsOpen, setCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
      const [judiciaryMaterialsModalIsOpen, setJudiciaryMaterialsModalIsOpen] = useState(false)
      const [committeeOnIntelligenceModalIsOpen, setCommitteeOnIntelligenceModalIsOpen] = useState(false)
      const [intelligenceMaterialsModalIsOpen, setIntelligenceMaterialsModalIsOpen] = useState(false)
      const [capitolCommitteeModalIsOpen, setCapitolCommitteeModalIsOpen] = useState(false)
      const [capitolMaterialsModalIsOpen, setCapitolMaterialsModalIsOpen] = useState(false)
      const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      const [modalInfo, setModalInfo] = useState({
        isOpen: false,
        title: '',
        content: null
      });
    
      const openModal = (title, content) => {
        setModalInfo({
          isOpen: true,
          title,
          content
        });
      };
    
      const closeModal = () => {
        setModalInfo({
          isOpen: false,
          title: '',
          content: null
        });
      };

    var branch = "all";
  
    return(
       <>
   <MobileTopNavbar/>
  
  
   <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
         
 
  
  <div id='homebg'>

               
 
  <h5 id='branchHeadingMobile' onClick={() => window.open('/us/house', "_self")}>House of Representatives</h5>
            
          
 
            
            <Col>  
            <h5  onClick={() => window.open('/us/houseofreps', "_self")} id='presidentialFeedHeadingMobile'>Represenatives<ChevronRight id='clickChevron'></ChevronRight></h5>
                        
                          
          <HouseOfRepsDisplay/>
                       </Col>    


  {/* <Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setHouseFloorModalIsOpen(true)}>House Floor Activity Today</h5>
 <div id='houseFloor'></div>
                      
          
             {CreateFeedItem('https://clerk.house.gov/Home/Feed', 'houseFloor', 'House Floor Activity Today', 'Legislative', 'N/A', HouseFloor )}

</Col> */}

<Modal id='fullBillModal' show={houseFloorModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHouseFloorModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>House Floor Activity Today</h3></Modal.Header>

<HouseFloor/>
</Modal>

                 

              
      
<Col>
                  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
                            openModal('Committee on Agriculture Materials',
                              <GenerateFeedCommittee  url='&skip=0&committee=Committee%20on%20Agriculture&chamber=HOUSE'
                              name='Committee on Agriculture Materials' branch='Legislative' topic='N/A' buttonName='Bill'/>)}>Committee on Agriculture Materials</h5>
 
                  
                     


                  <div class='courtNewsMobile' id='houseComitteeOnAgricultueMaterials'></div>
                          
                  {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20Agriculture&chamber=HOUSE', 
      'houseComitteeOnAgricultueMaterials', 
                            'Committee on Agriculture Materials', 'Legislative', 
                            'N/A', 'Bill', GenerateFeedCommittee)}
 
                </Col>



                {/* <Col>
                  <h5 id='presidentialFeedHeadingMobile' onClick={() => setAgMaterialsModalIsOpen(true)}>Committee on Agriculture Materials</h5>
 
                  
                     


                  <div class='courtNewsMobile' id='houseComitteeOnAgricultueMaterials'></div>
                          
                          {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Agriculture.xml', 'houseComitteeOnAgricultueMaterials', 'House Committee on Agriculture Materials', 'Legislative', 'N/A', HouseComitteeOnAgricultureMaterials)}

 
                </Col>

                <Modal id='fullBillModal' show={agMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Agriculture Materials</h3></Modal.Header>

<HouseComitteeOnAgricultureMaterials/>
</Modal> */}



                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() =>
                            openModal('Committee on Appropriations',
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_appropriations_meeting_feed&order_by=created_at' 
                            name='Committee on Appropriations' branch='Legislative' topic='N/A' buttonName='Bill' />)}>Committee on Appropriations</h5>

<div class='courtNewsMobile'id='houseCommitteeOnAppropriations'></div>


{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_appropriations_meeting_feed&order_by=created_at', 
      'houseCommitteeOnAppropriations', 
                            'Committee on Appropriations', 'Legislative', 
                            'N/A', 'Bill', GenerateFeed)}
    
                </Col>

                <Modal id='fullBillModal' show={committeeOnAppropriationsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnAppropriationsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Appropriations</h3></Modal.Header>

<HouseCommitteeOnAppropriations/>
</Modal>

          
          
<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Appropriations Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Appropriations&chamber=HOUSE'
          name='Committee on Appropriations Materials' 
          branch='Legislative' 
          topic='N/A' 
          buttonName='Bill' />
      )}>
      Committee on Appropriations Materials
  </h5>
  
  <div className='courtNewsMobile' id='houseCommitteeOnAppropriationsMaterials'></div>

  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Appropriations&chamber=HOUSE', 
    'houseCommitteeOnAppropriationsMaterials', 
    'House Committee on Appropriations Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>

{/* 
                <Col>
                <h5 id='presidentialFeedHeadingMobile'onClick={() => setCommitteeOnArmedServicesModalIsOpen(true)}>Committee on Armed Services</h5>
                        <div class='courtNewsMobile' id='houseComitteeOnArmedServices'></div>
                  
                 

                </Col> */}

                <Modal id='fullBillModal' show={committeeOnArmedServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnArmedServicesModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Armed Services</h3></Modal.Header>

<HouseCommitteeOnArmedServices/>
</Modal>

<Col>
  <div id='columnRightPadding'>
    <h5 id='presidentialFeedHeadingMobile' onClick={() =>
        openModal('Committee on Armed Services Materials',
          <GenerateFeedCommittee 
            url='&skip=0&committee=Committee%20on%20Armed%20Services&chamber=HOUSE'
            name='Committee on Armed Services Materials' 
            branch='Legislative' 
            topic='N/A' 
            buttonName='Bill' />
        )}>
        Committee on Armed Services Materials
    </h5>

    <div id='houseCommitteeOnArmedServicesMaterials'></div>

    {CreateFeedAPICommittee(
      '&skip=0&committee=Committee%20on%20Armed%20Services&chamber=HOUSE', 
      'houseCommitteeOnArmedServicesMaterials', 
      'House Committee on Armed Services Materials', 
      'Legislative', 
      'N/A', 
      'Bill', 
      GenerateFeedCommittee
    )}
  </div>
</Col>




                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnEducationAndLaborModalIsOpen(true)}>Committee on Education and Labor</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnEducationAndLabor'></div>
                        

                </Col>

                <Modal id='fullBillModal' show={committeeOnEducationAndLaborModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnEducationAndLaborModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Education and Labor</h3></Modal.Header>

<HouseCommitteeOnEducationAndLabor/>
</Modal>



<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Education and Labor Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Education%20and%20the%20Workforce&chamber=HOUSE'
          name='Committee on Education and Labor Materials' 
          branch='Legislative' 
          topic='Economy' 
          buttonName='Bill' />
      )}>
      Committee on Education and Labor Materials
  </h5>

  <div className='courtNewsMobile' id='houseCommitteeOnEducationAndLaborMaterials'></div>

  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Education%20and%20the%20Workforce&chamber=HOUSE', 
    'houseCommitteeOnEducationAndLaborMaterials', 
    'House Committee on Education and Labor Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>



                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnEnergyAndCommerceModalIsOpen(true)}>Committee on Energy and Commerce</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnEnergyAndCommerce'></div>
                        

                </Col>

                <Modal id='fullBillModal' show={committeeOnEnergyAndCommerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnEnergyAndCommerceModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Energy and Commerce</h3></Modal.Header>

<HouseCommitteeOnEnergyAndCommerce/>
</Modal>




<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Energy and Commerce Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Energy%20and%20Commerce&chamber=HOUSE'
          name='Committee on Energy and Commerce Materials' 
          branch='Legislative' 
          topic='N/A' 
          buttonName='Bill' />
      )}>
      Committee on Energy and Commerce Materials
  </h5>

  <div className='courtNewsMobile' id='houseCommitteeOnEnergyAndCommerceMaterials'></div>

  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Energy%20and%20Commerce&chamber=HOUSE', 
    'houseCommitteeOnEnergyAndCommerceMaterials', 
    'House Committee on Energy and Commerce Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>



                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnEthicsModalIsOpen(true)}>Committee on Ethics</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnEthics'></div>

                </Col>

                <Modal id='fullBillModal' show={committeeOnEthicsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnEthicsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Ethics</h3></Modal.Header>

<HouseCommitteeOnEthics/>
</Modal>



<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Ethics Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Ethics&chamber=HOUSE'
          name='Committee on Ethics Materials' 
          branch='Legislative' 
          topic='N/A' 
          buttonName='Bill' />
      )}>
      Committee on Ethics Materials
  </h5>

  <div className='courtNewsMobile' id='houseCommitteeOnEthicsMaterials'></div>

  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Ethics&chamber=HOUSE', 
    'houseCommitteeOnEthicsMaterials', 
    'House Committee on Ethics Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>


                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnFinacialServicesModalIsOpen(true)}>Committee on Financial Services</h5>
                        <div class='courtNewsMobile' id='houseCommitteeOnFinancialServices'></div>
                      



                </Col>

                <Modal id='fullBillModal' show={committeeOnFinancialServicesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnFinacialServicesModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Financial Services</h3></Modal.Header>

<HouseCommitteeOnFinacialServices/>
</Modal>


<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Financial Services Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Financial%20Services&chamber=HOUSE'
          name='Committee on Financial Services Materials' 
          branch='Legislative' 
          topic='Economy' 
          buttonName='Bill' />
      )}>
      Committee on Financial Services Materials
  </h5>

  <div className='courtNewsMobile' id='houseCommitteeOnFinancialServicesMaterials'></div>

  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Financial%20Services&chamber=HOUSE', 
    'houseCommitteeOnFinancialServicesMaterials', 
    'House Committee on Financial Services Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>



                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnForeignAffairsModalIsOpen(true)}>Committee on Foreign Affairs</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnForeignAffairs'></div>

                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=FA00', 'houseCommitteeOnForeignAffairs', 'House Committee on Foreign Affairs', 'Legislative', 'Foreign Affairs', HouseCommitteeOnForeignAffairs)}

                </Col>

                <Modal id='fullBillModal' show={committeeOnForeignAffairsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnForeignAffairsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Foreign Affairs</h3></Modal.Header>

<HouseCommitteeOnForeignAffairs/>
</Modal>


<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Foreign Affairs Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Foreign%20Affairs&chamber=HOUSE'
          name='Committee on Foreign Affairs Materials' 
          branch='Legislative' 
          topic='Foreign Affairs' 
          buttonName='Bill' />
      )}>
      Committee on Foreign Affairs Materials
  </h5>

  <div className='courtNewsMobile' id='houseCommitteeOnForeignAffairsMaterials'></div>

  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Foreign%20Affairs&chamber=HOUSE', 
    'houseCommitteeOnForeignAffairsMaterials', 
    'House Committee on Foreign Affairs Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>

                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnHomelandSecurityModalIsOpen(true)}>Committee on Homeland Security</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnHomelandSecurity'></div>
                    


                  
                </Col>

                <Modal id='fullBillModal' show={committeeOnHomelandSecurityModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnHomelandSecurityModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Homeland Security</h3></Modal.Header>

<HouseCommitteeOnHomelandSecurity/>
</Modal>




<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Homeland Security Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Homeland%20Security&chamber=HOUSE'
          name='Committee on Homeland Security Materials' 
          branch='Legislative' 
          topic='Foreign Affairs' 
          buttonName='Bill' />
      )}>
      Committee on Homeland Security Materials
  </h5>

  <div className='courtNewsMobile' id='houseCommitteeOnHomelandSecurityMaterials'></div>

  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Homeland%20Security&chamber=HOUSE', 
    'houseCommitteeOnHomelandSecurityMaterials', 
    'House Committee on Homeland Security Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>




                <Col>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnHouseAdministrationModalIsOpen(true)}>Committee on House Administration</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnHouseAdministration'></div>
                      


                     

                </Col>

                <Modal id='fullBillModal' show={committeeOnHouseAdministrationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnHouseAdministrationModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on House Administration</h3></Modal.Header>

<HouseCommitteeOnHouseAdministration/>
</Modal>



<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on House Administration Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20House%20Administration&chamber=HOUSE'
          name='Committee on House Administration Materials' 
          branch='Legislative' 
          topic='N/A' 
          buttonName='Bill' />
      )}>
      Committee on House Administration Materials
  </h5>

  <div className='courtNewsMobile' id='houseCommitteeOnHouseAdministrationMaterials'></div>

  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20House%20Administration&chamber=HOUSE', 
    'houseCommitteeOnHouseAdministrationMaterials', 
    'House Committee on House Administration Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>



<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Interstate and Foreign Commerce Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Interstate%20and%20Foreign%20Commerce&chamber=HOUSE'
          name='Committee on Interstate and Foreign Commerce Materials' 
          branch='Legislative' 
          topic='N/A' 
          buttonName='Bill' />
      )}>
      Committee on Interstate and Foreign Commerce Materials
  </h5>

  <div className='courtNewsMobile' id='houseCommitteeOnInterstateAndForeignCommerceMaterials'></div>

  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Interstate%20and%20Foreign%20Commerce&chamber=HOUSE', 
    'houseCommitteeOnInterstateAndForeignCommerceMaterials', 
    'House Committee on Interstate and Foreign Commerce Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>


                <Col>
                    
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnNaturalResourcesModalIsOpen(true)}>Committee on Natural Resources</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnNaturalResources'></div>
                  



                     

                </Col>

                <Modal id='fullBillModal' show={committeeOnNaturalResourcesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnNaturalResourcesModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Natural Resources</h3></Modal.Header>

<HouseCommitteeOnNaturalResources/>
</Modal>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Natural Resources Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Natural%20Resources&chamber=HOUSE'
          name='Committee on Natural Resources Materials' 
          branch='Legislative' 
          topic='Environment' 
          buttonName='Bill' />
      )}>
      Committee on Natural Resources Materials
  </h5>

  <div className='courtNewsMobile' id='houseCommitteeOnNaturalResourcesMaterials'></div>

  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Natural%20Resources&chamber=HOUSE', 
    'houseCommitteeOnNaturalResourcesMaterials', 
    'House Committee on Natural Resources Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>



                    <Col>
                    
                    
                    <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnOversightModalIsOpen(true)}>Committee on Oversight and Reform</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnOversightAndReform'></div>
                  



                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=GO00', 'houseCommitteeOnOversightAndReform', 'House Committee on Oversight and Reform', 'Legislative', 'N/A', HouseCommitteeOnOversightAndReform)}

        
                        </Col>

                        <Modal id='fullBillModal' show={committeeOnOversightModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnOversightModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Oversight and Reform</h3></Modal.Header>

<HouseCommitteeOnOversightAndReform/>
</Modal>


<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Oversight and Reform Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Oversight%20and%20Accountability&chamber=HOUSE'
          name='Committee on Oversight and Reform Materials' 
          branch='Legislative' 
          topic='N/A' 
          buttonName='Bill' />
      )}>
      Committee on Oversight and Reform Materials
  </h5>

  <div className='courtNewsMobile' id='houseCommitteeOnOversightAndReformMaterials'></div>

  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Oversight%20and%20Accountability&chamber=HOUSE', 
    'houseCommitteeOnOversightAndReformMaterials', 
    'House Committee on Oversight and Reform Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>


                        <Col>
                    
                    
                        <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnRulesModalIsOpen(true)} >Committee on Rules</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnRules'></div>
                      



                     
                    </Col>

                    <Modal id='fullBillModal' show={committeeOnRulesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnRulesModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Rules</h3></Modal.Header>

<HouseCommitteeOnRules/>
</Modal>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('House Committee on Rules Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Rules&chamber=HOUSE'
          name='House Committee on Rules Materials' 
          branch='Legislative' 
          topic='N/A' 
          buttonName='Bill' />
      )}>
      House Committee on Rules Materials
  </h5>

  <div className='courtNewsMobile' id='houseCommitteeOnRulesMaterials'></div>

  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Rules&chamber=HOUSE', 
    'houseCommitteeOnRulesMaterials', 
    'House Committee on Rules Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>



                {/* <Col>
                    
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnScienceAndTechModalIsOpen(true)}>Committee on Science, Space, and Technology</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnScienceSpaceAndTechnology'></div>
                       
                   

                </Col> */}

                <Modal id='fullBillModal' show={committeeOnScienceAndTechModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnScienceAndTechModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Science, Space, and Technology</h3></Modal.Header>

<HouseCommitteeOnScienceSpaceAndTechnology/>
</Modal>


<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Science, Space, and Technology Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Science,%20Space,%20and%20Technology&chamber=HOUSE'
          name='Committee on Science, Space, and Technology Materials' 
          branch='Legislative' 
          topic='Science & Tech' 
          buttonName='Bill' />
      )}>
      Committee on Science, Space, and Technology Materials
  </h5>

  <div className='courtNewsMobile' id='houseCommitteeOnScienceSpaceAndTechnologyMaterials'></div>

  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Science,%20Space,%20and%20Technology&chamber=HOUSE', 
    'houseCommitteeOnScienceSpaceAndTechnologyMaterials', 
    'House Committee on Science, Space, and Technology Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>






                <Col>
                    
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnSmallBusinessModalIsOpen(true)}>Committee on Small Business</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnSmallBusiness'></div>
           
                
                        </Col>

                        <Modal id='fullBillModal' show={committeeOnSmallBusinessModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnSmallBusinessModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Small Business</h3></Modal.Header>

<HouseCommitteeOnSmallBusiness/>
</Modal>



<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Small Business Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Small%20Business&chamber=HOUSE'
          name='Committee on Small Business Materials' 
          branch='Legislative' 
          topic='Economy' 
          buttonName='Bill' />
      )}>
      Committee on Small Business Materials
  </h5>

  <div className='courtNewsMobile' id='houseCommitteeOnSmallBusinessMaterials'></div>

  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Small%20Business&chamber=HOUSE', 
    'houseCommitteeOnSmallBusinessMaterials', 
    'House Committee on Small Business Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>



                            <Col>
                    
                            <h5 id='presidentialFeedHeadingMobile' onClick={() =>  setCommitteeOnTransportationModalIsOpen(true)}>Committee on Transportation and Infrastructure</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnTransportationAndInfrastructure'></div>
                       

                     
                          </Col>

                          <Modal id='fullBillModal' show={committeeOnTransportationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnTransportationModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Transportation and Infrastructure</h3></Modal.Header>

<HouseCommitteeOnTransportationAndInfrastructure/>
</Modal>



<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Transportation and Infrastructure Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Transportation%20and%20Infrastructure&chamber=HOUSE'
          name='Committee on Transportation and Infrastructure Materials' 
          branch='Legislative' 
          topic='Environment' 
          buttonName='Bill' />
      )}>
      Committee on Transportation and Infrastructure
  </h5>

  <div className='courtNewsMobile' id='houseCommitteeOnTransportationAndInfrastructureMaterials'></div>

  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Transportation%20and%20Infrastructure&chamber=HOUSE', 
    'houseCommitteeOnTransportationAndInfrastructureMaterials', 
    'House Committee on Transportation and Infrastructure', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>



                  <Col>
                    
                  <h5 id='presidentialFeedHeadingMobile' onClick={() => setVeteransAffairsModalIsOpen(true)}>Committee on Veterans' Affairs</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnVeteransAffairs'></div>
                       



               
            </Col>

            <Modal id='fullBillModal' show={veteransAffairsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setVeteransAffairsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Veterans' Affairs</h3></Modal.Header>

<HouseCommitteeOnVeteransAffairs/>
</Modal>






              <Col>
                    
              <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnWaysAndMeansModalIsOpen(true)}>Committee on Ways and Means</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnWaysAndMeans'></div>
                       
               
              </Col>

              <Modal id='fullBillModal' show={committeeOnWaysAndMeansModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnWaysAndMeansModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Ways and Means</h3></Modal.Header>

<HouseCommitteeOnWaysAndMeans/>
</Modal>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Ways and Means Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Ways%20and%20Means&chamber=HOUSE'
          name='Committee on Ways and Means Materials' 
          branch='Legislative' 
          topic='N/A' 
          buttonName='Bill' />
      )}>
      Committee on Ways and Means Materials
  </h5>

  <div className='courtNewsMobile' id='houseCommitteeOnWaysAndMeansMaterials'></div>

  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Ways%20and%20Means&chamber=HOUSE', 
    'houseCommitteeOnWaysAndMeansMaterials', 
    'House Committee on Ways and Means Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>



                    <Col>
                    
                    <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnTheBudgetModalIsOpen(true)}>Committee on the Budget</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnTheBudget'></div>
                       
                 
                          </Col>

                          <Modal id='fullBillModal' show={committeeOnTheBudgetModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnTheBudgetModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on the Budget</h3></Modal.Header>

<HouseCommitteeOnTheBudget/>
</Modal>
  
    
<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on the Budget Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20the%20Budget&chamber=HOUSE'
          name='Committee on the Budget Materials' 
          branch='Legislative' 
          topic='Economy' 
          buttonName='Bill' />
      )}>
      Committee on the Budget Materials
  </h5>

  <div className='courtNewsMobile' id='houseCommitteeOnTheBudgetMaterials'></div>

  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20the%20Budget&chamber=HOUSE', 
    'houseCommitteeOnTheBudgetMaterials', 
    'House Committee on the Budget Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>

  

                          <Col>
                    
                          <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnTheJudiciaryModalIsOpen(true)}>Committee on the Judiciary</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnTheJudiciary'></div>
                      

                 
      
                          </Col>

                          <Modal id='fullBillModal' show={committeeOnTheJudiciaryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnTheJudiciaryModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on the Judiciary</h3></Modal.Header>

<HouseCommitteeOnTheJudiciary/>
</Modal>
  



<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on the Judiciary Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20the%20Judiciary&chamber=HOUSE'
          name='Committee on the Judiciary Materials' 
          branch='Legislative' 
          topic='N/A' 
          buttonName='Bill' />
      )}>
      Committee on the Judiciary Materials
  </h5>

  <div className='courtNewsMobile' id='houseCommitteeOnTheJudiciaryMaterials'></div>

  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20the%20Judiciary&chamber=HOUSE', 
    'houseCommitteeOnTheJudiciaryMaterials', 
    'House Committee on the Judiciary Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>



                          {/* <Col>
                          <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnIntelligenceModalIsOpen(true)}>Permanent Select Committee on Intelligence</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnIntelligence'></div>
                      


      
                          </Col> */}

                          <Modal id='fullBillModal' show={committeeOnIntelligenceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnIntelligenceModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Permanent Select Committee on Intelligence</h3></Modal.Header>

<HouseCommitteeOnIntelligence/>
</Modal>


<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Permanent Select Committee on Intelligence Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Permanent%20Select%20Committee%20on%20Intelligence&chamber=HOUSE'
          name='Permanent Select Committee on Intelligence Materials' 
          branch='Legislative' 
          topic='N/A' 
          buttonName='Bill' />
      )}>
      Permanent Select Committee on Intelligence Materials
  </h5>

  <div className='courtNewsMobile' id='houseCommitteeOnIntelligenceMaterials'></div>

  {CreateFeedAPICommittee(
    '&skip=0&committee=Permanent%20Select%20Committee%20on%20Intelligence&chamber=HOUSE', 
    'houseCommitteeOnIntelligenceMaterials', 
    'Permanent Select Committee on Intelligence Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>



{/* 
                          <Col>
                          <h5 id='presidentialFeedHeadingMobile' onClick={() => setCapitolCommitteeModalIsOpen(true)}>Select Committee to Investigate the January 6th Attack on the United States Capitol</h5>
                        <div class='courtNewsMobile'id='selectCommitteeToInvestigate'></div>
                      



      
                          </Col>

                          <Modal id='fullBillModal' show={capitolCommitteeModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCapitolCommitteeModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Select Committee to Investigate the January 6th Attack on the United States Capitol</h3></Modal.Header>

<HouseCommitteeToInvestigateTheCapitol/>
</Modal>


<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Select Committee to Investigate the January 6th Attack on the United States Capitol Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Select%20Committee%20to%20Investigate%20the%20January%206th%20Attack%20on%20the%20United%20States%20Capitol&chamber=HOUSE'
          name='Select Committee to Investigate the January 6th Attack on the United States Capitol Materials' 
          branch='Legislative' 
          topic='N/A' 
          buttonName='Bill' />
      )}>
      Select Committee to Investigate the January 6th Attack on the United States Capitol Materials
  </h5>

  <div className='courtNewsMobile' id='selectCommitteeToInvestigateMaterials'></div>

  {CreateFeedAPICommittee(
    '&skip=0&committee=Select%20Committee%20to%20Investigate%20the%20January%206th%20Attack%20on%20the%20United%20States%20Capitol&chamber=HOUSE', 
    'selectCommitteeToInvestigateMaterials', 
    'Select Committee to Investigate the January 6th Attack on the United States Capitol Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}

  <Modal id='fullBillModal' show={capitolMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCapitolMaterialsModalIsOpen(false)}>
    <Modal.Header id='billModalHeader' closeButton>
      <h3>Select Committee to Investigate the January 6th Attack on the United States Capitol Materials</h3>
    </Modal.Header>
    <HouseCommitteeToInvestigateTheCapitolMaterials />
  </Modal>
</Col> */}


<Col>



</Col>



       <br></br>   


            <br></br><br></br><br></br><br></br><br></br><br></br>
            
            <Navbar id='navbarMobileBottom' fixed="bottom">    

<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
              
            </div>

</Navbar>
          </div>

          </>
  )
}
 }



  






export default HouseMobile;

