import React, { useState } from 'react';
import DesktopNavbar from '../DesktopNavbar';
import './Learn.css';

const quizData = {
  quiz: [
    {
      question: "What is the primary function of a state's legislative branch?",
      options: [
        "A) Enforce state laws",
        "B) Interpret state laws",
        "C) Create state laws",
        "D) Oversee federal agencies"
      ],
      correct_answer: "C"
    },
    {
      question: "Which amendment to the U.S. Constitution reserves powers to the states?",
      options: [
        "A) Ninth Amendment",
        "B) Tenth Amendment",
        "C) Fourteenth Amendment",
        "D) First Amendment"
      ],
      correct_answer: "B"
    },
    {
      question: "Which of the following is typically NOT an elected executive position in state government?",
      options: [
        "A) Governor",
        "B) Secretary of State",
        "C) Attorney General",
        "D) Chief Justice of the State Supreme Court"
      ],
      correct_answer: "D"
    },
    {
      question: "What is the name of the only state with a unicameral legislature?",
      options: [
        "A) Texas",
        "B) California",
        "C) Nebraska",
        "D) New York"
      ],
      correct_answer: "C"
    },
    {
      question: "State constitutions are generally __________ than the U.S. Constitution.",
      options: [
        "A) Shorter and less detailed",
        "B) Longer and more detailed",
        "C) The same length",
        "D) Nonexistent"
      ],
      correct_answer: "B"
    },
    {
      question: "What is the term for powers shared by both state and federal governments?",
      options: [
        "A) Reserved powers",
        "B) Concurrent powers",
        "C) Enumerated powers",
        "D) Implied powers"
      ],
      correct_answer: "B"
    },
    {
      question: "Which of the following is an example of a state police power?",
      options: [
        "A) Coining money",
        "B) Declaring war",
        "C) Regulating public health and safety",
        "D) Negotiating treaties with foreign nations"
      ],
      correct_answer: "C"
    },
    {
      question: "Who is typically responsible for overseeing state elections and maintaining public records?",
      options: [
        "A) State Treasurer",
        "B) Attorney General",
        "C) Secretary of State",
        "D) Lieutenant Governor"
      ],
      correct_answer: "C"
    },
    {
      question: "In most states, how are amendments to the state constitution ratified?",
      options: [
        "A) By the governor's approval",
        "B) By a two-thirds vote in the legislature alone",
        "C) Through approval by the state's voters in a referendum",
        "D) By the state supreme court's decision"
      ],
      correct_answer: "C"
    },
    {
      question: "What is the principle called when local governments are granted the authority to govern themselves with little state interference?",
      options: [
        "A) Federalism",
        "B) Home Rule",
        "C) Supremacy Clause",
        "D) Eminent Domain"
      ],
      correct_answer: "B"
    }
  ],
  flashcards: [
    {
      question: "What does the Tenth Amendment to the U.S. Constitution state?",
      answer: "It reserves powers not delegated to the federal government to the states or the people."
    },
    {
      question: "What are the three branches of state government?",
      answer: "Executive, Legislative, and Judicial branches."
    },
    {
      question: "Who is the chief executive officer of a state?",
      answer: "The Governor."
    },
    {
      question: "What is the primary role of a state's legislative branch?",
      answer: "To create and pass state laws."
    },
    {
      question: "What is the only state with a unicameral legislature?",
      answer: "Nebraska."
    },
    {
      question: "What is 'Home Rule' in the context of state and local government?",
      answer: "The authority granted by a state to a local government to govern itself with minimal state interference."
    },
    {
      question: "What is the difference between general jurisdiction and specialized courts at the state level?",
      answer: "General jurisdiction courts handle a wide range of cases, while specialized courts focus on specific types of cases like family or probate matters."
    },
    {
      question: "How are most state judges selected?",
      answer: "Through elections, appointments, or merit selection systems, varying by state."
    },
    {
      question: "What are 'police powers' in state government?",
      answer: "The inherent authority of states to enact laws protecting public health, safety, morals, and welfare."
    },
    {
      question: "What is a 'state constitution'?",
      answer: "A legal document that outlines the structure, powers, and functions of a state government."
    },
    {
      question: "What is 'fiscal federalism'?",
      answer: "The financial relationship between the federal and state governments, including grants and funding."
    },
    {
      question: "Who is typically responsible for legal matters and representing the state in court?",
      answer: "The State Attorney General."
    },
    {
      question: "What is the 'balanced budget requirement' in most states?",
      answer: "A mandate that the state cannot spend more than its income."
    },
    {
      question: "What are 'unfunded mandates'?",
      answer: "Federal requirements imposed on states without accompanying federal funding."
    },
    {
      question: "What is the role of a 'State Treasurer' or 'Comptroller'?",
      answer: "To manage state finances, including revenue collection and investments."
    },
    {
      term: "Concurrent Powers",
      definition: "Powers shared by both state and federal governments, such as the power to tax."
    },
    {
      term: "Reserved Powers",
      definition: "Powers not delegated to the federal government, reserved for the states."
    },
    {
      term: "Initiative",
      definition: "A process that allows citizens to propose legislation or constitutional amendments through petition."
    },
    {
      term: "Referendum",
      definition: "A direct vote by the electorate on a particular proposal or issue."
    },
    {
      term: "Block Grants",
      definition: "Federal funds provided to states with general spending guidelines."
    },
    {
      term: "Categorical Grants",
      definition: "Federal funds provided to states for a specific purpose, often with strict limitations."
    },
    {
      term: "Line-Item Veto",
      definition: "The power of a governor to veto specific provisions of a bill without vetoing the entire bill."
    },
    {
      term: "State Supreme Court",
      definition: "The highest judicial authority within a state's court system."
    },
    {
      term: "Merit Selection (Missouri Plan)",
      definition: "A method of selecting judges that combines appointment and election."
    },
    {
      term: "Police Powers",
      definition: "The capacity of a state to regulate behaviors and enforce order within its territory for the betterment of health, safety, morals, and general welfare."
    }
  ]
};

const LearnJudicialBranch = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showQuizResult, setShowQuizResult] = useState(false);
  const [score, setScore] = useState(0);
  const [showFlashcardAnswer, setShowFlashcardAnswer] = useState(false);
  const [currentFlashcard, setCurrentFlashcard] = useState(0);

  const handleAnswerOptionClick = (selectedOption) => {
    const correctOption = quizData.quiz[currentQuestion].correct_answer;
    if (selectedOption.charAt(0) === correctOption) {
      setScore(score + 1);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizData.quiz.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowQuizResult(true);
    }
  };

  const handleFlashcardFlip = () => {
    setShowFlashcardAnswer(!showFlashcardAnswer);
  };

  const handleNextFlashcard = () => {
    setShowFlashcardAnswer(false);
    const nextFlashcard = currentFlashcard + 1;
    if (nextFlashcard < quizData.flashcards.length) {
      setCurrentFlashcard(nextFlashcard);
    } else {
      setCurrentFlashcard(0);
    }
  };

  return (
    <div id='homebg'>

    <DesktopNavbar/>
    <div className="report-container">
      <h1 className="title">The Judicial Branch of the United States Government</h1>

      {/* Introduction */}
      <h2 className="section-title">Introduction</h2>
      <p>
        The Judicial Branch of the United States government is the system of federal courts and judges that interprets and applies the law in the name of the state. Established under Article III of the U.S. Constitution, the Judicial Branch is one of the three pillars of American democracy, alongside the Legislative and Executive branches. Its primary role is to ensure that the American legal system is fair, impartial, and consistent with the Constitution. This comprehensive report explores the structure, functions, powers, and significance of the Judicial Branch, highlighting its critical role in the federal government.
      </p>

      {/* Structure of the Judicial Branch */}
      <h2 className="section-title">Structure of the Judicial Branch</h2>

      <h3>Constitutional Foundation</h3>
      <p>
        Article III, Section 1 of the U.S. Constitution establishes the Judicial Branch:
      </p>
      <blockquote>
        “The judicial Power of the United States shall be vested in one supreme Court, and in such inferior Courts as the Congress may from time to time ordain and establish.”
      </blockquote>

      <h3>Hierarchy of Federal Courts</h3>
      <p>
        The federal court system is structured in three main tiers:
      </p>
      <ol>
        <li>U.S. Supreme Court</li>
        <li>U.S. Courts of Appeals (Circuit Courts)</li>
        <li>U.S. District Courts</li>
      </ol>
      <p>Additionally, there are specialized courts with jurisdiction over specific types of cases.</p>

      <h4>1. U.S. Supreme Court</h4>
      <ul>
        <li><strong>Composition:</strong> One Chief Justice and eight Associate Justices, totaling nine Justices.</li>
        <li><strong>Appointment:</strong> Nominated by the President and confirmed by the Senate.</li>
        <li><strong>Tenure:</strong> Life terms under “good Behaviour,” as specified in Article III, Section 1.</li>
        <li><strong>Jurisdiction:</strong>
          <ul>
            <li><strong>Original Jurisdiction:</strong> Limited cases such as those involving ambassadors or when a state is a party.</li>
            <li><strong>Appellate Jurisdiction:</strong> Reviews decisions from lower federal and state courts involving federal law or constitutional issues.</li>
          </ul>
        </li>
      </ul>

      <h4>2. U.S. Courts of Appeals</h4>
      <ul>
        <li><strong>Number:</strong> 13 circuits, including 12 regional circuits and one Federal Circuit.</li>
        <li><strong>Function:</strong> Intermediate appellate courts reviewing decisions from district courts within their circuit.</li>
        <li><strong>Panels:</strong> Typically, cases are heard by a panel of three judges.</li>
      </ul>

      <h4>3. U.S. District Courts</h4>
      <ul>
        <li><strong>Number:</strong> 94 judicial districts across the states and territories.</li>
        <li><strong>Function:</strong> General trial courts handling civil and criminal cases under federal jurisdiction.</li>
        <li><strong>Magistrate Judges:</strong> Assist district judges in preparing cases for trial.</li>
      </ul>

      <h4>Specialized Courts</h4>
      <ul>
        <li><strong>U.S. Bankruptcy Courts:</strong> Handle bankruptcy cases.</li>
        <li><strong>U.S. Court of International Trade:</strong> Deals with international trade and customs issues.</li>
        <li><strong>U.S. Court of Federal Claims:</strong> Addresses monetary claims against the U.S. government.</li>
        <li><strong>Military Courts:</strong> Governed by the Uniform Code of Military Justice.</li>
        <li><strong>Administrative Courts:</strong> Oversee specific administrative areas (e.g., Tax Court).</li>
      </ul>

      {/* Powers and Responsibilities of the Judicial Branch */}
      <h2 className="section-title">Powers and Responsibilities of the Judicial Branch</h2>

      <h3>Judicial Review</h3>
      <p><strong>Definition:</strong> The power of the courts to examine and invalidate legislative and executive actions that are contrary to the Constitution.</p>
      <p><strong>Established by:</strong> Marbury v. Madison (1803)</p>
      <ul>
        <li><strong>Significance:</strong> Chief Justice John Marshall asserted the authority of the Supreme Court to interpret the Constitution and strike down unconstitutional laws.</li>
        <li><strong>Quote:</strong> “It is emphatically the province and duty of the Judicial Department to say what the law is.”</li>
      </ul>

      <h3>Interpretation of Laws</h3>
      <ul>
        <li><strong>Statutory Interpretation:</strong> Courts interpret the meaning and application of federal statutes.</li>
        <li><strong>Constitutional Interpretation:</strong> Determining the constitutionality of laws and government actions.</li>
        <li><strong>Precedent (Stare Decisis):</strong> Courts follow previous judicial decisions to ensure consistency and predictability.</li>
      </ul>

      <h3>Types of Jurisdiction</h3>

      <h4>Original Jurisdiction</h4>
      <ul>
        <li><strong>Definition:</strong> The authority to hear a case first, rather than on appeal.</li>
        <li><strong>Supreme Court Original Jurisdiction:</strong> Limited to cases involving ambassadors, public ministers, consuls, and where a state is a party (Article III, Section 2).</li>
      </ul>

      <h4>Appellate Jurisdiction</h4>
      <ul>
        <li><strong>Definition:</strong> Authority to review and revise the decision of a lower court.</li>
        <li><strong>Supreme Court Appellate Jurisdiction:</strong> Extends to all federal cases and those involving federal law in state courts, subject to Congressional regulations.</li>
      </ul>

      <h3>Case Selection Process</h3>
      <ul>
        <li><strong>Writ of Certiorari:</strong> A petition for the Supreme Court to review a case.</li>
        <li><strong>Rule of Four:</strong> At least four Justices must agree to grant certiorari.</li>
        <li><strong>Factors Influencing Review:</strong>
          <ul>
            <li>Conflicts among lower courts.</li>
            <li>Important federal or constitutional questions.</li>
            <li>Significant legal principles.</li>
          </ul>
        </li>
      </ul>

      <h3>The Judicial Process</h3>

      <h4>Trial Courts (District Courts)</h4>
      <ul>
        <li><strong>Function:</strong> Conduct trials, evaluate evidence, and render verdicts.</li>
        <li><strong>Procedures:</strong>
          <ul>
            <li><strong>Civil Cases:</strong> Disputes between parties over legal rights and obligations.</li>
            <li><strong>Criminal Cases:</strong> Prosecution of individuals accused of federal crimes.</li>
          </ul>
        </li>
        <li><strong>Participants:</strong>
          <ul>
            <li><strong>Judge:</strong> Presides over the court proceedings.</li>
            <li><strong>Jury:</strong> Determines facts in a case (in jury trials).</li>
            <li><strong>Attorneys:</strong> Represent the parties involved.</li>
          </ul>
        </li>
      </ul>

      <h4>Appellate Courts (Courts of Appeals)</h4>
      <ul>
        <li><strong>Function:</strong> Review decisions from district courts for legal errors.</li>
        <li><strong>Process:</strong>
          <ul>
            <li><strong>Briefs:</strong> Written arguments submitted by both sides.</li>
            <li><strong>Oral Arguments:</strong> Opportunity for attorneys to present their case and answer judges’ questions.</li>
            <li><strong>Decisions:</strong>
              <ul>
                <li><strong>Affirm:</strong> Uphold the lower court’s decision.</li>
                <li><strong>Reverse:</strong> Overturn the decision.</li>
                <li><strong>Remand:</strong> Send the case back to the lower court for further proceedings.</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <h4>Supreme Court Procedures</h4>
      <ul>
        <li><strong>Case Review:</strong>
          <ul>
            <li><strong>Certiorari Granted:</strong> Case is placed on the docket.</li>
            <li><strong>Briefing:</strong> Parties submit detailed briefs; amicus curiae briefs may be filed by interested third parties.</li>
            <li><strong>Oral Arguments:</strong> Each side typically has 30 minutes to present.</li>
            <li><strong>Conferences:</strong> Justices meet privately to discuss and vote on cases.</li>
          </ul>
        </li>
        <li><strong>Decision-Making:</strong>
          <ul>
            <li><strong>Majority Opinion:</strong> Reflects the views of the majority of Justices; sets binding precedent.</li>
            <li><strong>Concurring Opinion:</strong> Agrees with the outcome but offers different reasoning.</li>
            <li><strong>Dissenting Opinion:</strong> Disagrees with the majority decision.</li>
            <li><strong>Per Curiam Opinion:</strong> Unsigned opinion representing the Court as a whole.</li>
          </ul>
        </li>
      </ul>

      <h3>Judicial Philosophy and Interpretation</h3>
      <h4>Originalism</h4>
      <ul>
        <li><strong>Definition:</strong> Interpreting the Constitution based on the original understanding at the time it was adopted.</li>
        <li><strong>Advocates:</strong> Justices like Antonin Scalia.</li>
        <li><strong>Approach:</strong> Emphasizes the text’s original meaning.</li>
      </ul>

      <h4>Living Constitution</h4>
      <ul>
        <li><strong>Definition:</strong> The Constitution is a dynamic document that adapts to contemporary societal needs.</li>
        <li><strong>Advocates:</strong> Justices like Stephen Breyer.</li>
        <li><strong>Approach:</strong> Considers the broader purpose and consequences of interpretation.</li>
      </ul>

      <h4>Textualism</h4>
      <ul>
        <li><strong>Definition:</strong> Focus on the plain meaning of the legal text.</li>
        <li><strong>Application:</strong> Used in statutory interpretation.</li>
      </ul>

      <h4>Judicial Activism vs. Judicial Restraint</h4>
      <ul>
        <li><strong>Judicial Activism:</strong> Courts should actively interpret the Constitution to address social issues.</li>
        <li><strong>Judicial Restraint:</strong> Courts should defer to the elected branches and avoid making policy decisions.</li>
      </ul>

      <h3>Checks and Balances on the Judicial Branch</h3>
      <h4>Legislative Branch</h4>
      <ul>
        <li><strong>Confirmation of Judges:</strong> Senate confirms federal judges nominated by the President.</li>
        <li><strong>Impeachment:</strong> Congress can remove federal judges for misconduct (Article II, Section 4).</li>
        <li><strong>Court Jurisdiction:</strong> Congress can establish and regulate inferior courts and adjust the Supreme Court’s appellate jurisdiction.</li>
        <li><strong>Constitutional Amendments:</strong> Can overturn judicial decisions by amending the Constitution.</li>
      </ul>

      <h4>Executive Branch</h4>
      <ul>
        <li><strong>Judicial Appointments:</strong> President nominates federal judges.</li>
        <li><strong>Enforcement of Decisions:</strong> Executive branch is responsible for enforcing court rulings.</li>
        <li><strong>Pardons:</strong> President can grant clemency for federal offenses, overriding judicial sentences.</li>
      </ul>

      <h4>Self-Imposed Constraints</h4>
      <ul>
        <li><strong>Justiciability Doctrines:</strong> Courts avoid cases that are not appropriate for judicial resolution.</li>
        <li><strong>Standing:</strong> Parties must have a significant stake in the outcome.</li>
        <li><strong>Mootness:</strong> Courts will not decide cases where the issue has been resolved.</li>
        <li><strong>Ripeness:</strong> Cases must involve actual, not hypothetical, disputes.</li>
        <li><strong>Political Questions:</strong> Courts avoid issues better resolved by other branches.</li>
      </ul>

      <h3>Judicial Independence and Accountability</h3>
      <h4>Life Tenure</h4>
      <ul>
        <li><strong>Purpose:</strong> Protect judges from political pressures, ensuring impartiality.</li>
        <li><strong>Constitutional Basis:</strong> Article III, Section 1.</li>
      </ul>

      <h4>Salaries</h4>
      <ul>
        <li><strong>Protection:</strong> Judges’ salaries cannot be diminished during their tenure (Article III, Section 1).</li>
        <li><strong>Reason:</strong> Prevent financial manipulation as a means of influence.</li>
      </ul>

      <h4>Code of Conduct</h4>
      <ul>
        <li><strong>Ethical Standards:</strong> Federal judges adhere to codes of conduct promoting integrity and independence.</li>
        <li><strong>Recusal:</strong> Judges must disqualify themselves from cases where they have a conflict of interest.</li>
      </ul>

      <h3>Role in Federalism</h3>
      <h4>Dual Court System</h4>
      <ul>
        <li><strong>Federal Courts:</strong> Handle cases involving federal law and constitutional issues.</li>
        <li><strong>State Courts:</strong> Address matters under state law.</li>
      </ul>

      <h4>Supremacy Clause</h4>
      <ul>
        <li><strong>Article VI, Clause 2:</strong> Establishes the Constitution and federal laws as the supreme law of the land.</li>
        <li><strong>Implication:</strong> State courts must adhere to federal law and constitutional interpretations.</li>
      </ul>

      <h4>Appeals from State Courts</h4>
      <ul>
        <li><strong>Process:</strong> Cases involving federal issues can be appealed from state supreme courts to the U.S. Supreme Court.</li>
        <li><strong>Purpose:</strong> Ensure uniformity in federal law application.</li>
      </ul>

      <h3>Significant Supreme Court Cases</h3>
      <h4>Marbury v. Madison (1803)</h4>
      <ul>
        <li><strong>Established:</strong> Judicial review.</li>
        <li><strong>Impact:</strong> Defined the judiciary’s role as interpreter of the Constitution.</li>
      </ul>

      <h4>McCulloch v. Maryland (1819)</h4>
      <ul>
        <li><strong>Issue:</strong> Federal power vs. state power.</li>
        <li><strong>Holding:</strong> Affirmed federal supremacy and implied powers under the Necessary and Proper Clause.</li>
      </ul>

      <h4>Brown v. Board of Education (1954)</h4>
      <ul>
        <li><strong>Issue:</strong> Racial segregation in public schools.</li>
        <li><strong>Holding:</strong> Declared segregation unconstitutional, overturning “separate but equal.”</li>
      </ul>

      <h4>Miranda v. Arizona (1966)</h4>
      <ul>
        <li><strong>Issue:</strong> Rights of the accused.</li>
        <li><strong>Holding:</strong> Established Miranda rights, requiring police to inform suspects of their rights.</li>
      </ul>

      <h4>Roe v. Wade (1973)</h4>
      <ul>
        <li><strong>Issue:</strong> Abortion rights.</li>
        <li><strong>Holding:</strong> Recognized a woman’s right to choose under the right to privacy (Note: Overturned by Dobbs v. Jackson Women’s Health Organization in 2022).</li>
      </ul>

      <h4>Citizens United v. Federal Election Commission (2010)</h4>
      <ul>
        <li><strong>Issue:</strong> Campaign finance.</li>
        <li><strong>Holding:</strong> Corporations and unions can spend unlimited funds on political advocacy.</li>
      </ul>

      <h4>Obergefell v. Hodges (2015)</h4>
      <ul>
        <li><strong>Issue:</strong> Same-sex marriage.</li>
        <li><strong>Holding:</strong> Legalized same-sex marriage nationwide under the Equal Protection Clause.</li>
      </ul>

      {/* Challenges and Criticisms */}
      <h2 className="section-title">Challenges and Criticisms</h2>

      <h3>Judicial Activism Concerns</h3>
      <ul>
        <li><strong>Criticism:</strong> Some argue courts overstep by making policy decisions.</li>
        <li><strong>Response:</strong> Others assert courts must protect minority rights and uphold constitutional principles.</li>
      </ul>

      <h3>Appointment Process and Partisanship</h3>
      <ul>
        <li><strong>Contention:</strong> Judicial nominations have become highly politicized.</li>
        <li><strong>Impact:</strong> Raises questions about impartiality and independence.</li>
      </ul>

      <h3>Life Tenure Debate</h3>
      <ul>
        <li><strong>Argument:</strong> Lifetime appointments may lead to lack of accountability.</li>
        <li><strong>Proposals:</strong> Suggestions include term limits or mandatory retirement ages.</li>
      </ul>

      <h3>Access to Justice</h3>
      <ul>
        <li><strong>Barriers:</strong> High costs and complex procedures can limit access.</li>
        <li><strong>Efforts:</strong> Legal aid programs and pro bono services aim to address disparities.</li>
      </ul>

      <h3>Technological and Modern Developments</h3>

      <h4>Use of Technology in Courts</h4>
      <ul>
        <li><strong>Electronic Filing:</strong> Courts have implemented electronic systems for filing documents.</li>
        <li><strong>Virtual Hearings:</strong> Especially during the COVID-19 pandemic, courts have used video conferencing.</li>
        <li><strong>Public Access:</strong> Online databases provide access to court opinions and records.</li>
      </ul>

      <h4>Privacy and Surveillance Issues</h4>
      <ul>
        <li><strong>Emerging Legal Questions:</strong> Courts face cases involving digital privacy, cybersecurity, and data protection.</li>
        <li><strong>Balancing Act:</strong> Protecting civil liberties while addressing national security concerns.</li>
      </ul>

      <h3>Judicial Administration and Support</h3>

      <h4>Administrative Office of the U.S. Courts</h4>
      <ul>
        <li><strong>Function:</strong> Provides administrative support and policy guidance to federal courts.</li>
        <li><strong>Responsibilities:</strong>
          <ul>
            <li>Budget management.</li>
            <li>Judicial statistics and reporting.</li>
            <li>Court personnel policies.</li>
          </ul>
        </li>
      </ul>

      <h4>Federal Judicial Center</h4>
      <ul>
        <li><strong>Role:</strong> Research and education agency for the federal judiciary.</li>
        <li><strong>Activities:</strong>
          <ul>
            <li>Judicial education programs.</li>
            <li>Research on court operations and procedures.</li>
          </ul>
        </li>
      </ul>

      <h4>Judicial Conference of the United States</h4>
      <ul>
        <li><strong>Composition:</strong> Policy-making body of the federal judiciary, including chief judges.</li>
        <li><strong>Purpose:</strong> Addresses administrative and policy issues affecting the courts.</li>
      </ul>

      <h3>The Role of Attorneys and the Bar</h3>
      <h4>U.S. Department of Justice (DOJ)</h4>
      <ul>
        <li><strong>Function:</strong> Represents the United States in legal matters.</li>
        <li><strong>Components:</strong>
          <ul>
            <li><strong>Solicitor General:</strong> Represents the federal government before the Supreme Court.</li>
            <li><strong>U.S. Attorneys:</strong> Prosecute federal crimes and represent the government in civil cases at the district level.</li>
          </ul>
        </li>
      </ul>

      <h4>Public Defenders and Legal Representation</h4>
      <ul>
        <li><strong>Right to Counsel:</strong> Guaranteed in criminal cases under the Sixth Amendment.</li>
        <li><strong>Public Defender System:</strong> Provides legal representation to indigent defendants.</li>
      </ul>

      <h4>Legal Profession Regulation</h4>
      <ul>
        <li><strong>Bar Associations:</strong> Regulate attorney conduct and set ethical standards.</li>
        <li><strong>Continuing Legal Education:</strong> Required for attorneys to maintain competence.</li>
      </ul>

      <h3>International Aspects</h3>
      <h4>Extraterritorial Jurisdiction</h4>
      <ul>
        <li><strong>Definition:</strong> The application of U.S. laws to conduct outside U.S. borders.</li>
        <li><strong>Cases:</strong> Involve issues like international terrorism, human rights violations, and global commerce.</li>
      </ul>

      <h4>Treaty Interpretation</h4>
      <ul>
        <li><strong>Role:</strong> Courts interpret treaties as part of federal law.</li>
        <li><strong>Supremacy:</strong> Treaties are the “supreme Law of the Land” under Article VI.</li>
      </ul>

      <h4>Interaction with International Courts</h4>
      <ul>
        <li><strong>Recognition of Foreign Judgments:</strong> U.S. courts may enforce foreign court decisions under certain conditions.</li>
        <li><strong>Limitations:</strong> U.S. courts maintain sovereignty and may decline enforcement if it violates public policy.</li>
      </ul>

            {/* Flashcards Section */}
            <h2 className="section-title">Flashcards</h2>
      <div className="flashcard-section">
        <div className="flashcard" onClick={handleFlashcardFlip}>
          <div className="flashcard-content">
            {showFlashcardAnswer ? (
              <p>{quizData.flashcards[currentFlashcard].answer}</p>
            ) : (
              <p>{quizData.flashcards[currentFlashcard].question}</p>
            )}
          </div>
        </div>
        <button className="btn" onClick={handleNextFlashcard}>
          Next Flashcard
        </button>
      </div>

      {/* Quiz Section */}
      <h2 className="section-title">Quiz</h2>
      <div className="quiz-section">
        {showQuizResult ? (
          <div className="quiz-result">
            <h3>
              Your Score: {score} out of {quizData.quiz.length}
            </h3>
            <button
              className="btn"
              onClick={() => {
                setCurrentQuestion(0);
                setScore(0);
                setShowQuizResult(false);
              }}
            >
              Retake Quiz
            </button>
          </div>
        ) : (
          <div className="quiz-question">
            <h3>
              Question {currentQuestion + 1} of {quizData.quiz.length}
            </h3>
            <p>{quizData.quiz[currentQuestion].question}</p>
            <ul>
              {quizData.quiz[currentQuestion].options.map((option, index) => (
                <li key={index}>
                  <button
                    onClick={() => handleAnswerOptionClick(option)}
                    className="quiz-option"
                  >
                    {option}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>


    </div>
    </div>
  );
};

export default LearnJudicialBranch;