import React, { useState, useEffect } from 'react';
import { Spinner, Table } from 'react-bootstrap';
require('dotenv').config();
const apiKey = process.env.REACT_APP_API_KEY;

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  const day = utcDate.getDate();
  const month = utcDate.getMonth() + 1;
  const year = utcDate.getFullYear();
  return `${month}-${day}-${year}`;
}

function CandidateJSONTableMobile({ id }) {
  const [candidateData, setCandidateData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiKey}`,
        },
      };

      try {
        const response = await fetch(
          `https://api.govglance.org/elections/election_candidates_financials/?candidate_id=${id}&limit=1&skip=0`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        setCandidateData(responseData[0]); // Assuming data is returned as an array
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  if (!candidateData) {
    return <Spinner animation="grow" variant="light" />;
  }

  return (
    <div>
      {/* Total Raised Table */}
      <h4 style={{ color: 'white' }}>Total Raised</h4>
      <Table striped bordered hover size="sm" style={{ color: 'white', borderColor: 'white' }}>
        <tbody>
          <tr>
            <td style={{ color: 'white', borderColor: 'white' }}>
              <strong>TOTAL RECEIPTS:</strong>
            </td>
            <td style={{ color: 'white', borderColor: 'white' }}>
              ${candidateData.receipts}
            </td>
          </tr>
          <tr>
            <td style={{ color: 'white', borderColor: 'white' }}>
              <strong>TOTAL CONTRIBUTIONS:</strong>
            </td>
            <td style={{ color: 'white', borderColor: 'white' }}>
              ${candidateData.contributions}
            </td>
          </tr>
          <tr>
            <td style={{ color: 'white', borderColor: 'white' }}>
              <strong>Itemized Individual Contributions:</strong>
            </td>
            <td style={{ color: 'white', borderColor: 'white' }}>
              ${candidateData.individual_itemized_contributions}
            </td>
          </tr>
          <tr>
            <td style={{ color: 'white', borderColor: 'white' }}>
              <strong>Unitemized Individual Contributions:</strong>
            </td>
            <td style={{ color: 'white', borderColor: 'white' }}>
              ${candidateData.individual_unitemized_contributions}
            </td>
          </tr>
          <tr>
            <td style={{ color: 'white', borderColor: 'white' }}>
              <strong>Party Committee Contributions:</strong>
            </td>
            <td style={{ color: 'white', borderColor: 'white' }}>
              ${candidateData.political_party_committee_contributions || '0.00'}
            </td>
          </tr>
          <tr>
            <td style={{ color: 'white', borderColor: 'white' }}>
              <strong>Other Committee Contributions:</strong>
            </td>
            <td style={{ color: 'white', borderColor: 'white' }}>
              ${candidateData.other_political_committee_contributions || '0.00'}
            </td>
          </tr>
          <tr>
            <td style={{ color: 'white', borderColor: 'white' }}>
              <strong>Candidate Contributions:</strong>
            </td>
            <td style={{ color: 'white', borderColor: 'white' }}>
              ${candidateData.candidate_contribution || '0.00'}
            </td>
          </tr>
        </tbody>
      </Table>

      {/* Total Spent Table */}
      <h4 style={{ color: 'white' }}>Total Spent</h4>
      <Table striped bordered hover size="sm" style={{ color: 'white', borderColor: 'white' }}>
        <tbody>
          <tr>
            <td style={{ color: 'white', borderColor: 'white' }}>
              <strong>TOTAL DISBURSEMENTS:</strong>
            </td>
            <td style={{ color: 'white', borderColor: 'white' }}>
              ${candidateData.disbursements}
            </td>
          </tr>
          <tr>
            <td style={{ color: 'white', borderColor: 'white' }}>
              <strong>OPERATING EXPENDITURES:</strong>
            </td>
            <td style={{ color: 'white', borderColor: 'white' }}>
              ${candidateData.operating_expenditures}
            </td>
          </tr>
          <tr>
            <td style={{ color: 'white', borderColor: 'white' }}>
              <strong>TRANSFERS TO OTHER AUTHORIZED COMMITTEES:</strong>
            </td>
            <td style={{ color: 'white', borderColor: 'white' }}>
              ${candidateData.transfers_to_other_authorized_committee}
            </td>
          </tr>
          <tr>
            <td style={{ color: 'white', borderColor: 'white' }}>
              <strong>FUNDRAISING:</strong>
            </td>
            <td style={{ color: 'white', borderColor: 'white' }}>
              ${candidateData.fundraising_disbursements || '0.00'}
            </td>
          </tr>
          <tr>
            <td style={{ color: 'white', borderColor: 'white' }}>
              <strong>EXEMPT LEGAL AND ACCOUNTING:</strong>
            </td>
            <td style={{ color: 'white', borderColor: 'white' }}>
              ${candidateData.exempt_legal_accounting_disbursement || '0.00'}
            </td>
          </tr>
          <tr>
            <td style={{ color: 'white', borderColor: 'white' }}>
              <strong>TOTAL LOAN REPAYMENTS MADE:</strong>
            </td>
            <td style={{ color: 'white', borderColor: 'white' }}>
              ${candidateData.loan_repayments_made || '0.00'}
            </td>
          </tr>
          <tr>
            <td style={{ color: 'white', borderColor: 'white' }}>
              <strong>Individual Refunds:</strong>
            </td>
            <td style={{ color: 'white', borderColor: 'white' }}>
              ${candidateData.refunded_individual_contributions}
            </td>
          </tr>
          <tr>
            <td style={{ color: 'white', borderColor: 'white' }}>
              <strong>Other Disbursements:</strong>
            </td>
            <td style={{ color: 'white', borderColor: 'white' }}>
              ${candidateData.other_disbursements}
            </td>
          </tr>
        </tbody>
      </Table>


          {/* Cash Summary Table */}
      <h4 style={{ color: 'white' }}>Cash Summary</h4>
      <Table striped bordered hover size="sm" style={{ color: 'white', borderColor: 'white' }}>
        <tbody>
          <tr>
            <td style={{ color: 'white', borderColor: 'white', width: '50%' }}>
              <strong>ENDING CASH ON HAND:</strong>
            </td>
            <td style={{ color: 'white', borderColor: 'white' }}>
              ${candidateData.last_cash_on_hand_end_period}
            </td>
          </tr>
          <tr>
            <td style={{ color: 'white', borderColor: 'white' }}>
              <strong>DEBTS/LOANS OWED TO COMMITTEE:</strong>
            </td>
            <td style={{ color: 'white', borderColor: 'white' }}>
              ${candidateData.last_debts_owed_to_committee || '0.00'}
            </td>
          </tr>
          <tr>
            <td style={{ color: 'white', borderColor: 'white' }}>
              <strong>DEBTS/LOANS OWED BY COMMITTEE:</strong>
            </td>
            <td style={{ color: 'white', borderColor: 'white' }}>
              ${candidateData.last_debts_owed_by_committee || '0.00'}
            </td>
          </tr>
        </tbody>
      </Table>
 

    </div>
  );
}

export default CandidateJSONTableMobile;
