import App from "../../../App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, PauseCircle, PlayCircle, FolderPlus, ChevronRight, Files, Folder, Pen, ChatLeftQuote, ShieldShaded, People, HeartFill, FileMedicalFill } from 'react-bootstrap-icons'
import '../../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import MobileOffCanvas from "../../../MobileOffCanvas";
import { CreateFeedItemUKAtom } from "../../../Functions";
import DepartmentForScience from "./DepartmentForScience";
import DepartmentForEnergy from "./DepartmentForEnergy";
import DepartmentForTransport from "../DepartmentForTransport";
import UKMobileTopNavbar from "../../UKMobileTopNavbar";
import OffCanvasUK from "../../OffCanvasUK";
var collectionCounter = 0  
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;

export const UKScienceAndTechMobile = () =>   {

  const [modal1, setModal1IsOpen] = useState(false);
  const [modal2, setModal2IsOpen] = useState(false);
  const [modal3, setModal3IsOpen] = useState(false);
  const [modal4, setModal4IsOpen] = useState(false);
  const [modal5, setModal5IsOpen] = useState(false);
  const [modal6, setModal6IsOpen] = useState(false);
  const [modal7, setModal7IsOpen] = useState(false);
  const [modal8, setModal8IsOpen] = useState(false);
  const [modal9, setModal9IsOpen] = useState(false);
  const [modal10, setModal10IsOpen] = useState(false);
  const [modal11, setModal11IsOpen] = useState(false);
  const [modal12, setModal12IsOpen] = useState(false);
  const [modal13, setModal13IsOpen] = useState(false);
  const [modal14, setModal14IsOpen] = useState(false);
  const [modal15, setModal15IsOpen] = useState(false);
  const [modal16, setModal16IsOpen] = useState(false);
  const [modal17, setModal17IsOpen] = useState(false);
  const [modal18, setModal18IsOpen] = useState(false);
  const [modal19, setModal19IsOpen] = useState(false);
  const [modal20, setModal20IsOpen] = useState(false);
    render()

    {
  
          const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
      
        localStorage.setItem('currentPage', 'uk')
    return(
       <>
    <UKMobileTopNavbar/>
  
  
  
  
  <div id='homebg'>

  <h5 id='branchHeadingMobile' > <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-rocket-takeoff" viewBox="0 0 16 16">
  <path d="M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z"/>
  <path d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z"/>
  <path d="M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z"/>
</svg> Science & Tech</h5>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setModal5IsOpen(true)}>Department for Energy Security & Net Zero</h5>

  <div id='deptOfEnergy'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-energy-security-and-net-zero.atom',
     'deptOfEnergy', 'Department for Energy Security & Net Zero', 'Executive', 'Science & Tech', DepartmentForEnergy)}

  <Modal id='mobileFullBillModal' show={modal4} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal4IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Energy Security & Net Zero</h3></Modal.Header>
<DepartmentForEnergy/>
</Modal>

    
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setModal8IsOpen(true)}>Department for Science, Innovation & Technology</h5>

  <div id='deptOfScience'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-science-innovation-and-technology.atom',
     'deptOfScience', 'Department for Science, Innovation & Technology', 'Executive', 'Science & Tech', DepartmentForScience)}

  <Modal id='mobileFullBillModal' show={modal8} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal8IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Science, Innovation & Technology</h3></Modal.Header>
<DepartmentForScience/>
</Modal>

    
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setModal10IsOpen(true)}>Department for Transport</h5>

  <div id='deptOfTransport'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-transport.atom',
     'deptOfTransport', 'Department for Transport', 'Executive', 'Science & Tech', DepartmentForTransport)}

  <Modal id='mobileFullBillModal' show={modal10} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal10IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Transport</h3></Modal.Header>
<DepartmentForTransport/>
</Modal>

    
</Col>

<Col>

<br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>

<br></br><br></br><br></br><br></br><br></br><br></br>

<Navbar id='navbarMobileBottom' fixed="bottom">  



       
<div id='changeBranchDropdownMobile'>

                  <OffCanvasUK/>
              
            </div>

</Navbar>
 
             
 
 
 
 
 
 

</div>



</>
)
}}
export default UKScienceAndTechMobile;


