
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, PauseCircle, PlayCircle, People, HeartFill, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import ExecutiveOrders from './ExecutiveOrders';
import PresidentialProclamations from './PresidentialProclamations';
import NationalScienceFoundation from './NationalScienceFoundation';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
  createWhiteHouseFeedMobile,createDepartmentOfState,
  createDepartmentOfDefense, 
  createDepartmentOfJustice, createDepartmentOfTheInterior,
  createDepartmentOfAgriculture, createDepartmentOfCommerce,
  createDepartmentOfCommerceMobile, createDepartmentOfLabor,
  createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
  ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
  , createEnvironmentalProtectionAgency,
  createDepartmentOfTreasury, createNationalScienceFoundation, 
   createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, createFederalRegisterStateDepartment, createFederalRegisterTreasuryDepartment, createFederalRegisterJusticeDepartment, createFederalRegisterInteriorDepartment, createFederalRegisterAgricultureDepartment, createFederalRegisterCommerceDepartment, createFederalRegisterLaborDepartment, createFederalRegisterHealthAndHumanServicesDepartment, createFederalRegisterHousingAndUrbanDevelopmentDepartment, createFederalRegisterHomelandSecurityDepartment, createFederalRegisterEducationDepartment, createFederalRegisterVeteransAffairsDepartment,
   CreateFeedItem, CreateFeedItemBillUpdates, CreateFeedItemWithDocButton,
   CreateFeedItemPresidentialDocuments, CreateFeedItemExecutiveOrders, CreateFeedItemCongressionalReports, CreateFeedItemExecutiveOffice, CreateFeedAPI } from './Functions'
import ExecutiveBranchMobile from './ExecutiveBranchMobile';
import DesktopNavbar from './DesktopNavbar';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import FederalRegisterJusticeDepartment from './FederalRegisterJusticeDepartment';
import FederalRegisterInteriorDepartment from './FederalRegisterInteriorDepartment';
import FederalRegisterAgricultureDepartment from './FederalRegisterAgricultureDepartment';
import FederalRegisterCommerceDepartment from './FederalRegisterCommerceDepartment';
import FederalRegisterLaborDepartment from './FederalRegisterLaborDepartment';
import FederalRegisterHealthAndHumanServicesDepartment from './FederalRegisterHealthAndHumanServicesDepartment';
import FederalRegisterHousingAndUrbanDevelopmentDepartment from './FederalRegisterHousingAndUrbanDevelopmentDepartment';
import FederalRegisterVeteransAffairsDepartment from './FederalRegisterVeteransAffairsDepartment';
import FederalRegisterHomelandSecurityDepartment from './FederalRegisterHomelandSecurityDepartment';
import FederalRegisterEducationDepartment from './FederalRegisterEducationDepartment';
import CouncilOfEconomicAdvisers from './CouncilOfEconomicAdvisers';
import CouncilOnEnvironmentQuality from './CouncilOnEnvironmentQuality';
import DomesticPolicyCouncil from './DomesticPolicyCouncil';
import GenderPolicyCouncil from './GenderPolicyCouncil';
import NationalEconomicCouncil from './NationalEconomicCouncil';
import NationalSecurityCouncil from './NationalSecurityCouncil';
import OfficeOfIntergovernmentalAffairs from './OfficeOfIntergovernmentalAffairs';
import OfficeOfManagmentAndBudget from './OfficeOfManagmentAndBudget';
import OfficeOfNationalDrugControlPolicy from './OfficeOfNationalDrugControlPolicy';
import OfficeOfPublicEngagement from './OfficeOfPublicEngagement';
import OfficeOfScienceAndTechnologyPolicy from './OfficeOfScienceAndTechnologyPolicy';
import OfficeOfNationalCyberDirector from './OfficeOfNationalCyberDirector';
import PresidentialPersonnelOffice from './PresidentialPersonnelOffice';
import ExecutiveOfficeMobile from './ExecutiveOfficeMobile';
import GenerateFeed from './GenerateFeed';





var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;





  

 


  


export const ExecutiveOffice = () =>
 {
   render()
   
   {
  const [show, setShow] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);


  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)

  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)

  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)


  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
  const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
  const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
  const [showBioModal, setBioModal] = useState(false);
  const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
  const [federalRegisterTreasuryDepartmentModalIsOpen, setFederalRegisterTreasuryDepartmentModalIsOpen] = useState(false)
  const [federalRegisterJusticeDepartmentModalIsOpen, setFederalRegisterJusticeDepartmentModalIsOpen] = useState(false)
  const [federalRegisterInteriorDepartmentModalIsOpen, setFederalRegisterInteriorDepartmentModalIsOpen] = useState(false)
  const [federalRegisterAgricultureDepartmentModalIsOpen, setFederalRegisterAgricultureDepartmentModalIsOpen] = useState(false)
  const [federalRegisterVeteransAffairsDepartmentModalIsOpen, setFederalRegisterVeteransAffairsDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHomelandSecurityDepartmentModalIsOpen, setFederalRegisterHomelandSecurityDepartmentModalIsOpen] = useState(false)
  const [federalRegisterCommerceDepartmentModalIsOpen, setFederalRegisterCommerceDepartmentModalIsOpen] = useState(false)
  const [federalRegisterLaborDepartmentModalIsOpen, setFederalRegisterLaborDepartmentModalIsOpen] = useState(false)
  const [federalRegisterEducationDepartmentModalIsOpen, setFederalRegisterEducationDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHealthAndHumanServicesDepartmentModalIsOpen, setFederalRegisterHealthAndHumanServicesDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHousingAndUrbanDevelopmentDepartmentModalIsOpen, setFederalRegisterHousingAndUrbanDevelopmentDepartmentModalIsOpen] = useState(false)
  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)



  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
  const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
  const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
  const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
  const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
  const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
  const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
  const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
  const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
  const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
  const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
  const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
  const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)

  const [
    number1ModalIsOpen, setNumber1ModalIsOpen,
    number2ModalIsOpen, setNumber2ModalIsOpen,
    number3ModalIsOpen, setNumber3ModalIsOpen,
    number4ModalIsOpen, setNumber4ModalIsOpen,
    number5ModalIsOpen, setNumber5ModalIsOpen,
    number6ModalIsOpen, setNumber6ModalIsOpen,
    number7ModalIsOpen, setNumber7ModalIsOpen,
    number8ModalIsOpen, setNumber8ModalIsOpen,
    number9ModalIsOpen, setNumber9ModalIsOpen,
    number10ModalIsOpen, setNumber10ModalIsOpen,
    number11ModalIsOpen, setNumber11ModalIsOpen,
    number12ModalIsOpen, setNumber12ModalIsOpen,
    number13ModalIsOpen, setNumber13ModalIsOpen,
    number14ModalIsOpen, setNumber14ModalIsOpen,
    number15ModalIsOpen, setNumber15ModalIsOpen,
    number16ModalIsOpen, setNumber16ModalIsOpen,
    number17ModalIsOpen, setNumber17ModalIsOpen,
    number18ModalIsOpen, setNumber18ModalIsOpen,
    number19ModalIsOpen, setNumber19ModalIsOpen,
    number20ModalIsOpen, setNumber20ModalIsOpen,
    number21ModalIsOpen, setNumber21ModalIsOpen,
    number22ModalIsOpen, setNumber22ModalIsOpen,
    number23ModalIsOpen, setNumber23ModalIsOpen,
    number24ModalIsOpen, setNumber24ModalIsOpen,
    number25ModalIsOpen, setNumber25ModalIsOpen,
  ] = useState(false);
  return(
    <>
      <BrowserView>
        <div id='homePage'>

     

        <div id='homebg'>

          <DesktopNavbar/>
          <div style={{ position: 'relative' }}>

<div id='profileImage' style={{ width: '130px', height: '130px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '60px' }}>
<Image id='profileImage-image' src='/executiveBranch.png' />
</div>


<div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
<div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Executive Office of the President </h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '16px' }}>
<span> 

Every day, the President of the United States is faced with scores of decisions, each with important consequences for America’s future. To provide the President with the support that he or she needs to govern effectively, the Executive Office of the President (EOP) was created in 1939 ...
</span>

<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

  <span><Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/administration/executive-office-of-the-president/", "_blank");
   }}>
     https://www.whitehouse.gov/administration/executive-office-of-the-president/
     </Link></span> 

     <Button id='whDocsViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
</div>
</div>

</div>
</div>


</div>
<br></br>
<br></br><br></br>
<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> The Executive Office of the President</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>Every day, the President of the United States is faced with scores of decisions, each with important consequences for America’s future. To provide the President with the support that he or she needs to govern effectively, the Executive Office of the President (EOP) was created in 1939 by President Franklin D. Roosevelt. The EOP has responsibility for tasks ranging from communicating the President’s message to the American people to promoting our trade interests abroad.</p>

<p>The EOP, overseen by the White House Chief of Staff, has traditionally been home to many of the President’s closest advisers. While Senate confirmation is required for some advisers, such as the Director of the Office of Management and Budget, most are appointed with full Presidential discretion. The individual offices that these advisors oversee have grown in size and number since the EOP was created. Some were formed by Congress, others as the President has needed them — they are constantly shifting as each President identifies his or her needs and priorities. Perhaps the most visible parts of the EOP are the White House Communications Office and Press Secretary’s Office. The Press Secretary provides daily briefings for the media on the President’s activities and agenda. Less visible to most Americans is the National Security Council, which advises the President on foreign policy, intelligence, and national security.</p>

<p>There are also a number of offices responsible for the practicalities of maintaining the White House and providing logistical support for the President. These include the White House Military Office, which is responsible for services ranging from Air Force One to the dining facilities, and the Office of Presidential Advance, which prepares sites remote from the White House for the President’s arrival.</p>

<p>Many senior advisors in the EOP work near the President in the West Wing of the White House. However, the majority of the staff is housed in the Eisenhower Executive Office Building, just a few steps away and part of the White House compound.</p>

<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/administration/executive-office-of-the-president/", "_blank");
   }}>
     https://www.whitehouse.gov/administration/executive-office-of-the-president/
     </Link>
 </div>

       </Modal>
                     
           
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading' onClick={() => setNumber1ModalIsOpen(true)}><b>Council of Economic Advisers	</b></h4>
                          <div id='councilOfEconomicAd'></div>
                    
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=council_of_economic_advisers&order_by=created_at', 
                          'councilOfEconomicAd', 
                            'Council of Economic Advisers', 'Executive', 
                            'Economy', 'Document', GenerateFeed)}

                          <Modal id='whiteHouseDocsModal' show={number1ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                          centered onHide={() => setNumber1ModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Council of Economic Advisers</h3></Modal.Header>
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=council_of_economic_advisers&order_by=created_at' 
                            name='Council of Economic Advisers' branch='Executive' topic='Economy' buttonName='Document' />
                          </Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setEnvironmentQualityModalIsOpen(true)}><b>Council on Environmental Quality</b></h4>
                          <div id='councilOnEnvironment'></div>
                     
                     {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=council_on_environmental_quality&order_by=created_at', 
                     'councilOnEnvironment', 
                       'Council on Environmental Quality', 'Executive', 
                       'Environment', 'Document', GenerateFeed)}

                     <Modal id='whiteHouseDocsModal' show={environmentQualityModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                     centered onHide={() => setEnvironmentQualityModalIsOpen(false)}>
                       <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Council on Environmental Quality</h3></Modal.Header>
                       <GenerateFeed url='&skip=0&schema=united_states_of_america&table=council_on_environmental_quality&order_by=created_at' 
                       name='Council on Environmental Quality' branch='Executive' topic='Environment' buttonName='Document' />
                     </Modal>
     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading' onClick={() => setDomesticPolicyModalIsOpen(true)}><b>Domestic Policy Council</b></h4>
                          <div  id='domesticPolicyCouncil'></div>
                          
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=domestic_policy_council&order_by=created_at', 
                                             'domesticPolicyCouncil', 
                                               'Domestic Policy Council', 'Executive', 
                                               'N/A', 'Document', GenerateFeed)}
                        
                                             <Modal id='whiteHouseDocsModal' show={domesticPolicyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                                             centered onHide={() => setDomesticPolicyModalIsOpen(false)}>
                                               <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Domestic Policy Council</h3></Modal.Header>
                                               <GenerateFeed url='&skip=0&schema=united_states_of_america&table=domestic_policy_council&order_by=created_at' 
                                               name='Domestic Policy Council' branch='Executive' topic='N/A' buttonName='Document' />
                                             </Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setNumber3ModalIsOpen(true)}><b>Gender Policy Council</b></h4>
                          <div id='genderPolicyCouncil'></div>
                          
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=gender_policy_council&order_by=created_at', 
                                             'genderPolicyCouncil', 
                                               'Gender Policy Council', 'Executive', 
                                               'N/A', 'Document', GenerateFeed)}
                        
                                             <Modal id='whiteHouseDocsModal' show={number3ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                                             centered onHide={() => setNumber3ModalIsOpen(false)}>
                                               <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Gender Policy Council</h3></Modal.Header>
                                               <GenerateFeed url='&skip=0&schema=united_states_of_america&table=gender_policy_council&order_by=created_at' 
                                               name='Gender Policy Council' branch='Executive' topic='N/A' buttonName='Document' />
                                             </Modal>
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             </div>


             <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading' onClick={() => setNumber21ModalIsOpen(true)}><b>National Economic Council</b></h4>
                          <div id='nationalEconomicCouncil'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=national_economic_council&order_by=created_at', 
                                             'nationalEconomicCouncil', 
                                               'National Economic Council', 'Executive', 
                                               'N/A', 'Document', GenerateFeed)}

<Modal id='whiteHouseDocsModal' show={number21ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                                             centered onHide={() => setNumber21ModalIsOpen(false)}>
                                               <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>National Economic Council</h3></Modal.Header>
                                               <GenerateFeed url='&skip=0&schema=united_states_of_america&table=national_economic_council&order_by=created_at' 
                                               name='National Economic Council' branch='Executive' topic='N/A' buttonName='Document' />
                                             </Modal>
                    
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading'><b>National Security Council</b></h4>
                          <div id='nationalSecurityCouncil'></div>
                          
                          {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/National_Security_Council.xml', 'nationalSecurityCouncil', 'National Security Council', 'Executive', 'N/A', NationalSecurityCouncil)}

     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             </div>

             <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading'><b>Office of Intergovernmental Affairs</b></h4>
                          <div id='officeOfIntergovernmentalAffairs'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                            {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/Office_of_Intergovernmental_Affairs.xml', 'officeOfIntergovernmentalAffairs', 'Office of Intergovernmental Affairs', 'Executive', 'N/A', OfficeOfIntergovernmentalAffairs)}

                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setNumber22ModalIsOpen(true)}><b>Office of Management and Budget	</b></h4>
                          <div id='officeOfManagmentAndBudget'></div>
                          
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=office_of_national_drug_control_policy&order_by=created_at', 
                                             'officeOfManagmentAndBudget', 
                                               'Office of Management and Budget', 'Executive', 
                                               'N/A', 'Document', GenerateFeed)}

<Modal id='whiteHouseDocsModal' show={number22ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                                             centered onHide={() => setNumber22ModalIsOpen(false)}>
                                               <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Office of Management and Budget</h3></Modal.Header>
                                               <GenerateFeed url='&skip=0&schema=united_states_of_america&table=office_of_science_and_technology_policy&order_by=created_at' 
                                               name='Office of Management and Budget' branch='Executive' topic='N/A' buttonName='Document' />
                                             </Modal>
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             </div>

             <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading' onClick={() => setNumber23ModalIsOpen(true)}><b>Office of National Drug Control Policy</b></h4>
                          <div id='officeOfNationalDrugControl'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=office_of_national_drug_control_policy&order_by=created_at', 
                                             'officeOfNationalDrugControl', 
                                               'Office of Science and Technology Policy', 'Executive', 
                                               'N/A', 'Document', GenerateFeed)}

<Modal id='whiteHouseDocsModal' show={number23ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                                             centered onHide={() => setNumber23ModalIsOpen(false)}>
                                               <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Office of National Drug Control Policy</h3></Modal.Header>
                                               <GenerateFeed url='&skip=0&schema=united_states_of_america&table=office_of_science_and_technology_policy&order_by=created_at' 
                                               name='Office of National Drug Control Policy' branch='Executive' topic='N/A' buttonName='Document' />
                                             </Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading'><b>Office of Public Engagement	</b></h4>
                          <div id='officeOfPublicEngagement'></div>
                          
                          {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/Office_of_Public_Engagement.xml', 'officeOfPublicEngagement', 'Office of Public Engagement', 'Executive', 'N/A', OfficeOfPublicEngagement)}

     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             </div>
             

             <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading' onClick={() => setNumber24ModalIsOpen(true)}><b>Office of Science and Technology Policy</b></h4>
                          <div id='officeOfScienceAndTechnology'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=office_of_science_and_technology_policy&order_by=created_at', 
                                             'officeOfScienceAndTechnology', 
                                               'Office of Science and Technology Policy', 'Executive', 
                                               'N/A', 'Document', GenerateFeed)}

<Modal id='whiteHouseDocsModal' show={number24ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                                             centered onHide={() => setNumber24ModalIsOpen(false)}>
                                               <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Office of Science and Technology Policy</h3></Modal.Header>
                                               <GenerateFeed url='&skip=0&schema=united_states_of_america&table=office_of_science_and_technology_policy&order_by=created_at' 
                                               name='Office of Science and Technology Policy' branch='Executive' topic='N/A' buttonName='Document' />
                                             </Modal>
     
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading'  onClick={() => setNumber25ModalIsOpen(true)}><b>Office of the National Cyber Director</b></h4>
                          <div id='officeOfTheNationalCyberDirector'></div>
                          
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=office_of_the_national_cyber_director&order_by=created_at', 
                                             'officeOfTheNationalCyberDirector', 
                                               'Office of the National Cyber Director', 'Executive', 
                                               'N/A', 'Document', GenerateFeed)}

<Modal id='whiteHouseDocsModal' show={number25ModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                                             centered onHide={() => setNumber25ModalIsOpen(false)}>
                                               <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Office of the National Cyber Director</h3></Modal.Header>
                                               <GenerateFeed url='&skip=0&schema=united_states_of_america&table=office_of_the_national_cyber_director&order_by=created_at' 
                                               name='Office of the National Cyber Director' branch='Executive' topic='N/A' buttonName='Document' />
                                             </Modal>
     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             </div>

             <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading'><b>Presidential Personnel Office</b></h4>
                          <div id='presidentialPersonnelOffice'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                            {CreateFeedItemExecutiveOffice('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive_office_of_the_president/Presidential_Personnel_Office.xml', 'presidentialPersonnelOffice', 'Presidential Personnel Office', 'Executive', 'N/A', PresidentialPersonnelOffice)}

                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
        
                      </Col>


                    </Row>


                  </Container>
                </Container>
             </div>
       

      



            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
   <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>



</div>
         </div>
          

              </BrowserView>

              <MobileView>

    <ExecutiveOfficeMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default ExecutiveOffice;