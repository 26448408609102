import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Coin, Tree, ChevronRight, GeoAlt } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import  "../../App.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import { CreateFeedItem, CreateFeedItemCanadaProvence, CreateFeedItemUKAtom } from '../../Functions';
import MobileOffCanvas from '../../MobileOffCanvas';
import UKMobileTopNavbar from '../UKMobileTopNavbar';
import EnglandCourt from './EnglandCourt';
import PrimeMinisterNews from '../Executive/PrimeMinisterNews';
import OffCanvasUK from '../OffCanvasUK';

var mobileCarouselInterval = 60000;




export const EnglandMobile = () =>   {
    render()
 
    {

      const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
      const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
      const [billUpdates, setBillUpdatesModalIsOpen] = useState(false)
      const [lordsResearchModal, setLordsResearchModalIsOpen] = useState(false)
      const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
    
    return(
       <>
      <UKMobileTopNavbar/>

       <div id='homebg'>
      
       <h5 id='stateFlagHeadingMobile'> <Image id='stateFlagMobile'  src='/englandFlag.png'></Image>England</h5>
       <br></br>
            <h5 id='branchHeadingMobile'> <Pen></Pen> Legislative<ChevronRight id='clickChevron'></ChevronRight></h5>

       
 
                <Carousel controls={false}>

<Carousel.Item>



<div id='executiveConMobile' >


<Col>
                 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile'>  Coming Soon</h5></Row>
 

               
                
             
               

</Col>
</div>

 </Carousel.Item>
 </Carousel>
  {/* 
<div id='executiveConMobile' >
             

             <Col>
                 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>  Alberta News</h5></Row>
 

                  <div class='whMobile' id='delawareBills'> </div>

                  {CreateFeedItem('https://api.io.canada.ca/io-server/gc/news/en/v2?location=ab48&sort=publishedDate&orderBy=desc&publishedDate%3E=2021-10-25&pick=100&format=atom&atomtitle=Alberta', 'delawareBills', 'Alberta News', 'N/A', 'N/A', AlbertaNews )}

                  <Modal id='mobileWhiteHouseFeedModal' show={whiteHouseFeedModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setWhiteHouseFeedModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>AlbertaNews</h3></Modal.Header>
                  <AlbertaNews/>
                </Modal>

</Col>

*/}



 
                 

              
             
    

            <h5 id='branchHeadingMobile' ><People></People> Executive<ChevronRight id='clickChevron'></ChevronRight></h5>

<Carousel controls={false}>



<Carousel.Item interval={mobileCarouselInterval}>
              <div id='legislativeConMobile' >
                <Col>



                  <Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setTrendingBillsOptionTenModalIsOpen(true)}>Prime Minister</h5></Row>

                  <div id='primeMinister'></div>

                  {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                  {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/prime-ministers-office-10-downing-street.atom',
                    'primeMinister', 'Prime Minister News', 'Executive', 'N/A', PrimeMinisterNews)}

                  <Modal id='mobileFullBillModal' show={trendingBillsOptionTen}
                    size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTenModalIsOpen(false)}>
                    <Modal.Header id='mobileBillModalHeader' closeButton><h3>Prime Minister</h3></Modal.Header>
                    <PrimeMinisterNews />
                  </Modal>
                </Col>

              </div>
            </Carousel.Item>


</Carousel>


  <h5 id='branchHeadingMobile'><svg xmlns="http://www.w3.org/2000/svg" height="27.54px" viewBox="0 -960 960 960" width="27.54px" fill="#FFFFFF"><path d="M160-120v-80h480v80H160Zm226-194L160-540l84-86 228 226-86 86Zm254-254L414-796l86-84 226 226-86 86Zm184 408L302-682l56-56 522 522-56 56Z"/></svg>   Judicial<ChevronRight id='clickChevron'></ChevronRight></h5>

            <Col>
            <Carousel controls={false}>
            <Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
        <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}>HM Court</h5></Row>


<div class='courtNewsMobile' id='engCourt'> {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/hm-courts-and-tribunals-service.atom', 
'engCourt', 'HM Court', 'Judicial', 'N/A', EnglandCourt)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>HM Court</h3></Modal.Header>
<EnglandCourt/>
</Modal>


</Carousel.Item>
</Carousel>

  </Col>

 
 
 
  <Col>
  <br></br>

  <h5 id='branchHeadingMobile' > <GeoAlt/>  Kingdoms <ChevronRight id='clickChevron'></ChevronRight></h5>
<div id='homebg'>  
<Container>
                      <Col>
                      <Row id='statesRow'>
                  <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/uk/england', '_self')
                      }}> <Image id='stateFlagMobile'  src='/englandFlag.png'></Image>England</Button>
                        <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/uk/northernIreland', '_self')
                      }}><Image id='stateFlagMobile'  src='/northernIrelandFlag.png'></Image>Northern Ireland</Button>
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/uk/scotland', '_self')
                      }}> <Image id='stateFlagMobile'  src='/scotlandFlag.png'></Image>Scotland</Button>
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/uk/wales', '_self')
                      }}> <Image id='stateFlagMobile'  src='/walesFlag.png'></Image>Wales</Button>
                      </Row>
               
                
   
                     </Col>
                     </Container>
                     </div>
           <br></br>
  </Col>

  <Col>

<br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>

     
           <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

            <OffCanvasUK/>
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
         
          </div>

          </>
  )
}
 }



  






export default EnglandMobile;

