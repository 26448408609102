
import App from "../App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, } from 'react-bootstrap';
import axios from "axios";
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';





const Illinois = () => {

  const fetchStateData = () => {
    const apikey = 'bc525046 - 9872 - 47be - a2f0 - bd111b97a538'
    return axios.get('https://v3.openstates.org/jurisdictions?classification=municipality&page=1&per_page=52&apikey=bc525046-9872-47be-a2f0-bd111b97a538')
      .then(res => {
        console.log(res);
        return res
      })
      .catch(err => {
        console.log(err);
      })
  }
  
  render(
    <>
  
   
    
  <div id='ill'>hello</div> 
  {fetchStateData()}
  
  </>

  )

  
}





export default Illinois;