import React, { useState, useEffect, useRef } from 'react';

import { Navbar, Nav, Spinner, Button } from 'react-bootstrap';
import { House, ArrowLeftCircle, HeartFill } from 'react-bootstrap-icons';
import { BrowserView, MobileView } from 'react-device-detect';
import DesktopNavbar from './DesktopNavbar';
import DesktopNavbarAI from './DesktopNavbarAI';
function AIChat() {
  const [inputText, setInputText] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const predefinedMessages = [
    "What is the constitution?",
    "Can you find legislation about infrastructure?"
  ];

  const sendPredefinedMessage = (predefinedInputText) => {
    setLoading(true);

    // Add the predefined message to the chat
    const userMessage = `You: ${predefinedInputText}`;
    setMessages(prevMessages => [...prevMessages, userMessage]);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "params": {
          "text": predefinedInputText
        },
        "project": "596fa2cef3c6-40a0-9148-0221f2701ed8"
      })
    };

    fetch('https://api-bcbe5a.stack.tryrelevance.com/latest/studios/4ec3eadb-880f-440b-8210-1479deb5bfc0/trigger_limited', requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const apiResponse = data.output && data.output.answer 
                            ? `AI: ${data.output.answer}` 
                            : "No answer received";
        setMessages(prevMessages => [...prevMessages, apiResponse]);
      })
      .catch(error => {
        setMessages(prevMessages => [...prevMessages, `Error: ${error.message}`]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    // Add the user message to the chat
    const userMessage = `You: ${inputText}`;
    setMessages(prevMessages => [...prevMessages, userMessage]);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "params": {
          "text": inputText
        },
        "project": "596fa2cef3c6-40a0-9148-0221f2701ed8"
      })
    };

    fetch('https://api-bcbe5a.stack.tryrelevance.com/latest/studios/4ec3eadb-880f-440b-8210-1479deb5bfc0/trigger_limited', requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        // Check if the 'output' field exists and has an 'answer' field
        const apiResponse = data.output && data.output.answer 
                            ? `AI: ${data.output.answer}` 
                            : "No answer received";

        // Add the apiResponse to the messages
        setMessages(prevMessages => [...prevMessages, apiResponse]);
      })
      .catch(error => {
        setMessages(prevMessages => [...prevMessages, `Error: ${error.message}`]);
      })
      .finally(() => {
        setLoading(false);
        setInputText(''); // Clear input after submission
      });
  };
  const chatStyle = {
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    border: '0px solid #ddd',
    padding: '20px',
    borderRadius: '5px',
    maxWidth: '500px',
    margin: '0 auto',
    backgroundColor: '#002031',
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
  };

  const chatContainerStyle = {
    height: '50vh', // Adjust as needed
    overflowY: 'auto',
    padding: '10px',
    border: '0px solid #ddd',
    borderRadius: '10px',
    marginBottom: '20px',
  };

  const buttonsColumnStyle = {
    display: 'flex',
    flexDirection: 'column', 
    marginBottom: '20px',
    padding: '10px 20px',
    backgroundColor: '#f8f8f8e7',
    color: '#002539',
    border: 'none',
    borderRadius: '10px',
    cursor: 'pointer',
    fontWeight: 'bold',
  };

  const formStyle = {
    backgoundColor: '#f8f8f8e7',
  };
  const inputStyle = {
    padding: '10px',
    width: 'calc(80%)', // Adjust for padding
    boxSizing: 'border-box', // Include padding in width
    marginBottom: '10px',
    backgoundColor: '#f8f8f8e7',
    borderRadius: '10px',
  };

  const buttonStyle = {
    padding: '10px 18px',
    backgroundColor: '#f8f8f8e7',
    color: '#002539',
    border: 'none',
    borderRadius: '10px',
    cursor: 'pointer',
    fontWeight: 'bold',
    position: 'relative',
    left: '10px',
  };

  const buttonMobileStyle = {
    padding: '10px 18px',
    backgroundColor: 'transparent',
    color: '#002539',
    border: 'none',
    borderRadius: '10px',
    cursor: 'pointer',
    fontWeight: 'bold',
    position: 'relative',
    left: '10px',
  };

  const messageStyle = {
    backgroundColor: '#f8f8f8e7',
    padding: '10px',
    borderRadius: '5px',
    margin: '5px 0',
    wordBreak: 'break-word',
  };

  const loadingStyle = {
    textAlign: 'center',
    margin: '10px 0',
    color: 'white',
  };

  const footerStyle = {
    color: 'white',
    textAlign: 'center',
  };

  return (
    <>

    <BrowserView>
    <div id='homebg'>

<DesktopNavbarAI/>
<div id='sectionHeading'>
           
         

        
       
         </div>
<div style={chatStyle}>
<div style={chatContainerStyle}>
<div>
  {predefinedMessages.map((message, index) => (
    <button key={index} onClick={() => sendPredefinedMessage(message)} style={buttonsColumnStyle}>
      {message}
    </button>
  ))}
</div>
  {messages.map((message, index) => (
    <p key={index} style={messageStyle}>{message}</p>
  ))}
   {loading && <Spinner style={loadingStyle} animation="grow" variant="light" />}
</div>
<form style={formStyle} onSubmit={handleSubmit}>
  <input 
    type="text" 
    value={inputText} 
    onChange={handleInputChange} 
    style={inputStyle} 
  />
  <button type="submit" disabled={loading} style={buttonStyle}>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
<path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z"/>
</svg>
  </button>
  <p style={footerStyle}>Gov Glance AI is experimental and may not give the best results. Help support the development of free, accurate, fact based AI.</p>
        <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button>
</form>
<br></br><br></br>
</div>

<br></br>
</div>
    </BrowserView>
    <MobileView>
    <div id='homebg'>

      <Navbar id='navbarMobile' >
<Nav.Link id='aiNavLinkMobile'> <ArrowLeftCircle id='aiNavIcon' size={24} onClick={(e) => {
              e.preventDefault();
              window.history.back()
            }}></ArrowLeftCircle></Nav.Link>
 < Nav.Link  id='optionAIMobile'>AI chat beta</Nav.Link>

<Nav.Link  id='aiNavLinkMobile'  onClick={(e) => {
                   e.preventDefault();
                   window.open('/', "_self") }}> <House id='aiNavIcon' size={24}/></Nav.Link>
    

  </Navbar>
    <div style={chatStyle}>
      <div style={chatContainerStyle}>
      <div>
        {predefinedMessages.map((message, index) => (
          <button key={index} onClick={() => sendPredefinedMessage(message)} style={buttonsColumnStyle}>
            {message}
          </button>
        ))}
      </div>
        {messages.map((message, index) => (
          <p key={index} style={messageStyle}>{message}</p>
        ))}
         {loading && <Spinner style={loadingStyle} animation="grow" variant="light" />}
      </div>
      <form style={formStyle} onSubmit={handleSubmit}>
        <input 
          type="text" 
          value={inputText} 
          onChange={handleInputChange} 
          style={inputStyle} 
        />
        <button type="submit" disabled={loading} style={buttonMobileStyle}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-send" viewBox="0 0 16 16">
  <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z"/>
</svg>
        </button>
        <p style={footerStyle}>Gov Glance AI is experimental and may be inaccurate. Help support the development of free fact based AI</p>
        <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button>
      </form>
    </div>
</div>
</MobileView>
</>
  );
}

export default AIChat;