var leXML = `<rss version = "2.0">
<channel>
<title> All News </title>
<link/>
<description/>
<language>en</language>
<data>
  <row>
    <id>ocd-bill/61f6130e-f3f9-4712-9f9e-7b48c405934b</id>
    <session>2021-2022</session>
    <identifier>A 10478</identifier>
    <title>Ensures the safe and efficient delivery of materials for the timely construction of major renewable energy facilities in furtherance of the goals mandated by the New York state climate leadership and community</title>
    <classification>['bill']</classification>
    <subject>[]</subject>
    <created_at>2022-05-28T01:54:19.570659+00:00</created_at>
    <updated_at>2022-05-28T01:54:19.589049+00:00</updated_at>
    <openstates_url>https://openstates.org/ny/bills/2021-2022/A10478/</openstates_url>
    <first_action_date>2022-05-27</first_action_date>
    <latest_action_date>2022-05-27</latest_action_date>
    <latest_action_description>REFERRED TO TRANSPORTATION</latest_action_description>
    <latest_passage_date/>
    <jurisdiction.id>ocd-jurisdiction/country:us/state:ny/government</jurisdiction.id>
    <jurisdiction.name>New York</jurisdiction.name>
    <jurisdiction.classification>state</jurisdiction.classification>
    <from_organization.id>ocd-organization/26bb6306-85f0-4d10-bff7-d1cd5bdc0865</from_organization.id>
    <from_organization.name>Assembly</from_organization.name>
    <from_organization.classification>lower</from_organization.classification>
  </row>
  <row>
    <id>ocd-bill/a984b2e3-d6b6-4f13-8510-23e835da8de4</id>
    <session>2021-2022</session>
    <identifier>A 9981</identifier>
    <title>Authorizes the assessor of the county of Nassau to accept from the Mineola Union Free School District an application for exemption from real property taxes</title>
    <classification>['bill']</classification>
    <subject>[]</subject>
    <created_at>2022-04-30T01:09:24.616152+00:00</created_at>
    <updated_at>2022-05-28T01:54:15.871935+00:00</updated_at>
    <openstates_url>https://openstates.org/ny/bills/2021-2022/A9981/</openstates_url>
    <first_action_date>2022-04-29</first_action_date>
    <latest_action_date>2022-05-27</latest_action_date>
    <latest_action_description>PRINT NUMBER 9981A</latest_action_description>
    <latest_passage_date/>
    <jurisdiction.id>ocd-jurisdiction/country:us/state:ny/government</jurisdiction.id>
    <jurisdiction.name>New York</jurisdiction.name>
    <jurisdiction.classification>state</jurisdiction.classification>
    <from_organization.id>ocd-organization/26bb6306-85f0-4d10-bff7-d1cd5bdc0865</from_organization.id>
    <from_organization.name>Assembly</from_organization.name>
    <from_organization.classification>lower</from_organization.classification>
  </row>
  <row>
    <id>ocd-bill/38e1ea3c-3317-444d-a337-5af90e732f02</id>
    <session>2021-2022</session>
    <identifier>A 10487</identifier>
    <title>Relates to the application of a lien or pledge for the benefit of certain bondholders to amounts withheld from certain taxes, penalties and interest imposed by the city of New York</title>
    <classification>['bill']</classification>
    <subject>[]</subject>
    <created_at>2022-05-28T01:54:10.642917+00:00</created_at>
    <updated_at>2022-05-28T01:54:10.663178+00:00</updated_at>
    <openstates_url>https://openstates.org/ny/bills/2021-2022/A10487/</openstates_url>
    <first_action_date>2022-05-27</first_action_date>
    <latest_action_date>2022-05-27</latest_action_date>
    <latest_action_description>REFERRED TO WAYS AND MEANS</latest_action_description>
    <latest_passage_date/>
    <jurisdiction.id>ocd-jurisdiction/country:us/state:ny/government</jurisdiction.id>
    <jurisdiction.name>New York</jurisdiction.name>
    <jurisdiction.classification>state</jurisdiction.classification>
    <from_organization.id>ocd-organization/26bb6306-85f0-4d10-bff7-d1cd5bdc0865</from_organization.id>
    <from_organization.name>Assembly</from_organization.name>
    <from_organization.classification>lower</from_organization.classification>
  </row>
  <row>
    <id>ocd-bill/380d2251-86a2-40bb-a20d-23d1618bb66f</id>
    <session>2021-2022</session>
    <identifier>J 2845</identifier>
    <title>Mourning the tragic and untimely death of Heyward Patterson, devoted father, brother and uncle, and distinguished member of his community</title>
    <classification>['resolution']</classification>
    <subject>[]</subject>
    <created_at>2022-05-28T01:54:10.562593+00:00</created_at>
    <updated_at>2022-05-28T01:54:10.581379+00:00</updated_at>
    <openstates_url>https://openstates.org/ny/bills/2021-2022/J2845/</openstates_url>
    <first_action_date>2022-05-27</first_action_date>
    <latest_action_date>2022-05-27</latest_action_date>
    <latest_action_description>REFERRED TO FINANCE</latest_action_description>
    <latest_passage_date/>
    <jurisdiction.id>ocd-jurisdiction/country:us/state:ny/government</jurisdiction.id>
    <jurisdiction.name>New York</jurisdiction.name>
    <jurisdiction.classification>state</jurisdiction.classification>
    <from_organization.id>ocd-organization/8291a233-623d-40e8-882d-21ec2d382c87</from_organization.id>
    <from_organization.name>Senate</from_organization.name>
    <from_organization.classification>upper</from_organization.classification>
  </row>
  <row>
    <id>ocd-bill/bd8f87bc-463d-4460-a313-d1bfc332d3f0</id>
    <session>2021-2022</session>
    <identifier>A 9776</identifier>
    <title>Authorizes Gurudwara Shaheedan, Inc. to file with the Nassau county assessor an application for certain real property tax exemptions</title>
    <classification>['bill']</classification>
    <subject>[]</subject>
    <created_at>2022-04-10T01:06:44.551547+00:00</created_at>
    <updated_at>2022-05-28T01:54:10.368862+00:00</updated_at>
    <openstates_url>https://openstates.org/ny/bills/2021-2022/A9776/</openstates_url>
    <first_action_date>2022-04-08</first_action_date>
    <latest_action_date>2022-05-27</latest_action_date>
    <latest_action_description>REFERENCE CHANGED TO WAYS AND MEANS</latest_action_description>
    <latest_passage_date/>
    <jurisdiction.id>ocd-jurisdiction/country:us/state:ny/government</jurisdiction.id>
    <jurisdiction.name>New York</jurisdiction.name>
    <jurisdiction.classification>state</jurisdiction.classification>
    <from_organization.id>ocd-organization/26bb6306-85f0-4d10-bff7-d1cd5bdc0865</from_organization.id>
    <from_organization.name>Assembly</from_organization.name>
    <from_organization.classification>lower</from_organization.classification>
  </row>
  <row>
    <id>ocd-bill/dd670b82-a70f-487c-8a23-33d27858047e</id>
    <session>2021-2022</session>
    <identifier>A 10443</identifier>
    <title>Authorizes the discontinuance of parkland and the granting of easements for improvements to the Metro-North railroad</title>
    <classification>['bill']</classification>
    <subject>[]</subject>
    <created_at>2022-05-24T01:43:33.547179+00:00</created_at>
    <updated_at>2022-05-28T01:54:07.235072+00:00</updated_at>
    <openstates_url>https://openstates.org/ny/bills/2021-2022/A10443/</openstates_url>
    <first_action_date>2022-05-23</first_action_date>
    <latest_action_date>2022-05-27</latest_action_date>
    <latest_action_description>REFERENCE CHANGED TO WAYS AND MEANS</latest_action_description>
    <latest_passage_date/>
    <jurisdiction.id>ocd-jurisdiction/country:us/state:ny/government</jurisdiction.id>
    <jurisdiction.name>New York</jurisdiction.name>
    <jurisdiction.classification>state</jurisdiction.classification>
    <from_organization.id>ocd-organization/26bb6306-85f0-4d10-bff7-d1cd5bdc0865</from_organization.id>
    <from_organization.name>Assembly</from_organization.name>
    <from_organization.classification>lower</from_organization.classification>
  </row>
  <row>
    <id>ocd-bill/064d0015-a181-4c18-a7fe-8fccda29aee3</id>
    <session>2021-2022</session>
    <identifier>S 8780</identifier>
    <title>Requires the creation and imposition of restrictive commercial practices and stringent recordkeeping and reporting to prevent gun and ammunition sales to individuals with a criminal record</title>
    <classification>['bill']</classification>
    <subject>[]</subject>
    <created_at>2022-04-15T00:58:52.645208+00:00</created_at>
    <updated_at>2022-05-28T01:54:03.702953+00:00</updated_at>
    <openstates_url>https://openstates.org/ny/bills/2021-2022/S8780/</openstates_url>
    <first_action_date>2022-04-14</first_action_date>
    <latest_action_date>2022-05-24</latest_action_date>
    <latest_action_description>PRINT NUMBER 8780A</latest_action_description>
    <latest_passage_date/>
    <jurisdiction.id>ocd-jurisdiction/country:us/state:ny/government</jurisdiction.id>
    <jurisdiction.name>New York</jurisdiction.name>
    <jurisdiction.classification>state</jurisdiction.classification>
    <from_organization.id>ocd-organization/8291a233-623d-40e8-882d-21ec2d382c87</from_organization.id>
    <from_organization.name>Senate</from_organization.name>
    <from_organization.classification>upper</from_organization.classification>
  </row>
  <row>
    <id>ocd-bill/43faca1e-8bd0-4d2e-b311-89d5f30068ab</id>
    <session>2021-2022</session>
    <identifier>S 9435</identifier>
    <title>Requires the deactivation of transportation network company drivers upon a substantiated report of a covered incident</title>
    <classification>['bill']</classification>
    <subject>[]</subject>
    <created_at>2022-05-28T01:54:00.669440+00:00</created_at>
    <updated_at>2022-05-28T01:54:00.690480+00:00</updated_at>
    <openstates_url>https://openstates.org/ny/bills/2021-2022/S9435/</openstates_url>
    <first_action_date>2022-05-27</first_action_date>
    <latest_action_date>2022-05-27</latest_action_date>
    <latest_action_description>REFERRED TO TRANSPORTATION</latest_action_description>
    <latest_passage_date/>
    <jurisdiction.id>ocd-jurisdiction/country:us/state:ny/government</jurisdiction.id>
    <jurisdiction.name>New York</jurisdiction.name>
    <jurisdiction.classification>state</jurisdiction.classification>
    <from_organization.id>ocd-organization/8291a233-623d-40e8-882d-21ec2d382c87</from_organization.id>
    <from_organization.name>Senate</from_organization.name>
    <from_organization.classification>upper</from_organization.classification>
  </row>
  <row>
    <id>ocd-bill/ef983404-188c-4d6a-86cc-f7ab9a578d26</id>
    <session>2021-2022</session>
    <identifier>S 9439</identifier>
    <title>Authorizes the county of Nassau assessor to accept an application for a real property tax exemption from the Nassau Cemetery Association</title>
    <classification>['bill']</classification>
    <subject>[]</subject>
    <created_at>2022-05-28T01:53:35.206527+00:00</created_at>
    <updated_at>2022-05-28T01:53:35.224714+00:00</updated_at>
    <openstates_url>https://openstates.org/ny/bills/2021-2022/S9439/</openstates_url>
    <first_action_date>2022-05-27</first_action_date>
    <latest_action_date>2022-05-27</latest_action_date>
    <latest_action_description>REFERRED TO LOCAL GOVERNMENT</latest_action_description>
    <latest_passage_date/>
    <jurisdiction.id>ocd-jurisdiction/country:us/state:ny/government</jurisdiction.id>
    <jurisdiction.name>New York</jurisdiction.name>
    <jurisdiction.classification>state</jurisdiction.classification>
    <from_organization.id>ocd-organization/8291a233-623d-40e8-882d-21ec2d382c87</from_organization.id>
    <from_organization.name>Senate</from_organization.name>
    <from_organization.classification>upper</from_organization.classification>
  </row>
  <row>
    <id>ocd-bill/4d565195-dc66-4f7f-93d9-c96d4b687f06</id>
    <session>2021-2022</session>
    <identifier>J 2847</identifier>
    <title>Commending the Eureka Grand Chapter Prince Hall Order Eastern Star, Inc. upon the occasion of hosting its 125th Annual Grand Session from May 30 through May 31, 2022</title>
    <classification>['resolution']</classification>
    <subject>[]</subject>
    <created_at>2022-05-28T01:53:28.636454+00:00</created_at>
    <updated_at>2022-05-28T01:53:28.655268+00:00</updated_at>
    <openstates_url>https://openstates.org/ny/bills/2021-2022/J2847/</openstates_url>
    <first_action_date>2022-05-27</first_action_date>
    <latest_action_date>2022-05-27</latest_action_date>
    <latest_action_description>REFERRED TO FINANCE</latest_action_description>
    <latest_passage_date/>
    <jurisdiction.id>ocd-jurisdiction/country:us/state:ny/government</jurisdiction.id>
    <jurisdiction.name>New York</jurisdiction.name>
    <jurisdiction.classification>state</jurisdiction.classification>
    <from_organization.id>ocd-organization/8291a233-623d-40e8-882d-21ec2d382c87</from_organization.id>
    <from_organization.name>Senate</from_organization.name>
    <from_organization.classification>upper</from_organization.classification>
  </row>
</data>

</channel>

</rss>`

function SomeTestFile()
{
return(
<>
<p>

{leXML}


</p>
</>
)
}
export default SomeTestFile;