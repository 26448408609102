import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Coin, Tree, ChevronRight, GeoAlt } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import  "../../App.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import { CreateFeedItem, CreateFeedItemCanadaProvence } from '../../Functions';
import MobileOffCanvas from '../../MobileOffCanvas';
import UKMobileTopNavbar from '../UKMobileTopNavbar';
import ScotlandCourtNews from './ScotlandCourtNews';
import ScotlandStatutoryInstrument from './ScotlandStatutoryInstrument';
import KingdomsButtonPage from '../../KingdomsButtonPage';
import OffCanvasUK from '../OffCanvasUK';


var mobileCarouselInterval = 60000;




export const ScotlandMobile = () =>   {
    render()
 
    {

      const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
      const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
      const [billUpdates, setBillUpdatesModalIsOpen] = useState(false)
      const [lordsResearchModal, setLordsResearchModalIsOpen] = useState(false)

      const [modal1, setModal1IsOpen] = useState(false);
          const [modal2, setModal2IsOpen] = useState(false);
          const [modal3, setModal3IsOpen] = useState(false);
          const [modal4, setModal4IsOpen] = useState(false);
          const [modal5, setModal5IsOpen] = useState(false);
          const [modal6, setModal6IsOpen] = useState(false);
          const [modal7, setModal7IsOpen] = useState(false);
          const [modal8, setModal8IsOpen] = useState(false);
          const [modal9, setModal9IsOpen] = useState(false);
          const [modal10, setModal10IsOpen] = useState(false);
          const [modal11, setModal11IsOpen] = useState(false);
          const [modal12, setModal12IsOpen] = useState(false);
          const [modal13, setModal13IsOpen] = useState(false);
          const [modal14, setModal14IsOpen] = useState(false);
          const [modal15, setModal15IsOpen] = useState(false);
          const [modal16, setModal16IsOpen] = useState(false);
          const [modal17, setModal17IsOpen] = useState(false);
          const [modal18, setModal18IsOpen] = useState(false);
          const [modal19, setModal19IsOpen] = useState(false);
          const [modal20, setModal20IsOpen] = useState(false);
          const [modal21, setModal21IsOpen] = useState(false);
          const [modal22, setModal22IsOpen] = useState(false);
          const [modal23, setModal23IsOpen] = useState(false);

    
    return(
       <>
      <UKMobileTopNavbar/>

       <div id='homebg'>
      
       <h5 id='stateFlagHeadingMobile'> <Image id='stateFlagMobile'  src='/scotlandFlag.png'></Image>Scotland</h5>

<br></br>








         
         
            <h5 id='branchHeadingMobile'> <Pen></Pen> Legislative<ChevronRight id='clickChevron'></ChevronRight></h5>

       
 
                <Carousel controls={false}>

                <Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
        <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setModal1IsOpen(true)}>Satutory Instrument</h5></Row>


<div class='courtNewsMobile' id='scotInstrum'> 


{CreateFeedItem('https://www.legislation.gov.uk/new/ssi/data.feed', 
'scotInstrum', 'Scotland Statutory Instrument', 'Legislative', 'N/A', ScotlandStatutoryInstrument)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={modal1} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal1IsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Satutory Instrument</h3></Modal.Header>
<ScotlandStatutoryInstrument/>
</Modal>


</Carousel.Item>
 </Carousel>
  {/* 
<div id='executiveConMobile' >
             

             <Col>
                 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>  Alberta News</h5></Row>
 

                  <div class='whMobile' id='delawareBills'> </div>

                  {CreateFeedItem('https://api.io.canada.ca/io-server/gc/news/en/v2?location=ab48&sort=publishedDate&orderBy=desc&publishedDate%3E=2021-10-25&pick=100&format=atom&atomtitle=Alberta', 'delawareBills', 'Alberta News', 'N/A', 'N/A', AlbertaNews )}

                  <Modal id='mobileWhiteHouseFeedModal' show={whiteHouseFeedModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setWhiteHouseFeedModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>AlbertaNews</h3></Modal.Header>
                  <AlbertaNews/>
                </Modal>

</Col>

*/}



 
                 

              
             
    

            <h5 id='branchHeadingMobile' ><People></People> Executive<ChevronRight id='clickChevron'></ChevronRight></h5>

<Carousel controls={false}>

<Carousel.Item interval={mobileCarouselInterval}>

  


<div id='executiveConMobile' >

              
 

<Col>
<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile'>  Coming Soon</h5></Row>



  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Delaware Governor</h3></Modal.Header>
                  
                </Modal>
  </Col>       
</div>

            

  </Carousel.Item>


</Carousel>


  <h5 id='branchHeadingMobile'><svg xmlns="http://www.w3.org/2000/svg" height="27.54px" viewBox="0 -960 960 960" width="27.54px" fill="#FFFFFF"><path d="M160-120v-80h480v80H160Zm226-194L160-540l84-86 228 226-86 86Zm254-254L414-796l86-84 226 226-86 86Zm184 408L302-682l56-56 522 522-56 56Z"/></svg>   Judicial<ChevronRight id='clickChevron'></ChevronRight></h5>

            <Col>
            <Carousel controls={false}>
  <Carousel.Item interval={mobileCarouselInterval}>

  <div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}>Court</h5></Row>


<div class='courtNewsMobile' id='scotCourt'> {CreateFeedItem('https://scotcourts.gov.uk/feeds/news', 
'scotCourt', 'Scotland Court', 'Judicial', 'N/A', ScotlandCourtNews)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Scotland Court</h3></Modal.Header>
<ScotlandCourtNews/>
</Modal>


</Carousel.Item>
</Carousel>

  </Col>

 
 
 
  <Col>
  <br></br>

  <h5 id='branchHeadingMobile' > <GeoAlt/>  Kingdoms <ChevronRight id='clickChevron'></ChevronRight></h5>
<div id='homebg'>  
<Container>
                      <Col>
                      <Row id='statesRow'>
                  <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/uk/england', '_self')
                      }}> <Image id='stateFlagMobile'  src='/englandFlag.png'></Image>England</Button>
                        <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/uk/northernIreland', '_self')
                      }}><Image id='stateFlagMobile'  src='/northernIrelandFlag.png'></Image>Northern Ireland</Button>
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/uk/scotland', '_self')
                      }}> <Image id='stateFlagMobile'  src='/scotlandFlag.png'></Image>Scotland</Button>
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/uk/wales', '_self')
                      }}> <Image id='stateFlagMobile'  src='/walesFlag.png'></Image>Wales</Button>
                      </Row>
               
                
   
                     </Col>
                     </Container>
                     </div>

           <br></br>
  </Col>

  <Col>

<br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>

     
           <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

            <OffCanvasUK/>
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
         
          </div>

          </>
  )
}
 }



  






export default ScotlandMobile;

