import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const NavbarSearchButton = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();

  const runSearch = () => {
   // console.log('Running search for:', searchQuery); // Debug log
    if (searchQuery.trim() === '') {
      //console.log('Search query is empty or whitespace'); // Debug log
      return;
    }

    const encodedQuery = encodeURIComponent(searchQuery);
    const searchPath = `/search/united_states_of_america?query=${encodedQuery}&limit=10`;
   // console.log('Search path:', searchPath); // Debug log
    history.push(searchPath);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    //console.log('Form submitted'); // Debug log
    runSearch();
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <Form.Group className="mb-3 d-flex">
        <Form.Control
          id="navbarSearch"
          placeholder="Search Gov Glance"
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          autoComplete="off"
        />
        <Button size="md" variant="primary" id="navbarAboutButtonDesktop" type="submit">
          Search
        </Button>
      </Form.Group>
    </Form>
  );
};

export default NavbarSearchButton;
