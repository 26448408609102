import React, { useState } from 'react';
import { Form, Button, Dropdown, Container, Row, Col } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';

const NavbarSearchButtonNoCountry = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const history = useHistory();

  const runSearch = () => {
    //console.log('Running search for:', searchQuery); // Debug log
    if (searchQuery.trim() === '') {
      //console.log('Search query is empty or whitespace'); // Debug log
      return;
    }

    const encodedQuery = encodeURIComponent(searchQuery);
    const searchPath = selectedCountry
      ? `/search/${selectedCountry}?query=${encodedQuery}&limit=10`
      : `/search/?query=${encodedQuery}&limit=10`;

    //console.log('Search path:', searchPath); // Debug log
    history.push(searchPath);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    //console.log('Form submitted'); // Debug log
    runSearch();
  };

  const getFlagEmoji = (country) => {
    switch (country) {
      case 'canada': return '🇨🇦';
      case 'mexico': return '🇲🇽';
      case 'united_kingdom': return '🇬🇧';
      case 'united_states_of_america': return '🇺🇸';
      case '': return '🌐'; // All countries
      default: return '';
    }
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group className="mb-3 d-flex justify-content-center align-items-center">
              <Dropdown drop="down-centered">
                <Dropdown.Toggle id='searchCountry'>
                  {getFlagEmoji(selectedCountry)}
                </Dropdown.Toggle>
                <Dropdown.Menu id="docsDropdown" drop="down-centered">
                  <Dropdown.Item id="docsDropdownItem" onClick={() => setSelectedCountry('')}>
                    🌐 All Countries
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => setSelectedCountry('canada')}>
                    🇨🇦 Canada
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => setSelectedCountry('mexico')}>
                    🇲🇽 Mexico
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => setSelectedCountry('united_kingdom')}>
                    🇬🇧 United Kingdom
                  </Dropdown.Item>
                  <Dropdown.Item id="docsDropdownItem" onClick={() => setSelectedCountry('united_states_of_america')}>
                    🇺🇸 United States
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Form.Control
                id="navbarSearch"
                placeholder="Search Gov Glance"
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                autoComplete="off"
              />
              <Button size="md" variant="primary" id="navbarAboutButtonDesktop" type="submit">
                <Search/>
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default NavbarSearchButtonNoCountry;
