import React, { useState, useEffect } from 'react';
import { Container, Spinner, Toast, Navbar } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';
import MobileTopNavbar from './MobileTopNavbar';
import MobileOffCanvas from './MobileOffCanvas';
import DesktopNavbar from './DesktopNavbar';
import { useHistory } from 'react-router-dom'; // Import useHistory
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

require('dotenv').config();

const apiKey = process.env.REACT_APP_API_KEY;

function FeedPageTemplate() {
  const [toptierData, setToptierData] = useState([]);
  const [federalAccountsData, setFederalAccountsData] = useState([]);
  const [programActivityData, setProgramActivityData] = useState([]);
  const [objectClassData, setObjectClassData] = useState([]);
  const [recipientsData, setRecipientsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [agencyId, setAgencyId] = useState(null);
  const [federalAccountId, setFederalAccountId] = useState(null);
  const [programActivityID, setProgramActivityID] = useState(null);
  const history = useHistory(); // Initialize useHistory

  const [navigationHistory, setNavigationHistory] = useState([]);

  const initialUrl = `https://api.govglance.org/posts/recent?limit=10&skip=0&schema=united_states_of_america&table=toptier_agencies&order_by=collected_at`;

  const buildFederalAccountsUrl = (agencyId) =>
    `https://api.govglance.org/posts/recent?limit=10&skip=0&schema=united_states_of_america&table=federal_accounts&order_by=collected_at&filter_column=agency_id&filter_string=${agencyId}`;

  const buildProgramActivityUrl = (federalAccountId) =>
    `https://api.govglance.org/posts/recent?limit=10&skip=0&schema=united_states_of_america&table=program_activity&order_by=collected_at&filter_column=federal_account_id&filter_string=${federalAccountId}`;

  const buildObjectClassUrl = (programActivityID) =>
    `https://api.govglance.org/posts/recent?limit=10&skip=0&schema=united_states_of_america&table=object_class&order_by=collected_at&filter_column=program_activity_id&filter_string=${programActivityID}`;

  const buildRecipientsUrl = (programActivityID) =>
    `https://api.govglance.org/posts/recent?limit=10&skip=0&schema=united_states_of_america&table=us_spending_recipients&order_by=collected_at&filter_column=program_activity_id&filter_string=${programActivityID}`;

  const fetchToptierData = async () => {
    setLoading(true);
    try {
      const response = await fetch(initialUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiKey}`,
        },
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const result = await response.json();
      console.log('Toptier Data:', result);
      setToptierData(result);
    } catch (error) {
      setError(error);
      console.error('Error fetching toptier data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchToptierData();
  }, []);

  const fetchFederalAccounts = async (agencyId) => {
    setLoading(true);
    try {
      const response = await fetch(buildFederalAccountsUrl(agencyId), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiKey}`,
        },
      });

      const data = await response.json();
      console.log('Federal Accounts Data:', data);
      setFederalAccountsData(data);
    } catch (error) {
      setError(error);
      console.error('Error fetching federal accounts:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProgramActivities = async (federalAccountId) => {
    setLoading(true);
    try {
      const response = await fetch(buildProgramActivityUrl(federalAccountId), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiKey}`,
        },
      });

      const data = await response.json();
      console.log('Program Activity Data:', data);
      setProgramActivityData(data);
    } catch (error) {
      setError(error);
      console.error('Error fetching program activities:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchObjectClass = async (programActivityID) => {
    setLoading(true);
    try {
      const response = await fetch(buildObjectClassUrl(programActivityID), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiKey}`,
        },
      });

      const data = await response.json();
      console.log('Object Class Data:', data);
      setObjectClassData(data);
    } catch (error) {
      setError(error);
      console.error('Error fetching object class data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRecipients = async (programActivityID) => {
    setLoading(true);
    try {
      const response = await fetch(buildRecipientsUrl(programActivityID), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiKey}`,
        },
      });

      const data = await response.json();
      console.log('Recipient Data:', data);
      setRecipientsData(data);
    } catch (error) {
      setError(error);
      console.error('Error fetching recipient data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAgencyClick = (id, agencyName) => {
    console.log(`Agency clicked, agency_id: ${id}`);

    // Update the URL with agency name
    history.push(`/us/spending/agency/${encodeURIComponent(agencyName)}`);

    setNavigationHistory((prevHistory) => [
      ...prevHistory,
      {
        level: 'toptier',
        data: {
          toptierData,
          agencyId: null,
          federalAccountId: null,
          programActivityID: null,
        },
      },
    ]);

    setAgencyId(id);
    setToptierData([]);
    fetchFederalAccounts(id);
  };

  const handleFederalAccountClick = (id) => {
    console.log(`Federal Account clicked, federal_account_id: ${id}`);

    setNavigationHistory((prevHistory) => [
      ...prevHistory,
      {
        level: 'federalAccounts',
        data: {
          federalAccountsData,
          agencyId,
          federalAccountId: null,
          programActivityID: null,
        },
      },
    ]);

    setFederalAccountId(id);
    setFederalAccountsData([]);
    fetchProgramActivities(id);
  };

  const handleProgramActivityClick = (id) => {
    console.log(`Program Activity clicked, program_activity_id: ${id}`);

    setNavigationHistory((prevHistory) => [
      ...prevHistory,
      {
        level: 'programActivity',
        data: {
          programActivityData,
          agencyId,
          federalAccountId,
          programActivityID: null,
        },
      },
    ]);

    setProgramActivityID(id);
    setProgramActivityData([]);
    fetchObjectClass(id);
  };

  const handleObjectClassClick = (id) => {
    console.log(`Object Class clicked, program_activity_id: ${id}`);

    setNavigationHistory((prevHistory) => [
      ...prevHistory,
      {
        level: 'objectClass',
        data: {
          objectClassData,
          agencyId,
          federalAccountId,
          programActivityID,
        },
      },
    ]);

    setObjectClassData([]);
    fetchRecipients(id);
  };

  const handleBack = () => {
    if (navigationHistory.length === 0) return; // Already at the top level

    const previousState = navigationHistory[navigationHistory.length - 1];
    setNavigationHistory((prevHistory) => prevHistory.slice(0, -1));

    switch (previousState.level) {
      case 'objectClass':
        setRecipientsData([]);
        setObjectClassData(previousState.data.objectClassData);
        setProgramActivityID(previousState.data.programActivityID);
        setFederalAccountId(previousState.data.federalAccountId);
        setAgencyId(previousState.data.agencyId);
        break;
      case 'programActivity':
        setObjectClassData([]);
        setProgramActivityData(previousState.data.programActivityData);
        setProgramActivityID(previousState.data.programActivityID);
        setFederalAccountId(previousState.data.federalAccountId);
        setAgencyId(previousState.data.agencyId);
        break;
      case 'federalAccounts':
        setProgramActivityData([]);
        setFederalAccountsData(previousState.data.federalAccountsData);
        setFederalAccountId(previousState.data.federalAccountId);
        setAgencyId(previousState.data.agencyId);
        break;
      case 'toptier':
        setFederalAccountsData([]);
        setToptierData(previousState.data.toptierData);
        setAgencyId(previousState.data.agencyId);
        break;
      default:
        break;
    }
  };

  const renderToptierData = () =>
    toptierData.map((item) => (
      <Container id="feedContainerNormalView" key={item.id} className="mb-4">
        <h4 id="billText" onClick={() => handleAgencyClick(item.agency_id, item.agency_name)}>
          {item.agency_name} ({item.abbreviation || 'N/A'})
        </h4>
        <p id="billBodyText">
          Active FY: {item.active_fy}, Quarter: {item.active_fq}
        </p>
        <p id="billBodyText">
          Budget Authority: ${item.budget_authority_amount.toLocaleString()}
        </p>
        <p id="billBodyText">
          Outlay Amount: ${item.outlay_amount.toLocaleString()}
        </p>
      </Container>
    ));

  const renderFederalAccountsData = () =>
    federalAccountsData.map((item) => (
      <Container id="feedContainerNormalView" key={item.id} className="mb-4">
        <h4 id="billText" onClick={() => handleFederalAccountClick(item.federal_account_id)}>
          {item.name || 'Unnamed Account'}
        </h4>
        <p id="billBodyText">Account Number: {item.account_number || 'N/A'}</p>
        <p id="billBodyText">Amount: ${item.amount.toLocaleString()}</p>
      </Container>
    ));

  const renderProgramActivityData = () =>
    programActivityData.map((item) => (
      <Container id="feedContainerNormalView" key={item.id} className="mb-4">
        <h4 id="billText" onClick={() => handleProgramActivityClick(item.program_activity_id)}>
          {item.name || 'Unnamed Program Activity'}
        </h4>
        <p id="billBodyText">Description: {item.description || 'No description available.'}</p>
        <p id="billBodyText">Amount: ${item.amount.toLocaleString()}</p>
      </Container>
    ));

  const renderObjectClassData = () =>
    objectClassData.map((item) => (
      <Container id="feedContainerNormalView" key={item.id} className="mb-4">
        <h4 id="billText" onClick={() => handleObjectClassClick(item.program_activity_id)}>
          {item.name || 'Unnamed Object Class'}
        </h4>
        <p id="billBodyText">Amount: ${item.amount.toLocaleString()}</p>
        <p id="billBodyText">Description: {item.description || 'No description available.'}</p>
      </Container>
    ));

  const renderRecipientsData = () =>
    recipientsData.map((item) => (
      <Container id="feedContainerNormalView" key={item.id} className="mb-4">
        <h4 id="billText">{item.name || 'Unnamed Recipient'}</h4>
        <p id="billBodyText">Amount: ${item.amount.toLocaleString()}</p>
        <p id="billBodyText">Description: {item.description || 'No description available.'}</p>
      </Container>
    ));

  return (
    <>
      <BrowserView>
        <DesktopNavbar />
        <div id="feedPageFeed">
          {error && (
            <Toast onClose={() => setError(null)}>
              <Toast.Header>
                <strong className="mr-auto">Error</strong>
              </Toast.Header>
              <Toast.Body>{error.message}</Toast.Body>
            </Toast>
          )}
          {navigationHistory.length > 0 && (
            <button onClick={handleBack} className="back-button">
              Back
            </button>
          )}
          {loading ? (
            <div className="spinner-container">
              <Spinner animation="grow" />
            </div>
          ) : recipientsData.length ? (
            renderRecipientsData()
          ) : objectClassData.length ? (
            renderObjectClassData()
          ) : programActivityData.length ? (
            renderProgramActivityData()
          ) : federalAccountsData.length ? (
            renderFederalAccountsData()
          ) : (
            renderToptierData()
          )}
        </div>
      </BrowserView>

      <MobileView>
        <MobileTopNavbar />
        <div id="feedPageFeed">
          {error && (
            <Toast onClose={() => setError(null)}>
              <Toast.Header>
                <strong className="mr-auto">Error</strong>
              </Toast.Header>
              <Toast.Body>{error.message}</Toast.Body>
            </Toast>
          )}
          {navigationHistory.length > 0 && (
            <button onClick={handleBack} className="back-button">
              Back
            </button>
          )}
          {loading ? (
            <div className="spinner-container">
              <Spinner animation="grow" />
            </div>
          ) : recipientsData.length ? (
            renderRecipientsData()
          ) : objectClassData.length ? (
            renderObjectClassData()
          ) : programActivityData.length ? (
            renderProgramActivityData()
          ) : federalAccountsData.length ? (
            renderFederalAccountsData()
          ) : (
            renderToptierData()
          )}
        </div>
        <Navbar fixed="bottom">
          <MobileOffCanvas />
        </Navbar>
      </MobileView>
    </>
  );
}

export default FeedPageTemplate;
