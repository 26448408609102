import React, { useState, useEffect } from 'react';
import { Container, Spinner, Toast, Navbar, Form, FormControl, Button, Modal } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';
import MobileTopNavbar from './MobileTopNavbar';
import MobileOffCanvas from './MobileOffCanvas';
import DesktopNavbar from './DesktopNavbar';
import { useHistory, useParams } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BoxArrowUp, ChevronRight, Clipboard, ClipboardCheck, FileText, FolderPlus, InfoCircle } from 'react-bootstrap-icons';
require('dotenv').config();

const apiKey = process.env.REACT_APP_API_KEY;

function DynamicData() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState(''); // New: Search query state
  const [toptierData, setToptierData] = useState([]);
  const [federalAccountsData, setFederalAccountsData] = useState([]);
  const [programActivityData, setProgramActivityData] = useState([]);
  const [objectClassData, setObjectClassData] = useState([]);
  const [recipientsData, setRecipientsData] = useState([]);
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
  const { level, id } = useParams();
  const history = useHistory();
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // For the share modal
  const [shareLink, setShareLink] = useState(''); // The link to be shared
  const [linkCopied, setLinkCopied] = useState(false); // To track if the link is copie
  const [agencyName, setAgencyName] = useState(''); // Store agency name

  const fetchData = async (url, setData) => {
    setLoading(true);
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiKey}`,
        },
      });
      const data = await response.json();
      setData(data);
      console.log(data)
    } catch (error) {
      setError(error);
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAgencyName = async (agency_id) => {
    try {
      const response = await fetch(
        `https://api.govglance.org/posts/recent?limit=10&skip=0&schema=united_states_of_america&table=toptier_agencies&order_by=collected_at&filter_column=agency_id&filter_string=${agency_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiKey}`,
        },
      });
      const data = await response.json();

      if (data.length > 0) {
        setAgencyName(data[0].agency_name); // Set the agency name
      } else {
        setAgencyName('Agency Name Not Found');
      }
    } catch (error) {
      console.error('Error fetching agency name:', error);
      setAgencyName('Error Loading Agency Name');
    }
  };
  

  const buildUrl = (table, filterColumn = '', filterValue = '') => {
    let url = `https://api.govglance.org/posts/recent?limit=10&skip=0&schema=united_states_of_america&table=${table}&order_by=collected_at`;
    if (filterColumn && filterValue) {
      url += `&filter_column=${filterColumn}&filter_string=${filterValue}`;
    }
    return url;
  };

  const handleNavigation = (nextLevel, nextId) => {
    history.push(`/us/spending/${nextLevel}/${nextId}`);
  };

  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
    var searchItem = document.getElementById('noteModalFeedItem').value;
    var collectionCounter = localStorage.getItem('collectionNumber');
    collectionCounter++;
  
    var title = 'title'.concat(collectionCounter);
    var detailLink = 'detailLink'.concat(collectionCounter);
    var docLink = 'docLink'.concat(collectionCounter);
    var itemNote = 'itemNote'.concat(collectionCounter);
    var itemDate = 'itemDate'.concat(collectionCounter);
    var feedName = 'feedName'.concat(collectionCounter);
    var branchName = 'branchName'.concat(collectionCounter);
    var topicName = 'topicName'.concat(collectionCounter);
  
    localStorage.setItem(title, saveCollectionTitle);
    localStorage.setItem(detailLink, saveCollectionDetailLink);
    localStorage.setItem(docLink, saveCollectionDocLink);
    localStorage.setItem(itemNote, searchItem);
    localStorage.setItem(itemDate, saveCollectionDate);
    localStorage.setItem(feedName, saveCollectionFeedName);
    localStorage.setItem(branchName, saveCollectionBranchName);
    localStorage.setItem(topicName, saveCollectionTopicName);
    localStorage.setItem('collectionNumber', collectionCounter);
    window.location.reload();
  }

  const handleShareClick = (event) => {
    event.stopPropagation(); // Prevent other click events from firing
    // console.log(`Current URL: ${window.location.href}`);
    const shareUrl = window.location.href;
    setShareLink(shareUrl);
    setShareModalIsOpen(true);
    setLinkCopied(false); // Reset the link copied state
  };

  const handleNativeShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: '',
          text: '',
          url: shareLink,
        });
     //   console.log('Content shared successfully!');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert('Sharing is not supported on this device.');
    }
  };

  const renderDataWithMessage = (data, renderFunction) => {
    if (data.length === 0) {
      return <p id="billBodyText" >No data currently available</p>;
    }
    return renderFunction();
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink).then(() => {
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 3000); // Reset after 3 seconds
    });
  };

  const clearData = () => {
    setToptierData([]);
    setFederalAccountsData([]);
    setProgramActivityData([]);
    setObjectClassData([]);
    setRecipientsData([]);
  };

  useEffect(() => {
    clearData(); // Ensure data is cleared before fetching
  
    if (!level) {
      fetchData(buildUrl('toptier_agencies'), setToptierData);
    } else if (level === 'agency') {
      fetchData(buildUrl('federal_accounts', 'agency_id', id), setFederalAccountsData);
    } else if (level === 'account') {
      fetchData(buildUrl('program_activity', 'federal_account_id', id), setProgramActivityData);
    } else if (level === 'program') {
      fetchData(buildUrl('object_class', 'program_activity_id', id), setObjectClassData);
    } else if (level === 'class') {
      fetchData(buildUrl('us_spending_recipients', 'program_activity_id', id), setRecipientsData);
    }
  }, [level, id]);
  
  // Watch for federal accounts data and fetch agency name
  useEffect(() => {
    if (federalAccountsData.length > 0) {
      const agencyId = federalAccountsData[0].agency_id;
      if (agencyId) {
        fetchAgencyName(agencyId);
      }
    }
  }, [federalAccountsData]);
  
  // Watch for program activity data and fetch agency name
  useEffect(() => {
    if (programActivityData.length > 0) {
      const agencyId = programActivityData[0].agency_id;
      if (agencyId) {
        fetchAgencyName(agencyId);
      }
    }
  }, [programActivityData]);
  
  // Watch for object class data and fetch agency name
  useEffect(() => {
    if (objectClassData.length > 0) {
      const agencyId = objectClassData[0].agency_id;
      if (agencyId) {
        fetchAgencyName(agencyId);
      }
    }
  }, [objectClassData]);
  
  // Watch for recipients data and fetch agency name
  useEffect(() => {
    if (recipientsData.length > 0) {
      const agencyId = recipientsData[0].agency_id;
      if (agencyId) {
        fetchAgencyName(agencyId);
      }
    }
  }, [recipientsData]);
  
  
  // New useEffect to monitor `federalAccountsData` and run `fetchAgencyName` only if data exists
  useEffect(() => {
    if (federalAccountsData.length > 0) {
      const agencyId = federalAccountsData[0].agency_id;
      if (agencyId) {
        fetchAgencyName(agencyId); // Fetch agency name only when agency_id is available
      }
    }
  }, [federalAccountsData]);
  

  // New: Filter data based on the search query
  const filterData = (data) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return data.filter(
      (item) =>
        item.name?.toLowerCase().includes(lowerCaseQuery) ||
        item.agency_name?.toLowerCase().includes(lowerCaseQuery) ||
        item.description?.toLowerCase().includes(lowerCaseQuery)
    );
  };
  const renderToptierData = () =>
  filterData(toptierData).map((item) => (
    <Container key={item.id} id="feedContainerNormalView" className="mb-4">
      <h4 id="billText" onClick={() => handleNavigation('agency', item.agency_id)}>
        {item.agency_name} ({item.abbreviation || 'N/A'})
      </h4>
      <p id="billBodyText">Active FY: {item.active_fy}, Quarter: {item.active_fq}</p>
      <p id="billBodyText">Budget Authority: ${item.budget_authority_amount.toLocaleString()}</p>
      <p id="billBodyText">Outlay Amount: ${item.outlay_amount.toLocaleString()}</p>
      {item.url && (
        <Button
          id="billLink"
          onClick={(e) => {
            e.stopPropagation();
            window.open(item.url, '_blank');
          }}
        >
          <FileText />  Budget
        </Button>
      )}
   <Button
                id="shareButton"
                onClick={(e) => {
                  e.stopPropagation();
                  handleShareClick(e);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-send"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                </svg>
              </Button>
    </Container>
  ));

  const renderFederalAccountsData = () => {
    const data = filterData(federalAccountsData);
  
    // Handle case where data is empty or unavailable
    if (!data || data.length === 0) {
      return (
        <Container id="feedContainerNormalView" className="mb-4">
          <h4 id="billText">No data currently available</h4>
        </Container>
      );
    }
  
    // Render the mapped data if available
    return data.map((item) => (
      <Container key={item.id} id="feedContainerNormalView" className="mb-4">
        <h4
          id="billText"
          onClick={() => handleNavigation('account', item.federal_account_id)}
        >
          {item.name || 'Unnamed Account'} <ChevronRight id='clickChevron'></ChevronRight>
        </h4>
        <p id="billBodyText">
          Account Number: {item.account_number || 'N/A'}
        </p>
        <p id="billBodyText">Amount: ${item.amount.toLocaleString()}</p>
        {item.url && (
          <Button
            id="billLink"
            onClick={(e) => {
              e.stopPropagation();
              window.open(item.url, '_blank');
            }}
          >
            <FileText /> Budget
          </Button>
        )}
        <Button
          id="shareButton"
          onClick={(e) => {
            e.stopPropagation();
            handleShareClick(e);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-send"
            viewBox="0 0 16 16"
            aria-hidden="true"
          >
            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
          </svg>
        </Button>
      </Container>
    ));
  };
  
  

const renderProgramActivityData = () =>
  filterData(programActivityData).map((item) => (
    <Container key={item.id} id="feedContainerNormalView" className="mb-4">
      <h4 id="billText" onClick={() => handleNavigation('program', item.program_activity_id)} >
        {item.name || 'Unnamed Program Activity'} <ChevronRight id='clickChevron'></ChevronRight>
      </h4>
      <p id="billBodyText">Amount: ${item.amount.toLocaleString()}</p>
      {item.url && (
        <Button
          id="billLink"
          onClick={(e) => {
            e.stopPropagation();
            window.open(item.url, '_blank');
          }}
        >
          <FileText />  Budget
        </Button>
      )}
   <Button
                id="shareButton"
                onClick={(e) => {
                  e.stopPropagation();
                  handleShareClick(e);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-send"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                </svg>
              </Button>
    </Container>
  ));

const renderObjectClassData = () =>
  filterData(objectClassData).map((item) => (
    <Container key={item.id} id="feedContainerNormalView" className="mb-4">
      <h4 id="billText" onClick={() => handleNavigation('class', item.program_activity_id)}>
        {item.name || 'Unnamed Object Class'} <ChevronRight id='clickChevron'></ChevronRight>
      </h4>
      <p id="billBodyText">Amount: ${item.amount.toLocaleString()}</p>
      {item.url && (
        <Button
          id="billLink"
          onClick={(e) => {
            e.stopPropagation();
            window.open(item.url, '_blank');
          }}
        >
          <FileText />  Budget
        </Button>
      )}
   <Button
                id="shareButton"
                onClick={(e) => {
                  e.stopPropagation();
                  handleShareClick(e);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-send"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                </svg>
              </Button>
    </Container>
  ));

const renderRecipientsData = () =>
  filterData(recipientsData).map((item) => (
    <Container key={item.id} id="feedContainerNormalView" className="mb-4">
      <h4 id="billText">{item.name || 'Unnamed Recipient'} <ChevronRight id='clickChevron'></ChevronRight></h4>
      <p id="billBodyText">Amount: ${item.amount.toLocaleString()}</p>
      {item.url && (
        <Button
          id="billLink"
          onClick={(e) => {
            e.stopPropagation();
            window.open(item.url, '_blank');
          }}
        >
          <FileText /> Budget
        </Button>
      )}
         <Button
                id="shareButton"
                onClick={(e) => {
                  e.stopPropagation();
                  handleShareClick(e);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-send"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                </svg>
              </Button>
    </Container>
  ));

  return (
    <>
      <BrowserView>
        <DesktopNavbar />
        <div id="feedPageFeed">
        {level && agencyName &&  <h3 id='branchHeading' > <b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-stack" viewBox="0 0 16 16">
  <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
  <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2z"/>
</svg>   {agencyName}</b></h3>} {/* Display agency name */}
  
        <div id="searchFormPage">
        <Form.Group className="d-flex justify-content-center align-items-center">
          <FormControl
          id="ggSearchCollection"
            type="text"
            placeholder="Search"
            className="mr-sm-2"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Form.Group>
        </div>
      
          {/* {error && (
            <Toast onClose={() => setError(null)}>
              <Toast.Header>
                <strong className="mr-auto">Error</strong>
              </Toast.Header>
              <Toast.Body>{error.message}</Toast.Body>
            </Toast>
          )} */}
          {loading ? (
            <div className="spinner-container">
              <Spinner animation="grow" />
            </div>
          ) : recipientsData.length ? (
            renderRecipientsData()
          ) : objectClassData.length ? (
            renderObjectClassData()
          ) : programActivityData.length ? (
            renderProgramActivityData()
          ) : federalAccountsData.length ? (
            renderFederalAccountsData()
          ) : (
            renderToptierData()
          )}
           <Modal
            id="shareModal"
            show={shareModalIsOpen}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => setShareModalIsOpen(false)}
          >
            <Modal.Header id="billModalHeader" closeButton>
              <h3>Share</h3>
            </Modal.Header>
            <Modal.Body id="descriptionModalBody">
              <Form.Group id="noteModalForm">
                <Form.Control
               
                  id="noteModalFeedItem"
                  type="text"
                  readOnly
                  value={shareLink}
                />
              </Form.Group>
              <Modal.Footer id="shareFooter">
              <Button 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>

                <Button id="addToCollectionButton" onClick={handleCopyLink}>
                  {linkCopied ? <ClipboardCheck /> : <Clipboard />}
                </Button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>
        </div>
      </BrowserView>

      <MobileView>
        <MobileTopNavbar />
     
        <div id="feedPageFeed">
        <div id="searchFormPage">
        <Form.Group className="d-flex justify-content-center align-items-center">
          <FormControl
          id="ggSearchCollectionMobile"
            type="text"
            placeholder="Search"
            className="mr-sm-2"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Form.Group>
        </div>
          {/* {error && (
        
            <Toast onClose={() => setError(null)}>
              <Toast.Header>
                <strong className="mr-auto">Error</strong>
              </Toast.Header>
              <Toast.Body>{error.message}</Toast.Body>
            </Toast>
          )} */}
          {loading ? (
            <div className="spinner-container">
              <Spinner animation="grow" />
            </div>
          ) : recipientsData.length ? (
            renderRecipientsData()
          ) : objectClassData.length ? (
            renderObjectClassData()
          ) : programActivityData.length ? (
            renderProgramActivityData()
          ) : federalAccountsData.length ? (
            renderFederalAccountsData()
          ) : (
            renderToptierData()
          )}
                    <Modal
            id="shareModal"
            show={shareModalIsOpen}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => setShareModalIsOpen(false)}
          >
            <Modal.Header id="billModalHeader" closeButton>
              <h3>Share</h3>
            </Modal.Header>
            <Modal.Body id="descriptionModalBody">
              <Form.Group id="noteModalForm">
                <Form.Control
               
                  id="noteModalFeedItem"
                  type="text"
                  readOnly
                  value={shareLink}
                />
              </Form.Group>
              <Modal.Footer id="shareFooter">
              <Button 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>

                <Button id="addToCollectionButton" onClick={handleCopyLink}>
                  {linkCopied ? <ClipboardCheck /> : <Clipboard />}
                </Button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>
        </div>
        <Navbar fixed="bottom">
        <MobileOffCanvas />
        </Navbar>
      </MobileView>
    </>
  );
}

export default DynamicData;