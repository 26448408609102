import App from "./App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, FolderPlus } from 'react-bootstrap-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import AddNoteModal from "./AddNoteModal";
import { render } from '@testing-library/react';
import raw from './xml/newXML.xml'



var collectionCounter = localStorage.getItem('collectionNumber')

function saveToCollection(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
    
  
  
  collectionCounter = localStorage.getItem('collectionNumber')
  //console.log(collectionCounter)
collectionCounter++;
  //console.log(collectionCounter)

var collectionTitles = []
 var collectionDetailLinks = []
var collectionDocLinks = []
var collectionNote = []

//collectionTitles.push(saveCollectionTitle)
//collectionDetailLinks.push(saveCollectionDetailLink)
//collectionDocLinks.push(saveCollectionDocLink)



 
  

 
  
  var title = 'title'.concat(collectionCounter)
  var detailLink = 'detailLink'.concat(collectionCounter)
  var docLink = 'docLink'.concat(collectionCounter)
  var itemNote = 'itemNote'.concat(collectionCounter)
  var itemDate = 'itemDate'.concat(collectionCounter)
  var feedName = 'feedName'.concat(collectionCounter)
  var branchName = 'branchName'.concat(collectionCounter)
  var topicName = 'topicName'.concat(collectionCounter)



  localStorage.setItem(title,saveCollectionTitle)
  
  localStorage.setItem(detailLink,saveCollectionDetailLink)
 
  localStorage.setItem(docLink,saveCollectionDocLink)
  localStorage.setItem(itemNote,saveCollectionNote)

  localStorage.setItem(itemDate,saveCollectionDate)
  localStorage.setItem(feedName,saveCollectionFeedName)
  localStorage.setItem(branchName,saveCollectionBranchName)
  localStorage.setItem(topicName,saveCollectionTopicName)

  localStorage.setItem('collectionNumber', collectionCounter)

return(
render(
<>
<AddNoteModal></AddNoteModal>




                       </>
)
)

  

  

}
/*W.I.P fetch function */


function AlabamaSoS()
 {

  const [completeBillFeed, setCompleteBillFeed] = React.useState([]);
  const [billTitle, setBillTitle] = React.useState([]);
  const [billLink, setBillLink] = React.useState([]);
  const [billDate, setBillDate] = React.useState([]);

  React.useEffect(async () => {

    let Parser = require('rss-parser');
    let parser = new Parser();

    const fBillFeed = [];
    const fBillTitle = [];
    const fBillLink = []
    const fBillDate = []


    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://www.sos.alabama.gov/newsroom/rss?_ga=2.44886628.1762786429.1626009166-1870547084.1626009166');
    feed.items.forEach(item => {

      fBillFeed.push(item.title + ':' + item.link);
      fBillTitle.push(item.title);
      fBillLink.push(item.link);
      fBillDate.push(item.pubDate.substring(0,10));


//implement Details link still

    });

    setCompleteBillFeed(fBillFeed);
    setBillTitle(fBillTitle);
    setBillLink(fBillLink);
    setBillDate(fBillDate);

  }, []);

  const renderData = () => {

    return completeBillFeed.map((f, index) => {

      const title = billTitle[index];
      const link = billLink[index];

      const date = billDate[index]
     
  
     

      return <div key={index} >
        <div id='fullBillFeed'>
          <Container id='feedContainer'>

            <Card.Title id='billText'>{billTitle[index]}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>
            
           

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, date, null, "Alabama Secretary of State", "Executive", "N/A"  )




            }><FolderPlus></FolderPlus></Button>

          </Container>

      </div>
      </div>
    })
  }

  return(
    <>

      {renderData()}
    </>
  )



  
}




export default AlabamaSoS;