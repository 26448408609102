import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, NavLink, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, List, InfoCircle, FileText, FileMedicalFill, Apple, Offcanvas, ReceiptCutoff, ShieldShaded, Globe, Tree, CircleFill, Pen, People, MicFill, ChatSquareQuote, ChatLeftQuote, Coin, BlockquoteRight, Collection, Grid, ThreeDots, Stack, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort, ArrowRightCircle, PlayCircle, PauseCircle, GeoAlt, HeartFill, Bricks, Lightbulb } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'


import MobileOffCanvas from '../MobileOffCanvas';

import TemplateMobileTopNavbar from './UKMobileTopNavbar';

import UKBillUpdates from './Legislative/UKBillUpdates';
import { CreateFeedAPI, CreateFeedAPIRecentCacheUK, CreateFeedItemUK, CreateFeedItemUKAtom } from '../Functions';
import HouseOfLordsResearch from './Legislative/HouseOfLordsResearch';
import PrimeMinisterNews from './Executive/PrimeMinisterNews';
import AttorneyGeneralNews from './Executive/AttorneyGeneralNews';
import CabinetNews from './Executive/CabinetNews';
import DepartmentForBusiness from './Executive/DepartmentForBusiness';
import DepartmentForHMTreasury from './Topics/Economy/DepartmentForHMTreasury';
import ExportFinance from './Topics/Economy/ExportFinance';
import DepartmentForEnvironment from './Topics/Environment/DepartmentForEnvironment';
import DepartmentForForeignCommonwealth from './Topics/Foreign Affairs/DepartmentForForeignCommonwealth';
import MinistryOfDefense from './Topics/Foreign Affairs/MinistryOfDefense';
import DepartmentCultureMediaAndSport from './Executive/DepartmentCultureMediaAndSport';
import DepartmentForHealth from './Topics/Health/DepartmentForHealth';
import DepartmentForEnergy from './Topics/Science and Tech/DepartmentForEnergy';
import DepartmentForScience from './Topics/Science and Tech/DepartmentForScience';
import DepartmentForTransport from './Topics/DepartmentForTransport';
import OffCanvasUK from './OffCanvasUK';
import MinistryOfJustice from './Topics/MinistryOfJustice';
import Defense from '../Defense';
import DepartmentForHousing from './Topics/DepartmentForHousing';
import GenerateFeed from '../GenerateFeed';
import GenerateFeedRecentUK from '../GenerateFeedRecentUK';
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);
const setHomeCountryAndReload = (homeCountry) => {
  localStorage.setItem('homeCountry', homeCountry);
  //console.log(localStorage.getItem('homeCountry'))

};

const mobileCarouselInterval = 60000;

const UKHomeMobile = () => {

  const [homeCountry, setHomeCountry] = useState('');
  const [fecModalIsOpen, setFECModalIsOpen] = useState(false);
  const [billUpdates, setBillUpdatesModalIsOpen] = useState(false)
  const [lordsResearchModal, setLordsResearchModalIsOpen] = useState(false)
  const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen] = useState(false)
  const [attorneyGeneralModal, setAttorneyGeneralModalIsOpen] = useState(false)
  const [modal1, setModal1IsOpen] = useState(false);
  const [modal2, setModal2IsOpen] = useState(false);
  const [modal3, setModal3IsOpen] = useState(false);
  const [modal4, setModal4IsOpen] = useState(false);
  const [modal5, setModal5IsOpen] = useState(false);
  const [modal6, setModal6IsOpen] = useState(false);
  const [modal7, setModal7IsOpen] = useState(false);
  const [modal8, setModal8IsOpen] = useState(false);
  const [modal9, setModal9IsOpen] = useState(false);
  const [modal10, setModal10IsOpen] = useState(false);
  const [modal11, setModal11IsOpen] = useState(false);
  const [modal12, setModal12IsOpen] = useState(false);
  const [modal13, setModal13IsOpen] = useState(false);
  const [modal14, setModal14IsOpen] = useState(false);
  const [modal15, setModal15IsOpen] = useState(false);
  const [modal16, setModal16IsOpen] = useState(false);
  const [modal17, setModal17IsOpen] = useState(false);
  const [modal18, setModal18IsOpen] = useState(false);
  const [modal19, setModal19IsOpen] = useState(false);
  const [modal20, setModal20IsOpen] = useState(false);
  const [modal21, setModal21IsOpen] = useState(false);
  const [modal22, setModal22IsOpen] = useState(false);
  const [modal23, setModal23IsOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };
 
    localStorage.setItem('currentPage', 'uk')
    return (
      <>

        <div id='homebg'>

          {/*Top Nav Bar for Country */}
          <TemplateMobileTopNavbar />


          <Col>

<h5 id='recentsHeading' onClick={() =>
                            openModal('Recents',
                              <GenerateFeedRecentUK/>)}>Recents</h5>

<div class='courtNewsMobile' id='recent'></div> 
{CreateFeedAPIRecentCacheUK('https://api.govglance.org/posts/recent/schema?limit=1&skip=0&schema=united_kingdom', 'recent', 
                'Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeedRecentUK)}


</Col>


          {/*Legislative Mobile Carousel*/}
          <h5 id='branchHeadingMobile' onClick={() => window.open('/uk/legislative', "_self")} > <Pen></Pen> Legislative<ChevronRight id='clickChevron'></ChevronRight></h5>


          <Carousel controls={false} >


            <Carousel.Item interval={mobileCarouselInterval}>

              <div id='legislativeConMobile' >


                <Col>



                  <Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() =>
                            openModal('Bill Updates',
                              <GenerateFeed
                                url='&skip=0&schema=united_kingdom&table=all_bills&order_by=created_at'
                                name='UK Bill Updates' branch='Legislative' topic='N/A' buttonName='Bill' />)}>Bill Updates</h5></Row>

                  <div id='billUpdates'></div>

                  {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                  {CreateFeedAPI('&skip=0&schema=united_kingdom&table=all_bills&order_by=created_at', 'billUpdates', 
                            'UK Bill Updates', 'Legislative', 
                            'N/A', 'Bill', GenerateFeed)}

                </Col>




              </div>
            </Carousel.Item>

            <Carousel.Item interval={mobileCarouselInterval}>
              <div id='legislativeConMobile' >
                <Col>



                  <Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setLordsResearchModalIsOpen(true)}>House of Lords Research</h5></Row>

                  <div id='lordsResearch'></div>

                  {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                  {CreateFeedItemUK('https://lordslibrary.parliament.uk/type/research-briefing/feed/',
                    'lordsResearch', 'House of Lords Research', 'Legislative', 'N/A', HouseOfLordsResearch)}

                  <Modal id='mobileFullBillModal' show={lordsResearchModal}
                    size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLordsResearchModalIsOpen(false)}>
                    <Modal.Header id='mobileBillModalHeader' closeButton><h3>House of Lords Research</h3></Modal.Header>
                    <HouseOfLordsResearch />
                  </Modal>
                </Col>

              </div>
            </Carousel.Item>

            <Carousel.Item interval={mobileCarouselInterval}>

              <div id='legislativeConMobile'>

                <Col>
                  <div id='viewFullPageButton'>
                    <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/legislative' >View all Legislative news</Button>
                  </div>
                </Col>

              </div>



            </Carousel.Item>

          </Carousel>

          {/* Executive Mobile Carousel*/}
          <h5 id='branchHeadingMobile' onClick={() => window.open('/uk/executive', "_self")} ><People></People> Executive<ChevronRight id='clickChevron'></ChevronRight></h5>
          <Carousel controls={false}>



            <Carousel.Item interval={mobileCarouselInterval}>
              <div id='legislativeConMobile' >
                <Col>



                  <Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() =>
                            openModal('Prime Minister',
                              <GenerateFeed
                                url='&skip=0&schema=united_kingdom&table=prime_minister&order_by=created_at'
                                name='Prime Minister' branch='Executive' topic='N/A' buttonName='Doc' />)}>Prime Minister</h5></Row>

                  <div id='primeMinister'></div>

{/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedAPI('&skip=0&schema=united_kingdom&table=courts_and_tribunals&order_by=created_at', 'primeMinister', 
                            'Prime Minister', 'Executive', 
                            'N/A', 'Docs', GenerateFeed)}
                </Col>

              </div>
            </Carousel.Item>

            <Carousel.Item interval={mobileCarouselInterval}>
              <div id='legislativeConMobile' >
                <Col>



                  <Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome'  onClick={() =>
                            openModal('Attorney General',
                              <GenerateFeed
                                url=' &skip=0&schema=united_kingdom&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Attorney%20Generals%20Office'
                                name='Attorney General' branch='Executive' topic='N/A' buttonName='Doc' />)}>Attorney General</h5></Row>

                  <div id='attorneyGeneral'></div>

                  {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                  {CreateFeedAPI(' &skip=0&schema=united_kingdom&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Attorney%20Generals%20Office', 'attorneyGeneral', 
                            'Attorney General', 'Executive', 
                            'N/A', 'Docs', GenerateFeed)}
                </Col>

              </div>
            </Carousel.Item>


            <Carousel.Item interval={mobileCarouselInterval}>
              <div id='legislativeConMobile' >
                <Col>



                  <Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setModal1IsOpen(true)}>Cabinet</h5></Row>

                  <div id='cabinet'></div>

                  {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                  {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/cabinet-office.atom',
                    'cabinet', 'Cabinet', 'Executive', 'N/A', CabinetNews)}

                  <Modal id='mobileFullBillModal' show={modal1}
                    size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal1IsOpen(false)}>
                    <Modal.Header id='mobileBillModalHeader' closeButton><h3>Cabinet</h3></Modal.Header>
                    <CabinetNews />
                  </Modal>
                </Col>

              </div>
            </Carousel.Item>


            <Carousel.Item interval={mobileCarouselInterval}>

              <div id='legislativeConMobile'>

                <Col>
                  <div id='viewFullPageButton'>
                    <Button id='viewFullLegislativePageButtonMobile' size='lg'  href='/uk/executive'>View all Executive news</Button>
                  </div>
                </Col>

              </div>



            </Carousel.Item>

          </Carousel >


          {/* Judicial Mobile Carousel*/}
          {/*<Link  onClick={() => handleHapticsClick(() => {})} id='pageLink' to="/uk/judicial">*/} <h5 id='branchHeadingMobile' onClick={() => window.open('/uk/judicial', "_self")}><svg xmlns="http://www.w3.org/2000/svg" height="27.54px" viewBox="0 -960 960 960" width="27.54px" fill="#FFFFFF"><path d="M160-120v-80h480v80H160Zm226-194L160-540l84-86 228 226-86 86Zm254-254L414-796l86-84 226 226-86 86Zm184 408L302-682l56-56 522 522-56 56Z"/></svg>   Judicial<ChevronRight id='clickChevron'></ChevronRight></h5>{/**/}
          <Carousel controls={false} >




          <Carousel.Item interval={mobileCarouselInterval}>
              <div id='legislativeConMobile' >
                <Col>



                  <Row id='mobileFeedHeadingRow'> <h5 id='presidentialFeedHeadingMobile' onClick={() => setModal14IsOpen(true)}>Supreme Court</h5></Row>

                  <div id='cabinet'></div>

                  {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                  {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/cabinet-office.atom',
                    'cabinet', 'Cabinet', 'Executive', 'N/A', CabinetNews)}

                  <Modal id='mobileFullBillModal' show={modal14}
                    size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal14IsOpen(false)}>
                    <Modal.Header id='mobileBillModalHeader' closeButton><h3>Cabinet</h3></Modal.Header>
                    <CabinetNews />
                  </Modal>
                </Col>

              </div>
            </Carousel.Item>
            <Carousel.Item interval={mobileCarouselInterval}>
              <div id='legislativeConMobile' >
                <Col>



                  <Row id='mobileFeedHeadingRow'> <h5 id='presidentialFeedHeadingMobile' oonClick={() =>
                            openModal('Court and Tribunals',
                              <GenerateFeed
                                url='&skip=0&schema=united_kingdom&table=courts_and_tribunals&order_by=created_at'
                                name='Court and Tribunals' branch='Judicial' topic='N/A' buttonName='Doc' />)}>Court and Tribunals</h5></Row>

<div id='courtTrib'></div>
                          
                          {CreateFeedAPI('&skip=0&schema=united_kingdom&table=courts_and_tribunals&order_by=created_at', 'courtTrib', 
                                                    'Court and Tribunals', 'Judicial', 
                                                    'N/A', 'Docs', GenerateFeed)}
                </Col>

              </div>
            </Carousel.Item>


            <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

  <Col>
    <div id='viewFullPageButton'>
      <Button id='viewFullLegislativePageButtonMobile' size='lg'  href='/uk/judicial'>View all Judicial news</Button>
    </div>
  </Col>

</div>



</Carousel.Item>

          </Carousel>

          {/* Economy */}
         <h5 id='branchHeadingMobile' onClick={() => window.open('/uk/economy', "_self")}> <Coin></Coin>    Economy<ChevronRight id='clickChevron'></ChevronRight></h5>
          <Carousel controls={false}>

            <Carousel.Item interval={mobileCarouselInterval}>

              <div id='legislativeConMobile' >


                <Col>



                  <Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setModal2IsOpen(true)}>Department for Business and Trade</h5></Row>

                  <div id='deptOfBusinessAndTrade'></div>

                  {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                  {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-business-and-trade.atom',
                    'deptOfBusinessAndTrade', 'Department of Business and Trade', 'Executive', 'Economy', DepartmentForBusiness)}

                  <Modal id='mobileFullBillModal' show={modal2}
                    size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal2IsOpen(false)}>
                    <Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Business and Trade</h3></Modal.Header>
                    <DepartmentForBusiness />
                  </Modal>
                </Col>


              </div>
            </Carousel.Item>

            <Carousel.Item interval={mobileCarouselInterval}>

              <div id='legislativeConMobile' >


                <Col>



                  <Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setModal13IsOpen(true)}>HM Treasury</h5></Row>

                  <div id='deptOfTreasury'></div>

                  {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                  {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/hm-treasury.atom',
                    'deptOfTreasury', 'HM Treasury', 'Executive', 'Economy', DepartmentForHMTreasury)}

                  <Modal id='mobileFullBillModal' show={modal13}
                    size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal13IsOpen(false)}>
                    <Modal.Header id='mobileBillModalHeader' closeButton><h3>HM Treasury</h3></Modal.Header>
                    <DepartmentForHMTreasury />
                  </Modal>
                </Col>


              </div>
            </Carousel.Item>

            <Carousel.Item interval={mobileCarouselInterval}>

              <div id='legislativeConMobile' >


                <Col>



                  <Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setModal23IsOpen(true)}>Export Finance</h5></Row>

                  <div id='exportFinance'></div>

                  {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                  {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/uk-export-finance.atom',
                    'exportFinance', 'Export Finance ', 'Executive', 'Economy', ExportFinance)}

                  <Modal id='mobileFullBillModal' show={modal23}
                    size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal23IsOpen(false)}>
                    <Modal.Header id='mobileBillModalHeader' closeButton><h3>Export Finance</h3></Modal.Header>
                    <ExportFinance />
                  </Modal>
                </Col>


              </div>
            </Carousel.Item>

            <Carousel.Item interval={mobileCarouselInterval}>
              <div id='legislativeConMobile'>


                <Col>
                  <div id='viewFullPageButton'>
                   <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/economy'>View all Economy news</Button>
                  </div>

                </Col>


              </div>
            </Carousel.Item>

          </Carousel>
          {/*Election */}
          {/*<Link onClick={() => handleHapticsClick(() => {})} id='pageLink' to="/uk/election">*/}<h5 id='branchHeadingMobile' > <ReceiptCutoff></ReceiptCutoff>   Elections<ChevronRight id='clickChevron'></ChevronRight></h5>{/**/}
          <Carousel controls={false}>



            <Carousel.Item interval={mobileCarouselInterval}>
              <div id='legislativeConMobile'>


                <Col>
                  <div id='viewFullPageButton'>
                    {/*<Link onClick={() => handleHapticsClick(() => { })} id='pageLink' to="/uk/election">*/}<Button id='viewFullLegislativePageButtonMobile' size='lg' >Coming Soon</Button>{/**/}
                   
                  </div>

                </Col>


              </div>
            </Carousel.Item>

          </Carousel>

          {/*Environment */}
          <h5 id='branchHeadingMobile' onClick={() => window.open('/uk/environment', "_self")}> <Tree></Tree>    Environment<ChevronRight id='clickChevron'></ChevronRight></h5>
          <Carousel controls={false}>

          <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >


  <Col>



    <Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setModal6IsOpen(true)}>Department for Environment Food & Rural Affairs</h5></Row>

<div id='deptOfEnvironmentFood'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-environment-food-rural-affairs.atom',
   'deptOfEnvironmentFood', 'Department for Environment Food & Rural Affairs', 'Executive', 'Environment', DepartmentForEnvironment)}

<Modal id='mobileFullBillModal' show={modal6} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal6IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Environment Food & Rural Affairs</h3></Modal.Header>
<DepartmentForEnvironment/>
</Modal>
  </Col>


</div>
</Carousel.Item>


            <Carousel.Item interval={mobileCarouselInterval}>
              <div id='legislativeConMobile'>


                <Col>
                  <div id='viewFullPageButton'>
                    <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/environment'>View all Environment news</Button>
                  </div>

                </Col>


              </div>
            </Carousel.Item>

          </Carousel>

          {/*Foreign Affairs */}
          <h5 id='branchHeadingMobile' onClick={() => window.open('/uk/foreignaffairs', "_self")}> <Globe></Globe>    Foreign Affairs<ChevronRight id='clickChevron'></ChevronRight></h5>
          <Carousel controls={false}>


          <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >


  <Col>



    <Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome'onClick={() => setModal12IsOpen(true)}>Foreign, Commonwealth & Development Office</h5></Row>

<div id='deptOfForeign'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/foreign-commonwealth-development-office.atom',
   'deptOfForeign', 'Foreign, Commonwealth & Development Office', 'Executive', 'Foreign Affairs', DepartmentForForeignCommonwealth)}

<Modal id='mobileFullBillModal' show={modal12} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal12IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Foreign, Commonwealth & Development Office</h3></Modal.Header>
<DepartmentForForeignCommonwealth/>
</Modal>
  </Col>


</div>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >


  <Col>



    <Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setModal15IsOpen(true)}>Ministry of Defence</h5></Row>

<div id='deptOfDefence'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/ministry-of-defence.atom',
   'deptOfDefence', 'Ministry of Defence', 'Executive', 'Foreign Affairs', MinistryOfDefense)}

<Modal id='mobileFullBillModal' show={modal15} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal15IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Ministry of Defence</h3></Modal.Header>
<MinistryOfDefense/>
</Modal>
  </Col>


</div>
</Carousel.Item>

            <Carousel.Item interval={mobileCarouselInterval}>
              <div id='legislativeConMobile'>

                <Col>
                  <div id='viewFullPageButton'>
                    <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/foreignaffairs'>View all Foreign Affairs news</Button>
                  </div>

                </Col>


              </div>
            </Carousel.Item>

          </Carousel>


          {/* Health*/}
          <h5 id='branchHeadingMobile' onClick={() => window.open('/uk/health', "_self")}> <FileMedicalFill></FileMedicalFill>    Health<ChevronRight id='clickChevron'></ChevronRight></h5>
          <Carousel controls={false}>

          <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >


  <Col>



    <Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setModal3IsOpen(true)}>Department for Culture Media and Sport</h5></Row>

<div id='deptForCulture'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-culture-media-and-sport.atom',
   'deptForCulture', 'Department for Culture Media and Sport', 'Executive', 'Health', DepartmentCultureMediaAndSport)}

<Modal id='mobileFullBillModal' show={modal3} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal3IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Culture Media and Sport</h3></Modal.Header>
<DepartmentCultureMediaAndSport/>
</Modal>
  </Col>


</div>
</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >


  <Col>



    <Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setModal11IsOpen(true)}>Department for Health & Social Care</h5></Row>

<div id='deptOfHealth'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-of-health-and-social-care.atom',
   'deptOfHealth', 'Department for Health & Social Care', 'Executive', 'Health', DepartmentForHealth)}

<Modal id='mobileFullBillModal' show={modal11} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal11IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Health & Social Care</h3></Modal.Header>
<DepartmentForHealth/>
</Modal>
  </Col>


</div>
</Carousel.Item>



            <Carousel.Item interval={mobileCarouselInterval}>
              <div id='legislativeConMobile'>


                <Col>
                  <div id='viewFullPageButton'>
                    <Button id='viewFullLegislativePageButtonMobile' size='lg'  href='/uk/health'>View all Health news</Button>
                  </div>

                </Col>


              </div>
            </Carousel.Item>

          </Carousel>





          <h5 id='branchHeadingMobile' onClick={() => window.open('/uk/science&tech', "_self")}> <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-rocket-takeoff" viewBox="0 0 16 16">
            <path d="M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z" />
            <path d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z" />
            <path d="M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z" />
          </svg>  Science & Tech<ChevronRight id='clickChevron'></ChevronRight></h5>

          <Carousel controls={false}>
          <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >


  <Col>



    <Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setModal5IsOpen(true)}>Department for Energy Security & Net Zero</h5></Row>

<div id='deptOfEnergy'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-energy-security-and-net-zero.atom',
   'deptOfEnergy', 'Department for Energy Security & Net Zero', 'Executive', 'Science & Tech', DepartmentForEnergy)}

<Modal id='mobileFullBillModal' show={modal4} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal4IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Energy Security & Net Zero</h3></Modal.Header>
<DepartmentForEnergy/>
</Modal>
  </Col>


</div>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >


  <Col>



    <Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setModal8IsOpen(true)}>Department for Science, Innovation & Technology</h5></Row>

<div id='deptOfScience'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-science-innovation-and-technology.atom',
   'deptOfScience', 'Department for Science, Innovation & Technology', 'Executive', 'Science & Tech', DepartmentForScience)}

<Modal id='mobileFullBillModal' show={modal8} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal8IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Science, Innovation & Technology</h3></Modal.Header>
<DepartmentForScience/>
</Modal>
  </Col>


</div>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >


  <Col>



    <Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setModal10IsOpen(true)}>Department for Transport</h5></Row>

<div id='deptOfTransport'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-transport.atom',
   'deptOfTransport', 'Department for Transport', 'Executive', 'Science & Tech', DepartmentForTransport)}

<Modal id='mobileFullBillModal' show={modal10} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal10IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Transport</h3></Modal.Header>
<DepartmentForTransport/>
</Modal>
  </Col>


</div>
</Carousel.Item>

            <Carousel.Item interval={mobileCarouselInterval}>
              <div id='legislativeConMobile'>


                <Col>
                  <div id='viewFullPageButton'>
                    <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/science&tech' >View all Science and Tech news</Button>
                  </div>

                </Col>


              </div>
            </Carousel.Item>

          </Carousel>
          <h5 id='branchHeadingMobile' onClick={() => window.open('/uk/defense', "_self")}> <ShieldShaded/>  Defense<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>
<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >


<Col>



<Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setModal15IsOpen(true)}>Ministry of Defence</h5></Row>

<div id='deptOfDefence2'></div>
              
              {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/ministry-of-defence.atom',
'deptOfDefence2', 'Ministry of Defence', 'Executive', 'Foreign Affairs', MinistryOfDefense)}


</Col>


</div>
</Carousel.Item>



  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='legislativeConMobile'>


      <Col>
        <div id='viewFullPageButton'>
        <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/defense' >View all Defense news</Button>

        </div>

      </Col>


    </div>
  </Carousel.Item>

</Carousel>


<h5 id='branchHeadingMobile' onClick={() => window.open('/uk/infrastructure', "_self")}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
<path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
<path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3z"/>
</svg>  Infrastructure<ChevronRight id='clickChevron'></ChevronRight></h5>

<Carousel controls={false}>
<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >


<Col>



<Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setModal10IsOpen(true)}>Department for Transport</h5></Row>

<div id='deptOfTransport2'></div>
              
              {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-transport.atom',
'deptOfTransport2', 'Department for Transport', 'Executive', 'Science & Tech', DepartmentForTransport)}

<Modal id='mobileFullBillModal' show={modal10} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal10IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Transport</h3></Modal.Header>
<DepartmentForTransport/>
</Modal>
</Col>


</div>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >


<Col>



<Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setModal22IsOpen(true)}>Department for Levelling Up, Housing & Communities</h5></Row>

<div id='deptOfHousing2'></div>
                
                {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-levelling-up-housing-and-communities.atom',
'deptOfHousing2', 'Department for Levelling Up, Housing & Communities', 'Executive', 'N/A', DepartmentForHousing)}

<Modal id='mobileFullBillModal' show={modal22} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal22IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Levelling Up, Housing & Communities</h3></Modal.Header>
<DepartmentForHousing/>
</Modal>
</Col>


</div>
</Carousel.Item>



<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>


<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/infrastructure' >View all Infrastructure news</Button>

</div>

</Col>


</div>
</Carousel.Item>

</Carousel>


<h5 id='branchHeadingMobile' onClick={() => window.open('/uk/justice', "_self")}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-arms-up" viewBox="0 0 16 16">
<path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
<path d="m5.93 6.704-.846 8.451a.768.768 0 0 0 1.523.203l.81-4.865a.59.59 0 0 1 1.165 0l.81 4.865a.768.768 0 0 0 1.523-.203l-.845-8.451A1.5 1.5 0 0 1 10.5 5.5L13 2.284a.796.796 0 0 0-1.239-.998L9.634 3.84a.7.7 0 0 1-.33.235c-.23.074-.665.176-1.304.176-.64 0-1.074-.102-1.305-.176a.7.7 0 0 1-.329-.235L4.239 1.286a.796.796 0 0 0-1.24.998l2.5 3.216c.317.316.475.758.43 1.204Z"/>
</svg>  Justice<ChevronRight id='clickChevron'></ChevronRight></h5>

<Carousel controls={false}>
<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >


<Col>



<Row id='mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setModal20IsOpen(true)}>Ministry of Justice</h5></Row>

<div id='deptOfJustice2'></div>
                
                {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/ministry-of-justice.atom',
'deptOfJustice2', 'Ministry of Justice', 'Executive', 'N/A', MinistryOfJustice)}

<Modal id='mobileFullBillModal' show={modal20} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal20IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Ministry of Justice</h3></Modal.Header>
<MinistryOfJustice/>
</Modal>
</Col>


</div>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>


<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/uk/justice' >View all Justice news</Button>

</div>

</Col>


</div>
</Carousel.Item>

</Carousel>

          <br></br>

          <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />

<br></br>

<h5 id='branchHeadingMobile' > <GeoAlt/>  Countries <ChevronRight id='clickChevron'></ChevronRight></h5>
<br></br>
<div id='homebg'>  
<Container>
                      <Col>
                      <Row id='statesRow'>
                  <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/uk/england', '_self')
                      }}> <Image id='stateFlagMobile'  src='/englandFlag.png'></Image>England</Button>
                        <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/uk/northernIreland', '_self')
                      }}><Image id='stateFlagMobile'  src='/northernIrelandFlag.png'></Image>Northern Ireland</Button>
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/uk/scotland', '_self')
                      }}> <Image id='stateFlagMobile'  src='/scotlandFlag.png'></Image>Scotland</Button>
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/uk/wales', '_self')
                      }}> <Image id='stateFlagMobile'  src='/walesFlag.png'></Image>Wales</Button>
                      </Row>
               
                
   
                     </Col>
                     </Container>
                     </div>

                     <br></br>


 {/*Make the Country Picker into a component */}
 <div className="d-flex flex-column align-items-center text-center">  
      <h5 id='branchHeadingMobile2'><GeoAlt/> Change country <ChevronRight id='clickChevron'></ChevronRight></h5>
</div>
<Col className="d-flex flex-column align-items-center">
  <Button id='selectHomeCountry' onClick={() => window.open('/canada', "_self")}>
    🇨🇦 Canada
  </Button>

  <Button id='selectHomeCountry' onClick={() => window.open('/mexico', "_self")}>
    🇲🇽 Mexico
  </Button>

  <Button id='selectHomeCountry' onClick={() => window.open('/uk', "_self")}>
    🇬🇧 United Kingdom
  </Button>

  <Button id='selectHomeCountry' onClick={() => window.open('/us', "_self")}>
    🇺🇸 United States
  </Button>
</Col>

         

          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
            e.preventDefault();
            window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
          }}><HeartFill id='donateIcon' />    Donate</Button></h5>


          <br></br><br></br><br></br>
          <Navbar id='navbarMobileBottom' fixed="bottom">




            <div id='changeBranchDropdownMobile'>



              <OffCanvasUK/>
           

            </div>

          </Navbar>

          <br></br><br></br>
          {/* 
<Offcanvas show={showOffCanvas} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas>
      */}
        </div>
      </>
    )

  }

export default UKHomeMobile;
