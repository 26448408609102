import App from "../../../App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, Coin, PauseCircle, PlayCircle, FolderPlus, ChevronRight, Files, Folder, Pen, ChatLeftQuote, ShieldShaded, People, HeartFill, Bricks, Lightbulb } from 'react-bootstrap-icons'
import '../../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import UKMobileTopNavbar from "../../UKMobileTopNavbar";
import MobileOffCanvas from "../../../MobileOffCanvas";
import { CreateFeedItemUKAtom } from "../../../Functions";
import DepartmentForEnergy from "../Science and Tech/DepartmentForEnergy";
import DepartmentForHousing from "../DepartmentForHousing";
import DepartmentForTransport from "../DepartmentForTransport";

var collectionCounter = 0  
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;



export const UKInfrastructureMobile = () =>   {
  const [modal1, setModal1IsOpen] = useState(false);
const [modal2, setModal2IsOpen] = useState(false);
const [modal3, setModal3IsOpen] = useState(false);
const [modal4, setModal4IsOpen] = useState(false);
const [modal5, setModal5IsOpen] = useState(false);
const [modal6, setModal6IsOpen] = useState(false);
const [modal7, setModal7IsOpen] = useState(false);
const [modal8, setModal8IsOpen] = useState(false);
const [modal9, setModal9IsOpen] = useState(false);
const [modal10, setModal10IsOpen] = useState(false);
const [modal11, setModal11IsOpen] = useState(false);
const [modal12, setModal12IsOpen] = useState(false);
const [modal13, setModal13IsOpen] = useState(false);
const [modal14, setModal14IsOpen] = useState(false);
const [modal15, setModal15IsOpen] = useState(false);
const [modal16, setModal16IsOpen] = useState(false);
const [modal17, setModal17IsOpen] = useState(false);
const [modal18, setModal18IsOpen] = useState(false);
const [modal19, setModal19IsOpen] = useState(false);
const [modal20, setModal20IsOpen] = useState(false);
const [modal23, setModal23IsOpen] = useState(false);
    render()

    {
  
          const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
      
        localStorage.setItem('currentPage', 'uk')
    return(
       <>
    <UKMobileTopNavbar/>
  
  
  
  
  <div id='homebg'>

<h5 id='branchHeadingMobile'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
  <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
  <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3z"/>
</svg> </h5>
{/*onClick={() => setModalIsOpen(true)}*/}

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setModal9IsOpen(true)}>Department for Transport</h5>

  <div id='deptOfTransport'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-transport.atom',
     'deptOfTransport', 'Department for Transport', 'Executive', 'Science & Tech', DepartmentForTransport)}

  <Modal id='mobileFullBillModal' show={modal9} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal9IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Transport</h3></Modal.Header>
<DepartmentForTransport/>
</Modal>

    
</Col>


<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setModal7IsOpen(true)}>Department for Levelling Up, Housing & Communities</h5>

  <div id='deptOfHousing'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-levelling-up-housing-and-communities.atom',
     'deptOfHousing', 'Department for Levelling Up, Housing & Communities', 'Executive', 'N/A', DepartmentForHousing)}

  <Modal id='mobileFullBillModal' show={modal7} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal7IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Levelling Up, Housing & Communities</h3></Modal.Header>
<DepartmentForHousing/>
</Modal>

    
</Col>
<Col>

<br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>

<br></br><br></br><br></br><br></br><br></br><br></br>

<Navbar id='navbarMobileBottom' fixed="bottom">  



       
<div id='changeBranchDropdownMobile'>

                  <MobileOffCanvas/>
      
              
            </div>

</Navbar>
 
             
 
 
 
 
 
 

</div>



</>
)
}}
export default UKInfrastructureMobile;


