import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Coin, Tree, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import  "../../App.css";
import 'bootstrap/dist/css/bootstrap.min.css'

import Axios from 'axios'
import { render } from '@testing-library/react';



import CanadaMobileTopNavbar from '../CanadaMobileTopNavbar';
import { CreateFeedItem } from '../../Functions';
import GDPUpdates from '../../GDPUpdates';

import MobileOffCanvas from '../../MobileOffCanvas';
import StandingCommitteeOnEnvironment from '../Legislative/StandingCommitteeOnEnvironment';
import StandingCommitteeOnIndustryAndTech from '../Legislative/StandingCommitteeOnIndustryAndTech';
import MobileOffCanvasCanada from '../../MobileOffCanvasCanada';


var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;




export const CanadaScienceAndTechMobile = () =>   {
    render()
 
    {
      const [show, setShow] = useState(true);

      
      const [fullscreen, setFullScreen] = useState(true);
    
    
      const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
    
      const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
    
    
      const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
      const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
    
    
      const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
      const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
    
      const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
    
      const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
    
      const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
    
      const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
    
    
      const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
      const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
      const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
      const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
      const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
      const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
      const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
      const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
      const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
      const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
      const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
      const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
      const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
      const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
      const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
      const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
      const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
      const [beaModalIsOpen, setBEAModalIsOpen] = useState(false)
      const [secPressModalIsOpen, setSECPressModalIsOpen] = useState(false)
    
      const [gdpUpdatesModalIsOpen, setGDPUpdatesModalIsOpen] = useState(false)
      const [inflationUpdatesModalIsOpen, setInflationUpdatesModalIsOpen] = useState(false)
      const [wageGrowthModalIsOpen, setWageGrowthModalIsOpen] = useState(false)
      const [bureauOfLaborStatisticsModalIsOpen, setBureauOfLaborStatisticsModalIsOpen] = useState(false)
      const [irsDocumentsModalIsOpen, setIRSDocumentsModalIsOpen] = useState(false)
      const [governmentContractsModalIsOpen, setGovernmentContractsModalIsOpen] = useState(false)
    
    
        const [secSAndSModalIsOpen, setSECSAndSModalIsOpen] = useState(false)
      const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
      const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    
      const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
      const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
      const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
      const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
      const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
      const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
      const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
      const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
      const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
      const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
      const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
      const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
      const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
      const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
      const [houseCommitteeOnSmallBusinessModalIsOpen, setHouseCommitteeOnSmallBusinessModalIsOpen] = useState(false)
      const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
      const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
    
      const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
      const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
    
    
    
      const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
      const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
      const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
    

      const [officeOfManagementModalIsOpen, setOfficeOfManagmentModalIsOpen] = useState(false)
      const [energyMaterialsmMdalIsOpen, setEnergyMaterialsModalIsOpen] = useState(false)
      const [interstateMaterialsModalIsOpen, setInterstateMaterialsModalIsOpen] = useState(false)
      const [budgetMaterialsModalIsOpen, setBudgetMaterialsModalIsOpen] = useState(false)
      const [educationMaterialsModalIsOpen, setEducationMaterialsModalIsOpen] = useState(false)
      const [financialServicesMaterialsModalIsOpen, setFinancialServicesMaterialsModalIsOpen] = useState(false)
      const [smallBusinessMaterialsModalIsOpen, setSmallBusinessMaterialsModalIsOpen] = useState(false)
      const [senateSmallBusinessMaterialsModalIsOpen, setSenateSmallBusinessMaterialsModalIsOpen] = useState(false)
      const [senateCommitteeOnEnergyModalIsOpen, setSenateCommitteeOnEnergyModalIsOpen] = useState(false)
      const [senateBudgetMaterialsModalIsOpen, setSenateBudgetMaterialsModalIsOpen] = useState(false)
      const [senateFinanceMaterialsModalIsOpen, setSenateFinaceMaterialsModalIsOpen] = useState(false)
      const [senateTransportationMaterialsModalIsOpen, setSenateTransportationMaterialsModalIsOpen] = useState(false)
      const [senateCommitteeOnTheBudgetModalIsOpen, setSenateCommitteeOnTheBudgetModalIsOpen] = useState(false)
      const [senateHousingMaterialsModalIsOpen, setSenateHousingMaterialsModalIsOpen] = useState(false)
      const [commerceDocsModalIsOpen, setCommerceDocsModalIsOpen] = useState(false)
      const [censusBureauModalIsOpen, setCensusBureauModalIsOpen] = useState(false)
      const [economicAnalysisModalIsOpen, setEconomicAnalysisModalIsOpen] = useState(false)
      const [ecoDevelopmentModalIsOpen, setEconDevelopmentModalIsOpen] = useState(false)
      const [enforcementsModalIsOpen, setEnforcemnetsModalIsOpen] = useState(false)
      const nullEntry = [];
      const [notifications, setNotifications] = useState(nullEntry);
  
      const [topic, setTopic] = useState(null);
  
  


    //const [ModalIsOpen, setModalIsOpen] = useState(false)
    
    var branch = "all";


  
    return(
       <>
      <CanadaMobileTopNavbar/>

       <div id='homebg'>
      
     


       <h5 id='branchHeadingMobile' > <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-rocket-takeoff" viewBox="0 0 16 16">
  <path d="M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z"/>
  <path d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z"/>
  <path d="M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z"/>
</svg> Science & Tech</h5>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfVeteransAffairsModalIsOpen(true)}>Standing Committee on Industry and Technology</h5>
  <div id='committee9'></div>
  {CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/indu.xml', 'committee9', 'Standing Committee on Industry and Technology', 'Legislative', 'N/A', StandingCommitteeOnIndustryAndTech)}


  <Modal id='fullBillModal' show={departmentOfVeteransAffairsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfVeteransAffairsModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3> Standing Committee on Industry and Technology</h3></Modal.Header>
                     <StandingCommitteeOnIndustryAndTech/>
                     </Modal>

</Col>


<Col>
    <h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfAgricultureModalIsOpen(true)}>Standing Committee on Environment and Sustainable Development </h5>
<div id='committee5'></div>
{CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/envi.xml', 'committee5', 'Standing Committee on Environment and Sustainable Development', 'Legislative', 'N/A', StandingCommitteeOnEnvironment)}

<Modal id='fullBillModal' show={departmentOfAgricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfAgricultureModalIsOpen(false)}>
                       <Modal.Header id='billModalHeader' closeButton><h3> Standing Committee on Environment and Sustainable Development </h3></Modal.Header>
                     <StandingCommitteeOnEnvironment/>
                     </Modal>

</Col>


{/* 
             <Col>


{/* 

<h5 id='presidentialFeedHeadingMobile' onClick={() => setGDPUpdatesModalIsOpen(true)}>GPD Updates</h5>
                 {CreateFeedItem('https://www.atlantafed.org/rss/GDPNow', 'gdpUpdates',"GDP Updates", "N/A", "Economy",GDPUpdates)}
                 <div class='courtNewsMobile' id='gdpUpdates'></div>



                 <Modal id='fullBillModal' show={gdpUpdatesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setGDPUpdatesModalIsOpen(false)}>
                         <Modal.Header id='billModalHeader' closeButton><h3>GDP Updates</h3></Modal.Header>
                       
                       </Modal>

                 </Col>
*/}

           <Col>

           </Col>
     
           <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvasCanada/>
   
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
         
          </div>

          </>
  )
}
 }



  






export default CanadaScienceAndTechMobile;

