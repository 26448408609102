
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, NavLink,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, FileMedicalFill, Coin, Tree, Globe2,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, GeoAlt, HeartFill, QuestionCircleFill, MicFill, ReceiptCutoff } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import
 {supremeCourtSearch, whiteHouseSearch, billFeedModal, 
  createHouseFloorToday, createSenateFloorToday, createTrendingBills,
  createNationalAeronauticsAndSpaceAdministration, 
  createEnvironmentalProtectionAgency, createDepartmentOfHomelandSecurity,
  createDepartmentOfVeteransAffairs, createDepartmentOfEducation,
  createDepartmentOfHousingAndUrbanDevelopment, 
  createDepartmentOfHealthAndHumanServices, createDepartmentOfLabor
, createDepartmentOfCommerce, createDepartmentOfAgriculture,
createDepartmentOfTheInterior, createDepartmentOfJustice,
createDepartmentOfTransportation, createDepartmentOfDefense,
createDepartmentOfTreasury, createDepartmentOfEnergy,
createDepartmentOfState, createBillsEnrolledMobile,
createBillsEnrolled, createNewLaws, createGAOReports,
createExecutiveOrders, createPresidentialProclamations, createCongressionalHearings,
createCongressionalBudgetOfficeMobile, createCongressionalBudgetOffice,
createHouseCommitteeOversightAndReform,
createHouseCommitteeOnNaturalResources,
createHouseCommitteeOnTheJudiciary,
createHouseCommitteeOnHouseAdministration,
createHouseCommitteeOnHomelandSecurity,
createHouseCommitteeOnForeignAffairs,
createHouseCommitteeOnFinancialServices,
createHouseCommitteeOnEthics,
createHouseCommitteeOnEnergyAndCommerce,
createHouseCommitteeOnEducationAndLabor,
createHouseCommitteeOnTheBudget,
createHouseCommitteeOnArmedServices,
createHouseCommitteeOnAppropriations,
createHouseCommitteeOnAgriculture,
createReports,
createBillFeedTwoMobile,
createBillFeedTwo,
createBillFeed, createWhiteHouseFeedMobile
, createWhiteHouseFeed, createWhiteHouseDocuments, 
createBillsPresentedFeed, createCourtAppealsMobile,
createCourtAppeals, createUSCourtNewsFeedMobile,
createUSCourtNewsFeed, saveToCollection,
createFullBillFeed, ListItem, Speak, feedText, variableTest, myMethod, myMethod2, createEconomicIndicators, 
createSecuritiesAndExchangeCommissionPressReleases, createNationWeatherServices, createCDCNewsroom,
createSecuritiesAndExchangeCommissionSpeechesAndStatements, createNationalScienceFoundation, createHouseCommitteeOnIntelligence,
 createUNTopStories, createCDCOutbreaks, createCDCTravelNotices, createFoodRecalls, createFoodSafety, createFederalElectionCommissionNews, createElectionAssistancCommissioneNews, 
 createBillStatus, CreateFeedItem, CreateFeedItemWithDocButton, CreateFeedItemWithLawButton, CreateFeedItemGAOReports,
CreateFeedItemCongressionalReports,
CreateFeedItemPresidentialDocuments,
CreateFeedItemExecutiveOrders,
CreateFeedItemCourtAppeals,
CreateFeedItemEconomicIndicators,
CreateFeedItemBillUpdates,
createBIllItem,
CreateFeedItemSupremeCourtArguments,
CreateFeedItemSupremeCourtOpinions,
CreateFeedItemTest,
CreateFeedItemBillUpdatesFlip,
CreateFeedItemWithDocumentFlip,
CreateFeedItemWithDocButtonDateFix,
CreateFeedItemWithLawButtonFlip,
CreateFeedItemCanadaDateFix,
CreateFeedItemUKAtom,
CreateFeedAPI,
CreateFeedAPIDepartmentUK} from '../../Functions'

import AttorneyGeneralNews from './AttorneyGeneralNews';
import CabinetNews from './CabinetNews';
import PrimeMinisterNews from './PrimeMinisterNews';
import UKDesktopNavbar from '../UKDesktopNavbar';
import DepartmentForBusiness from './DepartmentForBusiness';
import DepartmentCultureMediaAndSport from '../Topics/Health/DepartmentCultureMediaAndSport';
import DepartmentForEducation from '../Topics/DepartmentForEducation';
import DepartmentForEnergy from '../Topics/Science and Tech/DepartmentForEnergy';
import DepartmentForEnvironment from '../Topics/Environment/DepartmentForEnvironment';
import DepartmentForHousing from '../Topics/DepartmentForHousing';
import DepartmentForScience from '../Topics/Science and Tech/DepartmentForScience';
import DepartmentForTransport from '../Topics/DepartmentForTransport';
import DepartmentForWork from '../Topics/Economy/DepartmentForWork';
import DepartmentForHealth from '../Topics/Health/DepartmentForHealth';
import DepartmentForForeignCommonwealth from '../Topics/Foreign Affairs/DepartmentForForeignCommonwealth';
import DepartmentForHMTreasury from '../Topics/Economy/DepartmentForHMTreasury';
import DepartmentForHomeOffice from '../Topics/DepartmentForHomeOffice';
import MinistryOfDefense from '../Topics/Foreign Affairs/MinistryOfDefense';
import NorthernIrelandOffice from '../Topics/NorthernIrelandOffice';
import OfficeOfScotland from '../Topics/OfficeOfScotland';
import HouseOfCommons from '../../Canada/Legislative/HouseOfCommons';
import OfficeOfHouseOfLords from '../Topics/OfficeOfHouseOfLords';
import SecretaryForScotland from '../Topics/SecretaryForScotland';
import SecrataryOfWales from '../Topics/SecrataryOfWales';
import ExportFinance from '../Topics/Economy/ExportFinance';
import MinistryOfJustice from '../Topics/MinistryOfJustice';
import UKExecutiveMobile from './UKExecutiveMobile';
import GenerateFeed from '../../GenerateFeed';
import GenerateFeedDepartmentUK from '../../GenerateFeedDepartmentUK copy2';
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);
export const UKExecutive = () =>   {
  render()
  var carouselInterval = 60000;
 
  {
  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
    const [billStatusModalIsOpen, setBillStatusModalIsOpen] = useState(false)
  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
  const [modal1, setModal1IsOpen] = useState(false);
          const [modal2, setModal2IsOpen] = useState(false);
          const [modal3, setModal3IsOpen] = useState(false);
          const [modal4, setModal4IsOpen] = useState(false);
          const [modal5, setModal5IsOpen] = useState(false);
          const [modal6, setModal6IsOpen] = useState(false);
          const [modal7, setModal7IsOpen] = useState(false);
          const [modal8, setModal8IsOpen] = useState(false);
          const [modal9, setModal9IsOpen] = useState(false);
          const [modal10, setModal10IsOpen] = useState(false);
          const [modal11, setModal11IsOpen] = useState(false);
          const [modal12, setModal12IsOpen] = useState(false);
          const [modal13, setModal13IsOpen] = useState(false);
          const [modal14, setModal14IsOpen] = useState(false);
          const [modal15, setModal15IsOpen] = useState(false);
          const [modal16, setModal16IsOpen] = useState(false);
          const [modal17, setModal17IsOpen] = useState(false);
          const [modal18, setModal18IsOpen] = useState(false);
          const [modal19, setModal19IsOpen] = useState(false);
          const [modal20, setModal20IsOpen] = useState(false);
          const [modal21, setModal21IsOpen] = useState(false);
          const [modal22, setModal22IsOpen] = useState(false);
          const [modal23, setModal23IsOpen] = useState(false);
          const [showBioModal, setBioModal] = useState(false);
          const [modalInfo, setModalInfo] = useState({
            isOpen: false,
            title: '',
            content: null
          });
        
          const openModal = (title, content) => {
            setModalInfo({
              isOpen: true,
              title,
              content
            });
          };
        
          const closeModal = () => {
            setModalInfo({
              isOpen: false,
              title: '',
              content: null
            });
          };
  return (
    
    <>
    
      <div className="App">

      <BrowserView>
      <div id='homePage'>
        


        <div id='homebg'>

     

          

         <UKDesktopNavbar/>
       

         <div style={{ position: 'relative' }}>

<div id='profileImageIcon' style={{ width: '150px', height: '150px',  marginLeft: '60px' }}>
<People id='profileImage-imageicon' ></People>
</div>


<div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
<div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Executive Branch </h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '16px' }}>
<span> 
In the UK, the Prime Minister leads the government with the support of the Cabinet and ministers. </span>
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

  <span><Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.gov.uk/government/ministers", "_blank");
   }}>
     https://www.gov.uk/government/ministers
     </Link></span> 

     <Button id='whDocsViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
</div>
</div>

</div>
</div>



<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> The Executive Branch</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>The Prime Minister is the leader of His Majesty’s Government and is ultimately responsible for all policy and decisions.</p>

<p>The Prime Minister also:</p>
<ul>
  <li>oversees the operation of the Civil Service and government agencies</li>
  <li>appoints members of the government</li>
  <li>is the principal government figure in the House of Commons</li>
</ul>

<p>The current Prime Minister is The Rt Hon Sir Keir Starmer KCB KC MP.</p>

<p>The Cabinet is composed of the senior members of the government. Every week during Parliament, members of the Cabinet (Secretaries of State from all departments and some other ministers) meet to discuss the most important issues for the government.</p>

<br></br>
<Link onClick={(e) => {
     e.preventDefault();
     window.open("https://www.gov.uk/government/ministers", "_blank");
   }}>
     https://www.gov.uk/government/ministers
     </Link>
 </div>

       </Modal>

</div>
<br></br>
<br></br><br></br>
<Modal id='mobileFullBillModal' show={fullBillModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFullBillModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Prime Minister's Office</h3></Modal.Header>
<GenerateFeed url='&schema=canada&table=prime_minister&order_by=created_at' 
                            name='Prime Ministers Office' branch='Executive' topic='N/A' buttonName='Doc' />
</Modal>
            <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />

       
     {/* Prime Minister's Office and Attorney General's Office */}
{/* Prime Minister's Office and Attorney General's Office */}
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='departmentOfDefenseHeadingInCol' onClick={() => setTrendingBillsOptionTenModalIsOpen(true)}>
              <b>Prime Minister</b>
            </h4>
            <div id='primeMinister'></div>
            {CreateFeedItemUKAtom(
              'https://www.gov.uk/government/organisations/prime-ministers-office-10-downing-street.atom',
              'primeMinister', 'Prime Minister News', 'Executive', 'N/A', PrimeMinisterNews
            )}
            <Modal id='mobileFullBillModal' show={trendingBillsOptionTen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTenModalIsOpen(false)}>
              <Modal.Header id='mobileBillModalHeader' closeButton><h3>Prime Minister</h3></Modal.Header>
              <PrimeMinisterNews/>
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <Link id='pageLink' to="/uk/attorney-generals-office">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Attorney General's Office</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Attorney General\'s Office',
                    <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Attorney%20Generals%20Office'
                      name='Attorney Generals Office' branch='Executive' topic='N/A' buttonName='Doc' />)}><b>News</b></h4>
                <div id='ago'></div>
                {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Attorney%20Generals%20Office', 'ago', 'Attorney General\'s Office', 'Executive', 'N/A', 'Doc', GenerateFeedDepartmentUK)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg' href="/uk/attorney-generals-office">View all Attorney General's Office news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

{/* Cabinet Office and Department For Culture Media And Sport */}
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='departmentOfDefenseHeadingInCol' onClick={() => setModal1IsOpen(true)}>
              <b>Cabinet</b>
            </h4>
            <div id='cabinet'></div>
            {CreateFeedItemUKAtom(
              'https://www.gov.uk/government/organisations/cabinet-office.atom',
              'cabinet', 'Cabinet', 'Executive', 'N/A', CabinetNews
            )}
            <Modal id='mobileFullBillModal' show={modal1} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal1IsOpen(false)}>
              <Modal.Header id='mobileBillModalHeader' closeButton><h3>Cabinet</h3></Modal.Header>
              <CabinetNews/>
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <Link id='pageLink' to="/uk/department-for-culture-media-and-sport">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Department For Culture Media And Sport</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Department For Culture Media And Sport',
                    <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Culture%20Media%20And%20Sport'
                      name='Department For Culture Media And Sport' branch='Executive' topic='Health' buttonName='Doc' />)}><b>News</b></h4>
                <div id='dcms'></div>
                {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Culture%20Media%20And%20Sport', 'dcms', 'Department For Culture Media And Sport', 'Executive', 'Health', 'Doc', GenerateFeedDepartmentUK)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg' href="/uk/department-for-culture-media-and-sport">View all Department For Culture Media And Sport news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

{/* Office Of The Secretary Of State For Scotland and Department For Science Innovation And Technology */}
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <Link id='pageLink' to="/uk/office-of-the-secretary-of-state-for-scotland">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Office Of The Secretary Of State For Scotland</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Office Of The Secretary Of State For Scotland',
                    <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Office%20Of%20The%20Secretary%20Of%20State%20For%20Scotland'
                      name='Office Of The Secretary Of State For Scotland' branch='Executive' topic='N/A' buttonName='Doc' />)}><b>News</b></h4>
                <div id='secretary-scotland'></div>
                {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Office%20Of%20The%20Secretary%20Of%20State%20For%20Scotland', 'secretary-scotland', 'Office Of The Secretary Of State For Scotland', 'Executive', 'N/A', 'Doc', GenerateFeedDepartmentUK)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg' href="/uk/office-of-the-secretary-of-state-for-scotland">View all Office Of The Secretary Of State For Scotland news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <Link id='pageLink' to="/uk/department-for-science-innovation-and-technology">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Department For Science Innovation And Technology</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Department For Science Innovation And Technology',
                    <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Science%20Innovation%20And%20Technology'
                      name='Department For Science Innovation And Technology' branch='Executive' topic='Science & Tech' buttonName='Doc' />)}><b>News</b></h4>
                <div id='dsit'></div>
                {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Science%20Innovation%20And%20Technology', 'dsit', 'Department For Science Innovation And Technology', 'Executive', 'Science & Tech', 'Doc', GenerateFeedDepartmentUK)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg' href="/uk/department-for-science-innovation-and-technology">View all Department For Science Innovation And Technology news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

{/* HM Treasury and Home Office */}
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <Link id='pageLink' to="/uk/hm-treasury">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>HM Treasury</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('HM Treasury',
                    <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=HM%20Treasury'
                      name='HM Treasury' branch='Executive' topic='Economy' buttonName='Doc' />)}><b>News</b></h4>
                <div id='treasury'></div>
                {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=HM%20Treasury', 'treasury', 'HM Treasury', 'Executive', 'Economy', 'Doc', GenerateFeedDepartmentUK)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg' href="/uk/hm-treasury">View all HM Treasury news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <Link id='pageLink' to="/uk/home-office">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Home Office</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Home Office',
                    <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Home%20Office'
                      name='Home Office' branch='Executive' topic='N/A' buttonName='Doc' />)}><b>News</b></h4>
                <div id='home-office'></div>
                {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Home%20Office', 'home-office', 'Home Office', 'Executive', 'N/A', 'Doc', GenerateFeedDepartmentUK)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg' href="/uk/home-office">View all Home Office news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

{/* Department For Education and Ministry Of Defense */}
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <Link id='pageLink' to="/uk/department-for-education">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Department For Education</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Department For Education',
                    <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Education'
                      name='Department For Education' branch='Executive' topic='N/A' buttonName='Doc' />)}><b>News</b></h4>
                <div id='dfe'></div>
                {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Education', 'dfe', 'Department For Education', 'Executive', 'N/A', 'Doc', GenerateFeedDepartmentUK)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg' href="/uk/department-for-education">View all Department For Education news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <Link id='pageLink' to="/uk/ministry-of-defence">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Ministry Of Defense</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Ministry Of Defense',
                    <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Ministry%20Of%20Defence'
                      name='Ministry Of Defense' branch='Executive' topic='Defense' buttonName='Doc' />)}><b>News</b></h4>
                <div id='mod'></div>
                {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Ministry%20Of%20Defence', 'mod', 'Ministry Of Defense', 'Executive', 'Defense', 'Doc', GenerateFeedDepartmentUK)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg' href="/uk/ministry-of-defence">View all Ministry Of Defense news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

{/* Department For Business And Trade and Office Of The Leader Of The House Of Commons */}
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <Link id='pageLink' to="/uk/department-for-business-and-trade">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Department For Business And Trade</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Department For Business And Trade',
                    <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Business%20And%20Trade'
                      name='Department For Business And Trade' branch='Executive' topic='Economy' buttonName='Doc' />)}><b>News</b></h4>
                <div id='dbt'></div>
                {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Department%20For%20Business%20And%20Trade', 'dbt', 'Department For Business And Trade', 'Executive', 'Economy', 'Doc', GenerateFeedDepartmentUK)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg' href="/uk/department-for-business-and-trade">View all Department For Business And Trade news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <Link id='pageLink' to="/uk/the-office-of-the-leader-of-the-house-of-commons">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>The Office Of The Leader Of The House Of Commons</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('The Office Of The Leader Of The House Of Commons',
                    <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=The%20Office%20Of%20The%20Leader%20Of%20The%20House%20Of%20Commons'
                      name='The Office Of The Leader Of The House Of Commons' branch='Executive' topic='N/A' buttonName='Doc' />)}><b>News</b></h4>
                <div id='commons-leader'></div>
                {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=The%20Office%20Of%20The%20Leader%20Of%20The%20House%20Of%20Commons', 'commons-leader', 'The Office Of The Leader Of The House Of Commons', 'Executive', 'N/A', 'Doc', GenerateFeedDepartmentUK)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg' href="/uk/the-office-of-the-leader-of-the-house-of-commons">View all The Office Of The Leader Of The House Of Commons news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

{/* Office Of The Leader Of The House Of Lords and Office Of The Secretary Of State For Wales */}
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <Link id='pageLink' to="/uk/office-of-the-leader-of-the-house-of-lords">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Office Of The Leader Of The House Of Lords</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Office Of The Leader Of The House Of Lords',
                    <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Office%20Of%20The%20Leader%20Of%20The%20House%20Of%20Lords'
                      name='Office Of The Leader Of The House Of Lords' branch='Executive' topic='N/A' buttonName='Doc' />)}><b>News</b></h4>
                <div id='lords-leader'></div>
                {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Office%20Of%20The%20Leader%20Of%20The%20House%20Of%20Lords', 'lords-leader', 'Office Of The Leader Of The House Of Lords', 'Executive', 'N/A', 'Doc', GenerateFeedDepartmentUK)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg' href="/uk/office-of-the-leader-of-the-house-of-lords">View all Office Of The Leader Of The House Of Lords news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <Link id='pageLink' to="/uk/office-of-the-secretary-of-state-for-wales">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Office Of The Secretary Of State For Wales</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Office Of The Secretary Of State For Wales',
                    <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Office%20Of%20The%20Secretary%20Of%20State%20For%20Wales'
                      name='Office Of The Secretary Of State For Wales' branch='Executive' topic='N/A' buttonName='Doc' />)}><b>News</b></h4>
                <div id='wales'></div>
                {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Office%20Of%20The%20Secretary%20Of%20State%20For%20Wales', 'wales', 'Office Of The Secretary Of State For Wales', 'Executive', 'N/A', 'Doc', GenerateFeedDepartmentUK)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg' href="/uk/office-of-the-secretary-of-state-for-wales">View all Office Of The Secretary Of State For Wales news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

{/* UK Export Finance and Northern Ireland Office */}
<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <Link id='pageLink' to="/uk/uk-export-finance">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>UK Export Finance</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('UK Export Finance',
                    <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=UK%20Export%20Finance'
                      name='UK Export Finance' branch='Executive' topic='Economy' buttonName='Doc' />)}><b>News</b></h4>
                <div id='ukef'></div>
                {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=UK%20Export%20Finance', 'ukef', 'UK Export Finance', 'Executive', 'Economy', 'Doc', GenerateFeedDepartmentUK)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg' href="/uk/uk-export-finance">View all UK Export Finance news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <Link id='pageLink' to="/uk/northern-ireland-office">
              <h4 id='departmentOfDefenseHeadingInCol'>
                <b>Northern Ireland Office</b><ChevronRight id='clickChevron'></ChevronRight>
              </h4>
            </Link>

            <Carousel id='carouselInColumn' controls={false}>
              <Carousel.Item interval={carouselInterval}>
                <h4 id='departmentOfJusticeHeading' onClick={() =>
                  openModal('Northern Ireland Office',
                    <GenerateFeedDepartmentUK url='&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Northern%20Ireland%20Office'
                      name='Northern Ireland Office' branch='Executive' topic='N/A' buttonName='Doc' />)}><b>News</b></h4>
                <div id='nio'></div>
                {CreateFeedAPIDepartmentUK('&table=ministerial_departments&order_by=created_at&filter_column=category&filter_string=Northern%20Ireland%20Office', 'nio', 'Northern Ireland Office', 'Executive', 'N/A', 'Doc', GenerateFeedDepartmentUK)}
              </Carousel.Item>

              <Carousel.Item interval={carouselInterval}>
                <div id='legislativeConInCol'>
                  <Col>
                    <div id='viewFullPageButton'>
                      <Button id='viewFullLegislativePageButtonMobile' size='lg' href="/uk/northern-ireland-office">View all Northern Ireland Office news</Button>
                    </div>
                  </Col>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>



            
          <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
   <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>

      


    
          </div>
  

    </div>
      </BrowserView>
     

      <MobileView> 
        
        
               <UKExecutiveMobile/>

     
      </MobileView>
        </div>
      


       
    </>

     
    
  );







}
}


export default UKExecutive;
