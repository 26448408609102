
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, NavLink,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, FileMedicalFill, Coin, Tree, Globe2,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, GeoAlt, HeartFill, QuestionCircleFill, MicFill, ReceiptCutoff } from 'react-bootstrap-icons'

import { BrowserView, MobileView } from 'react-device-detect';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';

import
 {supremeCourtSearch, whiteHouseSearch, billFeedModal, 
  createHouseFloorToday, createSenateFloorToday, createTrendingBills,
  createNationalAeronauticsAndSpaceAdministration, 
  createEnvironmentalProtectionAgency, createDepartmentOfHomelandSecurity,
  createDepartmentOfVeteransAffairs, createDepartmentOfEducation,
  createDepartmentOfHousingAndUrbanDevelopment, 
  createDepartmentOfHealthAndHumanServices, createDepartmentOfLabor
, createDepartmentOfCommerce, createDepartmentOfAgriculture,
createDepartmentOfTheInterior, createDepartmentOfJustice,
createDepartmentOfTransportation, createDepartmentOfDefense,
createDepartmentOfTreasury, createDepartmentOfEnergy,
createDepartmentOfState, createBillsEnrolledMobile,
createBillsEnrolled, createNewLaws, createGAOReports,
createExecutiveOrders, createPresidentialProclamations, createCongressionalHearings,
createCongressionalBudgetOfficeMobile, createCongressionalBudgetOffice,
createHouseCommitteeOversightAndReform,
createHouseCommitteeOnNaturalResources,
createHouseCommitteeOnTheJudiciary,
createHouseCommitteeOnHouseAdministration,
createHouseCommitteeOnHomelandSecurity,
createHouseCommitteeOnForeignAffairs,
createHouseCommitteeOnFinancialServices,
createHouseCommitteeOnEthics,
createHouseCommitteeOnEnergyAndCommerce,
createHouseCommitteeOnEducationAndLabor,
createHouseCommitteeOnTheBudget,
createHouseCommitteeOnArmedServices,
createHouseCommitteeOnAppropriations,
createHouseCommitteeOnAgriculture,
createReports,
createBillFeedTwoMobile,
createBillFeedTwo,
createBillFeed, createWhiteHouseFeedMobile
, createWhiteHouseFeed, createWhiteHouseDocuments, 
createBillsPresentedFeed, createCourtAppealsMobile,
createCourtAppeals, createUSCourtNewsFeedMobile,
createUSCourtNewsFeed, saveToCollection,
createFullBillFeed, ListItem, Speak, feedText, variableTest, myMethod, myMethod2, createEconomicIndicators, 
createSecuritiesAndExchangeCommissionPressReleases, createNationWeatherServices, createCDCNewsroom,
createSecuritiesAndExchangeCommissionSpeechesAndStatements, createNationalScienceFoundation, createHouseCommitteeOnIntelligence,
 createUNTopStories, createCDCOutbreaks, createCDCTravelNotices, createFoodRecalls, createFoodSafety, createFederalElectionCommissionNews, createElectionAssistancCommissioneNews, 
 createBillStatus, CreateFeedItem, CreateFeedItemWithDocButton, CreateFeedItemWithLawButton, CreateFeedItemGAOReports,
CreateFeedItemCongressionalReports,
CreateFeedItemPresidentialDocuments,
CreateFeedItemExecutiveOrders,
CreateFeedItemCourtAppeals,
CreateFeedItemEconomicIndicators,
CreateFeedItemBillUpdates,
createBIllItem,
CreateFeedItemSupremeCourtArguments,
CreateFeedItemSupremeCourtOpinions,
CreateFeedItemTest,
CreateFeedItemBillUpdatesFlip,
CreateFeedItemWithDocumentFlip,
CreateFeedItemWithDocButtonDateFix,
CreateFeedItemWithLawButtonFlip,
CreateFeedItemMexico} from '../../Functions'



import MexicoDesktopNavbar from '../MexicoDesktopNavbar';
import PresidentUpdates from './PresidentUpdates';
import AgricultureAgency from './AgricultureAgency';
import WelfareAgency from './WelfareAgency';
import CjefAgency from './CjefAgency';
import ConadeAgency from './ConadeAgency';
import ConadisAgency from './ConadisAgency';
import ConaforAgency from './ConaforAgency';
import ConaquaAgency from './ConaquaAgency';
import CorreosDeMexicoAgency from './CorreosDeMexicoAgency';
import INMAgency from './INMAgency';
import SaludAgency from './SaludAgency';
import SCTAgency from './SCTAgency';
import SEAgency from './SeAgency';
import SecturAgency from './SecturAgency';
import SedatuAgency from './SedatuAgency';
import SedenaAgency from './SedenaAgency';
import SEGOBAgency from './SegobAgency';
import SemarAgency from './SemarAgency';
import SemarnatAgency from './SemarnatAgency';
import SFPAgency from './SFPAgency';
import SHCPAgency from './SHCPAgency';
import SREAgency from './SREAgency';
import SSPCAgency from './SSPCAgency';
import STPSAgency from './STPSAgency';
import JudicialBranchMobile from '../../JudicialBranchMobile';
import MexicoExecutiveBranchMobile from './MexicoExecutiveBranchMobile';












var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;

//https://evening-plains-21303.herokuapp.com/







  








export const MexicoExecutive = () =>   {
  render()
  
 
  {

  
  const [show, setShow] = useState(true);
  
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);
  const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  
  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
  const [billHelpModalIsOpen, setBillHelpModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
  const [courtOpinionsModalIsOpen, setCourtOpinionsModalIsOpen] = useState(false)
  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
  const [secSAndSModalIsOpen, setSECSAndSModalIsOpen] = useState(false)
  const [economicIndicatorsModalIsOpen, setEconomicIndicatorsModalIsOpen] = useState(false)
  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
  const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
  const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
  const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
  const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
  const [trendingBillsOptionSix, setTrendingBillsOptionSixModalIsOpen ] = useState(false)
  const [trendingBillsOptionSeven, setTrendingBillsOptionSevenModalIsOpen ] = useState(false)
  const [trendingBillsOptionEight, setTrendingBillsOptionEightModalIsOpen ] = useState(false)
  const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
  const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
  const [trendingBillsOptionEleven, setTrendingBillsOptionElevenModalIsOpen ] = useState(false)
  const [trendingBillsOptionTweleve, setTrendingBillsOptionTweleveModalIsOpen ] = useState(false)
  const [trendingBillsOptionThirteen, setTrendingBillsOptionThirteenModalIsOpen ] = useState(false)
  const [trendingBillsOptionFourteen, setTrendingBillsOptionFourteenModalIsOpen ] = useState(false)
  const [trendingBillsOptionFifteen, setTrendingBillsOptionFifteenModalIsOpen ] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
    const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
    const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
    const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
    const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
    const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
    const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
    const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
    const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
    const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
    const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
    const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
    const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
    const [houseCommitteeOnOversightAndReformModalIsOpen, setHouseCommitteeOnOversightAndReformModalIsOpen] = useState(false)
    const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
    const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
    const [cdcNewsroomModalIsOpen, setCDCNewsroomModalIsOpen] = useState(false)
    const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
    const [unTopStoriesModalIsOpen, setUNTopStoriesModalIsOpen] = useState(false)
    const [houseCommitteeOnIntelligenceModalIsOpen, setHouseCommitteeOnIntelligenceModalIsOpen] = useState(false)
    const [travelNoticesModalIsOpen, setTravelNoticesModalIsOpen] = useState(false)
  
    const [foodRecallsModalIsOpen, setFoodRecallsModalIsOpen] = useState(false)
    const [foodSafetyModalIsOpen, setFoodSafetyModalIsOpen] = useState(false)

    const [outbreaksModalIsOpen, setOutbreaksModalIsOpen] = useState(false)
    const [congressionalHearingsModalIsOpen, setCongressionalHearingsModalIsOpen] = useState(false)

    const [houseFloorModalIsOpen, setHouseFloorModalIsOpen] = useState(false)

    const [billStatusModalIsOpen, setBillStatusModalIsOpen] = useState(false)
    const [fecModalIsOpen, setFECModalIsOpen] = useState(false)
    const [eacModalIsOpen, setEACModalIsOpen] = useState(false)


  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [secPressModalIsOpen, setSECPressModalIsOpen] = useState(false)

  const [alertModalIsOpen, setAlertModalIsOpen] = useState(true)


  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
 

   










  
  
  

  

  
  //onClick={linkToBill()}
  return (
    
    <>
    
      <div className="App">
   
    




      <BrowserView>
      <div id='homePage'>
        


        <div id='homebg'>

     

          

         <MexicoDesktopNavbar/>
       

         <div id='sectionHeading'>
            
         <h3 id='branchHeading'><People></People> Executive

            
</h3>


            
            </div>
                     
           
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='presidentialFeedHeading' onClick={() => setDepartmentOfStateModalIsOpen(true)}><b>President Newsroom</b></h4>
                       

                          <div id='president'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.cdn.digitaloceanspaces.com/executive-xml/president/pre_newsroom.xml', 
  'president', 'President Newsroom', 'Executive', 'N/A', PresidentUpdates)}

<Modal id='mobileFullBillModal' show={departmentOfStateModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfStateModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>President Newsroom</h3></Modal.Header>
<PresidentUpdates/>
</Modal>

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setFullBillModalIsOpen(true)}><b>Secretaría de Agricultura y Desarrollo Rural</b></h4>
                          <div id='executiveNews'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/agricultura.xml', 'executiveNews', 'Secretaría de Agricultura y Desarrollo Rural', 'Executive', 'N/A', AgricultureAgency)}


<Modal id='mobileFullBillModal' show={fullBillModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFullBillModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Agricultura y Desarrollo Rural</h3></Modal.Header>
<AgricultureAgency/>
</Modal>
                        
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             
              </div>
           
           
        
              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='presidentialFeedHeading' onClick={() => setTrendingBillsModalIsOpen(true)}><b>Secretaría de Bienestar</b></h4>
                       

                          <div id='welfare'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/bienestar.xml', 'welfare', 'Secretaría de Bienestar', 'Executive', 'Health', WelfareAgency)}

 



  <Modal id='mobileFullBillModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Bienestar</h3></Modal.Header>
<WelfareAgency/>
</Modal>

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setTrendingBillsOptionOneModalIsOpen(true)}><b>Consejería Jurídica del Ejecutivo Federal</b></h4>
                          <div id='cjef'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/cjef.xml', 'cjef', 'Consejería Jurídica del Ejecutivo Federal', 'Executive', 'N/A', CjefAgency)}

 



  <Modal id='mobileFullBillModal' show={trendingBillsOptionOne} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionOneModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Consejería Jurídica del Ejecutivo Federal</h3></Modal.Header>
<CjefAgency/>
</Modal>
                        
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             
              </div>


              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='presidentialFeedHeading' onClick={() => setTrendingBillsOptionTwoModalIsOpen(true)}><b>Comisión Nacional de Cultura Física y Deporte</b></h4>

<div id='conade'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/conade.xml', 'conade', 'Comisión Nacional de Cultura Física y Deporte', 'Executive', 'N/A', ConadeAgency)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionTwo} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTwoModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Comisión Nacional de Cultura Física y Deporte</h3></Modal.Header>
<ConadeAgency/>
</Modal>

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setTrendingBillsOptionThreeModalIsOpen(true)}><b>Consejo Nacional para el Desarrollo y la Inclusión de las Personas con Discapacidad</b></h4>
                          <div id='conadis'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/conadis.xml', 'conadis', 'Consejo Nacional para el Desarrollo y la Inclusión de las Personas con Discapacidad', 'Executive', 'N/A', ConadisAgency)}

 



  <Modal id='mobileFullBillModal' show={trendingBillsOptionThree} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionThreeModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Consejo Nacional para el Desarrollo y la Inclusión de las Personas con Discapacidad</h3></Modal.Header>
<ConadisAgency/>
</Modal>
                        
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             
              </div>

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='presidentialFeedHeading' onClick={() => setTrendingBillsOptionFiveModalIsOpen(true)}><b>Comisión Nacional Forestal</b></h4>

                          <div id='conafor'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/conafor.xml',
     'conafor', 'Comisión Nacional Forestal', 'Executive', 'N/A', ConaforAgency)}

  <Modal id='mobileFullBillModal' show={trendingBillsOptionFive} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionFiveModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Comisión Nacional Forestal</h3></Modal.Header>
<ConaforAgency/>
</Modal>

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setTrendingBillsOptionFourModalIsOpen(true)}><b>Comisión Nacional del Agua</b></h4>
                          <div id='conagua'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/conagua.xml',
     'conagua', 'Comisión Nacional del Agua', 'Executive', 'N/A', ConaquaAgency)}

  <Modal id='mobileFullBillModal' show={trendingBillsOptionFour} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionFourModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Comisión Nacional del Agua</h3></Modal.Header>
<ConaquaAgency/>
</Modal>
                        
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             
              </div>
         


              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='presidentialFeedHeading' onClick={() => setTrendingBillsOptionSixModalIsOpen(true)}><b>Servicio Postal Mexicano</b></h4>

                          <div id='correosdemexico'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/correosdemexico.xml',
     'correosdemexico', 'Servicio Postal Mexicano', 'Executive', 'N/A', CorreosDeMexicoAgency)}

  <Modal id='mobileFullBillModal' show={trendingBillsOptionSix} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionSixModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Servicio Postal Mexicano</h3></Modal.Header>
<CorreosDeMexicoAgency/>
</Modal>

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setTrendingBillsOptionSevenModalIsOpen(true)}><b>Instituto Nacional de Migración</b></h4>
                          <div id='inm'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/inm.xml',
     'inm', 'Instituto Nacional de Migración', 'Executive', 'N/A', INMAgency)}

  <Modal id='mobileFullBillModal' show={trendingBillsOptionSeven} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionSevenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Instituto Nacional de Migración</h3></Modal.Header>
<INMAgency/>
</Modal>
                        
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             
              </div> 
            
              
              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='presidentialFeedHeading' onClick={() => setTrendingBillsOptionEightModalIsOpen(true)}><b>Secretaría de Salud</b></h4>
                          <div id='salud'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/salud.xml',
     'salud', 'Secretaría de Salud', 'Executive', 'N/A', SaludAgency)}

  <Modal id='mobileFullBillModal' show={trendingBillsOptionEight} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionEightModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Salud</h3></Modal.Header>
<SaludAgency/>
</Modal>

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setTrendingBillsOptionNineModalIsOpen(true)}><b>Secretaría de Comunicaciones y Transportes</b></h4>
                          <div id='sct'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/sct.xml',
     'sct', 'Secretaría de Comunicaciones y Transportes', 'Executive', 'N/A', SCTAgency)}

  <Modal id='mobileFullBillModal' show={trendingBillsOptionNine} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionNineModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Comunicaciones y Transportes</h3></Modal.Header>
<SCTAgency/>
</Modal>
                        
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             
              </div> 
              
              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='presidentialFeedHeading' onClick={() => setTrendingBillsOptionTenModalIsOpen(true)}><b>Secretaría de Economía</b></h4>
                        <div id='se'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/se.xml',
     'se', 'Secretaría de Economía', 'Executive', 'N/A', SEAgency)}

  <Modal id='mobileFullBillModal' show={trendingBillsOptionTen} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Economía</h3></Modal.Header>
<SEAgency/>
</Modal>

                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setTrendingBillsOptionElevenModalIsOpen(true)}><b>Secretaría de Turismo</b></h4>
                          <div id='sectur'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/sectur.xml',
     'sectur', 'Secretaría de Turismo', 'Executive', 'N/A', SecturAgency)}

  <Modal id='mobileFullBillModal' show={trendingBillsOptionEleven} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionElevenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Turismo</h3></Modal.Header>
<SecturAgency/>
</Modal>
                        
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             
              </div> 
          
            

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='presidentialFeedHeading'onClick={() => setTrendingBillsOptionTweleveModalIsOpen(true)}><b>Secretaría de Desarrollo Agrario, Territorial y Urbano</b></h4>
                        <div id='sedatu'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/sedatu.xml',
     'sedatu', 'Secretaría de Desarrollo Agrario, Territorial y Urbano', 'Executive', 'N/A', SedatuAgency)}

  <Modal id='mobileFullBillModal' show={trendingBillsOptionTweleve} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTweleveModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Desarrollo Agrario, Territorial y Urbano</h3></Modal.Header>
<SedatuAgency/>
</Modal>


                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setTrendingBillsOptionThirteenModalIsOpen(true)}><b>Secretaría de la Defensa Nacional</b></h4>
                          <div id='sedena'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/sedena.xml',
     'sedena', 'Secretaría de la Defensa Nacional', 'Executive', 'N/A', SedenaAgency)}

  <Modal id='mobileFullBillModal' show={trendingBillsOptionThirteen} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionThirteenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de la Defensa Nacional</h3></Modal.Header>
<SedenaAgency/>
</Modal>
                        
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             
              </div> 

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='presidentialFeedHeading'onClick={() => setDepartmentOfAgricultureModalIsOpen(true)}><b>Secretaría de Gobernación</b></h4>
                        <div id='segob'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/segob.xml',
     'segob', 'Secretaría de Gobernación', 'Executive', 'N/A', SEGOBAgency)}

  <Modal id='mobileFullBillModal' show={departmentOfAgricultureModalIsOpen} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfAgricultureModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Gobernación</h3></Modal.Header>
<SEGOBAgency/>
</Modal>


                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setDepartmentOfCommerceModalIsOpen(true)}><b>Secretaría de Marina</b></h4>
                          <div id='semar'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/semar.xml',
     'semar', 'Secretaría de Marina', 'Executive', 'N/A', SemarAgency)}

  <Modal id='mobileFullBillModal' show={departmentOfCommerceModalIsOpen} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfCommerceModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Marina</h3></Modal.Header>
<SemarAgency/>
</Modal>
                        
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             
              </div> 

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='presidentialFeedHeading' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}><b>Secretaría de Medio Ambiente y Recursos Naturales</b></h4>
                        <div id='semarNat'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/semarnat.xml',
     'semarNat', 'Secretaría de Medio Ambiente y Recursos Naturales', 'Executive', 'N/A', SemarnatAgency)}

  <Modal id='mobileFullBillModal' show={departmentOfDefenseModalIsOpen} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Medio Ambiente y Recursos Naturales</h3></Modal.Header>
<SemarnatAgency/>
</Modal>


                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setDepartmentOfEducationModalIsOpen(true)}><b>Secretaría de la Función Pública</b></h4>
                          <div id='sfp'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/sfp.xml',
     'sfp', 'Secretaría de la Función Pública', 'Executive', 'N/A', SFPAgency)}

  <Modal id='mobileFullBillModal' show={departmentOfEducationModalIsOpen} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfEducationModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de la Función Pública</h3></Modal.Header>
<SFPAgency/>
</Modal>
                        
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             
              </div> 


              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='presidentialFeedHeading' onClick={() => setDepartmentOfEnergyModalIsOpen(true)}><b>Secretaría de Hacienda y Crédito Público</b></h4>
                        <div id='shcp'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/shcp.xml',
     'shcp', 'Secretaría de Hacienda y Crédito Público', 'Executive', 'N/A', SHCPAgency)}

  <Modal id='mobileFullBillModal' show={departmentOfEnergyModalIsOpen} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfEnergyModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Hacienda y Crédito Público</h3></Modal.Header>
<SHCPAgency/>
</Modal>


                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(true)}><b>Secretaría de Relaciones Exteriores</b></h4>
                          <div id='sre'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/sre.xml',
     'sre', 'Secretaría de Relaciones Exteriores', 'Executive', 'N/A', SREAgency)}

  <Modal id='mobileFullBillModal' show={departmentOfHealthAndHumanServicesModalIsOpen} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Relaciones Exteriores</h3></Modal.Header>
<SREAgency/>
</Modal>
                        
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             
              </div> 


              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='presidentialFeedHeading' onClick={() => setDepartmentOfHomelandSecurityModalIsOpen(true)}><b>Secretaría de Seguridad y Protección Ciudadana</b></h4>
                          <div id='sspc'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/sspc.xml',
     'sspc', 'Secretaría de Seguridad y Protección Ciudadana', 'Executive', 'N/A', SSPCAgency)}

  <Modal id='mobileFullBillModal' show={departmentOfHomelandSecurityModalIsOpen} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHomelandSecurityModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Seguridad y Protección Ciudadana</h3></Modal.Header>
<SSPCAgency/>
</Modal>


                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(true)}><b>Secretaría del Trabajo y Previsión Social</b></h4>
                          <div id='stps'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/stps.xml',
     'stps', 'Secretaría del Trabajo y Previsión Social', 'Executive', 'N/A', STPSAgency)}

  <Modal id='mobileFullBillModal' show={departmentOfHousingAndUrbanDevelopmentModalIsOpen} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría del Trabajo y Previsión Social</h3></Modal.Header>
<STPSAgency/>
</Modal>
                        
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             
              </div> 
            
          <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
   <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>

      


    
          </div>
  

    </div>
      </BrowserView>
     

      <MobileView> 
        
        
              <MexicoExecutiveBranchMobile/> 

     
      </MobileView>
        </div>
      


       
    </>

     
    
  );







}
}


export default MexicoExecutive;
