import React, { useState, useEffect } from 'react';
import { Button, Card, Container, Form, Image, Navbar, Spinner } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import { ChevronRight, Files, Search } from 'react-bootstrap-icons';
import MobileBackButton from './MobileBackButton';
import MobileOffCanvas from './MobileOffCanvas';
import useHandleContainerClickVicePresident from './UseHandleContainerClickVicePresident';


const getOrdinalSuffix = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
};

function formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
}

function VicePresidentDisplayOnPageExec() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const handleContainerClick = useHandleContainerClickVicePresident();
    require('dotenv').config();
    const apiKey = process.env.REACT_APP_API_KEY;
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            "Authorization": "Bearer "+apiKey,
        },
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                setError(null);

                const response = await fetch('https://api.govglance.org/members/vice_presidents?order_by=id&limit=1&skip=0', requestOptions);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const jsonData = await response.json();
                setData(jsonData);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const renderData = () => {
        const renderItems = loading ? (
            <Spinner animation="grow" variant="light" />
        ) : error ? (
            <p>Error: {error.message}</p>
        ) : (
            data.map((item, index) => {
                const titleToDisplay = item.full_name;
                const termStart = item.term_start_date;
                const termEnd = item.term_end_date;
              
                const imageSource = item.url;
                const bio = item.description
                return (
                    <div id='feedDisplayCaro' key={index}>
                       
                            <Container id='repContainerDesktopOnPageExec'>
                            <div onClick={() => handleContainerClick(titleToDisplay, termStart, termEnd,  imageSource, bio)}>
                                <div style={{ position: 'relative' }}>
                                    {imageSource && (
                                       <div id='profileImage' style={{ width: '70px', height: '70px', borderRadius: '100%', overflow: 'hidden' }}>
                                       <Image id='profileImage-image' src={imageSource} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                     </div>
                                    )}
                                  <div style={{ position: 'absolute', top: '0', left: '100px', display: 'flex', flexDirection: 'column' }}>
    <div style={{ color: 'white', fontSize: '18px', fontWeight: 'bold' }}>
                                            <span>{titleToDisplay}</span>
                                        </div>
                                        <div style={{ color: 'white', fontSize: '16px' }}>
                                            <span> U.S. Vice President</span>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                 <Button onClick={() => window.open('/us/vicepresidents', "_self")} id='whDocsViewMoreButton' ><Files size={20}></Files> View More</Button>
                            </Container>
                           
                       
                    </div>
                );
            })
        );
        return renderItems;
    };

    return (
     
            <div id='feedDisplayCaro'>
            {renderData()}
            </div>
       
    );
}

export default VicePresidentDisplayOnPageExec;
