import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircleFill,  Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, PlayCircle, PauseCircle, BookHalf, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';

import{ createUSCourtNewsFeed, createUSCourtNewsFeedMobile, createCourtAppeals, 
  saveToCollection, createFullBillFeed, ListItem,
  createCourtAppealsMobile,supremeCourtSearch, Speak, createCourtOfInternationalTrade,
  createCourtAppealsFirstCircuit, createCourtAppealsSecondCircuit, createCourtAppealsThirdCircuit, 
  createCourtAppealsFourthCircuit, createCourtAppealsFifthCircuit, createCourtAppealsSixthCircuit,
  createCourtAppealsSeventhCircuit, createCourtAppealsEighthCircuit, createCourtAppealsNinthCircuit, 
  createCourtAppealsTenthCircuit, createCourtAppealsEleventhCircuit, createCourtAppealsDCCircuit,
  createCourtAppealsFederalCircuit, CreateCourtAppealsCircuit, CreateFeedItem, CreateFeedItemCourtAppeals, CreateFeedItemSupremeCourtArguments, CreateFeedItemTest, CreateFeedItemFlip, CreateFeedAPI, CreateFeedAPIOpinions} from './Functions'
import CourtOfInternationalTrade from './CourtOfInternationalTrade';
import CourtAppealsFirstCircuit from './CourtAppealsFirstCircuit';
import CourtAppealsSecondCircuit from './CourtAppealsSecondCircuit';
import CourtAppealsThirdCircuit from './CourtAppealsThirdCircuit';
import CourtAppealsFourthCircuit from './CourtAppealsFourthCircuit';
import CourtAppealsFifthCircuit from './CourtAppealsFifthCircuit';
import CourtAppealsSixthCircuit from './CourtAppealsSixthCircuit';
import CourtAppealsSeventhCircuit from './CourtAppealsSeventhCircuit';
import CourtAppealsEighthCircuit from './CourtAppealsEighthCircuit';
import CourtAppealsNinthCircuit from './CourtAppealsNinthCircuit';
import CourtAppealsTenthCircuit from './CourtAppealsTenthCircuit';
import CourtAppealsEleventhCircuit from './CourtAppealsEleventhCircuit';
import CourtAppealsDCCircuit from './CourtAppealsDCCircuit';
import CourtAppealsFederalCircuit from './CourtAppealsFederalCircuit';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import SupremeCourtArguments from './SupremeCourtArguments';
import SupremeCourtOpinions from './SupremeCourtOpinions';
import DistrictCourtNorthernAlabama from './District Courts/DistrictCourtNorthernAlabama';
import DistrictCourtSouthernAlabama from './District Courts/DistrictCourtSouthernAlabama';
import DistrictCourtMiddleAlabama from './District Courts/DistrictCourtMiddleAlabama';
import DistrictCourtAlaska from './District Courts/DistrictCourtAlaska';
import DistrictCourtArizona from './District Courts/DistrictCourtArizona';
import DistrictCourtEasternArkansas from './District Courts/DistrictCourtEasternArkansas';
import DistrictCourtEasternCalifornia from './District Courts/DistrictCourtEasternCalifornia';
import DistrictCourtNorthernCalifornia from './District Courts/DistrictCourtNorthernCalifornia';
import DistrictCourtSouthernCalifornia from './District Courts/DistrictCourtSouthernCalifornia';
import DistrictCourtColrado from './District Courts/DistrictCourtColrado';
import DistrictCourtConnecticut from './District Courts/DistrictCourtConnecticut';
import DistrictCourtColumbia from './District Courts/DistrictCourtColumbia';
import DistrictCourtDelaware from './District Courts/DistrictCourtDelaware';
import DistrictCourtSouthernFlorida from './District Courts/DistrictCourtSouthernFlorida';
import DistrictCourtNorthernFlorida from './District Courts/DistrictCourtNorthernFlorida';
import DistrictCourtMiddleGeorgia from './District Courts/DistrictCourtMiddleGeorgia';
import DistrictCourtNorthernGeorgia from './District Courts/DistrictCourtNorthernGeorgia';
import DistrictCourtGuam from './District Courts/DistrictCourtGuam';
import DistrictCourtHawaii from './District Courts/DistrictCourtHawaii';
import DistrictCourtIdaho from './District Courts/DistrictCourtIdaho';
import DistrictCourtNorthernIllinois from './District Courts/DistrictCourtNorthernIllinois';
import DistrictCourtSouthernIndiana from './District Courts/DistrictCourtSouthernIndiana';
import DistrictCourtNorthernIowa from './District Courts/DistrictCourtNorthernIowa';
import DistrictCourtKansas from './District Courts/DistrictCourtKansas';
import DistrictCourtEasternKentucky from './District Courts/DistrictCourtEasternKentucky';
import DistrictCourtWesternKentucky from './District Courts/DistrictCourtWesternKentucky';
import DistrictCourtEasternLouisiana from './District Courts/DistrictCourtEasternLouisiana';
import DistrictCourtMiddleLouisiana from './District Courts/DistrictCourtMiddleLouisiana';
import DistrictCourtMaine from './District Courts/DistrictCourtMaine';
import DistrictCourtMaryland from './District Courts/DistrictCourtMaryland';
import DistrictCourtMassachusetts from './District Courts/DistrictCourtMassachusetts';
import DistrictCourtEasternMichigan from './District Courts/DistrictCourtEasternMichigan';
import DistrictCourtWesternMichigan from './District Courts/DistrictCourtWesternMichigan';
import DistrictCourtMinnesota from './District Courts/DistrictCourtMinnesota';
import DistrictCourtEasternMissouri from './District Courts/DistrictCourtEasternMissouri';
import DistrictCourtWesternMissouri from './District Courts/DistrictCourtWesternMissouri';
import DistrictCourtMontana from './District Courts/DistrictCourtMontana';
import DistrictCourtNebraska from './District Courts/DistrictCourtNebraska';
import DistrictCourtNewHampshire from './District Courts/DistrictCourtNewJersey';
import DistrictCourtNewJersey from './District Courts/DistrictCourtNewHampshire';
import DistrictCourtNewMexico from './District Courts/DistrictCourtNewMexico';
import DistrictCourtEasternNewYork from './District Courts/DistrictCourtEasternNewYork';
import DistrictCourtNorthernNewYork from './District Courts/DistrictCourtNorthernNewYork';
import DistrictCourtMiddleNorthCarolina from './District Courts/DistrictCourtMiddleNorthCarolina';
import DistrictCourtNorthDakota from './District Courts/DistrictCourtNorthDakota';
import DistrictCourtNorthernMarianaIslands from './District Courts/DistrictCourtNorthernMarianaIslands';
import DistrictCourtNorthernOhio from './District Courts/DistrictCourtNorthernOhio';
import DistrictCourtSouthernOhio from './District Courts/DistrictCourtSouthernOhio';
import DistrictCourtEasternOklahoma from './District Courts/DistrictCourtEasternOklahoma';
import DistrictCourtWesternOklahoma from './District Courts/DistrictCourtWesternOklahoma';
import DistrictCourtOregon from './District Courts/DistrictCourtOregon';
import DistrictCourtEasternPennsylvania from './District Courts/DistrictCourtEasternPennsylvania';
import DistrictCourtMiddlePennslyvania from './District Courts/DistrictCourtMiddlePennslyvania';
import DistrictCourtWesternPennsylvania from './District Courts/DistrictCourtWesternPennsylvania';
import DistrictCourtPuertoRico from './District Courts/DistrictCourtPuertoRico';
import DistrictCourtRhodeIsland from './District Courts/DistrictCourtRhodeIsland';
import DistrictCourtSouthDakota from './District Courts/DistrictCourtSouthDakota';
import DistrictCourtEasternTennessee from './District Courts/DistrictCourtEasternTennessee';
import DistrictCourtWesternTennessee from './District Courts/DistrictCourtWesternTennessee';
import DistrictCourtMiddleTennessee from './District Courts/DistrictCourtMiddleTennessee';
import DistrictCourtNorthernTexas from './District Courts/DistrictCourtNorthernTexas';
import DistrictCourtSouthernTexas from './District Courts/DistrictCourtSouthernTexas';
import DistrictCourtWesternTexas from './District Courts/DistrictCourtWesternTexas';
import DistrictCourtUtah from './District Courts/DistrictCourtUtah';
import DistrictCourtVirginIslands from './District Courts/DistrictCourtVirginIslands';
import DistrictCourtVermont from './District Courts/DistrictCourtVermont';
import DistrictCourtEasternWashington from './District Courts/DistrictCourtEasternWashington';
import DistrictCourtNorthernWestVirginia from './District Courts/DistrictCourtNorthernWestVirginia';
import DistrictCourtWyoming from './District Courts/DistrictCourtWyoming';
import JusticeDisplay from './JusticeDisplay';
import GenerateFeed from './GenerateFeed';
import GenerateFeedAudio from './GenerateFeedAudio';
import GenerateFeedOpinions from './GenerateFeedOpinions';

  

const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);


var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;

export const JudicialBranchMobile = () =>   {
    render()
    
    {
  
  
      const [show, setShow] = useState(true);
    
      const [modalIsOpen, setModalIsOpen] = useState(false)
      const [fullscreen, setFullScreen] = useState(true);
    
      
      const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
    
      const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
    
    
      const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
      const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
    
    
      const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
      const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
     
      const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
    
      const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
    
      const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
    
      const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
    
    
      const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
      const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
      const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
      const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
      const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
      const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
      const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
      const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
      const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
      const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
      const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
      const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
      const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
      const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
      const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
      const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
      const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
      const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
      const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
      const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
      const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
      const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
      const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
      const [trendingBillsOptionSix, setTrendingBillsOptionSixModalIsOpen ] = useState(false)
      const [trendingBillsOptionSeven, setTrendingBillsOptionSevenModalIsOpen ] = useState(false)
      const [trendingBillsOptionEight, setTrendingBillsOptionEightModalIsOpen ] = useState(false)
      const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
      const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
      const [trendingBillsOptionEleven, setTrendingBillsOptionElevenModalIsOpen ] = useState(false)
      const [trendingBillsOptionTweleve, setTrendingBillsOptionTweleveModalIsOpen ] = useState(false)
      const [trendingBillsOptionThirteen, setTrendingBillsOptionThirteenModalIsOpen ] = useState(false)
      const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
        const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
        const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
        const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
        const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
        const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
        const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
        const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
        const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
        const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
        const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
        const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
        const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
        const [houseCommitteeOnOversightAndReformModalIsOpen, setHouseCommitteeOnOversightAndReformModalIsOpen] = useState(false)
        const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
    
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [courtOfInternationalTradeModalIsOpen, setCourtOfInternationalTradeModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
        
        const [courtAppealsFirstCircuitModalIsOpen, setCourtAppealsFirstCircuitModalIsOpen] = useState(false)
        const [courtAppealsSecondCircuitModalIsOpen, setCourtAppealsSecondCircuitModalIsOpen] = useState(false)
        const [courtAppealsThirdCircuitModalIsOpen, setCourtAppealsThirdCircuitModalIsOpen] = useState(false)
        const [courtAppealsFourthCircuitModalIsOpen, setCourtAppealsFourthCircuitModalIsOpen] = useState(false)
        const [courtAppealsFifthCircuitModalIsOpen, setCourtAppealsFifthCircuitModalIsOpen] = useState(false)
        const [courtAppealsSixthCircuitModalIsOpen, setCourtAppealsSixthCircuitModalIsOpen] = useState(false)
        const [courtAppealsSeventhCircuitModalIsOpen, setCourtAppealsSeventhCircuitModalIsOpen] = useState(false)
        const [courtAppealsEighthCircuitModalIsOpen, setCourtAppealsEighthCircuitModalIsOpen] = useState(false)
        const [courtAppealsNinthCircuitModalIsOpen, setCourtAppealsNinthCircuitModalIsOpen] = useState(false)
        const [courtAppealsTenthCircuitModalIsOpen, setCourtAppealsTenthCircuitModalIsOpen] = useState(false)
        const [courtAppealsEleventhCircuitModalIsOpen, setCourtAppealsEleventhCircuitModalIsOpen] = useState(false)
        const [courtAppealsDCCircuitModalIsOpen, setCourtAppealsDCCircuitModalIsOpen] = useState(false)
        const [courtAppealsFederalCircuitModalIsOpen, setCourtAppealsFederalCircuitModalIsOpen] = useState(false)
    
    
        const [congressionalHearingsModalIsOpen, setCongressionalHearingsModalIsOpen] = useState(false)
    
        const [houseFloorModalIsOpen, setHouseFloorModalIsOpen] = useState(false)
    
    
    
      const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
      const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
    
    
    
      const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
      const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
      const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
      const [courtOpinionsModalIsOpen, setCourtOpinionsModalIsOpen] = useState(false)
      const [courtArgumentsModalIsOpen, setCourtArgumentsModalIsOpen] = useState(false)
      const [districtCourt1ModalIsOpen, setDistrictCourt1ModalIsOpen] = useState(false)
      const [districtCourt2ModalIsOpen, setDistrictCourt2ModalIsOpen] = useState(false)
      const [districtCourt3ModalIsOpen, setDistrictCourt3ModalIsOpen] = useState(false)
      const [districtCourt4ModalIsOpen, setDistrictCourt4ModalIsOpen] = useState(false)
      const [districtCourt5ModalIsOpen, setDistrictCourt5ModalIsOpen] = useState(false)
      const [districtCourt6ModalIsOpen, setDistrictCourt6ModalIsOpen] = useState(false)
      const [districtCourt7ModalIsOpen, setDistrictCourt7ModalIsOpen] = useState(false)
      const [districtCourt8ModalIsOpen, setDistrictCourt8ModalIsOpen] = useState(false)
      const [districtCourt9ModalIsOpen, setDistrictCourt9ModalIsOpen] = useState(false)
      const [districtCourt10ModalIsOpen, setDistrictCourt10ModalIsOpen] = useState(false)
      const [districtCourt11ModalIsOpen, setDistrictCourt11ModalIsOpen] = useState(false)
      const [districtCourt12ModalIsOpen, setDistrictCourt12ModalIsOpen] = useState(false)
      const [districtCourt13ModalIsOpen, setDistrictCourt13ModalIsOpen] = useState(false)
      const [districtCourt14ModalIsOpen, setDistrictCourt14ModalIsOpen] = useState(false)
      const [districtCourt15ModalIsOpen, setDistrictCourt15ModalIsOpen] = useState(false)
      const [districtCourt16ModalIsOpen, setDistrictCourt16ModalIsOpen] = useState(false)
      const [districtCourt17ModalIsOpen, setDistrictCourt17ModalIsOpen] = useState(false)
      const [districtCourt18ModalIsOpen, setDistrictCourt18ModalIsOpen] = useState(false)
      const [districtCourt19ModalIsOpen, setDistrictCourt19ModalIsOpen] = useState(false)
      const [districtCourt20ModalIsOpen, setDistrictCourt20ModalIsOpen] = useState(false)
      const [districtCourt21ModalIsOpen, setDistrictCourt21ModalIsOpen] = useState(false)
      const [districtCourt22ModalIsOpen, setDistrictCourt22ModalIsOpen] = useState(false)
      const [districtCourt23ModalIsOpen, setDistrictCourt23ModalIsOpen] = useState(false)
      const [districtCourt24ModalIsOpen, setDistrictCourt24ModalIsOpen] = useState(false);
      const [districtCourt25ModalIsOpen, setDistrictCourt25ModalIsOpen] = useState(false);
      const [districtCourt26ModalIsOpen, setDistrictCourt26ModalIsOpen] = useState(false);
      const [districtCourt27ModalIsOpen, setDistrictCourt27ModalIsOpen] = useState(false);
      const [districtCourt28ModalIsOpen, setDistrictCourt28ModalIsOpen] = useState(false);
      const [districtCourt29ModalIsOpen, setDistrictCourt29ModalIsOpen] = useState(false);
      const [districtCourt30ModalIsOpen, setDistrictCourt30ModalIsOpen] = useState(false);
      const [districtCourt31ModalIsOpen, setDistrictCourt31ModalIsOpen] = useState(false);
      const [districtCourt32ModalIsOpen, setDistrictCourt32ModalIsOpen] = useState(false);
      const [districtCourt33ModalIsOpen, setDistrictCourt33ModalIsOpen] = useState(false);
      const [districtCourt34ModalIsOpen, setDistrictCourt34ModalIsOpen] = useState(false);
      const [districtCourt35ModalIsOpen, setDistrictCourt35ModalIsOpen] = useState(false);
      const [districtCourt36ModalIsOpen, setDistrictCourt36ModalIsOpen] = useState(false);
      const [districtCourt37ModalIsOpen, setDistrictCourt37ModalIsOpen] = useState(false);
      const [districtCourt38ModalIsOpen, setDistrictCourt38ModalIsOpen] = useState(false);
      const [districtCourt39ModalIsOpen, setDistrictCourt39ModalIsOpen] = useState(false);
      const [districtCourt40ModalIsOpen, setDistrictCourt40ModalIsOpen] = useState(false);
      const [districtCourt41ModalIsOpen, setDistrictCourt41ModalIsOpen] = useState(false);
      const [districtCourt42ModalIsOpen, setDistrictCourt42ModalIsOpen] = useState(false);
      const [districtCourt43ModalIsOpen, setDistrictCourt43ModalIsOpen] = useState(false);
      const [districtCourt44ModalIsOpen, setDistrictCourt44ModalIsOpen] = useState(false);
      const [districtCourt45ModalIsOpen, setDistrictCourt45ModalIsOpen] = useState(false);
      const [districtCourt46ModalIsOpen, setDistrictCourt46ModalIsOpen] = useState(false);
      const [districtCourt47ModalIsOpen, setDistrictCourt47ModalIsOpen] = useState(false);
      const [districtCourt48ModalIsOpen, setDistrictCourt48ModalIsOpen] = useState(false);
      const [districtCourt49ModalIsOpen, setDistrictCourt49ModalIsOpen] = useState(false);
      const [districtCourt50ModalIsOpen, setDistrictCourt50ModalIsOpen] = useState(false);
      const [districtCourt51ModalIsOpen, setDistrictCourt51ModalIsOpen] = useState(false);
      const [districtCourt52ModalIsOpen, setDistrictCourt52ModalIsOpen] = useState(false);
      const [districtCourt53ModalIsOpen, setDistrictCourt53ModalIsOpen] = useState(false);
      const [districtCourt54ModalIsOpen, setDistrictCourt54ModalIsOpen] = useState(false);
      const [districtCourt55ModalIsOpen, setDistrictCourt55ModalIsOpen] = useState(false);
      const [districtCourt56ModalIsOpen, setDistrictCourt56ModalIsOpen] = useState(false);
      const [districtCourt57ModalIsOpen, setDistrictCourt57ModalIsOpen] = useState(false);
      const [districtCourt58ModalIsOpen, setDistrictCourt58ModalIsOpen] = useState(false);
      const [districtCourt59ModalIsOpen, setDistrictCourt59ModalIsOpen] = useState(false);
      const [districtCourt60ModalIsOpen, setDistrictCourt60ModalIsOpen] = useState(false);
      const [districtCourt61ModalIsOpen, setDistrictCourt61ModalIsOpen] = useState(false);
      const [districtCourt62ModalIsOpen, setDistrictCourt62ModalIsOpen] = useState(false);
      const [districtCourt63ModalIsOpen, setDistrictCourt63ModalIsOpen] = useState(false);
      const [districtCourt64ModalIsOpen, setDistrictCourt64ModalIsOpen] = useState(false);
      const [districtCourt65ModalIsOpen, setDistrictCourt65ModalIsOpen] = useState(false);
      const [districtCourt66ModalIsOpen, setDistrictCourt66ModalIsOpen] = useState(false);
      const [districtCourt67ModalIsOpen, setDistrictCourt67ModalIsOpen] = useState(false);
      const [districtCourt68ModalIsOpen, setDistrictCourt68ModalIsOpen] = useState(false);
      const [districtCourt69ModalIsOpen, setDistrictCourt69ModalIsOpen] = useState(false);
      const [showBioModal, setBioModal] = useState(false);
    
      const [modalInfo, setModalInfo] = useState({
        isOpen: false,
        title: '',
        content: null
      });
    
      const openModal = (title, content) => {
        setModalInfo({
          isOpen: true,
          title,
          content
        });
      };
    
      const closeModal = () => {
        setModalInfo({
          isOpen: false,
          title: '',
          content: null
        });
      };

      const CourtFeedsMobile = () => {
        const [modalState, setModalState] = React.useState({});
        const feeds = [
          { id: 'alnd', name: 'District Court Northern District of Alabama' },
          { id: 'alsd', name: 'District Court Southern District of Alabama' },
          { id: 'almd', name: 'District Court Middle District of Alabama' },
          { id: 'akd', name: 'District Court District of Alaska' },
          { id: 'azd', name: 'District Court District of Arizona' },
          { id: 'ared', name: 'District Court Eastern District of Arkansas' },
          { id: 'caed', name: 'District Court Eastern District of California' },
          { id: 'cand', name: 'District Court Northern District of California' },
          { id: 'casd', name: 'District Court Southern District of California' },
          { id: 'cod', name: 'District Court District of Colorado' },
          { id: 'ctd', name: 'District Court District of Connecticut' },
          { id: 'dcd', name: 'District Court District of Columbia' },
          { id: 'ded', name: 'District Court District of Delaware' },
          { id: 'flsd', name: 'District Court Southern District of Florida' },
          { id: 'flnd', name: 'District Court Northern District of Florida' },
          { id: 'gamd', name: 'District Court Middle District of Georgia' },
          { id: 'gand', name: 'District Court Northern District of Georgia' },
          { id: 'gud', name: 'District Court District of Guam' },
          { id: 'hid', name: 'District Court District of Hawaii' },
          { id: 'idd', name: 'District Court District of Idaho' },
          { id: 'ilnd', name: 'District Court Northern District of Illinois' },
          { id: 'ilsd', name: 'District Court Southern District of Illinois' },
          { id: 'insd', name: 'District Court Southern District of Indiana' },
          { id: 'iand', name: 'District Court Northern District of Iowa' },
          { id: 'ksd', name: 'District Court District of Kansas' },
          { id: 'kyed', name: 'District Court Eastern District of Kentucky' },
          { id: 'kywd', name: 'District Court Western District of Kentucky' },
          { id: 'laed', name: 'District Court Eastern District of Louisiana' },
          { id: 'lamd', name: 'District Court Middle District of Louisiana' },
          { id: 'med', name: 'District Court District of Maine' },
          { id: 'mdd', name: 'District Court District of Maryland' },
          { id: 'mad', name: 'District Court District of Massachusetts' },
          { id: 'mied', name: 'District Court Eastern District of Michigan' },
          { id: 'miwd', name: 'District Court Western District of Michigan' },
          { id: 'mnd', name: 'District Court District of Minnesota' },
          { id: 'moed', name: 'District Court Eastern District of Missouri' },
          { id: 'mowd', name: 'District Court Western District of Missouri' },
          { id: 'mtd', name: 'District Court District of Montana' },
          { id: 'ned', name: 'District Court District of Nebraska' },
          { id: 'nhd', name: 'District Court District of New Hampshire' },
          { id: 'njd', name: 'District Court District of New Jersey' },
          { id: 'nmd', name: 'District Court District of New Mexico' },
          { id: 'nyed', name: 'District Court Eastern District of New York' },
          { id: 'nynd', name: 'District Court Northern District of New York' },
          { id: 'ncmd', name: 'District Court Middle District of North Carolina' },
          { id: 'ndd', name: 'District Court District of North Dakota' },
          { id: 'nmid', name: 'District Court District of Northern Mariana Islands' },
          { id: 'ohnd', name: 'District Court Northern District of Ohio' },
          { id: 'ohsd', name: 'District Court Southern District of Ohio' },
          { id: 'oked', name: 'District Court Eastern District of Oklahoma' },
          { id: 'okwd', name: 'District Court Western District of Oklahoma' },
          { id: 'ord', name: 'District Court District of Oregon' },
          { id: 'paed', name: 'District Court Eastern District of Pennsylvania' },
          { id: 'pamd', name: 'District Court Middle District of Pennsylvania' },
          { id: 'pawd', name: 'District Court Western District of Pennsylvania' },
          { id: 'prd', name: 'District Court District of Puerto Rico' },
          { id: 'rid', name: 'District Court District of Rhode Island' },
          { id: 'sdd', name: 'District Court District of South Dakota' },
          { id: 'tned', name: 'District Court Eastern District of Tennessee' },
          { id: 'tnwd', name: 'District Court Western District of Tennessee' },
          { id: 'tnmd', name: 'District Court Middle District of Tennessee' },
          { id: 'txnd', name: 'District Court Northern District of Texas' },
          { id: 'txsd', name: 'District Court Southern District of Texas' },
          { id: 'txwd', name: 'District Court Western District of Texas' },
          { id: 'utd', name: 'District Court District of Utah' },
          { id: 'vid', name: 'District Court of the Virgin Islands' },
          { id: 'vtd', name: 'District Court District of Vermont' },
          { id: 'waed', name: 'District Court Eastern District of Washington' },
          { id: 'wvnd', name: 'District Court Northern District of West Virginia' },
          { id: 'wyd', name: 'District Court District of Wyoming' }
        ];
        const openModal = (id) => setModalState({ ...modalState, [id]: true });
        const closeModal = (id) => setModalState({ ...modalState, [id]: false });

        
        const chunkFeeds = (feeds, size) =>
  feeds.reduce((result, value, index) => {
    if (index % size === 0) result.push([]);
    result[result.length - 1].push(value);
    return result;
  }, []);
        const feedPairs = chunkFeeds(feeds, 1); // Adjusted to single feed per row for mobile format
      
        return (
          <>
      {feedPairs.map((pair, index) => (
        <Col key={index}>
          {pair.map((feed) => (
            <>
              <h5
                id="presidentialFeedHeadingMobile"
                onClick={() => openModal(feed.id)}
              >
                {feed.name}
              </h5>
              <div className="courtNewsMobile" id={feed.id}></div>

              {CreateFeedAPI(
                `&skip=0&schema=united_states_of_america&table=state_judicial_publications&order_by=created_at&filter_column=feed_title&filter_string=${encodeURIComponent(
                  feed.name
                )}`,
                feed.id,
                feed.name,
                'Judicial',
                'N/A',
                'Document',
                GenerateFeed
              )}

              <Modal
                id="departmentOfStateModal"
                show={modalState[feed.id]}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => closeModal(feed.id)}
              >
                <Modal.Header id="whiteHouseDocsModalHeader" closeButton>
                  <h3>{feed.name}</h3>
                </Modal.Header>
                <GenerateFeed
                  url={`&skip=0&schema=united_states_of_america&table=state_judicial_publications&order_by=created_at&filter_column=feed_title&filter_string=${encodeURIComponent(
                    feed.name
                  )}`}
                  name={feed.name}
                  branch="Judicial"
                  topic="N/A"
                  buttonName="Document"
                />
              </Modal>
            </>
          ))}
        </Col>
      ))}
    </>
  );
};
  
    return(
       <>
   <MobileTopNavbar/>
  
  
   <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />

  
  <div id='homebg'>
  
  
    {/*Drop Down Code 
      <div id='changeBranchDropdownMobile'>
        <Dropdown   >
          <Dropdown.Toggle  id='branchesDropdownMobile' >
            Select Branch
          </Dropdown.Toggle>
          <Dropdown.Menu id='docsDropdown'>
            
            <Dropdown.Item id='docsDropdownItem' href='/us/legislative' >Legislative</Dropdown.Item>
            <Dropdown.Item id='docsDropdownItem' href='/us/executive' >Executive</Dropdown.Item>
            <Dropdown.Item id='docsDropdownItem' href='judicial' >Judical</Dropdown.Item>
  
  
          </Dropdown.Menu>
        </Dropdown>
      </div>
  
  
      <div id='changeBranchDropdownMobile'>
        <Dropdown  >
          <Dropdown.Toggle id='branchesDropdownMobile' >
            Select Topic
          </Dropdown.Toggle>
          <Dropdown.Menu id='docsDropdown'>
            <Dropdown.Item id='docsDropdownItem' href='/us/economy'>Economy</Dropdown.Item>
            <Dropdown.Item id='docsDropdownItem' href='/us/environment' >Environment</Dropdown.Item>
            <Dropdown.Item id='docsDropdownItem' href='/us/foreignaffairs' >Foreign Affairs</Dropdown.Item>
            <Dropdown.Item id='docsDropdownItem' href='/us/health' >Health</Dropdown.Item>
  
            
          </Dropdown.Menu>
        </Dropdown>
      </div>
  */}
  
  
  
 
  
  
  <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div id="profileImageOnPageDepartment">
        <Image id="profileImage-imageOnPageDepartment" src="/judicialBranch.png" />
      </div>
      <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
      {/* <Pen></Pen> */}
        <h5 id='branchHeadingMobile'>Judicial Branch</h5>
      </div>
    </div>
    <div style={{ marginLeft: 'auto' }}>
     
    </div>
  </div> 
  <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
  Article III of the Constitution, which establishes the Judicial Branch, leaves Congress significant discretion to determine the shape and structure of the federal judiciary.
  <Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
  <br></br>
  <br></br>

  </div>
</div>


<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> The Judicial Branch</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>Where the executive and legislative branches are elected by the people, members of the Judicial Branch are appointed by the President and confirmed by the Senate.</p>

<p>Article III of the Constitution, which establishes the Judicial Branch, leaves Congress significant discretion to determine the shape and structure of the federal judiciary. Even the number of Supreme Court Justices is left to Congress — at times there have been as few as six, while the current number (nine, with one Chief Justice and eight Associate Justices) has only been in place since 1869. The Constitution also grants Congress the power to establish courts inferior to the Supreme Court, and to that end Congress has established the United States district courts, which try most federal cases, and 13 United States courts of appeals, which review appealed district court cases.</p>

<p>Federal judges can only be removed through impeachment by the House of Representatives and conviction in the Senate. Judges and Justices serve no fixed term — they serve until their death, retirement, or conviction by the Senate. By design, this insulates them from the temporary passions of the public, and allows them to apply the law with only justice in mind, and not electoral or political concerns.</p>

<p>Generally, Congress determines the jurisdiction of the federal courts. In some cases, however — such as in the example of a dispute between two or more U.S. states — the Constitution grants the Supreme Court original jurisdiction, an authority that cannot be stripped by Congress.</p>

<p>The courts only try actual cases and controversies — a party must show that it has been harmed in order to bring suit in court. This means that the courts do not issue advisory opinions on the constitutionality of laws or the legality of actions if the ruling would have no practical effect. Cases brought before the judiciary typically proceed from district court to appellate court and may even end at the Supreme Court, although the Supreme Court hears comparatively few cases each year.</p>

<p>Federal courts enjoy the sole power to interpret the law, determine the constitutionality of the law, and apply it to individual cases. The courts, like Congress, can compel the production of evidence and testimony through the use of a subpoena. The inferior courts are constrained by the decisions of the Supreme Court — once the Supreme Court interprets a law, inferior courts must apply the Supreme Court’s interpretation to the facts of a particular case.</p>

<p><strong>The Supreme Court of the United States</strong></p>

<p>The Supreme Court of the United States is the highest court in the land and the only part of the federal judiciary specifically required by the Constitution.</p>

<p>The Constitution does not stipulate the number of Supreme Court Justices; the number is set instead by Congress. There have been as few as six, but since 1869 there have been nine Justices, including one Chief Justice. All Justices are nominated by the President, confirmed by the Senate, and hold their offices under life tenure. Since Justices do not have to run or campaign for re-election, they are thought to be insulated from political pressure when deciding cases. Justices may remain in office until they resign, pass away, or are impeached and convicted by Congress.</p>

<p>The Court’s caseload is almost entirely appellate in nature, and the Court’s decisions cannot be appealed to any authority, as it is the final judicial arbiter in the United States on matters of federal law. However, the Court may consider appeals from the highest state courts or from federal appellate courts. The Court also has original jurisdiction over limited types of cases, including those involving ambassadors and other diplomats, and in cases between states.</p>

<p>Although the Supreme Court may hear an appeal on any question of law provided it has jurisdiction, it usually does not hold trials. Instead, the Court’s task is to interpret the meaning of a law, to decide whether a law is relevant to a particular set of facts, or to rule on how a law should be applied. Lower courts are obligated to follow the precedent set by the Supreme Court when rendering decisions.</p>

<p>In almost all instances, the Supreme Court does not hear appeals as a matter of right; instead, parties must petition the Court for a writ of certiorari. It is the Court’s custom and practice to “grant cert” if four of the nine Justices decide that they should hear the case. Of the approximately 7,500 requests for certiorari filed each year, the Court usually grants cert to fewer than 150. These are typically cases that the Court considers sufficiently important to require their review; a common example is the occasion when two or more of the federal courts of appeals have ruled differently on the same question of federal law.</p>

<p>If the Court grants certiorari, Justices accept legal briefs from the parties to the case, as well as from amicus curiae, or “friends of the court.” These can include industry trade groups, academics, or even the U.S. government itself. Before issuing a ruling, the Supreme Court usually hears oral arguments, where the various parties to the suit present their arguments and the Justices ask them questions. If the case involves the federal government, the Solicitor General of the United States presents arguments on behalf of the United States. The Justices then hold private conferences, make their decision, and (often after a period of several months) issue the Court’s opinion, along with any dissenting arguments that may have been written.</p>

<p><strong>The Judicial Process</strong></p>

<p>Article III of the Constitution of the United States guarantees that every person accused of wrongdoing has the right to a fair trial before a competent judge and a jury of one’s peers.</p>

<p>The Fourth, Fifth, Sixth, and Eighth Amendments to the Constitution provide additional protections for those accused of a crime. These include:</p>

<ul>
  <li>A guarantee that no person shall be deprived of life, liberty, or property without the due process of law</li>
  <li>Protection against being tried for the same crime twice (“double jeopardy”)</li>
  <li>The right to a speedy trial by an impartial jury</li>
  <li>The right to cross-examine witnesses, and to call witnesses to support their case</li>
  <li>The right to legal representation</li>
  <li>The right to avoid self-incrimination</li>
  <li>Protection from excessive bail, excessive fines, and cruel and unusual punishments</li>
</ul>

<p>Criminal proceedings can be conducted under either state or federal law, depending on the nature and extent of the crime. A criminal legal procedure typically begins with an arrest by a law enforcement officer. If a grand jury chooses to deliver an indictment, the accused will appear before a judge and be formally charged with a crime, at which time he or she may enter a plea.</p>

<p>The defendant is given time to review all the evidence in the case and to build a legal argument. Then, the case is brought to trial and decided by a jury. If the defendant is determined to be not guilty of the crime, the charges are dismissed. Otherwise, the judge determines the sentence, which can include prison time, a fine, or even execution.</p>

<p>Civil cases are similar to criminal ones, but instead of arbitrating between the state and a person or organization, they deal with disputes between individuals or organizations. In civil cases, if a party believes that it has been wronged, it can file suit in civil court to attempt to have that wrong remedied through an order to cease and desist, alter behavior, or award monetary damages. After the suit is filed and evidence is gathered and presented by both sides, a trial proceeds as in a criminal case. If the parties involved waive their right to a jury trial, the case can be decided by a judge; otherwise, the case is decided and damages awarded by a jury.</p>

<p>After a criminal or civil case is tried, it may be appealed to a higher court — a federal court of appeals or state appellate court. A litigant who files an appeal, known as an “appellant,” must show that the trial court or administrative agency made a legal error that affected the outcome of the case. An appellate court makes its decision based on the record of the case established by the trial court or agency — it does not receive additional evidence or hear witnesses. It may also review the factual findings of the trial court or agency, but typically may only overturn a trial outcome on factual grounds if the findings were “clearly erroneous.” If a defendant is found not guilty in a criminal proceeding, he or she cannot be retried on the same set of facts.</p>

<p>Federal appeals are decided by panels of three judges. The appellant presents legal arguments to the panel, in a written document called a “brief.” In the brief, the appellant tries to persuade the judges that the trial court made an error, and that the lower decision should be reversed. On the other hand, the party defending against the appeal, known as the “appellee” or “respondent,” tries in its brief to show why the trial court decision was correct, or why any errors made by the trial court are not significant enough to affect the outcome of the case.</p>

<p>The court of appeals usually has the final word in the case, unless it sends the case back to the trial court for additional proceedings. In some cases the decision may be reviewed en banc — that is, by a larger group of judges of the court of appeals for the circuit.</p>

<p>A litigant who loses in a federal court of appeals, or in the highest court of a state, may file a petition for a “writ of certiorari,” which is a document asking the U.S. Supreme Court to review the case. The Supreme Court, however, is not obligated to grant review. The Court typically will agree to hear a case only when it involves a new and important legal principle, or when two or more federal appellate courts have interpreted a law differently. (There are also special circumstances in which the Supreme Court is required by law to hear an appeal.) When the Supreme Court hears a case, the parties are required to file written briefs and the Court may hear oral argument.</p>

<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-judicial-branch/", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-judicial-branch/
     </Link>
 </div>

       </Modal>  

       
           <Col>

           
                  <h5 id='presidentialFeedHeadingMobile' onClick={() => setCourtNewsModalIsOpen(true)}>U.S. Court News</h5>

                 
                    <div class='courtNewsMobile' id='courtNews'></div>
                    

                    {CreateFeedItem('https://www.uscourts.gov/news/rss', 'courtNews', 'U.S. Court News', 'Judicial', 'N/A', FullUSCourtNews)}


                    


                
                  </Col>

             
                 

                <Modal id='fullUSCourtModal' show={courtNewsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtNewsModalIsOpen(false)}>

                  <Modal.Header id='fullUSCourtNewsModalHeader' closeButton><h3>U.S. Court News</h3></Modal.Header>
                  <FullUSCourtNews />

                </Modal>
                <Link id='pageLink' to="/ussupremecourt"><h5 id='branchHeadingMobile' >  Supreme Court<ChevronRight id='clickChevron'></ChevronRight></h5></Link>
                <Col>  

<Carousel controls={false}>

<Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile'>
      <Col>
        <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() =>
                            openModal('Supreme Court Opinions',
                            <GenerateFeedOpinions url='&skip=0&schema=united_states_of_america&table=supreme_court_opinions&order_by=created_at' 
                            name='Supreme Court Opinions' branch='Judicial' topic='N/A' buttonName='Document' />)}>Supreme Court Opinions</h5></Row>
        <div id='courtOpinions'></div>
        {CreateFeedAPIOpinions('&skip=0&schema=united_states_of_america&table=supreme_court_opinions&order_by=created_at', 'courtOpinions', 
                               'Supreme Court Opinions', 'Judicial', 
                               'N/A', 'Doc', GenerateFeedOpinions)}

        </Col>
    </div>
  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile'>
      <Col>
       
          <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() =>
                            openModal('Supreme Court Arguments',
                            <GenerateFeedAudio url='&skip=0&schema=united_states_of_america&table=supreme_court_arguments&order_by=created_at' 
                            name='Supreme Court Arguments' branch='Judicial' topic='N/A' buttonName='Document' />)}>Supreme Court Arguments</h5></Row>


          <div id='courtArguments'></div>
                {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=supreme_court_arguments&order_by=created_at', 'courtArguments', 
                            'Supreme Court Arguments', 'Judicial', 
                            'N/A', 'Document', GenerateFeedAudio)}
    
     </Col>
      </div>
      </Carousel.Item>
    
      <Carousel.Item interval={mobileCarouselInterval}>
<div id='executiveConMobile' > 
    <Col>
    <Link o id='pageLink' to="/us/justices"> <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome'>Justices</h5></Row></Link>
<JusticeDisplay/>
      </Col>
  </div>
  </Carousel.Item>

      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='executiveConMobile'>   
  <Col>
  <div id='viewFullPageButton'>
  <Link o id='pageLink' to="/ussupremecourt"><Button id='viewFullLegislativePageButtonMobile' size='lg'  >View all Supreme Court news</Button></Link>
</div>
  </Col>
      </div>
      </Carousel.Item>


</Carousel>

</Col>        
   
            

             <Col>
 
                 <h5 id='presidentialFeedHeadingMobile' onClick={() => setCourtOfInternationalTradeModalIsOpen(true)}>U.S. Court of International Trade</h5>
 
 
                 
 
                   <div class='courtNewsMobile' id='cit'></div>
                  
 
                   {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-cit.xml', 'cit', 'U.S. Court of International Trade', 'Judicial', 'N/A', CourtOfInternationalTrade)}
                         
                 </Col>


 
                
                 <Modal id='whiteHouseDocsModal' show={courtOfInternationalTradeModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtOfInternationalTradeModalIsOpen(false)}>
                   <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>U.S. Court of International Trade</h3></Modal.Header>
                   <CourtOfInternationalTrade />
                 </Modal>
 
               
     
                 <Col>

<h5 id='presidentialFeedHeadingMobile' onClick={() => setCourtAppealsModalIsOpen(true)}>Court of Appeals for the Federal Circuit</h5>




  <div class='courtNewsMobile' id='courtAppeals'></div>
          
          {CreateFeedItemCourtAppeals('https://www.govinfo.gov/rss/uscourts-ca13.xml', 'courtAppeals', 'U.S. Court of Appeals', 'Judicial', 'N/A', CourtAppeals)}
     
</Col>



<Modal id='whiteHouseDocsModal' show={courtAppealsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Court of Appeals for the Federal Circuit</h3></Modal.Header>
  <CourtAppeals />
</Modal>

          
             
             <Col>
 
                 <h5 id='presidentialFeedHeadingMobile' onClick={() => setCourtAppealsFirstCircuitModalIsOpen(true)}>Court of Appeals for the First Circuit</h5>
 
 
                 
 
                   <div class='courtNewsMobile' id='appealsFC'></div>
                  
 
                   {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca1.xml', 'appealsFC', 'Court of Appeals for the First Circuit', 'Judicial', 'N/A', CourtAppealsFirstCircuit)}
                         
                 </Col>
 
 
                       

                          <Modal id='departmentOfStateModal' show={courtAppealsFirstCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsFirstCircuitModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Court of Appeals for the First Circuit</h3></Modal.Header>
                            <CourtAppealsFirstCircuit />
                 </Modal>
 
               
     


             
             <Col>
 
                 <h5 id='presidentialFeedHeadingMobile' onClick={() => setCourtAppealsSecondCircuitModalIsOpen(true)}>Court of Appeals for the Second Circuit</h5>
 
 
                 
 
                   <div class='courtNewsMobile' id='appealsSC'></div>
             
                   
                   
 
                   {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca2.xml', 'appealsSC', 'Court of Appeals for the Second Circuit', 'Judicial', 'N/A', CourtAppealsSecondCircuit)}
                         
                 </Col>
 
 
                

                          <Modal id='departmentOfStateModal' show={courtAppealsSecondCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsSecondCircuitModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Court of Appeals for the Second Circuit</h3></Modal.Header>
                            <CourtAppealsSecondCircuit />
                 </Modal>
 
               
      


             
             <Col>
 
                 <h5 id='presidentialFeedHeadingMobile' onClick={() => setCourtAppealsThirdCircuitModalIsOpen(true)}>Court of Appeals for the Third Circuit</h5>
 
 
                 
 
                   <div class='courtNewsMobile' id='appealsTC'></div>
                    
                       
                    {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca3.xml', 'appealsTC', 'Court of Appeals for the Third Circuit', 'Judicial', 'N/A', CourtAppealsThirdCircuit)}
                     
                 </Col>
 
 
                

                          <Modal id='departmentOfStateModal' show={courtAppealsThirdCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsThirdCircuitModalIsOpen(false)}>
                            <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Court of Appeals for the Third Circuit</h3></Modal.Header>
                            <CourtAppealsThirdCircuit />
                 </Modal>
 
               
     

            
             
             <Col>
 
                 <h5 id='presidentialFeedHeadingMobile' onClick={() => setCourtAppealsFourthCircuitModalIsOpen(true)}>Court of Appeals for the Fourth Circuit</h5>
 
 
                 
 
                   <div class='courtNewsMobile' id='appealsFourthC'></div>
                    
                        
                    {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca4.xml', 'appealsFourthC', 'Court of Appeals for the Fourth Circuit', 'Judicial', 'N/A', CourtAppealsFourthCircuit)}
                     
                 </Col>
 
 
                

           <Modal id='departmentOfStateModal' show={courtAppealsFourthCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsFourthCircuitModalIsOpen(false)}>
    <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Court of Appeals for the Fourth Circuit</h3></Modal.Header>
           <CourtAppealsThirdCircuit/>
                 </Modal>
 
               
        
             
             <Col>
 
                 <h5 id='presidentialFeedHeadingMobile' onClick={() => setCourtAppealsFifthCircuitModalIsOpen(true)}>Court of Appeals for the Fifth Circuit</h5>
 
 
                 
 
                   <div class='courtNewsMobile' id='appealsFifthC'></div>
                       
                         
                       {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca5.xml', 'appealsFifthC', 'Court of Appeals for the Fifth Circuit', 'Judicial', 'N/A', CourtAppealsFifthCircuit)}
                        
                 </Col>
 
 
                

                 <Modal id='departmentOfStateModal' show={courtAppealsFifthCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsFifthCircuitModalIsOpen(false)}>
    <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Court of Appeals for the Fifth Circuit</h3></Modal.Header>
           <CourtAppealsFifthCircuit/>
                 </Modal>
               
           
             
             <Col>
 
                 <h5 id='presidentialFeedHeadingMobile' onClick={() => setCourtAppealsSixthCircuitModalIsOpen(true)}>Court of Appeals for the Sixth Circuit</h5>
 
 
                 
 
                   <div class='courtNewsMobile' id='appealsSixthC'></div>
                  
                       
                  {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca6.xml', 'appealsSixthC', 'Court of Appeals for the Sixth Circuit', 'Judicial', 'N/A', CourtAppealsSixthCircuit)}
                   
                 </Col>
 
 
                

                 <Modal id='departmentOfStateModal' show={courtAppealsSixthCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsSixthCircuitModalIsOpen(false)}>
    <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Court of Appeals for the Sixth Circuit</h3></Modal.Header>
           <CourtAppealsSixthCircuit/>
                 </Modal>
 
               
     
             
             <Col>
 
                 <h5 id='presidentialFeedHeadingMobile' onClick={() => setCourtAppealsSeventhCircuitModalIsOpen(true)}>Court of Appeals for the Seventh Circuit</h5>
 
 
                 
 
                   <div class='courtNewsMobile' id='appealsSeventhC'></div>
                   
                      
                   {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca7.xml', 'appealsSeventhC', 'Court of Appeals for the Seventh Circuit', 'Judicial', 'N/A', CourtAppealsSeventhCircuit)}
                    

                 </Col>
 
 
                

                 <Modal id='departmentOfStateModal' show={courtAppealsSeventhCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsSeventhCircuitModalIsOpen(false)}>
    <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Court of Appeals for the Seventh Circuit</h3></Modal.Header>
           <CourtAppealsSeventhCircuit/>
                 </Modal>
 
               

             
             <Col>
 
                 <h5 id='presidentialFeedHeadingMobile' onClick={() => setCourtAppealsEighthCircuitModalIsOpen(true)}>Court of Appeals for the Eighth Circuit</h5>
 
 
                 
 
                   <div class='courtNewsMobile' id='appealsEC'></div>
                     
                     {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca8.xml', 'appealsEC', 'Court of Appeals for the Eighth Circuit', 'Judicial', 'N/A', CourtAppealsEighthCircuit)}
                      

                 </Col>
 
 
                
                 <Modal id='departmentOfStateModal' show={courtAppealsEighthCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsEighthCircuitModalIsOpen(false)}>
    <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Court of Appeals for the Eighth Circuit</h3></Modal.Header>
           <CourtAppeals/>
                 </Modal>
 
               
  
             
             <Col>
 
                 <h5 id='presidentialFeedHeadingMobile' onClick={() => setCourtAppealsNinthCircuitModalIsOpen(true)}>Court of Appeals for the Ninth Circuit</h5>
 
 
                 
 
                   <div class='courtNewsMobile' id='appealsNC'></div>
                       
                      
                       {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca9.xml', 'appealsNC', 'Court of Appeals for the Ninth Circuit', 'Judicial', 'N/A', CourtAppealsNinthCircuit)}
                        
                 </Col>
 
 
                
                 <Modal id='departmentOfStateModal' show={courtAppealsNinthCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsNinthCircuitModalIsOpen(false)}>
    <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Court of Appeals for the Ninth Circuit</h3></Modal.Header>
           <CourtAppealsNinthCircuit/>
                 </Modal>
               
 
             
             <Col>
 
                 <h5 id='presidentialFeedHeadingMobile' onClick={() => setCourtAppealsTenthCircuitModalIsOpen(true)}>Court of Appeals for the Tenth Circuit</h5>
 
 
                 
 
                   <div class='courtNewsMobile' id='appealsTenthC'></div>
                       
                         
                       {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca10.xml', 'appealsTenthC', 'Court of Appeals for the Tenth Circuit', 'Judicial', 'N/A', CourtAppealsTenthCircuit)}
                        
                 </Col>
 
 
                

                 <Modal id='departmentOfStateModal' show={courtAppealsTenthCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsTenthCircuitModalIsOpen(false)}>
    <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Court of Appeals for the Tenth Circuit</h3></Modal.Header>
           <CourtAppealsTenthCircuit/>
                 </Modal>
               
      

    


           
             
             <Col>
 
                 <h5 id='presidentialFeedHeadingMobile' onClick={() => setCourtAppealsEleventhCircuitModalIsOpen(true)}>Court of Appeals for the Eleventh Circuit</h5>
 
 
                 
 
                   <div class='courtNewsMobile' id='appealsEleventhC'></div>
               
               {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-ca11.xml', 'appealsEleventhC', 'Court of Appeals for the Eleventh Circuit', 'Judicial', 'N/A', CourtAppealsEleventhCircuit)}
                
                 </Col>
 
 
                
                 <Modal id='departmentOfStateModal' show={courtAppealsEleventhCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsEleventhCircuitModalIsOpen(false)}>
    <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Court of Appeals for the Eleventh Circuit</h3></Modal.Header>
           <CourtAppealsEleventhCircuit/>
                 </Modal>
             
             <Col>
 
                 <h5 id='presidentialFeedHeadingMobile' onClick={() => setCourtAppealsDCCircuitModalIsOpen(true)}>Court of Appeals for the District of Columbia Circuit</h5>
 
 
                 
 
                   <div class='courtNewsMobile' id='appealsDC'></div>
                       
                  
                       {CreateCourtAppealsCircuit('https://www.govinfo.gov/rss/uscourts-cadc.xml', 'appealsDC', 'Court of Appeals for the District of Columbia Circuit', 'Judicial', 'N/A', CourtAppealsDCCircuit)}
                        
                 </Col>
 
 
                

                 <Modal id='departmentOfStateModal' show={courtAppealsDCCircuitModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtAppealsDCCircuitModalIsOpen(false)}>
    <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Court of Appeals for the District of Columbia Circuit</h3></Modal.Header>
           <CourtAppealsDCCircuit/>
                 </Modal>

<CourtFeedsMobile/>
               
  
             




            <Col>


            </Col>
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
 
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
</div>
</>
)
}}
export default JudicialBranchMobile;