import App from "./App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, } from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';


function selectState(){

  
 render(<Modal>hhhh</Modal>, document.getElementById('state'));
  
}

const State= () => {
  
 render(

  <div id='state'>{selectState()}</div>

  

  
   
 )


}




export default State;