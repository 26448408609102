import React, { useState } from 'react';
import { Navbar, Button, Modal, Form, Dropdown, Container, Image, Col, Row, Nav } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { HeartFill, GeoAlt, Search } from 'react-bootstrap-icons';
import {
  CreateFeedAPIRecentAll,
  CreateFeedAPIRecentCanadaHome,
  CreateFeedAPIRecentUSHome,
  CreateFeedAPIRecentMexicoHome,
  CreateFeedAPIRecentAllDesk,

} from './Functions'; // Ensure these functions are correctly imported
import GenerateFeed from './GenerateFeed';
import { BrowserView, MobileView } from 'react-device-detect';
import NavbarSearchButtonNoCountry from './NavbarSearchButtonNoCountry';
import MobileOffCanvas from './MobileOffCanvas';
import MobileOffCanvasHome from './MobileOffCanvasHome';

const HomeCountrySelectMobile = () => {
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const history = useHistory();
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null,
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content,
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null,
    });
  };

  const handleComingSoon = () => {
    openModal('This country is coming soon. Support its development by donating.', '');
  };

  const CustomModal = ({ isOpen, onClose, title, content }) => (
    <Modal id='fullBillModal' show={isOpen} size='xl' aria-labelledby='contained-modal-title-vcenter' centered onHide={onClose}>
      <Modal.Header id='billModalHeader' closeButton>
        <h3>{title}</h3>
      </Modal.Header>
      <div id='fullBillFeed'>
        {content}
        <h5 id='headingSelectHomeCountry'>
          <Button
            size='lg'
            id='donateButton'
            onClick={(e) => {
              e.preventDefault();
              window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', '_blank');
            }}
          >
            <HeartFill id='donateIcon' /> Donate
          </Button>
        </h5>
      </div>
    </Modal>
  );

  const runSearch = () => {
    if (searchQuery.trim() === '') {
      return;
    }

    const encodedQuery = encodeURIComponent(searchQuery);
    const searchPath = selectedCountry ? `/search/${selectedCountry}?query=${encodedQuery}&limit=10` : `/search/?query=${encodedQuery}&limit=10`;

    history.push(searchPath);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    runSearch();
  };

  const getFlagEmoji = (country) => {
    switch (country) {
      case 'canada':
        return '🇨🇦';
      case 'mexico':
        return '🇲🇽';
      case 'united_kingdom':
        return '🇬🇧';
      case 'united_states_of_america':
        return '🇺🇸';
      case '':
        return '🌐'; // All countries
      default:
        return '';
    }
  };



  return (
    <>
        <BrowserView>
    
    <div id='homebg'>

      <div id='recent7'></div> 
      <Navbar  >

{/*  <Nav.Link id='betaTagMobile' href=""><h6></h6> Beta</Nav.Link> */}
  <Navbar.Brand id='mobileLogoOnBoardDesk' >
    {<Image  width='200px' src='/favicon.ico'></Image>}
  </Navbar.Brand>
  
</Navbar>

<CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />



        {/* <h2 id='headingSelectHomeCountry'>
          {' '}
         Welcome to Gov Glance
        </h2> */}
        <br></br>
        <NavbarSearchButtonNoCountry/>
        <br></br>
        <div className="d-flex flex-column align-items-center">
    
            <div id='recent'></div>
            {CreateFeedAPIRecentAllDesk(
          'recent',
          'All Recents',
          'N/A',
          'N/A',
          'Doc',
          GenerateFeed
        )}
          </div>

        <br></br>
        {/* <Container className="text-center"> */}
          
          {/* <Row className="justify-content-center no-gutters">
            <Col xs={12} md={6} className="d-flex justify-content-center mb-3">
              <Button id='selectHomeCountryPage' onClick={() => window.open('/canada', "_self")}>🇨🇦 Canada</Button>
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-center mb-3">
              <Button id='selectHomeCountryPage' onClick={() => window.open('/mexico', "_self")}>🇲🇽 Mexico</Button>
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-center mb-3">
              <Button id='selectHomeCountryPage' onClick={() => window.open('/uk', "_self")}>🇬🇧 United Kingdom</Button>
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-center mb-3">
              <Button id='selectHomeCountryPage' onClick={() => window.open('/us', "_self")}>🇺🇸 United States</Button>
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-center mb-3">
              <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇦🇺 Australia</Button>
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-center mb-3">
              <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇦🇷 Argentina</Button>
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-center mb-3">
              <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇧🇷 Brazil</Button>
            </Col>
            <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇧🇷 Brazil</Button>
            <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇫🇷 France</Button
            <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇩🇪 Germany</Button>>
            <Col xs={12} md={6} className="d-flex justify-content-center mb-3">
              <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇫🇷 France</Button>
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-center mb-3">
              
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-center mb-3">
              <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇮🇳 India</Button>
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-center mb-3">
   
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-center mb-3">
                     <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇮🇳 India</Button>
              <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇮🇹 Italy</Button>
              <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇯🇵 Japan</Button>
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-center mb-3">
              <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇳🇬 Nigeria</Button>
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-center mb-3">
               <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇳🇬 Nigeria</Button>
              <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇰🇷 South Korea</Button>
              <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇿🇦 South Africa</Button
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-center mb-3">
              <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇿🇦 South Africa</Button>
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-center mb-3">
              <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇪🇸 Spain</Button>
            </Col>
          </Row> */}

<h5 style={{marginTop: "15px"}} id='headingSelectHomeCountry'><GeoAlt /> Select a country</h5>
<Col className="justify-content-center no-gutters">
<Row className="d-flex justify-content-center mb-3">
<Button id='selectHomeCountryPage' onClick={() => window.open('/canada', "_self")}>🇨🇦 Canada</Button>
<Button id='selectHomeCountryPage' onClick={() => window.open('/mexico', "_self")}>🇲🇽 Mexico</Button>
<Button id='selectHomeCountryPage' onClick={() => window.open('/uk', "_self")}>🇬🇧 United Kingdom</Button>
</Row>
<Row className="d-flex justify-content-center mb-3">
<Button id='selectHomeCountryPage' onClick={() => window.open('/us', "_self")}>🇺🇸 United States</Button>
<Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇦🇺 Australia</Button>
<Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇦🇷 Argentina</Button>
</Row>

<Row className="d-flex justify-content-center mb-3">
<Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇧🇷 Brazil</Button>
<Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇫🇷 France</Button>
<Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇩🇪 Germany</Button>
</Row>
<Row className="d-flex justify-content-center mb-3">
<Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇮🇳 India</Button>
              <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇮🇹 Italy</Button>
              <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇯🇵 Japan</Button>
              </Row>

              <Row className="d-flex justify-content-center mb-3">
              <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇳🇬 Nigeria</Button>
              <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇰🇷 South Korea</Button>
              <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇿🇦 South Africa</Button>
              </Row>

              <Row className="d-flex justify-content-center mb-3">
              <Button id='selectHomeCountryPageLock' onClick={handleComingSoon}>🇪🇸 Spain</Button>
              </Row>
          </Col>




        <br></br>
        <div style={{marginTop: "15px", textAlign: "left"}}>
       <h5 id="headingSelectHomeCountry">Gov Glance is a completely free tool designed to help citizens
        <br></br> 
        get easy access to official government news and information. It 
        <br></br>
        is our mission to cover every country, state, and city in the world.</h5>
        </div>

        <div id='mobileNavbarSpacer'></div>
        <br></br>
      <h5 id="headingSelectHomeCountry">
        Support Gov Glance
        <Button
          id="donateButton"
          onClick={(e) => {
            e.preventDefault();
            window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', '_blank');
          }}
        >
          <HeartFill id="donateIcon" /> Donate
        </Button>
      </h5>
<br></br><br></br><br></br><br></br><br></br>
<footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc. 501(c)(3) non-profit
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>
      </div>
    
      
    </BrowserView>
    <MobileView>
      <div id='homebg'>
      <Navbar style={{ width: '100%', margin: '0 auto' }}>
        <Navbar.Brand id='mobileLogoOnBoard' style={{ margin: '20px auto' }}>
          <Image width='180px' src='/favicon.ico'></Image>
        </Navbar.Brand>
      </Navbar>
      <Container id='homebg' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Form onSubmit={handleFormSubmit} style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
            <Dropdown drop='down-centered'>
              <Dropdown.Toggle id='searchCountry'>{getFlagEmoji(selectedCountry)}</Dropdown.Toggle>
              <Dropdown.Menu id='docsDropdown' drop='down-centered'>
                <Dropdown.Item id='docsDropdownItem' onClick={() => setSelectedCountry('')}>
                  🌐 All Countries
                </Dropdown.Item>
                <Dropdown.Item id='docsDropdownItem' onClick={() => setSelectedCountry('canada')}>
                  🇨🇦 Canada
                </Dropdown.Item>
                <Dropdown.Item id='docsDropdownItem' onClick={() => setSelectedCountry('mexico')}>
                  🇲🇽 Mexico
                </Dropdown.Item>
                <Dropdown.Item id='docsDropdownItem' onClick={() => setSelectedCountry('united_kingdom')}>
                  🇬🇧 United Kingdom
                </Dropdown.Item>
                <Dropdown.Item id='docsDropdownItem' onClick={() => setSelectedCountry('united_states_of_america')}>
                  🇺🇸 United States
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Form.Control
              id='navbarSearchHome'
              placeholder='Search Gov Glance'
              type='text'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              autoComplete='off'
              style={{ marginLeft: '10px', flex: '1' }}
            />
            <Button size='md' variant='primary' id='searchButton' type='submit'>
              <Search />
            </Button>
          </Form>
        </div>
        <br></br>
        <div className='courtNewsMobile' id='recent' style={{ width: '100%' }}>
          {CreateFeedAPIRecentAll(
          'recent',
          'All Recents',
          'N/A',
          'N/A',
          'Doc',
          GenerateFeed
        )}
        </div>
        <br />
        <br />
        <h5 id='headingSelectHomeCountry'>
          <GeoAlt /> Select a country
        </h5>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
          <Button id='selectHomeCountry' className='text-center' onClick={() => window.open('/canada', '_self')} style={{ width: '90%', margin: '20px auto' }}>
            🇨🇦 Canada
          </Button>
          <Button id='selectHomeCountry' className='text-center' onClick={() => window.open('/mexico', '_self')} style={{ width: '90%', margin: '20px auto' }}>
            🇲🇽 Mexico
          </Button>
          <Button id='selectHomeCountry' className='text-center' onClick={() => window.open('/uk', '_self')} style={{ width: '90%', margin: '20px auto' }}>
            🇬🇧 United Kingdom
          </Button>
          <Button id='selectHomeCountry' className='text-center' onClick={() => window.open('/us', '_self')} style={{ width: '90%', margin: '20px auto' }}>
            🇺🇸 United States
          </Button>
          <Button id='selectHomeCountryLock' className='text-center' onClick={handleComingSoon} style={{ width: '90%', margin: '20px auto' }}>
            🇦🇺 Australia
          </Button>
          <Button id='selectHomeCountryLock' className='text-center' onClick={handleComingSoon} style={{ width: '90%', margin: '20px auto' }}>
            🇦🇷 Argentina
          </Button>
          <Button id='selectHomeCountryLock' className='text-center' onClick={handleComingSoon} style={{ width: '90%', margin: '20px auto' }}>
            🇧🇷 Brazil
          </Button>
          <Button id='selectHomeCountryLock' className='text-center' onClick={handleComingSoon} style={{ width: '90%', margin: '20px auto' }}>
            🇫🇷 France
          </Button>
          <Button id='selectHomeCountryLock' className='text-center' onClick={handleComingSoon} style={{ width: '90%', margin: '20px auto' }}>
            🇩🇪 Germany
          </Button>
          <Button id='selectHomeCountryLock' className='text-center' onClick={handleComingSoon} style={{ width: '90%', margin: '20px auto' }}>
            🇮🇳 India
          </Button>
          <Button id='selectHomeCountryLock' className='text-center' onClick={handleComingSoon} style={{ width: '90%', margin: '20px auto' }}>
            🇮🇹 Italy
          </Button>
          <Button id='selectHomeCountryLock' className='text-center' onClick={handleComingSoon} style={{ width: '90%', margin: '20px auto' }}>
            🇯🇵 Japan
          </Button>
          <Button id='selectHomeCountryLock' className='text-center' onClick={handleComingSoon} style={{ width: '90%', margin: '20px auto' }}>
            🇳🇬 Nigeria
          </Button>
          <Button id='selectHomeCountryLock' className='text-center' onClick={handleComingSoon} style={{ width: '90%', margin: '20px auto' }}>
            🇰🇷 South Korea
          </Button>
          <Button id='selectHomeCountryLock' className='text-center' onClick={handleComingSoon} style={{ width: '90%', margin: '20px auto' }}>
            🇿🇦 South Africa
          </Button>
          <Button id='selectHomeCountryLock' className='text-center' onClick={handleComingSoon} style={{ width: '90%', margin: '20px auto' }}>
            🇪🇸 Spain
          </Button>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div>
          <h5 id='headingSelectHomeCountry'>
            Gov Glance is a completely free tool designed to help citizens get easy access to official government news and information. <br />
            It is our mission to cover every country, state, and city in the world.
          </h5>
        </div>
        <div id='mobileNavbarSpacer'></div>
        <h5 id='headingSelectHomeCountry'>
          Support the Gov Glance Foundation{' '}
          <Button
            id='donateButton'
            onClick={(e) => {
              e.preventDefault();
              window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', '_blank');
            }}
          >
            <HeartFill id='donateIcon' /> Donate
          </Button>
        </h5>
        <Navbar id='navbarMobileBottom' fixed='bottom' style={{ width: '100%' }}>
          <div id='changeBranchDropdownMobile'>
       <MobileOffCanvasHome/>
          </div>
        </Navbar>
        <div height='20px'></div>
        <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
        <div id='mobileNavbarSpacer'></div>
        <div id='mobileNavbarSpacer'></div>
      </Container>
      </div>
      </MobileView>
    </>
  );
};

export default HomeCountrySelectMobile;
