import React, { useState, useEffect } from 'react';
import { Spinner, Container, Row, Image, Button, Modal, Form } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import APICongressionalBillPull from "./APICongressionalBillPull";
import SponsoredBills from "./SponsoredBills";
import MobileOffCanvas from "./MobileOffCanvas";
import MobileBackButton from "./MobileBackButton";
import useHandleContainerClick from './UseHandleContainerClick';
import { Files } from 'react-bootstrap-icons';
import { BrowserView, MobileView } from 'react-device-detect';

require('dotenv').config();
const apiKey = process.env.REACT_APP_API_KEY;

function SenateDisplay(apiRequest) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [feedLength, setFeedLength] = useState(50);
  const [showModal, setShowModal] = useState(false);
  const [bioGuideId, setBioGuideId] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const handleContainerClick = useHandleContainerClick();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
    
        const response = await fetch(`https://api.govglance.org/members/?limit=10&skip=0`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + apiKey,
          },
        });
    
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
    
        const membersData = await response.json();
    
        // Filter out members who are not in the House of Representatives
        const houseMembersData = membersData.filter(member => {
          const latestTerm = member.terms[member.terms.length - 1];
          return latestTerm.chamber === "Senate";
        });
  
        // Take only the first 3 members
        const firstThreeHouseMembers = houseMembersData.slice(0, 1);
    
        setData(firstThreeHouseMembers);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
        setLoading(false);
      }
    };
    
    fetchData();
  }, []);

  const renderData = () => {
    if (loading) {
      return <Spinner animation="grow" variant="light" />;
    }

    if (error) {
      return <p>Error: {error.message}</p>;
    }

    return data.map((item, index) => {
      const titleToDisplay = item.first_name + ' ' + item.last_name;
      const imageSource = item.depication?.imageUrl;
      const latestTerm = item.terms[item.terms.length - 1];
      const stateName = latestTerm.stateName;
      const chamber = latestTerm.chamber;
      const startYear = latestTerm.startYear;

      return (
        <div id='feedDisplayCaro' key={index}>
          
        <Container  id='repContainer'>
        <div onClick={() => handleContainerClick(item.bio_guide_id,item.first_name + ' ' + item.last_name, 
item.official_website_url, item.depication?.imageUrl, item.terms[item.terms.length - 1].chamber, 
item.terms[item.terms.length - 1].startYear, item.terms[item.terms.length - 1].stateName, item.party_history, item.address_information )}>
<div style={{display: 'flex',
              flexDirection: 'row'}}>
  {imageSource && (
    <div id='profileImage'>
    <Image id='profileImage-image' src={imageSource} />
  </div>
  )}

  <div style={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
  <div style={{color: 'white', fontSize: '18px',
                fontWeight: 'bold'}}>
<span>{titleToDisplay}</span>
</div>
<div  style={{color: 'white', fontSize: '16px'}}> 
<span> {stateName}  {chamber} - {startYear}</span>
    </div>
    </div>


    </div>
     
          </div>
           <Button id='whDocsViewMoreButton' onClick={() => window.open('/us/senators', "_self")} ><Files size={20}></Files> View More</Button>
        </Container>
        </div>
      );
    });
  };

  const renderDataDesk = () => {
    if (loading) {
      return <Spinner animation="grow" variant="light" />;
    }

    if (error) {
      return <p>Error: {error.message}</p>;
    }

    return data.map((item, index) => {
      const titleToDisplay = item.first_name + ' ' + item.last_name;
      const imageSource = item.depication?.imageUrl;
      const latestTerm = item.terms[item.terms.length - 1];
      const stateName = latestTerm.stateName;
      const chamber = latestTerm.chamber;
      const startYear = latestTerm.startYear;

      return (
        <div id='feedDisplayCaro' key={index}>
          
        <Container  id='repContainerDesktop'>
        <div onClick={() => handleContainerClick(item.bio_guide_id,item.first_name + ' ' + item.last_name, 
item.official_website_url, item.depication?.imageUrl, item.terms[item.terms.length - 1].chamber, 
item.terms[item.terms.length - 1].startYear, item.terms[item.terms.length - 1].stateName, item.party_history, item.address_information )}>
<div style={{display: 'flex',
              flexDirection: 'row'}}>
  {imageSource && (
    <div id='profileImage'>
    <Image id='profileImage-image' src={imageSource} />
  </div>
  )}

  <div style={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
  <div style={{color: 'white', fontSize: '18px',
                fontWeight: 'bold'}}>
<span>{titleToDisplay}</span>
</div>
<div  style={{color: 'white', fontSize: '16px'}}> 
<span> {stateName}  {chamber} - {startYear}</span>
    </div>
    </div>


    </div>
     
          </div>
           <Button id='whDocsViewMoreButton' onClick={() => window.open('/us/senators', "_self")} ><Files size={20}></Files> View More</Button>
        </Container>
        </div>
      );
    });
  };

  return (
    <>
    <BrowserView>
      <div id='feedDisplayCaro'>
        {renderDataDesk()}
      </div>
      </BrowserView>
      <MobileView>

      <div id='feedDisplayCaro'>
        {renderData()}
      </div>

      </MobileView>
    </>
  );
}

export default SenateDisplay;