import App from "../../../App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, Coin, PauseCircle, PlayCircle, FolderPlus, ChevronRight, Files, Folder, Pen, ChatLeftQuote, ShieldShaded, People, HeartFill, Bricks, Lightbulb } from 'react-bootstrap-icons'
import '../../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import UKMobileTopNavbar from "../../UKMobileTopNavbar";
import MobileOffCanvas from "../../../MobileOffCanvas";
import { CreateFeedItemUKAtom } from "../../../Functions";
import MinistryOfJustice from "../MinistryOfJustice";


var collectionCounter = 0  
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;



export const UKJusticeMobile = () =>   {
  const [modal1, setModal1IsOpen] = useState(false);
const [modal2, setModal2IsOpen] = useState(false);
const [modal3, setModal3IsOpen] = useState(false);
const [modal4, setModal4IsOpen] = useState(false);
const [modal5, setModal5IsOpen] = useState(false);
const [modal6, setModal6IsOpen] = useState(false);
const [modal7, setModal7IsOpen] = useState(false);
const [modal8, setModal8IsOpen] = useState(false);
const [modal9, setModal9IsOpen] = useState(false);
const [modal10, setModal10IsOpen] = useState(false);
const [modal11, setModal11IsOpen] = useState(false);
const [modal12, setModal12IsOpen] = useState(false);
const [modal13, setModal13IsOpen] = useState(false);
const [modal14, setModal14IsOpen] = useState(false);
const [modal15, setModal15IsOpen] = useState(false);
const [modal16, setModal16IsOpen] = useState(false);
const [modal17, setModal17IsOpen] = useState(false);
const [modal18, setModal18IsOpen] = useState(false);
const [modal19, setModal19IsOpen] = useState(false);
const [modal20, setModal20IsOpen] = useState(false);
const [modal23, setModal23IsOpen] = useState(false);
    render()

    {
  
          const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
      
        localStorage.setItem('currentPage', 'uk')
    return(
       <>
    <UKMobileTopNavbar/>
  
  
  
  
  <div id='homebg'>

<h5 id='branchHeadingMobile'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-arms-up" viewBox="0 0 16 16">
  <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
  <path d="m5.93 6.704-.846 8.451a.768.768 0 0 0 1.523.203l.81-4.865a.59.59 0 0 1 1.165 0l.81 4.865a.768.768 0 0 0 1.523-.203l-.845-8.451A1.5 1.5 0 0 1 10.5 5.5L13 2.284a.796.796 0 0 0-1.239-.998L9.634 3.84a.7.7 0 0 1-.33.235c-.23.074-.665.176-1.304.176-.64 0-1.074-.102-1.305-.176a.7.7 0 0 1-.329-.235L4.239 1.286a.796.796 0 0 0-1.24.998l2.5 3.216c.317.316.475.758.43 1.204Z"/>
</svg> Justice</h5>
{/*onClick={() => setModalIsOpen(true)}*/}

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setModal16IsOpen(true)}>Ministry of Justice</h5>

  <div id='deptOfJustice'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/ministry-of-justice.atom',
     'deptOfJustice', 'Ministry of Justice', 'Executive', 'N/A', MinistryOfJustice)}

  <Modal id='mobileFullBillModal' show={modal16} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal16IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Ministry of Justice</h3></Modal.Header>
<MinistryOfJustice/>
</Modal>

    
</Col>
<Col>

<br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>

<br></br><br></br><br></br><br></br><br></br><br></br>

<Navbar id='navbarMobileBottom' fixed="bottom">  



       
<div id='changeBranchDropdownMobile'>

                  <MobileOffCanvas/>

              
            </div>

</Navbar>
 
             
 
 
 
 
 
 

</div>



</>
)
}}
export default UKJusticeMobile;


