
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, PauseCircle, PlayCircle, People, HeartFill, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnIntelligence from './HouseCommitteeOnIntelligence'
import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import ExecutiveOrders from './ExecutiveOrders';
import PresidentialProclamations from './PresidentialProclamations';
import NationalScienceFoundation from './NationalScienceFoundation';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
  createWhiteHouseFeedMobile,createDepartmentOfState,
  createDepartmentOfDefense, 
  createDepartmentOfJustice, createDepartmentOfTheInterior,
  createDepartmentOfAgriculture, createDepartmentOfCommerce,
  createDepartmentOfCommerceMobile, createDepartmentOfLabor,
  createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
  ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
  , createEnvironmentalProtectionAgency,
  createDepartmentOfTreasury, createNationalScienceFoundation, 
   createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, createFederalRegisterStateDepartment, createFederalRegisterTreasuryDepartment, createFederalRegisterJusticeDepartment, createFederalRegisterInteriorDepartment, createFederalRegisterAgricultureDepartment, createFederalRegisterCommerceDepartment, createFederalRegisterLaborDepartment, createFederalRegisterHealthAndHumanServicesDepartment, createFederalRegisterHousingAndUrbanDevelopmentDepartment, createFederalRegisterHomelandSecurityDepartment, createFederalRegisterEducationDepartment, createFederalRegisterVeteransAffairsDepartment,
   CreateFeedItem, CreateFeedItemBillUpdates, CreateFeedItemWithDocButton,
   CreateFeedItemPresidentialDocuments, CreateFeedItemExecutiveOrders, CreateFeedItemCongressionalReports, CreateFeedItemBuild, CreateFeedAPICommittee, CreateFeedAPI } from './Functions'
import ExecutiveBranchMobile from './ExecutiveBranchMobile';
import DesktopNavbar from './DesktopNavbar';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import FederalRegisterJusticeDepartment from './FederalRegisterJusticeDepartment';
import FederalRegisterInteriorDepartment from './FederalRegisterInteriorDepartment';
import FederalRegisterAgricultureDepartment from './FederalRegisterAgricultureDepartment';
import FederalRegisterCommerceDepartment from './FederalRegisterCommerceDepartment';
import FederalRegisterLaborDepartment from './FederalRegisterLaborDepartment';
import FederalRegisterHealthAndHumanServicesDepartment from './FederalRegisterHealthAndHumanServicesDepartment';
import FederalRegisterHousingAndUrbanDevelopmentDepartment from './FederalRegisterHousingAndUrbanDevelopmentDepartment';
import FederalRegisterVeteransAffairsDepartment from './FederalRegisterVeteransAffairsDepartment';
import FederalRegisterHomelandSecurityDepartment from './FederalRegisterHomelandSecurityDepartment';
import FederalRegisterEducationDepartment from './FederalRegisterEducationDepartment';
import CouncilOfEconomicAdvisers from './CouncilOfEconomicAdvisers';
import CouncilOnEnvironmentQuality from './CouncilOnEnvironmentQuality';
import DomesticPolicyCouncil from './DomesticPolicyCouncil';
import GenderPolicyCouncil from './GenderPolicyCouncil';
import NationalEconomicCouncil from './NationalEconomicCouncil';
import NationalSecurityCouncil from './NationalSecurityCouncil';
import OfficeOfIntergovernmentalAffairs from './OfficeOfIntergovernmentalAffairs';
import OfficeOfManagmentAndBudget from './OfficeOfManagmentAndBudget';
import OfficeOfNationalDrugControlPolicy from './OfficeOfNationalDrugControlPolicy';
import OfficeOfPublicEngagement from './OfficeOfPublicEngagement';
import OfficeOfScienceAndTechnologyPolicy from './OfficeOfScienceAndTechnologyPolicy';
import OfficeOfNationalCyberDirector from './OfficeOfNationalCyberDirector';
import PresidentialPersonnelOffice from './PresidentialPersonnelOffice';
import ExecutiveOfficeMobile from './ExecutiveOfficeMobile';
import HouseMobile from './HouseMobile';
import HouseCommitteeOnArmedServices from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnEducationAndLabor from './HouseCommitteeOnEducationAndLabor';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEthics from './HouseCommitteeOnEthics';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import HouseCommitteeOnForeignAffairs from './HouseCommitteeOnForeignAffairs';
import HouseCommitteeOnHomelandSecurity from './HouseCommitteeOnHomelandSecurity';
import HouseCommitteeOnHouseAdministration from './HouseCommitteeOnHouseAdministration';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import HouseCommitteeOnOversightAndReform from './HouseCommitteeOnOversightAndReform';
import HouseCommitteeOnRules from './HouseCommitteeOnRules';
import HouseCommitteeOnScienceSpaceAndTechnology from './HouseCommitteeOnScienceSpaceAndTechnology';
import HouseCommitteeOnSmallBusiness from './HouseCommitteeOnSmallBusiness';
import HouseCommitteeOnTransportationAndInfrastructure from './HouseCommitteeOnTransportationAndInfrastructure';
import HouseCommitteeOnVeteransAffairs from './HouseCommitteeOnVeteransAffairs';
import HouseCommitteeOnTheBudget from './HouseCommitteeOnTheBudget';
import HouseCommitteeOnTheJudiciary from './HouseCommitteeOnTheJudiciary';
import BuildMaterialsFeed from './BuildMaterialsFeed';
import HouseComitteeOnAgricultureMaterials from './HouseComitteeOnAgricultureMaterials';
import HouseCommitteeOnAppropriationsMaterials from './HouseCommitteeOnAppropriationsMaterials';
import HouseCommitteeOnArmedServicesMaterials from './HouseCommitteeOnArmedServicesMaterials';
import HouseCommitteeOnEducationAndLaborMaterials from './HouseCommitteeOnEducationAndLaborMaterials';
import HouseCommitteeOnEnergyAndCommerceMaterials from './HouseCommitteeOnEnergyAndCommerceMaterials';
import HouseCommitteeOnEthicsMaterials from './HouseCommitteeOnEthicsMaterials';
import HouseCommitteeOnFinacialServicesMaterials from './HouseCommitteeOnFinacialServicesMaterials';
import HouseCommitteeOnForeignAffairsMaterials from './HouseCommitteeOnForeignAffairsMaterials';
import HouseCommitteeOnHomelandSecurityMaterials from './HouseCommitteeOnHomelandSecurityMaterials';
import HouseCommitteeOnHouseAdministrationMaterials from './HouseCommitteeOnHouseAdministrationMaterials';
import HouseCommitteeOnOversightAndReformMaterials from './HouseCommitteeOnOversightAndReformMaterials';
import HouseCommitteeOnRulesMaterials from './HouseCommitteeOnRulesMaterials';
import HouseCommitteeOnScienceSpaceAndTechnologyMaterials from './HouseCommitteeOnScienceSpaceAndTechnologyMaterials';
import HouseCommitteeOnSmallBusinessMaterials from './HouseCommitteeOnSmallBusinessMaterials';
import HouseCommitteeOnVeteransAffairsMaterials from './HouseCommitteeOnVeteransAffairsMaterials';
import HouseCommitteeOnWaysAndMeans from './HouseCommitteeOnWaysAndMeans';
import HouseCommitteeOnWaysAndMeansMaterials from './HouseCommitteeOnWaysAndMeansMaterials';
import HouseCommitteeOnTheBudgetMaterials from './HouseCommitteeOnTheBudgetMaterials';
import HouseCommitteeOnTheJudiciaryMaterials from './HouseCommitteeOnTheJudiciaryMaterials';
import HouseCommitteeOnIntelligenceMaterials from './HouseCommitteeOnIntelligenceMaterials';
import HouseCommitteeToInvestigateTheCapitol from './HouseCommitteeToInvestigateTheCapitol';
import HouseCommitteeToInvestigateTheCapitolMaterials from './HouseCommitteeToInvestigateTheCapitolMaterials';
import HouseFeed from './HouseFeed';
import HouseFloor from './HouseFloor';
import HouseCommitteeOnInterstateAndForeignCommerceMaterials from './HouseCommitteeOnInterstateAndForeignCommerceMaterials';
import HouseCommitteeOnTransportationAndInfrastructureMaterials from './HouseCommitteeOnTransportationAndInfrastructureMaterials';
import HouseOfRepsDisplay from './HouseOfRepsDisplay';
import HouseOfRepsDisplayOnPage from './HouseOfRepsDisplayOnPage';
import GenerateFeed from './GenerateFeed';
import GenerateFeedCommittee from './GenerateFeedCommittee';

const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);



var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;





  

 


  


export const HousePage = () =>
 {
   render()
   
   {
  const [show, setShow] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);


  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)

  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)

  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)


  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
  const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
  const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)

  const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
  const [federalRegisterTreasuryDepartmentModalIsOpen, setFederalRegisterTreasuryDepartmentModalIsOpen] = useState(false)
  const [federalRegisterJusticeDepartmentModalIsOpen, setFederalRegisterJusticeDepartmentModalIsOpen] = useState(false)
  const [federalRegisterInteriorDepartmentModalIsOpen, setFederalRegisterInteriorDepartmentModalIsOpen] = useState(false)
  const [federalRegisterAgricultureDepartmentModalIsOpen, setFederalRegisterAgricultureDepartmentModalIsOpen] = useState(false)
  const [federalRegisterVeteransAffairsDepartmentModalIsOpen, setFederalRegisterVeteransAffairsDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHomelandSecurityDepartmentModalIsOpen, setFederalRegisterHomelandSecurityDepartmentModalIsOpen] = useState(false)
  const [federalRegisterCommerceDepartmentModalIsOpen, setFederalRegisterCommerceDepartmentModalIsOpen] = useState(false)
  const [federalRegisterLaborDepartmentModalIsOpen, setFederalRegisterLaborDepartmentModalIsOpen] = useState(false)
  const [federalRegisterEducationDepartmentModalIsOpen, setFederalRegisterEducationDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHealthAndHumanServicesDepartmentModalIsOpen, setFederalRegisterHealthAndHumanServicesDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHousingAndUrbanDevelopmentDepartmentModalIsOpen, setFederalRegisterHousingAndUrbanDevelopmentDepartmentModalIsOpen] = useState(false)
  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)



  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };

  return(
    <>
      <BrowserView>
        <div id='homePage'>

        <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />


        <div id='homebg'>

          <DesktopNavbar/>
              <div id='sectionHeading'>
            
            <h3 id='branchHeading'   >House of Representatives </h3>
            
            </div>

            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='feedHeadingBillsEnrolled'><b>Representatives</b></h4>
                          
                       
                          <HouseOfRepsDisplayOnPage/>
                        
                        
                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>

                    
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

            {/* <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='feedHeadingBillsEnrolled'><b>Recent Materials</b></h4>
                          <div id='fullHouseFeed'></div>
                       
                     
                        
                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>

                        <h4 id='billFeedHeading'><b>House Floor Activity Today</b></h4>
                  <div class='billsDesktop' id='houseFloor'></div>
                      
                  
  
                    
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div> */}
                     
           
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                        <h4 id='feedHeadingBillsEnrolled'  onClick={() =>
                            openModal('Committee on Agriculture',
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_agriculture_meeting_feed&order_by=created_at' 
                            name='Committee on Agriculture' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Committee on Agriculture</b></h4>
                          <div id='houseComitteeOnAgriculture2'></div>
                       
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_agriculture_meeting_feed&order_by=created_at', 
      'houseComitteeOnAgriculture2', 
                            'Committee on Agriculture', 'Legislative', 
                            'N/A', 'Bill', GenerateFeed)}

                  
                        </div>
                      </Col>

               

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() =>
                            openModal('Committee on Agriculture Materials',
                              <GenerateFeedCommittee  url='&skip=0&committee=Committee%20on%20Agriculture&chamber=HOUSE'
                              name='Committee on Agriculture Materials' branch='Legislative' topic='N/A' buttonName='Bill'/>)}><b>Committee on Agriculture Materials</b></h4>
                          <div id='houseComitteeOnAgricultueMaterials'></div>
                          
                          {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20Agriculture&chamber=HOUSE', 
      'houseComitteeOnAgricultueMaterials', 
                            'Committee on Agriculture Materials', 'Legislative', 
                            'N/A', 'Bill', GenerateFeedCommittee)}
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Committee on Appropriations',
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_appropriations_meeting_feed&order_by=created_at' 
                            name='Committee on Appropriations' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Committee on Appropriations</b></h4>

<div id='houseCommitteeOnAppropriations'></div>


{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_appropriations_meeting_feed&order_by=created_at', 
      'houseCommitteeOnAppropriations', 
                            'Committee on Appropriations', 'Legislative', 
                            'N/A', 'Bill', GenerateFeed)}
                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() =>
                            openModal('Committee on Appropriations Materials',
                              <GenerateFeedCommittee  url='&skip=0&committee=Committee%20on%20Appropriations&chamber=HOUSE'
                              name='Committee on Appropriations Materials' branch='Legislative' topic='N/A' buttonName='Bill'/>)}><b>Committee on Appropriations Materials</b></h4>
                          <div id='houseCommitteeOnAppropriationsMaterials'></div>
                          
                          {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20Appropriations&chamber=HOUSE', 
      'houseCommitteeOnAppropriationsMaterials', 
                            'Committee on Appropriations Materials', 'Legislative', 
                            'N/A', 'Bill', GenerateFeedCommittee)}
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Committee on Appropriations',
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_armed_services_meeting_feed&order_by=created_at' 
                            name='Committee on Armed Services' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Committee on Armed Services</b></h4>
                        <div id='houseComitteeOnArmedServices'></div>
                  
                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_armed_services_meeting_feed&order_by=created_at', 
      'houseComitteeOnArmedServices', 
                            'Committee on Armed Services', 'Legislative', 
                            'N/A', 'Bill', GenerateFeed)}
                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='presidentialFeedHeading' onClick={() =>
                            openModal('Committee on Armed Services Materials',
                              <GenerateFeedCommittee  url='&skip=0&committee=Committee%20on%20Armed%20Services&chamber=HOUSE'
                              name='Committee on Armed Services Materials' branch='Legislative' topic='N/A' buttonName='Bill'/>)}><b>Committee on Armed Services Materials</b></h4>
                          <div id='houseCommitteeOnArmedServicesMaterials'></div>
                          
                          {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20Armed%20Services&chamber=HOUSE', 
      'houseCommitteeOnArmedServicesMaterials', 
                            'Committee on Armed Services Materials', 'Legislative', 
                            'N/A', 'Bill', GenerateFeedCommittee)}
     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='feedHeadingBillsEnrolled'  onClick={() =>
                            openModal('Committee on Education and Labor',
                            <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_education_and_the_workforce_meeting_feed&order_by=created_at' 
                            name='Committee on Education and Labor' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Committee on Education and Labor</b></h4>
                        <div id='houseCommitteeOnEducationAndLabor'></div>
                        
                        {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_education_and_the_workforce_meeting_feed&order_by=created_at', 
      'houseCommitteeOnEducationAndLabor', 
                            'Committee on Education and Labor', 'Legislative', 
                            'N/A', 'Bill', GenerateFeed)}
                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() =>
                            openModal('Committee on Education and Labor Materials',
                              <GenerateFeedCommittee  url='&skip=0&committee=Committee%20on%20Education%20and%20the%20Workforce&chamber=HOUSE'
                              name='Committee on Education and Labor Materials' branch='Legislative' topic='N/A' buttonName='Bill'/>)}><b> Committee on Education and Labor Materials</b></h4>
                          <div id='houseCommitteeOnEducationAndLaborMaterials'></div>
                          
                          {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20Education%20and%20the%20Workforce&chamber=HOUSE', 
      'houseCommitteeOnEducationAndLaborMaterials', 
                            'Committee on Education and Labor Materials', 'Legislative', 
                            'N/A', 'Bill', GenerateFeedCommittee)}
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Energy and Commerce',
              <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_energy_and_commerce_meeting_feed&order_by=created_at' 
              name='Committee on Energy and Commerce' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Committee on Energy and Commerce</b></h4>
            <div id='houseCommitteeOnEnergyAndCommerce2'></div>
            
            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_energy_and_commerce_meeting_feed&order_by=created_at', 
            'houseCommitteeOnEnergyAndCommerce2', 
            'Committee on Energy and Commerce', 'Legislative', 
            'N/A', 'Bill', GenerateFeed)}
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Energy and Commerce Materials',
                <GenerateFeedCommittee url='&skip=0&committee=Committee%20on%20Energy%20and%20Commerce&chamber=HOUSE'
                name='Committee on Energy and Commerce Materials' branch='Legislative' topic='N/A' buttonName='Bill'/>)}><b>Committee on Energy and Commerce Materials</b></h4>
            <div id='houseCommitteeOnEnergyAndCommerceMaterials'></div>
            
            {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20Energy%20and%20Commerce&chamber=HOUSE', 
            'houseCommitteeOnEnergyAndCommerceMaterials', 
            'Committee on Energy and Commerce Materials', 'Legislative', 
            'N/A', 'Bill', GenerateFeedCommittee)}
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Ethics',
              <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_ethics_meeting_feed&order_by=created_at' 
              name='Committee on Ethics' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Committee on Ethics</b></h4>
            <div id='houseCommitteeOnEthics'></div>

            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_ethics_meeting_feed&order_by=created_at', 
            'houseCommitteeOnEthics', 
            'Committee on Ethics', 'Legislative', 
            'N/A', 'Bill', GenerateFeed)}
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Ethics Materials',
                <GenerateFeedCommittee url='&skip=0&committee=Committee%20on%20Ethics&chamber=HOUSE'
                name='Committee on Ethics Materials' branch='Legislative' topic='N/A' buttonName='Bill'/>)}><b>Committee on Ethics Materials</b></h4>
            <div id='houseCommitteeOnEthicsMaterials'></div>

            {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20Ethics&chamber=HOUSE', 
            'houseCommitteeOnEthicsMaterials', 
            'Committee on Ethics Materials', 'Legislative', 
            'N/A', 'Bill', GenerateFeedCommittee)}
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Financial Services',
              <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_financial_services_meeting_feed&order_by=created_at' 
              name='Committee on Financial Services' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Committee on Financial Services</b></h4>
            <div id='houseCommitteeOnFinancialServices'></div>
            
            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_financial_services_meeting_feed&order_by=created_at', 
            'houseCommitteeOnFinancialServices', 
            'Committee on Financial Services', 'Legislative', 
            'N/A', 'Bill', GenerateFeed)}
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Financial Services Materials',
                <GenerateFeedCommittee url='&skip=0&committee=Committee%20on%20Financial%20Services&chamber=HOUSE'
                name='Committee on Financial Services Materials' branch='Legislative' topic='N/A' buttonName='Bill'/>)}><b>Committee on Financial Services Materials</b></h4>
            <div id='houseCommitteeOnFinancialServicesMaterials'></div>

            {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20Financial%20Services&chamber=HOUSE', 
            'houseCommitteeOnFinancialServicesMaterials', 
            'Committee on Financial Services Materials', 'Legislative', 
            'N/A', 'Bill', GenerateFeedCommittee)}
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Foreign Affairs',
              <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_foreign_affairs_meeting_feed&order_by=created_at' 
              name='Committee on Foreign Affairs' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Committee on Foreign Affairs</b></h4>
            <div id='houseCommitteeOnForeignAffairs'></div>

            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_foreign_affairs_meeting_feed&order_by=created_at', 
            'houseCommitteeOnForeignAffairs', 
            'Committee on Foreign Affairs', 'Legislative', 
            'N/A', 'Bill', GenerateFeed)}
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Foreign Affairs Materials',
                <GenerateFeedCommittee url='&skip=0&committee=Committee%20on%20Foreign%20Affairs&chamber=HOUSE'
                name='Committee on Foreign Affairs Materials' branch='Legislative' topic='Foreign Affairs' buttonName='Bills'/>)}><b>Committee on Foreign Affairs Materials</b></h4>
            <div id='houseCommitteeOnForeignAffairsMaterials'></div>

            {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20Foreign%20Affairs&chamber=HOUSE', 
            'houseCommitteeOnForeignAffairsMaterials', 
            'Committee on Foreign Affairs Materials', 'Legislative', 
            'Foreign Affairs', 'Bills', GenerateFeedCommittee)}
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>





<div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Homeland Security',
              <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_homeland_security_meeting_feed&order_by=created_at' 
              name='Committee on Homeland Security' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Committee on Homeland Security</b></h4>
            <div id='houseCommitteeOnHomelandSecurity'></div>

            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_homeland_security_meeting_feed&order_by=created_at', 
            'houseCommitteeOnHomelandSecurity', 
            'Committee on Homeland Security', 'Legislative', 
            'N/A', 'Bill', GenerateFeed)}
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Homeland Security Materials',
                <GenerateFeedCommittee url='&skip=0&committee=Committee%20on%20Homeland%20Security&chamber=HOUSE'
                name='Committee on Homeland Security Materials' branch='Legislative' topic='N/A' buttonName='Bills'/>)}><b>Committee on Homeland Security Materials</b></h4>
            <div id='houseCommitteeOnHomelandSecurityMaterials'></div>

            {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20Homeland%20Security&chamber=HOUSE', 
            'houseCommitteeOnHomelandSecurityMaterials', 
            'Committee on Homeland Security Materials', 'Legislative', 
            'N/A', 'Bills', GenerateFeedCommittee)}
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on House Administration',
              <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_house_administration_meeting_feed&order_by=created_at' 
              name='Committee on House Administration' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Committee on House Administration</b></h4>
            <div id='houseCommitteeOnHouseAdministration'></div>

            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_house_administration_meeting_feed&order_by=created_at', 
            'houseCommitteeOnHouseAdministration', 
            'Committee on House Administration', 'Legislative', 
            'N/A', 'Bill', GenerateFeed)}
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on House Administration Materials',
                <GenerateFeedCommittee url='&skip=0&committee=Committee%20on%20House%20Administration&chamber=HOUSE'
                name='Committee on House Administration Materials' branch='Legislative' topic='N/A' buttonName='Bills'/>)}><b>Committee on House Administration Materials</b></h4>
            <div id='houseCommitteeOnHouseAdministrationMaterials'></div>

            {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20House%20Administration&chamber=HOUSE', 
            'houseCommitteeOnHouseAdministrationMaterials', 
            'Committee on House Administration Materials', 'Legislative', 
            'N/A', 'Bills', GenerateFeedCommittee)}
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Natural Resources',
              <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_natural_resources_meeting_feed&order_by=created_at' 
              name='Committee on Natural Resources' branch='Legislative' topic='Environment' buttonName='Bill' />)}><b>Committee on Natural Resources</b></h4>
            <div id='houseCommitteeOnNaturalResources'></div>

            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_natural_resources_meeting_feed&order_by=created_at', 
            'houseCommitteeOnNaturalResources', 
            'Committee on Natural Resources', 'Legislative', 
            'Environment', 'Bill', GenerateFeed)}
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Natural Resources Materials',
                <GenerateFeedCommittee url='&skip=0&committee=Committee%20on%20Natural%20Resources&chamber=HOUSE'
                name='Committee on Natural Resources Materials' branch='Legislative' topic='Environment' buttonName='Bills'/>)}><b>Committee on Natural Resources Materials</b></h4>
            <div id='houseCommitteeOnNaturalResourcesMaterials'></div>

            {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20Natural%20Resources&chamber=HOUSE', 
            'houseCommitteeOnNaturalResourcesMaterials', 
            'Committee on Natural Resources Materials', 'Legislative', 
            'Environment', 'Bills', GenerateFeedCommittee)}
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Oversight and Reform',
              <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_oversight_and_accountability_meeting_feed&order_by=created_at' 
              name='Committee on Oversight and Reform' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Committee on Oversight and Reform</b></h4>
            <div id='houseCommitteeOnOversightAndReform'></div>

            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_oversight_and_accountability_meeting_feed&order_by=created_at', 
            'houseCommitteeOnOversightAndReform', 
            'Committee on Oversight and Reform', 'Legislative', 
            'N/A', 'Bill', GenerateFeed)}
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Oversight and Reform Materials',
                <GenerateFeedCommittee url='&skip=0&committee=Committee%20on%20Oversight%20and%20Accountability&chamber=HOUSE'
                name='Committee on Oversight and Reform Materials' branch='Legislative' topic='N/A' buttonName='Bills'/>)}><b>Committee on Oversight and Reform Materials</b></h4>
            <div id='houseCommitteeOnOversightAndReformMaterials'></div>

            {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20Oversight%20and%20Accountability&chamber=HOUSE', 
            'houseCommitteeOnOversightAndReformMaterials', 
            'Committee on Oversight and Reform Materials', 'Legislative', 
            'N/A', 'Bills', GenerateFeedCommittee)}
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Rules',
              <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_rules_meeting_feed&order_by=created_at' 
              name='Committee on Rules' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Committee on Rules</b></h4>
            <div id='houseCommitteeOnRules'></div>

            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_rules_meeting_feed&order_by=created_at', 
            'houseCommitteeOnRules', 
            'Committee on Rules', 'Legislative', 
            'N/A', 'Bill', GenerateFeed)}
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Rules Materials',
                <GenerateFeedCommittee url='&skip=0&committee=Committee%20on%20Rules&chamber=HOUSE'
                name='Committee on Rules Materials' branch='Legislative' topic='N/A' buttonName='Bills'/>)}><b>Committee on Rules Materials</b></h4>
            <div id='houseCommitteeOnRulesMaterials'></div>

            {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20Rules&chamber=HOUSE', 
            'houseCommitteeOnRulesMaterials', 
            'Committee on Rules Materials', 'Legislative', 
            'N/A', 'Bills', GenerateFeedCommittee)}
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Science, Space, and Technology',
              <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_science_space_and_technology_meeting_feed&order_by=created_at' 
              name='Committee on Science, Space, and Technology' branch='Legislative' topic='Science & Tech' buttonName='Bill' />)}><b>Committee on Science, Space, and Technology</b></h4>
            <div id='houseCommitteeOnScienceSpaceAndTechnology'></div>

            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_science_space_and_technology_meeting_feed&order_by=created_at', 
            'houseCommitteeOnScienceSpaceAndTechnology', 
            'Committee on Science, Space, and Technology', 'Legislative', 
            'Science & Tech', 'Bill', GenerateFeed)}
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Science, Space, and Technology Materials',
                <GenerateFeedCommittee url='&skip=0&committee=Committee%20on%20Science,%20Space,%20and%20Technology&chamber=HOUSE'
                name='Committee on Science, Space, and Technology Materials' branch='Legislative' topic='Science & Tech' buttonName='Bills'/>)}><b>Committee on Science, Space, and Technology Materials</b></h4>
            <div id='houseCommitteeOnScienceSpaceAndTechnologyMaterials'></div>

            {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20Science,%20Space,%20and%20Technology&chamber=HOUSE', 
            'houseCommitteeOnScienceSpaceAndTechnologyMaterials', 
            'Committee on Science, Space, and Technology Materials', 'Legislative', 
            'Science & Tech', 'Bills', GenerateFeedCommittee)}
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Small Business',
              <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_small_business_meeting_feed&order_by=created_at' 
              name='Committee on Small Business' branch='Legislative' topic='Economy' buttonName='Bill' />)}><b>Committee on Small Business</b></h4>
            <div id='houseCommitteeOnSmallBusiness'></div>

            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_small_business_meeting_feed&order_by=created_at', 
            'houseCommitteeOnSmallBusiness', 
            'Committee on Small Business', 'Legislative', 
            'Economy', 'Bill', GenerateFeed)}
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Small Business Materials',
                <GenerateFeedCommittee url='&skip=0&committee=Committee%20on%20Small%20Business&chamber=HOUSE'
                name='Committee on Small Business Materials' branch='Legislative' topic='Economy' buttonName='Bills'/>)}><b>Committee on Small Business Materials</b></h4>
            <div id='houseCommitteeOnSmallBusinessMaterials'></div>

            {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20Small%20Business&chamber=HOUSE', 
            'houseCommitteeOnSmallBusinessMaterials', 
            'Committee on Small Business Materials', 'Legislative', 
            'Economy', 'Bills', GenerateFeedCommittee)}
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Transportation and Infrastructure',
              <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_transportation_and_infrastructure_meeting_feed&order_by=created_at' 
              name='Committee on Transportation and Infrastructure' branch='Legislative' topic='Environment' buttonName='Bill' />)}><b>Committee on Transportation and Infrastructure</b></h4>
            <div id='houseCommitteeOnTransportationAndInfrastructure'></div>

            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_transportation_and_infrastructure_meeting_feed&order_by=created_at', 
            'houseCommitteeOnTransportationAndInfrastructure', 
            'Committee on Transportation and Infrastructure', 'Legislative', 
            'Environment', 'Bill', GenerateFeed)}
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Transportation and Infrastructure Materials',
                <GenerateFeedCommittee url='&skip=0&committee=Committee%20on%20Transportation%20and%20Infrastructure&chamber=HOUSE'
                name='Committee on Transportation and Infrastructure Materials' branch='Legislative' topic='Environment' buttonName='Bills'/>)}><b>Committee on Transportation and Infrastructure Materials</b></h4>
            <div id='houseCommitteeOnTransportationAndInfrastructureMaterials'></div>

            {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20Transportation%20and%20Infrastructure&chamber=HOUSE', 
            'houseCommitteeOnTransportationAndInfrastructureMaterials', 
            'Committee on Transportation and Infrastructure Materials', 'Legislative', 
            'Environment', 'Bills', GenerateFeedCommittee)}
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

{/* <div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal("Committee on Veterans' Affairs",
              <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_veterans_affairs_meeting_feed&order_by=created_at' 
              name="Committee on Veterans' Affairs" branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Committee on Veterans' Affairs</b></h4>
            <div id='houseCommitteeOnVeteransAffairs'></div>

            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_veterans_affairs_meeting_feed&order_by=created_at', 
            'houseCommitteeOnVeteransAffairs', 
            "Committee on Veterans' Affairs", 'Legislative', 
            'N/A', 'Bill', GenerateFeed)}
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal("Committee on Veterans' Affairs Materials",
                <GenerateFeedCommittee url='&skip=0&committee=Committee%20on%20Veterans%27%20Affairs&chamber=HOUSE'
                name="Committee on Veterans' Affairs Materials" branch='Legislative' topic='N/A' buttonName='Bills'/>)}><b>Committee on Veterans' Affairs Materials</b></h4>
            <div id='houseCommitteeOnVeteransAffairsMaterials'></div>

            {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20Veterans%27%20Affairs&chamber=HOUSE', 
            'houseCommitteeOnVeteransAffairsMaterials', 
            "Committee on Veterans' Affairs Materials", 'Legislative', 
            'N/A', 'Bills', GenerateFeedCommittee)}
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div> */}

<div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Ways and Means',
              <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_ways_and_means_meeting_feed&order_by=created_at' 
              name='Committee on Ways and Means' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Committee on Ways and Means</b></h4>
            <div id='houseCommitteeOnWaysAndMeans'></div>

            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_ways_and_means_meeting_feed&order_by=created_at', 
            'houseCommitteeOnWaysAndMeans', 
            'Committee on Ways and Means', 'Legislative', 
            'N/A', 'Bill', GenerateFeed)}
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on Ways and Means Materials',
                <GenerateFeedCommittee url='&skip=0&committee=Committee%20on%20Ways%20and%20Means&chamber=HOUSE'
                name='Committee on Ways and Means Materials' branch='Legislative' topic='N/A' buttonName='Bills'/>)}><b>Committee on Ways and Means Materials</b></h4>
            <div id='houseCommitteeOnWaysAndMeansMaterials'></div>

            {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20Ways%20and%20Means&chamber=HOUSE', 
            'houseCommitteeOnWaysAndMeansMaterials', 
            'Committee on Ways and Means Materials', 'Legislative', 
            'N/A', 'Bills', GenerateFeedCommittee)}
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>


<div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on the Budget',
              <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_the_budget_meeting_feed&order_by=created_at' 
              name='Committee on the Budget' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Committee on the Budget</b></h4>
            <div id='houseCommitteeOnTheBudget'></div>

            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_the_budget_meeting_feed&order_by=created_at', 
            'houseCommitteeOnTheBudget', 
            'Committee on the Budget', 'Legislative', 
            'N/A', 'Bill', GenerateFeed)}
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on the Budget Materials',
                <GenerateFeedCommittee url='&skip=0&committee=Committee%20on%20the%20Budget&chamber=HOUSE'
                name='Committee on the Budget Materials' branch='Legislative' topic='N/A' buttonName='Bills'/>)}><b>Committee on the Budget Materials</b></h4>
            <div id='houseCommitteeOnTheBudgetMaterials'></div>

            {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20the%20Budget&chamber=HOUSE', 
            'houseCommitteeOnTheBudgetMaterials', 
            'Committee on the Budget Materials', 'Legislative', 
            'N/A', 'Bills', GenerateFeedCommittee)}
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on the Judiciary',
              <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_the_judiciary_meeting_feed&order_by=created_at' 
              name='Committee on the Judiciary' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Committee on the Judiciary</b></h4>
            <div id='houseCommitteeOnTheJudiciary'></div>

            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_the_judiciary_meeting_feed&order_by=created_at', 
            'houseCommitteeOnTheJudiciary', 
            'Committee on the Judiciary', 'Legislative', 
            'N/A', 'Bill', GenerateFeed)}
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Committee on the Judiciary Materials',
                <GenerateFeedCommittee url='&skip=0&committee=Committee%20on%20the%20Judiciary&chamber=HOUSE'
                name='Committee on the Judiciary Materials' branch='Legislative' topic='N/A' buttonName='Bills'/>)}><b>Committee on the Judiciary Materials</b></h4>
            <div id='houseCommitteeOnTheJudiciaryMaterials'></div>

            {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20the%20Judiciary&chamber=HOUSE', 
            'houseCommitteeOnTheJudiciaryMaterials', 
            'Committee on the Judiciary Materials', 'Legislative', 
            'N/A', 'Bills', GenerateFeedCommittee)}
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Permanent Select Committee on Intelligence',
              <GenerateFeed url='&skip=0&schema=united_states_of_america&table=permanent_select_committee_on_intelligence_meeting_feed&order_by=created_at' 
              name='Permanent Select Committee on Intelligence' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Permanent Select Committee on Intelligence</b></h4>
            <div id='houseCommitteeOnIntelligence'></div>

            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=permanent_select_committee_on_intelligence_meeting_feed&order_by=created_at', 
            'houseCommitteeOnIntelligence', 
            'Permanent Select Committee on Intelligence', 'Legislative', 
            'N/A', 'Bill', GenerateFeed)}
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Permanent Select Committee on Intelligence Materials',
                <GenerateFeedCommittee url='&skip=0&committee=Permanent%20Select%20Committee%20on%20Intelligence&chamber=HOUSE'
                name='Permanent Select Committee on Intelligence Materials' branch='Legislative' topic='N/A' buttonName='Bills'/>)}><b>Permanent Select Committee on Intelligence Materials</b></h4>
            <div id='houseCommitteeOnIntelligenceMaterials'></div>

            {CreateFeedAPICommittee('&skip=0&committee=Permanent%20Select%20Committee%20on%20Intelligence&chamber=HOUSE', 
            'houseCommitteeOnIntelligenceMaterials', 
            'Permanent Select Committee on Intelligence Materials', 'Legislative', 
            'N/A', 'Bills', GenerateFeedCommittee)}
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

{/* <div id='branchPage'>
  <Container className='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Select Committee to Investigate the January 6th Attack on the United States Capitol',
              <GenerateFeed url='&skip=0&schema=united_states_of_america&table=select_committee_to_investigate_january_6th_attack_feed&order_by=created_at' 
              name='Select Committee to Investigate the January 6th Attack on the United States Capitol' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Select Committee to Investigate the January 6th Attack on the United States Capitol</b></h4>
            <div id='selectCommitteeToInvestigate'></div>

            {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=select_committee_to_investigate_january_6th_attack_feed&order_by=created_at', 
            'selectCommitteeToInvestigate', 
            'Select Committee to Investigate the January 6th Attack on the United States Capitol', 'Legislative', 
            'N/A', 'Bill', GenerateFeed)}
          </div>
        </Col>

        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='feedHeadingBillsEnrolled' onClick={() =>
              openModal('Select Committee to Investigate the January 6th Attack on the United States Capitol Materials',
                <GenerateFeedCommittee url='&skip=0&committee=Select%20Committee%20to%20Investigate%20the%20January%206th%20Attack%20on%20the%20United%20States%20Capitol&chamber=HOUSE'
                name='Select Committee to Investigate the January 6th Attack on the United States Capitol Materials' branch='Legislative' topic='N/A' buttonName='Bills'/>)}><b>Select Committee to Investigate the January 6th Attack on the United States Capitol Materials</b></h4>
            <div id='selectCommitteeToInvestigateMaterials'></div>

            {CreateFeedAPICommittee('&skip=0&committee=Select%20Committee%20to%20Investigate%20the%20January%206th%20Attack%20on%20the%20United%20States%20Capitol&chamber=HOUSE', 
            'selectCommitteeToInvestigateMaterials', 
            'Select Committee to Investigate the January 6th Attack on the United States Capitol Materials', 'Legislative', 
            'N/A', 'Bills', GenerateFeedCommittee)}
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div> */}






      



            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
   <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>



</div>
         </div>
          

              </BrowserView>

              <MobileView>

    <HouseMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default HousePage;