import React, { useState } from 'react';
import { Image, Navbar, Dropdown, Button, Col, Modal } from 'react-bootstrap';
import { Link45deg, HeartFill } from 'react-bootstrap-icons';
import MobileOffCanvas from './MobileOffCanvas';
import GenerateFeedDepartment from './GenerateFeedDepartment';
import MobileTopNavbar from './MobileTopNavbar';
import GenerateFeed from './GenerateFeed';
import { CreateFeedAPI, CreateFeedAPIDepartmentUS } from './Functions';


const FederalReservePageMobile = () => {
  const imageName = '/federalReserve.png'; // image path
  const agencyName = 'Federal Reserve'; // agency name
  const officialURL = 'https://www.federalreserve.gov/'; // official URL
  const missionStatement = 'The Federal Reserve System is the central bank of the United States. It performs five general functions to promote the effective operation of the U.S. economy and, more generally, the public interest'; // mission statement
  const rssURL = '&skip=0&schema=united_states_of_america&table=federal_reserve&order_by=created_at&filter_column=media_type&filter_string=Speeches%20%26%20Testimony'; // RSS feed URL
  const feedName = 'Speeches & Testimony'; // feed name
  const topic = 'Economy'; // topic name

  const rssURL2 = '&skip=0&schema=united_states_of_america&table=federal_reserve&order_by=created_at&filter_column=media_type&filter_string=Research%20Paper%20Feeds'; // RSS feed URL
  const feedName2 = 'Research Paper'; // feed name

  const rssURL3 = '&skip=0&schema=united_states_of_america&table=federal_reserve&order_by=created_at&filter_column=media_type&filter_string=Other'; // RSS feed URL
  const feedName3 = 'Others'; // feed name

  const rssURL4 = '&skip=0&schema=united_states_of_america&table=federal_reserve&order_by=created_at&filter_column=media_type&filter_string=Press%20Releases'; // RSS feed URL
  const feedName4 = 'Press Releases'; // feed name


  const rssURL5 = '&skip=0&schema=united_states_of_america&table=federal_reserve&order_by=created_at&filter_column=media_type&filter_string=Research%20Paper%20Feeds'; // RSS feed URL
  const feedName5 = 'Research Paper'; // feed name


  const [armyModalIsOpen, setArmyModalIsOpen] = useState(false);
  const [papersModalIsOpen, setPapersModalIsOpen] = useState(false);
  const [announcementsModalIsOpen, setAnnouncementsModalIsOpen] = useState(false);
  const [othersModalIsOpen, setOthersModalIsOpen] = useState(false);
  return (
    <>
      <MobileTopNavbar/>
      <div id='homebg'>
        <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div id="profileImageOnPageDepartment">
                <Image id="profileImage-imageOnPageDepartment" src={imageName} />
              </div>
              <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                <h5 id="branchHeadingMobile">{agencyName}</h5>
              </div>
            </div>
            <Dropdown drop="down-centered">
              <Dropdown.Toggle id="searchCountry"><Link45deg /></Dropdown.Toggle>
              <Dropdown.Menu id="docsDropdown" drop="down-centered">
                <Dropdown.Item id="docsDropdownItem" onClick={(e) => { e.preventDefault(); window.open(officialURL, "_blank") }}>
                  Official Site
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
            Mission Statement: {missionStatement}
          </div>
        </div>

        <Col>
          <h5 id='presidentialFeedHeadingMobile' onClick={() => setAnnouncementsModalIsOpen(true)}>{feedName4}</h5>
          <div className='courtNewsMobile' id='agency4'></div>
          {CreateFeedAPI(rssURL4, 'agency4', feedName4, '', topic, 'Doc', GenerateFeed )}

      
        </Col>

        <Modal id='whiteHouseDocsModal' show={announcementsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAnnouncementsModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>{feedName4}</h3></Modal.Header>
          <GenerateFeed url={rssURL4} name={feedName4} branch='' topic={topic} buttonName='Doc' />
        </Modal>

        <Col>
          <h5 id='presidentialFeedHeadingMobile' onClick={() => setArmyModalIsOpen(true)}>{feedName}</h5>
          <div className='courtNewsMobile' id='agency'></div>
          {CreateFeedAPI(rssURL, 'agency', feedName, '', topic, 'Doc', GenerateFeed )}

      
        </Col>

        <Modal id='whiteHouseDocsModal' show={armyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmyModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
          <GenerateFeed url={rssURL} name={feedName} branch='' topic={topic} buttonName='Doc' />
        </Modal>


        <Col>
          <h5 id='presidentialFeedHeadingMobile' onClick={() => setPapersModalIsOpen(true)}>{feedName2}</h5>
          <div className='courtNewsMobile' id='agency2'></div>
          {CreateFeedAPI(rssURL2, 'agency2', feedName2, '', topic, 'Doc', GenerateFeed )}

      
        </Col>

        <Modal id='whiteHouseDocsModal' show={papersModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setPapersModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>{feedName2}</h3></Modal.Header>
          <GenerateFeed url={rssURL2} name={feedName2} branch='' topic={topic} buttonName='Doc' />
        </Modal>

        <Col>
          <h5 id='presidentialFeedHeadingMobile' onClick={() => setOthersModalIsOpen(true)}>{feedName3}</h5>
          <div className='courtNewsMobile' id='agency3'></div>
          {CreateFeedAPI(rssURL3, 'agency3', feedName3, '', topic, 'Doc', GenerateFeed )}

      
        </Col>

        <Modal id='whiteHouseDocsModal' show={othersModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setOthersModalIsOpen(false)}>
          <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>{feedName3}</h3></Modal.Header>
          <GenerateFeed url={rssURL3} name={feedName3} branch='' topic={topic} buttonName='Doc' />
        </Modal>

   

        <Col>
          <br/><br/><br/><br/><br/><br/><br/>
          <h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
            e.preventDefault();
            window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288')
          }}><HeartFill id='donateIcon'/>    Donate</Button></h5>
        </Col>
        <br/><br/><br/><br/><br/><br/><br/><br/><Navbar id='navbarMobileBottom' fixed="bottom">    
          <div id='changeBranchDropdownMobile'>
            <MobileOffCanvas/>
     
          </div>
        </Navbar>
      </div>
    </>
  )
}

export default FederalReservePageMobile;
