import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const NavbarSearchButtonMexico = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();

  const runSearch = () => {
    const encodedQuery = encodeURIComponent(searchQuery);
    const searchPath = searchQuery
      ? `/search/mexico?query=${encodedQuery}&limit=10`
      : '/search';

    history.push(searchPath);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    runSearch();
  };

  return (
    <Form >
    <Form.Group className="mb-3 d-flex">
      <Form.Control
        id="navbarSearch"
        placeholder="Search Gov Glance"
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        autoComplete="off"
      />
      <Button size="md" variant="primary" id="navbarAboutButtonDesktop" type="submit" onClick={handleFormSubmit}>
        Search
      </Button>
    </Form.Group>
  </Form>
  );
};

export default NavbarSearchButtonMexico;
