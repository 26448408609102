
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, Building,  ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Tree, FileMedical, FileMedicalFill, ChevronRight  } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import { render } from '@testing-library/react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import DesktopNavbar from './DesktopNavbar';
import HealthMobile from './HealthMobile';
import { CreateFeedAPI, CreateFeedAPICommittee, CreateFeedAPIDepartmentUS, CreateFeedItem, CreateFeedItemBillWithDescription, CreateFeedItemMaterials } from './Functions';
import HouseCommitteeOnTransportationAndInfrastructure from './HouseCommitteeOnTransportationAndInfrastructure';
import HouseCommitteeOnTransportationAndInfrastructureMaterials from './HouseCommitteeOnTransportationAndInfrastructureMaterials';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfHousingAndUrbanDevelopmentDocs from './DepartmentOfHousingAndUrbanDevelopmentDocs';
import SenateCommerceScienceCommittee from './SenateCommerceScienceCommittee';
import SenateBankingHousingUrbanCommittee from './SenateBankingHousingUrbanCommittee';
import InfrastructureTopicMobile from './InfrastructureTopicMobile';
import DepartmentOfTransportationDocs from './DepartmentOfTransportationDocs';
import CommercialSpaceTransportationOffice from './CommercialSpaceTransportationOffice';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import Energy from './Energy';
import HouseCommitteeOnEnergyAndCommerceMaterials from './HouseCommitteeOnEnergyAndCommerceMaterials';
import DepartmentOfEnergyDocs from './DepartmentOfEnergyDocs';
import EnergyInformationAdministration from './EnergyInformationAdministration';
import BonnevillePowerAdministration from './BonnevillePowerAdministration';
import WesternAreaPowerAdministration from './WesternAreaPowerAdministration';
import SouthwesternPowerAdministration from './SouthwesternPowerAdministration';
import SoutheasternPowerAdministration from './SoutheasternPowerAdministration';
import NationalNuclearSecurityAdministration from './NationalNuclearSecurityAdministration';
import EnergyEfficiencyAndRenewableEnergyOffice from './EnergyEfficiencyAndRenewableEnergyOffice';
import FederalEnergyRegulatoryCommission from './FederalEnergyRegulatoryCommission';
import GenerateFeed from './GenerateFeed';
import GenerateFeedCommittee from './GenerateFeedCommittee';
import GenerateFeedDepartment from './GenerateFeedDepartment';

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;

export const Infrastructure = () =>
 {
   render()
   {
 
  const [cdcNewsroomModalIsOpen, setCDCNewsroomModalIsOpen] = useState(false)
  const [outbreaksModalIsOpen, setOutbreaksModalIsOpen] = useState(false)
  const [travelNoticesModalIsOpen, setTravelNoticesModalIsOpen] = useState(false)
  const [foodRecallsModalIsOpen, setFoodRecallsModalIsOpen] = useState(false)
  const [foodSafetyModalIsOpen, setFoodSafetyModalIsOpen] = useState(false)
  const [medWatchModalIsOpen, setMedWatchModalIsOpen] = useState(false)
  const [healthyLivingFeaturesModalIsOpen, setHealthyLivingFeaturesModalIsOpen] = useState(false)
  const [preventingChronicDiseaseModalIsOpen, setPreventingChronicDiseaseModalIsOpen] = useState(false)
  const [covid19ModalIsOpen, setCovid19ModalIsOpen] = useState(false)
  const [novelCoronavirusModalIsOpen, setNovelCoronavirusModalIsOpen] = useState(false)
  const [vaccineUpdatesModalIsOpen, setVaccineUpdatesModalIsOpen] = useState(false)
  const [seasonalFluModalIsOpen, setSeasonalFluModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
  const [immigrationModalIsOpen, setImmigrationModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [usCustomsModalIsOpen, setUSCustomsModalIsOpen] = useState(false)
  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEnergyMaterialsModalIsOpen, setDepartmentOfEnergyMaterialsModalIsOpen] = useState(false)
  const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
  const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
  const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
  const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
  const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
  const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
  const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
  const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)
  const [diplomaticSecurityModalIsOpen, setDiplomaticSecurityModalIsOpen] = useState(false)
  return(
    <>
      <BrowserView>
        <div id='homePage'>
        <div id='homebg'>
        <DesktopNavbar/>
      
               <div id='sectionHeading'>      
               <Row>
              <h3 id='branchHeading' ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
  <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
  <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3z"/>
</svg><b> Infrastructure</b></h3> 
              
              </Row>
              </div> 

              <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
                  <Col id='homeCol'>
                      <div id='columnRightPadding'>

                      <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentofhousingandurbandevelopment', "_self")}><b>Department of Housing and Urban Development</b><ChevronRight id='clickChevron'></ChevronRight></h4>

                      <Carousel id='carouselInColumn' controls={false}  >
            
      
            
            <Carousel.Item interval={carouselInterval}>




<h4 id='departmentOfJusticeHeading' onClick={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(true)}><b>News</b></h4>




  <div id='housingAndUrbanDevelopmentDepartment'></div>

  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=housing_and_urban_development&order_by=created_at', 'housingAndUrbanDevelopmentDepartment', 
                            'Department of Housing and Urban Development', 'Executive', 
                            'N/A', 'Document', GenerateFeed)}



<Modal id='whiteHouseDocsModal' show={departmentOfHousingAndUrbanDevelopmentModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Housing and Urban Development</h3></Modal.Header>
  <GenerateFeed url='&skip=0&schema=united_states_of_america&table=housing_and_urban_development&order_by=created_at'
                              name='Department of Housing and Urban Development' branch='Executive' topic='N/A' buttonName='Document'/>
</Modal>


    






</Carousel.Item>

<Carousel.Item interval={carouselInterval}>



<h4 id='departmentOfJusticeHeading' onClick={() => setUSCustomsModalIsOpen(true)}><b>Documents</b></h4>


<div id='housingDocs'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=housing-and-urban-development-department', 'housingDocs', 'Department of Housing and Urban Development Documents', 'Executive', 'N/A', DepartmentOfHousingAndUrbanDevelopmentDocs)}



<Modal id='whiteHouseDocsModal' show={usCustomsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setUSCustomsModalIsOpen(false)}>
      <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Housing and Urban Development Documents</h3></Modal.Header>
      <DepartmentOfHousingAndUrbanDevelopmentDocs/>
    </Modal>
  








</Carousel.Item>






<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/departmentofhousing' >View all Department of Housing and Urban Development news</Button>
</div>
</Col>

</div>



</Carousel.Item>

            
            
            </Carousel>
                      </div>
                    </Col>

                    <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>
                      <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentoftransportation', "_self")}><b>Department of Transporation</b><ChevronRight id='clickChevron'></ChevronRight></h4>
                 
                      <Carousel id='carouselInColumn' controls={false}  >
            
      
            
            <Carousel.Item interval={carouselInterval}>



<h4 id='departmentOfJusticeHeading' onClick={() => setNewLawsModalIsOpen(true)}><b>Documents</b></h4>




  <div id='transDepartment'></div>

  {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=transportation-department', 'transDepartment', 'Department of Transporation Docs', 'Executive', 'N/A', DepartmentOfTransportationDocs)}




<Modal id='whiteHouseDocsModal' show={newLawsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNewLawsModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Transporation</h3></Modal.Header>
  <DepartmentOfTransportationDocs />
</Modal>



</Carousel.Item>








<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentoftransportation' >View all Department of Transportation news</Button>
</div>
</Col>

</div>



</Carousel.Item>

            
            
            </Carousel>

                      </div>
                    </Col>
                  </Row>
                </Container>
              </Container>
            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
                  <Col id='legislativeColRight'>
                      <div id='columnRightPadding'>

                      <h4 id='departmentOfDefenseHeadingInCol' onClick={() => window.open('/us/departmentofenergy', "_self")}><b>Department of Energy</b><ChevronRight id='clickChevron'></ChevronRight></h4>

                       
                      <Carousel id='carouselInColumn' controls={false}  >
            
      
            
            <Carousel.Item interval={carouselInterval}>


<h4 id='departmentOfJusticeHeading' onClick={() => setDiplomaticSecurityModalIsOpen(true)}><b>Documents</b></h4>




<div id='energyDepartment'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=energy-department', 'energyDepartment', 'Department of Energy', 'Executive', 'N/A', DepartmentOfEnergyDocs)}

    


    
    <Modal id='whiteHouseDocsModal' show={diplomaticSecurityModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDiplomaticSecurityModalIsOpen(false)}>
      <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Energy Documents</h3></Modal.Header>
      <DepartmentOfEnergyDocs />
    </Modal>







</Carousel.Item>



<Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentofenergy' >View all Department of Energy news</Button>
</div>
</Col>

</div>



</Carousel.Item>

            
            
            </Carousel>
                      </div>
                    </Col>
                  <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading' onClick={() => setDepartmentOfEnergyModalIsOpen(true)} ><b>Committee on Energy and Commerce</b></h4>
                          <div id='houseCommitteeOnEnergyAndCommerce'></div>
                        
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=committee_on_energy_and_commerce_meeting_feed&order_by=created_at', 
            'houseCommitteeOnEnergyAndCommerce', 
            'Committee on Energy and Commerce', 'Legislative', 
            'N/A', 'Bill', GenerateFeed)}
                        <Modal id='departmentOfStateModal' show={departmentOfEnergyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfEnergyModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Committee on Energy and Commerce</h3></Modal.Header>
  <GenerateFeed url='&skip=0&schema=united_states_of_america&table=committee_on_energy_and_commerce_meeting_feed&order_by=created_at' 
              name='Committee on Energy and Commerce' branch='Legislative' topic='N/A' buttonName='Bill' />
</Modal>
     
                        </div>
                      </Col>

  
                  </Row>
                </Container>
              </Container>
            </div>

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                <Row>

                <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading' onClick={() => setDepartmentOfEnergyMaterialsModalIsOpen(true)}><b>Committee on Energy and Commerce Materials</b></h4>
                          <div id='houseCommitteeOnEnergyAndCommerceMaterials'></div>
            
            {CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20Energy%20and%20Commerce&chamber=HOUSE', 
            'houseCommitteeOnEnergyAndCommerceMaterials', 
            'Committee on Energy and Commerce Materials', 'Legislative', 
            'N/A', 'Bill', GenerateFeedCommittee)}
                  
<Modal id='departmentOfStateModal' show={departmentOfEnergyMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfEnergyMaterialsModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Committee on Energy and Commerce Materials</h3></Modal.Header>
  <GenerateFeedCommittee url='&skip=0&committee=Committee%20on%20Energy%20and%20Commerce&chamber=HOUSE'
                name='Committee on Energy and Commerce Materials' branch='Legislative' topic='N/A' buttonName='Bill'/>
</Modal>

                        </div>
                      </Col>

<Col id='legislativeColLeft'>
  <div id='columnLeftPadding'>
  <h4 id='whBreifingFeedHeading' onClick={() => setNavyModalIsOpen(true)}><b>Energy Information Administration</b></h4>


  <div id='defEia'></div>
            {CreateFeedAPIDepartmentUS('&table_name=energy&agency=Energy%20Information%20Administration', 'defEia',
              'Energy Information Administration Documents', 'Executive',
              'Energy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={navyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNavyModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Energy Information Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=energy&agency=Energy%20Information%20Administration'
                name='Energy Information Administration' branch='Executive' topic='Energy' buttonName='Doc' />
            </Modal>
  </div>
</Col>


</Row>
                </Container>
              </Container>
            </div>

            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                    <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>

                        <h4 id='whBreifingFeedHeading' onClick={() => setAirForceModalIsOpen(true)}><b>Federal Energy Regulatory Commission</b></h4>
                        <div id='airForce'></div>

{CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=federal-energy-regulatory-commission', 'airForce', 'Federal Energy Regulatory Commission', 'Executive', 'N/A', FederalEnergyRegulatoryCommission)}

     
<Modal id='whiteHouseDocsModal' show={airForceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAirForceModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Energy Regulatory Commission</h3></Modal.Header>
                  <FederalEnergyRegulatoryCommission/>
                </Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='whBreifingFeedHeading' onClick={() => setMarinesModalIsOpen(true)}><b>Energy Efficiency and Renewable Energy Office</b></h4>


                        <div id='defEere'></div>
            {CreateFeedAPIDepartmentUS('&table_name=energy&agency=Energy%20Efficiency%20and%20Renewable%20Energy%20Office', 'defEere',
              'Energy Efficiency and Renewable Energy Office Documents', 'Executive',
              'Energy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={marinesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setMarinesModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Energy Efficiency and Renewable Energy Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=energy&agency=Energy%20Efficiency%20and%20Renewable%20Energy%20Office'
                name='Energy Efficiency and Renewable Energy Office' branch='Executive' topic='Energy' buttonName='Doc' />
            </Modal>
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                    <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>

                        <h4 id='whBreifingFeedHeading' onClick={() => setSpaceForceModalIsOpen(true)}><b>National Nuclear Security Administration</b></h4>
                        <div  id='spaceForce'></div>

{CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=national-nuclear-security-administration', 'spaceForce', 'National Nuclear Security Administration', 'Executive', 'N/A', NationalNuclearSecurityAdministration)}

     
<Modal id='whiteHouseDocsModal' show={spaceForceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSpaceForceModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Nuclear Security Administration</h3></Modal.Header>
                  <NationalNuclearSecurityAdministration/>
                </Modal>

                        </div>
                      </Col>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='whBreifingFeedHeading'  onClick={() => setNationalGuardModalIsOpen(true)}><b>Southeastern Power Administration</b></h4>


                        <div id='defSpa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=energy&agency=Southeastern%20Power%20Administration', 'defSpa',
              'Southeastern Power Administration Documents', 'Executive',
              'Energy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={nationalGuardModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNationalGuardModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Southeastern Power Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=energy&agency=Southeastern%20Power%20Administration'
                name='Southeastern Power Administration' branch='Executive' topic='Energy' buttonName='Doc' />
            </Modal>
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                    <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>

                        <h4 id='whBreifingFeedHeading'onClick={() => setNSAModalIsOpen(true)}><b>Southwestern Power Administration</b></h4>
                        <div id='defSwpa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=energy&agency=Southwestern%20Power%20Administration', 'defSwpa',
              'Southwestern Power Administration Documents', 'Executive',
              'Energy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={nsaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNSAModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Southwestern Power Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=energy&agency=Southwestern%20Power%20Administration'
                name='Southwestern Power Administration' branch='Executive' topic='Energy' buttonName='Doc' />
            </Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='whBreifingFeedHeading' onClick={() => setArmyCorpsModalIsOpen(true)}><b>Western Area Power Administration</b></h4>

                        <div id='defWapa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=energy&agency=Western%20Area%20Power%20Administration', 'defWapa',
              'Western Area Power Administration Documents', 'Executive',
              'Energy', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={armyCorpsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmyCorpsModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Western Area Power Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=energy&agency=Western%20Area%20Power%20Administration'
                name='Western Area Power Administration' branch='Executive' topic='Energy' buttonName='Doc' />
            </Modal>
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                    <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>

                        <h4 id='whBreifingFeedHeading' onClick={() => setArmyModalIsOpen(true)}><b>Bonneville Power Administration</b></h4>
                        <div  id='armyDocs'></div>

{CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=bonneville-power-administration', 'armyDocs', 'Bonneville Power Administration', 'Executive', 'N/A', BonnevillePowerAdministration)}

<Modal id='whiteHouseDocsModal' show={armyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmyModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Bonneville Power Administration</h3></Modal.Header>
                  <BonnevillePowerAdministration/>
                </Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                     

                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>
         
                     
            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                  <Row>
                  <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading' onClick={() => setCDCNewsroomModalIsOpen(true)}><b>Committee on Transportation and Infrastructure</b></h4>
                          <div id='houseCommitteeOnTransportationAndInfrastructure'></div>
                       

                       {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=PW00', 
                       'houseCommitteeOnTransportationAndInfrastructure', 
                       'House Committee on Transportation and Infrastructure', 'Legislative', 'Environment', HouseCommitteeOnTransportationAndInfrastructure)}

                  

                       <Modal id='departmentOfStateModal' show={cdcNewsroomModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCDCNewsroomModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Committee on Transportation and Infrastructure</h3></Modal.Header>
  <HouseCommitteeOnTransportationAndInfrastructure/>
</Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading'onClick={() => setOutbreaksModalIsOpen(true)}><b>Committee on Transportation and Infrastructure Materials</b></h4>
                          <div id='houseCommitteeOnTransportationAndInfrastructureMaterials'></div>

{CreateFeedAPICommittee('&skip=0&committee=Committee%20on%20Transportation%20and%20Infrastructure&chamber=HOUSE', 
'houseCommitteeOnTransportationAndInfrastructureMaterials', 
'Committee on Transportation and Infrastructure Materials', 'Legislative', 
'Environment', 'Bills', GenerateFeedCommittee)}

                       <Modal id='departmentOfStateModal' show={outbreaksModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setOutbreaksModalIsOpen(false)}>
  <Modal.Header id='whiteHouseDocsModalHeader' closeButton><h3>Committee on Transportation and Infrastructure Materials</h3></Modal.Header>
  <GenerateFeedCommittee url='&skip=0&committee=Committee%20on%20Transportation%20and%20Infrastructure&chamber=HOUSE'
                name='Committee on Transportation and Infrastructure Materials' branch='Legislative' topic='Environment' buttonName='Bills'/>
</Modal>
     
                        </div>
                      </Col>





                  </Row>
                </Container>
              </Container>
            </div>

    

            <div id='branchPage'>
              <Container class='bgcolor' >
                <Container id='carouselBufferTwo'>

                <Row>

                <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='whBreifingFeedHeading' onClick={() => setSeasonalFluModalIsOpen(true)}><b>Senate Committee on Commerce, Science, and Transportation Materials</b></h4>
                        <div id='senateCommerceScienceAndTransportationCommittee'></div>
            {CreateFeedAPICommittee(
              '&skip=0&committee=Committee%20on%20Commerce,%20Science,%20and%20Transportation&chamber=SENATE', 
              'senateCommerceScienceAndTransportationCommittee', 
              'Senate Committee on Commerce, Science, and Transportation', 
              'Legislative', 
              'N/A', 
              'Bill', 
              GenerateFeedCommittee
            )}

                            <Modal id='whiteHouseDocsModal' show={seasonalFluModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSeasonalFluModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Senate Committee on Commerce, Science, and Transportation Materials</h3></Modal.Header>
<GenerateFeedCommittee
                  url='&skip=0&committee=Committee%20on%20Commerce,%20Science,%20and%20Transportation&chamber=SENATE'
                  name='Senate Committee on Commerce, Science, and Transportation Materials' 
                  branch='Legislative' 
                  topic='N/A' 
                  buttonName='Bill' />
</Modal>
</div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                        <h4 id='whBreifingFeedHeading' onClick={() => setTravelNoticesModalIsOpen(true)}><b>Senate Committee on Banking, Housing, and Urban Affairs	 Materials</b></h4>
                        <div id='senateBankingHousingUrbanCommittee'></div>
            {CreateFeedAPICommittee(
              '&skip=0&committee=Committee%20on%20Banking,%20Housing,%20and%20Urban%20Affairs&chamber=SENATE', 
              'senateBankingHousingUrbanCommittee', 
              'Senate Committee on Banking, Housing, and Urban Affairs', 
              'Legislative', 
              'N/A', 
              'Bill', 
              GenerateFeedCommittee
            )}


                            <Modal id='whiteHouseDocsModal' show={travelNoticesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTravelNoticesModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Senate Committee on Commerce, Science, and Transportation Materials</h3></Modal.Header>
<GenerateFeedCommittee 
                  url='&skip=0&committee=Committee%20on%20Banking,%20Housing,%20and%20Urban%20Affairs&chamber=SENATE'
                  name='Senate Committee on Banking, Housing, and Urban Affairs Materials' 
                  branch='Legislative' 
                  topic='N/A' 
                  buttonName='Bill' />
</Modal>
</div>
                      </Col>


</Row>
                </Container>
              </Container>
            </div>


       
            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
   <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>
      


            




</div>
         </div>
          

              </BrowserView>

              <MobileView>

            <InfrastructureTopicMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default Infrastructure;