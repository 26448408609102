
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, NavLink,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, FileMedicalFill, Coin, Tree, Globe2,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, GeoAlt, HeartFill, QuestionCircleFill, MicFill, ReceiptCutoff } from 'react-bootstrap-icons'

import { BrowserView, MobileView } from 'react-device-detect';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';

import
 {supremeCourtSearch, whiteHouseSearch, billFeedModal, 
  createHouseFloorToday, createSenateFloorToday, createTrendingBills,
  createNationalAeronauticsAndSpaceAdministration, 
  createEnvironmentalProtectionAgency, createDepartmentOfHomelandSecurity,
  createDepartmentOfVeteransAffairs, createDepartmentOfEducation,
  createDepartmentOfHousingAndUrbanDevelopment, 
  createDepartmentOfHealthAndHumanServices, createDepartmentOfLabor
, createDepartmentOfCommerce, createDepartmentOfAgriculture,
createDepartmentOfTheInterior, createDepartmentOfJustice,
createDepartmentOfTransportation, createDepartmentOfDefense,
createDepartmentOfTreasury, createDepartmentOfEnergy,
createDepartmentOfState, createBillsEnrolledMobile,
createBillsEnrolled, createNewLaws, createGAOReports,
createExecutiveOrders, createPresidentialProclamations, createCongressionalHearings,
createCongressionalBudgetOfficeMobile, createCongressionalBudgetOffice,
createHouseCommitteeOversightAndReform,
createHouseCommitteeOnNaturalResources,
createHouseCommitteeOnTheJudiciary,
createHouseCommitteeOnHouseAdministration,
createHouseCommitteeOnHomelandSecurity,
createHouseCommitteeOnForeignAffairs,
createHouseCommitteeOnFinancialServices,
createHouseCommitteeOnEthics,
createHouseCommitteeOnEnergyAndCommerce,
createHouseCommitteeOnEducationAndLabor,
createHouseCommitteeOnTheBudget,
createHouseCommitteeOnArmedServices,
createHouseCommitteeOnAppropriations,
createHouseCommitteeOnAgriculture,
createReports,
createBillFeedTwoMobile,
createBillFeedTwo,
createBillFeed, createWhiteHouseFeedMobile
, createWhiteHouseFeed, createWhiteHouseDocuments, 
createBillsPresentedFeed, createCourtAppealsMobile,
createCourtAppeals, createUSCourtNewsFeedMobile,
createUSCourtNewsFeed, saveToCollection,
createFullBillFeed, ListItem, Speak, feedText, variableTest, myMethod, myMethod2, createEconomicIndicators, 
createSecuritiesAndExchangeCommissionPressReleases, createNationWeatherServices, createCDCNewsroom,
createSecuritiesAndExchangeCommissionSpeechesAndStatements, createNationalScienceFoundation, createHouseCommitteeOnIntelligence,
 createUNTopStories, createCDCOutbreaks, createCDCTravelNotices, createFoodRecalls, createFoodSafety, createFederalElectionCommissionNews, createElectionAssistancCommissioneNews, 
 createBillStatus, CreateFeedItem, CreateFeedItemWithDocButton, CreateFeedItemWithLawButton, CreateFeedItemGAOReports,
CreateFeedItemCongressionalReports,
CreateFeedItemPresidentialDocuments,
CreateFeedItemExecutiveOrders,
CreateFeedItemCourtAppeals,
CreateFeedItemEconomicIndicators,
CreateFeedItemBillUpdates,
createBIllItem,
CreateFeedItemSupremeCourtArguments,
CreateFeedItemSupremeCourtOpinions,
CreateFeedItemTest,
CreateFeedItemBillUpdatesFlip,
CreateFeedItemWithDocumentFlip,
CreateFeedItemWithDocButtonDateFix,
CreateFeedItemWithLawButtonFlip,
CreateFeedItemCanadaDateFix,
CreateFeedItemCanada,
CreateFeedAPI} from '../../Functions'

import SupremeCourt from './SupremeCourt';
import UKDesktopNavbar from '../UKDesktopNavbar';
import UKJudicialMobile from './UKJudicialMobile';
import GenerateFeed from '../../GenerateFeed';
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);
export const UKJudicial = () =>   {
  render()

 
  {
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [showBioModal, setBioModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };

  return (
    
    <>
    
      <div className="App">
   
    




      <BrowserView>
      <div id='homePage'>
        


        <div id='homebg'>

     

          

         <UKDesktopNavbar/>
       
         <div style={{ position: 'relative' }}>

<div id='profileImage' style={{ width: '130px', height: '130px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '60px' }}>
<Image id='profileImage-image' src='/ukJudicial.png' />
</div>


<div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
<div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Judicial Branch </h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '16px' }}>
<span> 
The Supreme Court, as well as being the final court of appeal, plays an important role in the development of United Kingdom law.</span>
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

  <span><Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.supremecourt.uk/about/role-of-the-supreme-court.html", "_blank");
   }}>
     https://www.supremecourt.uk/about/role-of-the-supreme-court.html
     </Link></span> 

     <Button id='whDocsViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
</div>
</div>

</div>
</div>
<br></br>
<br></br>
<br></br>
<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> The Judicial Branch</h3></Modal.Header>
       
         <div id='fullBillFeed' >
         <p>The Supreme Court, as the final court of appeal, plays an important role in the development of United Kingdom law.</p>

<p>As an appeal court, The Supreme Court cannot consider a case unless a relevant order has been made in a lower court.</p>

<p>The Supreme Court:</p>
<ul>
  <li>is the final court of appeal for all United Kingdom civil cases, and criminal cases from England, Wales, and Northern Ireland</li>
  <li>hears appeals on arguable points of law of general public importance</li>
  <li>focuses on cases of the greatest public and constitutional importance</li>
  <li>maintains and develops the role of the highest court in the United Kingdom as a leader in the common law world</li>
</ul>

<br></br>
<Link onClick={(e) => {
     e.preventDefault();
     window.open("https://www.supremecourt.uk/about/role-of-the-supreme-court.html", "_blank");
   }}>
     https://www.supremecourt.uk/about/role-of-the-supreme-court.html
     </Link>
 </div>

       </Modal>

</div>
  
            <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
           
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                    <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>

                        <h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsModalIsOpen(true)}><b>Supreme Court</b></h4>
                          <div id='cabinetNews'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}

                          {CreateFeedItem('https://www.gov.uk/government/organisations/supreme-court-of-the-united-kingdom.atom', 'cabinetNews', 'Supreme Court', 'Executive', 'N/A', SupremeCourt)}


  <Modal id='mobileFullBillModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court</h3></Modal.Header>
<SupremeCourt/>
</Modal>
                          
                        
                        </div>
                      </Col>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='feedHeadingBillsEnrolled' onClick={() =>
                            openModal('Court and Tribunals',
                              <GenerateFeed
                                url='&skip=0&schema=united_kingdom&table=courts_and_tribunals&order_by=created_at'
                                name='Court and Tribunals' branch='Judicial' topic='N/A' buttonName='Doc' />)}><b>Courts and Tribunals</b></h4>
                          <div id='courtTrib'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}

                          {CreateFeedAPI('&skip=0&schema=united_kingdom&table=courts_and_tribunals&order_by=created_at', 'courtTrib', 
                            'Court and Tribunals', 'Judicial', 
                            'N/A', 'Docs', GenerateFeed)}

  <Modal id='mobileFullBillModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court</h3></Modal.Header>
<SupremeCourt/>
</Modal>
                      
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
             
              </div>
           
         
         


            
              
             
              
            
          
            
            
          <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Judicial, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
   <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>

      


    
          </div>
  

    </div>
      </BrowserView>
     

      <MobileView> 
        
        
<UKJudicialMobile/>
     
      </MobileView>
        </div>
      


       
    </>

     
    
  );







}
}


export default UKJudicial;
