import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import { Spinner, Card, Button, Container, Modal, Form } from 'react-bootstrap';
import { InfoCircle, FileText, FolderPlus, Search, ChevronRight } from 'react-bootstrap-icons';
import './App.css';
import useHandleContainerClickRecents from './UseHandleContainerClickRecents';
import useHandleContainerClickRecentsCanada from './UseHandleContainerClickRecentsCanada';
import useHandleContainerClickRecentsMexico from './UseHandleContainerClickRecentsMexico';


require('dotenv').config();
const apiKey = process.env.REACT_APP_API_KEY;
const requestOptions = {
    method: 'GET',
    headers: { 
      'Content-Type': 'application/json',
      "Authorization": "Bearer "+apiKey,
  },
  };

  var storedTitle = ''// title
var storedDetailsLink = ''// details link
var storedDocLink = ''// doc link *
var storedDate = ''// date
var storedNote = ''// note
var storedFeedName = ''// feed name
var storedBranchName = ''// branch name 
var storedTopicName = ''// topic name
var storedDescription = ''// description
var collectionCounter = localStorage.getItem('collectionNumber')

const stateUrls = {
  'alabama': '/alabama',
  'alaska': '/alaska',
  'arizona': '/arizona',
  'arkansas': '/arkansas',
  'california': '/california',
  'colorado': '/colorado',
  'connecticut': '/connecticut',
  'delaware': '/delaware',
  'florida': '/florida',
  'georgia': '/georgia',
  'hawaii': '/hawaii',
  'idaho': '/idaho',
  'illinois': '/illinois',
  'indiana': '/indiana',
  'iowa': '/iowa',
  'kansas': '/kansas',
  'kentucky': '/kentucky',
  'louisiana': '/louisiana',
  'maine': '/maine',
  'maryland': '/maryland',
  'massachusetts': '/massachusetts',
  'michigan': '/michigan',
  'minnesota': '/minnesota',
  'mississippi': '/mississippi',
  'missouri': '/missouri',
  'montana': '/montana',
  'nebraska': '/nebraska',
  'nevada': '/nevada',
  'newhampshire': '/newhampshire',
  'newjersey': '/newjersey',
  'newmexico': '/newmexico',
  'newyork': '/newyork',
  'northcarolina': '/northcarolina',
  'northdakota': '/northdakota',
  'ohio': '/ohio',
  'oklahoma': '/oklahoma',
  'oregon': '/oregon',
  'pennsylvania': '/pennsylvania',
  'rhodeisland': '/rhodeisland',
  'southcarolina': '/southcarolina',
  'southdakota': '/southdakota',
  'tennessee': '/tennessee',
  'texas': '/texas',
  'utah': '/utah',
  'vermont': '/vermont',
  'virginia': '/virginia',
  'washington': '/washington',
  'westvirginia': '/westvirginia',
  'wisconsin': '/wisconsin',
  'wyoming': '/wyoming',
};

const otherUrls = {
  'committee on agriculture meeting feed': '/environment',
  'council on environmental quality': '/environment',
  'usda latest news releases': '/environment',
  'united states environmental protection agency': '/environment',
  'national weather service update': '/environment',
  'cdc newsroom': '/health',
  'food and drug adminstration: food recalls': '/health',
  'u.s. department of health and human services': '/health',
  'food and drug adminstration: medical watch': '/health',
  'food and drug administration: medical watch': '/health',
  'bureau of economic analysis': '/economy',
  'council of economic advisers': '/economy',
  'federal reserve bank of america: gross domestic product': '/economy',
  'federal reserve bank of alanta: inflation update': '/economy',
  'federal reserve bank of atlanta: inflation update': '/economy',
  'u.s. securties and exchange commission: speeches and statements': '/economy',
  'u.s. securties and exchange commission: updates': '/economy',
  'federal reserve bank of atlanta: wage growth': '/economy',
  'department of interior news': '/departmentoftheinterior',
  'u.s. department of justice': '/departmentofjustice',
  'domestic policy council': '/executiveoffice',
  'gender policy council': '/executiveoffice',
  'national economic council': '/executiveoffice',
  'national security council': '/executiveoffice',
  'office of intergovernmental affairs': '/executiveoffice',
  'office of management and budget': '/executiveoffice',
  'office of national drug control policy': '/executiveoffice',
  'office of public engagement': '/executiveoffice',
  'office of science and technology policy': '/executiveoffice',
  'office of the national cyber director': '/executiveoffice',
  'presidential personnell office': '/executiveoffice',
  'department of state news': '/departmentofstate',
  'u.s. department of state': '/departmentofstate',
  'permanent select committee on intelligence': '/house',
  'committee on appropriations meeting feed': '/house',
  'committee on armed services meeting feed': '/house',
  'committee on education and the workforce meeting feed': '/house',
  'committee on energy and commerce meeting feed': '/house',
  'committee on ethics meeting feed': '/house',
  'committee on financial services meeting feed': '/house',
  'committee on foreign affairs meeting feed': '/house',
  'committee on homeland security meeting feed': '/house',
  'committee on house administration meeting feed': '/house',
  'committee on natural resources meeting feed': '/house',
  'committee on oversight and accountability meeting feed': '/house',
  'committee on rules meeting feed': '/house',
  'committee on small business meeting feed': '/house',
  'committee on the budget meeting feed': '/house',
  'committee on the judiciary meeting feed': '/house',
  'committee on transportation and infrastructure meeting feed': '/house',
  'committee on veterans affairs meeting feed': '/house',
  'congressional bills': '/legislative',
  'congressional bills enrolled': '/legislative',
  'congressional committee prints': '/legislative',
  'congressional documents': '/legislative',
  'congressional hearings': '/legislative',
  'congressional reports': '/legislative',
  'congressional serial set': '/legislative',
  'public and private laws': '/legislative',
  'public private laws': '/legislative',
  'executive orders': '/executive',
  'president executive orders': '/executive',
  'compilation of presidential documents': '/executive',
  'the white house': '/executive',
  'supreme court arguments': '/judicial',
  'supreme_court_opinions': '/judicial',
  'supreme court opinions': '/judicial',
  'department of defense': '/departmentofdefense',
  'the united states marine corps': '/departmentofdefense',
  'national guard': '/departmentofdefense',
  'u.s. space force': '/departmentofdefense',
  'veterans affairs news': '/departmentofveteransaffairs',
  'the national aeronautics and space administration': '/science&tech',
  'national science foundation': '/science&tech',
};

function generateSourceUrl(source) {
  const lowercaseSource = source.toLowerCase();
  return stateUrls[lowercaseSource] || otherUrls[lowercaseSource] || '';
}

function SaveFeedItem(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription) {
  storedTitle = saveCollectionTitle;
  storedDetailsLink = saveCollectionDetailLink;
  storedDocLink = saveCollectionDocLink;
  storedDate = saveCollectionDate;
  storedNote = saveCollectionNote;
  storedFeedName = saveCollectionFeedName;
  storedBranchName = saveCollectionBranchName;
  storedTopicName = saveCollectionTopicName;
  storedDescription = saveCollectionDescription;
}

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
}

function getCountryEmoji(country) {
  switch (country) {
    case 'United States':
      return '🇺🇸';
    case 'Canada':
      return '🇨🇦';
    case 'Mexico':
      return '🇲🇽';
    default:
      return '';
  }
}


function GenerateFeedRecentAll() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [feedLength, setFeedLength] = useState(10);
  const handleContainerClickUS = useHandleContainerClickRecents();
  const handleContainerClickCanada = useHandleContainerClickRecentsCanada();
  const handleContainerClickMexico = useHandleContainerClickRecentsMexico();
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
  const apiURLUS = `https://api.govglance.org/posts/recent/schema?limit=${feedLength}&skip=0&schema=united_states_of_america`;
  const apiURLCanada = `https://api.govglance.org/posts/recent/schema?limit=${feedLength}&skip=0&schema=canada`;
  const apiURLMexico = `https://api.govglance.org/posts/recent/schema?limit=${feedLength}&skip=0&schema=mexico`;

  
  

  useEffect(() => {
    setLoading(true);
    const requests = [
      fetch(apiURLUS, requestOptions).then(response => response.json()).then(data => data.map(item => ({ ...item, country: 'United States' }))),
      fetch(apiURLCanada, requestOptions).then(response => response.json()).then(data => data.map(item => ({ ...item, country: 'Canada' }))),
      fetch(apiURLMexico, requestOptions).then(response => response.json()).then(data => data.map(item => ({ ...item, country: 'Mexico' }))),
    ];

    Promise.all(requests)
      .then(responses => {
        const mergedData = [].concat(...responses);

        // Sort the merged data by date (newest first)
        const sortedData = mergedData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        setData(sortedData);
        setLoading(false);
        setError(null);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, [feedLength]);

  const performSearch = () => {
    const filteredData = data.filter((item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setData(filteredData);
  };

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  function generateCountryLink(country) {
    switch (country) {
      case 'United States':
        return '/us';
      case 'Canada':
        return '/canada';
      case 'Mexico':
        return '/mexico';
      default:
        return '#';
    }
  }

  const handleContainerClick = (country, nameForAPI, collectionName) => {
    switch (country) {
      case 'United States':
        handleContainerClickUS(nameForAPI, collectionName, "", "", "");
        break;
      case 'Canada':
        handleContainerClickCanada(nameForAPI, collectionName, "", "", "");
        break;
      case 'Mexico':
        handleContainerClickMexico(nameForAPI, collectionName, "", "", "");
        break;
      default:
        break;
    }
  };

  const renderData = () => {
    if (loading) {
      return <Spinner animation="grow" variant="light" />;
    }

    if (error) {
      return <p>Error fetching data.</p>;
    }

    if (!data || data.length === 0) {
      return <p>No results found.</p>;
    }

    return data.map((item, index) => {
      const title = item.title;
      const link = item.url;
      const date = formatDate(item.created_at);
      const source = item.collection_name;
      const nameForAPI = item.source.toLowerCase().replace(/\s+/g, '_');
      const sourceUrl = generateSourceUrl(source);
      const country = item.country;
      const countryLink = generateCountryLink(country);
      const countryEmoji = getCountryEmoji(country);
      
      return (
        <div key={index}>
          <div id="fullBillFeed">
            <Container id="feedContainer">
            <Card.Title
                id="billBodyTextSource"
                onClick={(e) => {
                  e.preventDefault();
                 
                  window.open(countryLink, "_self");
                }}
              >
                {countryEmoji} {country}<ChevronRight id='clickChevron'></ChevronRight>
              </Card.Title>
              <Card.Title
                id="billBodyTextSource"
                onClick={() => handleContainerClick(country, nameForAPI, item.collection_name)}
              >
                {source}
              </Card.Title>
              <Card.Title
                id="billText"
                onClick={(e) => {
                  e.preventDefault();
                  const linkToOpen = item.document_link || item.download?.pdfLink || link;
                  window.open(linkToOpen, "_blank");
                }}
              >
                {title}
              </Card.Title>
              <Card.Body id="billBodyText">{date}</Card.Body>
              <Button
                id="billLink"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(link, "_blank");
                }}
              >
                <InfoCircle /> Details
              </Button>
              {(item.document_link || item.download?.pdfLink) && (
                <Button
                  id="viewBillButton"
                  onClick={(e) => {
                    e.preventDefault();
                    const linkToOpen = item.document_link || item.download?.pdfLink;
                    window.open(linkToOpen, "_blank");
                  }}
                >
                  <FileText /> Doc
                </Button>
              )}
              <Button
                id="addToCollectionButton"
                onClick={() => {
                  setCollectionModalIsOpen(true);
                  SaveFeedItem(
                    title,
                    link,
                    "N/A",
                    date,
                    "",
                    item.source,
                    "",
                    "",
                    ""
                  );
                }}
              >
                <FolderPlus />
              </Button>
            </Container>
          </div>
        </div>
      );
    });
  };

  return (
    <div id="homebg">
      <div id="searchForm">
        <Form
          className="text-center"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Form.Group className="d-flex justify-content-center align-items-center">
            <Form.Control
              variant="light"
              id="searchInput"
              placeholder="Search..."
              type="text"
              value={searchTerm}
              onChange={handleSearchInputChange}
              color="white"
            />
            <Button id="ggButton" onClick={performSearch}>
              <Search />
            </Button>
          </Form.Group>
        </Form>
      </div>

      {renderData()}

      <Button
        id="standardButton"
        onClick={() => {
          setFeedLength((prevLength) => prevLength + 20);
        }}
      >
        More
      </Button>

      <Modal
        id="fullBillModal"
        show={collectionModalIsOpen}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setCollectionModalIsOpen(false)}
      >
        <Modal.Header id="billModalHeader" closeButton>
          <h3>{storedTitle}</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          {storedDescription}
          {storedDate}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default GenerateFeedRecentAll;