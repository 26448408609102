
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, ReceiptCutoff, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Tree, ChevronRight, GeoAlt } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import DesktopNavbar from './DesktopNavbar';
import MobileTopNavbar from './MobileTopNavbar';
import MobileOffCanvas from './MobileOffCanvas';
import StatePickerElection from './StatePickerElection';
import GenerateFeedStateRepsAll from './GenerateFeedStateRepsAll';
import { CreateFeedItemStateRepsAll } from './Functions';
import StatePickerElectionMobile from './StatePickerElectionMobile';

const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;



export const Election2024 = () =>
 {
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };
   render()
   
   {
  
  return(
    <>
      <BrowserView>
        <div id='homePage'>


        <div id='homebg'>

        <DesktopNavbar/>          
               <div id='sectionHeading'>      
            
              <h3 id='branchHeading' ><b>🇺🇸 2024 General Election</b></h3> 

       
              </div> 
         
                     
              <div id='branchPage'>
        <Container className='bgcolor'>
          <Container id='carouselBufferTwo'>
            <Row>
              <Col id='legislativeColLeft'>
                <div id='columnLefttPadding'>
                  <h4 id='billFeedHeading' onClick={() =>
                    openModal(` 2024 President Candidates`,
                      <GenerateFeedStateRepsAll
                        stateAbbreviation=''
                        candidateType='President' />
                    )}>
                    <b>2024 President Candidates</b>
                  </h4>
                  <Carousel id='carouselInColumn' controls={false}>
                  <Carousel.Item id='legislativeCarousel' interval={carouselInterval} >


<Col >
  <div id='columnLefttPadding'>
   
    <div id='president'></div>
    {CreateFeedItemStateRepsAll('president', 'President', '', 0)}

  </div>
</Col>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval} >


<Col >
<div id='columnLefttPadding'>
 
  <div id='president2'></div>
  {CreateFeedItemStateRepsAll('president2', 'President', '', 7)}

</div>
</Col>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval} >


<Col >
<div id='columnLefttPadding'>
 
  <div id='president3'></div>
  {CreateFeedItemStateRepsAll('president3', 'President', '', 4)}

</div>
</Col>

</Carousel.Item>

                       <Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' onClick={() =>
                    openModal(`2024 Senate Candidates`,
                      <GenerateFeedStateRepsAll
                      stateAbbreviation=''
                        candidateType='President' />
                    )} >View all 2024 President Candidates</Button>
</div>
</Col>

</div>



</Carousel.Item>

                       </Carousel>
                </div>
              </Col>
              <Col id='legislativeColLeft'>
                <div id='columnLefttPadding'>
                  <h4 id='billFeedHeadingElection' onClick={() =>
                    openModal(`2024 U.S. House Candidates`,
                      <GenerateFeedStateRepsAll
                      stateAbbreviation=''
                        candidateType='House' />
                    )}>
                    <b>2024 U.S. House Candidates</b>
                  </h4>
                  <Carousel id='carouselInColumn' controls={false}>
                  <Carousel.Item id='legislativeCarousel' interval={carouselInterval} >


  <Col >
    <div id='columnLefttPadding'>
     
      <div id='house'></div>
      {CreateFeedItemStateRepsAll('house', 'House', '', 0)}

    </div>
</Col>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval} >


<Col >
  <div id='columnLefttPadding'>
   
    <div id='house2'></div>
    {CreateFeedItemStateRepsAll('house2', 'House', '', 1)}

  </div>
</Col>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval} >


<Col >
  <div id='columnLefttPadding'>
   
    <div id='house3'></div>
    {CreateFeedItemStateRepsAll('house3', 'House', '', 3)}

  </div>
</Col>

</Carousel.Item>
                       <Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' onClick={() =>
                    openModal(`2024 U.S. House Candidates`,
                      <GenerateFeedStateRepsAll
                      stateAbbreviation=''
                        candidateType='House' />
                    )} >View all 2024 U.S. House Candidates</Button>
</div>
</Col>

</div>



</Carousel.Item>

                       </Carousel>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
        <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
      </div>

      <div id='branchPage'>
        <Container className='bgcolor'>
          <Container id='carouselBufferTwo'>
            <Row>
            <Col id='legislativeColLeft'>
                <div id='columnLefttPadding'>
                  <h4 id='billFeedHeading' onClick={() =>
                    openModal(`2024 Senate Candidates`,
                      <GenerateFeedStateRepsAll
                      stateAbbreviation=''
                        candidateType='Senate' />
                    )}>
                    <b>2024 U.S. Senate Candidates</b>
                  </h4>
                  <Carousel id='carouselInColumn' controls={false}>
                  <Carousel.Item id='legislativeCarousel' interval={carouselInterval} >


<Col >
  <div id='columnLefttPadding'>
   
    <div id='senate'></div>
    {CreateFeedItemStateRepsAll('senate', 'Senate', '', 0)}

  </div>
</Col>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval} >


<Col >
<div id='columnLefttPadding'>
 
  <div id='senate2'></div>
  {CreateFeedItemStateRepsAll('senate2', 'Senate', '', 1)}

</div>
</Col>

</Carousel.Item>

<Carousel.Item id='legislativeCarousel' interval={carouselInterval} >


<Col >
<div id='columnLefttPadding'>
 
  <div id='senate3'></div>
  {CreateFeedItemStateRepsAll('senate3', 'Senate', '', 2)}

</div>
</Col>

</Carousel.Item>

                       <Carousel.Item interval={carouselInterval}>

<div id='legislativeConInCol'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' onClick={() =>
                    openModal(`2024 Senate Candidates`,
                      <GenerateFeedStateRepsAll
                      stateAbbreviation=''
                        candidateType='Senate' />
                    )} >View all 2024 Senate Candidates</Button>
</div>
</Col>

</div>



</Carousel.Item>

                       </Carousel>
                </div>
              </Col>
              <Col id='legislativeColLeft'>
                <div id='columnLefttPadding'>
              
                </div>
              </Col>
           
            </Row>
          </Container>
        </Container>

      </div>



            <br></br>


<StatePickerElection/>







         
            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
   <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>

            




</div>
         </div>
          

              </BrowserView>

              <MobileView>

              <MobileTopNavbar/>


        <div id='homebg'>
         
        <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
        
            <h5 id='branchHeadingMobile' >🇺🇸 2024 General Election</h5>

          
    
          
            <h5 onClick={() =>
                    openModal(` 2024 President Candidates`,
                      <GenerateFeedStateRepsAll
                        stateAbbreviation=''
                        candidateType='President' />
                    )} id='presidentialFeedHeadingMobile'>2024 President Candidates</h5>
                        
                        <Col>
                        <Carousel controls={false} >
                        <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
                

                  <div class='courtNewsMobile' id='president'></div>
    {CreateFeedItemStateRepsAll('president', 'President', '', 0)}
                </Col>
    </div>
</Carousel.Item>
                        <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
                

                  <div class='courtNewsMobile' id='president2'></div>
    {CreateFeedItemStateRepsAll('president2', 'President', '', 1)}
                </Col>
    </div>
</Carousel.Item>

                        <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
                

                  <div class='courtNewsMobile' id='president3'></div>
    {CreateFeedItemStateRepsAll('president3', 'President', '', 2)}
                </Col>
    </div>
</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' onClick={() =>
                    openModal(` 2024 President Candidates`,
                      <GenerateFeedStateRepsAll
                        stateAbbreviation=''
                        candidateType='President' />
                    )} >View all 2024 President Candidates</Button>
</div>
</Col>

</div>



</Carousel.Item>

                        
                        
                        </Carousel>
                        
                        </Col>


                        <h5 onClick={() =>
                    openModal(` 2024 U.S. House Candidates`,
                      <GenerateFeedStateRepsAll
                        stateAbbreviation=''
                        candidateType='House' />
                    )} id='presidentialFeedHeadingMobile'>2024 U.S. House Candidates</h5>
                        
                        <Col>
                        <Carousel controls={false} >
                        <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
                

                  <div class='courtNewsMobile' id='house'></div>
    {CreateFeedItemStateRepsAll('house', 'House', '', 0)}
                </Col>
    </div>
</Carousel.Item>
                        <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
                

                  <div class='courtNewsMobile' id='house2'></div>
    {CreateFeedItemStateRepsAll('house2', 'House', '', 1)}
                </Col>
    </div>
</Carousel.Item>

                        <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
                

                  <div class='courtNewsMobile' id='house3'></div>
    {CreateFeedItemStateRepsAll('house3', 'House', '', 2)}
                </Col>
    </div>
</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' onClick={() =>
                    openModal(` 2024 U.S. House Candidates`,
                      <GenerateFeedStateRepsAll
                        stateAbbreviation=''
                        candidateType='House' />
                    )} >View all 2024 U.S. House Candidates</Button>
</div>
</Col>

</div>



</Carousel.Item>

                        
                        
                        </Carousel>
                        
                        </Col>


                        <h5 onClick={() =>
                    openModal(` 2024 Senate Candidates`,
                      <GenerateFeedStateRepsAll
                        stateAbbreviation=''
                        candidateType='Senate' />
                    )} id='presidentialFeedHeadingMobile'>2024 U.S. Senate Candidates</h5>
                        
                        <Col>
                        <Carousel controls={false} >
                        <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
                

                  <div class='courtNewsMobile' id='senate'></div>
    {CreateFeedItemStateRepsAll('senate', 'Senate', '', 0)}
                </Col>
    </div>
</Carousel.Item>
                        <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
                

                  <div class='courtNewsMobile' id='senate2'></div>
    {CreateFeedItemStateRepsAll('senate2', 'Senate', '', 1)}
                </Col>
    </div>
</Carousel.Item>

                        <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
                

                  <div class='courtNewsMobile' id='senate3'></div>
    {CreateFeedItemStateRepsAll('senate3', 'Senate', '', 2)}
                </Col>
    </div>
</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' onClick={() =>
                    openModal(` 2024 Senate Candidates`,
                      <GenerateFeedStateRepsAll
                        stateAbbreviation=''
                        candidateType='Senate' />
                    )} >View all 2024 U.S. Senate Candidates</Button>
</div>
</Col>

</div>



</Carousel.Item>

                        
                        
                        </Carousel>
                        
                        </Col>

                        <StatePickerElectionMobile/>


            <Col>

            </Col>

          
         <div id='mobileNavbarSpacer'></div>
            <Navbar id='navbarMobileBottom' fixed="bottom">  

          
 


<div id='navbarSpacer'></div><div id='navbarSpacer'></div>




       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
              
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
          </div>

              </MobileView>
              
    </>
  )
}
 }



  






export default Election2024;