
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, PauseCircle, PlayCircle, People, HeartFill, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import ExecutiveOrders from './ExecutiveOrders';
import PresidentialProclamations from './PresidentialProclamations';
import NationalScienceFoundation from './NationalScienceFoundation';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
  createWhiteHouseFeedMobile,createDepartmentOfState,
  createDepartmentOfDefense, 
  createDepartmentOfJustice, createDepartmentOfTheInterior,
  createDepartmentOfAgriculture, createDepartmentOfCommerce,
  createDepartmentOfCommerceMobile, createDepartmentOfLabor,
  createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
  ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
  , createEnvironmentalProtectionAgency,
  createDepartmentOfTreasury, createNationalScienceFoundation, 
   createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, createFederalRegisterStateDepartment, createFederalRegisterTreasuryDepartment, createFederalRegisterJusticeDepartment, createFederalRegisterInteriorDepartment, createFederalRegisterAgricultureDepartment, createFederalRegisterCommerceDepartment, createFederalRegisterLaborDepartment, createFederalRegisterHealthAndHumanServicesDepartment, createFederalRegisterHousingAndUrbanDevelopmentDepartment, createFederalRegisterHomelandSecurityDepartment, createFederalRegisterEducationDepartment, createFederalRegisterVeteransAffairsDepartment,
   CreateFeedItem, CreateFeedItemBillUpdates, CreateFeedItemWithDocButton,
   CreateFeedItemPresidentialDocuments, CreateFeedItemExecutiveOrders, CreateFeedItemCongressionalReports, CreateFeedItemExecutiveOffice, CreateFeedItemBillWithDescription, CreateFeedAPIDepartmentUS } from './Functions'
import ExecutiveBranchMobile from './ExecutiveBranchMobile';
import DesktopNavbar from './DesktopNavbar';
import DepartmentOfTheInteriorDocs from './DepartmentOfTheInteriorDocs';
import FishingAndWildlifeService from './FishingAndWildlifeService';
import GeologicalSurvey from './GeologicalSurvey';
import InteriorDeptHearings from './InteriorDeptHearings';
import IndianAffairsBureau from './IndianAffairsBureau';
import LandManagementBureau from './LandManagementBureau';
import NationalIndianGamingCommission from './NationalIndianGamingCommission';
import NationalParkService from './NationalParkService';
import ReclamationBureau from './ReclamationBureau';
import SpecialTrusteeForAmericanIndians from './SpecialTrusteeForAmericanIndians';
import SurfaceMiningReclamation from './SurfaceMiningReclamation';
import OceanEnergyManagmentBureau from './OceanEnergyManagmentBureau';
import SafetyAndEnvironmentEnforcement from './SafetyAndEnvironmentEnforcement';
import NaturalResourcesRevenueOffice from './NaturalResourcesRevenueOffice';
import TransportationMobile from './TransportationMobile';
import InteriorMobile from './InteriorMobile';
import DepartmentOfCommerceDocuments from './DepartmentOfCommerceDocuments';
import CensusBureau from './CensusBureau';
import EconomicAnalysisBureau from './EconomicAnalysisBureau';
import EconomicDevelopmentAdministration from './EconomicDevelopmentAdministration';
import EconomicsAndStatistics from './EconomicsAndStatistics';
import ForeignTradeZonesBoard from './ForeignTradeZonesBoard';
import IndustryAndSecurityBureau from './IndustryAndSecurityBureau';
import InternationalTradeAdministration from './InternationalTradeAdministration';
import MinorityBusinessDevelopmentAgency from './MinorityBusinessDevelopmentAgency';
import NationalInstituteOfStandardsAndTech from './NationalInstituteOfStandardsAndTech';
import NationalOceanicAndAtmosphericAdministration from './NationalOceanicAndAtmosphericAdministration';
import NationalTechnicalInformationService from './NationalTechnicalInformationService';
import NationalTelecommunicationsAndInformationAdmin from './NationalTelecommunicationsAndInformationAdmin';
import PatentAndTradeMarkOffice from './PatentAndTradeMarkOffice';
import UnderSecretaryForEconomicAffairs from './UnderSecretaryForEconomicAffairs';
import CommerceMobile from './CommerceMobile';
import GenerateFeedDepartment from './GenerateFeedDepartment';





var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;





  

 


  


export const DepartmentOfCommerceDesk = () =>
 {
   render()
   
   {
    const [show, setShow] = useState(true);

        
    const [fullscreen, setFullScreen] = useState(true);
  
  
    const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
  
    const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
  
  
    const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
    const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
  
  
    const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
    const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
  
    const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
  
    const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
  
    const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  
    const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
  
  
    const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
    const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
 
    const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
    const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
    const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
    const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
    const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
    const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
    const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
    const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
    const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
    const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
    const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
    const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
    const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
    const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
    const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
    const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
    const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
    const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
    const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  
  
    const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
    const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  
  
    const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
    const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
    const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
    const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
    const [commerceModalIsOpen, setCommerceModalIsOpen] = useState(false)
    const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
    const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
    const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
    const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
    const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
    const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
    const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
    const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
    const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
    const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
    const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
    const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)

    const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
    const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
    const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
    const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
    const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
    const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
    const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
    const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
    const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
    const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
    const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)

    const [coastGuardModalIsOpen, setCoastGuardModalIsOpen] = useState(false)
    const [femaModalIsOpen, setFEMAModalIsOpen] = useState(false)
    const [secretServiceModalIsOpen, setSecretServiceModalIsOpen] = useState(false)
    const [tsaModalIsOpen, setTSAModalIsOpen] = useState(false)
    const [immigrationModalIsOpen, setImmigrationModalIsOpen] = useState(false)
    const [usCustomsModalIsOpen, setUSCustomsModalIsOpen] = useState(false)
    const [enforcementsModalIsOpen, setEnforcemnetsModalIsOpen] = useState(false)
    const [homelandDocsModalIsOpen, setHomelandDocsModalIsOpen] = useState(false)
    const [showMissionModal, setMissionModal] = useState(false);

  return(
    <>
      <BrowserView>
        <div id='homePage'>

     

        <div id='homebg'>

          <DesktopNavbar/>
          <div id='feedDisplay' 
                  style={{ padding: '20px', borderRadius: '18px', marginTop: '0em'}}>

<div style={{ position: 'relative' }}>

     <div id='profileImage' style={{ width: '130px', height: '130px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '60px' }}>
     <Image id='profileImage-image' src='/departmentOfCommerce.png' />
   </div>


   <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
   <div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Department of Commerce </h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '16px', width: '80%' }}>
<span> The Department of Commerce is the government agency tasked with creating the conditions for economic growth and opportunity.</span>
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

       <span><Link style={{color: '#21cff5'}} onClick={(e) => {
          e.preventDefault();
          window.open("https://www.commerce.gov/", "_blank");
        }}>
          https://www.commerce.gov/
          </Link></span> 
<br></br>
<Button id='whDocsViewMoreButton' onClick={() =>  setMissionModal(true)}>  <Files size={20}></Files> Read More</Button>
</div>
  </div>

     </div>
     </div>


     </div>

           </div>

           <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showMissionModal} onHide={() => setMissionModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> Department of Commerce</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>The Department of Commerce is the government agency tasked with creating the conditions for economic growth and opportunity.</p>

<p>The department supports U.S. business and industry through a number of services, including gathering economic and demographic data, issuing patents and trademarks, improving understanding of the environment and oceanic life, and ensuring the effective use of scientific and technical resources. The agency also formulates telecommunications and technology policy, and promotes U.S. exports by assisting and enforcing international trade agreements.</p>

<p>The United States Secretary of Commerce oversees a $8.9 billion budget and more than 41,000 employees.</p>


<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=DEPARTMENT%20OF%20COMMERCE,than%2041%2C000%C2%A0%20employees.", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=DEPARTMENT%20OF%20COMMERCE,than%2041%2C000%C2%A0%20employees.
     </Link>
 </div>

       </Modal>
            <br></br>
 
      


     
           
                <div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/census-bureau', "_self")}}><b>Census Bureau</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defCb'></div>
            {CreateFeedAPIDepartmentUS('&table_name=commerce&agency=Census%20Bureau', 'defCb',
              'Census Bureau Documents', 'Executive',
              'Commerce', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={commerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommerceModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Census Bureau</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=commerce&agency=Census%20Bureau'
                name='Census Bureau' branch='Executive' topic='Commerce' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/economic-analysis-bureau', "_self")}}><b>Bureau of Economic Analysis</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defEab'></div>
            {CreateFeedAPIDepartmentUS('&table_name=commerce&agency=Economic%20Analysis%20Bureau', 'defEab',
              'Economic Analysis Bureau Documents', 'Executive',
              'Commerce', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={commerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommerceModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Economic Analysis Bureau</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=commerce&agency=Economic%20Analysis%20Bureau'
                name='Economic Analysis Bureau' branch='Executive' topic='Commerce' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/economic-development-administration', "_self")}}><b>Economic Development Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defEda'></div>
            {CreateFeedAPIDepartmentUS('&table_name=commerce&agency=Economic%20Development%20Administration', 'defEda',
              'Economic Development Administration Documents', 'Executive',
              'Commerce', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={commerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommerceModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Economic Development Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=commerce&agency=Economic%20Development%20Administration'
                name='Economic Development Administration' branch='Executive' topic='Commerce' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/economics-statistics-administration', "_self")}}><b>Economics and Statistics Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defEsa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=commerce&agency=Economics%20and%20Statistics%20Administration', 'defEsa',
              'Economics and Statistics Administration Documents', 'Executive',
              'Commerce', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={commerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommerceModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Economics and Statistics Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=commerce&agency=Economics%20and%20Statistics%20Administration'
                name='Economics and Statistics Administration' branch='Executive' topic='Commerce' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/export-administration-bureau', "_self")}}><b>Export Administration Bureau</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defEab'></div>
            {CreateFeedAPIDepartmentUS('&table_name=commerce&agency=Export%20Administration%20Bureau', 'defEab',
              'Export Administration Bureau Documents', 'Executive',
              'Commerce', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={commerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommerceModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Export Administration Bureau</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=commerce&agency=Export%20Administration%20Bureau'
                name='Export Administration Bureau' branch='Executive' topic='Commerce' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/foreign-trade-zones-board', "_self")}}><b>Foreign-Trade Zones Board</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFtzb'></div>
            {CreateFeedAPIDepartmentUS('&table_name=commerce&agency=Foreign-Trade%20Zones%20Board', 'defFtzb',
              'Foreign-Trade Zones Board Documents', 'Executive',
              'Commerce', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={commerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommerceModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Foreign-Trade Zones Board</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=commerce&agency=Foreign-Trade%20Zones%20Board'
                name='Foreign-Trade Zones Board' branch='Executive' topic='Commerce' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/industry-security-bureau', "_self")}}><b>Industry and Security Bureau</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defIsb'></div>
            {CreateFeedAPIDepartmentUS('&table_name=commerce&agency=Industry%20and%20Security%20Bureau', 'defIsb',
              'Industry and Security Bureau Documents', 'Executive',
              'Commerce', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={commerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommerceModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Industry and Security Bureau</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=commerce&agency=Industry%20and%20Security%20Bureau'
                name='Industry and Security Bureau' branch='Executive' topic='Commerce' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/international-trade-administration', "_self")}}><b>International Trade Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defIta'></div>
            {CreateFeedAPIDepartmentUS('&table_name=commerce&agency=International%20Trade%20Administration', 'defIta',
              'International Trade Administration Documents', 'Executive',
              'Commerce', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={commerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommerceModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>International Trade Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=commerce&agency=International%20Trade%20Administration'
                name='International Trade Administration' branch='Executive' topic='Commerce' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/minority-business-development', "_self")}}><b>Minority Business Development Agency</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defMbda'></div>
            {CreateFeedAPIDepartmentUS('&table_name=commerce&agency=Minority%20Business%20Development%20Agency', 'defMbda',
              'Minority Business Development Agency Documents', 'Executive',
              'Commerce', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={commerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommerceModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Minority Business Development Agency</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=commerce&agency=Minority%20Business%20Development%20Agency'
                name='Minority Business Development Agency' branch='Executive' topic='Commerce' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/nist', "_self")}}><b>National Institute of Standards and Technology</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNist'></div>
            {CreateFeedAPIDepartmentUS('&table_name=commerce&agency=National%20Institute%20of%20Standards%20and%20Technology', 'defNist',
              'National Institute of Standards and Technology Documents', 'Executive',
              'Commerce', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={commerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommerceModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Institute of Standards and Technology</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=commerce&agency=National%20Institute%20of%20Standards%20and%20Technology'
                name='National Institute of Standards and Technology' branch='Executive' topic='Commerce' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/noaa', "_self")}}><b>National Oceanic and Atmospheric Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNoaa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=commerce&agency=National%20Oceanic%20and%20Atmospheric%20Administration', 'defNoaa',
              'National Oceanic and Atmospheric Administration Documents', 'Executive',
              'Commerce', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={commerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommerceModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Oceanic and Atmospheric Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=commerce&agency=National%20Oceanic%20and%20Atmospheric%20Administration'
                name='National Oceanic and Atmospheric Administration' branch='Executive' topic='Commerce' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/ntis', "_self")}}><b>National Technical Information Service</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNtis'></div>
            {CreateFeedAPIDepartmentUS('&table_name=commerce&agency=National%20Technical%20Information%20Service', 'defNtis',
              'National Technical Information Service Documents', 'Executive',
              'Commerce', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={commerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommerceModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Technical Information Service</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=commerce&agency=National%20Technical%20Information%20Service'
                name='National Technical Information Service' branch='Executive' topic='Commerce' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/ntia', "_self")}}><b>National Telecommunications and Information Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNtia'></div>
            {CreateFeedAPIDepartmentUS('&table_name=commerce&agency=National%20Telecommunications%20and%20Information%20Administration', 'defNtia',
              'National Telecommunications and Information Administration Documents', 'Executive',
              'Commerce', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={commerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommerceModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Telecommunications and Information Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=commerce&agency=National%20Telecommunications%20and%20Information%20Administration'
                name='National Telecommunications and Information Administration' branch='Executive' topic='Commerce' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/patent-trademark', "_self")}}><b>Patent and Trademark Office</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defPto'></div>
            {CreateFeedAPIDepartmentUS('&table_name=commerce&agency=Patent%20and%20Trademark%20Office', 'defPto',
              'Patent and Trademark Office Documents', 'Executive',
              'Commerce', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={commerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommerceModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Patent and Trademark Office</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=commerce&agency=Patent%20and%20Trademark%20Office'
                name='Patent and Trademark Office' branch='Executive' topic='Commerce' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/technology-administration', "_self")}}><b>Technology Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defTa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=commerce&agency=Technology%20Administration', 'defTa',
              'Technology Administration Documents', 'Executive',
              'Commerce', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={commerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommerceModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Technology Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=commerce&agency=Technology%20Administration'
                name='Technology Administration' branch='Executive' topic='Commerce' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/travel-tourism-administration', "_self")}}><b>Travel and Tourism Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defTta'></div>
            {CreateFeedAPIDepartmentUS('&table_name=commerce&agency=Travel%20and%20Tourism%20Administration', 'defTta',
              'Travel and Tourism Administration Documents', 'Executive',
              'Commerce', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={commerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommerceModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Travel and Tourism Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=commerce&agency=Travel%20and%20Tourism%20Administration'
                name='Travel and Tourism Administration' branch='Executive' topic='Commerce' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/under-secretary-economic-affairs', "_self")}}><b>Under-Secretary for Economic Affairs</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defUsea'></div>
            {CreateFeedAPIDepartmentUS('&table_name=commerce&agency=Under-Secretary%20for%20Economic%20Affairs', 'defUsea',
              'Under-Secretary for Economic Affairs Documents', 'Executive',
              'Commerce', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={commerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommerceModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Under-Secretary for Economic Affairs</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=commerce&agency=Under-Secretary%20for%20Economic%20Affairs'
                name='Under-Secretary for Economic Affairs' branch='Executive' topic='Commerce' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
        </Col>
      </Row>
    </Container>
  </Container>
</div>


       


   

            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
   <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>



</div>
         </div>
          

              </BrowserView>

              <MobileView>

    <CommerceMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default DepartmentOfCommerceDesk;