// useHandleContainerClick.js
import { useHistory } from 'react-router-dom';

const UseHandleContainerClickJustice = () => {
  const history = useHistory();

  const handleContainerClickJustice = (name, termStart, termEnd, imageSource, description) => {
    // Save the name here or perform any other necessary actions


    // Navigate to the representative page with bioGuideId and name as state
    window.scrollTo(0, 0);
    history.push(`/us/justice/${name}`, { 
      name,
      termStart,
      termEnd,
      imageSource,
      description
    });
  };

  return handleContainerClickJustice;
};

export default UseHandleContainerClickJustice;
