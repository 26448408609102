import React, { useState, useEffect } from 'react';
import { Form, Button, Dropdown } from 'react-bootstrap';
import {  Search } from 'react-bootstrap-icons';
import { useHistory, Link } from 'react-router-dom';


var toggleCountryFlag = ''




const NavbarSearchButtonMobileCanada = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();
  const [toggleCountryFlag, setToggleCountryFlag] = useState('');


  const runSearch = () => {
    localStorage.setItem('previousPageUrl', history.location.pathname);
    const encodedQuery = encodeURIComponent(searchQuery);
    const searchPath = searchQuery
      ? `/search/canada?query=${encodedQuery}&limit=10`
      : '/search';

    history.push(searchPath);
  };
 

  
  const handleFormSubmit = (e) => {
    e.preventDefault();
    runSearch();
  };



  return (
 <>
    <Form className="text-center">
      <Form.Group className="d-flex justify-content-center align-items-center">
      <Dropdown id='mobileDropdown' align='end' drop='down' >
      <Dropdown.Toggle id='searchCountry'>
        🇨🇦
      </Dropdown.Toggle>
      <Dropdown.Menu size='' id='docsDropdown' >
      <Dropdown.Item
                                id="docsDropdownItem"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open('/', "_self");
                                }}
                              >
                                🌐 All Countries
                              </Dropdown.Item>
          <Dropdown.Item id='docsDropdownItem'  onClick={() => window.open('/canada', "_self")}>🇨🇦 Canada</Dropdown.Item>
          {/*onClick={() => setConstitutionModalIsOpen(true)}*/}
          <Dropdown.Item id='docsDropdownItem'  onClick={() => window.open('/mexico', "_self")}>🇲🇽 Mexico</Dropdown.Item>
          <Dropdown.Item id='docsDropdownItem' onClick={() => window.open('/uk', "_self")}>🇬🇧 United Kingdom</Dropdown.Item>
          <Dropdown.Item id='docsDropdownItem' onClick={() => window.open('/us', "_self")} >🇺🇸 United States</Dropdown.Item>
        </Dropdown.Menu>
        </Dropdown>
    <Form.Control
        id="ggSearch"
        placeholder="Search Gov Glance"
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        autoComplete='off'
      />
        <Button size='md' variant="primary" id='ggButton' type='submit' onClick={handleFormSubmit}>
          <Search/>
        </Button>
      </Form.Group>
    </Form>
    </>
  );
};




{/*   Home Country Flag logic

call {homeCountryFlag} in Dropdow.Toggle

haptics click -> <Link to='/us' ... '/mexico' />

    homeCountryFlag =
    {
      localStorage.get('homeCountry') == 'usa' ------ 🇺🇸
      localStorage.get('homeCountry') == 'canada' ------ 🇨🇦
      localStorage.get('homeCountry') == 'mexico' ------ 🇲🇽
      localStorage.get('homeCountry') == 'uk' ------ 🇬🇧
    }

*/}

export default NavbarSearchButtonMobileCanada;