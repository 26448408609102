import React from 'react';
import { Container, Button, Col, Row, Image } from 'react-bootstrap';
import { GeoAlt } from 'react-bootstrap-icons';
import './App.css';

function StatePicker() {
  const pathDepth = window.location.pathname.split('/').length - 1;
  const flagPrefix = pathDepth > 2 ? '../../' : '../';

  return (
    <Container id="stateContainer">
      <Col>
        <h3 id="branchHeading">
          <GeoAlt /> <b id="branchHeadingBold">Select a State</b>
        </h3>

        <Row>
          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/alabama', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}alabamaFlag.png`} /> Alabama
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/alaska', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}alaskaFlag.png`} /> Alaska
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/arizona', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}arizonaFlag.png`} /> Arizona
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/arkansas', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}arkansasFlag.png`} /> Arkansas
          </Button>
        </Row>

        <Row>
          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/california', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}californiaFlag.png`} /> California
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/colorado', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}coloradoFlag.png`} /> Colorado
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/connecticut', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}connecticutFlag.png`} /> Connecticut
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/delaware', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}delawareFlag.png`} /> Delaware
          </Button>
        </Row>

        <Row>
          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/florida', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}floridaFlag.png`} /> Florida
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/georgia', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}georgiaFlag.png`} /> Georgia
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/hawaii', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}hawaiiFlag.png`} /> Hawaii
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/idaho', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}idahoFlag.png`} /> Idaho
          </Button>
        </Row>

        <Row>
          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/illinois', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}illinoisFlag.png`} /> Illinois
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/indiana', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}indianaFlag.png`} /> Indiana
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/iowa', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}iowaFlag.png`} /> Iowa
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/kansas', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}kansasFlag.png`} /> Kansas
          </Button>
        </Row>

        <Row>
          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/kentucky', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}kentuckyFlag.png`} /> Kentucky
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/louisiana', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}louisianaFlag.png`} /> Louisiana
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/maine', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}maineFlag.png`} /> Maine
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/maryland', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}marylandFlag.png`} /> Maryland
          </Button>
        </Row>

        <Row>
          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/massachusetts', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}massachusettsFlag.png`} /> Massachusetts
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/michigan', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}michiganFlag.png`} /> Michigan
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/minnesota', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}minnesotaFlag.png`} /> Minnesota
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/mississippi', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}mississippiFlag.png`} /> Mississippi
          </Button>
        </Row>

        <Row>
          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/missouri', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}missouriFlag.png`} /> Missouri
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/montana', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}montanaFlag.png`} /> Montana
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/nebraska', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}nebraskaFlag.png`} /> Nebraska
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/nevada', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}nevadaFlag.png`} /> Nevada
          </Button>
        </Row>

        <Row>
          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/newhampshire', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}newHampshireFlag.png`} /> New Hampshire
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/newjersey', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}newJerseyFlag.png`} /> New Jersey
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/newmexico', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}newMexicoFlag.png`} /> New Mexico
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/newyork', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}newYorkFlag.png`} /> New York
          </Button>
        </Row>

        <Row>
          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/northcarolina', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}northCarolinaFlag.png`} /> North Carolina
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/northdakota', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}northDakotaFlag.png`} /> North Dakota
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/ohio', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}ohioFlag.png`} /> Ohio
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/oklahoma', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}oklahomaFlag.png`} /> Oklahoma
          </Button>
        </Row>

        <Row>
          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/oregon', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}oregonFlag.png`} /> Oregon
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/pennsylvania', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}pennsylvaniaFlag.png`} /> Pennsylvania
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/rhodeisland', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}rhodeIslandFlag.png`} /> Rhode Island
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/southcarolina', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}southCarolinaFlag.png`} /> South Carolina
          </Button>
        </Row>

        <Row>
          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/southdakota', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}southDakotaFlag.png`} /> South Dakota
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/tennessee', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}tennesseeFlag.png`} /> Tennessee
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/texas', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}texasFlag.png`} /> Texas
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/utah', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}utahFlag.png`} /> Utah
          </Button>
        </Row>

        <Row>
          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/vermont', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}vermontFlag.png`} /> Vermont
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/virginia', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}virginiaFlag.png`} /> Virginia
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/washington', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}washingtonFlag.png`} /> Washington
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/westvirginia', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}westVirginiaFlag.png`} /> West Virginia
          </Button>
        </Row>

        <Row>
          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/wisconsin', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}wisconsinFlag.png`} /> Wisconsin
          </Button>

          <Button size="sm" id="statesDesktopButtons" onClick={(e) => {
            e.preventDefault();
            window.open('/us/wyoming', '_self');
          }}>
            <Image id="stateFlagHomePage" src={`${flagPrefix}wyomingFlag.png`} /> Wyoming
          </Button>
        </Row>
      </Col>
    </Container>
  );
}

export default StatePicker;
