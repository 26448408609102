import React, { useState } from 'react';
import { Navbar, Nav, Button, Dropdown, Image, Row, Col } from 'react-bootstrap';
import { InfoCircle, Folder, HeartFill } from 'react-bootstrap-icons';
import NavbarSearchButton from '../NavbarSearchButton';

// List of all 50 states
const states = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado',
  'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho',
  'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
  'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
  'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada',
  'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
  'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
  'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
  'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia',
  'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];

const formatStateFlagName = (name) =>
  `${name.replace(/\s+/g, '').toLowerCase()}Flag`;

const formatStateNameForURL = (name) => 
  name.toLowerCase().replace(/\s+/g, '_');

// State Navbar Component
const StateDesktopNavbar = ({ state, stateFlag }) => {
  const [selectedState, setSelectedState] = useState(state);

  const handleStateSelect = (newState) => {
    setSelectedState(newState);
    window.open(`/us/${formatStateNameForURL(newState)}`, '_self');
  };

  const handleBranchClick = (branch) => {
    window.open(`/us/${formatStateNameForURL(selectedState)}/${branch}`, '_self');
  };

  return (
    <div className="App">
      <div id="homePage">
        <Navbar id="navbar">
          <Col>
            <Row id="realNavbarTop">
              <Nav>
                <h3 id="branchesHeadingUS">
                  <b id="sloganHeading"> </b>
                </h3>

                <Nav.Link id="navbarAboutDesktop">
                  <Button
                    id="navbarAboutButtonDesktop"
                    onClick={() => window.open('/', '_self')}
                    aria-label="Home"
                  >
                    <Image id="govGlanceLogo" width="22px" src="/favicon.ico" alt="Home Logo" /> Home
                  </Button>
                </Nav.Link>

                <Nav.Link id="navbarAboutDesktop">
                  <Button
                    id="navbarAboutButtonDesktop"
                    onClick={() => window.open('/about', '_self')}
                    aria-label="About"
                  >
                    <InfoCircle /> About
                  </Button>
                </Nav.Link>

                <Nav.Link id="navbarAboutDesktop">
                  <Button
                    id="navbarAboutButtonDesktop"
                    onClick={() => window.open('/collection', '_self')}
                    aria-label="Collection"
                  >
                    <Folder /> Collection
                  </Button>
                </Nav.Link>

                <Nav.Link id="navbarAboutDesktop">
                  <Button
                    id="navbarAboutButtonDesktop"
                    onClick={() =>
                      window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', '_blank')
                    }
                    aria-label="Donate"
                  >
                    <HeartFill id="donateIcon" /> Donate
                  </Button>
                </Nav.Link>

                <NavbarSearchButton />
              </Nav>
            </Row>

            <Row id="testRow">
              <Col xs={12}>
                <Navbar id="bottomNavbar">
                <Nav.Link id='navbarAboutDesktop' >
                          <Dropdown drop="down-centered">

                            <Dropdown.Toggle id='navbarAboutButtonDesktop'>
                              🇺🇸
                            </Dropdown.Toggle>
                            <Dropdown.Menu id="docsDropdown" drop="down-centered">
                            <Dropdown.Item
                                id="docsDropdownItem"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open('/', "_self");
                                }}
                              >
                                🌐 All Countries
                              </Dropdown.Item>
                              <Dropdown.Item
                                id="docsDropdownItem"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open('/canada', "_self");
                                }}
                              >
                                🇨🇦 Canada
                              </Dropdown.Item>
                              <Dropdown.Item
                                id="docsDropdownItem"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open('/mexico', "_self");
                                }}
                              >
                                🇲🇽 Mexico
                              </Dropdown.Item>

                              <Dropdown.Item
                                id="docsDropdownItem"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open('/uk', "_self");
                                }}
                              >
                                🇬🇧 United Kingdom
                              </Dropdown.Item>

                              <Dropdown.Item
                                id="docsDropdownItem"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open('/us', "_self");
                                }}
                              >
                                🇺🇸 United States
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Nav.Link>
                  <Dropdown align="end" className="ml-3">
                    <Dropdown.Toggle id="navbarAboutButtonDesktop" aria-label="Select State">
                      <Image
                        src={`/${formatStateFlagName(selectedState)}.png`}
                        alt={`${selectedState} flag`}
                        width="20"
                        height="12"
                        className="mr-2"
                      />
                      {selectedState}
                    </Dropdown.Toggle>

                    <Dropdown.Menu id="docsDropdown">
                      {states.map((stateName) => (
                        <Dropdown.Item
                          key={stateName}
                          id="docsDropdownItem"
                          onClick={() => handleStateSelect(stateName)}
                        >
                          <Image
                            src={`/${formatStateFlagName(stateName)}.png`}
                            alt={`${stateName} flag`}
                            width="20"
                            height="12"
                            className="mr-2"
                          />
                          {stateName}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* Branches Header */}
                  <div id="branchPageButtonPadding">
                    <h3 id="branchesHeading">
                      <b id="branchHeadingBold">Branches</b>
                    </h3>
                  </div>

                  {/* Branch Navigation Buttons */}
                  <Nav variant="pills" className="button-container-nav">
                    {['legislative', 'executive', 'judicial'].map((branch) => (
                      <Nav.Link key={branch} id="navbarAboutDesktop">
                        <Button
                          id="navbarAboutButtonDesktop"
                          onClick={() => handleBranchClick(branch)}
                          aria-label={`Navigate to ${branch}`}
                        >
                          {branch.charAt(0).toUpperCase() + branch.slice(1)}
                        </Button>
                      </Nav.Link>
                    ))}
                  </Nav>

                  {/* Topics Header */}
                  <div id="branchPageButtonPadding">
                    <h3 id="branchesHeading">
                      <b id="branchHeadingBold">Topics</b>
                    </h3>
                  </div>

                  {/* Elections Button */}
                  <Nav variant="pills" className="button-container-nav">
                    <Nav.Link id="navbarAboutDesktop">
                      <Button
                        id="navbarAboutButtonDesktop"
                        onClick={() => handleBranchClick('elections')}
                        aria-label="Navigate to elections"
                      >
                        Elections
                      </Button>
                    </Nav.Link>
                  </Nav>
                </Navbar>
              </Col>
            </Row>
          </Col>
        </Navbar>
      </div>
    </div>
  );
};

export default StateDesktopNavbar;
