import App from "./App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Alert } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, X } from 'react-bootstrap-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';







    


function CollectionPage(){

  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('');

  function removeFromCollection(indexNumber, saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink) {

    
    
    var collectionTitles = []
    var collectionDetailLinks = []
    var collectionDocLinks = []

  indexNumber++;

    var title = 'title'.concat(indexNumber)
    var detailLink = 'detailLink'.concat(indexNumber)
    var docLink = 'docLink'.concat(indexNumber)

    //collectionTitles.push(saveCollectionTitle)
    //collectionDetailLinks.push(saveCollectionDetailLink)
    //collectionDocLinks.push(saveCollectionDocLink)



    localStorage.removeItem(title, saveCollectionTitle)

    localStorage.removeItem(detailLink, saveCollectionDetailLink)

    localStorage.removeItem(docLink, saveCollectionDocLink)

    var collectionNumber = localStorage.getItem('collectionNumber')

    collectionNumber--






    window.location.reload()
    







    

   
    

    
    

  





    


    //return collectionCounter;



  }
  



 

  const [completeBillFeed, setCompleteBillFeed] = React.useState([]);
  const [docTitle, setDocTitle] = React.useState([]);
  const [docLink, setDocLink] = React.useState([]);
  const [detailLink, setDetailLink] = React.useState([]);


  React.useEffect(async () => {

   

    const fBillFeed = [];
    const fDocTitle = [];
    const fDocLink = []
    const fDetailLink = []


    for (let index = 1; index <= localStorage.getItem('collectionNumber'); index++) {

     const gtitle = localStorage.getItem('title'.concat(index).toString())
      const glink = localStorage.getItem('docLink'.concat(index).toString())
      const gdetail = localStorage.getItem('detailLink'.concat(index).toString())
      const both = gtitle + glink
      fDocTitle.push(gtitle)
      fDocLink.push(glink)
      fBillFeed.push(both)
      fDetailLink.push(gdetail)
      
    }



    setCompleteBillFeed(fBillFeed);
    setDocTitle(fDocTitle);
    setDocLink(fDocLink);
    setDetailLink(fDetailLink)


  }, []);

  const renderData = () => {
    const filteredData = completeBillFeed.filter((f, index) => {
      const title = docTitle[index] || '';
      return title.toLowerCase().includes(searchQuery.toLowerCase());
    });

    return filteredData.map((f, index) => {
      
 

  

      

      const title = docTitle[index];
      const link = docLink[index];
      const detail = detailLink[index]


      if (docTitle[index] == null) {
        
        return <div key={index} ></div>
      }


     




      else if (docLink[index] == 'null'){
        
        return <div key={index} >
          <div id='fullBillFeed'>
            <Container id='feedContainer'>

              <Card.Title id='billText'>{docTitle[index]}</Card.Title>
              
              <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(detailLink[index], "_blank")
              }}><InfoCircle></InfoCircle>Details</Button>
              <Button id='viewBillButton' onClick={() =>


                removeFromCollection(index, localStorage.getItem('title'.concat(index)), localStorage.getItem('detailLink'.concat(index)), null)
                


              }><X></X></Button>
              



            </Container>

          </div>
        </div>
      }

      else  {
        
        return <div key={index} >
          <div id='fullBillFeed'>
            <Container id='feedContainer'>

              <Card.Title id='billText'>{docTitle[index]}</Card.Title>
              <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(detailLink[index], "_blank")
              }}><InfoCircle></InfoCircle>Details</Button>
              <Button id='viewBillButton' onClick={(e) => {
                e.preventDefault();
                window.open(
                  docLink[index], "_blank")
              }}><FileText></FileText>Document</Button>
              <Button id='removeFromCollectionButton' onClick={() =>


                removeFromCollection(index, localStorage.getItem('title'.concat(index)), localStorage.getItem('detailLink'.concat(index)), localStorage.getItem('docLink'.concat(index)))


              }><X></X></Button>


            </Container>
          </div>
        </div>


      }
    
    })
  }

  return (
    <>
 <div id='searchForm'>
        <Form.Control
          id='searchInput'
          placeholder='Search...'
          type='text'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          color='white'
        />
      </div>

      {renderData()}
    </>
  )




}











export default CollectionPage;