
import App from "./App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, useRef } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, Spinner, Image, Carousel } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, FolderPlus, PlayCircle, Search, Files } from 'react-bootstrap-icons'
import './App.css';
import { useHistory } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import debounce from 'lodash.debounce';
import APICongressionalBillPull from "./APICongressionalBillPull";

import SponsoredBills from "./SponsoredBills";
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserView, MobileView } from "react-device-detect";
import MobileOffCanvas from "./MobileOffCanvas";
import useHandleContainerClick from './UseHandleContainerClick';
import MobileTopNavbar from "./MobileTopNavbar";
import MobileBackButton from "./MobileBackButton";
require('dotenv').config();
const apiKey = process.env.REACT_APP_API_KEY;
const dumb = process.env.CONGRESS_GOV;



var storedTitle = ''// title
var storedDetailsLink = ''// details link
var storedDocLink = ''// doc link *
var storedDate = ''// date
var storedNote = ''// note
var storedFeedName = ''// feed name
var storedBranchName = ''// branch name 
var storedTopicName = ''// topic name
var storedDescription = ''// description
var collectionCounter = localStorage.getItem('collectionNumber')



function SaveFeedItem(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, 
  saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription)
{

storedTitle = saveCollectionTitle;
storedDetailsLink = saveCollectionDetailLink;
storedDocLink = saveCollectionDocLink;
storedDate = saveCollectionDate;
storedNote = saveCollectionNote;
storedFeedName = saveCollectionFeedName;
storedBranchName = saveCollectionBranchName;
storedTopicName = saveCollectionTopicName;
storedDescription = saveCollectionDescription;
}


{/*
Expected Payload:

id
country
administrative_division_first_level
administrative_division_second_level
administrative_division_third_level
administrative_division_four_level
branch
topic
guid
category
creator
date
title
long_title
url — source
doc_urls — as a dictionary, link(s) to corresponding document(s)
description
encoded
collected_at
other_data
, branch, topic
*/}

function formatDate(inputDate) {
  const date = new Date(inputDate);
  
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
}
function formatName(fullName) {

  let parts = fullName.split(',');

  let names = parts[1].trim().split(' ');
  let firstName = names[0].toLowerCase();

  let lastName = parts[0].trim().toLowerCase();

  lastName = lastName.replace(/\s+/g, '-');

  let formattedName = `${firstName}-${lastName}`;

  return formattedName;
}



function SenateMembers(apiRequest, name, branch, topic, buttonName) {
  const [sponsoredBillsModalIsOpen, setSponsoredBillsModal] = useState(false)
  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  var [feedLength, setFeedLength] = useState(100);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [memberID, setMemberID] = useState(null);
  const [bioGuideId, setBioGuideId] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleContainerClick = useHandleContainerClick();
  

  // const Test = React.memo(({ bioGuideID }) => {
  //   const [billInfo, setBillInfo] = useState(null);
  //   const apiKey = process.env.REACT_APP_API_KEY;
  
  //   useEffect(() => {
  //     const fetchBillInfo = async () => {
  //       try {
  //         const response = await fetch(`https://api.govglance.org/members/{bio_guid_id}?bio_guide_id=${bioGuideID}&limit=10&skip=0`, {
  //           method: 'GET',
  //           headers: { 
  //             'Content-Type': 'application/json',
  //             "Authorization": "Bearer " + apiKey,
  //           }
  //         });
  //         const data = await response.json();
  //         console.log(data[0]); // Log the data to check if it's returned correctly
  //         setBillInfo(data);
  //       } catch (error) {
  //         console.error('Error fetching bill info:', error);
  //       }
  //     };
    
  //     fetchBillInfo();
  //   }, [apiKey, bioGuideID]);
  
  //   return (
  //     <>
  //       {billInfo ? (
  //         billInfo.map((bill, index) => (
  //           <p key={index}>{bill.role} - <APICongressionalBillPull congressionalBillId={bill.congressional_bill_id}/></p>
  //         ))
  //       ) : (
  //         <span><Spinner/></span>
  //       )}
  //     </>
  //   );
  // });
  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
    
    var searchItem = document.getElementById('noteModalFeedItem').value
  
       collectionCounter = localStorage.getItem('collectionNumber')
   
     collectionCounter++;

     
     var collectionTitles = []
      var collectionDetailLinks = []
     var collectionDocLinks = []
     var collectionNote = []

       var title = 'title'.concat(collectionCounter)
       var detailLink = 'detailLink'.concat(collectionCounter)
       var docLink = 'docLink'.concat(collectionCounter)
       var itemNote = 'itemNote'.concat(collectionCounter)
       var itemDate = 'itemDate'.concat(collectionCounter)
       var feedName = 'feedName'.concat(collectionCounter)
       var branchName = 'branchName'.concat(collectionCounter)
       var topicName = 'topicName'.concat(collectionCounter)
       var itemDescription = 'itemDescription'.concat(collectionCounter)
   
   
     
     
       localStorage.setItem(title,saveCollectionTitle)
       
       localStorage.setItem(detailLink,saveCollectionDetailLink)
      
       localStorage.setItem(docLink,saveCollectionDocLink)
       localStorage.setItem(itemNote,searchItem)
   
       localStorage.setItem(itemDate,saveCollectionDate)
       localStorage.setItem(feedName,saveCollectionFeedName)
       localStorage.setItem(branchName,saveCollectionBranchName)
       localStorage.setItem(topicName,saveCollectionTopicName)
   
       localStorage.setItem(itemDescription,saveCollectionDescription)
     
       localStorage.setItem('collectionNumber', collectionCounter)

  }

  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
const [searchQuery, setSearchQuery] = useState('');
const [schema, setSchema] = useState('default_schema');
const [table, setTable] = useState('default_table');


  const url = `https://api.govglance.org/members/?limit=${feedLength}&skip=0`;

 


  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);
  
      const response = await fetch(`https://api.govglance.org/members/?limit=${feedLength}&skip=0`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + apiKey,
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
  
      const membersData = await response.json();
  
      // Filter out members who are not in the Senate
      const senateMembersData = membersData.filter(member => {
        const latestTerm = member.terms[member.terms.length - 1];
        return latestTerm.chamber === "Senate";
      });
  
      setData(senateMembersData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [feedLength]);


const handleKeyDown = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault(); 
    setSearchPerformed(true); 
    handleSearchButtonClick(); 
  }
};

function chunkArray(array, size) {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArray.push(array.slice(i, i + size));
  }
  return chunkedArray;
}

const handleSearchButtonClick = async () => {
  const requestOptions = {
    method: 'GET',
    headers: { 
      'Content-Type': 'application/json',
      "Authorization": "Bearer "+apiKey,
    },
  };
  setLoading(true);
  setError(null);

  try {
    let url = `https://api.govglance.org/members/united_states_of_america/members?limit=${feedLength}&skip=0&table=member`;
    if (searchTerm) {
      url += `&search=${encodeURIComponent(searchTerm)}`;
    }

    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const searchData = await response.json();

    // Filter out members who are not in the Senate
    const senateMembersData = searchData.filter(member => member.terms[member.terms.length - 1].chamber === "Senate");

    const bioGuideIds = senateMembersData.map(member => member.bio_guide_id);
    const secondApiCalls = bioGuideIds.map(bioGuideId =>
      fetch(`https://api.govglance.org/members/{bio_guid_id}?bio_guide_id=${bioGuideId}&limit=10&skip=0`, requestOptions)
    );

    const secondApiResponses = await Promise.all(secondApiCalls);
    const membersDetailsData = await Promise.all(secondApiResponses.map(response => response.json()));

    const combinedData = senateMembersData.map((member, index) => ({
      ...member,
      details: membersDetailsData[index]
    }));

    setData(combinedData);
  } catch (error) {
    setError(error);
  } finally {
    setLoading(false);
    setSearchPerformed(true);
  }
};

const handleSearchInputChange = (e) => {
  setSearchTerm(e.target.value);
  if (e.target.value === '') {
    // If the search input is empty, reload the initial data
    fetchData();
  }
};


const FeedCarousel = React.memo(({ details }) => {
  return (
    <Carousel controls={false}>
      {details.map((detailChunk, index) => (
        <Carousel.Item key={index}>
          <div>
            {detailChunk.map((detail, detailIndex) => (
              <div key={detailIndex}>
                <Container id='feedContainerNormalView'>
                  <p>{detail.role} - <APICongressionalBillPull congressionalBillId={detail.congressional_bill_id}/></p>
            
                </Container>
                <br></br>
              </div>
            ))}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
});

const handleViewMoreClick = (bioGuideId, firstName, lastName) => {
  setBioGuideId(bioGuideId);
  setFirstName(firstName)
  setLastName(lastName)
  setShowModal(true);

}
  const renderData = () => {
    if (loading) {
      return  <Spinner animation="grow" variant="light" />;
    }

    if (error) {
      return <p>Error: {error.message}</p>;
    }

    if (!searchPerformed || !filteredData || filteredData.length === 0) {
      return data.map((item, index) => {
    
        //console.log(data)
        const bio_guide_id = item.bio_guide_id;
        const titleToDisplay = item.first_name + ' ' + item.last_name;
        const link = 'https://www.congress.gov/member/'+item.last_name+'-'+item.first_name+'/'+item.bio_guide_id;
        //const term = (item.terms.item[0].chamber);
        //const startYear = (item.terms.item[0].startYear);
        const docLink = item.document_link;
        const audioLink = item.audio_url; 
        const date = formatDate(item.created_at);
        const pdfLink = item.download ? item.download.pdfLink : null;
        const title = item.title;
        const status = item.status_title;
        const officialSite = item.official_website_url
        const description = "";
        const feedName = apiRequest.name; 
        const feedBranch = apiRequest.branch; 
        const feedTopic = apiRequest.topic; 
        const docButtonName = apiRequest.buttonName;
        const imageSource = item.depication?.imageUrl;
        const congressionalBillDetails = Array.isArray(item.details) ? item.details.slice(0, 8) : null;
        const latestTerm = item.terms[item.terms.length - 1];
        const congress = latestTerm.congress 
        const startYear = latestTerm.startYear 
        const endYear = latestTerm.endYear 
        const stateName = latestTerm.stateName
        const chamber = latestTerm.chamber;
        const party_history = item.party_history
        const address = item.address_information
       
        return (
          <div id='fullBillFeed' key={index}>
          
            <Container  id='feedContainerNormalView'>
          <div onClick={() => handleContainerClick(item.bio_guide_id,item.first_name + ' ' + item.last_name, 
item.official_website_url, item.depication?.imageUrl, item.terms[item.terms.length - 1].chamber, 
item.terms[item.terms.length - 1].startYear, item.terms[item.terms.length - 1].stateName, item.party_history, item.address_information )}>
    <Row>
    {imageSource && (
      <div id='profileImage'>
      <Image id='profileImage-image' src={imageSource} />
    </div>
    )}
    <div style={{color: 'white', fontSize: '16px'}}>
<span>{titleToDisplay}</span>
</div>
<div  style={{color: 'white', fontSize: '14px'}}> 
 <span> {stateName}  {chamber} - {startYear}</span>
      </div>
            </Row>
       
            </div>

          </Container>
              
            </div>
            
        
          
        );
      });
    } else {
       return data.map((item, index) => {
      
        const bio_guide_id = item.bio_guide_id;
        const titleToDisplay = item.first_name + ' ' + item.last_name;
        const link = 'https://www.congress.gov/member/'+item.last_name+'-'+item.first_name+'/'+item.bio_guide_id;
        //const term = (item.terms.item[0].chamber);
        //const startYear = (item.terms.item[0].startYear);
        const docLink = item.document_link;
        const audioLink = item.audio_url; 
        const date = formatDate(item.created_at);
        const pdfLink = item.download ? item.download.pdfLink : null;
        const title = item.title;
        const status = item.status_title;
        const description = "";
        const feedName = apiRequest.name; 
        const feedBranch = apiRequest.branch; 
        const feedTopic = apiRequest.topic; 
        const docButtonName = apiRequest.buttonName;
        const imageSource = item.depication?.imageUrl;
        const congressionalBillDetails = Array.isArray(item.details) ? item.details.slice(0, 8) : null;
        const latestTerm = item.terms[item.terms.length - 1];
        const congress = latestTerm.congress 
        const startYear = latestTerm.startYear 
        const endYear = latestTerm.endYear 
        const stateName = latestTerm.stateName
        const chamber = latestTerm.chamber;
        const officialSite = item.official_website_url
        
        return (
          <div id='fullBillFeed' key={index}>
            <Container  id='feedContainerNormalView'>
          <div onClick={() => handleContainerClick(item.bio_guide_id,item.first_name + ' ' + item.last_name, 
item.official_website_url, item.depication?.imageUrl, item.terms[item.terms.length - 1].chamber, 
item.terms[item.terms.length - 1].startYear, item.terms[item.terms.length - 1].stateName, item.party_history, item.address_information )}>
    
    {imageSource && (
      <div id='profileImage'>
      <Image id='profileImage-image' src={imageSource} />
    </div>
    )}
    <div style={{color: 'white', fontSize: '16px'}}>
<span>{titleToDisplay}</span>
</div>
<div  style={{color: 'white', fontSize: '14px'}}> 
 <span> {stateName}  {chamber} - {startYear}</span>
      </div>
           
       
            </div>

          </Container>
            
  
          
        </div>
        );
      });
    }
  };

  const renderDataMobile = () => {
    if (loading) {
      return  <Spinner animation="grow" variant="light" />;
    }

    if (error) {
      return <p>Error: {error.message}</p>;
    }

    if (!searchPerformed || !filteredData || filteredData.length === 0) {
      return data.map((item, index) => {
        //console.log(data)
        //console.log(data)
        const bio_guide_id = item.bio_guide_id;
        const titleToDisplay = item.first_name + ' ' + item.last_name;
        const link = 'https://www.congress.gov/member/'+item.last_name+'-'+item.first_name+'/'+item.bio_guide_id;
        //const term = (item.terms.item[0].chamber);
        //const startYear = (item.terms.item[0].startYear);
        const docLink = item.document_link;
        const audioLink = item.audio_url; 
        const date = formatDate(item.created_at);
        const pdfLink = item.download ? item.download.pdfLink : null;
        const title = item.title;
        const status = item.status_title;
        const officialSite = item.official_website_url
        const description = "";
        const feedName = apiRequest.name; 
        const feedBranch = apiRequest.branch; 
        const feedTopic = apiRequest.topic; 
        const docButtonName = apiRequest.buttonName;
        const imageSource = item.depication?.imageUrl;
        const congressionalBillDetails = Array.isArray(item.details) ? item.details.slice(0, 8) : null;
        const latestTerm = item.terms[item.terms.length - 1];
        const congress = latestTerm.congress 
        const startYear = latestTerm.startYear 
        const endYear = latestTerm.endYear 
        const stateName = latestTerm.stateName
        const chamber = latestTerm.chamber;
       //console.log(data)
        return (
          <div id='feedDisplay' key={index}>
          
          <Container  id='feedContainerNormalView'>
          <div onClick={() => handleContainerClick(item.bio_guide_id,item.first_name + ' ' + item.last_name, 
item.official_website_url, item.depication?.imageUrl, item.terms[item.terms.length - 1].chamber, 
item.terms[item.terms.length - 1].startYear, item.terms[item.terms.length - 1].stateName, item.party_history, item.address_information )}>
  <div style={{display: 'flex',
                flexDirection: 'row'}}>
    {imageSource && (
      <div id='profileImage'>
      <Image id='profileImage-image' src={imageSource} />
    </div>
    )}

    <div style={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
    <div style={{color: 'white', fontSize: '18px',
                  fontWeight: 'bold'}}>
<span>{titleToDisplay}</span>
</div>
<div  style={{color: 'white', fontSize: '16px'}}> 
 <span> {stateName}  {chamber} - {startYear}</span>
      </div>
      </div>


      </div>
       
            </div>

          </Container>
            
            
          </div>
          
        );
      });
    } else {
       return data.map((item, index) => {
       
        const bio_guide_id = item.bio_guide_id;
        const titleToDisplay = item.first_name + ' ' + item.last_name;
        const link = 'https://www.congress.gov/member/'+item.last_name+'-'+item.first_name+'/'+item.bio_guide_id;
        //const term = (item.terms.item[0].chamber);
        //const startYear = (item.terms.item[0].startYear);
        const docLink = item.document_link;
        const audioLink = item.audio_url; 
        const date = formatDate(item.created_at);
        const pdfLink = item.download ? item.download.pdfLink : null;
        const title = item.title;
        const status = item.status_title;
        const description = "";
        const feedName = apiRequest.name; 
        const feedBranch = apiRequest.branch; 
        const feedTopic = apiRequest.topic; 
        const docButtonName = apiRequest.buttonName;
        const imageSource = item.depication?.imageUrl;
        const congressionalBillDetails = Array.isArray(item.details) ? item.details.slice(0, 8) : null;
        const latestTerm = item.terms[item.terms.length - 1];
        const congress = latestTerm.congress 
        const startYear = latestTerm.startYear 
        const endYear = latestTerm.endYear 
        const stateName = latestTerm.stateName
        const chamber = latestTerm.chamber;
        const officialSite = item.official_website_url
        
        return (
          <div id='feedDisplay' key={index}>
          
          <Container  id='feedContainerNormalView'>
          <div onClick={() => handleContainerClick(item.bio_guide_id,item.first_name + ' ' + item.last_name, 
item.official_website_url, item.depication?.imageUrl, item.terms[item.terms.length - 1].chamber, 
item.terms[item.terms.length - 1].startYear, item.terms[item.terms.length - 1].stateName, item.party_history, item.address_information )}>
  <div style={{display: 'flex',
                flexDirection: 'row'}}>
    {imageSource && (
      <div id='profileImage'>
      <Image id='profileImage-image' src={imageSource} />
    </div>
    )}

    <div style={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
    <div style={{color: 'white', fontSize: '18px',
                  fontWeight: 'bold'}}>
<span>{titleToDisplay}</span>
</div>
<div  style={{color: 'white', fontSize: '16px'}}> 
 <span> {stateName}  {chamber} - {startYear}</span>
      </div>
      </div>


      </div>
       
            </div>

          </Container>
            
   
          
        </div>
        );
      });
    }
  };
  
  
    if (error) {
      return <div id='fullBillFeed'><p>Error fetching data. </p></div>;
    }
  
    
  
    return (
      <>
      <BrowserView>
  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
  centered onHide={setCollectionModalIsOpen}>
                               
                                <Modal.Header id='billModalHeader' closeButton ><h3>{storedTitle}</h3></Modal.Header>
  
                                <Modal.Body id='descriptionModalBody'>
                                            {storedDescription}
                                            {storedDate}
                                <div id='fullBill'>
                           <Form id='noteModalForm'>
           <Form.Group id='noteModalForm'  className="mb-3" >
             <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
               type="text"
               as={"textarea"}
               rows={2}
               />
               <br></br>
  
           </Form.Group>
           </Form>
  
           <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(storedTitle, storedDetailsLink, storedDocLink, 
    storedDate, storedFeedName, storedBranchName, storedTopicName, storedDescription) ;setCollectionModalIsOpen(false) }} >
      Save To Collection
    </Button>
    </Modal.Footer> 
  
             </div>
             </Modal.Body>
                                </Modal>

  <div id='homebg' >
  <div id='searchFormPage'>
  
  <Form className="text-center" onSubmit={(e) => { e.preventDefault(); }}>
<Form.Group className="d-flex justify-content-center align-items-center">
<Form.Control
variant="light"
id="searchInput"
placeholder="Search..."
type="text"
value={searchTerm}
onChange={handleSearchInputChange}
onKeyDown={handleKeyDown}
color="white"
/>
<Button id='ggButton' onClick={handleSearchButtonClick}>
<Search /> 
</Button>
</Form.Group>
</Form>

</div>
        {renderDataMobile()}
        <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header id='billModalHeader' closeButton><h3>{firstName} {lastName} Sponsored Bills</h3></Modal.Header>


    
          <SponsoredBills bioGuideID={bioGuideId}/>

      </Modal>
        </div>
        <div id='fullBillFeed'>
        <Button id='standardButton' onClick={() => {
                 setFeedLength(feedLength+=40)
                }}>More</Button>
                </div>
                </BrowserView>
                <MobileView>
                <div id='homebg'>
                <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
  centered onHide={setCollectionModalIsOpen}>
                               
                                <Modal.Header id='billModalHeader' closeButton ><h3>{storedTitle}</h3></Modal.Header>
  
                                <Modal.Body id='descriptionModalBody'>
                                            {storedDescription}
                                            {storedDate}
                                <div id='fullBill'>
                           <Form id='noteModalForm'>
           <Form.Group id='noteModalForm'  className="mb-3" >
             <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
               type="text"
               as={"textarea"}
               rows={2}
               />
               <br></br>
  
           </Form.Group>
           </Form>
  
           <Modal.Footer id='addNoteModalFooter'> 
  
  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(storedTitle, storedDetailsLink, storedDocLink, 
    storedDate, storedFeedName, storedBranchName, storedTopicName, storedDescription) ;setCollectionModalIsOpen(false) }} >
      Save To Collection
    </Button>
    </Modal.Footer> 
  
             </div>
             </Modal.Body>
                                </Modal>
                                <br>
                                </br>
                                <br></br>
                               
  <div id='searchFormPage'>
                                <Form className="text-center" onSubmit={(e) => { e.preventDefault(); }}>
    <Form.Group className="d-flex justify-content-center align-items-center">
      <Form.Control
        variant="light"
        id="searchInput"
        placeholder="Search..."
        type="text"
        value={searchTerm}
        onChange={handleSearchInputChange}
        onKeyDown={handleKeyDown}
        color="white"
      />
  <Button id='ggButton' onClick={handleSearchButtonClick}>
    <Search /> 
  </Button>
    </Form.Group>
  </Form>
  </div>
 

    
        {renderDataMobile()}
        <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header id='billModalHeader' closeButton><h3>{firstName} {lastName} Sponsored Bills</h3></Modal.Header>


    
          <SponsoredBills bioGuideID={bioGuideId}/>

      </Modal>
    
    
        <Button id='standardButton' onClick={() => {
                 setFeedLength(feedLength+=40)
                }}>More</Button>
                         <br></br> <br></br><br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
     
            </div>

</Navbar>
          
                  
  </div>    
                  
                  </MobileView>    
  
      </>
    )
  }




export default SenateMembers;