
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter, Offcanvas } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, FileMedicalFill, Coin, Tree, Globe2, GeoAlt,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, HeartFill, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, Circle, List, Info, House, Map, ReceiptCutoff, Search, Bricks, Lightbulb, } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NationalScienceFoundation from './NationalScienceFoundation';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import Outbreaks from './Outbreaks';
import TravelNotices from './TravelNotices';
import StatesModal from './StatesModal';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnOversightAndReform from './HouseCommitteeOnOversightAndReform';
import SecuritiesAndExchangeCommissionSpeechesAndStatments from './SecuritiesAndExchangeCommissionSpeechesAndStatments';
import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnArmedServices from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnTheBudget from './HouseCommitteeOnTheBudget';
import HouseCommitteeOnEducationAndLabor from './HouseCommitteeOnEducationAndLabor';
import HouseFloor from './HouseFloor';
import FoodRecalls from './FoodRecalls';
import FoodSafety from './FoodSafety';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEthics from './HouseCommitteeOnEthics';
import CongressionalHearings from './CongressionalHearings';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import HouseCommitteeOnForeignAffairs from './HouseCommitteeOnForeignAffairs';
import HouseCommitteeOnHomelandSecurity from './HouseCommitteeOnHomelandSecurity';
import HouseCommitteeOnTheJudiciary from './HouseCommitteeOnTheJudiciary';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import HouseCommitteeOnHouseAdministration from './HouseCommitteeOnHouseAdministration';
import CongressionalBudgetOffice from './CongressionalBudgetOffice';
import PresidentialProclamations from './PresidentialProclamations';
import ExecutiveOrders from './ExecutiveOrders';
import SecuritiesAndExchangeCommissionPressReleases from './SecuritiesAndExchangeCommissionPressReleases';
import CDCNewsroom from './CDCNewsroom';
import HouseCommitteeOnIntelligence from './HouseCommitteeOnIntelligence';
import UNTopStories from './UNTopStories';
import
 {supremeCourtSearch, whiteHouseSearch, billFeedModal, 
  createHouseFloorToday, createSenateFloorToday, createTrendingBills,
  createNationalAeronauticsAndSpaceAdministration, 
  createEnvironmentalProtectionAgency, createDepartmentOfHomelandSecurity,
  createDepartmentOfVeteransAffairs, createDepartmentOfEducation,
  createDepartmentOfHousingAndUrbanDevelopment, 
  createDepartmentOfHealthAndHumanServices, createDepartmentOfLabor
, createDepartmentOfCommerce, createDepartmentOfAgriculture,
createDepartmentOfTheInterior, createDepartmentOfJustice,
createDepartmentOfTransportation, createDepartmentOfDefense,
createDepartmentOfTreasury, createDepartmentOfEnergy,
createDepartmentOfState, createBillsEnrolledMobile,
createBillsEnrolled, createNewLaws, createGAOReports,
createExecutiveOrders, createPresidentialProclamations, createCongressionalHearings,
createCongressionalBudgetOfficeMobile, createCongressionalBudgetOffice,
createHouseCommitteeOversightAndReform,
createHouseCommitteeOnNaturalResources,
createHouseCommitteeOnTheJudiciary,
createHouseCommitteeOnHouseAdministration,
createHouseCommitteeOnHomelandSecurity,
createHouseCommitteeOnForeignAffairs,
createHouseCommitteeOnFinancialServices,
createHouseCommitteeOnEthics,
createHouseCommitteeOnEnergyAndCommerce,
createHouseCommitteeOnEducationAndLabor,
createHouseCommitteeOnTheBudget,
createHouseCommitteeOnArmedServices,
createHouseCommitteeOnAppropriations,
createHouseCommitteeOnAgriculture,
createReports,
createBillFeedTwoMobile,
createBillFeedTwo,
createBillFeed, createWhiteHouseFeedMobile
, createWhiteHouseFeed, createWhiteHouseDocuments, 
createBillsPresentedFeed, createCourtAppealsMobile,
createCourtAppeals, createUSCourtNewsFeedMobile,
createUSCourtNewsFeed, saveToCollection,
createFullBillFeed, ListItem, Speak, feedText, variableTest, myMethod, myMethod2, createEconomicIndicators, 
createSecuritiesAndExchangeCommissionPressReleases, createNationWeatherServices, createCDCNewsroom,
createSecuritiesAndExchangeCommissionSpeechesAndStatements, createNationalScienceFoundation, createHouseCommitteeOnIntelligence,
 createUNTopStories, createCDCOutbreaks, createCDCTravelNotices, createFoodRecalls, createFoodSafety, createAlabamaBills, createAlaskaBills} from './Functions'
import HomeMobile from './HomeMobile';

import EconomicIndicators from './EconomicIndicators';
import Alaska from './AlaskaBills'
import AlaskaHomeMobile from './AlaskaHomeMobile';










var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;









  











  
export const MobileOffCanvas= () =>   {
  render()
  
 
  {
    const [offcanvasIsOpen, setOffcanvasIsOpen] = useState(false)
  
  const [show, setShow] = useState(true);
  
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);

  
  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
  const [courtOpinionsModalIsOpen, setCourtOpinionsModalIsOpen] = useState(false)
  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
  const [secSAndSModalIsOpen, setSECSAndSModalIsOpen] = useState(false)
  const [economicIndicatorsModalIsOpen, setEconomicIndicatorsModalIsOpen] = useState(false)
  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
  const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
  const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
  const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
  const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
  const [trendingBillsOptionSix, setTrendingBillsOptionSixModalIsOpen ] = useState(false)
  const [trendingBillsOptionSeven, setTrendingBillsOptionSevenModalIsOpen ] = useState(false)
  const [trendingBillsOptionEight, setTrendingBillsOptionEightModalIsOpen ] = useState(false)
  const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
  const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
  const [trendingBillsOptionEleven, setTrendingBillsOptionElevenModalIsOpen ] = useState(false)
  const [trendingBillsOptionTweleve, setTrendingBillsOptionTweleveModalIsOpen ] = useState(false)
  const [trendingBillsOptionThirteen, setTrendingBillsOptionThirteenModalIsOpen ] = useState(false)
  const [trendingBillsOptionFourteen, setTrendingBillsOptionFourteenModalIsOpen ] = useState(false)
  const [trendingBillsOptionFifteen, setTrendingBillsOptionFifteenModalIsOpen ] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
    const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
    const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
    const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
    const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
    const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
    const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
    const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
    const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
    const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
    const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
    const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
    const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
    const [houseCommitteeOnOversightAndReformModalIsOpen, setHouseCommitteeOnOversightAndReformModalIsOpen] = useState(false)
    const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
    const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
    const [cdcNewsroomModalIsOpen, setCDCNewsroomModalIsOpen] = useState(false)
    const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
    const [unTopStoriesModalIsOpen, setUNTopStoriesModalIsOpen] = useState(false)
    const [houseCommitteeOnIntelligenceModalIsOpen, setHouseCommitteeOnIntelligenceModalIsOpen] = useState(false)
    const [travelNoticesModalIsOpen, setTravelNoticesModalIsOpen] = useState(false)
  
    const [foodRecallsModalIsOpen, setFoodRecallsModalIsOpen] = useState(false)
    const [foodSafetyModalIsOpen, setFoodSafetyModalIsOpen] = useState(false)

    const [outbreaksModalIsOpen, setOutbreaksModalIsOpen] = useState(false)
    const [congressionalHearingsModalIsOpen, setCongressionalHearingsModalIsOpen] = useState(false)

    const [houseFloorModalIsOpen, setHouseFloorModalIsOpen] = useState(false)



  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [secPressModalIsOpen, setSECPressModalIsOpen] = useState(false)
  const [appModalIsOpen, setAppModalIsOpen] = useState(false)

  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(true)

  const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  const [countriesModalIsOpen, setCountriesModalIsOpen] = useState(false)

 

   








  
  
  

{
  


  return (
    
    <>

    

    <Button id='offcanvasButton' onClick={() => setOffcanvasIsOpen(true)} ><List id='offCanvasIcon' size={30} ></List></Button>
    
    
<div id='homebg'>
<Offcanvas id='mobileOffcanvas' scroll='false'  placement='end' show={offcanvasIsOpen} onHide={() => setOffcanvasIsOpen(false)}  >
<Offcanvas.Header  closeButton>

</Offcanvas.Header>
<Offcanvas.Body >

<Nav>
 
    <h3 id='offcanvasDropdownHeaderThree' onClick={() => setCountriesModalIsOpen(true)}> 🇺🇸 United States </h3>
    <Dropdown.Divider color='white'  />
    <h3 id='offcanvasDropdownHeaderFour'>Branches</h3>

    <Link id='offcanvasDropdownItem' to='/us/legislative'> <Pen/> Legislative</Link>
    <Link id='offcanvasDropdownItem' to='/us/executive'> <People/>Executive</Link>
    <Link id='offcanvasDropdownItem' to='/us/judicial'> <svg xmlns="http://www.w3.org/2000/svg" height="27.54px" viewBox="0 -960 960 960" width="27.54px" fill="#FFFFFF"><path d="M160-120v-80h480v80H160Zm226-194L160-540l84-86 228 226-86 86Zm254-254L414-796l86-84 226 226-86 86Zm184 408L302-682l56-56 522 522-56 56Z"/></svg>Judicial</Link>
    <Dropdown.Divider color='white'  />
    <h3 id='offcanvasDropdownHeaderFour'>Topics</h3>
    <Link id='offcanvasDropdownItem' to='/us/defense'><ShieldShaded/> Defense</Link>
    <Link id='offcanvasDropdownItem' to='/us/economy'><Coin/> Economy</Link>
    
    <Link id='offcanvasDropdownItem' to='/us/elections'><ReceiptCutoff/> Elections</Link>
    <Link id='offcanvasDropdownItem' to='/us/environment'> <Tree/> Environment</Link>
    <Link id='offcanvasDropdownItem' to='/us/foreignaffairs'> <Globe2/> Foreign Affairs</Link>
    <Link id='offcanvasDropdownItem' to='/us/health'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart-pulse" viewBox="0 0 16 16">
  <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053.918 3.995.78 5.323 1.508 7H.43c-2.128-5.697 4.165-8.83 7.394-5.857q.09.083.176.171a3 3 0 0 1 .176-.17c3.23-2.974 9.522.159 7.394 5.856h-1.078c.728-1.677.59-3.005.108-3.947C13.486.878 10.4.28 8.717 2.01zM2.212 10h1.315C4.593 11.183 6.05 12.458 8 13.795c1.949-1.337 3.407-2.612 4.473-3.795h1.315c-1.265 1.566-3.14 3.25-5.788 5-2.648-1.75-4.523-3.434-5.788-5"/>
  <path d="M10.464 3.314a.5.5 0 0 0-.945.049L7.921 8.956 6.464 5.314a.5.5 0 0 0-.88-.091L3.732 8H.5a.5.5 0 0 0 0 1H4a.5.5 0 0 0 .416-.223l1.473-2.209 1.647 4.118a.5.5 0 0 0 .945-.049l1.598-5.593 1.457 3.642A.5.5 0 0 0 12 9h3.5a.5.5 0 0 0 0-1h-3.162z"/>
</svg> Health</Link>
    <Link id='offcanvasDropdownItem' to='/us/infrastructure'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
  <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
  <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3z"/>
</svg> Infrastructure</Link>
    <Link id='offcanvasDropdownItem' to='/us/justice'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-arms-up" viewBox="0 0 16 16">
  <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
  <path d="m5.93 6.704-.846 8.451a.768.768 0 0 0 1.523.203l.81-4.865a.59.59 0 0 1 1.165 0l.81 4.865a.768.768 0 0 0 1.523-.203l-.845-8.451A1.5 1.5 0 0 1 10.5 5.5L13 2.284a.796.796 0 0 0-1.239-.998L9.634 3.84a.7.7 0 0 1-.33.235c-.23.074-.665.176-1.304.176-.64 0-1.074-.102-1.305-.176a.7.7 0 0 1-.329-.235L4.239 1.286a.796.796 0 0 0-1.24.998l2.5 3.216c.317.316.475.758.43 1.204Z"/>
</svg> Justice</Link>
    <Link id='offcanvasDropdownItem' to='/us/science&tech'> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-rocket-takeoff" viewBox="0 0 16 16">
  <path d="M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z"/>
  <path d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z"/>
  <path d="M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z"/> </svg>Science & Tech</Link>

  <Link  id='offcanvasDropdownItem' to='/us/regulations'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16">
  <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"/>
  <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
</svg> Regulations</Link>

<Link  id='offcanvasDropdownItem' to='/us/immigration'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-passport" viewBox="0 0 16 16">
  <path d="M8 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6M6 8a2 2 0 1 1 4 0 2 2 0 0 1-4 0m-.5 4a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z"/>
  <path d="M3.232 1.776A1.5 1.5 0 0 0 2 3.252v10.95c0 .445.191.838.49 1.11.367.422.908.688 1.51.688h8a2 2 0 0 0 2-2V4a2 2 0 0 0-1-1.732v-.47A1.5 1.5 0 0 0 11.232.321l-8 1.454ZM4 3h8a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1"/>
</svg> Immigration</Link>
    <h3 id='offcanvasDropdownHeaderTwo' onClick={() => setStatesModalIsOpen(true)}><GeoAlt/> States <ChevronRight id='clickChevron'></ChevronRight></h3>

    <h3 id='offcanvasDropdownHeaderTwo' onClick={() => setCountriesModalIsOpen(true)}><Map/> Countries <ChevronRight id='clickChevron'></ChevronRight></h3>


    <h3 id='offcanvasDropdownHeader'>More</h3>
    <Link id='offcanvasDropdownItem' to='/'><House/> Home</Link>
  
    <Link id='offcanvasDropdownItem' to='/collection'><Folder/> Collection</Link>
    <Link id='offcanvasDropdownItem' to='/search'><Search/> Search</Link>
      <Link id='offcanvasDropdownItem' onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}}><Info/> About</Link>
                         <Link id='offcanvasDropdownItem' onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}}><Info/> Privacy</Link>
      <Link id='offcanvasDropdownItem' onClick={() => setAppModalIsOpen(true)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
</svg>Apps</Link>
      <Link id='offcanvasDropdownItem' onClick={() =>  window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")}
      ><HeartFill id='donateIcon'/> Donate</Link>
 







</Nav>

</Offcanvas.Body>

</Offcanvas>
</div>

<Modal id='collectionsModal' show={collectionsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionsModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='collectionModalHeader' closeButton><h3>Collection</h3></Modal.Header>
            <CollectionPage />
          </Modal>

<Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
            <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance is a civic engagement tool designed to help citizens 
                  get official government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official government 
              sources.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit dedicated to creating free educational tools and resources. <a id='aboutLink' href='https://govglance.foundation/'>https://govglance.foundation/</a>
              </p>
             
              <Button id='allPageButtonMobile' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>

          <Modal id='aboutModal' show={appModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAppModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>Download Gov Glance</h3></Modal.Header>
            <div id='homebg'>
            <Container>
  <Col>
  <Row id='statesRow'>
  <Button size='sm' id='appModalButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('https://apps.apple.com/us/app/gov-glance-news/id6448072618', "_blank") }}><svg id='appIcons' xmlns="http://www.w3.org/2000/svg" class="ionicon" height={24} fill='white' viewBox="0 0 512 512"><path d="M256 32C132.26 32 32 132.26 32 256s100.26 224 224 224 224-100.26 224-224S379.74 32 256 32zm-85 321.89a15.48 15.48 0 01-13.46 7.65 14.91 14.91 0 01-7.86-2.16 15.48 15.48 0 01-5.6-21.21l15.29-25.42a8.73 8.73 0 017.54-4.3h2.26c11.09 0 18.85 6.67 21.11 13.13zm129.45-50l-100.13.11h-66.55a15.46 15.46 0 01-15.51-16.15c.32-8.4 7.65-14.76 16-14.76h48.24l57.19-97.35-18.52-31.55C217 137 218.85 127.52 226 123a15.57 15.57 0 0121.87 5.17l9.9 16.91h.11l9.91-16.91A15.58 15.58 0 01289.6 123c7.11 4.52 8.94 14 4.74 21.22l-18.52 31.55-18 30.69-39.09 66.66v.11h57.61c7.22 0 16.27 3.88 19.93 10.12l.32.65c3.23 5.49 5.06 9.26 5.06 14.75a13.82 13.82 0 01-1.17 5.17zm77.75.11h-27.11v.11l19.82 33.71a15.8 15.8 0 01-5.17 21.53 15.53 15.53 0 01-8.08 2.27A15.71 15.71 0 01344.2 354l-29.29-49.86-18.2-31L273.23 233a38.35 38.35 0 01-.65-38c4.64-8.19 8.19-10.34 8.19-10.34L333 273h44.91c8.4 0 15.61 6.46 16 14.75A15.65 15.65 0 01378.23 304z"/></svg>iOS</Button>
                 

<Button size='sm' id='appModalButtons'  onClick={(e) => {
                        e.preventDefault();
                        window.open('https://play.google.com/store/apps/details?id=com.govglance1&hl=en_US&gl=US', "_blank") }}><svg id='appIcons' xmlns="http://www.w3.org/2000/svg" class="ionicon" fill='white' height={24} viewBox="0 0 512 512"><path d="M48 59.49v393a4.33 4.33 0 007.37 3.07L260 256 55.37 56.42A4.33 4.33 0 0048 59.49zM345.8 174L89.22 32.64l-.16-.09c-4.42-2.4-8.62 3.58-5 7.06l201.13 192.32zM84.08 472.39c-3.64 3.48.56 9.46 5 7.06l.16-.09L345.8 338l-60.61-57.95zM449.38 231l-71.65-39.46L310.36 256l67.37 64.43L449.38 281c19.49-10.77 19.49-39.23 0-50z"/></svg>Android</Button>
</Row>
</Col>
</Container>
</div>
          </Modal>


<Modal id='departmentOfStateModal' show={statesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setStatesModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3><GeoAlt/>Select a State</h3></Modal.Header>

  

          

  
  

  <div id='homebg'>  
  <Container>
  <Col>
  <Row id='statesRow'>
  <Button size='sm' id='statesModalButtons'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/alabama', '_self')
                    }}> <Image id='stateFlagMobile'  src='../alabamaFlag.png'></Image> Alabama</Button>
                      <Button size='sm' id='statesModalButtons'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/alaska', '_self')
                    }}><Image id='stateFlagMobile'  src='../alaskaFlag.png'></Image> Alaska</Button>

<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/arizona', '_self')
                    }}> <Image id='stateFlagMobile'  src='../arizonaFlag.png'></Image> Arizona</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/arkansas', '_self')
                    }}><Image id='stateFlagMobile'  src='../arkansasFlag.png'></Image> Arkansas</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/california', '_self')
                    }}><Image id='stateFlagMobile'  src='../californiaFlag.png'></Image> California</Button>


<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/colorado', '_self')
                    }}><Image id='stateFlagMobile'  src='../coloradoFlag.png'></Image> Colorado</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/connecticut', '_self')
                    }}><Image id='stateFlagMobile'  src='../connecticutFlag.png'></Image> Connecticut</Button>

<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/delaware', '_self')
                    }}><Image id='stateFlagMobile'  src='../delawareFlag.png'></Image> Delaware</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/florida', '_self')
                    }}><Image id='stateFlagMobile'  src='../floridaFlag.png'></Image> Florida</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/georgia', '_self')
                    }}><Image id='stateFlagMobile'  src='../georgiaFlag.png'></Image> Georgia</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/hawaii', '_self')
                    }}><Image id='stateFlagMobile'  src='../hawaiiFlag.png'></Image> Hawaii</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/idaho', '_self')
                    }}> <Image id='stateFlagMobile'  src='../idahoFlag.png'></Image> Idaho</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/illinois', '_self')
                    }}> <Image id='stateFlagMobile'  src='../illinoisFlag.png'></Image> Illinois</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/indiana', '_self')
                    }}><Image id='stateFlagMobile'  src='../indianaFlag.png'></Image> Indiana</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/iowa', '_self')
                    }}><Image id='stateFlagMobile'  src='../iowaFlag.png'></Image> Iowa</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/kansas', '_self')
                    }}><Image id='stateFlagMobile'  src='../kansasFlag.png'></Image> Kansas</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/kentucky', '_self')
                    }}><Image id='stateFlagMobile'  src='../kentuckyFlag.png'></Image> Kentucky</Button>

                   
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/louisiana', '_self')
                    }}><Image id='stateFlagMobile'  src='../louisianaFlag.png'></Image> Louisiana</Button>
</Row>

<Row>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/maine', '_self')
                    }}><Image id='stateFlagMobile'  src='../maineFlag.png'></Image> Maine</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/maryland', '_self')
                    }}><Image id='stateFlagMobile'  src='../marylandFlag.png'></Image> Maryland</Button>

<Button size='sm' id='statesModalButtons'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/massachusetts', '_self')
                    }}><Image id='stateFlagMobile'  src='../massachusettsFlag.png'></Image> Massachusetts</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/michigan', '_self')
                    }}><Image id='stateFlagMobile'  src='../michiganFlag.png'></Image> Michigan</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/minnesota', '_self')
                    }}><Image id='stateFlagMobile'  src='../minnesotaFlag.png'></Image> Minnesota</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/mississippi', '_self')
                    }}><Image id='stateFlagMobile'  src='../mississippiFlag.png'></Image> Mississippi</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/missouri', '_self')
                    }}><Image id='stateFlagMobile'  src='../missouriFlag.png'></Image> Missouri</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/montana', '_self')
                    }}><Image id='stateFlagMobile'  src='../montanaFlag.png'></Image> Montana</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/nebraska', '_self')
                    }}><Image id='stateFlagMobile'  src='../nebraskaFlag.png'></Image> Nebraska</Button>
</Row>

<Row>                    
                    <Button size='sm' id='statesModalButtons'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/nevada', '_self')
                    }}><Image id='stateFlagMobile'  src='../nevadaFlag.png'></Image> Nevada</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/newhampshire', '_self')
                    }}><Image id='stateFlagMobile'  src='../newHampshireFlag.png'></Image> New Hampshire</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/newjersey', '_self')
                    }}><Image id='stateFlagMobile'  src='../newJerseyFlag.png'></Image> New Jersey</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/newmexico', '_self')
                    }}><Image id='stateFlagMobile'  src='../newMexicoFlag.png'></Image> New Mexico</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/newyork', '_self')
                    }}><Image id='stateFlagMobile'  src='../newYorkFlag.png'></Image> New York</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/northcarolina', '_self')
                    }}><Image id='stateFlagMobile'  src='../northCarolinaFlag.png'></Image>North Carolina</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/northdakota', '_self')
                    }}><Image id='stateFlagMobile'  src='../northDakotaFlag.png'></Image>North Dakota</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/ohio', '_self')
                    }}><Image id='stateFlagMobile'  src='../ohioFlag.png'></Image>Ohio</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/oklahoma', '_self')
                    }}><Image id='stateFlagMobile'  src='../oklahomaFlag.png'></Image> Oklahoma</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/oregon', '_self')
                    }}><Image id='stateFlagMobile'  src='../oregonFlag.png'></Image> Oregon</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/pennsylvania', '_self')
                    }}><Image id='stateFlagMobile'  src='../pennsylvaniaFlag.png'></Image> Pennsylvania</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/rhodeisland', '_self')
                    }}><Image id='stateFlagMobile'  src='../rhodeIslandFlag.png'></Image> Rhode Island</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/southcarolina', '_self')
                    }}><Image id='stateFlagMobile'  src='../southCarolinaFlag.png'></Image> South Carolina</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/southdakota', '_self')
                    }}><Image id='stateFlagMobile'  src='../southDakotaFlag.png'></Image> South Dakota</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/tennessee', '_self')
                    }}><Image id='stateFlagMobile'  src='../tennesseeFlag.png'></Image> Tennessee</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/texas', '_self')
                    }}><Image id='stateFlagMobile'  src='../texasFlag.png'></Image> Texas</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/utah', '_self')
                    }}><Image id='stateFlagMobile'  src='../utahFlag.png'></Image> Utah</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/vermont', '_self')
                    }}><Image id='stateFlagMobile'  src='../vermontFlag.png'></Image> Vermont</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/virginia', '_self')
                    }}><Image id='stateFlagMobile'  src='../virginiaFlag.png'></Image> Virginia</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/washington', '_self')
                    }}><Image id='stateFlagMobile'  src='../washingtonFlag.png'></Image> Washington</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/westvirginia', '_self')
                    }}><Image id='stateFlagMobile'  src='../westVirginiaFlag.png'></Image> West Virginia</Button>
</Row>

<Row>
<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/wisconsin', '_self')
                    }}><Image id='stateFlagMobile'  src='../wisconsinFlag.png'></Image>Wisconsin</Button>

<Button size='sm' id='statesModalButtons' onClick={(e) => {
                      e.preventDefault();
                      window.open('/us/wyoming', '_self')
                    }}><Image id='stateFlagMobile'  src='../wyomingFlag.png'></Image>Wyoming</Button>

</Row>
 
 
 
 
 </Col>
 </Container>
 </div>
                  
      
             
</Modal>


<Modal id='departmentOfStateModal' show={countriesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCountriesModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3><GeoAlt/>Select a Country</h3></Modal.Header>


  <div id='homebg'>  
  <Container>
  <Col>
  <Row id='statesRow'>

<Button size='sm' id='selectHomeCountry'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/canada', '_self')
                    }}>🇨🇦 Canada</Button>

<Button size='sm' id='selectHomeCountry'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/mexico', '_self')
                    }}>🇲🇽 Mexico</Button>

<Button size='sm' id='selectHomeCountry'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/uk', '_self')
                    }}> 🇬🇧 United Kingdom</Button>


                <Button size='sm' id='selectHomeCountry'  onClick={(e) => {
                      e.preventDefault();
                      window.open('/us', '_self')
                    }}> 🇺🇸 United States</Button>
                     

                    </Row>
                      </Col>
                      </Container>
                      </div>
     </Modal>  
    </>

     
    
  );





                    }
                  }
                }




export default MobileOffCanvas;
