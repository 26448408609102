import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Coin, Tree, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import  "../../App.css";
import 'bootstrap/dist/css/bootstrap.min.css'

import Axios from 'axios'
import { render } from '@testing-library/react';




import CanadaMobileTopNavbar from '../CanadaMobileTopNavbar';
import { CreateFeedItem } from '../../Functions';
import GDPUpdates from '../../GDPUpdates';

import MobileOffCanvas from '../../MobileOffCanvas';
import CanadaBusinessNews from './CanadaBusinessNews';
import StandingCommitteeOnFinance from '../Legislative/StandingCommitteeOnFinance';
import StandingCommitteeOnInternationalTrade from '../Legislative/StandingCommitteeOnInternationalTrade';
import MobileOffCanvasCanada from '../../MobileOffCanvasCanada';


var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;




export const CanadaEconomyMobile = () =>   {
    render()
 
    {
      const [show, setShow] = useState(true);

      
      const [fullscreen, setFullScreen] = useState(true);
    
    
      const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
    
      const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
    
    
      const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
      const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
    
    
      const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
      const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
    
      const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
    
      const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
    
      const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
    
      const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
    
    
      const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
      const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
      const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
      const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
      const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
      const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
      const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
      const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
      const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
      const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
      const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
      const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
      const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
      const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
      const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
      const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
      const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
      const [beaModalIsOpen, setBEAModalIsOpen] = useState(false)
      const [secPressModalIsOpen, setSECPressModalIsOpen] = useState(false)
    
      const [gdpUpdatesModalIsOpen, setGDPUpdatesModalIsOpen] = useState(false)
      const [inflationUpdatesModalIsOpen, setInflationUpdatesModalIsOpen] = useState(false)
      const [wageGrowthModalIsOpen, setWageGrowthModalIsOpen] = useState(false)
      const [bureauOfLaborStatisticsModalIsOpen, setBureauOfLaborStatisticsModalIsOpen] = useState(false)
      const [irsDocumentsModalIsOpen, setIRSDocumentsModalIsOpen] = useState(false)
      const [governmentContractsModalIsOpen, setGovernmentContractsModalIsOpen] = useState(false)
    
    
        const [secSAndSModalIsOpen, setSECSAndSModalIsOpen] = useState(false)
      const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
      const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    
      const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
      const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
      const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
      const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
      const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
      const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
      const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
      const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
      const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
      const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
      const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
      const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
      const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
      const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
      const [houseCommitteeOnSmallBusinessModalIsOpen, setHouseCommitteeOnSmallBusinessModalIsOpen] = useState(false)
      const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
      const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
    
      const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
      const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
    
    
    
      const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
      const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
      const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
    

      const [officeOfManagementModalIsOpen, setOfficeOfManagmentModalIsOpen] = useState(false)
      const [energyMaterialsmMdalIsOpen, setEnergyMaterialsModalIsOpen] = useState(false)
      const [interstateMaterialsModalIsOpen, setInterstateMaterialsModalIsOpen] = useState(false)
      const [budgetMaterialsModalIsOpen, setBudgetMaterialsModalIsOpen] = useState(false)
      const [educationMaterialsModalIsOpen, setEducationMaterialsModalIsOpen] = useState(false)
      const [financialServicesMaterialsModalIsOpen, setFinancialServicesMaterialsModalIsOpen] = useState(false)
      const [smallBusinessMaterialsModalIsOpen, setSmallBusinessMaterialsModalIsOpen] = useState(false)
      const [senateSmallBusinessMaterialsModalIsOpen, setSenateSmallBusinessMaterialsModalIsOpen] = useState(false)
      const [senateCommitteeOnEnergyModalIsOpen, setSenateCommitteeOnEnergyModalIsOpen] = useState(false)
      const [senateBudgetMaterialsModalIsOpen, setSenateBudgetMaterialsModalIsOpen] = useState(false)
      const [senateFinanceMaterialsModalIsOpen, setSenateFinaceMaterialsModalIsOpen] = useState(false)
      const [senateTransportationMaterialsModalIsOpen, setSenateTransportationMaterialsModalIsOpen] = useState(false)
      const [senateCommitteeOnTheBudgetModalIsOpen, setSenateCommitteeOnTheBudgetModalIsOpen] = useState(false)
      const [senateHousingMaterialsModalIsOpen, setSenateHousingMaterialsModalIsOpen] = useState(false)
      const [commerceDocsModalIsOpen, setCommerceDocsModalIsOpen] = useState(false)
      const [censusBureauModalIsOpen, setCensusBureauModalIsOpen] = useState(false)
      const [economicAnalysisModalIsOpen, setEconomicAnalysisModalIsOpen] = useState(false)
      const [ecoDevelopmentModalIsOpen, setEconDevelopmentModalIsOpen] = useState(false)
      const [enforcementsModalIsOpen, setEnforcemnetsModalIsOpen] = useState(false)
      const nullEntry = [];
      const [notifications, setNotifications] = useState(nullEntry);
  
      const [topic, setTopic] = useState(null);
  
  
      const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
      const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
      const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
      const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
      const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
      const [trendingBillsOptionSix, setTrendingBillsOptionSixModalIsOpen ] = useState(false)
      const [trendingBillsOptionSeven, setTrendingBillsOptionSevenModalIsOpen ] = useState(false)
      const [trendingBillsOptionEight, setTrendingBillsOptionEightModalIsOpen ] = useState(false)
      const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
      const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
      const [trendingBillsOptionEleven, setTrendingBillsOptionElevenModalIsOpen ] = useState(false)
      const [trendingBillsOptionTweleve, setTrendingBillsOptionTweleveModalIsOpen ] = useState(false)
      const [trendingBillsOptionThirteen, setTrendingBillsOptionThirteenModalIsOpen ] = useState(false)
  

    //const [ModalIsOpen, setModalIsOpen] = useState(false)
    
    var branch = "all";


  
    return(
       <>
      <CanadaMobileTopNavbar/>

       <div id='homebg'>
      
     


           <h5 id='branchHeadingMobile' ><Coin></Coin> Economy </h5>

           {/*GPD Updates */}
           <Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionThreeModalIsOpen(true)} >Standing Committee on Finance</h5>
  <div id='committee7'></div>
  {CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/fina.xml', 'committee7', 'Standing Committee on Finance', 'Legislative', 'N/A', StandingCommitteeOnFinance)}


                 <Modal id='fullBillModal' show={trendingBillsOptionThree} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionThreeModalIsOpen(false)}>
                         <Modal.Header id='billModalHeader' closeButton><h3> Standing Committee on Finance</h3></Modal.Header>
                       <StandingCommitteeOnFinance/>
                       </Modal>






                 </Col>


                 <Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionFourModalIsOpen(true)}>Standing Committee on International Trade News</h5>
  <div id='committee4'></div>
  {CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/ciit.xml', 'committee4', 'Standing Committee on International Trade', 'Legislative', 'N/A', StandingCommitteeOnInternationalTrade)}

  <Modal id='fullBillModal' show={trendingBillsOptionFour} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionFourModalIsOpen(false)}>
                         <Modal.Header id='billModalHeader' closeButton><h3> Standing Committee on Natural Resources</h3></Modal.Header>
                       <StandingCommitteeOnInternationalTrade/>
                       </Modal>




</Col>

     
           <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvasCanada/>
          
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
         
          </div>

          </>
  )
}
 }



  






export default CanadaEconomyMobile;

