import React, { useState } from 'react';
import { Container, Col, Carousel, Button, Modal } from 'react-bootstrap';
import { ChevronRight, ReceiptCutoff } from 'react-bootstrap-icons';
import CreateFeedItemStateRepsForPage from './CreateFeedItemStateRepsForPage';
import GenerateFeedStateRepsAll from './GenerateFeedStateRepsAll';

const StateFeedComponentMobile = ({ stateName }) => {
  const getFormattedStateName = (stateName) => {
    return stateName.toLowerCase().replace(/\s+/g, '_');
  };

  const stateAbbreviations = {
    alabama: 'AL',
    alaska: 'AK',
    // ... (other states)
    wisconsin: 'WI',
    wyoming: 'WY',
  };

  const formattedStateName = getFormattedStateName(stateName);
  const stateAbbreviation = stateAbbreviations[formattedStateName];

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };

  const CustomModal = ({ isOpen, onClose, title, content }) => (
    <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
      <Modal.Header id='billModalHeader' closeButton>
        <h3>{title}</h3>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
    </Modal>
  );

  const mobileCarouselInterval = 30000;

  return (
    <>
      <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
      
      <h5 id='branchHeadingMobile' onClick={(e) => {
        e.preventDefault();
        window.open(`/us/${formattedStateName}/elections`, "_self");
      }}>
        <ReceiptCutoff /> Elections <ChevronRight id='clickChevron' />
      </h5>

      {/* House Candidates Carousel */}
      <h5 onClick={() =>
        openModal('2024 House Candidates', 
          <GenerateFeedStateRepsAll stateAbbreviation={stateAbbreviation} candidateType='House' />
        )} 
        id='presidentialFeedHeadingMobile'>
        2024 House Candidates
      </h5>

      <Col>
        <Carousel controls={false}>
          {[0, 1, 2].map(skipValue => (
            <Carousel.Item interval={mobileCarouselInterval} key={`house-carousel-${skipValue}`}>
              <div id='legislativeConMobile'>
                <Col>
                  <div className='courtNewsMobile' id={`house${skipValue + 1}`}></div>
                  <CreateFeedItemStateRepsForPage divID={`house${skipValue + 1}`} candidateType='House' stateAbbreviation={stateAbbreviation} skip={skipValue} />
                </Col>
              </div>
            </Carousel.Item>
          ))}
          <Carousel.Item interval={mobileCarouselInterval}>
            <div id='legislativeConMobile'>
              <Col>
                <div id='viewFullPageButton'>
                  <Button id='viewFullLegislativePageButtonMobile' size='lg' onClick={() =>
                    openModal('2024 House Candidates', 
                      <GenerateFeedStateRepsAll stateAbbreviation={stateAbbreviation} candidateType='House' />
                    )}>
                    View all 2024 House Candidates
                  </Button>
                </div>
              </Col>
            </div>
          </Carousel.Item>
        </Carousel>
      </Col>

      {/* Senate Candidates Carousel */}
      <h5 onClick={() =>
        openModal('2024 Senate Candidates', 
          <GenerateFeedStateRepsAll stateAbbreviation={stateAbbreviation} candidateType='Senate' />
        )} 
        id='presidentialFeedHeadingMobile'>
        2024 Senate Candidates
      </h5>

      <Col>
        <Carousel controls={false}>
          {[0, 1, 2].map(skipValue => (
            <Carousel.Item interval={mobileCarouselInterval} key={`senate-carousel-${skipValue}`}>
              <div id='legislativeConMobile'>
                <Col>
                  <div className='courtNewsMobile' id={`senate${skipValue + 1}`}></div>
                  <CreateFeedItemStateRepsForPage divID={`senate${skipValue + 1}`} candidateType='Senate' stateAbbreviation={stateAbbreviation} skip={skipValue} />
                </Col>
              </div>
            </Carousel.Item>
          ))}
          <Carousel.Item interval={mobileCarouselInterval}>
            <div id='legislativeConMobile'>
              <Col>
                <div id='viewFullPageButton'>
                  <Button id='viewFullLegislativePageButtonMobile' size='lg' onClick={() =>
                    openModal('2024 Senate Candidates', 
                      <GenerateFeedStateRepsAll stateAbbreviation={stateAbbreviation} candidateType='Senate' />
                    )}>
                    View all 2024 Senate Candidates
                  </Button>
                </div>
              </Col>
            </div>
          </Carousel.Item>
        </Carousel>
      </Col>
    </>
  );
};

export default StateFeedComponentMobile;
