import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import { Spinner, Card, Button, Container, Modal, Form } from 'react-bootstrap';
import { InfoCircle, FileText, FolderPlus, Search } from 'react-bootstrap-icons';
import './App.css';
import useHandleContainerClickRecents from './UseHandleContainerClickRecents';
import UseHandleContainerClickRecentsUK from './UseHandleContainerClickRecentsUK';

require('dotenv').config();
const apiKey = process.env.REACT_APP_API_KEY;
const requestOptions = {
    method: 'GET',
    headers: { 
      'Content-Type': 'application/json',
      "Authorization": "Bearer "+apiKey,
  },
  };

  var storedTitle = ''// title
var storedDetailsLink = ''// details link
var storedDocLink = ''// doc link *
var storedDate = ''// date
var storedNote = ''// note
var storedFeedName = ''// feed name
var storedBranchName = ''// branch name 
var storedTopicName = ''// topic name
var storedDescription = ''// description
var collectionCounter = localStorage.getItem('collectionNumber')

function generateSourceUrl(source) {

  const lowercaseSource = source.toLowerCase();

  if (lowercaseSource === 'alabama news' || lowercaseSource === 'supreme court of alabama' || lowercaseSource === 'alabama bills') {
    return '/alabama';
  }
  
  if (lowercaseSource === 'alaska news' || lowercaseSource === 'supreme court of alaska' || lowercaseSource === 'alaska bills') {
    return '/alaska';
  }
  
  if (lowercaseSource === 'arizona news' || lowercaseSource === 'supreme court of arizona' || lowercaseSource === 'arizona bills') {
    return '/arizona';
  }

  if (lowercaseSource === 'arkansas news' || lowercaseSource === 'supreme court of arkansas' || lowercaseSource === 'arkansas bills') {
    return '/arkansas';
  }
  
  if (lowercaseSource === 'california news' || lowercaseSource === 'supreme court of california' || lowercaseSource === 'california bills') {
    return '/california';
  }

  if (lowercaseSource === 'colorado news' || lowercaseSource === 'supreme court of colorado' || lowercaseSource === 'colorado bills') {
    return '/colorado';
  }
  
  if (lowercaseSource === 'connecticut news' || lowercaseSource === 'supreme court of connecticut' || lowercaseSource === 'connecticut bills') {
    return '/connecticut';
  }
  
  if (lowercaseSource === 'delaware news' || lowercaseSource === 'supreme court of delaware' || lowercaseSource === 'delaware bills') {
    return '/delaware';
  }
  
  if (lowercaseSource === 'florida news' || lowercaseSource === 'supreme court of florida' || lowercaseSource === 'florida bills') {
    return '/florida';
  }
  
  if (lowercaseSource === 'georgia news' || lowercaseSource === 'supreme court of georgia' || lowercaseSource === 'georgia bills') {
    return '/georgia';
  }

  if (lowercaseSource === 'hawaii news' || lowercaseSource === 'supreme court of hawaii' || lowercaseSource === 'hawaii bills') {
    return '/hawaii';
  }
  
  if (lowercaseSource === 'idaho news' || lowercaseSource === 'supreme court of idaho' || lowercaseSource === 'idaho bills') {
    return '/idaho';
  }
  
  if (lowercaseSource === 'illinois news' || lowercaseSource === 'supreme court of illinois' || lowercaseSource === 'illinois bills') {
    return '/illinois';
  }
  
  if (lowercaseSource === 'indiana news' || lowercaseSource === 'supreme court of indiana' || lowercaseSource === 'indiana bills') {
    return '/indiana';
  }
  
  if (lowercaseSource === 'iowa news' || lowercaseSource === 'supreme court of iowa' || lowercaseSource === 'iowa bills') {
    return '/iowa';
  }
  
  if (lowercaseSource === 'kansas news' || lowercaseSource === 'supreme court of kansas' || lowercaseSource === 'kansas bills') {
    return '/kansas';
  }
  
  if (lowercaseSource === 'kentucky news' || lowercaseSource === 'supreme court of kentucky' || lowercaseSource === 'kentucky bills') {
    return '/kentucky';
  }
  
  if (lowercaseSource === 'louisiana news' || lowercaseSource === 'supreme court of louisiana' || lowercaseSource === 'louisiana bills') {
    return '/louisiana';
  }
  
  if (lowercaseSource === 'maine news' || lowercaseSource === 'supreme court of maine' || lowercaseSource === 'maine bills') {
    return '/maine';
  }
  
  if (lowercaseSource === 'maryland news' || lowercaseSource === 'supreme court of maryland' || lowercaseSource === 'maryland bills') {
    return '/maryland';
  }

  if (lowercaseSource === 'massachusetts news' || lowercaseSource === 'supreme court of massachusetts' || lowercaseSource === 'massachusetts bills') {
    return '/massachusetts';
  }
  
  if (lowercaseSource === 'michigan news' || lowercaseSource === 'supreme court of michigan' || lowercaseSource === 'michigan bills') {
    return '/michigan';
  }
  
  if (lowercaseSource === 'minnesota news' || lowercaseSource === 'supreme court of minnesota' || lowercaseSource === 'minnesota bills') {
    return '/minnesota';
  }
  
  if (lowercaseSource === 'mississippi news' || lowercaseSource === 'supreme court of mississippi' || lowercaseSource === 'mississippi bills') {
    return '/mississippi';
  }
  
  if (lowercaseSource === 'missouri news' || lowercaseSource === 'supreme court of missouri' || lowercaseSource === 'missouri bills') {
    return '/missouri';
  }
  
  if (lowercaseSource === 'montana news' || lowercaseSource === 'supreme court of montana' || lowercaseSource === 'montana bills') {
    return '/montana';
  }
  
  if (lowercaseSource === 'nebraska news' || lowercaseSource === 'supreme court of nebraska' || lowercaseSource === 'nebraska bills') {
    return '/nebraska';
  }
  
  if (lowercaseSource === 'nevada news' || lowercaseSource === 'supreme court of nevada' || lowercaseSource === 'nevada bills') {
    return '/nevada';
  }
  
  if (lowercaseSource === 'new hampshire news' || lowercaseSource === 'supreme court of new hampshire' || lowercaseSource === 'new hampshire bills') {
    return '/newhampshire';
  }
  
  if (lowercaseSource === 'new jersey news' || lowercaseSource === 'supreme court of new jersey' || lowercaseSource === 'new jersey bills') {
    return '/newjersey';
  }

  if (lowercaseSource === 'new mexico news' || lowercaseSource === 'supreme court of new mexico' || lowercaseSource === 'new mexico bills') {
    return '/newmexico';
  }
  
  if (lowercaseSource === 'new york news' || lowercaseSource === 'supreme court of new york' || lowercaseSource === 'new york bills') {
    return '/newyork';
  }
  
  if (lowercaseSource === 'north carolina news' || lowercaseSource === 'supreme court of north carolina' || lowercaseSource === 'north carolina bills') {
    return '/northcarolina';
  }
  
  if (lowercaseSource === 'north dakota news' || lowercaseSource === 'supreme court of north dakota' || lowercaseSource === 'north dakota bills') {
    return '/northdakota';
  }
  
  if (lowercaseSource === 'ohio news' || lowercaseSource === 'supreme court of ohio' || lowercaseSource === 'ohio bills') {
    return '/ohio';
  }
  
  if (lowercaseSource === 'oklahoma news' || lowercaseSource === 'supreme court of oklahoma' || lowercaseSource === 'oklahoma bills') {
    return '/oklahoma';
  }
  
  if (lowercaseSource === 'oregon news' || lowercaseSource === 'supreme court of oregon' || lowercaseSource === 'oregon bills') {
    return '/oregon';
  }
  
  if (lowercaseSource === 'pennsylvania news' || lowercaseSource === 'supreme court of pennsylvania' || lowercaseSource === 'pennsylvania bills') {
    return '/pennsylvania';
  }
  
  if (lowercaseSource === 'rhode island news' || lowercaseSource === 'supreme court of rhode island' || lowercaseSource === 'rhode island bills') {
    return '/rhodeisland';
  }

  
  if (lowercaseSource === 'south carolina news' || lowercaseSource === 'supreme court of south carolina' || lowercaseSource === 'south carolina bills') {
    return '/southcarolina';
  }

  if (lowercaseSource === 'south dakota news' || lowercaseSource === 'supreme court of south dakota' || lowercaseSource === 'south dakota bills') {
    return '/southdakota';
  }
  
  if (lowercaseSource === 'tennessee news' || lowercaseSource === 'supreme court of tennessee' || lowercaseSource === 'tennessee bills') {
    return '/tennessee';
  }
  
  if (lowercaseSource === 'texas news' || lowercaseSource === 'supreme court of texas' || lowercaseSource === 'texas bills') {
    return '/texas';
  }
  
  if (lowercaseSource === 'utah news' || lowercaseSource === 'supreme court of utah' || lowercaseSource === 'utah bills') {
    return '/utah';
  }
  
  if (lowercaseSource === 'vermont news' || lowercaseSource === 'supreme court of vermont' || lowercaseSource === 'vermont bills') {
    return '/vermont';
  }
  
  if (lowercaseSource === 'virginia news' || lowercaseSource === 'supreme court of virginia' || lowercaseSource === 'virginia bills') {
    return '/virginia';
  }
  
  if (lowercaseSource === 'washington news' || lowercaseSource === 'supreme court of washington' || lowercaseSource === 'washington bills') {
    return '/washington';
  }
  
  if (lowercaseSource === 'west virginia news' || lowercaseSource === 'supreme court of west virginia' || lowercaseSource === 'west virginia bills') {
    return '/westvirginia';
  }
  
  if (lowercaseSource === 'wisconsin news' || lowercaseSource === 'supreme court of wisconsin' || lowercaseSource === 'wisconsin bills') {
    return '/wisconsin';
  }
  
  if (lowercaseSource === 'wyoming news' || lowercaseSource === 'supreme court of wyoming' || lowercaseSource === 'wyoming bills') {
    return '/wyoming';
  }

  if (lowercaseSource == 'committee on agriculture meeting feed' || lowercaseSource == 'council on environmental quality' 
  || lowercaseSource == 'usda latest news releases' || lowercaseSource == 'usda latest news releases' 
  || lowercaseSource == 'united states environmental protection agency'
  || lowercaseSource == 'national weather service update')
  {
      return '/environment'

  }

  if (lowercaseSource == 'cdc newsroom' || lowercaseSource == 'food and drug adminstration: food recalls'
  || lowercaseSource == 'u.s. department of health and human services' 
  || lowercaseSource == 'food and drug adminstration: medical watch'
  || lowercaseSource == 'food and drug administration: medical watch'  )
  {
      return '/health'

  }

  if (lowercaseSource == 'bureau of economic analysis' || lowercaseSource == 'council of economic advisers'
  || lowercaseSource == 'federal reserve bank of america: gross domestic product' 
  || lowercaseSource == 'federal reserve bank of alanta: inflation update'
  || lowercaseSource == 'federal reserve bank of atlanta: inflation update'
  || lowercaseSource == 'u.s. securties and exchange commission: speeches and statements'
  || lowercaseSource == 'u.s. securties and exchange commission: updates'
  || lowercaseSource == 'federal reserve bank of atlanta: wage growth')
  {
      return '/economy'

  }

  if (lowercaseSource == 'department of interior news')
  {
      return '/departmentoftheinterior'

  }


  if (lowercaseSource == 'u.s. department of justice')
  {
      return '/departmentofjustice'

  }

  if (lowercaseSource == 'domestic policy council' || lowercaseSource == 'gender policy council'
  || lowercaseSource == 'national economic council' || lowercaseSource == 'national security council'
  || lowercaseSource == 'office of intergovernmental affairs' || lowercaseSource == 'office of management and budget'
  || lowercaseSource == 'office of national drug control policy' || lowercaseSource == 'office of public engagement'
  || lowercaseSource == 'office of science and technology policy' || lowercaseSource == 'office of the national cyber director'
  || lowercaseSource == 'presidential personnell office')
  {
      return '/executiveoffice'

  }

  if (lowercaseSource == 'department of state news'  || lowercaseSource == 'u.s. department of state')
  {
      return '/departmentofstate'

  }

  if (lowercaseSource == 'permanent select committee on intelligence' || lowercaseSource == 'committee on appropriations meeting feed'
  || lowercaseSource == 'committee on armed services meeting feed' || lowercaseSource == 'committee on education and the workforce meeting feed'
  || lowercaseSource == 'committee on energy and commerce meeting feed' || lowercaseSource == 'committee on ethics meeting feed'
  || lowercaseSource == 'committee on financial services meeting feed' || lowercaseSource == 'committee on foreign affairs meeting feed'
  || lowercaseSource == 'committee on homeland security meeting feed' || lowercaseSource == 'committee on house administration meeting feed'
  || lowercaseSource == 'committee on natural resources meeting feed' || lowercaseSource == 'committee on oversight and accountability meeting feed'
  
  || lowercaseSource == 'committee on rules meeting feed' || lowercaseSource == 'committee on small business meeting feed'
  || lowercaseSource == 'committee on the budget meeting feed' || lowercaseSource == 'committee on the judiciary meeting feed'
  || lowercaseSource == 'committee on transportation and infrastructure meeting feed' || lowercaseSource == 'committee on veterans affairs meeting feed')
  {
      return '/house'

  }
  
  if (lowercaseSource == 'congressional bills' || lowercaseSource == 'congressional bills enrolled' || lowercaseSource == 'congressional committee prints' 
  || lowercaseSource == 'congressional documents' || lowercaseSource == 'congressional hearings' || lowercaseSource == 'congressional reports'
  || lowercaseSource == 'congressional serial set' || lowercaseSource == 'public and private laws' 
  || lowercaseSource == 'public private laws' )
  {
      return '/legislative'

  }

  if (lowercaseSource == 'executive orders'|| lowercaseSource == 'president executive orders'
  || lowercaseSource == 'compilation of presidential documents' || lowercaseSource == 'the white house')
  {
      return '/executive'

  }

  if (lowercaseSource == 'supreme court arguments' || lowercaseSource == 'supreme_court_opinions'  
  || lowercaseSource == 'supreme court opinions')
  {
      return '/judicial'

  }

  if (lowercaseSource == 'department of defense' || lowercaseSource == 'the united states marine corps'
  || lowercaseSource == 'national guard' || lowercaseSource == 'u.s. space force' )
  {
      return '/departmentofdefense'

  }

  if (lowercaseSource == 'veterans affairs news' )
  {
      return '/departmentofveteransaffairs'
  }

  if (lowercaseSource == 'the national aeronautics and space administration' || lowercaseSource == 'national science foundation' )
  {
      return '/science&tech'
  }


}

function SaveFeedItem(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, 
  saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription)
{

storedTitle = saveCollectionTitle;
storedDetailsLink = saveCollectionDetailLink;
storedDocLink = saveCollectionDocLink;
storedDate = saveCollectionDate;
storedNote = saveCollectionNote;
storedFeedName = saveCollectionFeedName;
storedBranchName = saveCollectionBranchName;
storedTopicName = saveCollectionTopicName;
storedDescription = saveCollectionDescription;
}

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
}

function GenerateFeedRecentUK() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  var [feedLength, setFeedLength] = useState(10);
  const handleContainerClick = UseHandleContainerClickRecentsUK();
  
  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
    
    var searchItem = document.getElementById('noteModalFeedItem').value
  
       collectionCounter = localStorage.getItem('collectionNumber')
   
     collectionCounter++;

     
     var collectionTitles = []
      var collectionDetailLinks = []
     var collectionDocLinks = []
     var collectionNote = []

       var title = 'title'.concat(collectionCounter)
       var detailLink = 'detailLink'.concat(collectionCounter)
       var docLink = 'docLink'.concat(collectionCounter)
       var itemNote = 'itemNote'.concat(collectionCounter)
       var itemDate = 'itemDate'.concat(collectionCounter)
       var feedName = 'feedName'.concat(collectionCounter)
       var branchName = 'branchName'.concat(collectionCounter)
       var topicName = 'topicName'.concat(collectionCounter)
       var itemDescription = 'itemDescription'.concat(collectionCounter)
   
   
     
     
       localStorage.setItem(title,saveCollectionTitle)
       
       localStorage.setItem(detailLink,saveCollectionDetailLink)
      
       localStorage.setItem(docLink,saveCollectionDocLink)
       localStorage.setItem(itemNote,searchItem)
   
       localStorage.setItem(itemDate,saveCollectionDate)
       localStorage.setItem(feedName,saveCollectionFeedName)
       localStorage.setItem(branchName,saveCollectionBranchName)
       localStorage.setItem(topicName,saveCollectionTopicName)
   
       localStorage.setItem(itemDescription,saveCollectionDescription)
     
       localStorage.setItem('collectionNumber', collectionCounter)

  }

  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
       
  const apiURL = `https://api.govglance.org/posts/recent/schema?limit=${feedLength}&skip=0&schema=united_kingdom`;

  useEffect(() => {
    setLoading(true);
    fetch(apiURL, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setData(data);
        //console.log(data)
        setLoading(false);
        setError(null);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, [feedLength]);



  const performSearch = () => {
    // Filter data based on search term
    // Assuming 'data' is an array of objects with a 'title' property
    const filteredData = data.filter(item =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setData(filteredData);
  };

  const handleSearchInputChange = event => {
    setSearchTerm(event.target.value);
  };

  const renderData = () => {
    let filteredData = data;
  
    // If there's a search term, filter the data
    if (searchTerm) {
      filteredData = data.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  
    if (loading) {
      return <Spinner animation="grow" variant="light" />;
    }
  
    if (error) {
      return <p>Error fetching data.</p>;
    }
  
    if (!filteredData || filteredData.length === 0) {
      return <p>No results found.</p>;
    }
  
    return filteredData.map((item, index) => {
      const title = item.title;
      const link = item.url;
      const date = formatDate(item.created_at);
      const source = item.collection_name;
      const nameForAPI = item.source.toLowerCase().replace(/\s+/g, '_');
      const sourceUrl = generateSourceUrl(source);
      return (
        <div key={index}>
          <div id='fullBillFeed'>
            <Container id='feedContainer'>
            {/* onClick={() => handleHapticsClick(() => {})} */}
            <Card.Title id='billBodyTextSource' onClick={() => handleContainerClick(nameForAPI, item.collection_name, "", "", "")}>{source}</Card.Title>
            <Card.Title id='billText' onClick={(e) => {
  e.preventDefault();
  const linkToOpen = item.document_link || item.download?.pdfLink || link;
  window.open(linkToOpen, "_blank");
}}>
  {title}
</Card.Title>

              <Card.Body id='billBodyText'>{date}</Card.Body>
            
              <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(link, "_blank");
              }}><InfoCircle></InfoCircle> Details</Button>
              {(item.document_link || item.download?.pdfLink) && (
                <Button id='viewBillButton' onClick={(e) => {
                  e.preventDefault();
                  const linkToOpen = item.document_link || item.download?.pdfLink;
                  window.open(linkToOpen, "_blank");
                }}>
                  <FileText></FileText> Doc
                </Button>
              )}
              <Button id='addToCollectionButton' onClick={() => {
                setCollectionModalIsOpen(true);
                SaveFeedItem(title, link, "N/A", date, '', item.source, "", "", "");
              }}><FolderPlus></FolderPlus></Button>
            </Container>
          </div>
        </div>
      );
    });
  };

  return (

     
        <div id='homebg'>
                              <div id='searchForm'>

<Form className="text-center" onSubmit={(e) => { e.preventDefault(); }}>
<Form.Group className="d-flex justify-content-center align-items-center">
<Form.Control
variant="light"
id="searchInput"
placeholder="Search..."
type="text"
value={searchTerm}
onChange={handleSearchInputChange}

color="white"
/>
<Button id='ggButton' onClick={performSearch}>
<Search /> 
</Button>
</Form.Group>
</Form>

</div>

        {renderData()}
       
        <Button id='standardButton' onClick={() => {
               setFeedLength(feedLength+=20)
              }}>More</Button>


      <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
centered onHide={setCollectionModalIsOpen}>
                             
                              <Modal.Header id='billModalHeader' closeButton ><h3>{storedTitle}</h3></Modal.Header>

                              <Modal.Body id='descriptionModalBody'>
                                          {storedDescription}
                                          {storedDate}
                              <div id='fullBill'>
                         <Form id='noteModalForm'>
         <Form.Group id='noteModalForm'  className="mb-3" >
           <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
             type="text"
             as={"textarea"}
             rows={2}
             />
             <br></br>

         </Form.Group>
         </Form>

         <Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(storedTitle, storedDetailsLink, storedDocLink, 
  storedDate, storedFeedName, storedBranchName, storedTopicName, storedDescription) ;setCollectionModalIsOpen(false) }} >
    Save To Collection
  </Button>
  </Modal.Footer> 

           </div>
           </Modal.Body>
                              </Modal>
    </div>
  );
}

export default GenerateFeedRecentUK;