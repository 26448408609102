import React, { useState,useEffect, memo } from 'react';
import { Button, Card, Form, Modal, Spinner } from 'react-bootstrap';
import { BoxArrowUp, Clipboard, ClipboardCheck, Files, FileText, FolderPlus, InfoCircle } from 'react-bootstrap-icons';
import { BrowserRouter as Router, Route, Link, Switch, useHistory  } from "react-router-dom";
import ItemDetailPage from './ItemDetailPage';

var storedTitle = ''// title
var storedDetailsLink = ''// details link
var storedDocLink = ''// doc link *
var storedDate = ''// date
var storedNote = ''// note
var storedFeedName = ''// feed name
var storedBranchName = ''// branch name 
var storedTopicName = ''// topic name
var storedDescription = ''// description
var collectionCounter = localStorage.getItem('collectionNumber')

function formatDate(inputDate) {
  const date = new Date(inputDate);
  
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
}

function SaveFeedItem(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, 
  saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription)
{

storedTitle = saveCollectionTitle;
storedDetailsLink = saveCollectionDetailLink;
storedDocLink = saveCollectionDocLink;
storedDate = saveCollectionDate;
storedNote = saveCollectionNote;
storedFeedName = saveCollectionFeedName;
storedBranchName = saveCollectionBranchName;
storedTopicName = saveCollectionTopicName;
storedDescription = saveCollectionDescription;
}

const APICongressionalBillPull =  React.memo(({ congressionalBillId }) => {
  //console.log(congressionalBillId)
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // For the share modal
  const [shareLink, setShareLink] = useState(''); // The link to be shared
  const [linkCopied, setLinkCopied] = useState(false); // To track if the link is copie
  const history = useHistory();
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
  const [billInfo, setBillInfo] = useState(null);
  require('dotenv').config();
  const apiKey = process.env.REACT_APP_API_KEY;
  var apiURL = '&skip=0&schema=united_states_of_america&table=congressional_bills&order_by=created_at'
  var feedName = 'Bill Updates'
  var feedBranch ='Legislative'
  var feedTopic = 'N/A'
  var feedButtonName = 'Bill'
  var schema = 'united_states_of_america'
  var table = 'congressional_bills'
  const handleDetailsClick = (id) => {
    // console.log("Navigating to item with ID:", id);
    
    history.push({
      pathname: '/item',
      state: {
        id,
        apiURL,
        feedName,
        feedBranch,
        feedTopic,
        feedButtonName,
        schema,
        table,
      },
    });
  };
  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
    
    var searchItem = document.getElementById('noteModalFeedItem').value
  
       collectionCounter = localStorage.getItem('collectionNumber')
   
     collectionCounter++;

     
     var collectionTitles = []
      var collectionDetailLinks = []
     var collectionDocLinks = []
     var collectionNote = []

       var title = 'title'.concat(collectionCounter)
       var detailLink = 'detailLink'.concat(collectionCounter)
       var docLink = 'docLink'.concat(collectionCounter)
       var itemNote = 'itemNote'.concat(collectionCounter)
       var itemDate = 'itemDate'.concat(collectionCounter)
       var feedName = 'feedName'.concat(collectionCounter)
       var branchName = 'branchName'.concat(collectionCounter)
       var topicName = 'topicName'.concat(collectionCounter)
       var itemDescription = 'itemDescription'.concat(collectionCounter)
   
   
     
     
       localStorage.setItem(title,saveCollectionTitle)
       
       localStorage.setItem(detailLink,saveCollectionDetailLink)
      
       localStorage.setItem(docLink,saveCollectionDocLink)
       localStorage.setItem(itemNote,searchItem)
   
       localStorage.setItem(itemDate,saveCollectionDate)
       localStorage.setItem(feedName,saveCollectionFeedName)
       localStorage.setItem(branchName,saveCollectionBranchName)
       localStorage.setItem(topicName,saveCollectionTopicName)
   
       localStorage.setItem(itemDescription,saveCollectionDescription)
     
       localStorage.setItem('collectionNumber', collectionCounter)

  }

  const handleShareClick = (id) => {
  
    // console.log(`Current URL: ${window.location.href}`);
    const shareUrl = `govglance.org/feed/united_states_of_america/congressional_bills/${id}`;
    setShareLink(shareUrl);
    setShareModalIsOpen(true);
    setLinkCopied(false); // Reset the link copied state
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink).then(() => {
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 3000); // Reset after 3 seconds
    });
  };

  const handleNativeShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: '',
          text: '',
          url: shareLink,
        });
     //   console.log('Content shared successfully!');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert('Sharing is not supported on this device.');
    }
  };


  useEffect(() => {
    let isMounted = true;

    const fetchBillInfo = async () => {
      try {
        const response = await fetch(`https://api.govglance.org/members/congresssional_bill/${congressionalBillId}`, {
          method: 'GET',
          headers: { 
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + apiKey,
          }
        });
        const data = await response.json();

        if (isMounted) {
          setBillInfo(data);
       // console.log(billInfo)
        }
      } catch (error) {
        console.error('Error fetching bill info:', error);
      }
    };

    fetchBillInfo();

    return () => {
      isMounted = false;
    };
  }, [congressionalBillId, apiKey]);

  return (
    <>
      {billInfo ? (
        <>
         <span>{billInfo.title.length > 180 ? `${billInfo.title.slice(0, 177)}...` : billInfo.title}</span>
          
          <Card.Body id='billBodyText'onClick={(e) => {
                    e.preventDefault();
                   
                    window.open(billInfo.download.pdfLink, "_blank");
                  }} ></Card.Body>
          <p>
          <Card.Body id='billBodyText'>{formatDate(billInfo.created_at)}</Card.Body>
          <Button id='billLink' onClick={() => history.push(`/feed/${schema}/${table}/${billInfo.id}`)}><InfoCircle></InfoCircle> More</Button>
                
                  <Button id='viewBillButton' onClick={(e) => {
                    e.preventDefault();
                   
                    window.open(billInfo.download.pdfLink, "_blank");
                  }}>
                    <FileText></FileText> Bill
                  </Button>

                  <Button id='addToCollectionButton' onClick={() => {
              setCollectionModalIsOpen(true);
              SaveFeedItem(billInfo.title, billInfo.url, billInfo.download.pdfLink, formatDate(billInfo.date_issued), '', "Bill Updates", "Legislation", "N/A", "");
            }}><FolderPlus></FolderPlus></Button>

<Button
                id="shareButton"
                onClick={(e) => {
                  e.stopPropagation();
                  handleShareClick(billInfo.id);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-send"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                </svg>
              </Button>
                  </p>
                  <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
    centered onHide={setCollectionModalIsOpen}>
                                 
                                  <Modal.Header id='billModalHeader' closeButton ><h3>{storedTitle}</h3></Modal.Header>
    
                                  <Modal.Body id='descriptionModalBody'>
                                              {storedDescription}
                                              {storedDate}
                                  <div id='fullBill'>
                             <Form id='noteModalForm'>
             <Form.Group id='noteModalForm'  className="mb-3" >
               <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
                 type="text"
                 as={"textarea"}
                 rows={2}
                 />
                 <br></br>
    
             </Form.Group>
             </Form>
    
             <Modal.Footer id='addNoteModalFooter'> 
    
    <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(storedTitle, storedDetailsLink, storedDocLink, 
      storedDate, storedFeedName, storedBranchName, storedTopicName, storedDescription) ;setCollectionModalIsOpen(false) }} >
        Save To Collection
      </Button>
      </Modal.Footer> 
    
               </div>
               </Modal.Body>
                                  </Modal>
                 
                                  <Modal
            id="shareModal"
            show={shareModalIsOpen}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => setShareModalIsOpen(false)}
          >
            <Modal.Header id="billModalHeader" closeButton>
              <h3>Share</h3>
            </Modal.Header>
            <Modal.Body id="descriptionModalBody">
              <Form.Group id="noteModalForm">
                <Form.Control
               
                  id="noteModalFeedItem"
                  type="text"
                  readOnly
                  value={shareLink}
                />
              </Form.Group>
              <Modal.Footer id="shareFooter">
              <Button 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>

                <Button id="addToCollectionButton" onClick={handleCopyLink}>
                  {linkCopied ? <ClipboardCheck /> : <Clipboard />}
                </Button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <span><Spinner animation="grow" variant="light" /></span>
      )}
    </>
  );
});

export default memo(APICongressionalBillPull);