import React, { useState, useEffect } from 'react';
import { Button, Card, Container, Form, Spinner } from 'react-bootstrap';
import { FileText, InfoCircle, Search } from 'react-bootstrap-icons';
import APICongressionalBillPull from './APICongressionalBillPull';


function formatDate(inputDate) {
  const date = new Date(inputDate);
  
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
}
const SponsoredBills = ({ bioGuideID }) => {
  var [feedLength, setFeedLength] = useState(1000);
  const [billInfo, setBillInfo] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  require('dotenv').config();
  const apiKey = process.env.REACT_APP_API_KEY;

  const fetchBillInfo = () => {
    setLoading(true);
  
    fetch(`https://api.govglance.org/members/{bio_guid_id}?bio_guide_id=${bioGuideID}&limit=${feedLength}&skip=0&search=${searchQuery}`, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + apiKey,
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch bill info');
      }
      return response.json();
    })
    .then(data => {
      const billDetailsPromises = [];
  
      data.forEach(bill => {
        const billDetailPromise = fetch(`https://api.govglance.org/members/congresssional_bill/${bill.id}`, {
          method: 'GET',
          headers: { 
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + apiKey,
          }
        }).then(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch bill details');
          }
          return response.json();
        });
  
        billDetailsPromises.push(billDetailPromise);
      });
  
      return Promise.all(billDetailsPromises)
        .then(billDetails => {
          const combinedData = data.map((bill, index) => ({
            ...bill,
            details: billDetails[index]
          }));
        
          setBillInfo(combinedData);
          setLoading(false);
          setError(null);
        });
    })
    .catch(error => {
      console.error('Error fetching bill info:', error);
      setError('Error fetching bill info');
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchBillInfo();
  }, [searchQuery]);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const renderData = () => {
    if (loading) {
      return <Spinner animation="grow" variant="light" />;
    }
  
    if (error) {
      return <p>Error fetching data.</p>;
    }
  
    if (!billInfo || billInfo.length === 0) {
      return <p>No data available.</p>;
    }
  
    // Filter billInfo based on searchQuery
    const filteredBillInfo = billInfo.filter(bill => {
      // Customize this condition based on your search requirements
      return bill.details.title.toLowerCase().includes(searchQuery.toLowerCase());
    });
  
    return filteredBillInfo.map((bill, index) => (
      <div id='fullBillFeed' key={index}>
      <Container id='feedContainer'>
        <Card.Title onClick={(e) => {
                  e.preventDefault();
                  
                  window.open(bill.details.download.pdfLink, "_blank");
                }} id='billText'>{bill.role} - {bill.details.title.length > 180 ? `${bill.details.title.slice(0, 177)}...` : bill.details.title} </Card.Title>
      <Card.Body id="billBodyText">{formatDate(bill.details.created_at)}</Card.Body>
        <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(bill.details.url, "_blank");
              }}><InfoCircle></InfoCircle> Details</Button>
              
                <Button id='viewBillButton' onClick={(e) => {
                  e.preventDefault();
                 
                  window.open(bill.details.download.pdfLink, "_blank");
                }}>
                  <FileText></FileText> Bill
                </Button>

                
      </Container>
    </div>
    ));
  };

  return (
    <>
      <div id='searchForm'>
        <Form className="text-center">
          <Form.Group className="d-flex justify-content-center align-items-center">
            <Form.Control
              variant="light"
              id="searchInput"
              placeholder="Search..."
              type="text"
              color="white"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
            <Button id='ggButton'>
              <Search /> 
            </Button>
          </Form.Group>
        </Form>
      </div>
      <div id='fullBillFeed'>
      {renderData()}
     
      </div>
    </>
  );
}

export default SponsoredBills;
