
import { BrowserRouter as Link, } from "react-router-dom";
import { Navbar, Nav, Button, FormControl, Dropdown, Image, Row } from 'react-bootstrap';
import { HeartFil, HeartFill } from 'react-bootstrap-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';



import MobileOffCanvas from "./MobileOffCanvas";
import { BrowserView, MobileView } from "react-device-detect";
import MobileTopNavbar from "./MobileTopNavbar";
import DesktopNavbar from "./DesktopNavbar";
import DesktopNavbarSearch from "./DesktopNavbarSearch";
import NavbarSearchButtonNoCountry from "./NavbarSearchButtonNoCountry";
import MobileOffCanvasHome from "./MobileOffCanvasHome";



export const Privacy = () =>   {
    render()
    {
    return(
       <>
              <BrowserView>
       <div id='homebg'>

       <DesktopNavbarSearch/>
       <NavbarSearchButtonNoCountry/>
           <div id='homebg'>  
           <div style={{height: '100px'}}></div>
           <div id='textPage'>
           <h3 id='aboutParagraph'>Privacy</h3>
                    
                    
                    
                    <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
                    Features like collection save news items directly to your device's local storage for your convenience
                    and is not shared with Gov Glance servers. We only use the default analytics provided by the Apple App Store. 
                    <br></br>
                  <a id='aboutLink' href='https://govglance.foundation/docs/privacy%20policy'>Learn more</a>
                  </p>
             
                      </div>
                      </div>
                      <br></br> <br></br> <br></br> <br></br> <br></br>
                           <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>
          </div>
</BrowserView>
       <MobileView>
       <div id='homebg'>

       <MobileTopNavbar/>
           <div id='homebg'>  
 <div id='textPageMobile'>
           <h3 id='aboutParagraph'>Privacy</h3>
                    <br></br>
                    
                    
                    <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
                    Features like collection save news items directly to your device's local storage for your convenience
                    and is not shared with Gov Glance servers. We only use the default analytics provided by the Apple App Store. 
                    <br></br>
                  <a id='aboutLink' href='https://govglance.foundation/docs/privacy%20policy'>Learn more</a>
                  </p>
                  </div>
        
                      </div>

                      <br></br>

<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
   e.preventDefault();
   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
 }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

           <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    
<div id='changeBranchDropdownMobile'>
<MobileOffCanvasHome/>
     
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
         
          </div>
</MobileView>
          </>
  )
}
 }



  






export default Privacy;




