import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Coin, Tree, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import  "../../App.css";
import 'bootstrap/dist/css/bootstrap.min.css'

import Axios from 'axios'
import { render } from '@testing-library/react';




import CanadaMobileTopNavbar from '../CanadaMobileTopNavbar';
import { CreateFeedItem } from '../../Functions';
import GDPUpdates from '../../GDPUpdates';

import MobileOffCanvas from '../../MobileOffCanvas';
import StandingCommitteeOnInternationalTrade from '../Legislative/StandingCommitteeOnInternationalTrade';
import StandingCommitteeOnAgricultureAndFood from '../Legislative/StandingCommitteeOnAgricultureAndFood';
import StandingCommitteeOnNaturalResources from '../Legislative/StandingCommitteeOnNaturalResources';
import StandingCommitteeOnEnvironment from '../Legislative/StandingCommitteeOnEnvironment';
import MobileOffCanvasCanada from '../../MobileOffCanvasCanada';


var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;




export const CanadaEnvironmentMobile = () =>   {
    render()
 
    {
      const [show, setShow] = useState(true);

      
      const [fullscreen, setFullScreen] = useState(true);
    
    
      const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
    
      const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
    
    
      const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
      const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
    
    
      const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
      const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
    
      const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
    
      const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
    
      const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
    
      const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
    
    
      const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
      const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
      const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
      const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
      const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
      const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
      const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
      const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
      const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
      const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
      const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
      const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
      const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
      const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
      const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
      const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
      const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
      const [beaModalIsOpen, setBEAModalIsOpen] = useState(false)
      const [secPressModalIsOpen, setSECPressModalIsOpen] = useState(false)
    
      const [gdpUpdatesModalIsOpen, setGDPUpdatesModalIsOpen] = useState(false)
      const [inflationUpdatesModalIsOpen, setInflationUpdatesModalIsOpen] = useState(false)
      const [wageGrowthModalIsOpen, setWageGrowthModalIsOpen] = useState(false)
      const [bureauOfLaborStatisticsModalIsOpen, setBureauOfLaborStatisticsModalIsOpen] = useState(false)
      const [irsDocumentsModalIsOpen, setIRSDocumentsModalIsOpen] = useState(false)
      const [governmentContractsModalIsOpen, setGovernmentContractsModalIsOpen] = useState(false)
    
    
        const [secSAndSModalIsOpen, setSECSAndSModalIsOpen] = useState(false)
      const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
      const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    
      const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
      const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
      const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
      const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
      const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
      const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
      const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
      const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
      const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
      const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
      const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
      const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
      const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
      const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
      const [houseCommitteeOnSmallBusinessModalIsOpen, setHouseCommitteeOnSmallBusinessModalIsOpen] = useState(false)
      const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
      const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
    
      const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
      const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
    
    
    
      const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
      const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
      const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
    

      const [officeOfManagementModalIsOpen, setOfficeOfManagmentModalIsOpen] = useState(false)
      const [energyMaterialsmMdalIsOpen, setEnergyMaterialsModalIsOpen] = useState(false)
      const [interstateMaterialsModalIsOpen, setInterstateMaterialsModalIsOpen] = useState(false)
      const [budgetMaterialsModalIsOpen, setBudgetMaterialsModalIsOpen] = useState(false)
      const [educationMaterialsModalIsOpen, setEducationMaterialsModalIsOpen] = useState(false)
      const [financialServicesMaterialsModalIsOpen, setFinancialServicesMaterialsModalIsOpen] = useState(false)
      const [smallBusinessMaterialsModalIsOpen, setSmallBusinessMaterialsModalIsOpen] = useState(false)
      const [senateSmallBusinessMaterialsModalIsOpen, setSenateSmallBusinessMaterialsModalIsOpen] = useState(false)
      const [senateCommitteeOnEnergyModalIsOpen, setSenateCommitteeOnEnergyModalIsOpen] = useState(false)
      const [senateBudgetMaterialsModalIsOpen, setSenateBudgetMaterialsModalIsOpen] = useState(false)
      const [senateFinanceMaterialsModalIsOpen, setSenateFinaceMaterialsModalIsOpen] = useState(false)
      const [senateTransportationMaterialsModalIsOpen, setSenateTransportationMaterialsModalIsOpen] = useState(false)
      const [senateCommitteeOnTheBudgetModalIsOpen, setSenateCommitteeOnTheBudgetModalIsOpen] = useState(false)
      const [senateHousingMaterialsModalIsOpen, setSenateHousingMaterialsModalIsOpen] = useState(false)
      const [commerceDocsModalIsOpen, setCommerceDocsModalIsOpen] = useState(false)
      const [censusBureauModalIsOpen, setCensusBureauModalIsOpen] = useState(false)
      const [economicAnalysisModalIsOpen, setEconomicAnalysisModalIsOpen] = useState(false)
      const [ecoDevelopmentModalIsOpen, setEconDevelopmentModalIsOpen] = useState(false)
      const [enforcementsModalIsOpen, setEnforcemnetsModalIsOpen] = useState(false)
      const nullEntry = [];
      const [notifications, setNotifications] = useState(nullEntry);
  
      const [topic, setTopic] = useState(null);
  
  


    //const [ModalIsOpen, setModalIsOpen] = useState(false)
    
    var branch = "all";


  
    return(
       <>
      <CanadaMobileTopNavbar/>

       <div id='homebg'>
      
     


       <h5 id='branchHeadingMobile' ><Tree></Tree>Environment  </h5>

       <Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfAgricultureModalIsOpen(true)}>Standing Committee on Environment and Sustainable Development </h5>
  <div id='committee5'></div>
  {CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/envi.xml', 'committee5', 'Standing Committee on Environment and Sustainable Development', 'Legislative', 'N/A', StandingCommitteeOnEnvironment)}

  <Modal id='fullBillModal' show={departmentOfAgricultureModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfAgricultureModalIsOpen(false)}>
                         <Modal.Header id='billModalHeader' closeButton><h3> Standing Committee on Environment and Sustainable Development </h3></Modal.Header>
                       <StandingCommitteeOnEnvironment/>
                       </Modal>

</Col>



<Col>
  <h5 id='presidentialFeedHeadingMobile'  onClick={() => setDepartmentOfCommerceModalIsOpen(true)}>Standing Committee on Agriculture and Agri-Food</h5>
  <div id='committee2'></div>
  {CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/agri.xml', 'committee2', 'Standing Committee on Agriculture and Agri-Food', 'Legislative', 'N/A', StandingCommitteeOnAgricultureAndFood)}

  <Modal id='fullBillModal' show={departmentOfCommerceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfCommerceModalIsOpen(false)}>
                         <Modal.Header id='billModalHeader' closeButton><h3> Standing Committee on Agriculture and Agri-Food</h3></Modal.Header>
                       <StandingCommitteeOnAgricultureAndFood/>
                       </Modal>

</Col>


<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfEducationModalIsOpen(true)} >Standing Committee on Natural Resources</h5>
  <div id='committee12'></div>
  {CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/rnnr.xml', 'committee12', 'Standing Committee on Natural Resources', 'Legislative', 'N/A', StandingCommitteeOnNaturalResources)}


  <Modal id='fullBillModal' show={departmentOfEducationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfEducationModalIsOpen(false)}>
                         <Modal.Header id='billModalHeader' closeButton><h3> Standing Committee on Natural Resources</h3></Modal.Header>
                       <StandingCommitteeOnNaturalResources/>
                       </Modal>

</Col>


{/* 
             <Col>


{/* 

<h5 id='presidentialFeedHeadingMobile' onClick={() => setGDPUpdatesModalIsOpen(true)}>GPD Updates</h5>
                 {CreateFeedItem('https://www.atlantafed.org/rss/GDPNow', 'gdpUpdates',"GDP Updates", "N/A", "Economy",GDPUpdates)}
                 <div class='courtNewsMobile' id='gdpUpdates'></div>



                 <Modal id='fullBillModal' show={gdpUpdatesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setGDPUpdatesModalIsOpen(false)}>
                         <Modal.Header id='billModalHeader' closeButton><h3>GDP Updates</h3></Modal.Header>
                       
                       </Modal>

                 </Col>
*/}






                 
                   






          







           <Col>

           </Col>
     
           <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvasCanada/>

            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
         
          </div>

          </>
  )
}
 }



  






export default CanadaEnvironmentMobile;

