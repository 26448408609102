// useHandleContainerClickRecnts.js
import { useHistory } from 'react-router-dom';

const useHandleContainerClickRecentsMexico = () => {
  const history = useHistory();

  const handleContainerClickRecentsMexico = (apiRequest, name, branch, topic, buttonName) => {


    // Navigate to the representative page with bioGuideId and name as state
    window.scrollTo(0, 0);
    history.push(`/mexico/feed`, { 
      apiRequest, name, branch, topic, buttonName
    });
  };

  return handleContainerClickRecentsMexico;
};

export default useHandleContainerClickRecentsMexico;
