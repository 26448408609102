import React, { useState } from 'react';
import DesktopNavbar from '../DesktopNavbar';
import './Learn.css';

const quizData = {
  quiz: [
    {
      question: "What is the primary function of a state's legislative branch?",
      options: [
        "A) Enforce state laws",
        "B) Interpret state laws",
        "C) Create state laws",
        "D) Oversee federal agencies"
      ],
      correct_answer: "C"
    },
    {
      question: "Which amendment to the U.S. Constitution reserves powers to the states?",
      options: [
        "A) Ninth Amendment",
        "B) Tenth Amendment",
        "C) Fourteenth Amendment",
        "D) First Amendment"
      ],
      correct_answer: "B"
    },
    {
      question: "Which of the following is typically NOT an elected executive position in state government?",
      options: [
        "A) Governor",
        "B) Secretary of State",
        "C) Attorney General",
        "D) Chief Justice of the State Supreme Court"
      ],
      correct_answer: "D"
    },
    {
      question: "What is the name of the only state with a unicameral legislature?",
      options: [
        "A) Texas",
        "B) California",
        "C) Nebraska",
        "D) New York"
      ],
      correct_answer: "C"
    },
    {
      question: "State constitutions are generally __________ than the U.S. Constitution.",
      options: [
        "A) Shorter and less detailed",
        "B) Longer and more detailed",
        "C) The same length",
        "D) Nonexistent"
      ],
      correct_answer: "B"
    },
    {
      question: "What is the term for powers shared by both state and federal governments?",
      options: [
        "A) Reserved powers",
        "B) Concurrent powers",
        "C) Enumerated powers",
        "D) Implied powers"
      ],
      correct_answer: "B"
    },
    {
      question: "Which of the following is an example of a state police power?",
      options: [
        "A) Coining money",
        "B) Declaring war",
        "C) Regulating public health and safety",
        "D) Negotiating treaties with foreign nations"
      ],
      correct_answer: "C"
    },
    {
      question: "Who is typically responsible for overseeing state elections and maintaining public records?",
      options: [
        "A) State Treasurer",
        "B) Attorney General",
        "C) Secretary of State",
        "D) Lieutenant Governor"
      ],
      correct_answer: "C"
    },
    {
      question: "In most states, how are amendments to the state constitution ratified?",
      options: [
        "A) By the governor's approval",
        "B) By a two-thirds vote in the legislature alone",
        "C) Through approval by the state's voters in a referendum",
        "D) By the state supreme court's decision"
      ],
      correct_answer: "C"
    },
    {
      question: "What is the principle called when local governments are granted the authority to govern themselves with little state interference?",
      options: [
        "A) Federalism",
        "B) Home Rule",
        "C) Supremacy Clause",
        "D) Eminent Domain"
      ],
      correct_answer: "B"
    }
  ],
  flashcards: [
    {
      question: "What does the Tenth Amendment to the U.S. Constitution state?",
      answer: "It reserves powers not delegated to the federal government to the states or the people."
    },
    {
      question: "What are the three branches of state government?",
      answer: "Executive, Legislative, and Judicial branches."
    },
    {
      question: "Who is the chief executive officer of a state?",
      answer: "The Governor."
    },
    {
      question: "What is the primary role of a state's legislative branch?",
      answer: "To create and pass state laws."
    },
    {
      question: "What is the only state with a unicameral legislature?",
      answer: "Nebraska."
    },
    {
      question: "What is 'Home Rule' in the context of state and local government?",
      answer: "The authority granted by a state to a local government to govern itself with minimal state interference."
    },
    {
      question: "What is the difference between general jurisdiction and specialized courts at the state level?",
      answer: "General jurisdiction courts handle a wide range of cases, while specialized courts focus on specific types of cases like family or probate matters."
    },
    {
      question: "How are most state judges selected?",
      answer: "Through elections, appointments, or merit selection systems, varying by state."
    },
    {
      question: "What are 'police powers' in state government?",
      answer: "The inherent authority of states to enact laws protecting public health, safety, morals, and welfare."
    },
    {
      question: "What is a 'state constitution'?",
      answer: "A legal document that outlines the structure, powers, and functions of a state government."
    },
    {
      question: "What is 'fiscal federalism'?",
      answer: "The financial relationship between the federal and state governments, including grants and funding."
    },
    {
      question: "Who is typically responsible for legal matters and representing the state in court?",
      answer: "The State Attorney General."
    },
    {
      question: "What is the 'balanced budget requirement' in most states?",
      answer: "A mandate that the state cannot spend more than its income."
    },
    {
      question: "What are 'unfunded mandates'?",
      answer: "Federal requirements imposed on states without accompanying federal funding."
    },
    {
      question: "What is the role of a 'State Treasurer' or 'Comptroller'?",
      answer: "To manage state finances, including revenue collection and investments."
    },
    {
      "term": "Concurrent Powers",
      "definition": "Powers shared by both state and federal governments, such as the power to tax."
    },
    {
      "term": "Reserved Powers",
      "definition": "Powers not delegated to the federal government, reserved for the states."
    },
    {
      "term": "Initiative",
      "definition": "A process that allows citizens to propose legislation or constitutional amendments through petition."
    },
    {
      "term": "Referendum",
      "definition": "A direct vote by the electorate on a particular proposal or issue."
    },
    {
      "term": "Block Grants",
      "definition": "Federal funds provided to states with general spending guidelines."
    },
    {
      "term": "Categorical Grants",
      "definition": "Federal funds provided to states for a specific purpose, often with strict limitations."
    },
    {
      "term": "Line-Item Veto",
      "definition": "The power of a governor to veto specific provisions of a bill without vetoing the entire bill."
    },
    {
      "term": "State Supreme Court",
      "definition": "The highest judicial authority within a state's court system."
    },
    {
      "term": "Merit Selection (Missouri Plan)",
      "definition": "A method of selecting judges that combines appointment and election."
    },
    {
      "term": "Police Powers",
      "definition": "The capacity of a state to regulate behaviors and enforce order within its territory for the betterment of health, safety, morals, and general welfare."
    }
  ]
};

const LearnStateGovernment = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showQuizResult, setShowQuizResult] = useState(false);
  const [score, setScore] = useState(0);
  const [showFlashcardAnswer, setShowFlashcardAnswer] = useState(false);
  const [currentFlashcard, setCurrentFlashcard] = useState(0);

  const handleAnswerOptionClick = (selectedOption) => {
    const correctOption = quizData.quiz[currentQuestion].correct_answer;
    if (selectedOption.charAt(0) === correctOption) {
      setScore(score + 1);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizData.quiz.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowQuizResult(true);
    }
  };

  const handleFlashcardFlip = () => {
    setShowFlashcardAnswer(!showFlashcardAnswer);
  };

  const handleNextFlashcard = () => {
    setShowFlashcardAnswer(false);
    const nextFlashcard = currentFlashcard + 1;
    if (nextFlashcard < quizData.flashcards.length) {
      setCurrentFlashcard(nextFlashcard);
    } else {
      setCurrentFlashcard(0);
    }
  };

  return (
    <div id='homebg'>

    <DesktopNavbar/>
    <div className="report-container">
      <h1 className="title">State Government in the United States</h1>

      {/* Introduction */}
      <h2 className="section-title">Introduction</h2>
      <p>
        State governments in the United States play a critical role in the federal system, serving as sovereign entities with their own constitutions, laws, and governance structures. They are responsible for addressing issues that directly affect the daily lives of citizens, such as education, transportation, public safety, and healthcare. This report provides a comprehensive analysis of state governments, exploring their constitutional foundations, structures, powers, functions, and the challenges they face within the context of American federalism.
      </p>

      {/* Constitutional Basis and Sovereignty */}
      <h2 className="section-title">Constitutional Basis and Sovereignty</h2>

      <h3>Federalism and State Sovereignty</h3>
      <p>
        The U.S. Constitution establishes a federal system of government, dividing power between the national government and the states. The Tenth Amendment explicitly reserves powers not delegated to the federal government to the states or the people:
      </p>
      <blockquote>
        “The powers not delegated to the United States by the Constitution, nor prohibited by it to the States, are reserved to the States respectively, or to the people.”
        — U.S. Constitution, Amendment X
      </blockquote>

      <h3>State Constitutions</h3>
      <p>
        Each state operates under its own constitution, which outlines the structure of its government, delineates powers, and establishes rights for its citizens. State constitutions are often more detailed than the U.S. Constitution and can be amended through processes defined within each state.
      </p>

      {/* Structure of State Governments */}
      <h2 className="section-title">Structure of State Governments</h2>
      <p>
        While there is variation among states, most share a similar governmental structure, mirroring the federal model with three branches: executive, legislative, and judicial.
      </p>

      <h3>1. Executive Branch</h3>
      <h4>Governor</h4>
      <ul>
        <li><strong>Role:</strong> The governor serves as the chief executive officer of the state.</li>
        <li><strong>Powers and Responsibilities:</strong>
          <ul>
            <li><strong>Enforcement of State Laws:</strong> Ensures laws enacted by the state legislature are implemented.</li>
            <li><strong>Budget Proposal:</strong> Develops and submits the state budget to the legislature.</li>
            <li><strong>Appointments:</strong> Nominates heads of state agencies and departments, sometimes subject to legislative approval.</li>
            <li><strong>Veto Power:</strong> Can veto legislation passed by the state legislature; some states allow line-item vetoes.</li>
            <li><strong>Executive Orders:</strong> Issues directives to manage state government operations.</li>
          </ul>
        </li>
      </ul>

      <h4>Other Elected Officials</h4>
      <p>States often have additional executive officers who may be elected independently of the governor, including:</p>
      <ul>
        <li><strong>Lieutenant Governor:</strong> Acts as the second-in-command; duties vary by state.</li>
        <li><strong>Attorney General:</strong> Chief legal officer, represents the state in legal matters.</li>
        <li><strong>Secretary of State:</strong> Oversees elections, business registrations, and public records.</li>
        <li><strong>Treasurer/Comptroller:</strong> Manages state finances and investments.</li>
        <li><strong>Superintendent of Education:</strong> Oversees public education (in some states).</li>
      </ul>

      <h4>State Agencies and Departments</h4>
      <ul>
        <li><strong>Function:</strong> Implement policies and administer programs in areas such as transportation, health, education, and public safety.</li>
        <li><strong>Organization:</strong> Varies by state; agencies may report directly to the governor or be led by independent boards.</li>
      </ul>

      <h3>2. Legislative Branch</h3>
      <h4>Structure</h4>
      <p>
        Most state legislatures are bicameral, consisting of:
      </p>
      <ul>
        <li><strong>Senate:</strong> The upper chamber.</li>
        <li><strong>House of Representatives/Assembly:</strong> The lower chamber.</li>
      </ul>
      <p><strong>Exception:</strong> Nebraska has a unicameral (single-chamber) legislature.</p>

      <h4>Membership</h4>
      <ul>
        <li><strong>Qualifications:</strong> Vary by state but generally include age, residency, and citizenship requirements.</li>
        <li><strong>Term Lengths:</strong> Senators typically serve longer terms than representatives, ranging from two to four years.</li>
      </ul>

      <h4>Powers and Functions</h4>
      <ul>
        <li><strong>Lawmaking:</strong> Enact state laws, including statutes and budgets.</li>
        <li><strong>Budget Approval:</strong> Approve the state budget proposed by the governor.</li>
        <li><strong>Oversight:</strong> Monitor the executive branch and state agencies.</li>
        <li><strong>Constituent Services:</strong> Address concerns and needs of residents in their districts.</li>
        <li><strong>Amend State Constitution:</strong> Propose amendments, often requiring voter approval.</li>
      </ul>

      <h4>Leadership</h4>
      <ul>
        <li><strong>Presiding Officers:</strong>
          <ul>
            <li><strong>Senate President:</strong> Often the lieutenant governor or elected from among the senators.</li>
            <li><strong>Speaker of the House:</strong> Elected by members of the lower chamber.</li>
          </ul>
        </li>
        <li><strong>Majority and Minority Leaders:</strong> Lead their respective parties.</li>
        <li><strong>Committees:</strong> Specialized groups that handle legislation related to specific topics.</li>
      </ul>

      <h3>3. Judicial Branch</h3>
      <h4>State Court Systems</h4>
      <p>
        State judicial systems handle the vast majority of legal cases in the United States, including civil and criminal matters under state law.
      </p>

      <h4>Hierarchy</h4>
      <ol>
        <li><strong>Trial Courts:</strong> Courts of first instance where cases are initially heard.
          <ul>
            <li><strong>General Jurisdiction:</strong> Handle a wide range of cases.</li>
            <li><strong>Specialized Courts:</strong> Focus on specific areas (e.g., family, probate, traffic).</li>
          </ul>
        </li>
        <li><strong>Intermediate Appellate Courts (in most states):</strong>
          <ul>
            <li><strong>Function:</strong> Review decisions from trial courts for legal errors.</li>
          </ul>
        </li>
        <li><strong>State Supreme Court:</strong>
          <ul>
            <li><strong>Highest Court:</strong> Final arbiter of state law.</li>
            <li><strong>Jurisdiction:</strong> Handles appeals from lower courts and interprets state constitution.</li>
          </ul>
        </li>
      </ol>

      <h4>Judicial Selection Methods</h4>
      <ul>
        <li><strong>Elections:</strong> Judges are elected by voters (partisan or non-partisan ballots).</li>
        <li><strong>Appointments:</strong> Judges are appointed by the governor or legislature.</li>
        <li><strong>Merit Selection (Missouri Plan):</strong> Combines appointment and election, with initial appointment followed by retention elections.</li>
      </ul>

      {/* Powers and Responsibilities of State Governments */}
      <h2 className="section-title">Powers and Responsibilities of State Governments</h2>

      <h3>Police Powers</h3>
      <ul>
        <li><strong>Definition:</strong> The inherent authority to enact laws and regulations to protect public health, safety, morals, and welfare.</li>
        <li><strong>Scope:</strong> Broad powers affecting areas like zoning, public health mandates, and safety regulations.</li>
      </ul>

      <h3>Education</h3>
      <ul>
        <li><strong>K-12 Education:</strong> States set educational standards, curricula, and testing requirements; fund public schools alongside local governments.</li>
        <li><strong>Higher Education:</strong> Operate public universities and colleges; provide financial aid programs.</li>
      </ul>

      <h3>Transportation</h3>
      <ul>
        <li><strong>Infrastructure:</strong> Maintain state highways, bridges, and public transportation systems.</li>
        <li><strong>Regulation:</strong> Oversee driver licensing, vehicle registration, and traffic laws.</li>
      </ul>

      <h3>Public Safety</h3>
      <ul>
        <li><strong>Law Enforcement:</strong> State police or highway patrol agencies enforce state laws.</li>
        <li><strong>Corrections:</strong> Manage state prisons and rehabilitation programs.</li>
        <li><strong>Emergency Services:</strong> Coordinate disaster response and preparedness efforts.</li>
      </ul>

      <h3>Health and Welfare</h3>
      <ul>
        <li><strong>Healthcare Programs:</strong> Administer Medicaid and other public health initiatives.</li>
        <li><strong>Social Services:</strong> Provide assistance programs for vulnerable populations.</li>
      </ul>

      <h3>Economic Development</h3>
      <ul>
        <li><strong>Business Regulation:</strong> License businesses, regulate commerce within the state.</li>
        <li><strong>Job Creation:</strong> Implement policies to attract businesses and promote employment.</li>
      </ul>

      <h3>State Constitutions</h3>
      <h4>Characteristics</h4>
      <ul>
        <li><strong>Length and Detail:</strong> Generally longer and more detailed than the U.S. Constitution.</li>
        <li><strong>Bill of Rights:</strong> Include protections for individual rights, sometimes expanding upon federal guarantees.</li>
      </ul>

      <h4>Amendment Process</h4>
      <ul>
        <li><strong>Legislative Proposal:</strong> Amendments proposed by the legislature, often requiring supermajority approval.</li>
        <li><strong>Constitutional Convention:</strong> A convention called to revise or rewrite the constitution.</li>
        <li><strong>Initiative:</strong> Citizens propose amendments through petition (available in some states).</li>
        <li><strong>Ratification:</strong> Amendments typically require approval by voters in a referendum.</li>
      </ul>

      <h4>Relationship with the U.S. Constitution</h4>
      <ul>
        <li><strong>Supremacy Clause:</strong> State constitutions and laws must not conflict with the U.S. Constitution or federal laws.</li>
        <li><strong>Additional Protections:</strong> States may grant more expansive rights but cannot restrict federally protected rights.</li>
      </ul>

      <h3>Local Governments</h3>
      <h4>Types of Local Governments</h4>

      <h5>Counties</h5>
      <ul>
        <li><strong>Role:</strong> Serve as administrative arms of the state, providing regional services.</li>
        <li><strong>Functions:</strong> Law enforcement (sheriff’s departments), property assessments, record keeping, public health.</li>
      </ul>

      <h5>Municipalities</h5>
      <ul>
        <li><strong>Cities and Towns:</strong> Provide services directly to residents within their boundaries.</li>
        <li><strong>Governance:</strong> Operate under charters granted by the state; may have mayor-council or council-manager systems.</li>
      </ul>

      <h5>Special Districts</h5>
      <ul>
        <li><strong>Purpose:</strong> Address specific functions (e.g., school districts, water districts).</li>
        <li><strong>Governance:</strong> Operate independently with their own elected boards.</li>
      </ul>

      <h5>Home Rule</h5>
      <ul>
        <li><strong>Definition:</strong> Authority granted by the state to local governments to self-govern in certain areas.</li>
        <li><strong>Variation:</strong> Degree of autonomy varies by state and is defined in state constitutions or statutes.</li>
      </ul>

      <h4>Federalism and Relationship with the Federal Government</h4>
      <h5>Supremacy Clause</h5>
      <ul>
        <li><strong>Article VI, Clause 2 of the U.S. Constitution:</strong></li>
        <blockquote>
          “This Constitution, and the Laws of the United States… shall be the supreme Law of the Land.”
        </blockquote>
        <li><strong>Implication:</strong> Federal law takes precedence over conflicting state laws.</li>
      </ul>

      <h5>Tenth Amendment</h5>
      <ul>
        <li><strong>Reserves Powers:</strong> Affirms that powers not delegated to the federal government are reserved for the states or the people.</li>
      </ul>

      <h5>Preemption</h5>
      <ul>
        <li><strong>Definition:</strong> Federal law overrides state law in areas where Congress intends to occupy the field or where state law conflicts with federal objectives.</li>
        <li><strong>Examples:</strong> Immigration enforcement, environmental regulations.</li>
      </ul>

      <h5>Intergovernmental Relations</h5>
      <ul>
        <li><strong>Cooperative Federalism:</strong> Collaboration between federal and state governments on shared policy areas.</li>
        <li><strong>Grants-in-Aid:</strong> Federal funding provided to states for specific purposes, often with conditions attached.</li>
      </ul>

      {/* Fiscal Aspects of State Government */}
      <h2 className="section-title">Fiscal Aspects of State Government</h2>

      <h3>State Taxation</h3>
      <ul>
        <li><strong>Types of Taxes:</strong>
          <ul>
            <li><strong>Income Tax:</strong> Levied on individual and corporate earnings (not all states impose income tax).</li>
            <li><strong>Sales Tax:</strong> Charged on goods and services at the point of sale.</li>
            <li><strong>Property Tax:</strong> Primarily a local government revenue source, but some states levy property taxes.</li>
            <li><strong>Excise Taxes:</strong> Applied to specific goods (e.g., gasoline, tobacco).</li>
          </ul>
        </li>
      </ul>

      <h3>Budget Process</h3>
      <ul>
        <li><strong>Balanced Budget Requirement:</strong> Most states are constitutionally or statutorily required to balance their budgets.</li>
        <li><strong>Budget Cycle:</strong>
          <ol>
            <li><strong>Proposal:</strong> Governor submits a budget proposal.</li>
            <li><strong>Legislative Review:</strong> Legislature examines, amends, and approves the budget.</li>
            <li><strong>Execution:</strong> Agencies implement the budget under executive oversight.</li>
            <li><strong>Audit and Evaluation:</strong> Monitoring and assessment of budget performance.</li>
          </ol>
        </li>
      </ul>

      <h3>Fiscal Federalism</h3>
      <ul>
        <li><strong>Federal Funding:</strong> States receive federal funds through grants, which can be categorical (specific purposes) or block grants (broader discretion).</li>
        <li><strong>Unfunded Mandates:</strong> Federal requirements imposed on states without accompanying funding strain budgets.</li>
      </ul>

      {/* Challenges Facing State Governments */}
      <h2 className="section-title">Challenges Facing State Governments</h2>

      <h3>Economic Fluctuations</h3>
      <ul>
        <li><strong>Revenue Volatility:</strong> State revenues can be highly sensitive to economic conditions.</li>
        <li><strong>Pension Obligations:</strong> Long-term liabilities for public employee retirement benefits.</li>
      </ul>

      <h3>Healthcare Costs</h3>
      <ul>
        <li><strong>Medicaid Expansion:</strong> Balancing costs with the need to provide healthcare to low-income residents.</li>
        <li><strong>Public Health Emergencies:</strong> Responding to crises like pandemics strains resources.</li>
      </ul>

      <h3>Infrastructure Needs</h3>
      <ul>
        <li><strong>Aging Infrastructure:</strong> Maintaining and upgrading transportation networks and public facilities.</li>
        <li><strong>Funding Gaps:</strong> Securing sufficient funding for large-scale projects.</li>
      </ul>

      <h3>Education Funding</h3>
      <ul>
        <li><strong>Equity Issues:</strong> Addressing disparities in funding between wealthy and less affluent districts.</li>
        <li><strong>Standards and Accountability:</strong> Implementing effective education policies and assessments.</li>
      </ul>

      <h3>Criminal Justice Reform</h3>
      <ul>
        <li><strong>Overcrowding:</strong> Managing prison populations and reducing recidivism.</li>
        <li><strong>Policing Practices:</strong> Balancing public safety with civil liberties.</li>
      </ul>

      <h3>Environmental Concerns</h3>
      <ul>
        <li><strong>Climate Change:</strong> Adapting to and mitigating environmental impacts.</li>
        <li><strong>Resource Management:</strong> Balancing economic development with conservation.</li>
      </ul>

      <h3>Intergovernmental Relations</h3>
      <ul>
        <li><strong>Preemption Conflicts:</strong> Navigating federal policies that may conflict with state initiatives.</li>
        <li><strong>Legal Challenges:</strong> Litigation over states’ rights and federal overreach.</li>
      </ul>

      {/* Policy Innovations and State Leadership */}
      <h2 className="section-title">Policy Innovations and State Leadership</h2>

      <h3>Laboratories of Democracy</h3>
      <ul>
        <li><strong>Concept:</strong> States can experiment with policies that, if successful, may be adopted at the federal level.</li>
        <li><strong>Examples:</strong>
          <ul>
            <li><strong>Healthcare Reform:</strong> Massachusetts’ healthcare model influenced the Affordable Care Act.</li>
            <li><strong>Marijuana Legalization:</strong> States leading changes in drug policy.</li>
          </ul>
        </li>
      </ul>

      <h3>State Compacts</h3>
      <ul>
        <li><strong>Definition:</strong> Agreements between two or more states to address shared concerns.</li>
        <li><strong>Examples:</strong> Interstate compacts on resource management, transportation.</li>
      </ul>

      <h3>Technology and E-Government</h3>
      <ul>
        <li><strong>Digital Services:</strong> Enhancing government efficiency through technology.</li>
        <li><strong>Transparency Initiatives:</strong> Online access to government data and records.</li>
      </ul>

      <h3>Sustainable Practices</h3>
      <ul>
        <li><strong>Environmental Programs:</strong> Initiatives for renewable energy, conservation, and waste reduction.</li>
        <li><strong>Smart Growth:</strong> Policies promoting sustainable development and preserving open spaces.</li>
      </ul>

      {/* State Government and Civil Rights */}
      <h2 className="section-title">State Government and Civil Rights</h2>

      <h3>Voting Rights</h3>
      <ul>
        <li><strong>Administration:</strong> States oversee voter registration, election procedures, and polling places.</li>
        <li><strong>Regulations:</strong> Voter ID laws, early voting, and mail-in ballots vary by state.</li>
      </ul>

      <h3>Criminal Justice</h3>
      <ul>
        <li><strong>Death Penalty:</strong> Legal in some states, abolished in others.</li>
        <li><strong>Sentencing Laws:</strong> States determine criminal penalties and sentencing guidelines.</li>
      </ul>

      <h3>Marriage and Family Law</h3>
      <ul>
        <li><strong>Regulations:</strong> States set laws regarding marriage licenses, divorce, child custody.</li>
      </ul>

      <h3>Education Policies</h3>
      <ul>
        <li><strong>Curriculum Standards:</strong> States establish educational content and graduation requirements.</li>
        <li><strong>School Choice:</strong> Policies on charter schools, vouchers, and homeschooling.</li>
      </ul>

        {/* Flashcards Section */}
        <h2 className="section-title">Flashcards</h2>
      <div className="flashcard-section">
        <div className="flashcard" onClick={handleFlashcardFlip}>
          <div className="flashcard-content">
            {showFlashcardAnswer ? (
              <p>{quizData.flashcards[currentFlashcard].answer}</p>
            ) : (
              <p>{quizData.flashcards[currentFlashcard].question}</p>
            )}
          </div>
        </div>
        <button className="btn" onClick={handleNextFlashcard}>
          Next Flashcard
        </button>
      </div>

      {/* Quiz Section */}
      <h2 className="section-title">Quiz</h2>
      <div className="quiz-section">
        {showQuizResult ? (
          <div className="quiz-result">
            <h3>
              Your Score: {score} out of {quizData.quiz.length}
            </h3>
            <button
              className="btn"
              onClick={() => {
                setCurrentQuestion(0);
                setScore(0);
                setShowQuizResult(false);
              }}
            >
              Retake Quiz
            </button>
          </div>
        ) : (
          <div className="quiz-question">
            <h3>
              Question {currentQuestion + 1} of {quizData.quiz.length}
            </h3>
            <p>{quizData.quiz[currentQuestion].question}</p>
            <ul>
              {quizData.quiz[currentQuestion].options.map((option, index) => (
                <li key={index}>
                  <button
                    onClick={() => handleAnswerOptionClick(option)}
                    className="quiz-option"
                  >
                    {option}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

    
    </div>
    </div>
  );
};

export default LearnStateGovernment;