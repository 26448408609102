// useHandleContainerClickRecnts.js
import { useHistory } from 'react-router-dom';

const UseHandleContainerClickRecentsUK = () => {
  const history = useHistory();

  const handleContainerClickRecentsUK = (apiRequest, name, branch, topic, buttonName) => {


    // Navigate to the representative page with bioGuideId and name as state
    window.scrollTo(0, 0);
    history.push(`/uk/feed`, { 
      apiRequest, name, branch, topic, buttonName
    });
  };

  return handleContainerClickRecentsUK;
};

export default UseHandleContainerClickRecentsUK;
