import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom'; // Import useHistory
import { BrowserView, MobileView } from 'react-device-detect';
import { Navbar, Nav, Container, Row, Col, Modal } from 'react-bootstrap';
import { Pen, HeartFill, People } from 'react-bootstrap-icons';
import StateDesktopNavbar from './States/StateDesktopNavbar';
import GenerateFeedState from './GenerateFeedState';
import { CreateFeedAPI, CreateFeedAPIState } from './Functions';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import GenerateFeed from './GenerateFeed';
import MobileTopNavbar from './MobileTopNavbar';
import MobileOffCanvas from './MobileOffCanvas';

// State abbreviations mapping
const stateAbbreviations = {
  alabama: 'al',
  alaska: 'ak',
  arizona: 'az',
  arkansas: 'ar',
  california: 'ca',
  colorado: 'co',
  connecticut: 'ct',
  delaware: 'de',
  florida: 'fl',
  georgia: 'ga',
  hawaii: 'hi',
  idaho: 'id',
  illinois: 'il',
  indiana: 'in',
  iowa: 'ia',
  kansas: 'ks',
  kentucky: 'ky',
  louisiana: 'la',
  maine: 'me',
  maryland: 'md',
  massachusetts: 'ma',
  michigan: 'mi',
  minnesota: 'mn',
  mississippi: 'ms',
  missouri: 'mo',
  montana: 'mt',
  nebraska: 'ne',
  nevada: 'nv',
  newhampshire: 'nh',
  newjersey: 'nj',
  newmexico: 'nm',
  newyork: 'ny',
  northcarolina: 'nc',
  northdakota: 'nd',
  ohio: 'oh',
  oklahoma: 'ok',
  oregon: 'or',
  pennsylvania: 'pa',
  rhodeisland: 'ri',
  southcarolina: 'sc',
  southdakota: 'sd',
  tennessee: 'tn',
  texas: 'tx',
  utah: 'ut',
  vermont: 'vt',
  virginia: 'va',
  washington: 'wa',
  westvirginia: 'wv',
  wisconsin: 'wi',
  wyoming: 'wy',
};

// Known states with spaces
const knownStatesWithSpaces = [
  'newyork',
  'newjersey',
  'newhampshire',
  'northcarolina',
  'southcarolina',
  'northdakota',
  'southdakota',
  'westvirginia',
  'rhodeisland',
];

// Function to format state names
const formatStateName = (state) => {
  console.log('Original state:', state);

  // Replace underscores and multiple spaces with a single space
  let replacedState = state.replace(/[_\s]+/g, ' ').trim();
  console.log('After replacing underscores with spaces:', replacedState);

  // Normalize the state name by removing spaces for comparison
  const normalizedState = replacedState.toLowerCase().replace(/\s+/g, '');
  console.log('Normalized state for comparison:', normalizedState);

  if (knownStatesWithSpaces.includes(normalizedState)) {
    // Insert a space between known conjoined state names
    replacedState = replacedState.replace(/(new|north|south|west|rhode)(?=[A-Za-z])/i, '$1 ');
    console.log('After handling conjoined names:', replacedState);
  }

  const lowerCasedState = replacedState.toLowerCase();
  console.log('After converting to lowercase:', lowerCasedState);

  const splitWords = lowerCasedState.split(' ');
  console.log('After splitting into words:', splitWords);

  const capitalizedWords = splitWords.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  console.log('After capitalizing each word:', capitalizedWords);

  const finalStateName = capitalizedWords.join(' ');
  console.log('Final formatted state name:', finalStateName);

  return finalStateName;
};

const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal
    id="fullBillModal"
    show={isOpen}
    size="xl"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    onHide={onClose}
  >
    <Modal.Header id="billModalHeader" closeButton>
      <h3>{title}</h3>
    </Modal.Header>
    {content}
  </Modal>
);

const StateExecutivePage = () => {
  const { stateName } = useParams(); // Extract stateName from the URL
  const history = useHistory(); // For programmatic navigation

  // Normalize the state name
  const normalizedStateName = stateName.trim().replace(/[_\s]+/g, '').toLowerCase();
  const stateAbbreviation = stateAbbreviations[normalizedStateName] || 'N/A';

  // Redirect if the state name is invalid
  useEffect(() => {
    if (stateAbbreviation === 'N/A') {
      history.replace('/'); // Redirect to home if state name is invalid
    }
  }, [stateAbbreviation, history]);

  // Format the state name for display
  const formattedStateName = formatStateName(stateName);

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null,
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content,
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null,
    });
  };

  // Render nothing if the state abbreviation is invalid
  if (stateAbbreviation === 'N/A') {
    return null;
  }

  return (
    <>
      <BrowserView>
        <div id="homePage">
          <div id="homebg">
            <CustomModal
              isOpen={modalInfo.isOpen}
              onClose={closeModal}
              title={modalInfo.title}
              content={modalInfo.content}
            />
            {/* Use the formatted state name */}
            <StateDesktopNavbar state={formattedStateName} stateFlag="" />

            <div id="sectionHeading">
              <Row>
                <h3 id="branchHeading">
                  <People />
                  <b> Executive</b>
                </h3>
              </Row>
            </div>

            <div id="branchPage">
              <Container className="bgcolor">
                <Container id="carouselBufferTwo">
                  <Row>
                    <Col id="legislativeColLeft">
                      <div id="columnLeftPadding">
                        <h4
                          id="whBreifingFeedHeading"
                          onClick={() =>
                            openModal(
                              'Governor',
                              <GenerateFeed
                                url={`&skip=0&schema=united_states_of_america&table=${normalizedStateName}&order_by=created_at`}
                                name={formattedStateName}
                                branch="Executive"
                                topic="N/A"
                                buttonName="Document"
                              />
                            )
                          }
                        >
                          <b>Governor</b>
                        </h4>
                        <div id="gov"></div>

                        {CreateFeedAPI(
                          `&skip=0&schema=united_states_of_america&table=${normalizedStateName}&order_by=created_at`,
                          'gov',
                          'Governor',
                          'Executive',
                          'N/A',
                          'Document',
                          GenerateFeed
                        )}
                      </div>
                    </Col>

                    <Col id="legislativeColRight">
                      <div id="columnRightPadding"></div>
                    </Col>
                  </Row>
                </Container>
              </Container>
            </div>

            <footer id="homeFooter">
              <Navbar>
                <Row id="footerNav">
                  <Nav variant="pills">
                    <Nav.Link id="footerContent">Gov Glance Foundation Inc.</Nav.Link>
                    <Nav.Item>
                      <Nav.Link id="footerContent" href="mailto:contact@govglance.org">
                        Contact
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        id="footerContent"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            'https://donate.stripe.com/28o3fXbdvbQO2RO288',
                            '_blank'
                          );
                        }}
                      >
                        <HeartFill id="donateIcon" /> Donate
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Link
                      onClick={(e) => {
                        e.preventDefault();
                        window.open('/about', '_self');
                      }}
                      id="footerContent"
                    >
                      About
                    </Nav.Link>
                    <Nav.Link
                      onClick={(e) => {
                        e.preventDefault();
                        window.open('/privacy', '_self');
                      }}
                      id="footerContent"
                    >
                      Privacy
                    </Nav.Link>
                  </Nav>
                </Row>
              </Navbar>
            </footer>
          </div>
        </div>
      </BrowserView>

      <MobileView>
        <MobileTopNavbar />
        <div id="homebg">
          <h5 id="branchHeadingMobile">
            <People /> Executive
          </h5>

          <Col>
            <h5 id="presidentialFeedHeadingMobile">Governor</h5>

            <div id="gov"></div>

            {CreateFeedAPI(
              `&skip=0&schema=united_states_of_america&table=${normalizedStateName}&order_by=created_at`,
              'gov',
              'Governor',
              'Executive',
              'N/A',
              'Document',
              GenerateFeed
            )}
          </Col>
          <Navbar id="navbarMobileBottom" fixed="bottom">
            <div id="changeBranchDropdownMobile">
              <MobileOffCanvas />
            </div>
          </Navbar>
        </div>
      </MobileView>
    </>
  );
};

export default StateExecutivePage;
