import React, { useState } from 'react';
import DesktopNavbar from '../DesktopNavbar';
import './Learn.css';

const quizData = {
  quiz: [
    {
      question: "How many chambers make up the U.S. Congress?",
      options: [
        "A) One",
        "B) Two",
        "C) Three",
        "D) Four"
      ],
      correct_answer: "B"
    },
    {
      question: "Which chamber of Congress has the sole authority to impeach federal officials?",
      options: [
        "A) Senate",
        "B) House of Representatives",
        "C) Both Senate and House",
        "D) Supreme Court"
      ],
      correct_answer: "B"
    },
    {
      question: "What is the term length for a U.S. Senator?",
      options: [
        "A) 2 years",
        "B) 4 years",
        "C) 6 years",
        "D) Lifetime"
      ],
      correct_answer: "C"
    },
    {
      question: "Which committee is responsible for originating revenue bills?",
      options: [
        "A) Senate Finance Committee",
        "B) House Ways and Means Committee",
        "C) House Appropriations Committee",
        "D) Senate Budget Committee"
      ],
      correct_answer: "B"
    },
    {
      question: "What is the primary role of the Speaker of the House?",
      options: [
        "A) Preside over the Senate",
        "B) Lead the majority party in the Senate",
        "C) Set the legislative agenda in the House",
        "D) Appoint federal judges"
      ],
      correct_answer: "C"
    },
    {
      question: "What clause grants Congress the power to pass laws necessary and proper for executing its enumerated powers?",
      options: [
        "A) Supremacy Clause",
        "B) Elastic Clause",
        "C) Commerce Clause",
        "D) Necessary Clause"
      ],
      correct_answer: "B"
    },
    {
      question: "How many voting members are there in the House of Representatives?",
      options: [
        "A) 100",
        "B) 200",
        "C) 435",
        "D) 535"
      ],
      correct_answer: "C"
    },
    {
      question: "Which amendment limits the use of the filibuster in the Senate?",
      options: [
        "A) 17th Amendment",
        "B) 19th Amendment",
        "C) No current amendment",
        "D) 25th Amendment"
      ],
      correct_answer: "C"
    },
    {
      question: "What is gerrymandering?",
      options: [
        "A) Redrawing district boundaries to favor a particular party",
        "B) A type of filibuster",
        "C) The process of impeachment",
        "D) A budgetary procedure"
      ],
      correct_answer: "A"
    },
    {
      question: "Which body confirms presidential appointments, including federal judges?",
      options: [
        "A) House of Representatives",
        "B) Senate",
        "C) Supreme Court",
        "D) Executive Office of the President"
      ],
      correct_answer: "B"
    }
  ],
  flashcards: [
    {
      question: "What are the two chambers of the U.S. Congress?",
      answer: "The House of Representatives and the Senate."
    },
    {
      question: "How many voting members are in the Senate?",
      answer: "100 senators, two from each state."
    },
    {
      question: "What is the term length for a member of the House of Representatives?",
      answer: "Two years."
    },
    {
      question: "What is the Great Compromise?",
      answer: "An agreement during the Constitutional Convention that established a bicameral legislature with proportional representation in the House and equal representation in the Senate."
    },
    {
      question: "Who is the presiding officer of the Senate?",
      answer: "The Vice President of the United States serves as the President of the Senate, but presiding duties are often delegated to the President pro tempore."
    },
    {
      question: "What power does the House of Representatives have that the Senate does not?",
      answer: "The sole authority to impeach federal officials."
    },
    {
      question: "What power does the Senate have that the House does not?",
      answer: "The sole authority to conduct impeachment trials and confirm presidential appointments."
    },
    {
      question: "What is the purpose of the Necessary and Proper Clause?",
      answer: "To grant Congress the power to pass laws required to carry out its enumerated powers."
    },
    {
      question: "What is a filibuster?",
      answer: "A tactic used in the Senate to prolong debate and delay or prevent a vote on a bill."
    },
    {
      question: "What is the role of a Majority Leader in the Senate?",
      answer: "To guide the legislative agenda and strategy of the majority party."
    },
    {
      question: "What is the process for a bill to become law?",
      answer: "Introduction, committee referral, committee action, floor debate and voting in both chambers, reconciliation of differences, and presidential action."
    },
    {
      question: "What is gerrymandering?",
      answer: "The manipulation of electoral district boundaries to favor a specific political party."
    },
    {
      question: "What are standing committees?",
      answer: "Permanent committees in Congress that focus on specific areas of legislation, such as finance or education."
    },
    {
      question: "What is the role of the Government Accountability Office (GAO)?",
      answer: "To audit federal expenditures and provide nonpartisan information to Congress."
    },
    {
      question: "What is a conference committee?",
      answer: "A temporary joint committee formed to reconcile differences between House and Senate versions of a bill."
    },
    {
      question: "What is the power of the purse?",
      answer: "Congress's authority to tax, spend, and allocate federal funds."
    },
    {
      question: "What is the impact of the Citizens United v. FEC decision?",
      answer: "It allowed corporations and unions to spend unlimited funds on political advocacy, increasing the influence of money in politics."
    },
    {
      question: "What is the role of whips in Congress?",
      answer: "To assist party leaders in managing their legislative program and ensuring member attendance and voting."
    },
    {
      question: "What is impeachment?",
      answer: "The process by which Congress can remove federal officials, including the President, for 'high crimes and misdemeanors.'"
    },
    {
      question: "What is the significance of the House Ways and Means Committee?",
      answer: "It is the chief tax-writing committee in the House of Representatives."
    },
    {
      term: "Bicameral Legislature",
      definition: "A legislative body with two separate chambers or houses, such as the House of Representatives and the Senate in the U.S. Congress."
    },
    {
      term: "Enumerated Powers",
      definition: "Specific powers granted to Congress listed in Article I, Section 8 of the U.S. Constitution."
    },
    {
      term: "Impeachment",
      definition: "The process by which a legislative body levels charges against a government official."
    },
    {
      term: "Precedent (Stare Decisis)",
      definition: "The principle that courts should follow previous judicial decisions when the same points arise again in litigation."
    },
    {
      term: "Supremacy Clause",
      definition: "Article VI, Clause 2 of the U.S. Constitution, establishing that federal law takes precedence over state laws."
    }
  ]
};

const LearnLegislativeBranch = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showQuizResult, setShowQuizResult] = useState(false);
  const [score, setScore] = useState(0);
  const [showFlashcardAnswer, setShowFlashcardAnswer] = useState(false);
  const [currentFlashcard, setCurrentFlashcard] = useState(0);

  const handleAnswerOptionClick = (selectedOption) => {
    const correctOption = quizData.quiz[currentQuestion].correct_answer;
    if (selectedOption.charAt(0) === correctOption) {
      setScore(score + 1);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizData.quiz.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowQuizResult(true);
    }
  };

  const handleFlashcardFlip = () => {
    setShowFlashcardAnswer(!showFlashcardAnswer);
  };

  const handleNextFlashcard = () => {
    setShowFlashcardAnswer(false);
    const nextFlashcard = currentFlashcard + 1;
    if (nextFlashcard < quizData.flashcards.length) {
      setCurrentFlashcard(nextFlashcard);
    } else {
      setCurrentFlashcard(0);
    }
  };

  return (
    <div id='homebg'>

<DesktopNavbar/>
    <div className="report-container">
      <h1 className="title">The Legislative Branch of the United States Government</h1>

      {/* Introduction */}
      <h2 className="section-title">Introduction</h2>
      <p>
        The Legislative Branch of the United States government, established by Article I of the Constitution, is a cornerstone of the nation’s democratic system. Known collectively as Congress, it is the body responsible for crafting federal laws, representing the interests of the American people, and checking the powers of the other branches. This in-depth report explores the structure, functions, processes, and significance of the Legislative Branch, highlighting how it operates within the federal government.
      </p>

      {/* Structure of the Legislative Branch */}
      <h2 className="section-title">Structure of the Legislative Branch</h2>

      <h3>Bicameral Legislature</h3>
      <p>
        The U.S. Congress is a bicameral legislature, meaning it consists of two separate chambers:
      </p>
      <ol>
        <li>The House of Representatives</li>
        <li>The Senate</li>
      </ol>
      <p>
        This structure was a result of the “Great Compromise” during the Constitutional Convention of 1787, balancing the interests of both populous and smaller states.
      </p>

      <h4>The House of Representatives</h4>
      <ul>
        <li><strong>Composition:</strong> 435 voting members, apportioned among the states based on population. Additionally, there are non-voting delegates from U.S. territories and the District of Columbia.</li>
        <li><strong>Term Length:</strong> Members, known as Representatives or Congresspersons, serve two-year terms.</li>
        <li><strong>Qualifications (Article I, Section 2):</strong>
          <ul>
            <li><strong>Age:</strong> At least 25 years old.</li>
            <li><strong>Citizenship:</strong> U.S. citizen for at least seven years.</li>
            <li><strong>Residency:</strong> Resident of the state they represent.</li>
          </ul>
        </li>
      </ul>

      <h4>Leadership in the House</h4>
      <ul>
        <li><strong>Speaker of the House:</strong> The presiding officer and the most powerful member, elected by the House members. The Speaker sets the legislative agenda, appoints committee members, and represents the House in official capacities.</li>
        <li><strong>Majority and Minority Leaders:</strong> Elected leaders of the majority and minority parties, respectively, responsible for strategy and coordination.</li>
        <li><strong>Whips:</strong> Assist leaders in managing their party’s legislative program and ensuring member attendance and voting.</li>
      </ul>

      <h4>The Senate</h4>
      <ul>
        <li><strong>Composition:</strong> 100 senators, with each state represented equally by two senators.</li>
        <li><strong>Term Length:</strong> Senators serve six-year terms, with staggered elections so that approximately one-third of the Senate is up for election every two years.</li>
        <li><strong>Qualifications (Article I, Section 3):</strong>
          <ul>
            <li><strong>Age:</strong> At least 30 years old.</li>
            <li><strong>Citizenship:</strong> U.S. citizen for at least nine years.</li>
            <li><strong>Residency:</strong> Resident of the state they represent.</li>
          </ul>
        </li>
      </ul>

      <h4>Leadership in the Senate</h4>
      <ul>
        <li><strong>Vice President of the United States:</strong> Serves as the President of the Senate but votes only to break ties.</li>
        <li><strong>President Pro Tempore:</strong> Traditionally the most senior member of the majority party, presides over the Senate in the Vice President’s absence.</li>
        <li><strong>Majority and Minority Leaders:</strong> Chief spokespersons and strategists for their parties, controlling the Senate’s agenda and floor proceedings.</li>
        <li><strong>Whips:</strong> Similar to the House, they assist party leadership in managing legislative priorities.</li>
      </ul>

      <h4>Independent Agencies and Government Corporations</h4>
      <ul>
        <li><strong>Independent Agencies:</strong> Entities that exist outside of the executive departments (e.g., Environmental Protection Agency, Central Intelligence Agency).</li>
        <li><strong>Government Corporations:</strong> Government-owned entities that provide commercial services (e.g., United States Postal Service, Amtrak).</li>
      </ul>

      {/* Powers and Responsibilities of Congress */}
      <h2 className="section-title">Powers and Responsibilities of Congress</h2>

      <h3>Enumerated Powers</h3>
      <p>
        Article I, Section 8 of the Constitution lists the specific powers granted to Congress, known as enumerated powers. These include:
      </p>
      <ul>
        <li><strong>Legislation:</strong> Authority to enact laws necessary for the execution of governmental functions.</li>
        <li><strong>Taxation and Spending:</strong> Power to lay and collect taxes, duties, imposts, and excises; to pay debts and provide for the common defense and general welfare.</li>
        <li><strong>Borrowing Money:</strong> Ability to borrow money on the credit of the United States.</li>
        <li><strong>Regulation of Commerce:</strong> Control over interstate and international trade.</li>
        <li><strong>Currency:</strong> Authority to coin money, regulate its value, and fix standards of weights and measures.</li>
        <li><strong>Naturalization and Bankruptcy Laws:</strong> Establish uniform rules.</li>
        <li><strong>Post Offices:</strong> Establish and maintain postal services.</li>
        <li><strong>Intellectual Property:</strong> Promote progress by securing exclusive rights to authors and inventors.</li>
        <li><strong>Federal Courts:</strong> Constitute tribunals inferior to the Supreme Court.</li>
        <li><strong>War Powers:</strong> Declare war, grant letters of marque and reprisal, and regulate captures on land and water.</li>
        <li><strong>Military:</strong> Raise and support armies, provide and maintain a navy, and make rules for the regulation of the armed forces.</li>
        <li><strong>Militia:</strong> Provide for calling forth the militia to execute federal laws, suppress insurrections, and repel invasions.</li>
        <li><strong>District of Columbia:</strong> Exercise exclusive legislation over the nation’s capital.</li>
      </ul>

      <h3>Implied Powers</h3>
      <p>
        The Necessary and Proper Clause (Article I, Section 8, Clause 18), also known as the Elastic Clause, grants Congress the power:
      </p>
      <blockquote>
        “To make all Laws which shall be necessary and proper for carrying into Execution the foregoing Powers, and all other Powers vested by this Constitution in the Government of the United States…”
      </blockquote>
      <p>
        This clause allows Congress to pass laws needed to effectively execute its enumerated powers, providing flexibility to adapt to changing circumstances.
      </p>

      <h3>Exclusive Powers of Each Chamber</h3>

      <h4>House of Representatives</h4>
      <ul>
        <li><strong>Initiate Revenue Bills:</strong> All bills for raising revenue must originate in the House (Article I, Section 7).</li>
        <li><strong>Impeachment Authority:</strong> Sole power to impeach federal officials, including the President (Article I, Section 2).</li>
      </ul>

      <h4>Senate</h4>
      <ul>
        <li><strong>Advice and Consent:</strong> Approves treaties (by a two-thirds vote) and confirms presidential appointments, including federal judges, ambassadors, and cabinet members (Article II, Section 2).</li>
        <li><strong>Trial of Impeachments:</strong> Acts as the jury in impeachment trials, with the Chief Justice presiding over presidential impeachment trials (Article I, Section 3).</li>
      </ul>

      {/* The Legislative Process */}
      <h2 className="section-title">The Legislative Process</h2>

      <p>
        The process by which Congress creates laws is methodical and involves several stages to ensure thorough consideration.
      </p>

      <h3>1. Introduction of Legislation</h3>
      <ul>
        <li><strong>Bills:</strong> Proposed laws presented for consideration.</li>
        <li><strong>Origin:</strong> Any member of Congress can introduce a bill in their respective chamber, except revenue bills, which must originate in the House.</li>
        <li><strong>Sponsorship:</strong> The member who introduces the bill is its sponsor; additional members may co-sponsor.</li>
      </ul>

      <h3>2. Committee Referral</h3>
      <ul>
        <li><strong>Assignment:</strong> Bills are referred to relevant standing committees based on subject matter.</li>
        <li><strong>Subcommittees:</strong> Committees may further refer bills to specialized subcommittees for detailed examination.</li>
      </ul>

      <h3>3. Committee Action</h3>
      <ul>
        <li><strong>Hearings:</strong> Committees hold hearings to gather information and views from experts, officials, and stakeholders.</li>
        <li><strong>Markup Sessions:</strong> Members debate, amend, and rewrite the proposed legislation.</li>
        <li><strong>Reporting:</strong> If approved, the committee reports the bill back to the full chamber with recommendations.</li>
      </ul>

      <h3>4. Floor Debate and Voting</h3>

      <h4>House of Representatives</h4>
      <ul>
        <li><strong>Rules Committee:</strong> Determines the rules governing debate on the bill, including time limits and amendment procedures.</li>
        <li><strong>Debate:</strong> Members debate the bill under the established rules.</li>
        <li><strong>Voting:</strong> Requires a simple majority to pass.</li>
      </ul>

      <h4>Senate</h4>
      <ul>
        <li><strong>Unanimous Consent Agreements:</strong> Set terms for debate; absent such agreements, debate is unlimited.</li>
        <li><strong>Filibuster:</strong> Senators may prolong debate to delay or prevent a vote.</li>
        <li><strong>Cloture:</strong> A motion requiring 60 votes to end debate and proceed to a vote.</li>
        <li><strong>Voting:</strong> Simple majority needed for passage.</li>
      </ul>

      <h3>5. Reconciliation of Differences</h3>
      <p>
        If both chambers pass different versions of a bill, they must reconcile them.
      </p>
      <ul>
        <li><strong>Conference Committee:</strong> Composed of members from both chambers to resolve discrepancies.</li>
        <li><strong>Conference Report:</strong> The agreed-upon version is sent back to both chambers for final approval.</li>
      </ul>

      <h3>6. Presidential Action</h3>
      <ul>
        <li><strong>Signing:</strong> The President signs the bill, and it becomes law.</li>
        <li><strong>Veto:</strong> The President can veto the bill, returning it to Congress with objections.</li>
        <li><strong>Veto Override:</strong> Congress can override the veto with a two-thirds majority in both chambers.</li>
        <li><strong>Pocket Veto:</strong> If the President takes no action within ten days and Congress is adjourned, the bill does not become law.</li>
      </ul>

      {/* Committees and Subcommittees */}
      <h2 className="section-title">Committees and Subcommittees</h2>

      <h3>Committees are essential to Congress’s ability to manage the vast array of issues requiring legislative attention.</h3>

      <h4>Types of Committees</h4>
      <ul>
        <li><strong>Standing Committees:</strong> Permanent bodies with specific responsibilities, such as the House Ways and Means Committee or the Senate Judiciary Committee.</li>
        <li><strong>Select or Special Committees:</strong> Temporary committees appointed for specific purposes, such as investigations.</li>
        <li><strong>Joint Committees:</strong> Include members from both the House and Senate to oversee areas of mutual interest.</li>
        <li><strong>Conference Committees:</strong> Temporary joint committees formed to reconcile differences between House and Senate versions of a bill.</li>
      </ul>

      <h4>Functions of Committees</h4>
      <ul>
        <li><strong>Legislative Review:</strong> Analyze proposed legislation, hold hearings, and make recommendations.</li>
        <li><strong>Oversight:</strong> Monitor and investigate the implementation of federal laws and programs by the executive branch.</li>
        <li><strong>Budgetary Review:</strong> Examine funding requests and appropriations.</li>
      </ul>

      <h4>Committee Leadership</h4>
      <ul>
        <li><strong>Chairpersons:</strong> Typically from the majority party, they set agendas, schedule hearings, and manage committee staff.</li>
        <li><strong>Ranking Members:</strong> The most senior member from the minority party, serving as the opposition leader within the committee.</li>
      </ul>

      {/* Budgetary and Fiscal Responsibilities */}
      <h2 className="section-title">Budgetary and Fiscal Responsibilities</h2>

      <p>
        Congress holds significant power over federal finances, often referred to as the “power of the purse.”
      </p>

      <h3>Budget Process</h3>
      <ol>
        <li><strong>President’s Budget Proposal:</strong> Initiated by the President, outlining funding priorities.</li>
        <li><strong>Congressional Budget Resolution:</strong> Congress develops its own budget plan, setting total spending levels.</li>
        <li><strong>Appropriations Bills:</strong> Detailed legislation allocating funds to specific federal agencies and programs.</li>
        <li><strong>Authorization Bills:</strong> Establish or continue federal programs or agencies and set maximum funding levels.</li>
        <li><strong>Reconciliation Process:</strong> Adjusts existing laws to align with budgetary goals.</li>
      </ol>

      <h3>Taxation and Revenue</h3>
      <ul>
        <li><strong>House Ways and Means Committee:</strong> Originates revenue legislation.</li>
        <li><strong>Senate Finance Committee:</strong> Reviews and amends revenue bills passed by the House.</li>
        <li><strong>Tax Legislation:</strong> Must be passed by both chambers and signed by the President.</li>
      </ul>

      {/* Oversight and Investigation */}
      <h2 className="section-title">Oversight and Investigation</h2>

      <p>
        Congress exercises oversight to ensure laws are implemented effectively and to prevent misuse of power.
      </p>

      <h3>Mechanisms of Oversight</h3>
      <ul>
        <li><strong>Hearings and Investigations:</strong> Committees can subpoena witnesses, demand documents, and hold hearings to investigate issues.</li>
        <li><strong>Government Accountability Office (GAO):</strong> An independent agency that audits federal expenditures and reports to Congress.</li>
        <li><strong>Inspectors General:</strong> Appointed within federal agencies to monitor operations and report to Congress.</li>
      </ul>

      <h3>Purpose of Oversight</h3>
      <ul>
        <li><strong>Accountability:</strong> Hold the executive branch and federal agencies accountable for their actions.</li>
        <li><strong>Transparency:</strong> Provide information to the public about government operations.</li>
        <li><strong>Policy Evaluation:</strong> Assess the effectiveness of laws and programs.</li>
      </ul>

      {/* Checks and Balances */}
      <h2 className="section-title">Checks and Balances</h2>

      <p>
        The Legislative Branch plays a crucial role in the system of checks and balances designed to prevent any one branch from dominating the government.
      </p>

      <h3>Interaction with the Executive Branch</h3>
      <ul>
        <li><strong>Confirmation of Appointments:</strong> The Senate confirms presidential nominees for key positions.</li>
        <li><strong>Budget Approval:</strong> Congress must approve funding for executive initiatives.</li>
        <li><strong>Override Vetoes:</strong> Congress can override presidential vetoes with a two-thirds majority in both chambers.</li>
        <li><strong>Impeachment Powers:</strong> Congress can remove the President and other federal officials from office for misconduct.</li>
      </ul>

      <h3>Interaction with the Judicial Branch</h3>
      <ul>
        <li><strong>Creation of Lower Courts:</strong> Congress establishes and organizes lower federal courts.</li>
        <li><strong>Confirmation of Judges:</strong> The Senate confirms federal judges nominated by the President.</li>
        <li><strong>Impeachment of Judges:</strong> Congress can impeach and remove federal judges for misconduct.</li>
        <li><strong>Amendments:</strong> Congress can propose constitutional amendments to overturn judicial decisions.</li>
      </ul>

      <h3>Additional Functions</h3>
      <ul>
        <li><strong>Advice and Consent Powers:</strong> As per Article II, Section 2 of the Constitution, the Senate has the authority to approve treaties and confirm appointments.</li>
        <li><strong>Declaring War:</strong> Only Congress has the power to declare war (Article I, Section 8).</li>
      </ul>

      {/* Challenges and Criticisms */}
      <h2 className="section-title">Challenges and Criticisms</h2>

      <h3>Partisanship and Gridlock</h3>
      <ul>
        <li><strong>Polarization:</strong> Increasing ideological divides can lead to legislative stalemates.</li>
        <li><strong>Filibuster:</strong> The use of filibusters in the Senate can block legislation, requiring 60 votes for cloture.</li>
        <li><strong>Impact:</strong> Gridlock can prevent the passage of necessary legislation and delay government functioning.</li>
      </ul>

      <h3>Lobbying and Influence</h3>
      <ul>
        <li><strong>Lobbyists:</strong> Representatives of special interest groups who attempt to influence legislation.</li>
        <li><strong>Campaign Contributions:</strong> Concerns over the impact of money on legislative decisions, especially after the Citizens United v. FEC decision.</li>
        <li><strong>Ethics Regulations:</strong> Rules exist to limit undue influence, but enforcement can be challenging.</li>
      </ul>

      <h3>Representation Issues</h3>
      <ul>
        <li><strong>Gerrymandering:</strong> Manipulating district boundaries to favor a particular party can affect representation in the House.</li>
        <li><strong>Population Disparities:</strong> Each state has equal representation in the Senate regardless of population, raising questions about proportional representation.</li>
        <li><strong>Diversity:</strong> Efforts continue to increase the representation of women and minorities in Congress.</li>
      </ul>

      {/* Technological and Modern Developments */}
      <h2 className="section-title">Technological and Modern Developments</h2>

      <h3>Digital Transformation</h3>
      <ul>
        <li><strong>Electronic Voting:</strong> Use of technology for efficient voting processes on the floor.</li>
        <li><strong>Online Communication:</strong> Members engage with constituents through social media and digital platforms.</li>
      </ul>

      <h3>Transparency Initiatives</h3>
      <ul>
        <li><strong>C-SPAN:</strong> Televises congressional proceedings, increasing public access.</li>
        <li><strong>Online Records:</strong> Bills, hearings, and votes are accessible online for public review.</li>
      </ul>

      {/* Case Studies and Historical Highlights */}
      <h2 className="section-title">Case Studies and Historical Highlights</h2>

      <h3>Landmark Legislation</h3>
      <ul>
        <li><strong>Civil Rights Act of 1964:</strong> Passed after extensive debate and filibuster, prohibiting discrimination.</li>
        <li><strong>Affordable Care Act of 2010:</strong> Significant healthcare reform impacting millions of Americans.</li>
        <li><strong>War Powers Resolution of 1973:</strong> Intended to check the President’s power to commit U.S. forces to armed conflict without congressional consent.</li>
      </ul>

      <h3>Notable Investigations</h3>
      <ul>
        <li><strong>Watergate Hearings:</strong> Led to President Nixon’s resignation.</li>
        <li><strong>Iran-Contra Affair:</strong> Congressional committees investigated covert arms sales.</li>
        <li><strong>Benghazi Hearings:</strong> Examined the 2012 attack on the U.S. diplomatic compound in Libya.</li>
      </ul>

         {/* Flashcards Section */}
         <h2 className="section-title">Flashcards</h2>
      <div className="flashcard-section">
        <div className="flashcard" onClick={handleFlashcardFlip}>
          <div className="flashcard-content">
            {showFlashcardAnswer ? (
              <p>{quizData.flashcards[currentFlashcard].answer}</p>
            ) : (
              <p>{quizData.flashcards[currentFlashcard].question}</p>
            )}
          </div>
        </div>
        <button className="btn" onClick={handleNextFlashcard}>
          Next Flashcard
        </button>
      </div>

      {/* Quiz Section */}
      <h2 className="section-title">Quiz</h2>
      <div className="quiz-section">
        {showQuizResult ? (
          <div className="quiz-result">
            <h3>
              Your Score: {score} out of {quizData.quiz.length}
            </h3>
            <button
              className="btn"
              onClick={() => {
                setCurrentQuestion(0);
                setScore(0);
                setShowQuizResult(false);
              }}
            >
              Retake Quiz
            </button>
          </div>
        ) : (
          <div className="quiz-question">
            <h3>
              Question {currentQuestion + 1} of {quizData.quiz.length}
            </h3>
            <p>{quizData.quiz[currentQuestion].question}</p>
            <ul>
              {quizData.quiz[currentQuestion].options.map((option, index) => (
                <li key={index}>
                  <button
                    onClick={() => handleAnswerOptionClick(option)}
                    className="quiz-option"
                  >
                    {option}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

   
    </div>
    </div>
  );
};

export default LearnLegislativeBranch;