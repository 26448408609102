import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { render } from '@testing-library/react';
import AIChat from './AIChat';


export const AI = () => {
  render()
  {
    return (
      <>
        <div className="App">
          <BrowserView>
<AIChat/>
          </BrowserView>

          <MobileView>
          <AIChat/>
          </MobileView>
        </div>
      </>
);
  }
}


export default AI;
