import React, { useState } from "react";
import { ReactDOM } from "react";
import { Container, Carousel, Image, Row, Col, Modal } from "react-bootstrap";
import { ChevronRight, House } from "react-bootstrap-icons";
import AlabamaAgencies from "./AlabamaAgencies";
import Alabama from "./AlabamaBills";
import AlabamaCourts from "./AlabamaCourts";
import AlabamaGovernor from "./AlabamaGovernor";
import AlabamaSoS from "./AlabamaSoS";
import Alaska from "./AlaskaBills";
import AlaskaCourts from "./AlaskaCourts";
import AlaskaGovernor from "./AlaskaGovernor";
import Arizona from "./ArizonaBills";
import ArizonaCourts from "./ArizonaCourts";
import ArizonaGovernor from "./ArizonaGovernor";
import ArkansasBills from "./ArkansasBills";
import ArkansasCourts from "./ArkansasCourts";
import ArkansasGovernor from "./ArkansasGovernor";
import CaliforniaBills from "./CaliforniaBills";
import CaliforniaCourts from "./CaliforniaCourts";
import CaliforniaGovernor from "./CaliforniaGovernor";
import ColoradoBills from "./ColoradoBills";
import ColoradoCourts from "./ColoradoCourts";
import ColoradoGovernor from "./ColoradoGovernor";
import ConnecticutBills from "./ConnecticutBills";
import ConnecticutCourts from "./ConnecticutCourts";
import ConnecticutGovernor from "./ConnecticutGovernor";
import { CreateFeedItem, CreateFeedItemCanadaProvence, CreateFeedItemStateBills, CreateFeedItemStateCourt, CreateFeedItemStateGovernor } from "./Functions";
import DelawareBills from "./States/DelawareBills";
import DelawareCourts from "./States/DelawareCourts";
import DelawareGovernor from "./States/DelawareGovernor";
import FloridaBills from "./States/FloridaBills";
import FloridaCourts from "./States/FloridaCourts";
import FloridaGovernor from "./States/FloridaGovernor";
import GeorgiaBills from "./States/GeorgiaBills";
import GeorgiaCourts from "./States/GeorgiaCourts";
import GeorgiaGovernor from "./States/GeorgiaGovernor";
import HawaiiBills from "./States/HawaiiBills";
import HawaiiCourts from "./States/HawaiiCourts";
import HawaiiGovernor from "./States/HawaiiGovernor";
import IdahoBills from "./States/IdahoBills";
import IdahoCourts from "./States/IdahoCourts";
import IdahoGovernor from "./States/IdahoGovernor";
import IllinoisBills from "./States/IllinoisBills";
import IllinoisCourts from "./States/IllinoisCourts";
import IllinoisGovernor from "./States/IllinoisGovernor";
import IndianaBills from "./States/IndianaBills";
import IndianaCourts from "./States/IndianaCourts";
import IndianaGovernor from "./States/IndianaGovernor";
import IowaBills from "./States/IowaBills";
import IowaCourts from "./States/IowaCourts";
import IowaGovernor from "./States/IowaGovernor";
import KansasBills from "./States/KansasBills";
import KansasCourts from "./States/KansasCourts";
import KansasGovernor from "./States/KansasGovernor";
import KentuckyBills from "./States/KentuckyBills";
import KentuckyCourts from "./States/KentuckyCourts";
import KentuckyGovernor from "./States/KentuckyGovernor";
import LouisianaBills from "./States/LouisianaBills";
import LouisianaCourts from "./States/LouisianaCourts";
import LouisianaGovernor from "./States/LouisianaGovernor";
import MaineBills from "./States/MaineBills";
import MaineCourts from "./States/MaineCourts";
import MaineGovernor from "./States/MaineGovernor";
import MarylandBills from "./States/MarylandBills";
import MarylandCourts from "./States/MarylandCourts";
import MarylandGovernor from "./States/MarylandGovernor";
import MassachusettsBills from "./States/MassachusettsBills";
import MassachusettsCourts from "./States/MassachusettsCourts";
import MassachusettsGovernor from "./States/MassachusettsGovernor";
import MichiganBills from "./States/MichiganBills";
import MichiganCourts from "./States/MichiganCourts";
import MichiganGovernor from "./States/MichiganGovernor";
// Minnesota
import MinnesotaBills from "./States/MinnesotaBills";
import MinnesotaCourts from "./States/MinnesotaCourts";
import MinnesotaGovernor from "./States/MinnesotaGovernor";

// Mississippi
import MississippiBills from "./States/MississippiBills";
import MississippiCourts from "./States/MississippiCourts";
import MississippiGovernor from "./States/MississippiGovernor";

// Missouri
import MissouriBills from "./States/MissouriBills";
import MissouriCourts from "./States/MissouriCourts";
import MissouriGovernor from "./States/MissouriGovernor";

// Montana
import MontanaBills from "./States/MontanaBills";
import MontanaCourts from "./States/MontanaCourts";
import MontanaGovernor from "./States/MontanaGovernor";

// Nebraska
import NebraskaBills from "./States/NebraskaBills";
import NebraskaCourts from "./States/NebraskaCourts";
import NebraskaGovernor from "./States/NebraskaGovernor";

// Nevada
import NevadaBills from "./States/NevadaBills";
import NevadaCourts from "./States/NevadaCourts";
import NevadaGovernor from "./States/NevadaGovernor";

// New Hampshire
import NewHampshireBills from "./States/NewHampshireBills";
import NewHampshireCourts from "./States/NewHampshireCourts";
import NewHampshireGovernor from "./States/NewHampshireGovernor";

// New Jersey
import NewJerseyBills from "./States/NewJerseyBills";
import NewJerseyCourts from "./States/NewJerseyCourts";
import NewJerseyGovernor from "./States/NewJerseyGovernor";

// New Mexico
import NewMexicoBills from "./States/NewMexicoBills";
import NewMexicoCourts from "./States/NewMexicoCourts";
import NewMexicoGovernor from "./States/NewMexicoGovernor";

// New York
import NewYorkBills from "./States/NewYorkBills";
import NewYorkCourts from "./States/NewYorkCourts";
import NewYorkGovernor from "./States/NewYorkGovernor";

// North Carolina
import NorthCarolinaBills from "./States/NorthCarolinaBills";
import NorthCarolinaCourts from "./States/NorthCarolinaCourts";
import NorthCarolinaGovernor from "./States/NorthCarolinaGovernor";

// North Dakota
import NorthDakotaBills from "./States/NorthDakotaBills";
import NorthDakotaCourts from "./States/NorthDakotaCourts";
import NorthDakotaGovernor from "./States/NorthDakotaGovernor";

// Ohio
import OhioBills from "./States/OhioBills";
import OhioCourts from "./States/OhioCourts";
import OhioGovernor from "./States/OhioGovernor";

// Oklahoma
import OklahomaBills from "./States/OklahomaBills";
import OklahomaCourts from "./States/OklahomaCourts";
import OklahomaGovernor from "./States/OklahomaGovernor";

// Oregon
import OregonBills from "./States/OregonBills";
import OregonCourts from "./States/OregonCourts";
import OregonGovernor from "./States/OregonGovernor";

// Pennsylvania
import PennsylvaniaBills from "./States/PennsylvaniaBills";
import PennslyvaniaCourts from "./States/PennslyvaniaCourts";
import PennsylvaniaGovernor from "./States/PennsylvaniaGovernor";

// Rhode Island
import RhodeIslandBills from "./States/RhodeIslandBills";
import RhodeIslandCourts from "./States/RhodeIslandCourts";
import RhodeIslandGovernor from "./States/RhodeIslandGovernor";

// South Carolina
import SouthCarolinaBills from "./States/SouthCarolinaBills";
import SouthCarolinaCourts from "./States/SouthCarolinaCourts";
import SouthCarolinaGovernor from "./States/SouthCarolinaGovernor";

// South Dakota
import SouthDakotaBills from "./States/SouthDakotaBills";
import SouthDakotaCourts from "./States/SouthDakotaCourts";
import SouthDakotaGovernor from "./States/SouthDakotaGovernor";

// Tennessee
import TennesseeBills from "./States/TennesseeBills";
import TennesseeCourts from "./States/TennesseeCourts";
import TennesseeGovernor from "./States/TennesseeGovernor";

// Texas
import TexasBills from "./States/TexasBills";
import TexasCourts from "./States/TexasCourts";
import TexasGovernor from "./States/TexasGovernor";

// Utah
import UtahBills from "./States/UtahBills";
import UtahCourts from "./States/UtahCourts";
import UtahGovernor from "./States/UtahGovernor";

// Vermont
import VermontBills from "./States/VermontBills";
import VermontCourts from "./States/VermontCourts";
import VermontGovernor from "./States/VermontGovernor";

// Virginia
import VirginiaBills from "./States/VirginiaBills";
import VirginiaCourts from "./States/VirginiaCourts";
import VirginiaGovernor from "./States/VirginiaGovernor";

// Washington
import WashingtonBills from "./States/WashingtonBills";
import WashingtonCourts from "./States/WashingtonCourts";
import WashingtonGovernor from "./States/WashingtonGovernor";

// West Virginia
import WestVirginiaBills from "./States/WestVirginiaBills";
import WestVirginiaCourts from "./States/WestVirginiaCourts";
import WestVirginiaGovernor from "./States/WestVirginiaGovernor";

// Wisconsin
import WisconsinBills from "./States/WisconsinBills";
import WisconsinCourts from "./States/WisconsinCourts";
import WisconsinGovernor from "./States/WisconsinGovernor";

// Wyoming
import WyomingBills from "./States/WyomingBills";
import WyomingCourts from "./States/WyomingCourts";
import WyomingGovernor from "./States/WyomingGovernor";
import YukonNews from "./Canada/Provinces/YukonNews";
import AlbertaNews from "./Canada/Provinces/AlbertaNews";
import BritishColumbiaNews from "./Canada/Provinces/BritishColumbiaNews";
import ManitobaNews from "./Canada/Provinces/ManitobaNews";
import NewBrunswickNews from "./Canada/Provinces/NewBrunswickNews";
import NewfoundlandAndLabradorNews from "./Canada/Provinces/NewfoundlandAndLabradorNews";
import NorthwestTerritoriesNews from "./Canada/Provinces/NorthwestTerritoriesNews";
import NoviaScotiaNews from "./Canada/Provinces/NoviaScotiaNews";
import NunavutNews from "./Canada/Provinces/NunavutNews";
import OntarioNews from "./Canada/Provinces/OntarioNews";
import PrinceEdwardIslandNews from "./Canada/Provinces/PrinceEdwardIslandNews";
import SaskatchewanNews from "./Canada/Provinces/SaskatchewanNews";
import QuebecNews from "./Canada/Provinces/QuebecNews";

var homeState = localStorage.getItem('homeState')


if (localStorage.getItem('homeState') != null)
{
  homeState = homeState.slice(0, -4);
  homeState = homeState.charAt(0).toUpperCase() + homeState.slice(1);
}

var mobileCarouselInterval = 60000;

export const DisplayHomeStateMobile= () =>   {
    const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
    const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
    const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
    const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    const [agenciesModalIsOpen, setAgenciesModalIsOpen] = useState(false)
    if (localStorage.getItem('homeState') === 'alabamaNews') {
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item interval={mobileCarouselInterval}>


<div id='executiveConMobile' >




<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}> Bill Updates</h5></Row>

 {CreateFeedItemStateBills('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/AL_json.xml', 'alabamaBills', 'Bill Updates', 'N/A', 'N/A', Alabama)}
        

  <div class='courtNewsMobile' id='alabamaBills'> </div>

  </Col>




</div>
  <Modal id='mobileWhiteHouseFeedModal' show={whiteHouseFeedModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setWhiteHouseFeedModalIsOpen(false)}>
  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Bill Updates</h3></Modal.Header>
  <Alabama/>
</Modal>

  </Carousel.Item>


  <Carousel.Item interval={mobileCarouselInterval}>

  


<div id='executiveConMobile' >

              
 

<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='alabamaGovernor'> {CreateFeedItemStateGovernor('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/alabama.xml', 'alabamaGovernor', 'Alabama Governor', 'N/A', 'Executive', AlabamaGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Governor</h3></Modal.Header>
                  <AlabamaGovernor/>
                </Modal>
  </Col>       

  </div>
                  <Modal id='mobileWhiteHouseFeedModal' show={goaReportsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setGAOReportsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Alabama Secretary of State</h3></Modal.Header>
                  <AlabamaSoS/>
                </Modal>

  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  


<div id='executiveConMobile' >

              
 

<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setGAOReportsModalIsOpen(true)}> Secretary of State</h5></Row>


  <div class='courtNewsMobile' id='alabamaSoS'> {CreateFeedItem('https://www.sos.alabama.gov/newsroom/rss?_ga=2.44886628.1762786429.1626009166-1870547084.1626009166', 'alabamaSoS', 'Alabama Secretary of State', 'Executive', 'N/A', AlabamaSoS)}</div>

  </Col>
  </div>
                  <Modal id='mobileWhiteHouseFeedModal' show={goaReportsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setGAOReportsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Alabama Secretary of State</h3></Modal.Header>
                  <AlabamaSoS/>
                </Modal>

  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>

  <div id='executiveConMobile' >
            <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setAgenciesModalIsOpen(true)}> Agencies</h5></Row>


 <div class='courtNewsMobile' id='alAgencies'> {CreateFeedItem('http://www.media.alabama.gov/rss/rss.aspx', 'alAgencies', 'Alabama Agencies', 'Executive', 'N/A', AlabamaAgencies)}</div>
 </Col>
  </div>

 <Modal id='mobileWhiteHouseFeedModal' show={agenciesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAgenciesModalIsOpen(false)}>
 <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Alabama Agencies</h3></Modal.Header>
 <AlabamaAgencies/>
</Modal>

 </Carousel.Item>

 <Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/ala_json.xml', 'alCourts', 'Alabama Courts', 'Judicial', 'N/A', AlabamaCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Alabama Courts</h3></Modal.Header>
<AlabamaCourts/>
</Modal>

</Carousel.Item>


            </Carousel>
            </>
        )
        
      } else if (localStorage.getItem('homeState') === 'alaskaNews') {
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>

            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/AK_json.xml',
        'Bills',
        'Bill Updates',
        'N/A',
        'N/A',
        Alaska
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Bill Updates</h3>
    </Modal.Header>
    <Alaska />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>
          Governor
        </h5>
      </Row>
      <div class='courtNewsMobile' id='AlaskaFeed'>
        {CreateFeedItemStateGovernor(
          'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/alaska.xml',
          'AlaskaFeed',
          'Alaska Governor',
          'N/A',
          'Executive',
          AlaskaGovernor
        )}
      </div>
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={trendingBillsModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setTrendingBillsModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Alaska Governor</h3>
    </Modal.Header>
    <AlaskaGovernor />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}>
          Supreme Courts
        </h5>
      </Row>
      <div class='courtNewsMobile' id='alCourts'>
        {CreateFeedItemStateCourt(
          'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/alaska_json.xml',
          'alCourts',
          'Alaska Courts',
          'Judicial',
          'N/A',
          AlaskaCourts
        )}
      </div>
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={courtModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setCourtModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Alaska Courts</h3>
    </Modal.Header>
    <AlaskaCourts />
  </Modal>
</Carousel.Item>


                </Carousel>
                </>
        )
        
      } else if (localStorage.getItem('homeState') === 'arizonaNews') {

        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/AZ_json.xml',
        'Bills',
        'Arizona Bill Updates',
        'N/A',
        'N/A',
        Arizona
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Arizona Bill Updates</h3>
    </Modal.Header>
    <Arizona/>
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>
          Governor
        </h5>
      </Row>
      <div class='courtNewsMobile' id='ArizonaFeed'>
        {CreateFeedItemStateGovernor(
          
          'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/arizona.xml',
          'ArizonaFeed',
          'Arizona Governor',
          'N/A',
          'Executive',
          ArizonaGovernor
        )}
      </div>
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={trendingBillsModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setTrendingBillsModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Arizona Governor</h3>
    </Modal.Header>
    <ArizonaGovernor />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}>
          Supreme Courts
        </h5>
      </Row>
      <div class='courtNewsMobile' id='alCourts'>
        {CreateFeedItemStateCourt(
          'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/ariz_json.xml',
          'alCourts',
          'Arizona Courts',
          'Judicial',
          'N/A',
          ArizonaCourts
        )}
      </div>
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={courtModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setCourtModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Arizona Courts</h3>
    </Modal.Header>
    <ArizonaCourts />
  </Modal>
</Carousel.Item>

                </Carousel>
                </>
        )
     
      } else if (localStorage.getItem('homeState') === 'arkansasNews') {


        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/AR_json.xml',
        'Bills',
        'Bill Updates',
        'N/A',
        'N/A',
        ArkansasBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Bill Updates</h3>
    </Modal.Header>
    <ArkansasBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>
          Governor
        </h5>
      </Row>
      <div class='courtNewsMobile' id='ArkansasFeed'>
        {CreateFeedItemStateGovernor(
          'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/arkansas.xml',
          'ArkansasFeed',
          'Arkansas Governor',
          'N/A',
          'Executive',
          ArkansasGovernor
        )}
      </div>
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={trendingBillsModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setTrendingBillsModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Arkansas Governor</h3>
    </Modal.Header>
    <ArkansasGovernor />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}>
          Supreme Courts
        </h5>
      </Row>
      <div class='courtNewsMobile' id='alCourts'>
        {CreateFeedItemStateCourt(
          'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/ark_json.xml',
          'alCourts',
          'Arkansas Courts',
          'Judicial',
          'N/A',
          ArkansasCourts
        )}
      </div>
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={courtModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setCourtModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Arkansas Courts</h3>
    </Modal.Header>
    <ArkansasCourts />
  </Modal>
</Carousel.Item>
                </Carousel>
                </>
        )
        
      } else if (localStorage.getItem('homeState') === 'californiaNews') {
        
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>

            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/CA_json.xml',
        'Bills',
        'California Bill Updates',
        'N/A',
        'N/A',
        CaliforniaBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>California Bill Updates</h3>
    </Modal.Header>
    <CaliforniaBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  


<div id='executiveConMobile' >

              
 

<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='caliGovernor'> {CreateFeedItemStateGovernor('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/california.xml', 'caliGovernor', 'California Governor', 'N/A', 'Executive', CaliforniaGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3> California Governor</h3></Modal.Header>
                  <CaliforniaGovernor/>
                </Modal>
  </Col>       

  </div>
      

  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/cal_json.xml', 'alCourts', 'California Courts', 'Judicial', 'N/A', CaliforniaCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>California Courts</h3></Modal.Header>
<CaliforniaCourts/>
</Modal>

</Carousel.Item>

                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'coloradoNews') {
     
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/CO_json.xml',
        'Bills',
        'Colorado Bill Updates',
        'N/A',
        'N/A',
        ColoradoBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Colorado Bill Updates</h3>
    </Modal.Header>
    <ColoradoBills />
  </Modal>
</Carousel.Item>

<Carousel.Item>
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='colGovernor'> {CreateFeedItemStateGovernor('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/colorado.xml', 'colGovernor', 'Colorado Governor', 'N/A', 'Executive', ColoradoGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3> Colorado Governor</h3></Modal.Header>
                  <ColoradoGovernor/>
                </Modal>
  </Col>      
  </div>
  </Carousel.Item>
  <Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/colo_json.xml', 'alCourts', 'Colorado Courts', 'Judicial', 'N/A', ColoradoCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Colorado Courts</h3></Modal.Header>
<ColoradoCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'connecticutNews') {
        
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/CT_json.xml',
        'Bills',
        'Connecticut Bill Updates',
        'N/A',
        'N/A',
        ConnecticutBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Connecticut Bill Updates</h3>
    </Modal.Header>
    <ConnecticutBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  


<div id='executiveConMobile' >

              
 

<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='connecticutGovernor'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/connecticut.xml', 
  'connecticutGovernor', 'Connecticut Governor', 'N/A', 'Executive', 
  ConnecticutGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Connecticut Governor</h3></Modal.Header>
                  <ConnecticutGovernor/>
                </Modal>
  </Col>       

  </div>
            

  </Carousel.Item>
  <Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/conn_json.xml', 'alCourts', 'Connecticut Courts', 'Judicial', 'N/A', ConnecticutCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Connecticut Courts</h3></Modal.Header>
<ConnecticutCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'delawareNews') {
       
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/DE_json.xml',
        'Bills',
        'Delaware Bill Updates',
        'N/A',
        'N/A',
        DelawareBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Delaware Bill Updates</h3>
    </Modal.Header>
    <DelawareBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  


<div id='executiveConMobile' >

              
 

<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='delawareGov'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/delaware.xml', 
  'delawareGov', 'Delaware Governor', 'N/A', 'Executive', 
  DelawareGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Delaware Governor</h3></Modal.Header>
                  <DelawareGovernor/>
                </Modal>
  </Col>       
</div>

            

  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/del_json.xml', 'alCourts', 'Delaware Courts', 'Judicial', 'N/A', DelawareCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Delaware Courts</h3></Modal.Header>
<DelawareCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'floridaNews') {
        
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/FL_json.xml',
        'Bills',
        'Florida Bill Updates',
        'N/A',
        'N/A',
        FloridaBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Florida Bill Updates</h3>
    </Modal.Header>
    <FloridaBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='floridaGovernor'> {CreateFeedItemStateGovernor('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/florida.xml', 'floridaGovernor', 'Florida Governor', 'N/A', 'Executive', FloridaGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Florida Governor</h3></Modal.Header>
                  <FloridaGovernor/>
                </Modal>
  </Col>       
  </div>
  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/fla_json.xml', 'alCourts', 'Florida Courts', 'Judicial', 'N/A', FloridaCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Florida Courts</h3></Modal.Header>
<FloridaCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'georgiaNews') {
       
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/GA_json.xml',
        'Bills',
        'Georgia Bill Updates',
        'N/A',
        'N/A',
        GeorgiaBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Georgia Bill Updates</h3>
    </Modal.Header>
    <GeorgiaBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  



              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='georgiaFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/georgia.xml', 
  'georgiaFeed', 'Georgia Governor', 'N/A', 'Executive', 
  GeorgiaGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Georgia Governor</h3></Modal.Header>
                  <GeorgiaGovernor/>
                </Modal>
  </Col>       
</div>

            

  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/ga_json.xml', 'alCourts', 'Georgia Courts', 'Judicial', 'N/A', GeorgiaCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Georgia Courts</h3></Modal.Header>
<GeorgiaCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'hawaiiNews') {
   
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/HI_json.xml',
        'Bills',
        'Hawaii Bill Updates',
        'N/A',
        'N/A',
        HawaiiBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Hawaii Bill Updates</h3>
    </Modal.Header>
    <HawaiiBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='hawaiiFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/hawaii.xml', 
  'hawaiiFeed', 'Hawaii Governor', 'N/A', 'Executive', 
  HawaiiGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Hawaii Governor</h3></Modal.Header>
                  <HawaiiGovernor/>
                </Modal>
  </Col>       
</div>

            

  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/haw_json.xml', 'alCourts', 'Hawaii Courts', 'Judicial', 'N/A', HawaiiCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Hawaii Courts</h3></Modal.Header>
<HawaiiCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'idahoNews') {
        
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/ID_json.xml',
        'Bills',
        'Idaho Bill Updates',
        'N/A',
        'N/A',
        IdahoBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Idaho Bill Updates</h3>
    </Modal.Header>
    <IdahoBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='idahoFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/idaho.xml', 
  'idahoFeed', 'Idaho Governor', 'N/A', 'Executive', 
  IdahoGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Idaho Governor</h3></Modal.Header>
                  <IdahoGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>


  <Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/idaho_json.xml', 'alCourts', 'Idaho Courts', 'Judicial', 'N/A', IdahoCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Idaho Courts</h3></Modal.Header>
<IdahoCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'illinoisNews') {
       
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/IL_json.xml',
        'Bills',
        'Illinois Bill Updates',
        'N/A',
        'N/A',
        IllinoisBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Illinois Bill Updates</h3>
    </Modal.Header>
    <IllinoisBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='IllinoisFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/illinois.xml', 
  'IllinoisFeed', 'Illinois Governor', 'N/A', 'Executive', 
  IllinoisGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Illinois Governor</h3></Modal.Header>
                  <IllinoisGovernor/>
                </Modal>
  </Col>       
</div>

            

  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/ill_json.xml', 'alCourts', 'Illinois Courts', 'Judicial', 'N/A', IllinoisCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Illinois Courts</h3></Modal.Header>
<IllinoisCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'indianaNews') {

        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='inBills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/IN_json.xml',
        'inBills',
        'Indiana Bill Updates',
        'N/A',
        'N/A',
        IndianaBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Indiana Bill Updates</h3>
    </Modal.Header>
    <IndianaBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>
          Governor
        </h5>
      </Row>
      <div class='courtNewsMobile' id='IndianaFeed'>
        {CreateFeedItemStateGovernor(
          'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/indiana.xml',
          'IndianaFeed',
          'Indiana Governor',
          'N/A',
          'Executive',
          IndianaGovernor
        )}
      </div>
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={trendingBillsModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setTrendingBillsModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Indiana Governor</h3>
    </Modal.Header>
    <IndianaGovernor />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}>
          Supreme Courts
        </h5>
      </Row>
      <div class='courtNewsMobile' id='alCourts'>
        {CreateFeedItemStateCourt(
          'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/ind_json.xml',
          'alCourts',
          'Indiana Courts',
          'Judicial',
          'N/A',
          IndianaCourts
        )}
      </div>
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={courtModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setCourtModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Indiana Courts</h3>
    </Modal.Header>
    <IndianaCourts/>
  </Modal>
</Carousel.Item>


            </Carousel>
            </>
        )
        
      } else if (localStorage.getItem('homeState') === 'iowaNews') {
      
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/IA_json.xml',
        'Bills',
        'Iowa Bill Updates',
        'N/A',
        'N/A',
        IowaBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Iowa Bill Updates</h3>
    </Modal.Header>
    <IowaBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='IowaFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/iowa.xml', 
  'IowaFeed', 'Iowa Governor', 'N/A', 'Executive', 
  IowaGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Iowa Governor</h3></Modal.Header>
                  <IowaGovernor/>
                </Modal>
  </Col>       
</div>
  
            

  </Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/iowa_json.xml', 'alCourts', 'Iowa Courts', 'Judicial', 'N/A', IowaCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Iowa Courts</h3></Modal.Header>
<IowaCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'kansasNews') {
       
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/KS_json.xml',
        'Bills',
        'Kansas Bill Updates',
        'N/A',
        'N/A',
        KansasBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Kansas Bill Updates</h3>
    </Modal.Header>
    <KansasBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='KansasFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/kansas.xml', 
  'KansasFeed', 'Kansas Governor', 'N/A', 'Executive', 
  KansasGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Kansas Governor</h3></Modal.Header>
                  <KansasGovernor/>
                </Modal>
  </Col>       
  </div>

  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/kan_json.xml', 'alCourts', 'Kansas Courts', 'Judicial', 'N/A', KansasCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Kansas Courts</h3></Modal.Header>
<KansasCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'kentuckyNews') {
        
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/KY_json.xml',
        'Bills',
        'Kentucky Bill Updates',
        'N/A',
        'N/A',
        KentuckyBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Kentucky Bill Updates</h3>
    </Modal.Header>
    <KentuckyBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='KentuckyFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/kentucky.xml', 
  'KentuckyFeed', 'Kentucky Governor', 'N/A', 'Executive', 
  KentuckyGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Kentucky Governor</h3></Modal.Header>
                  <KentuckyGovernor/>
                </Modal>
  </Col>       
</div>
            

  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>
         
<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/ky_json.xml', 'alCourts', 'Kentucky Courts', 'Judicial', 'N/A', KentuckyCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Kentucky Courts</h3></Modal.Header>
<KentuckyCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'louisianaNews') {
        
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/LA_json.xml',
        'Bills',
        'Lousiana Bill Updates',
        'N/A',
        'N/A',
        LouisianaBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Lousiana Bill Updates</h3>
    </Modal.Header>
    <LouisianaBills />
  </Modal>
</Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='LouisianaFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/louisiana.xml', 
  'LouisianaFeed', 'Louisiana Governor', 'N/A', 'Executive', 
  LouisianaGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Louisiana Governor</h3></Modal.Header>
                  <LouisianaGovernor/>
                </Modal>
  </Col>       
</div>
       

  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/la_json.xml', 'alCourts', 'Louisiana Courts', 
  'Judicial', 'N/A', LouisianaCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Louisiana Courts</h3></Modal.Header>
<LouisianaCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'maineNews') {
        
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/ME_json.xml',
        'Bills',
        'Maine Bill Updates',
        'N/A',
        'N/A',
        MaineBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Maine Bill Updates</h3>
    </Modal.Header>
    <MaineBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='MaineFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/maine.xml', 
  'MaineFeed', 'Maine Governor', 'N/A', 'Executive', 
  MaineGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Maine Governor</h3></Modal.Header>
                  <MaineGovernor/>
                </Modal>
  </Col>       
</div>
            

  </Carousel.Item>

                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'marylandNews') {

        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/MD_json.xml',
        'Bills',
        'Maryland Bill Updates',
        'N/A',
        'N/A',
        MarylandBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Maryland Bill Updates</h3>
    </Modal.Header>
    <MarylandBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='MarylandFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/maryland.xml', 
  'MarylandFeed', 'Maryland Governor', 'N/A', 'Executive', 
  MarylandGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Maryland Governor</h3></Modal.Header>
                  <MarylandGovernor/>
                </Modal>
  </Col>       
</div>
  
            

  </Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'massachusettsNews') {
        
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/MA_json.xml',
        'Bills',
        'Massachusetts Bill Updates',
        'N/A',
        'N/A',
        MassachusettsBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Massachusetts Bill Updates</h3>
    </Modal.Header>
    <MassachusettsBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='MassachusettsFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/massachusetts.xml', 
  'MassachusettsFeed', 'Massachusetts Governor', 'N/A', 'Executive', 
  MassachusettsGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Massachusetts Governor</h3></Modal.Header>
                  <MassachusettsGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'michiganNews') {
     
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/MI_json.xml',
        'Bills',
        'Michigan Bill Updates',
        'N/A',
        'N/A',
        MichiganBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Michigan Bill Updates</h3>
    </Modal.Header>
    <MichiganBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='MichiganFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/michigan.xml', 
  'MichiganFeed', 'Michigan Governor', 'N/A', 'Executive', 
  MichiganGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Michigan Governor</h3></Modal.Header>
                  <MichiganGovernor/>
                </Modal>
  </Col>       
</div>

            

  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/mich_json.xml', 'alCourts', 'Michigan Courts', 
  'Judicial', 'N/A', MichiganCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Michigan Courts</h3></Modal.Header>
<MichiganCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'minnesotaNews') {
        
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/NM_json.xml',
        'Bills',
        'Minnesota Bill Updates',
        'N/A',
        'N/A',
        MinnesotaBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Minnesota Bill Updates</h3>
    </Modal.Header>
    <MinnesotaBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/minn_json.xml', 'alCourts', 'Minnesota Courts', 
  'Judicial', 'N/A', MinnesotaCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Minnesota Courts</h3></Modal.Header>
<MinnesotaCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'mississippiNews') {
        
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/MS_json.xml',
        'Bills',
        'Mississippi Bill Updates',
        'N/A',
        'N/A',
        MississippiBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Mississippi Bill Updates</h3>
    </Modal.Header>
    <MississippiBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='MississippiFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/mississippi.xml', 
  'MississippiFeed', 'Mississippi Governor', 'N/A', 'Executive', 
  MississippiGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Mississippi Governor</h3></Modal.Header>
                  <MississippiGovernor/>
                </Modal>
  </Col>       
</div>

            

  </Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/miss_json.xml', 'alCourts', 'Mississippi Courts', 
  'Judicial', 'N/A', MississippiCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Mississippi Courts</h3></Modal.Header>
<MississippiCourts/>
</Modal>

</Carousel.Item>

                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'missouriNews') {
      
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/MO_json.xml',
        'Bills',
        'Missouri Bill Updates',
        'N/A',
        'N/A',
        MissouriBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Missouri Bill Updates</h3>
    </Modal.Header>
    <MissouriBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='MissouriFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/missouri.xml', 
  'MissouriFeed', 'Missouri Governor', 'N/A', 'Executive', 
  MissouriGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Missouri Governor</h3></Modal.Header>
                  <MissouriGovernor/>
                </Modal>
  </Col>       
</div>

            

  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/mo_json.xml', 'alCourts', 'Missouri Courts', 
  'Judicial', 'N/A', MissouriCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Missouri Courts</h3></Modal.Header>
<MississippiCourts/>
</Modal>

</Carousel.Item>

                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'montanaNews') {
    
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/MT_json.xml',
        'Bills',
        'Montana Bill Updates',
        'N/A',
        'N/A',
        MontanaBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Montana Bill Updates</h3>
    </Modal.Header>
    <MontanaBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='MontanaFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/montana.xml', 
  'MontanaFeed', 'Montana Governor', 'N/A', 'Executive', 
  MontanaGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Montana Governor</h3></Modal.Header>
                  <MontanaGovernor/>
                </Modal>
  </Col>       
</div>

            

  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/mont_json.xml', 'alCourts', 'Montana Courts', 
  'Judicial', 'N/A', MontanaCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Montana Courts</h3></Modal.Header>
<MontanaCourts/>
</Modal>

</Carousel.Item>

                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'nebraskaNews') {
       
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/NE_json.xml',
        'Bills',
        'Nebraska Bill Updates',
        'N/A',
        'N/A',
        NebraskaBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Nebraska Bill Updates</h3>
    </Modal.Header>
    <NebraskaBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='NebraskaFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/nebraska.xml', 
  'NebraskaFeed', 'Nebraska Governor', 'N/A', 'Executive', 
  NebraskaGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Nebraska Governor</h3></Modal.Header>
                  <NebraskaGovernor/>
                </Modal>
  </Col>       
</div>

            

  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/neb_json.xml', 'alCourts', 'Nebraska Courts', 
  'Judicial', 'N/A', NebraskaCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Nebraska Courts</h3></Modal.Header>
<NebraskaCourts/>
</Modal>

</Carousel.Item>

                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'nevadaNews') {
       
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/NV_json.xml',
        'Bills',
        'Nevada Bill Updates',
        'N/A',
        'N/A',
        NevadaBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Nevada Bill Updates</h3>
    </Modal.Header>
    <NevadaBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='NevadaFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/nevada.xml', 
  'NevadaFeed', 'Nevada Governor', 'N/A', 'Executive', 
  NevadaGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Nevada Governor</h3></Modal.Header>
                  <NevadaGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/nev_json.xml', 'alCourts', 'Nevada Courts', 
  'Judicial', 'N/A', NevadaCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Nevada Courts</h3></Modal.Header>
<NevadaCourts/>
</Modal>

</Carousel.Item>

                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'newHampshireNews') {
       
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/us/newhampshire', "_self")}> <House id='homeStateIcon'></House>  New Hampshire <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>

 
 
<div id='executiveConMobile' >
             
 
             <Col>
                 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>  Bill Updates</h5></Row>
 
 
       
                  <div class='whMobile' id='newHampshireBills'> </div>


                  {CreateFeedItemStateBills('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/NH_json.xml', 'newHampshireBills', 'New Hampshire Bill Updates', 'N/A', 'N/A', NewHampshireBills )}
 
 </Col>
 </div>
 </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='NewHampshireFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/new_hampshire.xml', 
  'NewHampshireFeed', 'New Hampshire Governor', 'N/A', 'Executive', 
  NewHampshireGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>New Hampshire Governor</h3></Modal.Header>
                  <NewHampshireGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/nh_json.xml', 'alCourts', 'New Hampshire Courts', 
  'Judicial', 'N/A', NewHampshireCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>New Hampshire Courts</h3></Modal.Header>
<NewHampshireCourts/>
</Modal>

</Carousel.Item>

                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'newJerseyNews') {
        
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/us/newjersey', "_self")}> <House id='homeStateIcon'></House>  New Jersey <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>

            <Carousel.Item>

<div id='executiveConMobile' >
             
 
             <Col>
                 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}> Bill Updates</h5></Row>
 

                  <div class='whMobile' id='newJerseyBills'> </div>


                  {CreateFeedItemStateBills('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/NJ_json.xml', 'newJerseyBills', 'New Jersey Bill Updates', 'N/A', 'N/A', NewJerseyBills )}
 </Col>
 </div>
 <Modal id='mobileWhiteHouseFeedModal' show={whiteHouseFeedModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setWhiteHouseFeedModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>New Jersey Bill Updates</h3></Modal.Header>
                  <NewJerseyBills/>
                </Modal>
 </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='NewJerseyFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/new_jersey.xml', 
  'NewJerseyFeed', 'New Jersey Governor', 'N/A', 'Executive', 
  NewJerseyGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>New Jersey Governor</h3></Modal.Header>
                  <NewJerseyGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/nj_json.xml', 'alCourts', 'New Jersey Courts', 
  'Judicial', 'N/A', NewJerseyCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>New Jersey Courts</h3></Modal.Header>
<NewJerseyCourts/>
</Modal>

</Carousel.Item>

                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'newMexicoNews') {
      
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/us/newmexico', "_self")}> <House id='homeStateIcon'></House>  New Mexico <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>

<div id='executiveConMobile' >
             
 
             <Col>
                 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>  Bill Updates</h5></Row>
 
        
                  <div class='whMobile' id='newMexicoBills'> </div>

                  {CreateFeedItemStateBills('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/NM_json.xml', 'newMexicoBills', 'New Mexico Bill Updates', 'N/A', 'N/A', NewMexicoBills )}

</Col>
</div>
<Modal id='mobileWhiteHouseFeedModal' show={whiteHouseFeedModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setWhiteHouseFeedModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>New Mexico Bill Updates</h3></Modal.Header>
                  <NewMexicoBills/>
                </Modal>
 </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 

<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='NewMexicoFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/new_mexico.xml', 
  'NewMexicoFeed', 'New Mexico Governor', 'N/A', 'Executive', 
  NewMexicoGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>New Mexico Governor</h3></Modal.Header>
                  <NewMexicoGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/nm_json.xml', 'alCourts', 'New Mexico Courts', 
  'Judicial', 'N/A', NewMexicoCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>New Mexico Courts</h3></Modal.Header>
<NewMexicoCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'newYorkNews') {
       
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/us/newyork', "_self")}> <House id='homeStateIcon'></House>  New York <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>

<div id='executiveConMobile' >
             
 
             <Col>
                 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>  Bill Updates</h5></Row>
          
 
                  <div class='whMobile' id='newYorkBills'> </div>


                  {CreateFeedItemStateBills('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/NY_json.xml', 'newYorkBills', 'New York Bill Updates', 'N/A', 'N/A', NewYorkBills )}
 </Col>
 </div>
 <Modal id='mobileWhiteHouseFeedModal' show={whiteHouseFeedModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setWhiteHouseFeedModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>New York Bill Updates</h3></Modal.Header>
                  <NewYorkBills/>
                </Modal>
 </Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='NewYorkFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/new_york.xml', 
  'NewYorkFeed', 'New York Governor', 'N/A', 'Executive', 
  NewYorkGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>New York Governor</h3></Modal.Header>
                  <NewYorkGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'northCarolinaNews') {
       
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/us/northcarolina', "_self")}> <House id='homeStateIcon'></House>  North Carolina <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
<div id='executiveConMobile' >
             
 
             <Col>
                 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>  Bill Updates</h5></Row>
  
       
 
                  <div class='whMobile' id='northCarolinaBills'> </div>

                    {CreateFeedItemStateBills('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/NC_json.xml', 'northCarolinaBills', 'North Carolina Bill Updates', 'N/A', 'N/A', NorthCarolinaBills )}
                    <Modal id='mobileWhiteHouseFeedModal' show={whiteHouseFeedModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setWhiteHouseFeedModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>North Carolina Bill Updates</h3></Modal.Header>
                  <NorthCarolinaBills/>
                </Modal>
 </Col>
 </div>
 
 </Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='NorthCarolinaFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/north_carolina.xml', 
  'NorthCarolinaFeed', 'North Carolina Governor', 'N/A', 'Executive', 
  NorthCarolinaGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>North Carolina Governor</h3></Modal.Header>
                  <NorthCarolinaGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/nc_json.xml', 'alCourts', 'North Carolina Courts', 
  'Judicial', 'N/A', NorthCarolinaCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>North Carolina Courts</h3></Modal.Header>
<NorthCarolinaCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'northDakotaNews') {
     
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/us/northdakota', "_self")}> <House id='homeStateIcon'></House>  North Dakota <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>


            <Carousel.Item>
<div id='executiveConMobile' >
             
 
             <Col>
                 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>  Bill Updates</h5></Row>
 
  

                  <div class='whMobile' id='northDakotaBills'> </div>


                  {CreateFeedItemStateBills('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/ND_json.xml', 'northDakotaBills', 'North Dakota Bill Updates', 'N/A', 'N/A', NorthDakotaBills )}

                  <Modal id='mobileWhiteHouseFeedModal' show={whiteHouseFeedModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setWhiteHouseFeedModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>North Dakota Bill Updates</h3></Modal.Header>
                  <NorthDakotaBills/>
                </Modal>
</Col>
</div>
 </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='NorthDakotaFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/north_dakota.xml', 
  'NorthDakotaFeed', 'North Dakota Governor', 'N/A', 'Executive', 
  NorthDakotaGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>North Dakota Governor</h3></Modal.Header>
                  <NorthDakotaGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/nd_json.xml', 'alCourts', 'North Dakota Courts', 
  'Judicial', 'N/A', NorthDakotaCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>North Dakota Courts</h3></Modal.Header>
<NorthDakotaCourts/>
</Modal>

</Carousel.Item>

                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'ohioNews') {
     
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/OH_json.xml',
        'Bills',
        'Ohio Bill Updates',
        'N/A',
        'N/A',
        OhioBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Ohio Bill Updates</h3>
    </Modal.Header>
    <OhioBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='OhioFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/ohio.xml', 
  'OhioFeed', 'Ohio Governor', 'N/A', 'Executive', 
  OhioGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Ohio Governor</h3></Modal.Header>
                  <OhioGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/ohio_json.xml', 'alCourts', 'Ohio Courts', 
  'Judicial', 'N/A', OhioCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Ohio Courts</h3></Modal.Header>
<OhioCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'oklahomaNews') {
       
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/OK_json.xml',
        'Bills',
        'Oklahoma Bill Updates',
        'N/A',
        'N/A',
        OklahomaBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Oklahoma Bill Updates</h3>
    </Modal.Header>
    <OklahomaBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>


 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='OklahomaFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/oklahoma.xml', 
  'OklahomaFeed', 'Oklahoma Governor', 'N/A', 'Executive', 
  OklahomaGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Oklahoma Governor</h3></Modal.Header>
                  <OklahomaGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/okla_json.xml', 'alCourts', 'Oklahoma Courts', 
  'Judicial', 'N/A', OklahomaCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Oklahoma Courts</h3></Modal.Header>
<OklahomaCourts/>
</Modal>

</Carousel.Item>

                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'oregonNews') {
        
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/OR_json.xml',
        'Bills',
        'Oregon Bill Updates',
        'N/A',
        'N/A',
        OregonBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Oregon Bill Updates</h3>
    </Modal.Header>
    <OregonBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='OregonFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/oregon.xml', 
  'OregonFeed', 'Oregon Governor', 'N/A', 'Executive', 
  OregonGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Oregon Governor</h3></Modal.Header>
                  <OregonGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/or_json.xml', 'alCourts', 'Oregon Courts', 
  'Judicial', 'N/A', OregonCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Oregon Courts</h3></Modal.Header>
<OregonCourts/>
</Modal>

</Carousel.Item>

                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'pennslyvaniaNews') {
       
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/PA_json.xml',
        'Bills',
        'Pennsylvania Bill Updates',
        'N/A',
        'N/A',
        PennsylvaniaBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Pennsylvania Bill Updates</h3>
    </Modal.Header>
    <PennsylvaniaBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='pennsylvaniaGovernor'> {CreateFeedItemStateGovernor('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/pennsylvania.xml', 'pennsylvaniaGovernor', 'Pennsylvania Governor', 'N/A', 'Executive', PennsylvaniaGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Pennsylvania Governor</h3></Modal.Header>
                  <PennsylvaniaGovernor/>
                </Modal>
  </Col>       
  </div>
  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/pa_json.xml', 'alCourts', 'Pennsylvania Courts', 
  'Judicial', 'N/A', PennslyvaniaCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Pennsylvania Courts</h3></Modal.Header>
<PennslyvaniaCourts/>
</Modal>

</Carousel.Item>

                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'rhodeIslandNews') {
       
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/us/rhodeisland', "_self")}> <House id='homeStateIcon'></House>  Rhode Island <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>


            <Carousel.Item>
<div id='executiveConMobile' >
             
 
             <Col>
                 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}> Rhode Island Bill Updates</h5></Row>
 
            
                  <div class='whMobile' id='rhodeIslandBills'></div>

                  {CreateFeedItemStateBills('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/RI_json.xml', 'rhodeIslandBills', 'Rhode Island Bill Updates', 'N/A', 'N/A', RhodeIslandBills )}
 
 
 </Col>
 </div>
 <Modal id='mobileWhiteHouseFeedModal' show={whiteHouseFeedModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setWhiteHouseFeedModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Rhode Island Bill Updates</h3></Modal.Header>
                  <RhodeIslandBills/>
                </Modal>
 </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='RhodeIslandFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/rhode_island.xml', 
  'RhodeIslandFeed', 'Rhode Island Governor', 'N/A', 'Executive', 
  RhodeIslandGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Rhode Island Governor</h3></Modal.Header>
                  <RhodeIslandGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/ri_json.xml', 'alCourts', 'Rhode Island Courts', 
  'Judicial', 'N/A', RhodeIslandCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Rhode Island Courts</h3></Modal.Header>
<RhodeIslandCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'southCarolinaNews') {
       
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/us/southcarolina', "_self")}> <House id='homeStateIcon'></House>  South Carolina <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
 
 <div id='executiveConMobile' >
              
  
              <Col>
                  <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}> Bill Updates</h5></Row>
    
  
                   <div class='whMobile' id='southCarolinaBills'> </div>
 
                       {CreateFeedItemStateBills('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/SC_json.xml', 'southCarolinaBills', 'South Carolina Bill Updates', 'N/A', 'N/A', SouthCarolinaBills )}
  </Col>
  </div>
  <Modal id='mobileWhiteHouseFeedModal' show={whiteHouseFeedModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setWhiteHouseFeedModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>South Carolina Bill Updates</h3></Modal.Header>
                  <SouthCarolinaBills/>
                </Modal>
  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='SouthCarolinaFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/south_carolina.xml', 
  'SouthCarolinaFeed', 'South Carolina Governor', 'N/A', 'Executive', 
  SouthCarolinaGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>South Carolina Governor</h3></Modal.Header>
                  <SouthCarolinaGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/sc_json.xml', 'alCourts', 'South Carolina Courts', 
  'Judicial', 'N/A', SouthCarolinaCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>South Carolina Courts</h3></Modal.Header>
<SouthCarolinaCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'southDakotaNews') {
       
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/us/southdakota', "_self")}> <House id='homeStateIcon'></House>  South Dakota <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
<div id='executiveConMobile' >
             
 
             <Col>
                 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}> Bill Updates</h5></Row>

 
                  <div class='whMobile' id='southDakotaBills'></div>

                  {CreateFeedItemStateBills('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/SD_json.xml', 'southDakotaBills', 'South Dakota Bill Updates', 'N/A', 'N/A', SouthDakotaBills )}
 
 </Col>
 </div>

 <Modal id='mobileWhiteHouseFeedModal' show={whiteHouseFeedModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setWhiteHouseFeedModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>South Dakota Bill Updates</h3></Modal.Header>
                  <SouthDakotaBills/>
                </Modal>
 </Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='SouthDakotaFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/south_dakota.xml', 
  'SouthDakotaFeed', 'South Dakota Governor', 'N/A', 'Executive', 
  SouthDakotaGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>South Dakota Governor</h3></Modal.Header>
                  <SouthDakotaGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/sd_json.xml', 'alCourts', 'South Dakota Courts', 
  'Judicial', 'N/A', SouthDakotaCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>South Dakota Courts</h3></Modal.Header>
<SouthDakotaCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'tennesseeNews') {
        
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/TN_json.xml',
        'Bills',
        'Tennessee Bill Updates',
        'N/A',
        'N/A',
        TennesseeBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Tennessee Bill Updates</h3>
    </Modal.Header>
    <TennesseeBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='TennesseeFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/tennessee.xml', 
  'TennesseeFeed', 'Tennessee Governor', 'N/A', 'Executive', 
  TennesseeGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Tennessee Governor</h3></Modal.Header>
                  <TennesseeGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/tenn_json.xml', 'alCourts', 'Tennessee Courts', 
  'Judicial', 'N/A', TennesseeCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Tennessee Courts</h3></Modal.Header>
<TennesseeCourts/>
</Modal>

</Carousel.Item>

                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'texasNews') {
      
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/TX_json.xml',
        'Bills',
        'Texas Bill Updates',
        'N/A',
        'N/A',
        TexasBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Texas Bill Updates</h3>
    </Modal.Header>
    <TexasBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='texasGovernor'> {CreateFeedItemStateGovernor('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/texas.xml', 'texasGovernor', 'Texas Governor', 'N/A', 'Executive', TexasGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Texas Governor</h3></Modal.Header>
                  <TexasGovernor/>
                </Modal>
  </Col>       
  </div>
  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/tex_json.xml', 'alCourts', 'Texas Courts', 
  'Judicial', 'N/A', TexasCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Texas Courts</h3></Modal.Header>
<TexasCourts/>
</Modal>

</Carousel.Item>


                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'utahNews') {
        
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/UT_json.xml',
        'Bills',
        'Utah Bill Updates',
        'N/A',
        'N/A',
        UtahBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Utah Bill Updates</h3>
    </Modal.Header>
    <UtahBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='UtahFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/utah.xml', 
  'UtahFeed', 'Utah Governor', 'N/A', 'Executive', 
  UtahGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Utah Governor</h3></Modal.Header>
                  <UtahGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/utah_json.xml', 'alCourts', 'Utah Courts', 
  'Judicial', 'N/A', UtahCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Utah Courts</h3></Modal.Header>
<UtahCourts/>
</Modal>

</Carousel.Item>

                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'vermontNews') {
      
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/VT_json.xml',
        'Bills',
        'Vermont Bill Updates',
        'N/A',
        'N/A',
        VermontBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Vermont Bill Updates</h3>
    </Modal.Header>
    <VermontBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='VermontFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/vermont.xml', 
  'VermontFeed', 'Vermont Governor', 'N/A', 'Executive', 
  VermontGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Vermont Governor</h3></Modal.Header>
                  <VermontGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/vt_json.xml', 'alCourts', 'Vermont Courts', 
  'Judicial', 'N/A', VermontCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Vermont Courts</h3></Modal.Header>
<VermontCourts/>
</Modal>

</Carousel.Item>

                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'virginiaNews') {
        
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/VA_json.xml',
        'Bills',
        'Virginia Bill Updates',
        'N/A',
        'N/A',
        VirginiaBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Virginia Bill Updates</h3>
    </Modal.Header>
    <VirginiaBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='VirginiaFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/virginia.xml', 
  'VirginiaFeed', 'Virginia Governor', 'N/A', 'Executive', 
  VirginiaGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Virginia Governor</h3></Modal.Header>
                  <VirginiaGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>



<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/va_json.xml', 'alCourts', 'Virginia Courts', 
  'Judicial', 'N/A', VirginiaCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Virginia Courts</h3></Modal.Header>
<VirginiaCourts/>
</Modal>

</Carousel.Item>

                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'washingtonNews') {
        
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/WA_json.xml',
        'Bills',
        'Washington Bill Updates',
        'N/A',
        'N/A',
        WashingtonBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Washington Bill Updates</h3>
    </Modal.Header>
    <WashingtonBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='WashingtonFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/washington.xml', 
  'WashingtonFeed', 'Washington Governor', 'N/A', 'Executive', 
  WashingtonGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Washington Governor</h3></Modal.Header>
                  <WashingtonGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/wash_json.xml', 'alCourts', 'Washington Courts', 
  'Judicial', 'N/A', WashingtonCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Washington Courts</h3></Modal.Header>
<WashingtonCourts/>
</Modal>

</Carousel.Item>


                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'westVirginiaNews') {
       
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/us/westvirginia', "_self")}> <House id='homeStateIcon'></House>  West Virginia <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
<div id='executiveConMobile' >
             
 
             <Col>
                 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>  Bill Updates</h5></Row>
 
       
                  <div class='whMobile' id='westVirginiaBills'> </div>

                  {CreateFeedItemStateBills('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/WV_json.xml', 'westVirginiaBills', 'West Virginia Bill Updates', 'N/A', 'N/A', WestVirginiaBills)}
 
 
 </Col>
 </div>

 <Modal id='mobileWhiteHouseFeedModal' show={whiteHouseFeedModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setWhiteHouseFeedModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>West Virginia Bill Updates</h3></Modal.Header>
                  <WestVirginiaBills/>
                </Modal>
 </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='WestVirginiaFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/west_virginia.xml', 
  'WestVirginiaFeed', 'West Virginia Governor', 'N/A', 'Executive', 
  WestVirginiaGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>West Virginia Governor</h3></Modal.Header>
                  <WestVirginiaGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/wva_json.xml', 'alCourts', 'West Virginia Courts', 
  'Judicial', 'N/A', WestVirginiaCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>West Virginia Courts</h3></Modal.Header>
<WestVirginiaCourts/>
</Modal>

</Carousel.Item>

                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'wisconsinNews') {
      
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/WI_json.xml',
        'Bills',
        'Wisconsin Bill Updates',
        'N/A',
        'N/A',
        WisconsinBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Wisconsin Bill Updates</h3>
    </Modal.Header>
    <WisconsinBills />
  </Modal>
</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='WisconsinFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/wisconsin.xml', 
  'WisconsinFeed', 'Wisconsin Governor', 'N/A', 'Executive', 
  WisconsinGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Wisconsin Governor</h3></Modal.Header>
                  <WisconsinGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/wis_json.xml', 'alCourts', 'Wisonsin Courts', 
  'Judicial', 'N/A', WisconsinCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Wisonsin Courts</h3></Modal.Header>
<WisconsinCourts/>
</Modal>

</Carousel.Item>
                </Carousel>
                </>
        )
      } else if (localStorage.getItem('homeState') === 'wyomingNews') {
        
        return(
            <>
              <h5 id='branchHeadingMobile' onClick={() => window.open('/'+homeState, "_self")}> <House id='homeStateIcon'></House>  {homeState} <ChevronRight id='clickChevron'></ChevronRight></h5>
            <Carousel controls={false}>
            <Carousel.Item>
  <div id='executiveConMobile'>
    <Col>
      <Row id='mobileFeedHeadingRow'>
        <h5 id='feedHeadingMobile' onClick={() => setWhiteHouseFeedModalIsOpen(true)}>
          Bill Updates
        </h5>
      </Row>
      <div class='whMobile' id='Bills'></div>
      {CreateFeedItemStateBills(
        'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state-xml/WY_json.xml',
        'Bills',
        'Wyoming Bill Updates',
        'N/A',
        'N/A',
        WyomingBills
      )}
    </Col>
  </div>
  <Modal
    id='mobileWhiteHouseFeedModal'
    show={whiteHouseFeedModalIsOpen}
    size='xl'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    onHide={() => setWhiteHouseFeedModalIsOpen(false)}
  >
    <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton>
      <h3>Wyoming Bill Updates</h3>
    </Modal.Header>
    <WyomingBills />
  </Modal>
</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

  




              
 
<div id='executiveConMobile' >
<Col>
 <Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setTrendingBillsModalIsOpen(true)}>Governor</h5></Row>


  <div class='courtNewsMobile' id='WyomingFeed'> {CreateFeedItemStateGovernor
  ('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_newsroom-xml/wyoming.xml', 
  'WyomingFeed', 'Wyoming Governor', 'N/A', 'Executive', 
  WyomingGovernor)}</div>


  <Modal id='mobileWhiteHouseFeedModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Wyoming Governor</h3></Modal.Header>
                  <WyomingGovernor/>
                </Modal>
  </Col>       
</div>


  </Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='executiveConMobile' >
          <Col>

<Row id = 'mobileFeedHeadingRow'> <h5 id='feedHeadingMobile' onClick={() => setCourtModalIsOpen(true)}> Supreme Court</h5></Row>


<div class='courtNewsMobile' id='alCourts'> {CreateFeedItemStateCourt(
  'https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/wyo_json.xml', 'alCourts', 'Wyoming Courts', 
  'Judicial', 'N/A', WyomingCourts)}</div>
</Col>
</div>

<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>Wyoming Courts</h3></Modal.Header>
<WyomingCourts/>
</Modal>

</Carousel.Item>

                </Carousel>
                </>
        )
      }

    else{
        return null;
    }

}







export default DisplayHomeStateMobile;