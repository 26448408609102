import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Coin, Tree, ChevronRight, Bricks, Lightbulb } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import  "../../App.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import CanadaMobileTopNavbar from '../CanadaMobileTopNavbar';
import { CreateFeedItem } from '../../Functions';
import GDPUpdates from '../../GDPUpdates';
import MobileOffCanvas from '../../MobileOffCanvas';
import CanadaBusinessNews from './CanadaBusinessNews';
import StandingCommitteeOnFinance from '../Legislative/StandingCommitteeOnFinance';
import StandingCommitteeOnInternationalTrade from '../Legislative/StandingCommitteeOnInternationalTrade';
import StandingCommitteeOnNaturalResources from '../Legislative/StandingCommitteeOnNaturalResources';
import StandingCommitteeOnEnvironment from '../Legislative/StandingCommitteeOnEnvironment';
;




var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;


export const CanadaEnergyMobile = () =>   {
    render()
 
    {
      const nullEntry = [];
      const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
      const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
      const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
      const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
      const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
      const [trendingBillsOptionSix, setTrendingBillsOptionSixModalIsOpen ] = useState(false)
      const [trendingBillsOptionSeven, setTrendingBillsOptionSevenModalIsOpen ] = useState(false)
      const [trendingBillsOptionEight, setTrendingBillsOptionEightModalIsOpen ] = useState(false)
      const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
      const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
      const [trendingBillsOptionEleven, setTrendingBillsOptionElevenModalIsOpen ] = useState(false)
      const [trendingBillsOptionTweleve, setTrendingBillsOptionTweleveModalIsOpen ] = useState(false)
      const [trendingBillsOptionThirteen, setTrendingBillsOptionThirteenModalIsOpen ] = useState(false)
  

    //const [ModalIsOpen, setModalIsOpen] = useState(false)
    
    var branch = "all";


  
    return(
       <>
      <CanadaMobileTopNavbar/>

       <div id='homebg'>
      
     


           <h5 id='branchHeadingMobile' ><Lightbulb/> Energy </h5>


           <Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setTrendingBillsOptionFourModalIsOpen(true)}>Standing Committee on Environment and Sustainable Development</h5>
  <div id='committee5'></div>
  {CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/envi.xml', 'committee5', 'Standing Committee on Environment and Sustainable Development', 'Legislative', 'N/A', StandingCommitteeOnEnvironment)}

  <Modal id='mobileFullBillModal' show={trendingBillsOptionFour} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionFourModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Standing Committee on Environment and Sustainable Development</h3></Modal.Header>
<StandingCommitteeOnEnvironment/>
</Modal>
</Col>


<Col>
  <h5 id='presidentialFeedHeadingMobile'onClick={() => setTrendingBillsOptionElevenModalIsOpen(true)}>Standing Committee on Natural Resources</h5>
  <div id='committee12'></div>
  {CreateFeedItem('https://gov-glance-canada.nyc3.digitaloceanspaces.com/legislative-xml/committees/rnnr.xml', 'committee12', 'Standing Committee on Natural Resources', 'Legislative', 'N/A', StandingCommitteeOnNaturalResources)}


  <Modal id='mobileFullBillModal' show={trendingBillsOptionEleven} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionElevenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Standing Committee on Natural Resources</h3></Modal.Header>
<StandingCommitteeOnNaturalResources/>
</Modal>
</Col>

<Col>

<br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>


     
           <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
         
          </div>

          </>
  )
}
 }



  






export default CanadaEnergyMobile;

