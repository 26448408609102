import App from "./App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, lazy, Suspense } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter, Spinner } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, FolderPlus, Files, Folder, HeartFill, PauseCircle, ChevronRight, Pen, ChatLeftQuote, PlayCircle } from 'react-bootstrap-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserView, MobileView } from 'react-device-detect';
import { render } from '@testing-library/react';
import GAOReports from "./GAOReports";
import CongressionalBudgetOffice from "./CongressionalBudgetOffice";
import LegislativeBranchMobile from "./LegislativeBranchMobile";
import { congressSearch, CreateFeedItem, CreateFeedItemGAOReports, CreateFeedAPI
} from './Functions'
import DesktopNavbar from "./DesktopNavbar";
import GenerateFeed from "./GenerateFeed";
import GenerateFeedPageCreator from "./GenerateFeedPageCreator";
import RepresenativesDisplay from "./RepresenativesDisplay";
import SenateDisplay from "./SenateDisplay";
import HouseOfRepsDisplay from "./HouseOfRepsDisplay";
import FederalElectionCommissionPageMobile from "./FederalElectionCommissionPageMobile";

var carouselInterval = 30000;
const HeavyDependencyComponent = lazy(() => import('./GenerateFeed'));
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);

export const FederalElectionCommissionPage = () => {
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };
  render()

  {
    const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
    const [showBioModal, setBioModal] = useState(false);
    return (
      <>
        <BrowserView>
          <div id='homePage'>


            <div id='homebg'>
              <DesktopNavbar />


     

<div style={{ position: 'relative' }}>

<div id='profileImage' style={{ width: '130px', height: '130px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '60px' }}>
<Image id='profileImage-image' src='/fecSeal.svg' />
</div>


<div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
<div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Federal Election Commission</h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '16px' }}>
<span> 
The Federal Election Commission (FEC) is the independent regulatory agency charged with administering and enforcing the federal campaign finance law. The FEC has jurisdiction over the financing of campaigns ...</span>
 
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

  <span><Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.fec.gov/", "_blank");
   }}>
     https://www.fec.gov/
     </Link></span> 
<br></br>
<Button id='whDocsViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button> 
</div>
</div>

</div>
</div>


</div>
<br></br>
<br></br><br></br>
<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> Federal Election Commission</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>The Federal Election Commission (FEC) is the independent regulatory agency charged with administering and enforcing the federal campaign finance law. The FEC has jurisdiction over the financing of campaigns for the U.S. House, Senate, Presidency and the Vice Presidency.</p>

         <strong>Mission</strong>
<p>To protect the integrity of the federal campaign finance process by providing transparency and fairly enforcing and administering federal campaign finance laws.</p>

<strong>History</strong>

<p>As early as 1905, President Theodore Roosevelt recognized the need for campaign finance reform and called for legislation to ban corporate contributions for political purposes. In response, Congress enacted several statutes between 1907 and 1966.</p>

<p>In 1971, Congress consolidated its earlier reform efforts in the Federal Election Campaign Act, instituting more stringent disclosure requirements for federal candidates, political parties and political action committees (PACs). Still, without a central administrative authority, the campaign finance laws were difficult to enforce.</p>

<p>Following reports of serious financial abuses in the 1972 presidential campaign, Congress amended the Federal Election Campaign Act in 1974 to set limits on contributions by individuals, political parties and PACs. The 1974 amendments also established an independent agency, the FEC. The FEC opened its doors in 1975.</p>

<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.fec.gov/about/mission-and-history/", "_blank");
   }}>
     https://www.fec.gov/about/mission-and-history/
     </Link>
 </div>

       </Modal>

       <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferOne'>
                    <Row>

                      <Col id='legislativeColRight' >
                        <div id='columnRightPadding'>
                          <h4 id='billFeedHeading' onClick={() =>
                            openModal('Federal Election Commission News',
                              <GenerateFeed
                                url='&skip=0&schema=united_states_of_america&table=federal_election_commission&order_by=created_at'
                                name='Federal Election Commission News' branch='N/A' topic='Election' buttonName='Doc' />)}><b>News</b></h4>


                          <div id='bills'></div>
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=federal_election_commission&order_by=created_at', 'bills',
                            'Federal Election Commission News', 'N/A',
                            'Elections', 'Doc', GenerateFeed)}

                        </div>
                      </Col>



               
                      <Col id='legislativeColRight' >
                        <div id='columnRightPadding'>

                       
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>

         




              <footer id='homeFooter'>
                <Navbar>
                  <Row id='footerNav'>
                    <Nav variant='pills'>

                      <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
                      </Nav.Link>
                      <Nav.Item>
                        <Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link id='footerContent' onClick={(e) => {
                          e.preventDefault();
                          window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                        }}> <HeartFill id='donateIcon' />    Donate</Nav.Link>
                      </Nav.Item>
                      <Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>


                    </Nav>
                  </Row>
                </Navbar>
              </footer>





            </div>
          </div>
        </BrowserView>

        <MobileView>
          <FederalElectionCommissionPageMobile/>

        </MobileView>
      </>
    )
  }
}










export default FederalElectionCommissionPage;