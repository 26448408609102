// useHandleContainerClick.js
import { useHistory } from 'react-router-dom';

const UseHandleContainerClickVicePresident = () => {
  const history = useHistory();

  const handleContainerClickVicePresident = (name, termStart, termEnd, imageSource, description) => {
    // Save the name here or perform any other necessary actions


    // Navigate to the representative page with bioGuideId and name as state
    history.push(`/us/vicepresident/${name}`, { 
      name,
      termStart,
      termEnd,
      imageSource,
      description
    });
  };

  return handleContainerClickVicePresident;
};

export default UseHandleContainerClickVicePresident;
