import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';

const GDPChart = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dateRange, setDateRange] = useState('all'); // '1year', '10years', 'all'

  // Styles (Dark Theme)
  const styles = {
    container: {
      padding: '2rem',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#001f3f', // Dark blue background
      color: '#ffffff', // White text
      minHeight: '100vh',
    },
    heading: {
      textAlign: 'center',
      marginBottom: '2rem',
      fontSize: '2rem',
    },
    chartContainer: {
      margin: '0 auto',
      maxWidth: '800px',
    },
    dateRangeSelector: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '1rem',
    },
    button: {
      margin: '0 0.5rem',
      padding: '0.5rem 1rem',
      backgroundColor: '#0074D9',
      color: '#ffffff',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      fontWeight: 'bold',
    },
    activeButton: {
      backgroundColor: '#7FDBFF',
      color: '#001f3f',
    },
  };

  useEffect(() => {
    // Path to your CSV file in the public folder
    const csvFilePath = `/datasets/GDP.csv`;

    // Fetch the CSV file
    fetch(csvFilePath)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then((csvData) => {
        // Parse the CSV data
        Papa.parse(csvData, {
          header: false, // Assuming the CSV doesn't have headers
          delimiter: '\t', // Assuming tab-delimited data
          skipEmptyLines: 'greedy',
          complete: function (results) {
            console.log(results.data)
            const parsedData = results.data
              .filter((item) => item.length >= 2 && item[0] && item[1])
              .map((item) => {
                const dateStr = item[0].trim();
                const gdpStr = item[1].trim();
                const [year, month, day] = dateStr.split('-').map(Number);
                const date = new Date(year, month - 1, day);
                if (isNaN(date)) {
                  console.error(`Invalid date: ${dateStr}`);
                }
                const gdp = parseFloat(gdpStr);
                return {
                  date: date.getTime(),
                  dateStr: dateStr,
                  gdp,
                };
              })
              .filter((item) => !isNaN(item.date) && !isNaN(item.gdp))
              .sort((a, b) => a.date - b.date);
            setData(parsedData);
          },
          error: function (error) {
            console.error('Error parsing CSV:', error);
          },
        });
      })
      .catch((error) => {
        console.error('Error fetching the CSV file:', error);
      });
  }, []);

  // Update filtered data based on selected date range
  useEffect(() => {
    if (data.length > 0) {
      const now = new Date(data[data.length - 1].date);
      let startDate;

      if (dateRange === '1year') {
        startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
      } else if (dateRange === '10years') {
        startDate = new Date(now.getFullYear() - 10, now.getMonth(), now.getDate());
      } else {
        startDate = new Date(data[0].date); // All time
      }

      const filtered = data.filter((item) => item.date >= startDate.getTime());
      setFilteredData(filtered);
    }
  }, [data, dateRange]);

  // Function to format date strings to 'MMM yyyy' (e.g., 'Jan 1947')
  const formatDateFromTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    if (isNaN(date)) {
      console.error(`Invalid date timestamp: ${timestamp}`);
      return '';
    }
    const options = { year: 'numeric', month: 'short' };
    return date.toLocaleDateString(undefined, options);
  };

  // Function to format numbers with dollar sign and commas
  const formatGDP = (value) => `$${Number(value).toLocaleString()}`;

  // Handle date range change
  const handleDateRangeChange = (range) => {
    setDateRange(range);
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>US GDP Over Time</h2>
      {/* Date Range Selector */}
      <div style={styles.dateRangeSelector}>
        <button
          style={{
            ...styles.button,
            ...(dateRange === '1year' ? styles.activeButton : {}),
          }}
          onClick={() => handleDateRangeChange('1year')}
        >
          1 Year
        </button>
        <button
          style={{
            ...styles.button,
            ...(dateRange === '10years' ? styles.activeButton : {}),
          }}
          onClick={() => handleDateRangeChange('10years')}
        >
          10 Years
        </button>
        <button
          style={{
            ...styles.button,
            ...(dateRange === 'all' ? styles.activeButton : {}),
          }}
          onClick={() => handleDateRangeChange('all')}
        >
          All Time
        </button>
      </div>
      <div style={styles.chartContainer}>
        {filteredData.length > 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={filteredData}>
              <CartesianGrid stroke="#ffffff" strokeDasharray="5 5" />
              <XAxis
                dataKey="date"
                type="number"
                domain={['auto', 'auto']}
                scale="time"
                stroke="#ffffff"
                tickFormatter={(unixTime) => formatDateFromTimestamp(unixTime)}
                tick={{ fill: '#ffffff' }}
              />
              <YAxis
                stroke="#ffffff"
                tickFormatter={(value) => `$${value.toLocaleString()}`}
                label={{
                  value: 'GDP (Billions of Dollars)',
                  angle: -90,
                  position: 'insideLeft',
                  fill: '#ffffff',
                }}
                tick={{ fill: '#ffffff' }}
              />
              <Tooltip
                formatter={(value) => formatGDP(value)}
                labelFormatter={(label) => `Date: ${formatDateFromTimestamp(label)}`}
                contentStyle={{
                  backgroundColor: '#001f3f',
                  border: '1px solid #ffffff',
                  color: '#ffffff',
                }}
              />
              <Line
                type="monotone"
                dataKey="gdp"
                stroke="#7FDBFF"
                strokeWidth={2}
                dot={{ r: 2 }}
                activeDot={{ r: 4 }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <p style={{ textAlign: 'center' }}>Loading data...</p>
        )}
      </div>
    </div>
  );
};

export default GDPChart;