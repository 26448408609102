
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand,  ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Tree, FileMedical, FileMedicalFill, ChevronRight, Bricks, Lightbulb  } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import {createDepartmentOfHealthAndHumanServices, 
  createCDCNewsroom, createCDCOutbreaks, 
  createCDCTravelNotices, createFoodSafety, 
  createFoodRecalls, createMedWatch, 
  createHealthyLivingFeatures, 
  createPreventingChronicDiseases, 
  createCovid19, createNovelCoronavirus, 
  createVaccineUpdates, createSeasonalFlu, Speak, CreateFeedItem, CreateFeedItemMaterials, CreateFeedAPI, CreateFeedItemBillWithDescription} from "./Functions";
  import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
  import DepartmentOfEnergyDocs from './DepartmentOfEnergyDocs';
import BonnevillePowerAdministration from './BonnevillePowerAdministration';
import EnergyEfficiencyAndRenewableEnergyOffice from './EnergyEfficiencyAndRenewableEnergyOffice';
import EnergyInformationAdministration from './EnergyInformationAdministration';
import FederalEnergyRegulatoryCommission from './FederalEnergyRegulatoryCommission';
import NationalNuclearSecurityAdministration from './NationalNuclearSecurityAdministration';
import SoutheasternPowerAdministration from './SoutheasternPowerAdministration';
import SouthwesternPowerAdministration from './SouthwesternPowerAdministration';
import WesternAreaPowerAdministration from './WesternAreaPowerAdministration';

import HouseCommitteeOnNaturalResourcesMaterials from './HouseCommitteeOnNaturalResourcesMaterials';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import NaturalResourcesConservation from './NaturalResourcesConservation';
import SenateEnergyAndNRCommittee from './SenateEnergyAndNRCommittee';
import MobileTopNavbar from './MobileTopNavbar';
import MobileOffCanvas from './MobileOffCanvas';

var mobileCarouselInterval = 60000;

export const EnergyTopicMobile = () =>
 {
   render()
   {

    const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
    const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
    const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
    const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
    const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
    const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
    const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
    const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
    const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
    const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
    const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)
    const [armedServicesMaterialsModalIsOpen, setArmedServicesMaterialsModalIsOpen] = useState(false)
    const [committeeOnArmedServicesModalIsOpen, setCommitteeOnArmedServicesModalIsOpen] = useState(false)
    const [committeeOnIntelligenceModalIsOpen, setCommitteeOnIntelligenceModalIsOpen] = useState(false)
    const [intelligenceMaterialsModalIsOpen, setIntelligenceMaterialsModalIsOpen] = useState(false)
    const [committeeOnHomelandSecurityModalIsOpen, setCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
    const [homelandSecurityMaterialsModalIsOpen, setHomelandSecurityMaterialsModalIsOpen] = useState(false)
    const [committeeOnForeignAffairsModalIsOpen, setCommitteeOnForeignAffairsModalIsOpen] = useState(false)
    const [foreignAffairsMaterialsModalIsOpen, setForeignAffairsMaterialsModalIsOpen] = useState(false)
    const [homelandDocsModalIsOpen, setHomelandDocsModalIsOpen] = useState(false)
    const [coastGuardModalIsOpen, setCoastGuardModalIsOpen] = useState(false)
    const [femaModalIsOpen, setFEMAModalIsOpen] = useState(false)
    const [secretServiceModalIsOpen, setSecretServiceModalIsOpen] = useState(false)
    const [tsaModalIsOpen, setTSAModalIsOpen] = useState(false)
    const [immigrationModalIsOpen, setImmigrationModalIsOpen] = useState(false)
    const [usCustomsModalIsOpen, setUSCustomsModalIsOpen] = useState(false)
    const [enforcementsModalIsOpen, setEnforcemnetsModalIsOpen] = useState(false)
    const [counterterrorismModalIsOpen, setCounterterrorismModalIsOpen] = useState(false)
    const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [committeeOnTransportationModalIsOpen, setCommitteeOnTransportationModalIsOpen] = useState(false)
      const [transportationMaterialsModalIsOpen, setTransportationMaterialsModalIsOpen] = useState(false)
      const [committeeOnNaturalResourcesModalIsOpen, setCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
      const [naturalResourcesMaterialsModalIsOpen, setNaturalResourcesMaterialsModalIsOpen] = useState(false)
      const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
      const [energyModalIsOpen, setEnergyModalIsOpen] = useState(false)
      const [diplomaticSecurityModalIsOpen, setDiplomaticSecurityModalIsOpen] = useState(false)
        return(
    <>

      


<MobileTopNavbar/>


        <div id='homebg'>
    

            <h5 id='branchHeadingMobile' ><Lightbulb/> Energy  </h5>
<br></br>
            <h5 onClick={() => window.open('/us/departmentofenergy', "_self")} id='presidentialFeedHeadingMobile'>Department of Energy<ChevronRight id='clickChevron'></ChevronRight></h5>

<Col>

<Carousel controls={false} >
            
      
            
            <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>

<h5 id='presidentialFeedHeadingMobile' onClick={() => setDiplomaticSecurityModalIsOpen(true)}>Documents</h5>




<div class='courtNewsMobile' id='energyDepartment'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=energy-department', 'energyDepartment', 'Department of Energy', 'Executive', 'N/A', DepartmentOfEnergyDocs)}

    </Col>


    
    <Modal id='whiteHouseDocsModal' show={diplomaticSecurityModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDiplomaticSecurityModalIsOpen(false)}>
      <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Energy Documents</h3></Modal.Header>
      <DepartmentOfEnergyDocs />
    </Modal>



</div>



</Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentofenergy' >View all Department of Energy news</Button>
</div>
</Col>

</div>



</Carousel.Item>

            
            
            </Carousel>



    </Col>






                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setNavyModalIsOpen(true)}>Energy Information Administration</h5>


  <div class='courtNewsMobile' id='navyDocs'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=energy-information-administration', 'navyDocs', 'Energy Information Administration', 'Executive', 'N/A', EnergyInformationAdministration)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={navyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNavyModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Energy Information Administration</h3></Modal.Header>
                  <EnergyInformationAdministration/>
                </Modal>



                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setAirForceModalIsOpen(true)}>Federal Energy Regulatory Commission</h5>


  <div class='courtNewsMobile' id='airForce'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=federal-energy-regulatory-commission', 'airForce', 'Federal Energy Regulatory Commission', 'Executive', 'N/A', FederalEnergyRegulatoryCommission)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={airForceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAirForceModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Energy Regulatory Commission</h3></Modal.Header>
                  <FederalEnergyRegulatoryCommission/>
                </Modal>

                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setMarinesModalIsOpen(true)}>Energy Efficiency and Renewable Energy Office</h5>


  <div class='courtNewsMobile' id='marinesPress'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=energy-efficiency-and-renewable-energy-office', 'marinesPress', 'Energy Efficiency and Renewable Energy Office', 'Executive', 'N/A', EnergyEfficiencyAndRenewableEnergyOffice)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={marinesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setMarinesModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Energy Efficiency and Renewable Energy Office</h3></Modal.Header>
                  <EnergyEfficiencyAndRenewableEnergyOffice/>
                </Modal>

                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setSpaceForceModalIsOpen(true)}>National Nuclear Security Administration</h5>


  <div class='courtNewsMobile' id='spaceForce'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=national-nuclear-security-administration', 'spaceForce', 'National Nuclear Security Administration', 'Executive', 'N/A', NationalNuclearSecurityAdministration)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={spaceForceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSpaceForceModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Nuclear Security Administration</h3></Modal.Header>
                  <NationalNuclearSecurityAdministration/>
                </Modal>


                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setNationalGuardModalIsOpen(true)}>Southeastern Power Administration</h5>


  <div class='courtNewsMobile' id='nationalGuard'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=southeastern-power-administration', 'nationalGuard', 'Southeastern Power Administration', 'Executive', 'N/A', SoutheasternPowerAdministration)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={nationalGuardModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNationalGuardModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Southeastern Power Administration</h3></Modal.Header>
                  <SoutheasternPowerAdministration/>
                </Modal>




                
                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setNSAModalIsOpen(true)}>Southwestern Power Administration</h5>


  <div class='courtNewsMobile' id='nsa'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=southwestern-power-administration', 'nsa', 'Southwestern Power Administration', 'Executive', 'N/A', SouthwesternPowerAdministration)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={nsaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNSAModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Southwestern Power Administration</h3></Modal.Header>
                  <SouthwesternPowerAdministration/>
                </Modal>

                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setArmyCorpsModalIsOpen(true)}>Western Area Power Administration</h5>


  <div class='courtNewsMobile' id='armyCorps'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=western-area-power-administration', 'armyCorps', 'Western Area Power Administration', 'Executive', 'N/A', WesternAreaPowerAdministration)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={armyCorpsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmyCorpsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Western Area Power Administration</h3></Modal.Header>
                  <WesternAreaPowerAdministration/>
                </Modal>

                <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setArmyModalIsOpen(true)}>Bonneville Power Administration</h5>


  <div class='courtNewsMobile' id='armyDocs'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=bonneville-power-administration', 'armyDocs', 'Bonneville Power Administration', 'Executive', 'N/A', BonnevillePowerAdministration)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={armyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmyModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Bonneville Power Administration</h3></Modal.Header>
                  <BonnevillePowerAdministration/>
                </Modal>

           
                <Col>
                    
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnNaturalResourcesModalIsOpen(true)}>Committee on Natural Resources</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnNaturalResources'></div>
                  



                        {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=II00', 'houseCommitteeOnNaturalResources', 'House Committee on Natural Resources', 'Legislative', 'Environment', HouseCommitteeOnNaturalResources)}


                </Col>

                <Modal id='fullBillModal' show={committeeOnNaturalResourcesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnNaturalResourcesModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Natural Resources</h3></Modal.Header>

<HouseCommitteeOnNaturalResources/>
</Modal>

                <Col>
                    
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setNaturalResourcesMaterialsModalIsOpen(true)}>Committee on Natural Resources Materials</h5>
                        <div class='courtNewsMobile'id='houseCommitteeOnNaturalResourcesMaterials'></div>
                  



                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/houseCommittees/Committee%20on%20Natural%20Resources.xml', 'houseCommitteeOnNaturalResourcesMaterials', 'House Committee on Natural Resources Materials', 'Legislative', 'Environment', HouseCommitteeOnNaturalResourcesMaterials)}

    
                    </Col>

                    <Modal id='fullBillModal' show={naturalResourcesMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNaturalResourcesMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on Natural Resources Materials</h3></Modal.Header>

<HouseCommitteeOnNaturalResourcesMaterials/>
</Modal>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setNASAModalIsOpen(true)}>Natural Resources Conservation Service</h5>


  <div class='courtNewsMobile' id='naturalResources'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=natural-resources-conservation-service', 'naturalResources', 'Natural Resources Conservation Service', 'Executive', 'N/A', NaturalResourcesConservation)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={nasaModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNASAModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Natural Resources Conservation Service</h3></Modal.Header>
                  <NaturalResourcesConservation/>
                </Modal>

                <Col>
              
              <h5 id='presidentialFeedHeadingMobile' onClick={() => setEnergyModalIsOpen(true)}>Committee on Energy and Natural Resources Materials</h5>
                      <div class='courtNewsMobile'id='senateEnergyCommittee'></div>
                
                        {CreateFeedItemMaterials('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/legislative-xml/senateCommittees/Committee%20on%20Energy%20and%20Natural%20Resources.xml', 'senateEnergyCommittee', 'Senate Committee on Energy and Natural Resources', 'Legislative', 'Environment', SenateEnergyAndNRCommittee)}

              
                        <Modal id='fullBillModal' show={energyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setEnergyModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on Energy and Natural Resources Materials</h3></Modal.Header>
            <SenateEnergyAndNRCommittee/>
          </Modal>

              </Col>
     
        
          <Col>

            </Col>


            <Col>
            <br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288',"_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>

            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
</div>




             
              
    </>
  )
}
 }



  






export default EnergyTopicMobile;