
import { BrowserRouter as Link, } from "react-router-dom";
import { Navbar, Nav, Button, FormControl, Dropdown, Image, Row } from 'react-bootstrap';
import { HeartFil, HeartFill } from 'react-bootstrap-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';



import MobileOffCanvas from "./MobileOffCanvas";
import { BrowserView, MobileView } from "react-device-detect";
import MobileTopNavbar from "./MobileTopNavbar";
import DesktopNavbar from "./DesktopNavbar";
import DesktopNavbarSearch from "./DesktopNavbarSearch";
import NavbarSearchButtonNoCountry from "./NavbarSearchButtonNoCountry";
import MobileOffCanvasHome from "./MobileOffCanvasHome";



export const About = () =>   {
    render()
    {
    return(
       <>
              <BrowserView>
       <div id='homebg'>

       <DesktopNavbarSearch/>
       <NavbarSearchButtonNoCountry/>
           <div id='homebg'>  
           <div style={{height: '100px'}}></div>
           <div id='textPage'>
          <h3 id='aboutParagraph'>About</h3>
           <p id='aboutParagraph'>Gov Glance is a civic engagement tool designed to help citizens 
                                      get official government news in an easy-to-navigate centralized location. 
                                  There will never be any opinion or political analysis(That's your job), only information from official government 
                                  sources.
                                  </p>
                    
                                  <p id='aboutParagraph'>
                                  Gov Glance Foundation Inc. is a non-profit dedicated to creating free educational tools and resources. <a id='govglanceLink' href='https://govglance.foundation/'>https://govglance.foundation/</a>
                                  </p>
                                 
                                  <div id='centerButton'><Button id='allPageButtonMobile' onClick={(e) => {
                                            e.preventDefault();
                                            window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288')
                                          }}><HeartFill id='donateIcon'/>    Donate</Button></div>
             
                      </div>

                      </div>
                      <br></br> <br></br> <br></br> <br></br> <br></br>
                           <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>
          </div>
</BrowserView>
       <MobileView>
       <div id='homebg'>
       <div id='mobileNavbarSpacer'></div>
       <MobileTopNavbar/>
           <div id='homebg'>  
           <div id='textPageMobile'>
           <h3 id='aboutParagraph'>About</h3>
           <p id='aboutParagraph'>Gov Glance is a civic engagement tool designed to help citizens 
                                      get official government news in an easy-to-navigate centralized location. 
                                  There will never be any opinion or political analysis(That's your job), only information from official government 
                                  sources.
                                  </p>
                    
                                  <p id='aboutParagraph'>
                                  Gov Glance Foundation Inc. is a non-profit dedicated to creating free educational tools and resources. <a id='govglanceLink' href='https://govglance.foundation/'>https://govglance.foundation/</a>
                                  </p>
                                 
                                  <div id='centerButton'><Button id='allPageButtonMobile' onClick={(e) => {
                                            e.preventDefault();
                                            window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288')
                                          }}><HeartFill id='donateIcon'/>    Donate</Button></div>
       
                  </div>
                      </div>

           <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    
<div id='changeBranchDropdownMobile'>
<MobileOffCanvasHome/>
     
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
         
          </div>
</MobileView>
          </>
  )
}
 }



  






export default About;




