import React, { useState, useEffect } from 'react';
import { Button, Card, Container, Form, Image, Navbar, Spinner } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { BrowserView, MobileView } from 'react-device-detect';
import MobileBackButton from './MobileBackButton';
import MobileOffCanvas from './MobileOffCanvas';
import useHandleContainerClickJustice from './UseHandleContainerClickJustice';


function formatDate(inputDate) {
    const date = new Date(inputDate);
    
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
  
    return `${month}-${day}-${year}`;
  }


function Justices() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const handleContainerClick = useHandleContainerClickJustice();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  require('dotenv').config();
  const apiKey = process.env.REACT_APP_API_KEY;
  const requestOptions = {
    method: 'GET',
    headers: { 
      'Content-Type': 'application/json',
      "Authorization": "Bearer "+apiKey,
  },
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        const response = await fetch('https://api.govglance.org/members/justices?limit=50&skip=0', requestOptions);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
   // console.log(data); // Logging fetched data
  }, [data]);

  useEffect(() => {
    setFilteredData(data.filter(item =>
      item.full_name.toLowerCase().includes(searchTerm.toLowerCase())
    ));
  }, [data, searchTerm]);
  
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };
  
  const handleSearchButtonClick = () => {
    // You can apply the same filtering logic here as in useEffect to filter data
    setFilteredData(data.filter(item =>
      item.full_name.toLowerCase().includes(searchTerm.toLowerCase())
    ));
  };

  const getOrdinalSuffix = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
};
  
  const renderData = () => {
    const renderItems = loading ? (
      <Spinner animation="grow" variant="light" />
    ) : error ? (
      <p>Error: {error.message}</p>
    ) : (
      filteredData.map((item, index) => {
        const titleToDisplay = item.full_name;
        const termStart = item.term_start_date;
        const termEnd = item.term_end_date;
  
        const imageSource = item.url;
        const description = item.description
        return (
          <div id='feedDisplay' key={index}>
            <div  onClick={() => handleContainerClick(titleToDisplay, termStart, termEnd, imageSource, description)}>
              <Container id='feedContainerNormalView'>

              <div style={{display: 'flex',
                flexDirection: 'row'}}>
    {imageSource && (
      <div id='profileImage'>
      <Image id='profileImage-image' src={imageSource} />
    </div>
    )}

    <div style={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
    <div style={{color: 'white', fontSize: '18px',
                  fontWeight: 'bold'}}>
<span>{titleToDisplay}</span>
</div>
<div  style={{color: 'white', fontSize: '16px'}}> 
 <span> U.S. Justice</span>
      </div>
      </div>


      </div>
             
             
              </Container>
            </div>
          </div>
        );
      })
    );
    return renderItems;
  };

  return (
<>
    <BrowserView>
    <div id='homebg'>
    <div id='feedDisplay'>
      <div id='searchFormPage'>
      <Form className="text-center" onSubmit={(e) => { e.preventDefault(); }}>
        <Form.Group className="d-flex justify-content-center align-items-center">
          <Form.Control
            variant="light"
            id="searchInput"
            placeholder="Search..."
            type="text"
            value={searchTerm}
            onChange={handleSearchInputChange}
            color="white"
          />
          <Button id='ggButton' onClick={handleSearchButtonClick}>
            <Search /> 
          </Button>
        </Form.Group>
      </Form>
      </div>
     {renderData()}



    </div>
    </div>
    </BrowserView>

    <MobileView>
    <div id='homebg'>
    <div id='feedDisplay'>
        <br></br>
        <br></br>
      <div id='searchFormPage'>
      <Form className="text-center" onSubmit={(e) => { e.preventDefault(); }}>
        <Form.Group className="d-flex justify-content-center align-items-center">
          <Form.Control
            variant="light"
            id="searchInput"
            placeholder="Search..."
            type="text"
            value={searchTerm}
            onChange={handleSearchInputChange}
            color="white"
          />
          <Button id='ggButton' onClick={handleSearchButtonClick}>
            <Search /> 
          </Button>
        </Form.Group>
      </Form>
      </div>
     {renderData()}





     <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>

            </div>

</Navbar>
    </div>
    </div>
    </MobileView>
    </>
  );
}

export default Justices;
