
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter, Offcanvas } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, FileMedicalFill, Coin, Tree, Globe2, GeoAlt,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, HeartFill, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, Circle, List, CaretLeftFill, ArrowLeftCircle } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


import { render } from '@testing-library/react';
export const MobileBackButton = () =>   {
  render()
 
 
  {

  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)

{



  return (
    
    <>
    
    <ArrowLeftCircle id='offCanvasIcon' size={30} onClick={(e) => {
              e.preventDefault();
              window.history.back()
            }}></ArrowLeftCircle>

    </>

     
    
  );





                    }
                  }
                }




export default MobileBackButton;
