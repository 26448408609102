import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import { Navbar, Nav, Container, Row, Col, Modal } from 'react-bootstrap';
import { HeartFill } from 'react-bootstrap-icons';
import StateDesktopNavbar from './States/StateDesktopNavbar';
import GenerateFeed from './GenerateFeed';
import { CreateFeedAPI } from './Functions';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MobileOffCanvas from './MobileOffCanvas';

// Abbreviations mapping (normalized state names to abbreviations)
const stateAbbreviations = {
  alabama: 'ala',
  alaska: 'alaska',
  arizona: 'ariz',
  arkansas: 'ark',
  california: 'cal',
  colorado: 'colo',
  connecticut: 'conn',
  delaware: 'del',
  florida: 'fla',
  georgia: 'ga',
  hawaii: 'haw',
  idaho: 'idaho',
  illinois: 'ill',
  indiana: 'ind',
  iowa: 'iowa',
  kansas: 'kan',
  kentucky: 'ky',
  louisiana: 'la',
  michigan: 'mich',
  minnesota: 'minn',
  mississippi: 'miss',
  missouri: 'mo',
  montana: 'mont',
  nebraska: 'neb',
  nevada: 'nev',
  newhampshire: 'nh',
  newjersey: 'nj',
  newmexico: 'nm',
  newyork: 'ny',
  northcarolina: 'nc',
  northdakota: 'nd',
  ohio: 'ohio',
  oklahoma: 'okla',
  oregon: 'or',
  pennsylvania: 'pa',
  rhodeisland: 'ri',
  southcarolina: 'sc',
  southdakota: 'sd',
  tennessee: 'tenn',
  texas: 'tex',
  utah: 'utah',
  vermont: 'vt',
  virginia: 'va',
  washington: 'wash',
  westvirginia: 'wva',
  wisconsin: 'wis',
  wyoming: 'wyo',
};

// Known states with spaces
const knownStatesWithSpaces = [
  'newyork',
  'newjersey',
  'newhampshire',
  'northcarolina',
  'southcarolina',
  'northdakota',
  'southdakota',
  'westvirginia',
  'rhodeisland',
];

// Function to format state names
const formatStateName = (state) => {
  console.log('formatStateName - original state:', state);

  let replacedState = state.replace(/[_\s]+/g, ' ').trim();
  console.log('formatStateName - replacedState:', replacedState);

  const normalizedState = replacedState.toLowerCase().replace(/\s+/g, '');
  console.log('formatStateName - normalizedState:', normalizedState);

  if (knownStatesWithSpaces.includes(normalizedState)) {
    replacedState = replacedState.replace(/(new|north|south|west|rhode)(?=[A-Za-z])/i, '$1 ');
    console.log('formatStateName - after handling known states with spaces:', replacedState);
  }

  const lowerCasedState = replacedState.toLowerCase();
  const splitWords = lowerCasedState.split(' ');
  const capitalizedWords = splitWords.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const finalStateName = capitalizedWords.join(' ');
  console.log('formatStateName - finalStateName:', finalStateName);

  return finalStateName;
};

const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal
    id="fullBillModal"
    show={isOpen}
    size="xl"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    onHide={onClose}
  >
    <Modal.Header id="billModalHeader" closeButton>
      <h3>{title}</h3>
    </Modal.Header>
    {content}
  </Modal>
);

const StateJudicialPage = () => {
  const { stateName } = useParams();
  const history = useHistory();

  console.log('StateJudicialPage - stateName from URL:', stateName);

  // Even if stateName is undefined, proceed to call Hooks
  // Normalize the state name from the URL
  const normalizedStateName = stateName ? stateName.trim().replace(/[_\s]+/g, '').toLowerCase() : '';
  console.log('StateJudicialPage - normalizedStateName:', normalizedStateName);

  // Get the abbreviation directly from the mapping
  const abbreviation = stateAbbreviations[normalizedStateName] || 'N/A';
  console.log('StateJudicialPage - abbreviation:', abbreviation);

  // Format the state name for display
  const formattedStateName = formatStateName(stateName || '');
  console.log('StateJudicialPage - formattedStateName:', formattedStateName);

  // Hooks must be called unconditionally
  useEffect(() => {
    if (!stateName || abbreviation === 'N/A') {
      console.log('StateJudicialPage - Invalid or undefined state name, redirecting to home');
      history.replace('/'); // Redirect to home if state name is invalid
    }
  }, [stateName, abbreviation, history]);

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null,
  });

  const openModal = (title, content) => {
    setModalInfo({ isOpen: true, title, content });
  };

  const closeModal = () => {
    setModalInfo({ isOpen: false, title: '', content: null });
  };

  // Now, you can return null or your JSX after Hooks have been called
  if (!stateName || abbreviation === 'N/A') {
    // Already redirected in useEffect
    return null;
  }

  return (
    <div key={stateName}>
      {/* Your existing component JSX goes here */}
      <BrowserView>
        <div id="homePage">
          <div id="homebg">
            <CustomModal
              isOpen={modalInfo.isOpen}
              onClose={closeModal}
              title={modalInfo.title}
              content={modalInfo.content}
            />
            {/* Use the formatted state name */}
            <StateDesktopNavbar state={formattedStateName} stateFlag="" />

            <div id="sectionHeading">
              <Row>
                <h3 id="branchHeading">
                  {/* SVG icon for Judicial */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="27.54px"
                    viewBox="0 -960 960 960"
                    width="27.54px"
                    fill="#FFFFFF"
                  >
                    <path d="M160-120v-80h480v80H160Zm226-194L160-540l84-86 228 226-86 86Zm254-254L414-796l86-84 226 226-86 86Zm184 408L302-682l56-56 522 522-56 56Z" />
                  </svg>
                  <b> Judicial</b>
                </h3>
              </Row>
            </div>

            <div id="branchPage">
              <Container className="bgcolor">
                <Container id="carouselBufferTwo">
                  <Row>
                    <Col id="legislativeColLeft">
                      <div id="columnLeftPadding">
                        <h4
                          id="whBreifingFeedHeading"
                          onClick={() =>
                            openModal(
                              'Supreme Court',
                              <GenerateFeed
                                url={`&skip=0&schema=united_states_of_america&table=supreme_${abbreviation}&order_by=created_at`}
                                name={formattedStateName}
                                branch="Judicial"
                                topic="N/A"
                                buttonName="Document"
                              />
                            )
                          }
                        >
                          <b>Supreme Court</b>
                        </h4>
                        <div id="gov"></div>

                        {CreateFeedAPI(
                          `&skip=0&schema=united_states_of_america&table=supreme_${abbreviation}&order_by=created_at`,
                          'gov',
                          'Supreme Court',
                          'Judicial',
                          'N/A',
                          'Document',
                          GenerateFeed
                        )}
                      </div>
                    </Col>

                    <Col id="legislativeColRight">
                      <div id="columnRightPadding"></div>
                    </Col>
                  </Row>
                </Container>
              </Container>
            </div>

            <footer id="homeFooter">
              <Navbar>
                <Row id="footerNav">
                  <Nav variant="pills">
                    <Nav.Link id="footerContent">Gov Glance Foundation Inc.</Nav.Link>
                    <Nav.Item>
                      <Nav.Link id="footerContent" href="mailto:contact@govglance.org">
                        Contact
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        id="footerContent"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            'https://donate.stripe.com/28o3fXbdvbQO2RO288',
                            '_blank'
                          );
                        }}
                      >
                        <HeartFill id="donateIcon" /> Donate
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Link
                      onClick={(e) => {
                        e.preventDefault();
                        window.open('/about', '_self');
                      }}
                      id="footerContent"
                    >
                      About
                    </Nav.Link>
                    <Nav.Link
                      onClick={(e) => {
                        e.preventDefault();
                        window.open('/privacy', '_self');
                      }}
                      id="footerContent"
                    >
                      Privacy
                    </Nav.Link>
                  </Nav>
                </Row>
              </Navbar>
            </footer>
          </div>
        </div>
      </BrowserView>

      <MobileView>
        <div id="homebg">
          <h5 id="branchHeadingMobile">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="27.54px"
              viewBox="0 -960 960 960"
              width="27.54px"
              fill="#FFFFFF"
            >
              <path d="M160-120v-80h480v80H160Zm226-194L160-540l84-86 228 226-86 86Zm254-254L414-796l86-84 226 226-86 86Zm184 408L302-682l56-56 522 522-56 56Z" />
            </svg>{' '}
            Judicial
          </h5>

          <Col>
            <h5 id="presidentialFeedHeadingMobile">Supreme Court</h5>

            <div id="gov"></div>

            {CreateFeedAPI(
              `&skip=0&schema=united_states_of_america&table=supreme_${abbreviation}&order_by=created_at`,
              'gov',
              'Supreme Court',
              'Judicial',
              'N/A',
              'Document',
              GenerateFeed
            )}
          </Col>
        </div>
        <Navbar id="navbarMobileBottom" fixed="bottom">
          <div id="changeBranchDropdownMobile">
            <MobileOffCanvas />
          </div>
        </Navbar>
      </MobileView>
    </div>
  );
};

export default StateJudicialPage;
