
import App from "./App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, useRef } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, Spinner, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, FolderPlus, PlayCircle, Search } from 'react-bootstrap-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import debounce from 'lodash.debounce';
require('dotenv').config();
const apiKey = process.env.REACT_APP_API_KEY;
const dumb = process.env.CONGRESS_GOV;


var storedTitle = ''// title
var storedDetailsLink = ''// details link
var storedDocLink = ''// doc link *
var storedDate = ''// date
var storedNote = ''// note
var storedFeedName = ''// feed name
var storedBranchName = ''// branch name 
var storedTopicName = ''// topic name
var storedDescription = ''// description
var collectionCounter = localStorage.getItem('collectionNumber')



function SaveFeedItem(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, 
  saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription)
{

storedTitle = saveCollectionTitle;
storedDetailsLink = saveCollectionDetailLink;
storedDocLink = saveCollectionDocLink;
storedDate = saveCollectionDate;
storedNote = saveCollectionNote;
storedFeedName = saveCollectionFeedName;
storedBranchName = saveCollectionBranchName;
storedTopicName = saveCollectionTopicName;
storedDescription = saveCollectionDescription;
}


{/*
Expected Payload:

id
country
administrative_division_first_level
administrative_division_second_level
administrative_division_third_level
administrative_division_four_level
branch
topic
guid
category
creator
date
title
long_title
url — source
doc_urls — as a dictionary, link(s) to corresponding document(s)
description
encoded
collected_at
other_data
, branch, topic
*/}

function formatDate(inputDate) {
  const date = new Date(inputDate);
  
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
}
function formatName(fullName) {

  let parts = fullName.split(',');

  let names = parts[1].trim().split(' ');
  let firstName = names[0].toLowerCase();

  let lastName = parts[0].trim().toLowerCase();

  lastName = lastName.replace(/\s+/g, '-');

  let formattedName = `${firstName}-${lastName}`;

  return formattedName;
}

function RepresenativesTest(apiRequest, name, branch, topic, buttonName) {
  
  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  var [feedLength, setFeedLength] = useState(50);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [filteredData, setFilteredData] = useState([]);


  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
    
    var searchItem = document.getElementById('noteModalFeedItem').value
  
       collectionCounter = localStorage.getItem('collectionNumber')
   
     collectionCounter++;

     
     var collectionTitles = []
      var collectionDetailLinks = []
     var collectionDocLinks = []
     var collectionNote = []

       var title = 'title'.concat(collectionCounter)
       var detailLink = 'detailLink'.concat(collectionCounter)
       var docLink = 'docLink'.concat(collectionCounter)
       var itemNote = 'itemNote'.concat(collectionCounter)
       var itemDate = 'itemDate'.concat(collectionCounter)
       var feedName = 'feedName'.concat(collectionCounter)
       var branchName = 'branchName'.concat(collectionCounter)
       var topicName = 'topicName'.concat(collectionCounter)
       var itemDescription = 'itemDescription'.concat(collectionCounter)
   
   
     
     
       localStorage.setItem(title,saveCollectionTitle)
       
       localStorage.setItem(detailLink,saveCollectionDetailLink)
      
       localStorage.setItem(docLink,saveCollectionDocLink)
       localStorage.setItem(itemNote,searchItem)
   
       localStorage.setItem(itemDate,saveCollectionDate)
       localStorage.setItem(feedName,saveCollectionFeedName)
       localStorage.setItem(branchName,saveCollectionBranchName)
       localStorage.setItem(topicName,saveCollectionTopicName)
   
       localStorage.setItem(itemDescription,saveCollectionDescription)
     
       localStorage.setItem('collectionNumber', collectionCounter)

  }

  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
const [searchQuery, setSearchQuery] = useState('');
const [schema, setSchema] = useState('default_schema');
const [table, setTable] = useState('default_table');






{/*https://api.govglance.org/posts/recent?limit=10&skip=0&schema=united_states_of_america&table=demo_table&order_by=created_at */}




  const url = `https://api.govglance.org/members/?limit=${feedLength}&skip=0`;

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        "Authorization": "Bearer "+apiKey,
    },
    };
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        // First API call to fetch member data
        const response = await fetch(`https://api.govglance.org/members/?limit=${feedLength}&skip=0`, requestOptions);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const membersData = await response.json();

        // Extract bio_guide_id from membersData and create an array of promises for the second API call
        const bioGuideIds = membersData.map(member => member.bio_guide_id);
        const secondApiCalls = bioGuideIds.map(bioGuideId =>
          fetch(`https://api.govglance.org/members/{bio_guid_id}?bio_guide_id=${bioGuideId}&limit=10&skip=0`, requestOptions)
        );

        // Wait for all second API calls to complete
        const secondApiResponses = await Promise.all(secondApiCalls);
        const membersDetailsData = await Promise.all(secondApiResponses.map(response => response.json()));

        // Combine member data from both API calls
        const combinedData = membersData.map((member, index) => ({
          ...member,
          details: membersDetailsData[index]
        }));

        setData(combinedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [feedLength]);

  const handleSearchButtonClick = () => {
    console.log("Search button clicked");
    setSearchPerformed(true);
  };

  const handleSearchInputChange = (e) => {
    console.log("Input value changed");
    setSearchTerm(e.target.value);
    setSearchPerformed(false);
  };

const handleKeyDown = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault(); 
    setSearchPerformed(true); 
    handleSearchButtonClick(); 
  }
};



  const renderData = () => {
    if (loading) {
      return <p>Loading...</p>;
    }

    if (error) {
      return <p>Error: {error.message}</p>;
    }

    return data.map((member, index) => (
      
      <div key={index}>
        {/* Render member data here */}
        <p>Name: {member.name}</p>
        {/* Access details from member.details */}
        <p>Details: {JSON.stringify(member.details)}</p>
      </div>
    ));
  };

  return (
    <div>
      {renderData()}
    </div>
  );
}




export default RepresenativesTest;