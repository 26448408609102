import App from "./App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, useRef } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, Spinner } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, FolderPlus, PlayCircle, Search } from 'react-bootstrap-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import debounce from 'lodash.debounce';
require('dotenv').config();
const apiKey = process.env.REACT_APP_API_KEY;

var storedTitle = ''// title
var storedDetailsLink = ''// details link
var storedDocLink = ''// doc link *
var storedDate = ''// date
var storedNote = ''// note
var storedFeedName = ''// feed name
var storedBranchName = ''// branch name 
var storedTopicName = ''// topic name
var storedDescription = ''// description
var collectionCounter = localStorage.getItem('collectionNumber')

function SaveFeedItem(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, 
  saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription)
{
  storedTitle = saveCollectionTitle;
  storedDetailsLink = saveCollectionDetailLink;
  storedDocLink = saveCollectionDocLink;
  storedDate = saveCollectionDate;
  storedNote = saveCollectionNote;
  storedFeedName = saveCollectionFeedName;
  storedBranchName = saveCollectionBranchName;
  storedTopicName = saveCollectionTopicName;
  storedDescription = saveCollectionDescription;
}

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  const day = utcDate.getDate();
  const month = utcDate.getMonth() + 1;
  const year = utcDate.getFullYear();

  return `${month}-${day}-${year}`;
}

function GenerateFeed(apiRequest, name, branch, topic, buttonName) {
  
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  var [feedLength, setFeedLength] = useState(20);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);

  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
    var searchItem = document.getElementById('noteModalFeedItem').value;
    collectionCounter = localStorage.getItem('collectionNumber');
    collectionCounter++;
   
    localStorage.setItem('title' + collectionCounter, saveCollectionTitle);
    localStorage.setItem('detailLink' + collectionCounter, saveCollectionDetailLink);
    localStorage.setItem('docLink' + collectionCounter, saveCollectionDocLink);
    localStorage.setItem('itemNote' + collectionCounter, searchItem);
    localStorage.setItem('itemDate' + collectionCounter, saveCollectionDate);
    localStorage.setItem('feedName' + collectionCounter, saveCollectionFeedName);
    localStorage.setItem('branchName' + collectionCounter, saveCollectionBranchName);
    localStorage.setItem('topicName' + collectionCounter, saveCollectionTopicName);
    localStorage.setItem('itemDescription' + collectionCounter, saveCollectionDescription);
    localStorage.setItem('collectionNumber', collectionCounter);
  }

  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [schema, setSchema] = useState('default_schema');
  const [table, setTable] = useState('default_table');

  const requestOptions = {
    method: 'GET',
    headers: { 
      'Content-Type': 'application/json',
      "Authorization": "Bearer "+apiKey,
    },
  };

  const performSearch = () => {
    const schemaMatch = /schema=([^&]+)/.exec(apiRequest.url);
    const tableMatch = /table=([^&]+)/.exec(apiRequest.url);

    const extractedSchema = schemaMatch ? schemaMatch[1] : 'default_schema';
    const extractedTable = tableMatch ? tableMatch[1] : 'default_table';

    setSchema(extractedSchema);
    setTable(extractedTable);

    const searchUrl = `https://api.govglance.org/search/${extractedSchema}/${extractedTable}?limit=${feedLength}&skip=0&search=${searchTerm}`;
  };

  useEffect(() => {
    console.log(apiRequest.url)
    const url = searchPerformed
      ? `https://api.govglance.org/search/${schema}/${table}?limit=${feedLength}&skip=0&search_term=${searchTerm}`
      : `https://api.govglance.org/posts/recent?limit=${feedLength}${apiRequest.url}`;
    console.log(`https://api.govglance.org/posts/recent?limit=${feedLength}${apiRequest.url}`)
    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((fetchedData) => {
        // Sort data by created_at date in descending order

        const sortedData = fetchedData.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        setData(sortedData);
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, [feedLength, searchPerformed, searchTerm, apiRequest.url, schema, table]);

  const handleSearchButtonClick = () => {
    if (searchTerm.trim() === '') {
      return;
    }

    setSearchPerformed(true);
    performSearch();
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
    setSearchPerformed(false);
    if (e.key === 'Enter') {
      setSearchPerformed(true);
      handleSearchButtonClick();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchPerformed(true);
      handleSearchButtonClick();
    }
  };

  const renderData = () => {
    if (loading) {
      return <div id='fullBillFeed'><Spinner animation="grow" variant="light" /></div>;
    }

    if (error) {
      return <div id='fullBillFeed'><p>Error fetching data.</p></div>;
    }

    const filteredData = data.reduce((filtered, item, index) => {
      const title = item.title || '';
      const statusTitle = item.status_title || '';

      if (statusTitle.toLowerCase().includes(searchQuery.toLowerCase())) {
        filtered.push({ title: statusTitle, index });
      } else if (title.toLowerCase().includes(searchQuery.toLowerCase())) {
        filtered.push({ title, index });
      }

      return filtered;
    }, []);

    if (filteredData.length === 0) {
      return <div id='fullBillFeed'><p>No results found.</p></div>;
    }

    return filteredData.map(({ source, index }) => {
      const titleToDisplay = data[index].status_title || data[index].title;
      const link = data[index].url;
      const docLink = data[index].document_link || data[index].download?.pdfLink || data[index].document_url || data[index].download_link || data[index].pdf_url ;
      const date = formatDate(data[index].created_at);
      const docButtonName = apiRequest.buttonName;

      return (
        <div key={index}>
          <div id='fullBillFeed'>
            <Container id='feedContainer'>
              <Card.Title id='billText' onClick={(e) => {
                e.preventDefault();
                window.open(link, "_blank");
              }}>{titleToDisplay}</Card.Title>
              <Card.Body id='billBodyText'>{date}</Card.Body>
              <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(link, "_blank");
              }}><InfoCircle /> Details</Button>

              {docLink && (
                <Button id='viewBillButton' onClick={(e) => {
                  e.preventDefault();
                  window.open(docLink, "_blank");
                }}>
                  <FileText /> {docButtonName}
                </Button>
              )}
    
              <Button id='addToCollectionButton' onClick={() => {
                setCollectionModalIsOpen(true);
                SaveFeedItem(titleToDisplay, link, docLink, date, '', apiRequest.name, apiRequest.branch, apiRequest.topic, '');
              }}><FolderPlus /></Button>
            </Container>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
        centered onHide={() => setCollectionModalIsOpen(false)}>
        <Modal.Header id='billModalHeader' closeButton ><h3>{storedTitle}</h3></Modal.Header>
        <Modal.Body id='descriptionModalBody'>
          {storedDescription}
          {storedDate}
          <div id='fullBill'>
            <Form id='noteModalForm'>
              <Form.Group id='noteModalForm' className="mb-3">
                <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
                  type="text"
                  as="textarea"
                  rows={2}
                />
                <br />
              </Form.Group>
            </Form>

            <Modal.Footer id='addNoteModalFooter'>
              <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {
                saveNote(storedTitle, storedDetailsLink, storedDocLink, storedDate, storedFeedName, storedBranchName, storedTopicName, storedDescription);
                setCollectionModalIsOpen(false);
              }}>
                Save To Collection
              </Button>
            </Modal.Footer>
          </div>
        </Modal.Body>
      </Modal>

      <div id='searchForm'>
        <Form className="text-center" onSubmit={(e) => { e.preventDefault(); }}>
          <Form.Group className="d-flex justify-content-center align-items-center">
            <Form.Control
              variant="light"
              id="searchInput"
              placeholder="Search..."
              type="text"
              value={searchTerm}
              onChange={handleSearchInputChange}
              onKeyDown={handleKeyDown}
            />
            <Button id='ggButton' onClick={handleSearchButtonClick}>
              <Search /> 
            </Button>
          </Form.Group>
        </Form>
      </div>

      {renderData()}

      <div id='fullBillFeed'>
        <Button id='standardButton' onClick={() => {
          setFeedLength(feedLength + 20);
        }}>More</Button>
      </div>
    </>
  );
}

export default GenerateFeed;
