import App from "./App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, Spinner } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, FolderPlus, PlayCircle } from 'react-bootstrap-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
require('dotenv').config();
const apiKey = process.env.REACT_APP_API_KEY;



var storedTitle = ''// title
var storedDetailsLink = ''// details link
var storedDocLink = ''// doc link *
var storedDate = ''// date
var storedNote = ''// note
var storedFeedName = ''// feed name
var storedBranchName = ''// branch name 
var storedTopicName = ''// topic name
var storedDescription = ''// description
var collectionCounter = localStorage.getItem('collectionNumber')



function SaveFeedItem(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, 
  saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription)
{

storedTitle = saveCollectionTitle;
storedDetailsLink = saveCollectionDetailLink;
storedDocLink = saveCollectionDocLink;
storedDate = saveCollectionDate;
storedNote = saveCollectionNote;
storedFeedName = saveCollectionFeedName;
storedBranchName = saveCollectionBranchName;
storedTopicName = saveCollectionTopicName;
storedDescription = saveCollectionDescription;
}


{/*
Expected Payload:

id
country
administrative_division_first_level
administrative_division_second_level
administrative_division_third_level
administrative_division_four_level
branch
topic
guid
category
creator
date
title
long_title
url — source
doc_urls — as a dictionary, link(s) to corresponding document(s)
description
encoded
collected_at
other_data
, branch, topic
*/}

function formatDate(pubDate) {
  const date = new Date(pubDate);
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // Months are zero-indexed
  const year = date.getUTCFullYear();
  return `${month}-${day}-${year}`;
}

function GenerateFeedAudio(apiRequest, name, branch, topic, buttonName) {
  
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  var [feedLength, setFeedLength] = useState(20);
  

  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
    
    var searchItem = document.getElementById('noteModalFeedItem').value
  
       collectionCounter = localStorage.getItem('collectionNumber')
   
     collectionCounter++;

     
     var collectionTitles = []
      var collectionDetailLinks = []
     var collectionDocLinks = []
     var collectionNote = []

       var title = 'title'.concat(collectionCounter)
       var detailLink = 'detailLink'.concat(collectionCounter)
       var docLink = 'docLink'.concat(collectionCounter)
       var itemNote = 'itemNote'.concat(collectionCounter)
       var itemDate = 'itemDate'.concat(collectionCounter)
       var feedName = 'feedName'.concat(collectionCounter)
       var branchName = 'branchName'.concat(collectionCounter)
       var topicName = 'topicName'.concat(collectionCounter)
       var itemDescription = 'itemDescription'.concat(collectionCounter)
   
   
     
     
       localStorage.setItem(title,saveCollectionTitle)
       
       localStorage.setItem(detailLink,saveCollectionDetailLink)
      
       localStorage.setItem(docLink,saveCollectionDocLink)
       localStorage.setItem(itemNote,searchItem)
   
       localStorage.setItem(itemDate,saveCollectionDate)
       localStorage.setItem(feedName,saveCollectionFeedName)
       localStorage.setItem(branchName,saveCollectionBranchName)
       localStorage.setItem(topicName,saveCollectionTopicName)
   
       localStorage.setItem(itemDescription,saveCollectionDescription)
     
       localStorage.setItem('collectionNumber', collectionCounter)

  }

  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
const [searchQuery, setSearchQuery] = useState('');


const requestOptions = {
  method: 'GET',
  headers: { 
    'Content-Type': 'application/json',
    "Authorization": "Bearer "+apiKey,
},
};

  useEffect(() => {
    fetch(`https://api.govglance.org/posts/recent?limit=${feedLength}${apiRequest.url}`
    , requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data)
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [feedLength]);

  if (loading) {
    return  <div id='fullBillFeed'><Spinner  animation="grow" variant="light" /></div>;
  }

  if (error) {
    return <div id='fullBillFeed'><p>Error: {error.message}</p></div>;
  }

  const renderData = () => {
    const filteredData = data.reduce((filtered, item, index) => {
      const title = item.title || '';
  
      if (title.toLowerCase().includes(searchQuery.toLowerCase())) {
        filtered.push({ title, index });
      }
  
      return filtered;
    }, []);
  
    return filteredData.map(({ source, index }) => {
      const link = data[index].url;
      const docLink = data[index].document_link;
      const audioLink = data[index].audio_url; 
      const date = formatDate(data[index].created_at);
      
      const title = data[index].title;
      const description = "";
      const feedName = apiRequest.name; 
      const feedBranch = apiRequest.branch; 
      const feedTopic = apiRequest.topic; 
      const docButtonName = apiRequest.buttonName; 

 
      
      return (
        <div key={index}>
          <div id='fullBillFeed'>
            <Container id='feedContainer'>
              <Card.Title id='billText' onClick={(e) => {
                e.preventDefault();
                window.open(link, "_blank");
              }}>{title}</Card.Title>
              <Card.Body id='billBodyText'>{date}</Card.Body>
              <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(link, "_blank");
              }}><PlayCircle/> Audio</Button>
  
              {data[index].pdf_url && (
                <Button id='viewBillButton' onClick={(e) => {
                  e.preventDefault();
                  window.open(data[index].pdf_url, "_blank");
                }}><FileText></FileText> {docButtonName}</Button>
              )}
      
              <Button id='addToCollectionButton' onClick={() => {
                setCollectionModalIsOpen(true);
                SaveFeedItem(title, link, '', date, '', feedName, feedBranch, feedTopic, description);
              }}><FolderPlus></FolderPlus></Button>
            </Container>
          </div>
        </div>
      );
    });
  }

  return (
    <>
<Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
centered onHide={setCollectionModalIsOpen}>
                             
                              <Modal.Header id='billModalHeader' closeButton ><h3>{storedTitle}</h3></Modal.Header>

                              <Modal.Body id='descriptionModalBody'>
                                          {storedDescription}
                                          {storedDate}
                              <div id='fullBill'>
                         <Form id='noteModalForm'>
         <Form.Group id='noteModalForm'  className="mb-3" >
           <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
             type="text"
             as={"textarea"}
             rows={2}
             />
             <br></br>

         </Form.Group>
         </Form>

         <Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(storedTitle, storedDetailsLink, storedDocLink, 
  storedDate, storedFeedName, storedBranchName, storedTopicName, storedDescription) ;setCollectionModalIsOpen(false) }} >
    Save To Collection
  </Button>
  </Modal.Footer> 

           </div>
           </Modal.Body>
                              </Modal>
                              <div id='searchForm'>
                              <Form.Control
  id="searchInput"
  placeholder="Search..."
  type="text"
  value={searchQuery}
  onChange={(e) => setSearchQuery(e.target.value)}
  color='white'
/>
</div> 
      {renderData()}
      <div id='fullBillFeed'>
      <Button id='standardButton' onClick={() => {
               setFeedLength(feedLength+=20)
              }}>More</Button>
              </div>
             

    </>
  )
}



export default GenerateFeedAudio;