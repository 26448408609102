import { useHistory } from 'react-router-dom';

const useHandleContainerClickRecents = () => {
  const history = useHistory();

  const handleContainerClickRecents = (apiRequest, name, branch, topic, buttonName) => {
    //console.log("useHandleContainerClickRecents called with:", { apiRequest, name, branch, topic, buttonName });

    // Navigate to the representative page with bioGuideId and name as state
    window.scrollTo(0, 0);
    history.push('/us/feed', { 
      apiRequest, name, branch, topic, buttonName
    });
  };

  return handleContainerClickRecents;
};

export default useHandleContainerClickRecents;
