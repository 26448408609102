import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, Link45deg } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnOversightAndReform from './HouseCommitteeOnOversightAndReform';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnArmedServices from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnTheBudget from './HouseCommitteeOnTheBudget';
import HouseCommitteeOnEducationAndLabor from './HouseCommitteeOnEducationAndLabor';
import HouseFloor from './HouseFloor';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEthics from './HouseCommitteeOnEthics';
import CongressionalHearings from './CongressionalHearings';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import HouseCommitteeOnForeignAffairs from './HouseCommitteeOnForeignAffairs';
import HouseCommitteeOnHomelandSecurity from './HouseCommitteeOnHomelandSecurity';
import HouseCommitteeOnTheJudiciary from './HouseCommitteeOnTheJudiciary';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import HouseCommitteeOnHouseAdministration from './HouseCommitteeOnHouseAdministration';
import CongressionalBudgetOffice from './CongressionalBudgetOffice';
import PresidentialProclamations from './PresidentialProclamations';
import ExecutiveOrders from './ExecutiveOrders';
import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
    createWhiteHouseFeedMobile,createDepartmentOfState,
    createDepartmentOfDefense, 
    createDepartmentOfJustice, createDepartmentOfTheInterior,
    createDepartmentOfAgriculture, createDepartmentOfCommerce,
    createDepartmentOfCommerceMobile, createDepartmentOfLabor,
    createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
    ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
    , createEnvironmentalProtectionAgency,
    createDepartmentOfTreasury, createNationalScienceFoundation, 
     createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, 
     createFederalRegisterStateDepartment, createFederalRegisterDefense,
    CreateFeedItem, CreateFeedItemPresidentialDocuments, CreateExecutiveOrders,
    CreateFeedItemExecutiveOrders,
    CreateFeedItemBillUpdates,
    CreateFeedItemExecutiveOffice,
    CreateFeedItemBillWithDescription,
    CreateFeedAPIDepartmentUS,
    CreateFeedAPI,  } from './Functions'
import NationalScienceFoundation from './NationalScienceFoundation';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import BillUpdates from './BillUpdates';
import CouncilOfEconomicAdvisers from './CouncilOfEconomicAdvisers';
import CouncilOnEnvironmentQuality from './CouncilOnEnvironmentQuality';
import DomesticPolicyCouncil from './DomesticPolicyCouncil';
import GenderPolicyCouncil from './GenderPolicyCouncil';
import NationalEconomicCouncil from './NationalEconomicCouncil';
import NationalSecurityCouncil from './NationalSecurityCouncil';
import OfficeOfIntergovernmentalAffairs from './OfficeOfIntergovernmentalAffairs';
import OfficeOfManagmentAndBudget from './OfficeOfManagmentAndBudget';
import OfficeOfNationalDrugControlPolicy from './OfficeOfNationalDrugControlPolicy';
import OfficeOfPublicEngagement from './OfficeOfPublicEngagement';
import OfficeOfScienceAndTechnologyPolicy from './OfficeOfScienceAndTechnologyPolicy';
import OfficeOfNationalCyberDirector from './OfficeOfNationalCyberDirector';
import PresidentialPersonnelOffice from './PresidentialPersonnelOffice';
import ArmyDocuments from './ArmyDocuments';
import NavyDocuments from './NavyDocuments';

import SpaceForceNews from './SpaceForceNews';
import AirForceDocuments from './AirForceDocuments';
import NationalGuardNews from './NationalGuardNews';
import NSADocuments from './NSADocuments';

import ArmyCorpsEngineers from './ArmyCorpsEngineers';
import MarinesPressReleases from './MarinesPressReleases';
import GenerateFeedDepartment from './GenerateFeedDepartment';
import GenerateFeed from './GenerateFeed';


var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 30000;



  

 


  

export const DefenseMobile= () =>   {
    render()
    
    {
        const [show, setShow] = useState(true);

        
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
     
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
      
      
        const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

        const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
        const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
        const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
        const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
        const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
        const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
        const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
        const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
        const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
        const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
        const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
        const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)

        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
        const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
        const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
        const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
        const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
        const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
        const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
        const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
        const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
        const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)
        const [showBioModal, setBioModal] = useState(false);
        const [modalInfo, setModalInfo] = useState({
          isOpen: false,
          title: '',
          content: null
        });
      
        const openModal = (title, content) => {
          setModalInfo({
            isOpen: true,
            title,
            content
          });
        };
      
        const closeModal = () => {
          setModalInfo({
            isOpen: false,
            title: '',
            content: null
          });
        };

        var mobileCarouselInterval = 60000;
        const CustomModal = ({ isOpen, onClose, title, content }) => (
          <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
            <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
            {content}
          </Modal>
        );
    var branch = "all";
  
    return(
       <>
   <MobileTopNavbar/>
  
  
  
  
  <div id='homebg'>

               
  
  <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div id="profileImageOnPageDepartment">
                <Image id="profileImage-imageOnPageDepartment" src="/departmentOfDefense.png" />
              </div>
              <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                <h5 id="branchHeadingMobile" > Department of Defense</h5>
              </div>
            </div>
            <Dropdown drop="down-centered">
              <Dropdown.Toggle id="searchCountry"><Link45deg /></Dropdown.Toggle>
              <Dropdown.Menu id="docsDropdown" drop="down-centered">
                <Dropdown.Item id="docsDropdownItem" onClick={(e) => { e.preventDefault(); window.open("https://www.defense.gov/", "_blank") }}>
                  Official Site
                </Dropdown.Item>

                <Dropdown.Item id="docsDropdownItem" onClick={(e) => { e.preventDefault(); window.open("https://www.govinfo.gov/content/pkg/STATUTE-1/pdf/STATUTE-1-Pg49-2.pdf", "_blank") }}>
                  Establishing Act
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
          
            Mission Statement: The Department of Defense is America's largest government agency. With our military tracing its roots back to pre-Revolutionary times, the department has grown and evolved with our nation. Our mission is to provide the military forces needed to deter war and ensure our nation's security.
          </div>

          {/* <div style={{height: '20px',}}></div>
          <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
          <b>Establishing Act</b>
          </div>
          <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
         
          Be it enacted by the Senate and House of Representatives of the United States of America in Congress assembled, That there shall be an executive department to be denom...
          </div> */}

        </div>

        <Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>


       
        <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> Department of Defense</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>The mission of the Department of Defense (DOD) is to provide the military forces needed to deter war and to protect the security of our country. The department’s headquarters is at the Pentagon.</p>

<p>The DOD consists of the Departments of the Army, Navy, and Air Force, as well as many agencies, offices, and commands, including the Joint Chiefs of Staff, the Pentagon Force Protection Agency, the National Security Agency, and the Defense Intelligence Agency. The DOD occupies the vast majority of the Pentagon building in Arlington, Virginia.</p>

<p>The DOD is the largest government agency, with more than 1.4 million men and women on active duty, more than 700,000 civilian personnel, and 1.1 million citizens who serve in the National Guard and Reserve forces. Together, the military and civilian arms of DOD protect national interests through war-fighting, providing humanitarian aid, and performing peacekeeping and disaster relief services.</p>


<br></br>
<Link onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=than%2041%2C000%C2%A0%20employees.-,DEPARTMENT%20OF%20DEFENSE,-The%20mission%20of", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=than%2041%2C000%C2%A0%20employees.-,DEPARTMENT%20OF%20DEFENSE,-The%20mission%20of
     </Link>
 </div>

       </Modal>
  
           

       

       
 

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}>News</h5>




  <div class='courtNewsMobile' id='defenseDepartment'></div>
  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=defense&order_by=created_at', 'defenseDepartment', 'Department of Defense', 'Executive', 'Foreign Affairs', DepartmentOfDefense)}

        
  <Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense</h3></Modal.Header>
                  <GenerateFeed url='&skip=0&schema=united_states_of_america&table=defense&order_by=created_at' 
                            name='Department of Defense News' branch='Executive' topic='N/A' buttonName='' />
                </Modal>

  </Col>

  <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDefenseDocumentsModalIsOpen(true)}>Documents</h5>


  <div class='courtNewsMobile' id='frDefense'></div>

  {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=defense-department', 'frDefense', 'Department of Defense Documents', 'Executive', 'Foreign Affairs', FederalRegisterDefenseDepartment)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Defense Documents</h3></Modal.Header>
                  <FederalRegisterDefenseDepartment />
                </Modal>


                <Col>
                <Link  id='pageLink' to="/us/army"> <h5 id='presidentialFeedHeadingMobile'>Army<ChevronRight id='clickChevron'></ChevronRight></h5></Link>
                        <Carousel controls={false} >
                        
              

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<Row id = 'mobileFeedHeadingRow'><h5 id='billFeedHeadingMobileHome' onClick={() => setArmyModalIsOpen(true)}>Army Documents</h5></Row>


  <div class='courtNewsMobile' id='armyDocs'></div>

  {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=army-department', 'armyDocs', 'Army Documents', 'Executive', 'N/A', ArmyDocuments)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={armyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmyModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Army Documents</h3></Modal.Header>
                 <ArmyDocuments/>
                </Modal>


    
  
    </div>
   


</Carousel.Item>





<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/army' >View all Army news</Button>
</div>
</Col>

</div>



</Carousel.Item>

                        
                        
                        </Carousel>
                        
                        </Col>

                        <Col>
                <Link  id='pageLink' to="/us/navy"> <h5 id='presidentialFeedHeadingMobile'>Navy<ChevronRight id='clickChevron'></ChevronRight></h5></Link>
                        <Carousel controls={false} >
                        
              

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<Row id = 'mobileFeedHeadingRow'><h5 id='billFeedHeadingMobileHome' onClick={() => setNavyModalIsOpen(true)}>Navy Documents</h5></Row>


  <div class='courtNewsMobile' id='navyDocs'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=navy-department', 'navyDocs', 'Navy Documents', 'Executive', 'N/A', NavyDocuments)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={navyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNavyModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Navy Documents</h3></Modal.Header>
                  <NavyDocuments />
                </Modal>


    
  
    </div>
   


</Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<Row id = 'mobileFeedHeadingRow'><h5 id='billFeedHeadingMobileHome' onClick={() => setMarinesModalIsOpen(true)}>Marines Press Releases</h5></Row>


  <div class='courtNewsMobile' id='marinesPress'></div>

  {CreateFeedItemBillWithDescription('https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/executive-xml/marine.xml', 'marinesPress', 'Marines Press Releases', 'Executive', 'N/A', MarinesPressReleases)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={marinesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setMarinesModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Marines Press Releases</h3></Modal.Header>
                  <MarinesPressReleases/>
                </Modal>


    
  
    </div>
   


</Carousel.Item>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/navy' >View all Navy news</Button>
</div>
</Col>

</div>



</Carousel.Item>

                        
                        
                        </Carousel>
                        
                        </Col>





                        <Col>
                <Link  id='pageLink' to="/us/airforce"> <h5 id='presidentialFeedHeadingMobile'>Air Force<ChevronRight id='clickChevron'></ChevronRight></h5></Link>
                        <Carousel controls={false} >
                        
              

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<Row id = 'mobileFeedHeadingRow'><h5 id='billFeedHeadingMobileHome' onClick={() => setAirForceModalIsOpen(true)}>Air Force Documents</h5></Row>


  <div class='courtNewsMobile' id='airForce2'></div>

  {CreateFeedItemBillWithDescription('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=air-force-department', 'airForce2', 'Air Force Documents', 'Executive', 'N/A', AirForceDocuments)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={airForceModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAirForceModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Air Force Documents</h3></Modal.Header>
                  <AirForceDocuments/>
                </Modal>


    
  
    </div>
   


</Carousel.Item>



<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/airforce' >View all Air Force news</Button>
</div>
</Col>

</div>



</Carousel.Item>

                        
                        
                        </Carousel>
                        
                        </Col>



                        <Col>
  <Link  id='pageLink' to="/us/defense-acquisition-regulations-system">
    <h5 id='presidentialFeedHeadingMobile'>Defense Acquisition Regulations System<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Defense Acquisition Regulations System',
                <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Acquisition%20Regulations%20System'
                  name='Defense Acquisition Regulations System' branch='Executive' topic='Defense' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defDars'></div>
          {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Acquisition%20Regulations%20System', 'defDars',
            'Defense Acquisition Regulations System Documents', 'Executive',
            'Defense', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/defense-acquisition-regulations-system' >View all Defense Acquisition Regulations System news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/defense-contract-audit-agency">
    <h5 id='presidentialFeedHeadingMobile'>Defense Contract Audit Agency<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Defense Contract Audit Agency',
                <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Contract%20Audit%20Agency'
                  name='Defense Contract Audit Agency' branch='Executive' topic='Defense' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defDcaa'></div>
          {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Contract%20Audit%20Agency', 'defDcaa',
            'Defense Contract Audit Agency Documents', 'Executive',
            'Defense', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/defense-contract-audit-agency' >View all Defense Contract Audit Agency news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/defense-finance-accounting-service">
    <h5 id='presidentialFeedHeadingMobile'>Defense Finance and Accounting Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Defense Finance and Accounting Service',
                <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Finance%20and%20Accounting%20Service'
                  name='Defense Finance and Accounting Service' branch='Executive' topic='Defense' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defDfas'></div>
          {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Finance%20and%20Accounting%20Service', 'defDfas',
            'Defense Finance and Accounting Service Documents', 'Executive',
            'Defense', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/defense-finance-accounting-service' >View all Defense Finance and Accounting Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>



<Col>
  <Link  id='pageLink' to="/us/defense-information-systems-agency">
    <h5 id='presidentialFeedHeadingMobile'>Defense Information Systems Agency<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Defense Information Systems Agency',
                <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Information%20Systems%20Agency'
                  name='Defense Information Systems Agency' branch='Executive' topic='Defense' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defDisa'></div>
          {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Information%20Systems%20Agency', 'defDisa',
            'Defense Information Systems Agency Documents', 'Executive',
            'Defense', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/defense-information-systems-agency' >View all Defense Information Systems Agency news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/defense-intelligence-agency">
    <h5 id='presidentialFeedHeadingMobile'>Defense Intelligence Agency<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Defense Intelligence Agency',
                <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Intelligence%20Agency'
                  name='Defense Intelligence Agency' branch='Executive' topic='Defense' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defDia'></div>
          {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Intelligence%20Agency', 'defDia',
            'Defense Intelligence Agency Documents', 'Executive',
            'Defense', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/defense-intelligence-agency' >View all Defense Intelligence Agency news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/defense-intelligence-agency">
    <h5 id='presidentialFeedHeadingMobile'>Defense Legal Services Agency<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Defense Legal Services Agency',
                <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Legal%20Services%20Agency'
                  name='Defense Legal Services Agency' branch='Executive' topic='Defense' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defDlsa'></div>
          {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Legal%20Services%20Agency', 'defDlsa',
            'Defense Legal Services Agency Documents', 'Executive',
            'Defense', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/defense-intelligence-agency' >View all Defense Legal Services Agency news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/defense-logistics-agency">
    <h5 id='presidentialFeedHeadingMobile'>Defense Logistics Agency<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Defense Logistics Agency',
                <GenerateFeedDepartment url='&table_name=defense&agency=Defense%20Logistics%20Agency'
                  name='Defense Logistics Agency' branch='Executive' topic='Defense' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defDla'></div>
          {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Defense%20Logistics%20Agency', 'defDla',
            'Defense Logistics Agency Documents', 'Executive',
            'Defense', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/defense-logistics-agency' >View all Defense Logistics Agency news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>




<Col>
                <Link  id='pageLink' to="/us/engineerscorps"> <h5 id='presidentialFeedHeadingMobile'>Engineers Corps<ChevronRight id='clickChevron'></ChevronRight></h5></Link>


                <Carousel controls={false} >
                        
              

                        <Carousel.Item interval={mobileCarouselInterval}>
                        
                    <div id='legislativeConMobile'>

<Col>


<Row id = 'mobileFeedHeadingRow'><h5 id='billFeedHeadingMobileHome' onClick={() => setNewLawsModalIsOpen(true)}>Documents</h5></Row>





  <div class='courtNewsMobile' id='engCorps'></div>

  {CreateFeedAPIDepartmentUS('&table_name=defense&agency=Engineers%20Corps', 'engCorps', 
                            'Engineers Corps', 'Executive', 
                            'Defense', 'Doc', GenerateFeedDepartment)}
</Col>
              
                <Modal id='whiteHouseDocsModal' show={newLawsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setNewLawsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Engineers Corps</h3></Modal.Header>
                  <GenerateFeedDepartment   url='&table_name=defense&agency=Engineers%20Corps'
                                name='Engineers Corps' branch='Executive' topic='Defense' buttonName='Doc'  />
                </Modal>



    
  
    </div>
                           
                        
                        
                        </Carousel.Item>
                        
                        
                        
                        <Carousel.Item interval={mobileCarouselInterval}>
                        
                        <div id='legislativeConMobile'>
                        
                        <Col>
                        <div id='viewFullPageButton'>
                        <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/engineerscorps' >View all Engineers Corps news</Button>
                        </div>
                        </Col>
                        
                        </div>
                        
                        
                        
                        </Carousel.Item>
                        
                                                
                                                
                                                </Carousel>
</Col>

<CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />

<br></br>
          
          
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
              
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
          </div>

          </>
  )
}
 }



  






export default DefenseMobile;

