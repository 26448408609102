import App from "./App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, lazy } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, PauseCircle, PlayCircle, FolderPlus, ChevronRight, Files, Folder, Pen, ChatLeftQuote } from 'react-bootstrap-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from "./FullBillFeed";
import TrendingBills from "./TrendingBills";
import BillsEnrolled from "./BillsEnrolled";
import CollectionPage from "./CollectionPage";
import NewLaws from "./NewLaws";
import GAOReports from "./GAOReports";
import Reports from "./Reports";
import HouseComitteeOnAgriculture from "./HouseComitteeOnAgriculture"
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnTheBudget from "./HouseCommitteeOnTheBudget";
import HouseCommitteeOnArmedServices from "./HouseCommitteeOnArmedServices";
import HouseFloor from "./HouseFloor";
import HouseCommitteeOnEducationAndLabor from "./HouseCommitteeOnEducationAndLabor";
import HouseCommitteeOnEnergyAndCommerce from "./HouseCommitteeOnEnergyAndCommerce";
import HouseCommitteeOnEthics from "./HouseCommitteeOnEthics";
import HouseCommitteeOnFinacialServices from "./HouseCommitteeOnFinacialServices";
import HouseCommitteeOnForeignAffairs from "./HouseCommitteeOnForeignAffairs";
import HouseCommitteeOnHomelandSecurity from "./HouseCommitteeOnHomelandSecurity";
import HouseCommitteeOnHouseAdministration from "./HouseCommitteeOnHouseAdministration";
import HouseCommitteeOnTheJudiciary from "./HouseCommitteeOnTheJudiciary";
import HouseCommitteeOnNaturalResources from "./HouseCommitteeOnNaturalResources";
import HouseCommitteeOnOversightAndReform from "./HouseCommitteeOnOversightAndReform";
import CongressionalBudgetOffice from "./CongressionalBudgetOffice";
import HouseCommitteeOnRules from "./HouseCommitteeOnRules";
import HouseCommitteeOnScienceSpaceAndTechnology from "./HouseCommitteeOnScienceSpaceAndTechnology";
import ExecutiveOrders from "./ExecutiveOrders";
import PresidentialProclamations from "./PresidentialProclamations";
import HouseCommitteeOnTransportationAndInfrastructure from "./HouseCommitteeOnTransportationAndInfrastructure";
import HouseCommitteeOnSmallBusiness from "./HouseCommitteeOnSmallBusiness";
import CongressionalHearings from "./CongressionalHearings";
import{ ListItem, createFullBillFeed, saveToCollection, createHouseCommitteeOversightAndReform, createHouseCommitteeOnRules,
    createHouseCommitteeOnSmallBusiness, createHouseCommitteeOnScienceSpaceAndTechnology,
    createHouseCommitteeOnTransportationAndInfrastructure,
    createHouseCommitteeOnVeteransAffairs,
    createCongressionalBudgetOffice, createBillFeedTwo,
    createBillFeedTwoMobile, createReports, createHouseCommitteeOnAgriculture,
     createHouseCommitteeOnAppropriations, createHouseCommitteeOnArmedServices,
     createHouseCommitteeOnTheBudget, createHouseCommitteeOnEducationAndLabor,
    createHouseCommitteeOnEnergyAndCommerce, createHouseCommitteeOnEthics,
     createHouseCommitteeOnFinancialServices, createHouseCommitteeOnForeignAffairs, createHouseCommitteeOnHomelandSecurity,
    createHouseCommitteeOnHouseAdministration, 
    createHouseCommitteeOnTheJudiciary, createHouseCommitteeOnNaturalResources, 
     createHouseCommitteeOnNaturalResourcesMobile, createGAOReports,
     createNewLaws, createBillsEnrolled
    , createBillsEnrolledMobile, createTrendingBills
    , createHouseFloorToday, congressSearch, Speak, createGAOReportsMobile, createCongressionalHearings, createBillStatus, CreateFeedItem,
    CreateFeedItemBillUpdates, CreateFeedItemWithDocButton, CreateFeedItemWithLawButton,
    CreateFeedItemGAOReports, CreateFeedItemCongressionalReports, CreateFeedItemWithDocumentFlip, CreateFeedItemWithLawButtonFlip, CreateFeedItemWithDocButtonDateFix, CreateFeedAPI} from './Functions'
import BillStatus from "./BillStatus";
import BillUpdates from "./BillUpdates";
import HouseCommitteeOnVeteransAffairs from "./HouseCommitteeOnVeteransAffairs";
import MobileOffCanvas from "./MobileOffCanvas";
import MobileTopNavbar from "./MobileTopNavbar";
import HouseFeed from "./HouseFeed";
import HouseComitteeOnAgricultureMaterials from "./HouseComitteeOnAgricultureMaterials";
import SenateFeed from "./SenateFeed";
import SenateAgricultureNutritionAndForestryCommittee from "./SenateAgricultureNutritionAndForestryCommittee";
import SenateAppropriationCommittee from "./SenateAppropriationCommittee";
import GenerateFeed from "./GenerateFeed";
import RepresenativesDisplay from "./RepresenativesDisplay";
import SenateDisplay from "./SenateDisplay";
import HouseOfRepsDisplay from "./HouseOfRepsDisplay";

const HeavyDependencyComponent = lazy(() => import('./GenerateFeed'));
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);
    var response=''

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;


  class PlayUpdateButtonMobile extends React.Component { 
      constructor(props) {
        super(props);
        this.state = {
          isCardView: false,
        }
      } 
      
      render() {
        return (
          <Button  id='navbarUpdateButtonMobile' size='lg'  onClick={()=>{this.setState({ isCardView: !this.state.isCardView }); Speak(response);  }}  >
            { this.state.isCardView                                                   
              ? <PauseCircle size={30}   />
              : <PlayCircle size={30}  />
            }
            &nbsp;&nbsp;Update (Beta)
          </Button>
        );
      }
      
    }
  
   
  
  
    
  class PlayUpdateButton extends React.Component { 
    constructor(props) {
      super(props);
      this.state = {
        isCardView: false,
      }
    } 
    
    render() {
      return (
        <Button id='navbarCollectionButtonDesktop' size='lg'  onClick={()=>{this.setState({ isCardView: !this.state.isCardView }); Speak(response);  }}  >
          { this.state.isCardView                                                   
            ? <PauseCircle  />
            : <PlayCircle />
          }
          &nbsp;&nbsp;Update (Beta)
        </Button>
      );
    }
    
  }



export const ElectionAssistancePageMobile = () =>   {
    render()
    
    {
  
  
        const [show, setShow] = useState(true);

        const [modalIsOpen, setModalIsOpen] = useState(false)
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
          const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
        const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
          const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
          const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
          const [houseFloorModalIsOpen, setHouseFloorModalIsOpen] = useState(false)
          const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
          const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
          const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
          const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
          const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
          const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
          const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
          const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
          const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
          const [houseCommitteeOnOversightAndReformModalIsOpen, setHouseCommitteeOnOversightAndReformModalIsOpen] = useState(false)
          const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
          const [houseCommitteeOnRulesModalIsOpen, setHouseCommitteeOnRulesModalIsOpen] = useState(false)
          const [houseCommitteeOnScienceSpaceAndTechnologyModalIsOpen, setHouseCommitteeOnScienceSpaceAndTechnologyModalIsOpen] = useState(false)
          const [houseCommitteeOnSmallBusinessModalIsOpen, setHouseCommitteeOnSmallBusinessModalIsOpen] = useState(false)
          const [houseCommitteeOnTransportationAndInfrastructureModalIsOpen, setHouseCommitteeOnTransportationAndInfrastructureModalIsOpen] = useState(false)
          const [houseCommitteeOnVeteransAffairsModalIsOpen, setHouseCommitteeOnVeteransAffairsModalIsOpen] = useState(false)
          const [congressionalHearingsModalIsOpen, setCongressionalHearingsModalIsOpen] = useState(false)
          
          const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
          const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
          const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
          const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
          const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
          const [trendingBillsOptionSix, setTrendingBillsOptionSixModalIsOpen ] = useState(false)
          const [trendingBillsOptionSeven, setTrendingBillsOptionSevenModalIsOpen ] = useState(false)
          const [trendingBillsOptionEight, setTrendingBillsOptionEightModalIsOpen ] = useState(false)
          const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
          const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
          const [trendingBillsOptionEleven, setTrendingBillsOptionElevenModalIsOpen ] = useState(false)
          const [trendingBillsOptionTweleve, setTrendingBillsOptionTweleveModalIsOpen ] = useState(false)
          const [trendingBillsOptionThirteen, setTrendingBillsOptionThirteenModalIsOpen ] = useState(false)
          const [lawsModalIsOpen, setLawsModalIsOpen] = useState(false)
        
          const [congressionalReportsModalIsOpen, setCongressionalReportsModalIsOpen] = useState(false)
            
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
        const [showBioModal, setBioModal] = useState(false);
    
        const [billStatusModalIsOpen, setBillStatusModalIsOpen] = useState(false)
        const [modalInfo, setModalInfo] = useState({
          isOpen: false,
          title: '',
          content: null
        });
      
        const openModal = (title, content) => {
          setModalInfo({
            isOpen: true,
            title,
            content
          });
        };
      
        const closeModal = () => {
          setModalInfo({
            isOpen: false,
            title: '',
            content: null
          });
        };
    return(
       <>
    <MobileTopNavbar/>
  
  
  
  
  <div id='homebg'>
  <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />

{/*Legislative Mobile Carousel*/}
<div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div id="profileImageOnPageDepartment">
        <Image id="profileImage-imageOnPageDepartment" src="/eacLogo.png" />
      </div>
      <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
      {/* <Pen></Pen> */}
        <h5 id='branchHeadingMobile'>Election Assistance Commission</h5>
      </div>
    </div>
    <div style={{ marginLeft: 'auto' }}>
     
    </div>
  </div> 
  <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
  The U.S. Election Assistance Commission (EAC) is an independent, bipartisan commission whose mission is to help election officials improve the administration of elections and help Americans participate in the voting process.
  <Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
  <br></br>
  <br></br>
  <div style={{ display: 'flex', justifyContent: 'center' }}>
  <Link style={{color: '#21cff5'}} onClick={(e) => { e.preventDefault(); window.open("https://www.eac.gov/", "_blank") }} id='pageLink'><h5 id='presidentialFeedHeadingMobile'>Official Site</h5></Link>
  </div>
  </div>
</div>
{/*onClick={() => setModalIsOpen(true)}*/}






<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> Election Assistance Commission</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>The Federal Election Commission (FEC) is the independent regulatory agency charged with administering and enforcing the federal campaign finance law. The FEC has jurisdiction over the financing of campaigns for the U.S. House, Senate, Presidency and the Vice Presidency.</p>

<strong>Mission</strong>
<p>To protect the integrity of the federal campaign finance process by providing transparency and fairly enforcing and administering federal campaign finance laws.</p>

<strong>History</strong>

<p>As early as 1905, President Theodore Roosevelt recognized the need for campaign finance reform and called for legislation to ban corporate contributions for political purposes. In response, Congress enacted several statutes between 1907 and 1966.</p>

<p>In 1971, Congress consolidated its earlier reform efforts in the Federal Election Campaign Act, instituting more stringent disclosure requirements for federal candidates, political parties and political action committees (PACs). Still, without a central administrative authority, the campaign finance laws were difficult to enforce.</p>

<p>Following reports of serious financial abuses in the 1972 presidential campaign, Congress amended the Federal Election Campaign Act in 1974 to set limits on contributions by individuals, political parties and PACs. The 1974 amendments also established an independent agency, the FEC. The FEC opened its doors in 1975.</p>

<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
e.preventDefault();
window.open("https://www.fec.gov/about/mission-and-history/", "_blank");
}}>
https://www.fec.gov/about/mission-and-history/
</Link>
 </div>

       </Modal>




<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
                            openModal('Federal Election Commission News',
                              <GenerateFeed
                              url='&skip=0&schema=united_states_of_america&table=election_assistance_commission&order_by=created_at'
                              name='Election Assistance Commission News' branch='N/A' topic='Election' buttonName='Doc' />)}>News</h5>

 <div class='courtNewsMobile' id='bills'></div>
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=election_assistance_commission&order_by=created_at', 'bills',
                            'Election Assistance Commission News', 'N/A',
                            'Elections', 'Doc', GenerateFeed)}
</Col>



             <Col>
             
                        </Col>
                 
      

                        <br></br>     <br></br>  <br></br><Navbar id='navbarMobileBottom' fixed="bottom">  

 


<div id='navbarSpacer'></div><div id='navbarSpacer'></div>





       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
              
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
</div>



</>
)
}}
export default ElectionAssistancePageMobile;


