import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, NavLink,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal,  Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, List, InfoCircle, FileText, FileMedicalFill, Apple,  Offcanvas, ReceiptCutoff,  ShieldShaded, Globe, Tree, CircleFill, Pen, People, MicFill, ChatSquareQuote, ChatLeftQuote, Coin, BlockquoteRight, Collection, Grid, ThreeDots, Stack, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, GeoAlt, HeartFill, Bricks } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import '../App.css';

import 'bootstrap/dist/css/bootstrap.min.css'


import { render } from '@testing-library/react';

import
 {supremeCourtSearch, whiteHouseSearch, billFeedModal, 
  createHouseFloorToday, createSenateFloorToday, createTrendingBills,
  createNationalAeronauticsAndSpaceAdministration, 
  createEnvironmentalProtectionAgency, createDepartmentOfHomelandSecurity,
  createDepartmentOfVeteransAffairs, createDepartmentOfEducation,
  createDepartmentOfHousingAndUrbanDevelopment, 
  createDepartmentOfHealthAndHumanServices, createDepartmentOfLabor
, createDepartmentOfCommerce, createDepartmentOfAgriculture,
createDepartmentOfTheInterior, createDepartmentOfJustice,
createDepartmentOfTransportation, createDepartmentOfDefense,
createDepartmentOfTreasury, createDepartmentOfEnergy,
createDepartmentOfState, createBillsEnrolledMobile,
createBillsEnrolled, createNewLaws, createGAOReports,
createExecutiveOrders, createPresidentialProclamations, createCongressionalHearings,
createCongressionalBudgetOfficeMobile, createCongressionalBudgetOffice,
createHouseCommitteeOversightAndReform,
createHouseCommitteeOnNaturalResources,
createHouseCommitteeOnTheJudiciary,
createHouseCommitteeOnHouseAdministration,
createHouseCommitteeOnHomelandSecurity,
createHouseCommitteeOnForeignAffairs,
createHouseCommitteeOnFinancialServices,
createHouseCommitteeOnEthics,
createHouseCommitteeOnEnergyAndCommerce,
createHouseCommitteeOnEducationAndLabor,
createHouseCommitteeOnTheBudget,
createHouseCommitteeOnArmedServices,
createHouseCommitteeOnAppropriations,
createHouseCommitteeOnAgriculture,
createReports,
createBillFeedTwoMobile,
createBillFeedTwo,
createBillFeed, createWhiteHouseFeedMobile
, createWhiteHouseFeed, createWhiteHouseDocuments, 
createBillsPresentedFeed, createCourtAppealsMobile,
createCourtAppeals, createUSCourtNewsFeedMobile,
createUSCourtNewsFeed, saveToCollection,
createFullBillFeed, ListItem, Speak, feedText, variableTest, myMethod, myMethod2,
createEconomicIndicators, 
createSecuritiesAndExchangeCommissionPressReleases, 
createSecuritiesAndExchangeCommissionSpeechesAndStatements,
createDepartmentOfCommerceMobile, createNationWeatherServices, createEnvironmentalProtectionAgencyMobile,
createNationalScienceFoundation, createHouseCommitteeOnIntelligence, createUNTopStories, createCDCNewsroom,
createCDCOutbreaks, createCDCTravelNotices, createFoodRecalls, createFoodSafety, createElectionAssistancCommissioneNews, 
createFederalElectionCommissionNews, createBillStatus, CreateFeedItem, CreateFeedItemBillUpdates,
CreateFeedItemWithDocButton, CreateFeedItemWithLawButton, CreateFeedItemPresidentialDocuments, CreateFeedItemExecutiveOrders,
CreateFeedItemCourtAppeals, CreateFeedItemEconomicIndicators, CreateFeedItemGAOReports, 
CreateFeedItemCongressionalReports, CreateFeedItemSupremeCourtArguments, CreateFeedItemTest, 
CreateFeedItemWithDocumentFlip, CreateFeedItemWithDocButtonDateFix, CreateFeedItemWithLawButtonFlip, CreateFeedItemCanada, CreateFeedItemCanadaDateFix, CreateFeedItemHouseOfCommons, CreateFeedItemMexico, CreateFeedAPIRecentCacheMexico, CreateFeedAPI  } from '../Functions'


import MobileOffCanvas from '../MobileOffCanvas';




import MexicoMobileTopNavbar from './MexicoMobileTopNavbar';
import PresidentUpdates from './Executive/PresidentUpdates';
import AgricultureAgency from './Executive/AgricultureAgency';
import WelfareAgency from './Executive/WelfareAgency';
import ConadeAgency from './Executive/ConadeAgency';
import ConadisAgency from './Executive/ConadisAgency';
import ConaquaAgency from './Executive/ConaquaAgency';
import ConaforAgency from './Executive/ConaforAgency';
import INMAgency from './Executive/INMAgency';
import SaludAgency from './Executive/SaludAgency';
import SCTAgency from './Executive/SCTAgency';
import SEAgency from './Executive/SeAgency';
import SecturAgency from './Executive/SecturAgency';
import SedatuAgency from './Executive/SedatuAgency';
import SedenaAgency from './Executive/SedenaAgency';
import SemarnatAgency from './Executive/SemarnatAgency';
import SHCPAgency from './Executive/SHCPAgency';
import SREAgency from './Executive/SREAgency';
import STPSAgency from './Executive/STPSAgency';
import SenateNews from './Legislative/SenateNews';
import ChamberNews from './Legislative/ChamberNews';
import SupremeCourtNews from './Judicial/SupremeCourtNews';
import MobileOffCanvasMexico from '../MobileOffCanvasMexico';
import GenerateFeedRecentMexico from '../GenerateFeedRecentMexico';
import GenerateFeed from '../GenerateFeed';




const mobileCarouselInterval = 60000;









  











  const setHomeCountryAndReload = (homeCountry) => {
    localStorage.setItem('homeCountry', homeCountry);
    //console.log(localStorage.getItem('homeCountry'))
    
  };

  
const MexicoHomeMobile = () => {
  
  const [homeCountry, setHomeCountry] = useState('');

  useEffect(() => {
  
    const storedHomeCountry = localStorage.getItem('homeCountry');
    setHomeCountry(storedHomeCountry);
  }, []);

  render()
  
  {

//Off Canvas
const [showOffCanvas, setShowOffCanvas] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

    const [show, setShow] = useState(true);
  
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [fullscreen, setFullScreen] = useState(true);
  
    
    const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
  
    const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
  
  
    const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
    const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
    const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  
  
    const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
    const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
    const [courtOpinionsModalIsOpen, setCourtOpinionsModalIsOpen] = useState(false)
    const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
    

    const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
  
    const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  
    const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
  
  
    const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
    const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
    const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
    const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
    const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
    const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
    const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
    const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
    const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
    const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
    const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
    const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
    const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
    const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
    const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
    const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
    const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
    const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
    const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
    const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
    const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
    const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
    const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
    const [trendingBillsOptionSix, setTrendingBillsOptionSixModalIsOpen ] = useState(false)
    const [trendingBillsOptionSeven, setTrendingBillsOptionSevenModalIsOpen ] = useState(false)
    const [trendingBillsOptionEight, setTrendingBillsOptionEightModalIsOpen ] = useState(false)
    const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
    const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
    const [trendingBillsOptionEleven, setTrendingBillsOptionElevenModalIsOpen ] = useState(false)
    const [trendingBillsOptionTweleve, setTrendingBillsOptionTweleveModalIsOpen ] = useState(false)
    const [trendingBillsOptionThirteen, setTrendingBillsOptionThirteenModalIsOpen ] = useState(false)
    const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    const [cdcNewsroomModalIsOpen, setCDCNewsroomModalIsOpen] = useState(false)
    const [outbreaksModalIsOpen, setOutbreaksModalIsOpen] = useState(false)
    const [travelNoticesModalIsOpen, setTravelNoticesModalIsOpen] = useState(false)
    
    const [foodRecallsModalIsOpen, setFoodRecallsModalIsOpen] = useState(false)
    const [foodSafetyModalIsOpen, setFoodSafetyModalIsOpen] = useState(false)
    const [medWatchModalIsOpen, setMedWatchModalIsOpen] = useState(false)
    const [healthyLivingFeaturesModalIsOpen, setHealthyLivingFeaturesModalIsOpen] = useState(false)
    const [preventingChronicDiseaseModalIsOpen, setPreventingChronicDiseaseModalIsOpen] = useState(false)
    const [covid19ModalIsOpen, setCovid19ModalIsOpen] = useState(false)
    const [novelCoronavirusModalIsOpen, setNovelCoronavirusModalIsOpen] = useState(false)
    const [vaccineUpdatesModalIsOpen, setVaccineUpdatesModalIsOpen] = useState(false)
    const [seasonalFluModalIsOpen, setSeasonalFluModalIsOpen] = useState(false)
      const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
      const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
      const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
      const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
      const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
      const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
      const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
      const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
      const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
      const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
      const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
      const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
      const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
      const [houseCommitteeOnOversightAndReformModalIsOpen, setHouseCommitteeOnOversightAndReformModalIsOpen] = useState(false)
      const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
      const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
      const [economicIndicatorsModalIsOpen, setEconomicIndicatorsModalIsOpen] = useState(false)
      const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
      const [secPressModalIsOpen, setSECPressModalIsOpen] = useState(false)
      const [secSAndSModalIsOpen, setSECSAndSModalIsOpen] = useState(false)
      const [unTopStoriesModalIsOpen, setUNTopStoriesModalIsOpen] = useState(false)
      const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  
      
    
  
      const [congressionalHearingsModalIsOpen, setCongressionalHearingsModalIsOpen] = useState(false)
  
      const [houseFloorModalIsOpen, setHouseFloorModalIsOpen] = useState(false)
  
  
  
    const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
    const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
    const [houseCommitteeOnIntelligenceModalIsOpen, setHouseCommitteeOnIntelligenceModalIsOpen] = useState(false)
  
    const [fecModalIsOpen, setFECModalIsOpen] = useState(false)
    const [eacModalIsOpen, setEACModalIsOpen] = useState(false)
  
    const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
    const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
    const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
  
    const [alertModalIsOpen, setAlertModalIsOpen] = useState(true)
    const [billStatusModalIsOpen, setBillStatusModalIsOpen] = useState(false)
  var branch = "all";
  const nullEntry = [];
  const [recentsModalIsOpen, setRecentsModalIsOpen] = useState(false)
  const [notifications, setNotifications] = useState(nullEntry);



  

  localStorage.setItem('currentPage', 'mexico')
  return(
     <>

<div id='homebg'>
<MexicoMobileTopNavbar/>
 

        
<Col>

<h5 id='recentsHeading' onClick={() => setRecentsModalIsOpen(true)}>Recents</h5>

<div class='courtNewsMobile' id='recent'></div> 
{CreateFeedAPIRecentCacheMexico('https://api.govglance.org/posts/recent/schema?limit=1&skip=0&schema=mexico', 'recent', 
                'Recents', 'N/A', 
                'N/A', 'Doc', GenerateFeedRecentMexico)}
        <Modal id='fullBillModal' show={recentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setRecentsModalIsOpen(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3>Recents</h3></Modal.Header>
         <GenerateFeedRecentMexico/>
        </Modal>

</Col>
     {/*   <DisplayHomeProvince/>*/} 
     <br></br>

{/*Legislative Mobile Carousel*/}
   <h5 id='branchHeadingMobile' onClick={() => window.open('/mexico/legislative', "_self")} > <Pen></Pen> Legislative<ChevronRight id='clickChevron'></ChevronRight></h5>
  {/*onClick={() => setModalIsOpen(true)}*/}

<Carousel controls={false} >
{/* */}

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setFECModalIsOpen(true)}>Senate</h5></Row>

<div id='senate'></div>
                        
                                 
{CreateFeedAPI('&skip=0&schema=mexico&table=senado_de_la_republica&order_by=created_at', 'senate', 
                            'Senate', 'Legislative', 
                            'N/A', 'Doc', GenerateFeed)}


<Modal id='mobileFullBillModal' show={fecModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() =>  setFECModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Senate</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=senado_de_la_republica&order_by=created_at'
                                name='Senate' branch='Legislative' topic='N/A' buttonName='Doc' />
</Modal>
</Col>

  
  </div>
  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setNSFModalIsOpen(true)}>Chamber</h5></Row>

<div id='chamber'></div>
                        
                        
{CreateFeedAPI('&skip=0&schema=mexico&table=chamber_of_deputies&order_by=created_at', 'chamber', 
                            'Chamber', 'Legislative', 
                            'N/A', 'Doc', GenerateFeed)}


<Modal id='mobileFullBillModal' show={nsfModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() =>  setNSFModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Chamber</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=chamber_of_deputies&order_by=created_at'
                                name='Chamber' branch='Legislative' topic='N/A' buttonName='Doc' />
</Modal>
</Col>

  
  </div>
  </Carousel.Item>

  

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/mexico/legislative' >View all Legislative news</Button>
</div>
</Col>

</div>



</Carousel.Item>

</Carousel>
  {/* Executive Mobile Carousel*/}
  <h5 id='branchHeadingMobile' onClick={() => window.open('/mexico/executive', "_self")}  ><People></People> Executive<ChevronRight id='clickChevron'></ChevronRight></h5>
  <Carousel controls={false}>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setDepartmentOfStateModalIsOpen(true)}>President Newsroom</h5></Row>

<div id='president'></div>
                             
{CreateFeedAPI('&skip=0&schema=mexico&table=presidente&order_by=created_at', 'president', 
                            'President Newsroom', 'Executive', 
                            'N/A', 'Doc', GenerateFeed)}


<Modal id='mobileFullBillModal' show={departmentOfStateModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfStateModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>President Newsroom</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=presidente&order_by=created_at'
                                name='President Newsroom' branch='Executive' topic='N/A' buttonName='Doc' />
</Modal>
</Col>

  
  </div>
  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setFullBillModalIsOpen(true)}>Secretaría de Agricultura y Desarrollo Rural</h5></Row>

<div id='executiveNews'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                        {CreateFeedAPI('&skip=0&schema=mexico&table=agricultura&order_by=created_at', 'executiveNews', 
                            'Secretaría de Agricultura y Desarrollo Rural', 'Legislative', 
                            'Environment', 'Doc', GenerateFeed)}


<Modal id='mobileFullBillModal' show={fullBillModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFullBillModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Agricultura y Desarrollo Rural</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=agricultura&order_by=created_at'
                                name='Secretaría de Agricultura y Desarrollo Rural' branch='Legislative' topic='N/A' buttonName='Doc' />
</Modal>
</Col>

  
  </div>
  </Carousel.Item>


  <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setTrendingBillsModalIsOpen(true)}>Secretaría de Bienestar</h5></Row>

<div id='welfare'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                        {CreateFeedAPI('&skip=0&schema=mexico&table=bienestar&order_by=created_at', 'welfare', 
                            'Secretaría de Bienestar', 'Legislative', 
                            'N/A', 'Doc', GenerateFeed)}




<Modal id='mobileFullBillModal' show={trendingBillsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Bienestar</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=bienestar&order_by=created_at'
                                name='Secretaría de Bienestar' branch='Legislative' topic='N/A' buttonName='Doc'/>
</Modal>
</Col>

  
  </div>
  </Carousel.Item>

  




  <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/mexico/executive' >View all Executive news</Button>
</div>
</Col>

</div>



</Carousel.Item>



      </Carousel >
  

{/* Judicial Mobile Carousel*/}
<h5 id='branchHeadingMobile' onClick={() => window.open('/mexico/judicial', "_self")} ><svg xmlns="http://www.w3.org/2000/svg" height="27.54px" viewBox="0 -960 960 960" width="27.54px" fill="#FFFFFF"><path d="M160-120v-80h480v80H160Zm226-194L160-540l84-86 228 226-86 86Zm254-254L414-796l86-84 226 226-86 86Zm184 408L302-682l56-56 522 522-56 56Z"/></svg>   Judicial<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false} >
{/* */}

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setFoodRecallsModalIsOpen(true)}>Supreme Court</h5></Row>

<div id='sc'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                        {CreateFeedAPI('&skip=0&schema=mexico&table=suprema_corte_de_justicia_de_la_nacion&order_by=created_at', 'sc', 
                            'Supreme Court', 'Judicial', 
                            'N/A', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={foodRecallsModalIsOpen}
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFoodRecallsModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court News</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=suprema_corte_de_justicia_de_la_nacion&order_by=created_at'
                                name='Supreme Court' branch='Judicial' topic='N/A' buttonName='Doc'/>
</Modal>
</Col>

  
  </div>
  </Carousel.Item>



  <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/mexico/judicial' >View all Judicial news</Button>
</div>
</Col>

</div>



</Carousel.Item>

</Carousel>
{/* Economy */}
<h5 id='branchHeadingMobile' onClick={() => window.open('/mexico/economy', "_self")} > <Coin></Coin>    Economy<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setTrendingBillsOptionTenModalIsOpen(true)}>Secretaría de Economía</h5></Row>

<div id='se'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                        {CreateFeedAPI('&skip=0&schema=mexico&table=se&order_by=created_at', 'se', 
                            'Secretaría de Economía', 'Executive', 
                            'Economy', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionTen} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Economía</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=se&order_by=created_at'
                                name='Secretaría de Economía' branch='Executive' topic='Economy' buttonName='Doc'/>
</Modal>
</Col>

  
  </div>
  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setTrendingBillsOptionElevenModalIsOpen(true)}>Secretaría de Turismo</h5></Row>

<div id='sectur'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                        {CreateFeedAPI('&skip=0&schema=mexico&table=sectur&order_by=created_at', 'sectur', 
                            'Secretaría de Turismo', 'Executive', 
                            'Economy', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionEleven} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionElevenModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Turismo</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=sectur&order_by=created_at'
                                name='Secretaría de Turismo' branch='Executive' topic='Economy' buttonName='Doc'/>
</Modal>
</Col>

  
  </div>
  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setDepartmentOfEnergyModalIsOpen(true)}>Secretaría de Hacienda y Crédito Público</h5></Row>

<div id='shcp'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                        {CreateFeedAPI('&skip=0&schema=mexico&table=shcp&order_by=created_at', 'shcp', 
                            'Secretaría de Hacienda y Crédito Público', 'Executive', 
                            'Economy', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={departmentOfEnergyModalIsOpen} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfEnergyModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Hacienda y Crédito Público</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=shcp&order_by=created_at'
                                name='Secretaría de Hacienda y Crédito Público' branch='Executive' topic='Economy' buttonName='Doc' />
</Modal>
</Col>

  
  </div>
  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='legislativeConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/mexico/economy' >View all Economy news</Button>
</div>
  
  </Col>


      </div>
      </Carousel.Item>



      </Carousel>
{/*Election */}
<h5 id='branchHeadingMobile' > <ReceiptCutoff></ReceiptCutoff>   Elections<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>



      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='legislativeConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/mexico/election' >View all Elections news</Button>
</div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>

{/*Environment */}
 <h5 id='branchHeadingMobile' onClick={() => window.open('/mexico/environment', "_self")} > <Tree></Tree>    Environment<ChevronRight id='clickChevron'></ChevronRight></h5>
 <Carousel controls={false}>


<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setFullBillModalIsOpen(true)}>Secretaría de Agricultura y Desarrollo Rural</h5></Row>

<div id='executiveNews2'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                        {CreateFeedAPI('&skip=0&schema=mexico&table=agricultura&order_by=created_at', 'executiveNews2', 
                            'Secretaría de Agricultura y Desarrollo Rural', 'Legislative', 
                            'Environment', 'Doc', GenerateFeed)}




</Col>

  
  </div>
  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setTrendingBillsOptionFiveModalIsOpen(true)}>Comisión Nacional Forestal</h5></Row>

<div id='conafor'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                        {CreateFeedAPI('&skip=0&schema=mexico&table=conafor&order_by=created_at', 'conafor', 
                            'Comisión Nacional Forestal', 'Executive', 
                            'Environment', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionFive} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionFiveModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Comisión Nacional Forestal</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=conafor&order_by=created_at'
                                name='Comisión Nacional Forestal' branch='Executive' topic='Environment' buttonName='Doc' />
</Modal>
</Col>

  
  </div>
  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setTrendingBillsOptionFourModalIsOpen(true)}>Comisión Nacional del Agua</h5></Row>

<div id='conagua'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                        {CreateFeedAPI('&skip=0&schema=mexico&table=conagua&order_by=created_at', 'conagua', 
                            'Comisión Nacional del Agua', 'Executive', 
                            'Environment', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionFour} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionFourModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Comisión Nacional del Agua</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=conagua&order_by=created_at'
                                name='Comisión Nacional del Agua' branch='Executive' topic='Environment' buttonName='Doc' />
</Modal>
</Col>

  
  </div>
  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setTrendingBillsOptionNineModalIsOpen(true)}>Secretaría de Comunicaciones y Transportes</h5></Row>

<div id='sct'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                        {CreateFeedAPI('&skip=0&schema=mexico&table=sct&order_by=created_at', 'sct', 
                            'Secretaría de Comunicaciones y Transportes', 'Executive', 
                            'Environment', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionNine} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionNineModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Comunicaciones y Transportes</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=sct&order_by=created_at'
                                name='Secretaría de Comunicaciones y Transportes' branch='Executive' topic='Environment' buttonName='Doc' />
</Modal>
</Col>

  
  </div>
  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setTrendingBillsOptionTweleveModalIsOpen(true)}>Secretaría de Desarrollo Agrario, Territorial y Urbano</h5></Row>

<div id='sedatu'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                        {CreateFeedAPI('&skip=0&schema=mexico&table=sedatu&order_by=created_at', 'sedatu', 
                            'Secretaría de Desarrollo Agrario, Territorial y Urbano', 'Executive', 
                            'Environment', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionTweleve} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTweleveModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Desarrollo Agrario, Territorial y Urbano</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=sedatu&order_by=created_at'
                                name='Secretaría de Desarrollo Agrario, Territorial y Urbano' branch='Executive' topic='Environment' buttonName='Doc' />
</Modal>
</Col>

  
  </div>
  </Carousel.Item>
     
  <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}>Secretaría de Medio Ambiente y Recursos Naturales</h5></Row>

<div id='semarNat'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                        {CreateFeedAPI('&skip=0&schema=mexico&table=semarnat&order_by=created_at', 'semarNat', 
                            'Secretaría de Medio Ambiente y Recursos Naturales', 'Executive', 
                            'Environment', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={departmentOfDefenseModalIsOpen} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Medio Ambiente y Recursos Naturales</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=semarnat&order_by=created_at'
                                name='Secretaría de Medio Ambiente y Recursos Naturales' branch='Executive' topic='Environment' buttonName='Doc' />
</Modal>
</Col>

  
  </div>
  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='legislativeConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/mexico/environment' >View all Environment news</Button>
  </div>
  
  </Col>


      </div>
      </Carousel.Item>


      </Carousel>

{/*Foreign Affairs */}
 <h5 id='branchHeadingMobile' onClick={() => window.open('/mexico/foreignaffairs', "_self")} > <Globe></Globe>    Foreign Affairs<ChevronRight id='clickChevron'></ChevronRight></h5>
 <Carousel controls={false}>
 

 <Carousel.Item interval={mobileCarouselInterval}>
 
 <div id='legislativeConMobile' >
   
     
     <Col>
 
 
 
     <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setTrendingBillsOptionSevenModalIsOpen(true)}>Instituto Nacional de Migración</h5></Row>
 
 <div id='inm'></div>
                         
                         {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                         {CreateFeedAPI('&skip=0&schema=mexico&table=inm&order_by=created_at', 'inm', 
                             'Instituto Nacional de Migración', 'Executive', 
                             'Foreign Affairs', 'Doc', GenerateFeed)}
 
 <Modal id='mobileFullBillModal' show={trendingBillsOptionSeven} 
 size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionSevenModalIsOpen(false)}>
 <Modal.Header id='mobileBillModalHeader' closeButton><h3>Instituto Nacional de Migración</h3></Modal.Header>
 <GenerateFeed url='&skip=0&schema=mexico&table=inm&order_by=created_at'
                                 name='Instituto Nacional de Migración' branch='Executive' topic='Foreign Affairs' buttonName='Doc' />
 </Modal>
 </Col>
 
   
   </div>
   </Carousel.Item>
 
 
   <Carousel.Item interval={mobileCarouselInterval}>
 
 <div id='legislativeConMobile' >
   
     
     <Col>
 
 
 
     <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(true)}>Secretaría de Relaciones Exteriores</h5></Row>
 
 <div id='sre'></div>
                         
                         {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                         {CreateFeedAPI('&skip=0&schema=mexico&table=sre&order_by=created_at', 'sre', 
                             'Secretaría de Relaciones Exteriores', 'Executive', 
                             'Foreign Affairs', 'Doc', GenerateFeed)}
 
 <Modal id='mobileFullBillModal' show={departmentOfHealthAndHumanServicesModalIsOpen} 
 size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHealthAndHumanServicesModalIsOpen(false)}>
 <Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Relaciones Exteriores</h3></Modal.Header>
 <GenerateFeed url='&skip=0&schema=mexico&table=sre&order_by=created_at'
                                 name='Secretaría de Relaciones Exteriores' branch='Executive' topic='Foreign Affairs' buttonName='Doc' />
 </Modal>
 </Col>
 
   
   </div>
   </Carousel.Item>
 
 
 
   <Carousel.Item interval={mobileCarouselInterval}>
 
 <div id='legislativeConMobile' >
   
     
     <Col>
 
 
 
     <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setTrendingBillsOptionThirteenModalIsOpen(true)}>Secretaría de la Defensa Nacional</h5></Row>
 
 <div id='sedena'></div>
                         
 {CreateFeedAPI('&skip=0&schema=mexico&table=sedena&order_by=created_at', 'sedena', 
                             'Secretaría de la Defensa Nacional', 'Executive', 
                             'Foreign Affairs', 'Doc', GenerateFeed)}
 
 <Modal id='mobileFullBillModal' show={trendingBillsOptionThirteen} 
 size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionThirteenModalIsOpen(false)}>
 <Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de la Defensa Nacional</h3></Modal.Header>
 <GenerateFeed url='&skip=0&schema=mexico&table=sedena&order_by=created_at'
                                 name='Secretaría de la Defensa Nacional' branch='Executive' topic='Foreign Affairs' buttonName='Doc' />
 </Modal>
 </Col>
 
   
   </div>
   </Carousel.Item>
 
 
 
   <Carousel.Item interval={mobileCarouselInterval}>
    <div id='legislativeConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/mexico/foreignaffairs' >View all Foreign Affairs news</Button>
</div>
  
  </Col>


      </div>
      </Carousel.Item>

 
       </Carousel>


{/* Health*/}
<h5 id='branchHeadingMobile' onClick={() => window.open('/mexico/health', "_self")} > <FileMedicalFill></FileMedicalFill>    Health<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setTrendingBillsOptionEightModalIsOpen(true)}>Secretaría de Salud</h5></Row>

<div id='salud'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                        {CreateFeedAPI('&skip=0&schema=mexico&table=salud&order_by=created_at', 'salud', 
                            'Secretaría de Salud', 'Executive', 
                            'Health', 'Doc', GenerateFeed)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionEight} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionEightModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría de Salud</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=salud&order_by=created_at'
                                name='Secretaría de Salud' branch='Executive' topic='Health' buttonName='Doc' />
</Modal>
</Col>

  
  </div>
  </Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setTrendingBillsOptionTwoModalIsOpen(true)}>Comisión Nacional de Cultura Física y Deporte</h5></Row>

<div id='conade'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                        {CreateFeedAPI('&skip=0&schema=mexico&table=conade&order_by=created_at', 'conade', 
                            'Comisión Nacional de Cultura Física y Deporte', 'Executive', 
                            'Health', 'Doc', GenerateFeed)}



<Modal id='mobileFullBillModal' show={trendingBillsOptionTwo} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTwoModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Comisión Nacional de Cultura Física y Deporte</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=conade&order_by=created_at'
                                name='Comisión Nacional de Cultura Física y Deporte' branch='Executive' topic='Health' buttonName='Doc' />
</Modal>
</Col>

  
  </div>
  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(true)}>Secretaría del Trabajo y Previsión Social</h5></Row>

<div id='stps'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                        {CreateFeedAPI('&skip=0&schema=mexico&table=stps&order_by=created_at', 'stps', 
                            'Secretaría del Trabajo y Previsión Social', 'Executive', 
                            'Health', 'Doc', GenerateFeed)}
<Modal id='mobileFullBillModal' show={departmentOfHousingAndUrbanDevelopmentModalIsOpen} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Secretaría del Trabajo y Previsión Social</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=stps&order_by=created_at'
                                name='Secretaría del Trabajo y Previsión Social' branch='Executive' topic='Health' buttonName='Doc' />
</Modal>
</Col>

  
  </div>
  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setTrendingBillsOptionThreeModalIsOpen(true)}>Consejo Nacional para el Desarrollo y la Inclusión de las Personas con Discapacidad</h5></Row>

<div id='conadis'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
                        {CreateFeedAPI('&skip=0&schema=mexico&table=conadis&order_by=created_at', 'conadis', 
                            'Consejo Nacional para el Desarrollo y la Inclusión de las Personas con Discapacidad', 'Executive', 
                            'Health', 'Doc', GenerateFeed)}


<Modal id='mobileFullBillModal' show={trendingBillsOptionThree} 
size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionThreeModalIsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Consejo Nacional para el Desarrollo y la Inclusión de las Personas con Discapacidad</h3></Modal.Header>
<GenerateFeed url='&skip=0&schema=mexico&table=conadis&order_by=created_at'
                                name='Consejo Nacional para el Desarrollo y la Inclusión de las Personas con Discapacidad' branch='Executive' topic='Health' buttonName='Doc' />
</Modal>
</Col>

  
  </div>
  </Carousel.Item>

  <Carousel.Item interval={mobileCarouselInterval}>
    <div id='legislativeConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/mexico/health' >View all Health news</Button>
</div>
  
  </Col>


      </div>
      </Carousel.Item>


      </Carousel>





      <h5 id='branchHeadingMobile'  > <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-rocket-takeoff" viewBox="0 0 16 16">
  <path d="M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z"/>
  <path d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z"/>
  <path d="M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z"/>
</svg>  Science & Tech<ChevronRight id='clickChevron'></ChevronRight></h5>

<Carousel controls={false}>




  

      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='legislativeConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/mexico/science&tech' >View all Science and Tech news</Button>
</div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>

      <h5 id='branchHeadingMobile' onClick={() => window.open('/mexico/defense', "_self")} > <ShieldShaded/>    Defense<ChevronRight id='clickChevron'></ChevronRight></h5>
<Carousel controls={false}>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setTrendingBillsOptionThirteenModalIsOpen(true)}>Secretaría de la Defensa Nacional</h5></Row>

<div id='sedena2'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/sedena.xml',
   'sedena2', 'Secretaría de la Defensa Nacional', 'Executive', 'N/A', SedenaAgency)}


</Col>

  
  </div>
  </Carousel.Item>



      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='legislativeConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/mexico/defense' >View all Defense news</Button>
</div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>

      <h5 id='branchHeadingMobile' onClick={() => window.open('/mexico/infrastructure', "_self")} > <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
  <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
  <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3z"/>
</svg>    Infrastructure<ChevronRight id='clickChevron'></ChevronRight></h5>

<Carousel controls={false}>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile' >
  
    
    <Col>



    <Row id = 'mobileFeedHeadingRow'><h5 id='feedHeadingMobileHome' onClick={() => setTrendingBillsOptionThirteenModalIsOpen(true)}>Secretaría de la Defensa Nacional</h5></Row>

<div id='sedena2'></div>
                        
                        {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
  {CreateFeedItemMexico('https://gov-glance-mexico.nyc3.digitaloceanspaces.com/executive-xml/agencies/sedena.xml',
   'sedena2', 'Secretaría de la Defensa Nacional', 'Executive', 'N/A', SedenaAgency)}


</Col>

  
  </div>
  </Carousel.Item>



      <Carousel.Item interval={mobileCarouselInterval}>
    <div id='legislativeConMobile'>
     
       
  <Col>
  <div id='viewFullPageButton'>
  <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/mexico/infrastructure' >View all Infrastructure news</Button>
</div>
  
  </Col>


      </div>
      </Carousel.Item>

      </Carousel>

<br></br>


                     <br></br>
  
                     <div className="d-flex flex-column align-items-center text-center">  
      <h5 id='branchHeadingMobile2'><GeoAlt/> Change country <ChevronRight id='clickChevron'></ChevronRight></h5>
</div>
<Col className="d-flex flex-column align-items-center">
  <Button id='selectHomeCountry' onClick={() => window.open('/canada', "_self")}>
    🇨🇦 Canada
  </Button>

  <Button id='selectHomeCountry' onClick={() => window.open('/mexico', "_self")}>
    🇲🇽 Mexico
  </Button>

  <Button id='selectHomeCountry' onClick={() => window.open('/uk', "_self")}>
    🇬🇧 United Kingdom
  </Button>

  <Button id='selectHomeCountry' onClick={() => window.open('/us', "_self")}>
    🇺🇸 United States
  </Button>
</Col>

                     <br></br>
<br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_self")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>


<br></br><br></br><br></br>
<Navbar id='navbarMobileBottom' fixed="bottom">  



       
<div id='changeBranchDropdownMobile'>



<MobileOffCanvasMexico />
              
            </div>

</Navbar>

<br></br><br></br>
{/* 
<Offcanvas show={showOffCanvas} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas>
      */}
</div>
</>
)

                }
              }
export default MexicoHomeMobile;
