// useHandleContainerClick.js
import { useHistory } from 'react-router-dom';

const useHandleContainerClickPresident = () => {
  const history = useHistory();

  const handleContainerClickPresident = (name, termStart, termEnd, presidentNumber, imageSource, description) => {
    // Save the name here or perform any other necessary actions



   
    history.push(`/us/president/${presidentNumber}`, { 
      name,
      termStart,
      termEnd,
      presidentNumber,
      imageSource,
      description
    });
  };

  return handleContainerClickPresident;
};

export default useHandleContainerClickPresident;
