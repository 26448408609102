// useHandleContainerClick.js
import { useHistory } from 'react-router-dom';

const UseHandleContainerClickCandidate = () => {
  const history = useHistory();

  const handleContainerClickCandidate = (name, office, state, party, district, date, id) => {
    // Save the name here or perform any other necessary actions
    
    console.log(id)
    // Navigate to the representative page with bioGuideId and name as state
    window.scrollTo(0, 0);
    history.push(`/us/candidate/${name}`, { 
      name,
      office,
      state,
      party,
      district, 
      date,
      id
    });
  };

  return handleContainerClickCandidate;
};

export default UseHandleContainerClickCandidate;
