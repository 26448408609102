
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, PauseCircle, PlayCircle, People, HeartFill, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import ExecutiveOrders from './ExecutiveOrders';
import PresidentialProclamations from './PresidentialProclamations';
import NationalScienceFoundation from './NationalScienceFoundation';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
  createWhiteHouseFeedMobile,createDepartmentOfState,
  createDepartmentOfDefense, 
  createDepartmentOfJustice, createDepartmentOfTheInterior,
  createDepartmentOfAgriculture, createDepartmentOfCommerce,
  createDepartmentOfCommerceMobile, createDepartmentOfLabor,
  createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
  ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
  , createEnvironmentalProtectionAgency,
  createDepartmentOfTreasury, createNationalScienceFoundation, 
   createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, createFederalRegisterStateDepartment, createFederalRegisterTreasuryDepartment, createFederalRegisterJusticeDepartment, createFederalRegisterInteriorDepartment, createFederalRegisterAgricultureDepartment, createFederalRegisterCommerceDepartment, createFederalRegisterLaborDepartment, createFederalRegisterHealthAndHumanServicesDepartment, createFederalRegisterHousingAndUrbanDevelopmentDepartment, createFederalRegisterHomelandSecurityDepartment, createFederalRegisterEducationDepartment, createFederalRegisterVeteransAffairsDepartment,
   CreateFeedItem, CreateFeedItemBillUpdates, CreateFeedItemWithDocButton,
   CreateFeedItemPresidentialDocuments, CreateFeedItemExecutiveOrders, CreateFeedItemCongressionalReports, CreateFeedItemExecutiveOffice, CreateFeedItemBillWithDescription, CreateFeedAPIDepartmentUS, CreateFeedAPI } from './Functions'
import ExecutiveBranchMobile from './ExecutiveBranchMobile';
import DesktopNavbar from './DesktopNavbar';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import FederalRegisterJusticeDepartment from './FederalRegisterJusticeDepartment';
import FederalRegisterInteriorDepartment from './FederalRegisterInteriorDepartment';
import FederalRegisterAgricultureDepartment from './FederalRegisterAgricultureDepartment';
import FederalRegisterCommerceDepartment from './FederalRegisterCommerceDepartment';
import FederalRegisterLaborDepartment from './FederalRegisterLaborDepartment';
import FederalRegisterHealthAndHumanServicesDepartment from './FederalRegisterHealthAndHumanServicesDepartment';
import FederalRegisterHousingAndUrbanDevelopmentDepartment from './FederalRegisterHousingAndUrbanDevelopmentDepartment';
import FederalRegisterVeteransAffairsDepartment from './FederalRegisterVeteransAffairsDepartment';
import FederalRegisterHomelandSecurityDepartment from './FederalRegisterHomelandSecurityDepartment';
import FederalRegisterEducationDepartment from './FederalRegisterEducationDepartment';
import CouncilOfEconomicAdvisers from './CouncilOfEconomicAdvisers';
import CouncilOnEnvironmentQuality from './CouncilOnEnvironmentQuality';
import DomesticPolicyCouncil from './DomesticPolicyCouncil';
import GenderPolicyCouncil from './GenderPolicyCouncil';
import NationalEconomicCouncil from './NationalEconomicCouncil';
import NationalSecurityCouncil from './NationalSecurityCouncil';
import OfficeOfIntergovernmentalAffairs from './OfficeOfIntergovernmentalAffairs';
import OfficeOfManagmentAndBudget from './OfficeOfManagmentAndBudget';
import OfficeOfNationalDrugControlPolicy from './OfficeOfNationalDrugControlPolicy';
import OfficeOfPublicEngagement from './OfficeOfPublicEngagement';
import OfficeOfScienceAndTechnologyPolicy from './OfficeOfScienceAndTechnologyPolicy';
import OfficeOfNationalCyberDirector from './OfficeOfNationalCyberDirector';
import PresidentialPersonnelOffice from './PresidentialPersonnelOffice';
import ExecutiveOfficeMobile from './ExecutiveOfficeMobile';
import ArmyDocuments from './ArmyDocuments';
import MarinesPressReleases from './MarinesPressReleases';
import NavyDocuments from './NavyDocuments';
import SpaceForceNews from './SpaceForceNews';
import NationalGuardNews from './NationalGuardNews';
import NSADocuments from './NSADocuments';
import ArmyCorpsEngineers from './ArmyCorpsEngineers';
import DefenseMobile from './DefenseMoible';
import HomelandSecurityDocuments from './HomelandSecurityDocuments';
import CoastGuardDocuments from './CoastGuardDocuments';
import FEMADocuments from './FEMADocuments';
import SecretService from './SecretService';
import TSADocuments from './TSADocuments';
import CitizenshipAndImmigrationDocuments from './CitizenshipAndImmigrationDocuments';
import CustomsAndBorderDocs from './CustomsAndBorderDocs';
import CustomsEnforcementDocs from './CustomsEnforcementDocs';
import HomelandSecurityMobile from './HomelandSecurityMobile';
import GenerateFeedDepartment from './GenerateFeedDepartment';
import GenerateFeed from './GenerateFeed';





var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;





  

 


  


export const HomelandSecurity = () =>
 {
   render()
   
   {
  const [show, setShow] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);


  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)

  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)

  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)


  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
  const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
  const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)

  const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
  const [federalRegisterTreasuryDepartmentModalIsOpen, setFederalRegisterTreasuryDepartmentModalIsOpen] = useState(false)
  const [federalRegisterJusticeDepartmentModalIsOpen, setFederalRegisterJusticeDepartmentModalIsOpen] = useState(false)
  const [federalRegisterInteriorDepartmentModalIsOpen, setFederalRegisterInteriorDepartmentModalIsOpen] = useState(false)
  const [federalRegisterAgricultureDepartmentModalIsOpen, setFederalRegisterAgricultureDepartmentModalIsOpen] = useState(false)
  const [federalRegisterVeteransAffairsDepartmentModalIsOpen, setFederalRegisterVeteransAffairsDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHomelandSecurityDepartmentModalIsOpen, setFederalRegisterHomelandSecurityDepartmentModalIsOpen] = useState(false)
  const [federalRegisterCommerceDepartmentModalIsOpen, setFederalRegisterCommerceDepartmentModalIsOpen] = useState(false)
  const [federalRegisterLaborDepartmentModalIsOpen, setFederalRegisterLaborDepartmentModalIsOpen] = useState(false)
  const [federalRegisterEducationDepartmentModalIsOpen, setFederalRegisterEducationDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHealthAndHumanServicesDepartmentModalIsOpen, setFederalRegisterHealthAndHumanServicesDepartmentModalIsOpen] = useState(false)
  const [federalRegisterHousingAndUrbanDevelopmentDepartmentModalIsOpen, setFederalRegisterHousingAndUrbanDevelopmentDepartmentModalIsOpen] = useState(false)
  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)

  const [homelandModalIsOpen, setHomelandModalIsOpen] = useState(false);

  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
  const [showBioModal, setBioModal] = useState(false);
  const [showMissionModal, setMissionModal] = useState(false);

  return(
    <>
      <BrowserView>
        <div id='homePage'>

     

        <div id='homebg'>

          <DesktopNavbar/>
          <div id='feedDisplay' 
                  style={{ padding: '20px', borderRadius: '18px', marginTop: '0em'}}>

<div style={{ position: 'relative' }}>

     <div id='profileImage' style={{ width: '130px', height: '130px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '60px' }}>
     <Image id='profileImage-image' src='/departmentOfHomeland.png' />
   </div>


   <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
   <div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Department of Homeland Security </h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '16px' }}>
<span> The Department of Homeland Security (DHS) protects the American people from a wide range of foreign and domestic threats. DHS has a broad and diverse mission set, including to prevent and disrupt terrorist attacks, protect critical infrastructure and civilian computer networks, facilitate lawful trade and travel, respond to and recover from natural disasters, protect our borders, and regulate the migration of individuals to and from our country.  </span>
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

       <span><Link style={{color: '#21cff5'}} onClick={(e) => {
          e.preventDefault();
          window.open("https://www.dhs.gov/", "_blank");
        }}>
          https://www.dhs.gov/
          </Link></span> 
<br></br>
<Button id='whDocsViewMoreButton' onClick={() =>  setMissionModal(true)}>  <Files size={20}></Files> Read More</Button>
</div>
  </div>

     </div>
     </div>


     </div>

           </div>

           <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showMissionModal} onHide={() => setMissionModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> Department of Homeland Security</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>The Department of Homeland Security (DHS) protects the American people from a wide range of foreign and domestic threats. DHS has a broad and diverse mission set, including to prevent and disrupt terrorist attacks, protect critical infrastructure and civilian computer networks, facilitate lawful trade and travel, respond to and recover from natural disasters, protect our borders, and regulate the migration of individuals to and from our country.</p>

<p>The third largest Cabinet department, DHS employs more than 250,000 people and deploys an $58 billion annual budget across more than 20 components, including the U.S. Secret Service, Transportation Security Administration, Federal Emergency Management Agency, U.S. Coast Guard, U.S. Customs and Border Protection, U.S. Immigration and Customs Enforcement, U.S. Citizenship and Immigration Services, and the Cybersecurity and Infrastructure Security Agency. The Homeland Security Act of 2002 established the Department in response to the terrorist attacks of September 11, 2001 and brought together 22 executive branch agencies.</p>

<p>The Assistant to the President for Homeland Security and the Secretary of Homeland Security coordinate policy, including through the Homeland Security Council at the White House and in cooperation with other defense and intelligence agencies.</p>

<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=and%20Medicaid%20Services.-,DEPARTMENT%20OF%20HOMELAND%20SECURITY,-The%20Department%20of", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=and%20Medicaid%20Services.-,DEPARTMENT%20OF%20HOMELAND%20SECURITY,-The%20Department%20of
     </Link>
 </div>

       </Modal>
           <br></br> <br></br> <br></br>
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>

                          <h4 id='whBreifingFeedHeading'><b>Documents</b></h4>
                          <div id='homelandDocs'></div>
                
                {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=homeland-security-department', 'homelandDocs', 'Homeland Security Documents', 'Executive', 'N/A', HomelandSecurityDocuments)}

                  
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnRightPadding'>
                          <h4 id='presidentialFeedHeading'><b>Coast Guard Documents</b></h4>
                          <div id='coastGuard'></div>
                    
                    {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=coast-guard', 'coastGuard', 'Coast Guard', 'Executive', 'N/A', CoastGuardDocuments)}

     
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>

                <div id='branchPage'>
        <Container className='bgcolor'>
          <Container id='carouselBufferTwo'>
            <Row>
              <Col id='legislativeColRight'>
                <div id='columnRightPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/coastguard', "_self")
                  }}>
                    <b>Coast Guard</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defCoastGuard'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=Coast%20Guard', 'defCoastGuard',
                    'Coast Guard Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>Coast Guard</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=Coast%20Guard'
                      name='Coast Guard' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>

              <Col id='legislativeColLeft'>
                <div id='columnLeftPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/fema', "_self")
                  }}>
                    <b>Federal Emergency Management Agency</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defFema'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=Federal%20Emergency%20Management%20Agency', 'defFema',
                    'Federal Emergency Management Agency Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>Federal Emergency Management Agency</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=Federal%20Emergency%20Management%20Agency'
                      name='Federal Emergency Management Agency' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <div id='branchPage'>
        <Container className='bgcolor'>
          <Container id='carouselBufferTwo'>
            <Row>
              <Col id='legislativeColRight'>
                <div id='columnRightPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/fletc', "_self")
                  }}>
                    <b>Federal Law Enforcement Training Center</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defFletc'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=Federal%20Law%20Enforcement%20Training%20Center', 'defFletc',
                    'Federal Law Enforcement Training Center Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>Federal Law Enforcement Training Center</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=Federal%20Law%20Enforcement%20Training%20Center'
                      name='Federal Law Enforcement Training Center' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>

              <Col id='legislativeColLeft'>
                <div id='columnLeftPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/national-communications', "_self")
                  }}>
                    <b>National Communications System</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defNationalCommunications'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=National%20Communications%20System', 'defNationalCommunications',
                    'National Communications System Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>National Communications System</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=National%20Communications%20System'
                      name='National Communications System' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <div id='branchPage'>
        <Container className='bgcolor'>
          <Container id='carouselBufferTwo'>
            <Row>
              <Col id='legislativeColRight'>
                <div id='columnRightPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/secret-service', "_self")
                  }}>
                    <b>Secret Service</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defSecretService'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=Secret%20Service', 'defSecretService',
                    'Secret Service Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>Secret Service</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=Secret%20Service'
                      name='Secret Service' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>

              <Col id='legislativeColLeft'>
                <div id='columnLeftPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/tsa', "_self")
                  }}>
                    <b>Transportation Security Administration</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defTsa'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=Transportation%20Security%20Administration', 'defTsa',
                    'Transportation Security Administration Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>Transportation Security Administration</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=Transportation%20Security%20Administration'
                      name='Transportation Security Administration' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <div id='branchPage'>
        <Container className='bgcolor'>
          <Container id='carouselBufferTwo'>
            <Row>
              <Col id='legislativeColRight'>
                <div id='columnRightPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/uscis', "_self")
                  }}>
                    <b>U.S. Citizenship and Immigration Services</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defUscis'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=U.S.%20Citizenship%20and%20Immigration%20Services', 'defUscis',
                    'U.S. Citizenship and Immigration Services Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>U.S. Citizenship and Immigration Services</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=U.S.%20Citizenship%20and%20Immigration%20Services'
                      name='U.S. Citizenship and Immigration Services' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>

    

              <Col id='legislativeColLeft'>
                <div id='columnLeftPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/cbp', "_self")
                  }}>
                    <b>U.S. Customs and Border Protection</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defCbp'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=U.S.%20Customs%20and%20Border%20Protection', 'defCbp',
                    'U.S. Customs and Border Protection Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>U.S. Customs and Border Protection</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=U.S.%20Customs%20and%20Border%20Protection'
                      name='U.S. Customs and Border Protection' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <div id='branchPage'>
        <Container className='bgcolor'>
          <Container id='carouselBufferTwo'>
            <Row>
            <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                        <h4 id='departmentOfDefenseHeadingInCol' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/ice', "_self")
                  }}><b>U.S. Immigration and Customs Enforcement</b><ChevronRight id='clickChevron'></ChevronRight></h4>


                        <Carousel id='carouselInColumn' controls={false}  >





<Carousel.Item interval={carouselInterval}>

<h4 id='departmentOfJusticeHeading'><b> News</b></h4>

<div id='iceNews'></div>
                {CreateFeedAPI('&schema=united_states_of_america&table=immigration&order_by=created_at&filter_column=agency&filter_string=U.S.%20Immigration%20and%20Customs%20Enforcement', 'iceNews', 'ICE News', 'Executive', 'Immigration', 'Doc', GenerateFeed)}



</Carousel.Item>

<Carousel.Item interval={carouselInterval}>

<h4 id='departmentOfJusticeHeading'><b>Documents</b></h4>

<div id='defIce'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=U.S.%20Immigration%20and%20Customs%20Enforcement', 'defIce',
                    'U.S. Immigration and Customs Enforcement Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>U.S. Immigration and Customs Enforcement</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=U.S.%20Immigration%20and%20Customs%20Enforcement'
                      name='U.S. Immigration and Customs Enforcement' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>




</Carousel.Item>





</Carousel>  
                    </div>
                  </Col>
            </Row>
          </Container>
        </Container>
      </div>

       
             

           

      



            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
   <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>



</div>
         </div>
          

              </BrowserView>

              <MobileView>

    <HomelandSecurityMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default HomelandSecurity;