import React, { useState } from 'react';
import { Image, Navbar, Dropdown, Button, Col, Modal, Card } from 'react-bootstrap';
import { Link45deg, HeartFill } from 'react-bootstrap-icons';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import { CreateFeedAPIDepartmentUS, CreateFeedAPI } from './Functions';
import GenerateFeedDepartment from './GenerateFeedDepartment';
import DesktopNavbar from './DesktopNavbar';
import { BrowserView, MobileView } from 'react-device-detect';
import { Link } from 'react-router-dom';
import GenerateFeed from './GenerateFeed';

const AgencyTemplate = ({
  imageName,
  agencyName,
  officialURL,
  missionStatement,
  rssURL,
  feedName,
  topic,
  rssURL2, // Second RSS URL for CreateFeedAPI
  feedName2, // Second feed name for the CreateFeedAPI
  departmentType, // New parameter for department type in CreateFeedAPI
  executiveBranch, // New parameter for executive branch in CreateFeedAPI
}) => {
  const [armyModalIsOpen, setArmyModalIsOpen] = useState(false);
  const [secondFeedModalIsOpen, setSecondFeedModalIsOpen] = useState(false); // Modal for the second feed

  return (
    <>
      <MobileView>
        <MobileTopNavbar />
        <div id='homebg'>
          <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div id="profileImageOnPageDepartment">
                  <Image id="profileImage-imageOnPageDepartment" src={imageName} />
                </div>
                <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold' }}>
                  <h5 id="branchHeadingMobile">{agencyName}</h5>
                </div>
              </div>
              <Dropdown drop="down-centered">
                <Dropdown.Toggle id="searchCountry"><Link45deg /></Dropdown.Toggle>
                <Dropdown.Menu id="docsDropdown" drop="down-centered">
                  <Dropdown.Item id="docsDropdownItem" onClick={(e) => { e.preventDefault(); window.open(officialURL, "_blank") }}>
                    Official Site
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
              Mission Statement: {missionStatement}
            </div>
          </div>

          <Col>
            <h5 id='presidentialFeedHeadingMobile' onClick={() => setArmyModalIsOpen(true)}>{feedName}</h5>
            <div className='courtNewsMobile' id='agency'></div>
            {CreateFeedAPIDepartmentUS(rssURL, 'agency', feedName, executiveBranch, topic, 'Doc', GenerateFeedDepartment)}

            {/* Conditionally render the second feed if parameters are available */}
            {rssURL2 && feedName2 && departmentType ? (
              <>
                <h5 id='presidentialFeedHeadingMobile' onClick={() => setSecondFeedModalIsOpen(true)}>{feedName2}</h5> {/* Second feed heading */}
                <div className='courtNewsMobile' id='agency2'></div>
                {CreateFeedAPI(rssURL2, 'agency2', feedName2, departmentType, topic, 'Doc', GenerateFeedDepartment)} {/* Call the second function */}
              </>
            ) : null}
          </Col>

          <Modal id='whiteHouseDocsModal' show={armyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmyModalIsOpen(false)}>
            <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
            <GenerateFeedDepartment url={rssURL} name={feedName} branch='Executive' topic={topic} buttonName='Doc' />
          </Modal>

          <Modal id='secondFeedModal' show={secondFeedModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSecondFeedModalIsOpen(false)}>
            <Modal.Header id='secondFeedModalHeader' closeButton><h3>{feedName2}</h3></Modal.Header>
            <GenerateFeedDepartment url={rssURL2} name={feedName2} branch='Executive' topic={topic} buttonName='Doc' />
          </Modal>

          {/* <Col>
            <h5 id='headingSelectHomeCountry'>
              Support the Gov Glance Foundation
              <Button id='donateButton' onClick={(e) => {
                e.preventDefault();
                window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288')
              }}>
                <HeartFill id='donateIcon' /> Donate
              </Button>
            </h5>
          </Col> */}

          <Navbar id='navbarMobileBottom' fixed="bottom">
            <MobileOffCanvas />
          </Navbar>
        </div>
      </MobileView>

      <BrowserView>
        <div id='homebg'>
          <DesktopNavbar />
          <br />
          <div id='feedDisplay' style={{ padding: '20px', borderRadius: '18px', marginTop: '0em' }}>
            <div style={{ position: 'relative' }}>
              {imageName && (
                <div id='profileImage' style={{ width: '130px', height: '130px', borderRadius: '100%', overflow: 'hidden', marginLeft: '60px' }}>
                  <Image id='profileImage-image' src={imageName} />
                </div>
              )}
              <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
                <div style={{ color: 'white', fontSize: '20px' }}>
                  <span><h3>{feedName}</h3></span>
                  <br />
                  <div style={{ color: 'white', fontSize: '16px' }}>
                    <span>{missionStatement}</span>
                    <br />
                    <br />
                    <div style={{ color: 'white', fontSize: '18px' }}>
                      {officialURL && (
                        <Link style={{ color: '#21cff5' }} onClick={(e) => {
                          e.preventDefault();
                          window.open(officialURL, "_blank");
                        }}>
                          {officialURL}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br /><br />

            {rssURL2 && feedName2 && departmentType ? (
              <>
                <Card.Body id='cardBodyRep'><h3>News</h3></Card.Body>
                <div id='agency2'></div>
                {CreateFeedAPI(rssURL2, 'agency2', feedName2, departmentType, topic, 'Doc', GenerateFeed)} {/* Call the second function */}
              </>
            ) : null}
            <Card.Body id='cardBodyRep'><h3>Documents</h3></Card.Body>
            <div id='agency'></div>
            {CreateFeedAPIDepartmentUS(rssURL, 'agency', feedName, executiveBranch, topic, 'Doc', GenerateFeedDepartment)}

            {/* Conditionally render the second feed if parameters are available */}
 

            <Modal id='whiteHouseDocsModal' show={armyModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setArmyModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>{feedName}</h3></Modal.Header>
              <GenerateFeedDepartment url={rssURL} name={feedName} branch='Executive' topic={topic} buttonName='Doc' />
            </Modal>

            <Modal id='secondFeedModal' show={secondFeedModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setSecondFeedModalIsOpen(false)}>
              <Modal.Header id='secondFeedModalHeader' closeButton><h3>{feedName2}</h3></Modal.Header>
              <GenerateFeedDepartment url={rssURL2} name={feedName2} branch='Executive' topic={topic} buttonName='Doc' />
            </Modal>
          </div>
        </div>
      </BrowserView>
    </>
  );
};

export default AgencyTemplate;
