
import React, { useState } from 'react';
import DesktopNavbar from '../DesktopNavbar';
import './Learn.css';

const quizData = {
  quiz: [
    {
      question: "What is the primary function of county governments in the United States?",
      options: [
        "A) Enforce federal laws exclusively",
        "B) Provide local services and administration between state and municipal governments",
        "C) Conduct international diplomacy",
        "D) Oversee national defense initiatives"
      ],
      correct_answer: "B"
    },
    {
      question: "Which of the following is a common form of county government structure?",
      options: [
        "A) Parliamentary system",
        "B) Commission system",
        "C) Unitary executive system",
        "D) Federal council system"
      ],
      correct_answer: "B"
    },
    {
      question: "In which state are county-equivalent entities called 'parishes'?",
      options: [
        "A) Alaska",
        "B) Louisiana",
        "C) Hawaii",
        "D) Virginia"
      ],
      correct_answer: "B"
    },
    {
      question: "What is the most common source of revenue for county governments?",
      options: [
        "A) Income taxes",
        "B) Property taxes",
        "C) Sales of natural resources",
        "D) Tariffs on imports"
      ],
      correct_answer: "B"
    },
    {
      question: "What is 'home rule' in the context of county governments?",
      options: [
        "A) The authority granted by the state to counties to govern themselves with minimal state interference",
        "B) A federal mandate that all counties must follow the same laws",
        "C) A rule that allows counties to secede from their state",
        "D) The requirement that counties must be governed directly by the state legislature"
      ],
      correct_answer: "A"
    },
    {
      question: "Who typically oversees law enforcement at the county level?",
      options: [
        "A) The county sheriff",
        "B) The state police",
        "C) The federal marshals",
        "D) The city mayor"
      ],
      correct_answer: "A"
    },
    {
      question: "What is Dillon's Rule?",
      options: [
        "A) A principle that grants counties complete autonomy from state governments",
        "B) A legal doctrine stating that counties have only the powers expressly granted to them by the state",
        "C) A federal law mandating uniform county government structures",
        "D) A rule that allows counties to override state laws"
      ],
      correct_answer: "B"
    },
    {
      question: "What is an independent city?",
      options: [
        "A) A city that is not part of any county and operates as its own county-equivalent",
        "B) A city located in multiple counties simultaneously",
        "C) A city that has declared independence from the United States",
        "D) A city that exists only within the boundaries of a national park"
      ],
      correct_answer: "A"
    },
    {
      question: "Which of the following is NOT typically a responsibility of county governments?",
      options: [
        "A) Maintaining county roads and bridges",
        "B) Administering local elections",
        "C) Issuing marriage licenses",
        "D) Negotiating international treaties"
      ],
      correct_answer: "D"
    },
    {
      question: "What is the role of a county assessor?",
      options: [
        "A) Enforces laws at the county level",
        "B) Determines property values for taxation purposes",
        "C) Manages the county's public health services",
        "D) Oversees the county's transportation systems"
      ],
      correct_answer: "B"
    }
  ],
  flashcards: [
    {
      question: "What is the main purpose of county governments in the United States?",
      answer: "To provide local services and administrative functions between the state and municipal governments."
    },
    {
      question: "What are the three common forms of county government structures?",
      answer: "Commission system, council-executive system, and commission-administrator system."
    },
    {
      question: "What is a commission system in county government?",
      answer: "A form of government where elected commissioners serve as both the legislative and executive authority."
    },
    {
      question: "Define 'home rule' in the context of county government.",
      answer: "The authority granted by a state to a county to govern itself and make decisions on local matters with minimal state interference."
    },
    {
      question: "What is Dillon's Rule?",
      answer: "A legal principle stating that counties have only the powers expressly granted to them by the state government."
    },
    {
      question: "Which state refers to its county-equivalent entities as 'parishes'?",
      answer: "Louisiana."
    },
    {
      question: "What is the role of a county sheriff?",
      answer: "To oversee law enforcement and maintain public safety at the county level, often including managing the county jail."
    },
    {
      question: "What are 'unfunded mandates' in the context of county governments?",
      answer: "State or federal requirements imposed on counties without providing accompanying funding."
    },
    {
      question: "What is an independent city?",
      answer: "A city that is not part of any county and operates as its own county-equivalent government."
    },
    {
      question: "Name a common responsibility of county governments related to infrastructure.",
      answer: "Maintaining and repairing county roads and bridges."
    },
    {
      question: "What is the primary source of revenue for most county governments?",
      answer: "Property taxes."
    },
    {
      question: "Explain the council-executive system in county government.",
      answer: "A government structure with an elected county executive who handles administrative functions and an elected council that serves as the legislative body."
    },
    {
      question: "What is a county assessor responsible for?",
      answer: "Determining property values within the county for taxation purposes."
    },
    {
      question: "How are county officials typically selected?",
      answer: "Through local elections."
    },
    {
      question: "What are 'county equivalents'?",
      answer: "Entities that serve similar functions as counties but have different names or structures, such as parishes in Louisiana or boroughs in Alaska."
    },
    {
      question: "What role do counties play in public health?",
      answer: "They provide services such as immunizations, disease control, and health education through county health departments."
    },
    {
      question: "Define the commission-administrator system in county government.",
      answer: "A structure where elected commissioners appoint a professional administrator to handle executive functions."
    },
    {
      question: "What is the function of a county clerk?",
      answer: "Managing public records, such as property deeds, marriage licenses, and other official documents."
    },
    {
      question: "What is 'home rule' authority?",
      answer: "The power granted to counties by the state to manage their own affairs without direct state control, within certain legal limits."
    },
    {
      question: "What is the significance of property taxes for counties?",
      answer: "They are the primary source of revenue for funding county services and operations."
    },
    {
      term: "County Commission",
      definition: "A group of elected officials who serve as the legislative and executive authority in a county."
    },
    {
      term: "County Executive",
      definition: "An elected or appointed official who serves as the chief administrative officer of a county."
    },
    {
      term: "Dillon's Rule",
      definition: "A legal doctrine stating that local governments have only the powers expressly granted to them by the state."
    },
    {
      term: "Home Rule",
      definition: "The authority granted to local governments to self-govern and manage their own affairs with limited state interference."
    },
    {
      term: "Unfunded Mandate",
      definition: "A requirement imposed by a higher level of government without providing funds to fulfill it."
    }
  ]
};

const LearnCountyGovernment = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showQuizResult, setShowQuizResult] = useState(false);
  const [score, setScore] = useState(0);
  const [showFlashcardAnswer, setShowFlashcardAnswer] = useState(false);
  const [currentFlashcard, setCurrentFlashcard] = useState(0);

  const handleAnswerOptionClick = (selectedOption) => {
    const correctOption = quizData.quiz[currentQuestion].correct_answer;
    if (selectedOption.charAt(0) === correctOption) {
      setScore(score + 1);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizData.quiz.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowQuizResult(true);
    }
  };

  const handleFlashcardFlip = () => {
    setShowFlashcardAnswer(!showFlashcardAnswer);
  };

  const handleNextFlashcard = () => {
    setShowFlashcardAnswer(false);
    const nextFlashcard = currentFlashcard + 1;
    if (nextFlashcard < quizData.flashcards.length) {
      setCurrentFlashcard(nextFlashcard);
    } else {
      setCurrentFlashcard(0);
    }
  };

  return (
    <div id='homebg'>
      <DesktopNavbar/>
    <div className="report-container">
      <h1 className="title">
       County Government in the United States
      </h1>

      {/* Introduction */}
      <h2 className="section-title">Introduction</h2>
      <p>
        County governments play a vital role in the administrative structure of the United States,
        serving as a bridge between state governments and local municipalities. They are responsible
        for a wide range of services that directly affect the daily lives of citizens, including law
        enforcement, public health, infrastructure maintenance, and social services. This report
        provides a comprehensive analysis of county governments, exploring their historical
        development, structures, functions, powers, and the challenges they face within the context
        of American governance.
      </p>

      {/* Historical Development of County Governments */}
      <h2 className="section-title">Historical Development of County Governments</h2>
      
      <h3>Origins</h3>
      <ul>
        <li>
          <strong>English Roots:</strong> The concept of counties in the United States is derived from
          the English shire system, where local governance was managed by appointed officials.
        </li>
        <li>
          <strong>Colonial Era:</strong> Counties were established in the American colonies to administer
          justice and manage land records.
        </li>
      </ul>

      <h3>Evolution</h3>
      <ul>
        <li>
          <strong>Westward Expansion:</strong> As the nation expanded westward, new counties were formed
          to provide governmental services in growing communities.
        </li>
        <li>
          <strong>Diversity of Structure:</strong> Different states adapted the county system to meet local
          needs, resulting in variations in county governance across the country.
        </li>
      </ul>

      {/* Overview of County Government Structure */}
      <h2 className="section-title">Overview of County Government Structure</h2>

      <h3>Number and Distribution</h3>
      <ul>
        <li>
          <strong>Total Counties:</strong> There are over 3,000 counties and county-equivalent entities in
          the United States.
        </li>
        <li>
          <strong>Variations:</strong> Some states have a large number of small counties (e.g., Texas),
          while others have fewer, larger counties (e.g., Delaware).
        </li>
      </ul>

      <h3>County Equivalents</h3>
      <ul>
        <li>
          <strong>Parishes:</strong> Louisiana uses the term “parishes” instead of counties.
        </li>
        <li>
          <strong>Boroughs:</strong> Alaska refers to its county-equivalent entities as boroughs.
        </li>
        <li>
          <strong>Independent Cities:</strong> Certain cities, such as Baltimore and St. Louis, are
          independent of any county governance.
        </li>
      </ul>

      <h3>Governance Structures</h3>
      <p>
        County governments vary significantly in their structures, influenced by state laws and local
        preferences.
      </p>

      <h4>Common Forms of County Government</h4>
      <ul>
        <li>
          <strong>1. Commission System</strong>
          <ul>
            <li>
              <strong>Structure:</strong> A board of commissioners serves as both the legislative and
              executive authority.
            </li>
            <li>
              <strong>Responsibilities:</strong> Commissioners pass ordinances, approve budgets, and
              oversee county departments.
            </li>
          </ul>
        </li>
        <li>
          <strong>2. Council-Executive System</strong>
          <ul>
            <li>
              <strong>Structure:</strong> A separately elected county executive and an elected council.
            </li>
            <li>
              <strong>Roles:</strong>
              <ul>
                <li>
                  <strong>County Executive:</strong> Serves as the chief administrator, implementing
                  policies and managing daily operations.
                </li>
                <li>
                  <strong>County Council:</strong> Acts as the legislative body, enacting laws and
                  budgets.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <strong>3. Commission-Administrator System</strong>
          <ul>
            <li>
              <strong>Structure:</strong> An elected commission appoints a professional administrator.
            </li>
            <li>
              <strong>Roles:</strong>
              <ul>
                <li>
                  <strong>Commissioners:</strong> Focus on legislative functions.
                </li>
                <li>
                  <strong>Administrator:</strong> Manages executive functions under the commission’s
                  direction.
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <h3>Elected Officials</h3>
      <p>
        In addition to commissioners or council members, counties often have other elected officials,
        including:
      </p>
      <ul>
        <li>
          <strong>Sheriff:</strong> Oversees law enforcement and jail operations.
        </li>
        <li>
          <strong>Clerk:</strong> Manages public records, such as property deeds and marriage licenses.
        </li>
        <li>
          <strong>Assessor:</strong> Determines property values for taxation.
        </li>
        <li>
          <strong>Treasurer/Tax Collector:</strong> Handles county finances and tax collection.
        </li>
        <li>
          <strong>District Attorney/Prosecutor:</strong> Prosecutes criminal cases on behalf of the county.
        </li>
      </ul>

      {/* Powers and Responsibilities of County Governments */}
      <h2 className="section-title">Powers and Responsibilities of County Governments</h2>
      <p>
        Counties are responsible for providing essential services that are crucial for community
        well-being.
      </p>

      <h3>Law Enforcement and Public Safety</h3>
      <ul>
        <li>
          <strong>Sheriff’s Department:</strong> Provides policing in unincorporated areas and
          operates county jails.
        </li>
        <li>
          <strong>Emergency Services:</strong> Manages disaster response, emergency medical
          services, and fire protection in some regions.
        </li>
      </ul>

      <h3>Judicial Administration</h3>
      <ul>
        <li>
          <strong>Courts:</strong> Operate local court systems, including probate, family, and small
          claims courts.
        </li>
        <li>
          <strong>Corrections:</strong> Manage detention facilities and rehabilitation programs.
        </li>
      </ul>

      <h3>Public Health and Social Services</h3>
      <ul>
        <li>
          <strong>Health Departments:</strong> Offer services like immunizations, disease control, and
          health education.
        </li>
        <li>
          <strong>Human Services:</strong> Administer welfare programs, mental health services, and
          support for the elderly and disabled.
        </li>
      </ul>

      <h3>Infrastructure and Public Works</h3>
      <ul>
        <li>
          <strong>Road Maintenance:</strong> Maintain and repair county roads and bridges.
        </li>
        <li>
          <strong>Waste Management:</strong> Oversee garbage collection, recycling programs, and
          landfill operations.
        </li>
        <li>
          <strong>Water and Sewer Services:</strong> Provide utilities in unincorporated areas.
        </li>
      </ul>

      <h3>Land Use and Environmental Management</h3>
      <ul>
        <li>
          <strong>Zoning and Planning:</strong> Regulate land use, building codes, and development
          plans.
        </li>
        <li>
          <strong>Environmental Protection:</strong> Enforce regulations to preserve natural resources
          and manage parks and recreation areas.
        </li>
      </ul>

      <h3>Economic Development</h3>
      <ul>
        <li>
          <strong>Business Support:</strong> Promote local economic growth through incentives and
          infrastructure improvements.
        </li>
        <li>
          <strong>Tourism:</strong> Develop attractions and events to boost local economies.
        </li>
      </ul>

      <h3>Elections and Records</h3>
      <ul>
        <li>
          <strong>Voter Registration:</strong> Maintain voter rolls and administer elections.
        </li>
        <li>
          <strong>Public Records:</strong> Keep official documents like property records, marriage
          licenses, and court records.
        </li>
      </ul>

      {/* Fiscal Aspects of County Government */}
      <h2 className="section-title">Fiscal Aspects of County Government</h2>

      <h3>Revenue Sources</h3>
      <ul>
        <li>
          <strong>Property Taxes:</strong> The primary source of income, based on assessed property
          values.
        </li>
        <li>
          <strong>Sales Taxes:</strong> Some counties impose local sales taxes.
        </li>
        <li>
          <strong>Fees and Charges:</strong> Collected for services like licensing, permits, and
          recreational facilities.
        </li>
        <li>
          <strong>Intergovernmental Transfers:</strong> Funds received from state and federal
          governments, often for specific programs.
        </li>
        <li>
          <strong>Bonds and Loans:</strong> Issued for capital projects like building infrastructure.
        </li>
      </ul>

      <h3>Budgeting Process</h3>
      <ul>
        <li>
          <strong>Proposal:</strong> County departments submit budget requests.
        </li>
        <li>
          <strong>Review:</strong> The county executive or administrator compiles a proposed budget.
        </li>
        <li>
          <strong>Approval:</strong> The county commission or council reviews, amends, and adopts the
          budget.
        </li>
        <li>
          <strong>Monitoring:</strong> Ongoing oversight ensures funds are used appropriately.
        </li>
      </ul>

      {/* Relationship with State and Federal Governments */}
      <h2 className="section-title">Relationship with State and Federal Governments</h2>

      <h3>State Oversight</h3>
      <ul>
        <li>
          <strong>Authority:</strong> Counties are creations of the state and have only the powers
          granted by state law.
        </li>
        <li>
          <strong>Regulation:</strong> States may impose mandates on counties, sometimes without
          providing funding (unfunded mandates).
        </li>
        <li>
          <strong>Home Rule:</strong> Some states grant counties home rule authority, allowing more
          self-governance.
        </li>
      </ul>

      <h3>Federal Interaction</h3>
      <ul>
        <li>
          <strong>Grants and Funding:</strong> Counties receive federal funds for programs like Medicaid,
          infrastructure, and social services.
        </li>
        <li>
          <strong>Compliance:</strong> Must adhere to federal regulations in areas such as environmental
          protection and civil rights.
        </li>
      </ul>

      {/* Challenges Facing County Governments */}
      <h2 className="section-title">Challenges Facing County Governments</h2>

      <h3>Financial Constraints</h3>
      <ul>
        <li>
          <strong>Revenue Limitations:</strong> Dependence on property taxes can be problematic during
          economic downturns.
        </li>
        <li>
          <strong>Unfunded Mandates:</strong> State or federal requirements without accompanying
          funding strain budgets.
        </li>
        <li>
          <strong>Infrastructure Needs:</strong> Aging roads, bridges, and facilities require
          significant investment.
        </li>
      </ul>

      <h3>Service Demands</h3>
      <ul>
        <li>
          <strong>Population Changes:</strong> Growth in suburban and rural areas increases demand for
          services.
        </li>
        <li>
          <strong>Public Health Crises:</strong> Pandemics and natural disasters require rapid, resource-
          intensive responses.
        </li>
      </ul>

      <h3>Technological Advancements</h3>
      <ul>
        <li>
          <strong>Digital Infrastructure:</strong> Need for improved technology to enhance service
          delivery and cybersecurity.
        </li>
        <li>
          <strong>Data Management:</strong> Handling large volumes of public records securely and
          efficiently.
        </li>
      </ul>

      <h3>Intergovernmental Relations</h3>
      <ul>
        <li>
          <strong>Coordination with Municipalities:</strong> Overlapping services with cities and towns
          can lead to inefficiencies.
        </li>
        <li>
          <strong>State Preemption:</strong> State laws may limit county authority, affecting local
          decision-making.
        </li>
      </ul>

      <h3>Workforce Challenges</h3>
      <ul>
        <li>
          <strong>Recruitment and Retention:</strong> Competing with private sector salaries for skilled
          workers.
        </li>
        <li>
          <strong>Training and Development:</strong> Keeping staff updated on best practices and
          regulatory changes.
        </li>
      </ul>

      {/* Innovations and Best Practices */}
      <h2 className="section-title">Innovations and Best Practices</h2>

      <h3>Regional Cooperation</h3>
      <ul>
        <li>
          <strong>Shared Services:</strong> Collaborating with neighboring counties or municipalities to
          reduce costs.
        </li>
        <li>
          <strong>Regional Planning Commissions:</strong> Joint efforts on transportation, land use, and
          economic development.
        </li>
      </ul>

      <h3>Citizen Engagement</h3>
      <ul>
        <li>
          <strong>Transparency Initiatives:</strong> Open data portals and accessible meetings enhance
          public trust.
        </li>
        <li>
          <strong>Participatory Budgeting:</strong> Involving residents in budget decisions to reflect
          community priorities.
        </li>
      </ul>

      <h3>Technological Solutions</h3>
      <ul>
        <li>
          <strong>E-Government Services:</strong> Online platforms for tax payments, license renewals, and
          service requests.
        </li>
        <li>
          <strong>Geographic Information Systems (GIS):</strong> Mapping technology for planning and
          emergency response.
        </li>
      </ul>

      <h3>Sustainable Practices</h3>
      <ul>
        <li>
          <strong>Environmental Programs:</strong> Initiatives for renewable energy, conservation, and
          waste reduction.
        </li>
        <li>
          <strong>Smart Growth:</strong> Policies promoting sustainable development and preserving open
          spaces.
        </li>
      </ul>

      {/* Case Studies of County Governments */}
      <h2 className="section-title">Case Studies of County Governments</h2>

      <h3>Los Angeles County, California</h3>
      <ul>
        <li>
          <strong>Overview:</strong> The most populous county in the U.S., with over 10 million residents.
        </li>
        <li>
          <strong>Structure:</strong> Governed by a five-member Board of Supervisors.
        </li>
        <li>
          <strong>Services:</strong> Provides extensive health services, operates one of the largest jail
          systems, and manages a vast public transportation network.
        </li>
      </ul>

      <h3>Fairfax County, Virginia</h3>
      <ul>
        <li>
          <strong>Overview:</strong> A suburban county near Washington, D.C., known for its high median
          income and educated workforce.
        </li>
        <li>
          <strong>Structure:</strong> Utilizes an urban county executive form of government.
        </li>
        <li>
          <strong>Initiatives:</strong> Focuses on economic development, technology integration, and
          education excellence.
        </li>
      </ul>

      <h3>Harris County, Texas</h3>
      <ul>
        <li>
          <strong>Overview:</strong> Includes the city of Houston and is the third most populous county in
          the U.S.
        </li>
        <li>
          <strong>Structure:</strong> Governed by a Commissioners Court, consisting of a County Judge and four
          Commissioners.
        </li>
        <li>
          <strong>Challenges:</strong> Addressing flooding issues, infrastructure demands, and public health
          concerns.
        </li>
      </ul>

      {/* Legal Framework and Authority */}
      <h2 className="section-title">Legal Framework and Authority</h2>

      <h3>Dillon’s Rule vs. Home Rule</h3>
      <ul>
        <li>
          <strong>Dillon’s Rule</strong>
          <ul>
            <li>
              <strong>Definition:</strong> Counties have only the powers expressly granted by the state
              legislature.
            </li>
            <li>
              <strong>Implication:</strong> Limits county autonomy; prevalent in many states.
            </li>
          </ul>
        </li>
        <li>
          <strong>Home Rule</strong>
          <ul>
            <li>
              <strong>Definition:</strong> Grants counties greater self-governance, allowing them to
              pass laws and ordinances not expressly forbidden by state law.
            </li>
            <li>
              <strong>Application:</strong> Varies by state; some allow home rule charters.
            </li>
          </ul>
        </li>
      </ul>

      <h3>Charter Counties</h3>
      <ul>
        <li>
          <strong>Definition:</strong> Counties that have adopted a charter to outline their government
          structure and powers.
        </li>
        <li>
          <strong>Purpose:</strong> Provides flexibility to tailor governance to local needs.
        </li>
      </ul>

      <h3>Role in Local Governance</h3>
      <h4>Unincorporated Areas</h4>
      <ul>
        <li>
          <strong>Definition:</strong> Regions not within the boundaries of a municipality.
        </li>
        <li>
          <strong>County Responsibility:</strong> Serve as the primary local government, providing all
          municipal services.
        </li>
      </ul>

      <h4>Coordination with Municipalities</h4>
      <ul>
        <li>
          <strong>Overlap of Services:</strong> In areas where cities exist, counties coordinate on
          issues like law enforcement and infrastructure.
        </li>
        <li>
          <strong>Interlocal Agreements:</strong> Formal arrangements to share services or resources.
        </li>
      </ul>

      {/* Public Participation and Accountability */}
      <h2 className="section-title">Public Participation and Accountability</h2>

      <h3>Elections</h3>
      <ul>
        <li>
          <strong>Local Officials:</strong> Residents elect commissioners, sheriffs, clerks, and other
          officials.
        </li>
        <li>
          <strong>Ballot Measures:</strong> Voters may approve budgets, bonds, and referendums affecting
          county policies.
        </li>
      </ul>

      <h3>Meetings and Transparency</h3>
      <ul>
        <li>
          <strong>Open Meetings Laws:</strong> Require county governing bodies to hold meetings
          accessible to the public.
        </li>
        <li>
          <strong>Public Records:</strong> Citizens have the right to access county documents under
          freedom of information laws.
        </li>
      </ul>

      {/* Impact of Counties on Daily Life */}
      <h2 className="section-title">Impact of Counties on Daily Life</h2>
      <p>
        Counties significantly influence various aspects of residents’ lives:
      </p>
      <ul>
        <li>
          <strong>Education:</strong> In some states, counties oversee public schools (e.g., Maryland,
          Virginia).
        </li>
        <li>
          <strong>Healthcare:</strong> Operate hospitals and clinics, provide mental health services.
        </li>
        <li>
          <strong>Elections:</strong> Administer voter registration and conduct elections.
        </li>
        <li>
          <strong>Cultural Services:</strong> Manage libraries, museums, and recreational facilities.
        </li>
      </ul>

      {/* Future Outlook */}
      <h2 className="section-title">Future Outlook</h2>

      <h3>Population Shifts</h3>
      <ul>
        <li>
          <strong>Urbanization:</strong> Migration to urban counties increases service demands.
        </li>
        <li>
          <strong>Rural Challenges:</strong> Declining populations strain resources and economic viability.
        </li>
      </ul>

      <h3>Technological Integration</h3>
      <ul>
        <li>
          <strong>Smart Counties:</strong> Adoption of Internet of Things (IoT) devices for efficient
          service delivery.
        </li>
        <li>
          <strong>Cybersecurity:</strong> Protecting county systems from cyber threats.
        </li>
      </ul>

      <h3>Sustainability Efforts</h3>
      <ul>
        <li>
          <strong>Climate Action Plans:</strong> Counties are developing strategies to address
          environmental concerns.
        </li>
        <li>
          <strong>Resilience Planning:</strong> Preparing for natural disasters and adapting
          infrastructure accordingly.
        </li>
      </ul>

      {/* Conclusion */}
      <h2 className="section-title">Conclusion</h2>
      <p>
        County governments are essential components of the United States’ governmental framework,
        providing crucial services that affect citizens’ quality of life. They operate within a complex
        environment shaped by historical precedents, state laws, and the needs of diverse communities.
        As counties face challenges such as financial constraints, technological advancements, and
        shifting demographics, they continue to innovate and adapt. Understanding the functions and
        importance of county governments enhances civic engagement and appreciation for the roles
        these entities play in governing at the local level.
      </p>

      {/* Further Reading and Resources */}
      <h2 className="section-title">Further Reading and Resources</h2>
      <ul>
        <li>
          <strong>National Association of Counties (NACo):</strong> Provides research, advocacy, and
          resources for county governments.
        </li>
        <li>
          <strong>U.S. Census Bureau:</strong> Offers data on county demographics, economics, and
          governance structures.
        </li>
        <li>
          <strong>State Government Websites:</strong> Information on state-specific county laws and
          structures.
        </li>
        <li>
          <strong>Local Government Handbooks:</strong> Published by states to outline county powers and
          responsibilities.
        </li>
        <li>
          <strong>Academic Journals:</strong> Publications like “State and Local Government Review”
          provide scholarly analyses.
        </li>
      </ul>

          {/* Flashcards Section */}
          <h2 className="section-title">Flashcards</h2>
      <div className="flashcard-section">
        <div className="flashcard" onClick={handleFlashcardFlip}>
          <div className="flashcard-content">
            {showFlashcardAnswer ? (
              <p>{quizData.flashcards[currentFlashcard].answer}</p>
            ) : (
              <p>{quizData.flashcards[currentFlashcard].question}</p>
            )}
          </div>
        </div>
        <button className="learnbtn" onClick={handleNextFlashcard}>
          Next Flashcard
        </button>
      </div>

      {/* Quiz Section */}
      <h2 className="section-title">Quiz</h2>
      <div className="quiz-section">
        {showQuizResult ? (
          <div className="quiz-result">
            <h3>Your Score: {score} out of {quizData.quiz.length}</h3>
            <button
              className="learnbtn"
              onClick={() => {
                setCurrentQuestion(0);
                setScore(0);
                setShowQuizResult(false);
              }}
            >
              Retake Quiz
            </button>
          </div>
        ) : (
          <div className="quiz-question">
            <h3>Question {currentQuestion + 1} of {quizData.quiz.length}</h3>
            <p>{quizData.quiz[currentQuestion].question}</p>
            <ul>
              {quizData.quiz[currentQuestion].options.map((option, index) => (
                <li key={index}>
                  <button
                    onClick={() => handleAnswerOptionClick(option)}
                    className="quiz-option"
                  >
                    {option}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

  
    </div>
    </div>
  );
};

export default LearnCountyGovernment;