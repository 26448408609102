import App from "./App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, FolderPlus } from 'react-bootstrap-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import AddNoteModal from "./AddNoteModal";


var url = 'https://www.atlantafed.org/rss/InflationProject'
var collectionCounter = localStorage.getItem('collectionNumber')

function saveToCollection(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
    
  
  
  collectionCounter = localStorage.getItem('collectionNumber')

collectionCounter++;


var collectionTitles = []
 var collectionDetailLinks = []
var collectionDocLinks = []
var collectionNote = []


  
  var title = 'title'.concat(collectionCounter)
  var detailLink = 'detailLink'.concat(collectionCounter)
  var docLink = 'docLink'.concat(collectionCounter)
  var itemNote = 'itemNote'.concat(collectionCounter)
  var itemDate = 'itemDate'.concat(collectionCounter)
  var feedName = 'feedName'.concat(collectionCounter)
  var branchName = 'branchName'.concat(collectionCounter)
  var topicName = 'topicName'.concat(collectionCounter)



  localStorage.setItem(title,saveCollectionTitle)
  
  localStorage.setItem(detailLink,saveCollectionDetailLink)
 
  localStorage.setItem(docLink,saveCollectionDocLink)
  localStorage.setItem(itemNote,saveCollectionNote)

  localStorage.setItem(itemDate,saveCollectionDate)
  localStorage.setItem(feedName,saveCollectionFeedName)
  localStorage.setItem(branchName,saveCollectionBranchName)
  localStorage.setItem(topicName,saveCollectionTopicName)

  localStorage.setItem('collectionNumber', collectionCounter)

return(
  render(
  <>
  <AddNoteModal></AddNoteModal>
  
  
  
  
                         </>
  )
  )

  

  

}

function InflationUpdates()
 {

  const [searchQuery, setSearchQuery] = useState('');

  const [completeBillFeed, setCompleteBillFeed] = React.useState([]);
  const [billTitle, setBillTitle] = React.useState([]);
  const [billLink, setBillLink] = React.useState([]);
  const [billDate, setBillDate] = React.useState([]);

  React.useEffect(async () => {

    let Parser = require('rss-parser');
    let parser = new Parser();

    const fBillFeed = [];
    const fBillTitle = [];
    const fBillLink = []
    const fBillDate = []


    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/'+ url);
    feed.items.forEach(item => {

      fBillFeed.push(item.title + ':' + item.link);
      fBillTitle.push(item.title);
      fBillLink.push(item.link);
      fBillDate.push(item.pubDate);


//implement view details link still

    });

    setCompleteBillFeed(fBillFeed);
    setBillTitle(fBillTitle);
    setBillLink(fBillLink);
    setBillDate(fBillDate);

  }, []);

  const renderData = () => {
    const filteredData = completeBillFeed.reduce((filtered, f, index) => {
      const title = billTitle[index] || ''; 
  
      if (title.toLowerCase().includes(searchQuery.toLowerCase())) {
        filtered.push({ title, index });
      }
  
      return filtered;
    }, []);
  
    return filteredData.map(({ title, index }) => {
      const link = billLink[index];
      const date = billDate[index]
      const description = ""


  
     

      return <div key={index} >
        <div id='fullBillFeed'>
          <Container id='feedContainer'>

            <Card.Title id='billText' onClick={(e) => {
                   e.preventDefault();
                   window.open(billLink[index], "_blank")
                 }}>{billTitle[index]}</Card.Title>
            <Card.Body id='billBodyText' >{date}</Card.Body>
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle> Details</Button>
            
           

            <Button id='addToCollectionButton' onClick={() => saveToCollection(billTitle[index], billLink[index], null, date, '', "House Committee on Intelligence", "Legislative", "Foreign Affairs" )




            }><FolderPlus></FolderPlus></Button>

          </Container>

      </div>
      </div>
    })
  }

  return(
    <>
         <div id='searchForm'>
                              <Form.Control
  id="searchInput"
  placeholder="Search..."
  type="text"
  value={searchQuery}
  onChange={(e) => setSearchQuery(e.target.value)}
  color='white'
/>
</div> 
      {renderData()}
    </>
  )



  
}





export default InflationUpdates;