import App from "./App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, useRef } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, Spinner } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, FolderPlus, PlayCircle, Search } from 'react-bootstrap-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import debounce from 'lodash.debounce';
import Fade from 'react-bootstrap/Fade';
require('dotenv').config();
const apiKey = process.env.REACT_APP_API_KEY;


var storedTitle = ''// title
var storedDetailsLink = ''// details link
var storedDocLink = ''// doc link *
var storedDate = ''// date
var storedNote = ''// note
var storedFeedName = ''// feed name
var storedBranchName = ''// branch name 
var storedTopicName = ''// topic name
var storedDescription = ''// description
var collectionCounter = localStorage.getItem('collectionNumber')



function SaveFeedItem(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, 
  saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription)
{

storedTitle = saveCollectionTitle;
storedDetailsLink = saveCollectionDetailLink;
storedDocLink = saveCollectionDocLink;
storedDate = saveCollectionDate;
storedNote = saveCollectionNote;
storedFeedName = saveCollectionFeedName;
storedBranchName = saveCollectionBranchName;
storedTopicName = saveCollectionTopicName;
storedDescription = saveCollectionDescription;
}


{/*
Expected Payload:

id
country
administrative_division_first_level
administrative_division_second_level
administrative_division_third_level
administrative_division_four_level
branch
topic
guid
category
creator
date
title
long_title
url — source
doc_urls — as a dictionary, link(s) to corresponding document(s)
description
encoded
collected_at
other_data
, branch, topic
*/}

function formatDate(inputDate) {
  const date = new Date(inputDate);
  
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
}

function FeedPage(apiRequest, name, branch, topic, buttonName, indexToShow) {
  
  const containerRef = useRef(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  var [feedLength, setFeedLength] = useState(20);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);


  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
    
    var searchItem = document.getElementById('noteModalFeedItem').value
  
       collectionCounter = localStorage.getItem('collectionNumber')
   
     collectionCounter++;

     
     var collectionTitles = []
      var collectionDetailLinks = []
     var collectionDocLinks = []
     var collectionNote = []

       var title = 'title'.concat(collectionCounter)
       var detailLink = 'detailLink'.concat(collectionCounter)
       var docLink = 'docLink'.concat(collectionCounter)
       var itemNote = 'itemNote'.concat(collectionCounter)
       var itemDate = 'itemDate'.concat(collectionCounter)
       var feedName = 'feedName'.concat(collectionCounter)
       var branchName = 'branchName'.concat(collectionCounter)
       var topicName = 'topicName'.concat(collectionCounter)
       var itemDescription = 'itemDescription'.concat(collectionCounter)
   
   
     
     
       localStorage.setItem(title,saveCollectionTitle)
       
       localStorage.setItem(detailLink,saveCollectionDetailLink)
      
       localStorage.setItem(docLink,saveCollectionDocLink)
       localStorage.setItem(itemNote,searchItem)
   
       localStorage.setItem(itemDate,saveCollectionDate)
       localStorage.setItem(feedName,saveCollectionFeedName)
       localStorage.setItem(branchName,saveCollectionBranchName)
       localStorage.setItem(topicName,saveCollectionTopicName)
   
       localStorage.setItem(itemDescription,saveCollectionDescription)
     
       localStorage.setItem('collectionNumber', collectionCounter)

  }

  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
  const [itemLinkedToModalIsOpen, setItemLinkedToModalIsOpen] = useState(true)
const [searchQuery, setSearchQuery] = useState('');
const [schema, setSchema] = useState('default_schema');
const [table, setTable] = useState('default_table');



 useEffect(() => {
    // Scroll to the container element when the component mounts
    if (apiRequest && apiRequest.indexToShow != null && containerRef.current) {
      const containerOffset = containerRef.current.offsetTop;
      window.scrollTo({ top: containerOffset, behavior: 'smooth' });
    }
  }, [apiRequest]);

  const requestOptions = {
    method: 'GET',
    headers: { 
      'Content-Type': 'application/json',
      "Authorization": "Bearer "+apiKey,
  },
  };
{/*https://api.govglance.org/posts/recent?limit=10&skip=0&schema=united_states_of_america&table=demo_table&order_by=created_at */}


const performSearch = () => {
  const schemaMatch = /schema=([^&]+)/.exec(apiRequest.url);
  const tableMatch = /table=([^&]+)/.exec(apiRequest.url);

  const extractedSchema = schemaMatch ? schemaMatch[1] : 'default_schema';
  const extractedTable = tableMatch ? tableMatch[1] : 'default_table';

  setSchema(extractedSchema);
  setTable(extractedTable);

  const searchUrl = `https://api.govglance.org/search/${extractedSchema}/${extractedTable}?limit=${feedLength}&skip=0&search=${searchTerm}`;


};

useEffect(() => {
  const url = searchPerformed
    ? `https://api.govglance.org/search/${schema}/${table}?limit=${feedLength}&skip=0&search=${searchTerm}`
    : `https://api.govglance.org/posts/recent?limit=${feedLength}${apiRequest.url}`;

    fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      setData(data);
      //console.log(data); // Log the data for debugging
      setLoading(false);
      setError(null); // Reset error state
    })
    .catch((error) => {
      setError(error);
      setLoading(false);
      console.error("Error fetching data:", error); // Log the error for debugging
    });
}, [feedLength, searchPerformed, searchTerm, apiRequest.url, schema, table]);



const handleSearchButtonClick = () => {
  if (searchTerm.trim() === '') {
    return;
  }

  setSearchPerformed(true); // Set searchPerformed to true
  performSearch(); // Trigger search directly without debounce
};


const handleSearchInputChange = (e) => {
  setSearchTerm(e.target.value);
  setSearchPerformed(false); // Set searchPerformed to false
  //console.log("Search performed:", searchPerformed); // Debugging statement
  if (e.key === 'Enter') {
    setSearchPerformed(true); // Set searchPerformed to true
    //console.log("Search performed:", searchPerformed); // Debugging statement
    handleSearchButtonClick(); // Trigger debounced search
  }
};

const handleKeyDown = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault(); // Prevent the default behavior of the Enter key
    setSearchPerformed(true); // Set searchPerformed to true
    handleSearchButtonClick(); // Trigger debounced search
  }
};




const renderData = () => {
  if (loading) {
    return <div id='fullBillFeed'><Spinner  animation="grow" variant="light" /></div>;
  }

  if (error) {
    return <div id='fullBillFeed'><p>Error fetching data. </p></div>;
  }

  const filteredData = data.reduce((filtered, item, index) => {
    const title = item.title || '';
    const statusTitle = item.status_title || ''; // Add this line to get the status_title

    if (statusTitle.toLowerCase().includes(searchQuery.toLowerCase())) {
      filtered.push({ title: statusTitle, index }); // Use statusTitle instead of title
    } else if (title.toLowerCase().includes(searchQuery.toLowerCase())) {
      filtered.push({ title, index });
    }

    return filtered;
  }, []);

  if (filteredData.length === 0) {
    return <div id='fullBillFeed'><p>No results found. </p></div>;
  }

  return filteredData.map(({ source, index }) => {
    const titleToDisplay = data[index].status_title || data[index].title;
    const link = data[index].url;
    const docLink = data[index].document_link;
    const audioLink = data[index].audio_url; 
    const date = formatDate(data[index].created_at);
    const pdfLink = data[index].download ? data[index].download.pdfLink : null;
    const title = data[index].title;
    const status = data[index].status_title
    const description = "";
    const feedName = apiRequest.name; 
    const feedBranch = apiRequest.branch; 
    const feedTopic = apiRequest.topic; 
    const docButtonName = apiRequest.buttonName; 
  
    const isLargeItem = index === apiRequest.indexToShow;
    console.log('Index:', index, 'Index to Show:', apiRequest.indexToShow, 'Is Large Item:', isLargeItem);
    return (
      <div id='fullBillFeed' key={index} >
        <div id='fullBillFeed'className={index === apiRequest.indexToShow ? "large-item" : ""}>
          <Container id='feedContainer' >
            <Card.Title id='billText' onClick={(e) => {
              e.preventDefault();
              window.open(link, "_blank");
            }}>{titleToDisplay}</Card.Title>
            <Card.Body id='billBodyText'>{date}</Card.Body>
            <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(link, "_blank");
            }}><InfoCircle></InfoCircle> Details</Button>
  
            {(docLink || pdfLink) && (
              <Button id='viewBillButton' onClick={(e) => {
                e.preventDefault();
                const linkToOpen = docLink || pdfLink;
                window.open(linkToOpen, "_blank");
              }}>
                <FileText></FileText> {docButtonName}
              </Button>
            )}
  
            <Button id='addToCollectionButton' onClick={() => {
              setCollectionModalIsOpen(true);
              SaveFeedItem(title, link, '', date, '', feedName, feedBranch, feedTopic, description);
            }}><FolderPlus></FolderPlus></Button>
          </Container>
        </div>
        {isLargeItem && (
          
            <Modal id='fullBillModal' show={itemLinkedToModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setItemLinkedToModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton>
              <h3>{titleToDisplay}</h3>
            </Modal.Header>
            <Modal.Body id='descriptionModalBody'>
              {description}
              {date}
              <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(link, "_blank");
            }}><InfoCircle></InfoCircle> Details</Button>
  
            {(docLink || pdfLink) && (
              <Button id='viewBillButton' onClick={(e) => {
                e.preventDefault();
                const linkToOpen = docLink || pdfLink;
                window.open(linkToOpen, "_blank");
              }}>
                <FileText></FileText> {docButtonName}
              </Button>
            )}
              <div id='fullBill'>
                <Form id='noteModalForm'>
                  <Form.Group id='noteModalForm'  className="mb-3">
                    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
                      type="text"
                      as={"textarea"}
                      rows={2}
                    />
                    <br></br>
                  </Form.Group>
                </Form>
                <Modal.Footer id='addNoteModalFooter'> 
                  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(titleToDisplay, link, '', date, feedName, feedBranch, feedTopic, description); setCollectionModalIsOpen(false) }}>
                    Save To Collection
                  </Button>
                </Modal.Footer> 
              </div>
            </Modal.Body>
          </Modal>
   
        )}
      </div>
    );
  });
}

  if (loading) {
    return  <div id='fullBillFeed'><Spinner  animation="grow" variant="light" /></div>;
  }

  

 



  return (
    <>
<Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
centered onHide={setCollectionModalIsOpen}>
                             
                              <Modal.Header id='billModalHeader' closeButton ><h3>{storedTitle}</h3></Modal.Header>

                              <Modal.Body id='descriptionModalBody'>
                                          {storedDescription}
                                          {storedDate}
                              <div id='fullBill'>
                         <Form id='noteModalForm'>
         <Form.Group id='noteModalForm'  className="mb-3" >
           <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
             type="text"
             as={"textarea"}
             rows={2}
             />
             <br></br>

         </Form.Group>
         </Form>

         <Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(storedTitle, storedDetailsLink, storedDocLink, 
  storedDate, storedFeedName, storedBranchName, storedTopicName, storedDescription) ;setCollectionModalIsOpen(false) }} >
    Save To Collection
  </Button>
  </Modal.Footer> 

           </div>
           </Modal.Body>
                              </Modal>
                              <div id='searchForm'>

                              <Form className="text-center" onSubmit={(e) => { e.preventDefault(); }}>
  <Form.Group className="d-flex justify-content-center align-items-center">
    <Form.Control
      variant="light"
      id="searchInput"
      placeholder="Search..."
      type="text"
      value={searchTerm}
      onChange={handleSearchInputChange}
      onKeyDown={handleKeyDown}
      color="white"
    />
    <Button id='ggButton' onClick={handleSearchButtonClick}>
      <Search /> 
    </Button>
  </Form.Group>
</Form>

</div>
      {renderData()}
      <div id='fullBillFeed'>
      <Button id='standardButton' onClick={() => {
               setFeedLength(feedLength+=20)
              }}>More</Button>
              </div>
             

    </>
  )
}




export default FeedPage;