import App from "../App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, FolderPlus } from 'react-bootstrap-icons'
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import AddNoteModal from "../AddNoteModal";


var storedTitle = ''// title
var storedDetailsLink = ''// details link
var storedDocLink = ''// doc link *
var storedDate = ''// date
var storedNote = ''// note
var storedFeedName = ''// feed name
var storedBranchName = ''// branch name 
var storedTopicName = ''// topic name
var storedDescription = ''// description
var collectionCounter = localStorage.getItem('collectionNumber')



function SaveFeedItem(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, 
  saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription)
{

storedTitle = saveCollectionTitle;
storedDetailsLink = saveCollectionDetailLink;
storedDocLink = saveCollectionDocLink;
storedDate = saveCollectionDate;
storedNote = saveCollectionNote;
storedFeedName = saveCollectionFeedName;
storedBranchName = saveCollectionBranchName;
storedTopicName = saveCollectionTopicName;
storedDescription = saveCollectionDescription;
}

function saveToCollection(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName ){
    
  
  
  collectionCounter = localStorage.getItem('collectionNumber')
  //console.log(collectionCounter)X$
collectionCounter++;
  //console.log(collectionCounter)

var collectionTitles = []
 var collectionDetailLinks = []
var collectionDocLinks = []
var collectionNote = []

//collectionTitles.push(saveCollectionTitle)
//collectionDetailLinks.push(saveCollectionDetailLink)
//collectionDocLinks.push(saveCollectionDocLink)



 
  

 
  
  var title = 'title'.concat(collectionCounter)
  var detailLink = 'detailLink'.concat(collectionCounter)
  var docLink = 'docLink'.concat(collectionCounter)
  var itemNote = 'itemNote'.concat(collectionCounter)
  var itemDate = 'itemDate'.concat(collectionCounter)
  var feedName = 'feedName'.concat(collectionCounter)
  var branchName = 'branchName'.concat(collectionCounter)
  var topicName = 'topicName'.concat(collectionCounter)



  localStorage.setItem(title,saveCollectionTitle)
  
  localStorage.setItem(detailLink,saveCollectionDetailLink)
 
  localStorage.setItem(docLink,saveCollectionDocLink)
  localStorage.setItem(itemNote,saveCollectionNote)

  localStorage.setItem(itemDate,saveCollectionDate)
  localStorage.setItem(feedName,saveCollectionFeedName)
  localStorage.setItem(branchName,saveCollectionBranchName)
  localStorage.setItem(topicName,saveCollectionTopicName)

  localStorage.setItem('collectionNumber', collectionCounter)



//return collectionCounter;




//return collectionCounter;


return(
render(
<>
<AddNoteModal></AddNoteModal>




                       </>
)
)

}




function NewHampshireCourts()
 {

  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
    
    var searchItem = document.getElementById('noteModalFeedItem').value
  
       collectionCounter = localStorage.getItem('collectionNumber')
       //console.log(collectionCounter)
     collectionCounter++;
       //console.log(collectionCounter)
     
     var collectionTitles = []
      var collectionDetailLinks = []
     var collectionDocLinks = []
     var collectionNote = []
     
     //collectionTitles.push(saveCollectionTitle)
     //collectionDetailLinks.push(saveCollectionDetailLink)
     //collectionDocLinks.push(saveCollectionDocLink)
     
     
     
      
       
     
      
       
       var title = 'title'.concat(collectionCounter)
       var detailLink = 'detailLink'.concat(collectionCounter)
       var docLink = 'docLink'.concat(collectionCounter)
       var itemNote = 'itemNote'.concat(collectionCounter)
       var itemDate = 'itemDate'.concat(collectionCounter)
       var feedName = 'feedName'.concat(collectionCounter)
       var branchName = 'branchName'.concat(collectionCounter)
       var topicName = 'topicName'.concat(collectionCounter)
       var itemDescription = 'itemDescription'.concat(collectionCounter)
   
   
     
     
       localStorage.setItem(title,saveCollectionTitle)
       
       localStorage.setItem(detailLink,saveCollectionDetailLink)
      
       localStorage.setItem(docLink,saveCollectionDocLink)
       localStorage.setItem(itemNote,searchItem)
   
       localStorage.setItem(itemDate,saveCollectionDate)
       localStorage.setItem(feedName,saveCollectionFeedName)
       localStorage.setItem(branchName,saveCollectionBranchName)
       localStorage.setItem(topicName,saveCollectionTopicName)
   
       localStorage.setItem(itemDescription,saveCollectionDescription)
     
       localStorage.setItem('collectionNumber', collectionCounter)
      
       function saveNote() {
     
       window.location.reload()
  
       }
  
   

  
  
  }
  const [searchQuery, setSearchQuery] = useState('');

  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
  const [completeBillFeed, setCompleteBillFeed] = React.useState([]);
  const [billTitle, setBillTitle] = React.useState([]);
  const [billLink, setBillLink] = React.useState([]);
  const [billDate, setBillDate] = React.useState([]);
  const [billDescription, setBillDescription] = React.useState([]);
  const [billDocumentLink, setBillDocumentLink] = React.useState([]);

 

  React.useEffect(async () => {

    let Parser = require('rss-parser');
    let parser = new Parser({
      customFields:{
       
      
        item:['row', 'openstates_url', 'updated_at', 'description', 'documentLink']
   } });

   const fBillFeed = [];
   const fBillTitle = [];
   const fBillLink = []
   const fBillDate= []
   const fBillDescription= []
   const fBillDocLink = []


    let feed = await parser.parseURL('https://evening-plains-21303.herokuapp.com/https://gov-glance-state-xml.nyc3.digitaloceanspaces.com/state_judicial/nh_json.xml');
    feed.items.forEach(item => {

      fBillFeed.push(item.title + ':' + item.link);
          
            if (item.title.toString().length >= 180)
            {
              
                    fBillTitle.push(item.title.toString().substring(0,177).replace(/\&nbsp;/g, '')+'...');
            }
                 else{
                     fBillTitle.push(item.title.toString().replace(/\&nbsp;/g, ''))
              }
      
      
                    // fBillDescription.push(item.description.toString())
              
            fBillLink.push(item.link);
           fBillDate.push(item.pubDate.substring(0,10));
              fBillDocLink.push(item.documentLink)
           
          
      //implement view details link still
      
          });

    setCompleteBillFeed(fBillTitle);
    setBillTitle(fBillTitle);
    setBillLink(fBillLink);
    setBillDate(fBillDate);
    setBillDate(fBillDate);
    setBillDocumentLink(fBillDocLink);


  }, []);

  const renderData = () => {
    const filteredData = completeBillFeed.reduce((filtered, f, index) => {
      const title = billTitle[index] || ''; 
  
      if (title.toLowerCase().includes(searchQuery.toLowerCase())) {
        filtered.push({ title, index });
      }
  
      return filtered;
    }, []);
  
    return filteredData.map(({ title, index }) => {
    
      return (
        <div key={index}>
         <div id='fullBillFeed'>
          <Container id='feedContainer'>

            <Card.Title id='billText' onClick={(e) => {
                   e.preventDefault();
                   window.open(billLink[index], "_blank")
                 }}>{billTitle[index]}</Card.Title>
            <Card.Body>{billDate[index]}</Card.Body>
             <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><InfoCircle></InfoCircle>Details</Button>
            <Button id='viewBillButton' onClick={(e) => {
              e.preventDefault();
              window.open(billLink[index], "_blank")
            }}><FileText></FileText> Document</Button>
                                                                                                                  {/*saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName */}
            <Button id='addToCollectionButton' onClick={() => 
            {setCollectionModalIsOpen(true); 
              SaveFeedItem(billTitle[index], billLink[index], billDocumentLink[index], 
               billDate[index], '', "New Hampshire Courts", "Judicial", "N/A", "" )}
               }><FolderPlus></FolderPlus></Button>

          </Container>

      </div>
        </div>
      );
    });
  };

  return(
    <>
<Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
centered onHide={setCollectionModalIsOpen}>
                             
                              <Modal.Header id='billModalHeader' closeButton ><h3>{storedTitle}</h3></Modal.Header>

                              <Modal.Body id='descriptionModalBody'>
                                          {storedDescription}
                                          {storedDate}
                              <div id='fullBill'>
                         <Form id='noteModalForm'>
         <Form.Group id='noteModalForm'  className="mb-3" >
           <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
             type="text"
             as={"textarea"}
             rows={2}
             />
             <br></br>

         </Form.Group>
         </Form>

         <Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(storedTitle, storedDetailsLink, storedDocLink, 
  storedDate, storedFeedName, storedBranchName, storedTopicName, storedDescription) ;setCollectionModalIsOpen(false) }} >
    Save To Collection
  </Button>
  </Modal.Footer> 

           </div>
           </Modal.Body>
                              </Modal>



                             <div id='searchForm'>
                              <Form.Control
  id="searchInput"
  placeholder="Search..."
  type="text"
  value={searchQuery}
  onChange={(e) => setSearchQuery(e.target.value)}
  color='white'
/>
</div> 
      {renderData()}
    </>
  )



  
}





export default NewHampshireCourts;