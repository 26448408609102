import App from "../../App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, PauseCircle, PlayCircle, FolderPlus, ChevronRight, Files, Folder, Pen, ChatLeftQuote, ShieldShaded, People, HeartFill } from 'react-bootstrap-icons'
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import MobileBackButton from "../../MobileBackButton";
import MobileOffCanvas from "../../MobileOffCanvas";
import SupremeCourt from "./SupremeCourt";
import { CreateFeedAPI, CreateFeedItemUKAtom } from "../../Functions";
import UKMobileTopNavbar from "../UKMobileTopNavbar";
import OffCanvasUK from "../OffCanvasUK";
import GenerateFeed from "../../GenerateFeed";
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);

var collectionCounter = 0  
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;

export const UKJudicialMobile = () =>   {
    render()

    {
  
          const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
          const [modal1, setModal1IsOpen] = useState(false);
          const [modal2, setModal2IsOpen] = useState(false);
          const [modal3, setModal3IsOpen] = useState(false);
          const [modal4, setModal4IsOpen] = useState(false);
          const [modal5, setModal5IsOpen] = useState(false);
          const [modal6, setModal6IsOpen] = useState(false);
          const [modal7, setModal7IsOpen] = useState(false);
          const [modal8, setModal8IsOpen] = useState(false);
          const [modal9, setModal9IsOpen] = useState(false);
          const [modal10, setModal10IsOpen] = useState(false);
          const [modal11, setModal11IsOpen] = useState(false);
          const [modal12, setModal12IsOpen] = useState(false);
          const [modal13, setModal13IsOpen] = useState(false);
          const [modal14, setModal14IsOpen] = useState(false);
          const [modal15, setModal15IsOpen] = useState(false);
          const [modal16, setModal16IsOpen] = useState(false);
          const [modal17, setModal17IsOpen] = useState(false);
          const [modal18, setModal18IsOpen] = useState(false);
          const [modal19, setModal19IsOpen] = useState(false);
          const [modal20, setModal20IsOpen] = useState(false);
          const [modal21, setModal21IsOpen] = useState(false);
          const [modal22, setModal22IsOpen] = useState(false);
          const [modal23, setModal23IsOpen] = useState(false);
          const [showBioModal, setBioModal] = useState(false);
          const [modalInfo, setModalInfo] = useState({
            isOpen: false,
            title: '',
            content: null
          });
        
          const openModal = (title, content) => {
            setModalInfo({
              isOpen: true,
              title,
              content
            });
          };
        
          const closeModal = () => {
            setModalInfo({
              isOpen: false,
              title: '',
              content: null
            });
          };
        
        localStorage.setItem('currentPage', 'uk')
    return(
       <>
    <UKMobileTopNavbar/>
  
  
  
  
  <div id='homebg'>
  <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />

  <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div id="profileImageOnPageDepartment">
        <Image id="profileImage-imageOnPageDepartment" src="/ukJudicial.png" />
      </div>
      <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
      {/* <Pen></Pen> */}
        <h5 id='branchHeadingMobile'>Judicial Branch</h5>
      </div>
    </div>
    <div style={{ marginLeft: 'auto' }}>
     
    </div>
  </div> 
  <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
  The Supreme Court, as well as being the final court of appeal, plays an important role in the development of United Kingdom law.
  <Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
  <br></br>
  <br></br>
  <div style={{ display: 'flex', justifyContent: 'center' }}><Link style={{color: '#21cff5'}} onClick={(e) => { e.preventDefault(); window.open("https://www.supremecourt.uk/about/role-of-the-supreme-court.html", "_blank") }} id='pageLink'><h5 id='presidentialFeedHeadingMobile'>Official Site</h5></Link>
  </div>
  </div>
</div>


<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> The Judicial Branch</h3></Modal.Header>
       
         <div id='fullBillFeed' >
         <p>The Supreme Court, as the final court of appeal, plays an important role in the development of United Kingdom law.</p>

<p>As an appeal court, The Supreme Court cannot consider a case unless a relevant order has been made in a lower court.</p>

<p>The Supreme Court:</p>
<ul>
  <li>is the final court of appeal for all United Kingdom civil cases, and criminal cases from England, Wales, and Northern Ireland</li>
  <li>hears appeals on arguable points of law of general public importance</li>
  <li>focuses on cases of the greatest public and constitutional importance</li>
  <li>maintains and develops the role of the highest court in the United Kingdom as a leader in the common law world</li>
</ul>

<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.supremecourt.uk/about/role-of-the-supreme-court.html", "_blank");
   }}>
     https://www.supremecourt.uk/about/role-of-the-supreme-court.html
     </Link>
 </div>

       </Modal>  
<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setModal1IsOpen(true)}>Supreme Court</h5>

  <div id='supremeCourt'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/supreme-court-of-the-united-kingdom.atom',
     'supremeCourt', 'UK Supreme Court', 'Judicial', 'N/A', SupremeCourt)}

  <Modal id='mobileFullBillModal' show={modal1} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal1IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Supreme Court</h3></Modal.Header>
<SupremeCourt/>
</Modal>

    
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' oonClick={() =>
                            openModal('Court and Tribunals',
                              <GenerateFeed
                                url='&skip=0&schema=united_kingdom&table=courts_and_tribunals&order_by=created_at'
                                name='Court and Tribunals' branch='Judicial' topic='N/A' buttonName='Doc' />)}>Court and Tribunals</h5>

  <div id='courtTrib'></div>
                          
  {CreateFeedAPI('&skip=0&schema=united_kingdom&table=courts_and_tribunals&order_by=created_at', 'courtTrib', 
                            'Court and Tribunals', 'Judicial', 
                            'N/A', 'Docs', GenerateFeed)}

    
</Col>

<Col>

<br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>

<br></br><br></br><br></br><br></br><br></br><br></br>

<Navbar id='navbarMobileBottom' fixed="bottom">  



       
<div id='changeBranchDropdownMobile'>

                   <OffCanvasUK/>
  
              
            </div>

</Navbar>
 
             
 
 
 
 
 
 

</div>



</>
)
}}
export default UKJudicialMobile;


