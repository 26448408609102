import App from "../../App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, PauseCircle, PlayCircle, FolderPlus, ChevronRight, Files, Folder, Pen, ChatLeftQuote, ShieldShaded, People, HeartFill } from 'react-bootstrap-icons'
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import MobileOffCanvas from "../../MobileOffCanvas";
import UKBillUpdates from "./UKBillUpdates";
import { CreateFeedAPI, CreateFeedItem, CreateFeedItemInstrument, CreateFeedItemUK } from "../../Functions";
import HouseOfLordsResearch from "./HouseOfLordsResearch";
import UKMobileTopNavbar from "../UKMobileTopNavbar";
import OffCanvasUK from "../OffCanvasUK";
import GenerateFeed from "../../GenerateFeed";
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;

export const UKLegislativeMobile = () => {
  render()

  {

    const [billUpdates, setBillUpdatesModalIsOpen] = useState(false)
    const [lordsResearchModal, setLordsResearchModalIsOpen] = useState(false)
    const [modal1, setModal1IsOpen] = useState(false)
    const [showBioModal, setBioModal] = useState(false);
    const [modalInfo, setModalInfo] = useState({
      isOpen: false,
      title: '',
      content: null
    });
  
    const openModal = (title, content) => {
      setModalInfo({
        isOpen: true,
        title,
        content
      });
    };
  
    const closeModal = () => {
      setModalInfo({
        isOpen: false,
        title: '',
        content: null
      });
    };
    localStorage.setItem('currentPage', 'uk')
    return (
      <>
        <UKMobileTopNavbar />




        <div id='homebg'>

        <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div id="profileImageOnPageDepartment">
        <Image id="profileImage-imageOnPageDepartment" src="/ukLegislative.png" />
      </div>
      <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
        {/* <People /> */}
        <h5 id='branchHeadingMobile'>Legislative</h5>
      </div>
    </div>

  </div> 
  <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
  The business of Parliament takes place in two Houses: the House of Commons and the House of Lords. Their work is similar: making laws (legislation), checking the work of the government (scrutiny), and debating current issues.
  <br></br>
  <Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
  </div>
</div>

<Modal id='fullBillModal' size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
    <Modal.Header id='billModalHeader' closeButton>
        <h3>The Legislative Branch</h3>
    </Modal.Header>

    <div id='fullBillFeed'>
        <p><strong>The Two-House System</strong></p>
        <p>The Parliament conducts its business through two chambers: the <strong>House of Commons</strong> and the <strong>House of Lords</strong>. Both chambers engage in legislating, scrutinizing government actions, and debating current issues.</p>
        
        <p><strong>The House of Commons</strong></p>
        <p>The House of Commons consists of publicly elected Members of Parliament (MPs). The party with the most MPs typically forms the government.</p>

        <p>MPs debate significant political matters and proposals for new laws. It serves as a primary arena for government ministers, such as the Prime Minister and Chancellor, and major political party leaders.</p>

        <p>The Commons has the exclusive authority to decide on financial Bills, including proposed taxes. While the House of Lords can review these Bills, it lacks the power to block or amend them.</p>
        
        <p><strong>The House of Lords</strong></p>
        <p>The House of Lords serves as the Parliament's second chamber, operating independently and complementing the elected House of Commons. It shares responsibilities in legislation, shaping laws, and scrutinizing government activities.</p>
        
        <br></br>
        <Link onClick={(e) => {
            e.preventDefault();
            window.open("https://www.parliament.uk/about/how/role/system/", "_blank");
        }}>
            https://www.parliament.uk/about/how/role/system/
        </Link>
    </div>
</Modal>
      

        <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />


       
          {/*onClick={() => setModalIsOpen(true)}*/}

          <Col>
            <h5 id='presidentialFeedHeadingMobile' onClick={() =>
                            openModal('Bill Updates',
                              <GenerateFeed
                                url='&skip=0&schema=united_kingdom&table=all_bills&order_by=created_at'
                                name='UK Bill Updates' branch='Legislative' topic='N/A' buttonName='Bill' />)}>Bill Updates</h5>

            <div id='billUpdates'></div>

            {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
            {CreateFeedAPI('&skip=0&schema=united_kingdom&table=all_bills&order_by=created_at', 'billUpdates', 
                            'UK Bill Updates', 'Legislative', 
                            'N/A', 'Bill', GenerateFeed)}

            <Modal id='mobileFullBillModal' show={billUpdates}
              size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillUpdatesModalIsOpen(false)}>
              <Modal.Header id='mobileBillModalHeader' closeButton><h3>Bill Updates</h3></Modal.Header>
              <UKBillUpdates />
            </Modal>


          </Col>


          <Col>
            <h5 id='presidentialFeedHeadingMobile' onClick={() => setLordsResearchModalIsOpen(true)}>House of Lords Research</h5>

            <div id='lordsResearch'></div>

            {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
            {CreateFeedItemUK('https://lordslibrary.parliament.uk/type/research-briefing/feed/',
              'lordsResearch', 'House of Lords Research', 'Legislative', 'N/A', HouseOfLordsResearch)}

            <Modal id='mobileFullBillModal' show={lordsResearchModal}
              size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setLordsResearchModalIsOpen(false)}>
              <Modal.Header id='mobileBillModalHeader' closeButton><h3>House of Lords Research</h3></Modal.Header>
              <HouseOfLordsResearch />
            </Modal>


          </Col>



          <Col>

            <br></br><br></br><br></br><br></br>
            <br></br>
            <br></br>
            <h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
              e.preventDefault();
             window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
            }}><HeartFill id='donateIcon' />    Donate</Button></h5>

          </Col>

          <br></br><br></br><br></br><br></br><br></br><br></br>

          <Navbar id='navbarMobileBottom' fixed="bottom">




            <div id='changeBranchDropdownMobile'>

              <OffCanvasUK/>

            </div>

          </Navbar>









        </div>



      </>
    )
  }
}
export default UKLegislativeMobile;


