import App from "./App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, useRef } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, Spinner } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, FolderPlus, PlayCircle, Search } from 'react-bootstrap-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import debounce from 'lodash.debounce';

require('dotenv').config();
const apiKey = process.env.REACT_APP_API_KEY;


var storedTitle = ''// title
var storedDetailsLink = ''// details link
var storedDocLink = ''// doc link *
var storedDate = ''// date
var storedNote = ''// note
var storedFeedName = ''// feed name
var storedBranchName = ''// branch name 
var storedTopicName = ''// topic name
var storedDescription = ''// description
var collectionCounter = localStorage.getItem('collectionNumber')



function SaveFeedItem(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, 
  saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription)
{

storedTitle = saveCollectionTitle;
storedDetailsLink = saveCollectionDetailLink;
storedDocLink = saveCollectionDocLink;
storedDate = saveCollectionDate;
storedNote = saveCollectionNote;
storedFeedName = saveCollectionFeedName;
storedBranchName = saveCollectionBranchName;
storedTopicName = saveCollectionTopicName;
storedDescription = saveCollectionDescription;
}


{/*
Expected Payload:

id
country
administrative_division_first_level
administrative_division_second_level
administrative_division_third_level
administrative_division_four_level
branch
topic
guid
category
creator
date
title
long_title
url — source
doc_urls — as a dictionary, link(s) to corresponding document(s)
description
encoded
collected_at
other_data
, branch, topic
*/}

function formatDate(inputDate) {
  const date = new Date(inputDate);
  
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
}

function GenerateFeedDepartmentUK(apiRequest, name, branch, topic, buttonName) {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  var [feedLength, setFeedLength] = useState(20);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [filteredData, setFilteredData] = useState([]); // Add a state for filtered data

  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
    
    var searchItem = document.getElementById('noteModalFeedItem').value
  
    collectionCounter = localStorage.getItem('collectionNumber')
    collectionCounter++;

    localStorage.setItem('title'.concat(collectionCounter), saveCollectionTitle);
    localStorage.setItem('detailLink'.concat(collectionCounter), saveCollectionDetailLink);
    localStorage.setItem('docLink'.concat(collectionCounter), saveCollectionDocLink);
    localStorage.setItem('itemNote'.concat(collectionCounter), searchItem);
    localStorage.setItem('itemDate'.concat(collectionCounter), saveCollectionDate);
    localStorage.setItem('feedName'.concat(collectionCounter), saveCollectionFeedName);
    localStorage.setItem('branchName'.concat(collectionCounter), saveCollectionBranchName);
    localStorage.setItem('topicName'.concat(collectionCounter), saveCollectionTopicName);
    localStorage.setItem('itemDescription'.concat(collectionCounter), saveCollectionDescription);
    localStorage.setItem('collectionNumber', collectionCounter);
  }

  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);

  const requestOptions = {
    method: 'GET',
    headers: { 
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + apiKey,
    },
  };

  useEffect(() => {
    const url = `https://api.govglance.org/posts/recent?&limit=10&skip=0&schema=united_kingdom${apiRequest.url}`;

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setFilteredData(data); // Set the filtered data initially as the full data
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [apiRequest.url]);

  const handleSearchButtonClick = () => {
    if (searchTerm.trim() === '') {
      setFilteredData(data); // Reset to full data if search term is empty
      return;
    }

    const searchUrl = `https://api.govglance.org/posts/recent?&limit=10&skip=0&schema=united_kingdom${apiRequest.url}&search=${searchTerm}`;
    
    setLoading(true);
    fetch(searchUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((searchData) => {
        const filtered = searchData.filter(item => {
          const title = item.title || '';
          const statusTitle = item.status_title || '';
          return (
            title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            statusTitle.toLowerCase().includes(searchTerm.toLowerCase())
          );
        });

        setFilteredData(filtered);
        setLoading(false);
        setSearchPerformed(true);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
    setSearchPerformed(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearchButtonClick();
    }
  };

  const renderData = () => {
    if (loading) {
      return <div id='fullBillFeed'><Spinner animation="grow" variant="light" /></div>;
    }

    if (error) {
      return <div id='fullBillFeed'><p>Error fetching data. </p></div>;
    }

    if (filteredData.length === 0) {
      return <div id='fullBillFeed'><p>No results found. </p></div>;
    }

    return filteredData.map((item, index) => {
      const title = item.status_title || item.title;
      const link = item.url;
      const docLink = item.document_link || item.download?.pdfLink;
      const date = formatDate(item.created_at);
      const description = "";
      const feedName = apiRequest.name; 
      const feedBranch = apiRequest.branch; 
      const feedTopic = apiRequest.topic; 
      const docButtonName = apiRequest.buttonName; 

      return (
        <div key={index}>
          <div id='fullBillFeed'>
            <Container id='feedContainer'>
              <Card.Title id='billText' onClick={(e) => {
                e.preventDefault();
                window.open(docLink || link ,'_blank');
              }}>
                {title}
              </Card.Title>
              <Card.Body id='billBodyText'>{date}</Card.Body>
              <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(link ,'_blank');
              }}><InfoCircle></InfoCircle> Details</Button>
              
              {(docLink && (
                <Button id='viewBillButton' onClick={(e) => {
                  e.preventDefault();
                  window.open(docLink ,'_blank');
                }}>
                  <FileText></FileText> {docButtonName}
                </Button>
              ))}
    
              <Button id='addToCollectionButton' onClick={() => {
                setCollectionModalIsOpen(true);
                SaveFeedItem(title, link, '', date, '', feedName, feedBranch, feedTopic, description);
              }}><FolderPlus></FolderPlus></Button>
            </Container>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
        centered onHide={setCollectionModalIsOpen}>
        <Modal.Header id='billModalHeader' closeButton ><h3>{storedTitle}</h3></Modal.Header>
        <Modal.Body id='descriptionModalBody'>
          {storedDescription}
          {storedDate}
          <div id='fullBill'>
            <Form id='noteModalForm'>
              <Form.Group id='noteModalForm' className="mb-3">
                <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
                  type="text"
                  as={"textarea"}
                  rows={2}
                />
                <br />
              </Form.Group>
            </Form>
            <Modal.Footer id='addNoteModalFooter'> 
              <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(storedTitle, storedDetailsLink, storedDocLink, 
                storedDate, storedFeedName, storedBranchName, storedTopicName, storedDescription) ;setCollectionModalIsOpen(false) }} >
                Save To Collection
              </Button>
            </Modal.Footer> 
          </div>
        </Modal.Body>
      </Modal>
      <div id='searchForm'>
        <Form className="text-center" onSubmit={(e) => { e.preventDefault(); }}>
          <Form.Group className="d-flex justify-content-center align-items-center">
            <Form.Control
              variant="light"
              id="searchInput"
              placeholder="Search..."
              type="text"
              value={searchTerm}
              onChange={handleSearchInputChange}
              onKeyDown={handleKeyDown}
              color="white"
            />
            <Button id='ggButton' onClick={handleSearchButtonClick}>
              <Search /> 
            </Button>
          </Form.Group>
        </Form>
      </div>
      {renderData()}
      <div id='fullBillFeed'>
        <Button id='standardButton' onClick={() => {
          setFeedLength(feedLength += 20);
        }}>More</Button>
      </div>
    </>
  )
}

export default GenerateFeedDepartmentUK;



