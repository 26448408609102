import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter, Spinner } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, HeartFill, FileText, PauseCircle, PlayCircle, FolderPlus, ChevronRight, Files, Folder, Pen, ChatLeftQuote, GeoAlt, Search } from 'react-bootstrap-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserView, MobileView } from 'react-device-detect';
import DesktopNavbarAI from './DesktopNavbarAI';
import DesktopNavbarSearch from './DesktopNavbarSearch';
import { useLocation } from 'react-router-dom';
import MobileOffCanvas from './MobileOffCanvas';

var storedTitle = ''// title
var storedDetailsLink = ''// details link
var storedDocLink = ''// doc link *
var storedDate = ''// date
var storedNote = ''// note
var storedFeedName = ''// feed name
var storedBranchName = ''// branch name 
var storedTopicName = ''// topic name
var storedDescription = ''// description
var collectionCounter = localStorage.getItem('collectionNumber')

require('dotenv').config();
const apiKey = process.env.REACT_APP_API_KEY;

function stripHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }

  function truncateDescription(description, maxLength) {
    const strippedText = stripHtmlTags(description);
    return strippedText.length > maxLength ? strippedText.slice(0, maxLength) + '...' : strippedText;
  }

  const TestSearch = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
  
    const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false);
    const [inputText, setInputText] = useState('');
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [feedLength, setFeedLength] = useState(10);
    const [searchTriggered, setSearchTriggered] = useState(false);
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
    
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
         //console.log(collectionCounter)
       collectionCounter++;
         //console.log(collectionCounter)
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
       
       //collectionTitles.push(saveCollectionTitle)
       //collectionDetailLinks.push(saveCollectionDetailLink)
       //collectionDocLinks.push(saveCollectionDocLink)
       
       
       
        
         
       
        
         
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         localStorage.setItem(itemDescription,saveCollectionDescription)
       
         localStorage.setItem('collectionNumber', collectionCounter)
        
         function saveNote() {
       
         window.location.reload()
    
         }
    
     
  
    
    
    }
  
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
  
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer "+apiKey,
      },
    };
  
    const runSearch = () => {
      setLoading(true);
  
      fetch(`https://api.govglance.org/search/united_states_of_america/congressional_bills?limit=${feedLength}&skip=0&search=${inputText}`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setData(data);
          console.log(data)
          setLoading(false);
          
        })
        .catch((error) => {
          setError(error);
          console.log(error.message)
          setLoading(false);
        });
    };
  
    const handleSearch = () => {
      setSearchTriggered(true);
      runSearch();
      // Update URL
      const newUrl = generateSearchUrl();
      window.history.pushState({ path: newUrl }, '', newUrl);
    };
  
    const generateSearchUrl = () => {
      return `/search?query=${encodeURIComponent(inputText)}&limit=${feedLength}`;
    };

    function SaveFeedItem(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, 
      saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription)
    {
    
    storedTitle = saveCollectionTitle;
    storedDetailsLink = saveCollectionDetailLink;
    storedDocLink = saveCollectionDocLink;
    storedDate = saveCollectionDate;
    storedNote = saveCollectionNote;
    storedFeedName = saveCollectionFeedName;
    storedBranchName = saveCollectionBranchName;
    storedTopicName = saveCollectionTopicName;
    storedDescription = saveCollectionDescription;
    }
  
    useEffect(() => {
      const hasQuery = queryParams.has('query');
    
      if (inputText === '' && hasQuery) {
        // Set inputText from the query parameter but don't trigger the search automatically
        setInputText(queryParams.get('query'));
    
        // Trigger the search
        setSearchTriggered(true);
        runSearch();
      }
    }, [queryParams, inputText, searchTriggered]);
    
    useEffect(() => {
      // Run the search only if searchTriggered is true
      if (searchTriggered) {
        runSearch();
      }
    }, [searchTriggered]);

return (
    <>

    <BrowserView>
    
    <div id='homebg'>
      <DesktopNavbarSearch/>


      <Navbar id='navbarSearchLogo' >
  <Navbar.Brand id='searchLogo' >
    {<Image  width='200px' src='/favicon.ico'></Image>}
  </Navbar.Brand>
  
</Navbar>

<h1 id='headingSearch'>
          {' '}
           Gov Glance Search
        </h1>
        <Form className="text-center" onSubmit={(e) => { e.preventDefault(); handleSearch(); }}>
  <Form.Group className="d-flex justify-content-center align-items-center">
    <Dropdown.Toggle id='searchCountry'>
      🇺🇸
    </Dropdown.Toggle>
    <Form.Control
      id='ggSearch'
      size="sm"
      placeholder="Search the Gov Glance database"
      type="text"
      value={inputText}
      onChange={(e) => setInputText(e.target.value)}
      autoComplete='off'
    />
    <Button variant="primary" id='ggButton' type="submit">
      <Search/> Search
    </Button>
  </Form.Group>
</Form>

<div id='searchSpacer'></div>
           {loading && <Spinner animation="grow" variant="light" />}





{data && (
  
  <div>
  {data.map(item => (
    
   <div key={item.id} id='searchPadding' className='d-flex align-items-center justify-content-center'>
   <Container id='searchFeedContainer'>
        <Card.Title id='billText' onClick={(e) => {
                e.preventDefault();
                window.open(item.url, "_blank");
              }}><b>{item.status_title || item.title}</b></Card.Title>
              <Card.Body id='billBodyText' >{item.created_at}</Card.Body>
        <Card.Body id='billBodyText' onClick={(e) => {
                e.preventDefault();
                window.open(item.url, "_blank");
              }} >{stripHtmlTags(truncateDescription(item.description, 297))}</Card.Body>
        <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(item.url, "_blank");
              }}><InfoCircle></InfoCircle> Details</Button>
                        {item.document_url && (
            <Button id='viewBillButton' onClick={(e) => {
              e.preventDefault();
              window.open(item.document_url, "_blank");
            }}><FileText></FileText> Document</Button>
                        )}

<Button id='addToCollectionButton'  onClick={() =>     {setCollectionModalIsOpen(true); 
                   SaveFeedItem(item.title, item.url, "", "", 
                   "", "", "", "", stripHtmlTags(truncateDescription(item.description, 297)))} }><FolderPlus></FolderPlus></Button>
     

     <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
centered onHide={setCollectionModalIsOpen}>
                             
                              <Modal.Header id='billModalHeader' closeButton ><h3>{storedTitle}</h3></Modal.Header>

                              <Modal.Body id='descriptionModalBody'>
                                          {storedDescription}
                                          {storedDate}
                              <div id='fullBill'>
                         <Form id='noteModalForm'>
         <Form.Group id='noteModalForm'  className="mb-3" >
           <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
             type="text"
             as={"textarea"}
             rows={2}
             />
             <br></br>

         </Form.Group>
         </Form>

         <Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(storedTitle, storedDetailsLink, storedDocLink, 
  storedDate, storedFeedName, storedBranchName, storedTopicName, storedDescription) ;setCollectionModalIsOpen(false) }} >
    Save To Collection
  </Button>
  </Modal.Footer> 

           </div>
           </Modal.Body>
                              </Modal>
      </Container>
    </div>
  ))}

<div style={{ textAlign: 'center' }}>
  <Button id='searchViewMoreButton' onClick={() => {
    setFeedLength(feedLength + 20);
    handleSearch();
  }}>More</Button>
  
</div>
            </div>
          )}
  
        </div>
     


      </BrowserView>
      <MobileView>

      <div id='homebg'>


      <DesktopNavbarSearch/>


      <Navbar id='navbarSearchLogo' >
  <Navbar.Brand id='searchLogo' >
    {<Image  width='100px' src='/favicon.ico'></Image>}
  </Navbar.Brand>
  
</Navbar>

<h1 id='headingSearch'> Gov Glance Search
        </h1>
        <Form className="text-center" onSubmit={(e) => { e.preventDefault(); handleSearch(); }}>
  <Form.Group className="d-flex justify-content-center align-items-center">
    <Dropdown.Toggle id='searchCountry'>
      🇺🇸
    </Dropdown.Toggle>
    <Form.Control
      id='ggSearch'
      size="sm"
      placeholder="Search Gov Glance"
      type="text"
      value={inputText}
      onChange={(e) => setInputText(e.target.value)}
      autoComplete='off'
    />
    <Button variant="primary" id='ggButton' type="submit">
      <Search/>
    </Button>
  </Form.Group>
</Form>

<div id='searchSpacer'></div>
           {loading && <Spinner animation="grow" variant="light" />}



{data && (
  <div>
  {data.map(item => (
   <div key={item.id} id='searchPadding' className='d-flex align-items-center justify-content-center'>
   <Container id='searchFeedContainer'>
        <Card.Title id='billText' onClick={(e) => {
                e.preventDefault();
                window.open(item.url, "_blank");
              }}><b>{item.title}</b></Card.Title>
        <Card.Body id='billBodyText' onClick={(e) => {
                e.preventDefault();
                window.open(item.url, "_blank");
              }} >{stripHtmlTags(truncateDescription(item.description, 297))}</Card.Body>
        <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(item.url, "_blank");
              }}><InfoCircle></InfoCircle> Details</Button>
                        {item.document_url && (
            <Button id='viewBillButton' onClick={(e) => {
              e.preventDefault();
              window.open(item.document_url, "_blank");
            }}><FileText></FileText> Document</Button>
                        )}

<Button id='addToCollectionButton'  onClick={() =>     {setCollectionModalIsOpen(true); 
                   SaveFeedItem(item.title, item.url, "", "", 
                   "", "", "", "", stripHtmlTags(truncateDescription(item.description, 297)))} }><FolderPlus></FolderPlus></Button>
     

     <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
centered onHide={setCollectionModalIsOpen}>
                             
                              <Modal.Header id='billModalHeader' closeButton ><h3>{storedTitle}</h3></Modal.Header>

                              <Modal.Body id='descriptionModalBody'>
                                          {storedDescription}
                                          {storedDate}
                              <div id='fullBill'>
                         <Form id='noteModalForm'>
         <Form.Group id='noteModalForm'  className="mb-3" >
           <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
             type="text"
             as={"textarea"}
             rows={2}
             />
             <br></br>

         </Form.Group>
         </Form>

         <Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(storedTitle, storedDetailsLink, storedDocLink, 
  storedDate, storedFeedName, storedBranchName, storedTopicName, storedDescription) ;setCollectionModalIsOpen(false) }} >
    Save To Collection
  </Button>
  </Modal.Footer> 

           </div>
           </Modal.Body>
                              </Modal>
      </Container>
    </div>
  ))}

<div style={{ textAlign: 'center' }}>
  <Button id='searchViewMoreButtonMobile' onClick={() => {
    setFeedLength(feedLength + 20);
    handleSearch();
  }}>More</Button>
  
</div>
            </div>
          )}
  <br></br><br></br><br></br>
  <Navbar id='navbarMobileBottom' fixed="bottom">    

<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
              
            </div>

</Navbar>
        </div>
     


      </MobileView>
    </>
  );
};

export default TestSearch; 