import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, useHistory } from "react-router-dom";
import { Spinner, Card, Button, Container, Modal, Form, Navbar } from 'react-bootstrap';
import { InfoCircle, FileText, FolderPlus, Search, Clipboard, ClipboardCheck, BoxArrowUp } from 'react-bootstrap-icons';
import './App.css';
import useHandleContainerClickRecents from './UseHandleContainerClickRecents';
import { BrowserView, MobileView } from 'react-device-detect';
import MobileTopNavbar from './MobileTopNavbar';
import DesktopNavbar from './DesktopNavbar';
import MobileOffCanvas from './MobileOffCanvas';

require('dotenv').config();
const apiKey = process.env.REACT_APP_API_KEY;
const requestOptions = {
    method: 'GET',
    headers: { 
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + apiKey,
  },
};

var storedTitle = ''; // title
var storedDetailsLink = ''; // details link
var storedDocLink = ''; // doc link
var storedDate = ''; // date
var storedNote = ''; // note
var storedFeedName = ''; // feed name
var storedBranchName = ''; // branch name 
var storedTopicName = ''; // topic name
var storedDescription = ''; // description
var collectionCounter = localStorage.getItem('collectionNumber') || 0;

function SaveFeedItem(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription) {
  storedTitle = saveCollectionTitle;
  storedDetailsLink = saveCollectionDetailLink;
  storedDocLink = saveCollectionDocLink;
  storedDate = saveCollectionDate;
  storedNote = saveCollectionNote;
  storedFeedName = saveCollectionFeedName;
  storedBranchName = saveCollectionBranchName;
  storedTopicName = saveCollectionTopicName;
  storedDescription = saveCollectionDescription;
}

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
}

function GenerateFeedRecentScienceTech() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  var [feedLength, setFeedLength] = useState(10);
  const handleContainerClick = useHandleContainerClickRecents();
  const history = useHistory();
const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
  // Share functionality states
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const handleNativeShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Check this out!',
          text: 'Here’s something interesting I found:',
          url: shareLink,
        });
        console.log('Content shared successfully!');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert('Sharing is not supported on this device.');
    }
  };
  // Function to open the share modal
  const handleShareModalOpen = (itemTable, id) => {
    const baseUrl = 'https://govglance.org';
    const fullUrl = `${baseUrl}/feed/united_states_of_america/${itemTable}/${id}`;
    setShareLink(fullUrl);
    setShareModalIsOpen(true);
    setLinkCopied(false);
  };

  // Function to copy the share link
  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink)
      .then(() => setLinkCopied(true))
      .catch((error) => console.error('Error copying link:', error));
  };

  const apiURL = `https://api.govglance.org/posts/recent/topic?limit=${feedLength}&skip=0&topic=science_and_tech`;

  useEffect(() => {
    setLoading(true);
    fetch(apiURL, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setData(data);
        setLoading(false);
        setError(null);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, [feedLength]);

  const performSearch = () => {
    const filteredData = data.filter(item =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setData(filteredData);
  };

  const handleSearchInputChange = event => {
    setSearchTerm(event.target.value);
  };

  const renderData = () => {
    let filteredData = data;

    if (searchTerm) {
      filteredData = data.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (loading) {
      return <Spinner animation="grow" variant="light" />;
    }

    if (error) {
      return <p>Error fetching data.</p>;
    }

    if (!filteredData || filteredData.length === 0) {
      return <p>No results found.</p>;
    }

    return filteredData.map((item, index) => {
      const title = item.title;
      const link = item.url;
      const date = formatDate(item.created_at);
      const source = item.collection_name;
      const id = item.id;
      const itemTable = item.source;
      return (
        <div key={index}>
          <div id='feedPageFeed'>
            <Container id="feedContainerNormalView">
              {/* Add functionality for source click */}
              <Card.Title id='billBodyTextSource' onClick={() => {
                // Redirect to the same place as the "View More" button using the source as the table
                history.push({
                  pathname: `/feed/united_states_of_america/${itemTable}`,
                  state: {
                    source,
                    feedName: item.feed_name,
                    feedBranch: item.branch_name,
                    feedTopic: item.topic_name,
                    feedButtonName: 'View More',
                  },
                });
              }}>
                {source}
              </Card.Title>

              <Card.Title id='billText' onClick={(e) => {
                e.preventDefault();
                const linkToOpen = item.document_link || item.download?.pdfLink || link;
                window.open(linkToOpen, "_blank");
              }}>
                {title}
              </Card.Title>

              <Card.Body id='billBodyText'>{date}</Card.Body>
              <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(link, "_blank");
              }}>
                <InfoCircle /> Details
              </Button>

              {(item.document_link || item.download?.pdfLink) && (
                <Button id='viewBillButton' onClick={(e) => {
                  e.preventDefault();
                  const linkToOpen = item.document_link || item.download?.pdfLink;
                  window.open(linkToOpen, "_blank");
                }}>
                  <FileText /> Doc
                </Button>
              )}
              <Button id='addToCollectionButton' onClick={() => {
                setCollectionModalIsOpen(true);
                SaveFeedItem(title, link, "N/A", date, '', item.source, "", "", "");
              }}>
                <FolderPlus />
              </Button>

              {/* Share Button */}
              <Button id="shareButton" onClick={() => handleShareModalOpen(item.source, id)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
                  <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                </svg>
              </Button>
            </Container>
          </div>
        </div>
      );
    });
  };

  return (
    <>
    <BrowserView>
    <div id='homebg'>
      <DesktopNavbar/>
      <div id='searchFormPage'>
        <Form className="text-center" onSubmit={(e) => { e.preventDefault(); }}>
          <Form.Group className="d-flex justify-content-center align-items-center">
            <Form.Control
              variant="light"
              id="ggSearchCollection"
              placeholder="Search..."
              type="text"
              value={searchTerm}
              onChange={handleSearchInputChange}
            />
          </Form.Group>
        </Form>
      </div>

      {renderData()}
      <div className="d-flex justify-content-center align-items-center">
      <Button id="feedViewMoreButton" onClick={() => {
        setFeedLength(feedLength += 20)
      }}>More</Button>
      </div>

      {/* Share Modal */}
    <Modal id="fullBillModal" show={shareModalIsOpen} size="xl" centered onHide={() => setShareModalIsOpen(false)}>
        <Modal.Header id="billModalHeader" closeButton>
          <h3>Share</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          <Form.Group id="noteModalForm">
            <Form.Control id="noteModalFeedItem" type="text" readOnly value={shareLink} />
          </Form.Group>
          <Modal.Footer id="shareFooter">
          <Button 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>
            <Button id="addToCollectionButton" onClick={handleCopyLink}>
              {linkCopied ? <ClipboardCheck /> : <Clipboard />}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </div>
    </BrowserView>

    <MobileView>
    <div id='homebg'>
      <MobileTopNavbar/>
      <div id='searchFormPage'>
        <Form className="text-center" onSubmit={(e) => { e.preventDefault(); }}>
          <Form.Group className="d-flex justify-content-center align-items-center">
            <Form.Control
              variant="light"
              id="ggSearchCollection"
              placeholder="Search..."
              type="text"
              value={searchTerm}
              onChange={handleSearchInputChange}
            />
          </Form.Group>
        </Form>
      </div>

      {renderData()}
      <div className="d-flex justify-content-center align-items-center">
      <Button id="feedViewMoreButton" onClick={() => {
        setFeedLength(feedLength += 20)
      }}>More</Button>
      </div>

      {/* Share Modal */}
    <Modal id="fullBillModal" show={shareModalIsOpen} size="xl" centered onHide={() => setShareModalIsOpen(false)}>
        <Modal.Header id="billModalHeader" closeButton>
          <h3>Share</h3>
        </Modal.Header>
        <Modal.Body id="descriptionModalBody">
          <Form.Group id="noteModalForm">
            <Form.Control id="noteModalFeedItem" type="text" readOnly value={shareLink} />
          </Form.Group>
          <Modal.Footer id="shareFooter">
          <Button 
  id="addToCollectionButton" 
  onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>
            <Button id="addToCollectionButton" onClick={handleCopyLink}>
              {linkCopied ? <ClipboardCheck /> : <Clipboard />}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
      <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
           
            </div>
            </Navbar>
    </div>
    </MobileView>


</>
  );
}

export default GenerateFeedRecentScienceTech;
