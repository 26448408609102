import React, { useState, useEffect, memo } from 'react';
import { Button, Card, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { BoxArrowUp, Clipboard, ClipboardCheck, FileText, FolderPlus, InfoCircle } from 'react-bootstrap-icons';
import { useHistory } from "react-router-dom";

function formatDate(inputDate) {
  const date = new Date(inputDate);
  return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
}

const APIMemberVotePull = memo(({ bio_guide_id }) => {
  const history = useHistory();
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
  const [voteInfo, setVoteInfo] = useState([]);
  const [collectionCounter, setCollectionCounter] = useState(parseInt(localStorage.getItem('collectionNumber')) || 0);
  const [selectedVote, setSelectedVote] = useState(null); // To handle the modal for a specific vote
  const apiKey = process.env.REACT_APP_API_KEY;
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false); // For the share modal
  const [shareLink, setShareLink] = useState(''); // The link to be shared
  const [linkCopied, setLinkCopied] = useState(false); // To track if the link is copie
  const saveNote = (vote) => {
    const searchItem = document.getElementById('noteModalFeedItem').value;
    const updatedCounter = collectionCounter + 1;
    localStorage.setItem(`title${updatedCounter}`, vote.title);
    localStorage.setItem('collectionNumber', updatedCounter);
    setCollectionCounter(updatedCounter);
    setCollectionModalIsOpen(false);
  };

  useEffect(() => {
    console.log(bio_guide_id);
    const fetchVoteInfo = async () => {
      try {
        const response = await fetch(`https://api.govglance.org/posts/recent?limit=1000&skip=0&schema=united_states_of_america&table=all_member_votes&order_by=created_at&filter_column=bio_guide_id&filter_string=${bio_guide_id}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${apiKey}` },
        });
        const data = await response.json();
        setVoteInfo(data); // Store the array of votes
      } catch (error) {
        console.error('Error fetching vote info:', error);
      }
    };

    fetchVoteInfo();
  }, [bio_guide_id]);
  const handleShareClick = (id) => {
  
    // console.log(`Current URL: ${window.location.href}`);
    const shareUrl = `govglance.org/feed/united_states_of_america/all_member_votes/${id}`;
    setShareLink(shareUrl);
    setShareModalIsOpen(true);
    setLinkCopied(false); // Reset the link copied state
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink).then(() => {
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 3000); // Reset after 3 seconds
    });
  };

  const handleNativeShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: '',
          text: '',
          url: shareLink,
        });
     //   console.log('Content shared successfully!');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert('Sharing is not supported on this device.');
    }
  };
  return (
    <>
      {voteInfo.length > 0 ? (
        <>
          {voteInfo.map((vote) => (
            <div key={vote.id}>
              <Container id='feedContainerNormalView'>
              <span>{vote.title.length > 180 ? `${vote.title.slice(0, 177)}...` : vote.title}</span>
              <Card.Body
                id='billBodyText'
                onClick={() => window.open(vote.document_link, "_blank")}
                aria-label="Open Document Link"
              ></Card.Body>
              <p>
              <Card.Body id="voteBodyText">
            <p>Date: {formatDate(vote.created_at)}</p>
            <p>Status: {vote.status}</p>
            <p>Vote: {vote.vote}</p>
            </Card.Body>


                <Button id='billLink' onClick={() => history.push(`/feed/united_states_of_america/all_member_votes/${vote.roll_call_number}`)}>
                  <InfoCircle /> More
                </Button>
                <Button id='viewBillButton' onClick={() => window.open(vote.document_link, "_blank")}>
                  <FileText /> Document
                </Button>
                <Button id='addToCollectionButton' onClick={() => { setSelectedVote(vote); setCollectionModalIsOpen(true); }}>
                  <FolderPlus />
                </Button>
                <Button
                id="shareButton"
                onClick={(e) => {
                  e.stopPropagation();
                  handleShareClick(vote.roll_call_number);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-send"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                </svg>
              </Button>
              <Modal
id="shareModal"
show={shareModalIsOpen}
size="xl"
aria-labelledby="contained-modal-title-vcenter"
centered
onHide={() => setShareModalIsOpen(false)}
>
<Modal.Header id="billModalHeader" closeButton>
  <h3>Share</h3>
</Modal.Header>
<Modal.Body id="descriptionModalBody">
  <Form.Group id="noteModalForm">
    <Form.Control
   
      id="noteModalFeedItem"
      type="text"
      readOnly
      value={shareLink}
    />
  </Form.Group>
  <Modal.Footer id="shareFooter">
  <Button 
id="addToCollectionButton" 
onClick={() => handleNativeShare()}
>
<BoxArrowUp/>
</Button>

    <Button id="addToCollectionButton" onClick={handleCopyLink}>
      {linkCopied ? <ClipboardCheck/> : <Clipboard />}
    </Button>
  </Modal.Footer>
</Modal.Body>
</Modal>
              </p>
            </Container>
            <br></br>
            </div>
            
          ))}
          {selectedVote && (
            <Modal id='fullVoteModal' show={collectionModalIsOpen} size="xl" aria-labelledby="voteModalHeader" centered onHide={() => setCollectionModalIsOpen(false)}>
              <Modal.Header id='voteModalHeader' closeButton>
                <h3>{selectedVote.title}</h3>
              </Modal.Header>
              <Modal.Body id='descriptionModalBody'>
                {selectedVote.description || "No description available"}
                <Form id='noteModalForm'>
                  <Form.Group className="mb-3">
                    <Form.Control id="noteModalFeedItem" placeholder="Add a note here" as="textarea" rows={2} />
                  </Form.Group>
                </Form>
                <Modal.Footer>
                  <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => saveNote(selectedVote)}>
                    Save To Collection
                  </Button>
                </Modal.Footer>
              </Modal.Body>
            </Modal>

            


          )}
        </>
      ) : (
        <Spinner animation="grow" variant="light" />
      )}
    </>
  );
});

export default APIMemberVotePull;
