
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, Pen, PauseCircle, PlayCircle, People, HeartFill, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, ChevronRight } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import ExecutiveOrders from './ExecutiveOrders';
import PresidentialProclamations from './PresidentialProclamations';
import NationalScienceFoundation from './NationalScienceFoundation';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
  createWhiteHouseFeedMobile,createDepartmentOfState,
  createDepartmentOfDefense, 
  createDepartmentOfJustice, createDepartmentOfTheInterior,
  createDepartmentOfAgriculture, createDepartmentOfCommerce,
  createDepartmentOfCommerceMobile, createDepartmentOfLabor,
  createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
  ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
  , createEnvironmentalProtectionAgency,
  createDepartmentOfTreasury, createNationalScienceFoundation, 
   createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, createFederalRegisterStateDepartment, createFederalRegisterTreasuryDepartment, createFederalRegisterJusticeDepartment, createFederalRegisterInteriorDepartment, createFederalRegisterAgricultureDepartment, createFederalRegisterCommerceDepartment, createFederalRegisterLaborDepartment, createFederalRegisterHealthAndHumanServicesDepartment, createFederalRegisterHousingAndUrbanDevelopmentDepartment, createFederalRegisterHomelandSecurityDepartment, createFederalRegisterEducationDepartment, createFederalRegisterVeteransAffairsDepartment,
   CreateFeedItem, CreateFeedItemBillUpdates, CreateFeedItemWithDocButton,
   CreateFeedItemPresidentialDocuments, CreateFeedItemExecutiveOrders, CreateFeedItemCongressionalReports, CreateFeedItemExecutiveOffice, CreateFeedItemBillWithDescription, CreateFeedAPIDepartmentUS } from './Functions'
import ExecutiveBranchMobile from './ExecutiveBranchMobile';
import DesktopNavbar from './DesktopNavbar';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import FederalRegisterJusticeDepartment from './FederalRegisterJusticeDepartment';
import FederalRegisterInteriorDepartment from './FederalRegisterInteriorDepartment';
import FederalRegisterAgricultureDepartment from './FederalRegisterAgricultureDepartment';
import FederalRegisterCommerceDepartment from './FederalRegisterCommerceDepartment';
import FederalRegisterLaborDepartment from './FederalRegisterLaborDepartment';
import FederalRegisterHealthAndHumanServicesDepartment from './FederalRegisterHealthAndHumanServicesDepartment';
import FederalRegisterHousingAndUrbanDevelopmentDepartment from './FederalRegisterHousingAndUrbanDevelopmentDepartment';
import FederalRegisterVeteransAffairsDepartment from './FederalRegisterVeteransAffairsDepartment';
import FederalRegisterHomelandSecurityDepartment from './FederalRegisterHomelandSecurityDepartment';
import FederalRegisterEducationDepartment from './FederalRegisterEducationDepartment';
import CouncilOfEconomicAdvisers from './CouncilOfEconomicAdvisers';
import CouncilOnEnvironmentQuality from './CouncilOnEnvironmentQuality';
import DomesticPolicyCouncil from './DomesticPolicyCouncil';
import GenderPolicyCouncil from './GenderPolicyCouncil';
import NationalEconomicCouncil from './NationalEconomicCouncil';
import NationalSecurityCouncil from './NationalSecurityCouncil';
import OfficeOfIntergovernmentalAffairs from './OfficeOfIntergovernmentalAffairs';
import OfficeOfManagmentAndBudget from './OfficeOfManagmentAndBudget';
import OfficeOfNationalDrugControlPolicy from './OfficeOfNationalDrugControlPolicy';
import OfficeOfPublicEngagement from './OfficeOfPublicEngagement';
import OfficeOfScienceAndTechnologyPolicy from './OfficeOfScienceAndTechnologyPolicy';
import OfficeOfNationalCyberDirector from './OfficeOfNationalCyberDirector';
import PresidentialPersonnelOffice from './PresidentialPersonnelOffice';
import ExecutiveOfficeMobile from './ExecutiveOfficeMobile';
import ArmyDocuments from './ArmyDocuments';
import MarinesPressReleases from './MarinesPressReleases';
import NavyDocuments from './NavyDocuments';
import SpaceForceNews from './SpaceForceNews';
import NationalGuardNews from './NationalGuardNews';
import NSADocuments from './NSADocuments';
import ArmyCorpsEngineers from './ArmyCorpsEngineers';
import DefenseMobile from './DefenseMoible';
import HomelandSecurityDocuments from './HomelandSecurityDocuments';
import CoastGuardDocuments from './CoastGuardDocuments';
import FEMADocuments from './FEMADocuments';
import SecretService from './SecretService';
import TSADocuments from './TSADocuments';
import CitizenshipAndImmigrationDocuments from './CitizenshipAndImmigrationDocuments';
import CustomsAndBorderDocs from './CustomsAndBorderDocs';
import CustomsEnforcementDocs from './CustomsEnforcementDocs';
import HomelandSecurityMobile from './HomelandSecurityMobile';
import DepartmentOfStateMobile from './DepartmentOfStateMobile';
import DoSPress from './DoSPress';
import ArmsControl from './ArmsControl';
import CounterTerrorism from './CounterTerrorism';
import DemocracyHumanRightsLabor from './DemocracyHumanRightsLabor';
import DiplomaticSecurity from './DiplomaticSecurity';
import DepartmentOfJusticeDocs from './DepartmentOfJusticeDocs';
import AlcoholTobaccoFireArms from './AlcoholTobaccoFireArms';
import AntitrustDivision from './AntitrustDivision';
import DrugEnforcementAdministration from './DrugEnforcementAdministration';
import ExecutiveOfficeForImmigrationReview from './ExecutiveOfficeForImmigrationReview';
import FederalBureauOfInvestigation from './FederalBureauOfInvestigation';
import UnitedStatesMarshalsService from './UnitedStatesMarshalsService';
import ParoleCommission from './ParoleCommission';
import PrisonBureau from './PrisonBureau';
import NationalInstituteOfJustice from './NationalInstituteOfJustice';
import NationalInstituteOfCorrections from './NationalInstituteOfCorrections';
import JuvenileJusticeAndDellinquencyPreventionOfficee from './JuvenileJusticeAndDellinquencyPreventionOffice';
import JusticeProgramsOffice from './JusticeProgramsOffice';
import ForeignClaimsSettlement from './ForeignClaimsSettlement';
import JusticeMobile from './JusticeMobile';
import FederalAviationAdministration from './FederalAviationAdministration';
import CommercialSpaceTransportationOffice from './CommercialSpaceTransportationOffice';
import FederalHighwayAdmin from './FederalHighwayAdmin';
import FederalMotorCarrierSafetyAdmin from './FederalMotorCarrierSafetyAdmin';
import FederalRailroadAdmin from './FederalRailroadAdmin';
import FederalTransitAdmin from './FederalTransitAdmin';
import MaritimeAdmin from './MaritimeAdmin';
import NationalHighwayTrafficSafetyAdmin from './NationalHighwayTrafficSafetyAdmin';
import PipelineAndHazardousMaterialsSafetyAdmin from './PipelineAndHazardousMaterialsSafetyAdmin';
import TransportationStatsBureau from './TransportationStatsBureau';
import GreatLakesStLawerenceSeaway from './GreatLakesStLawerenceSeaway';
import TransportationMobile from './TransportationMobile';
import DepartmentOfTransportationDocs from './DepartmentOfTransportationDocs';
import GenerateFeedDepartment from './GenerateFeedDepartment';





var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;





  

 


  


export const DepartmentOfTransportationDesk = () =>
 {
   render()
   
   {
    const [show, setShow] = useState(true);

        
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
     
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
      
      
        const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

        const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
        const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
        const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
        const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
        const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
        const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
        const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
        const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
        const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
        const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
        const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
        const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)

        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
        const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
        const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
        const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
        const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
        const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
        const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
        const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
        const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
        const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)
        const [transportationModalIsOpen, setTransportationModalIsOpen] = useState(false)
        const [showBioModal, setBioModal] = useState(false);
        const [showMissionModal, setMissionModal] = useState(false);

  return(
    <>
      <BrowserView>
        <div id='homePage'>

     

        <div id='homebg'>

          <DesktopNavbar/>
          <div id='feedDisplay' 
                  style={{ padding: '20px', borderRadius: '18px', marginTop: '0em'}}>

<div style={{ position: 'relative' }}>

     <div id='profileImage' style={{ width: '130px', height: '130px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '60px' }}>
     <Image id='profileImage-image' src='/departmentOfTransportation.png' />
   </div>


   <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
   <div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Department of Transportation </h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '16px', width: '80%' }}>
<span> The mission of the Department of Transportation (DOT) is to ensure a fast, safe, efficient, accessible and convenient transportation system that meets our vital national interests and enhances the quality of life of the American people.</span>
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

       <span><Link style={{color: '#21cff5'}} onClick={(e) => {
          e.preventDefault();
          window.open("https://www.transportation.gov/", "_blank");
        }}>
          https://www.transportation.gov/
          </Link></span> 
          <br></br>
<Button id='whDocsViewMoreButton' onClick={() =>  setMissionModal(true)}>  <Files size={20}></Files> Read More</Button>
</div>
  </div>

     </div>
     </div>


     </div>

           </div>


           <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showMissionModal} onHide={() => setMissionModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> Department of Transporation</h3></Modal.Header>
       
         <div id='fullBillFeed' >
         <p>The mission of the Department of Transportation (DOT) is to ensure a fast, safe, efficient, accessible, and convenient transportation system that meets our vital national interests and enhances the quality of life of the American people.</p>

<p>Organizations within the DOT include the Federal Highway Administration, the Federal Aviation Administration, the National Highway Traffic Safety Administration, the Federal Transit Administration, the Federal Railroad Administration, and the Maritime Administration.</p>

<p>The U.S. Secretary of Transportation oversees nearly 55,000 employees and a budget of approximately $70 billion.</p>


<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=approximately%20%2435%20billion.-,DEPARTMENT%20OF%20TRANSPORTATION,-The%20mission%20of", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=approximately%20%2435%20billion.-,DEPARTMENT%20OF%20TRANSPORTATION,-The%20mission%20of
     </Link>
 </div>

       </Modal>
       <br></br><br></br><br></br>
            <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferTwo'>
                   
                    <Row>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                        <h4 id='whBreifingFeedHeading'onClick={() => setDepartmentOfDefenseModalIsOpen(true)}><b>Documents</b></h4>


<div  id='laborDepartment'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=transportation-department', 'laborDepartment', 'Department of Transporation', 'Executive', 'N/A', DepartmentOfTransportationDocs)}

<Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Transporation Documents</h3></Modal.Header>
                <DepartmentOfTransportationDocs/>
              </Modal>
                        </div>
                      </Col>

                      <Col id='legislativeColLeft'>
                        <div id='columnLeftPadding'>
                    
                        </div>
                      </Col>


                    </Row>


                  </Container>
                </Container>
                </div>    
           
                <div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/faa', "_self")}}><b>Federal Aviation Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFaa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Federal%20Aviation%20Administration', 'defFaa',
              'Federal Aviation Administration Documents', 'Executive',
              'Infrastructure', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={transportationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTransportationModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Aviation Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=transportation&agency=Federal%20Aviation%20Administration'
                name='Federal Aviation Administration' branch='Executive' topic='Infrastructure' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/fha', "_self")}}><b>Federal Highway Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFhwa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Federal%20Highway%20Administration', 'defFhwa',
              'Federal Highway Administration Documents', 'Executive',
              'Infrastructure', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={transportationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTransportationModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Highway Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=transportation&agency=Federal%20Highway%20Administration'
                name='Federal Highway Administration' branch='Executive' topic='Infrastructure' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/fmcsa', "_self")}}><b>Federal Motor Carrier Safety Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFmca'></div>
            {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Federal%20Motor%20Carrier%20Safety%20Administration', 'defFmca',
              'Federal Motor Carrier Safety Administration Documents', 'Executive',
              'Infrastructure', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={transportationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTransportationModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Motor Carrier Safety Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=transportation&agency=Federal%20Motor%20Carrier%20Safety%20Administration'
                name='Federal Motor Carrier Safety Administration' branch='Executive' topic='Infrastructure' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/fra', "_self")}}><b>Federal Railroad Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFra'></div>
            {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Federal%20Railroad%20Administration', 'defFra',
              'Federal Railroad Administration Documents', 'Executive',
              'Infrastructure', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={transportationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTransportationModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Railroad Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=transportation&agency=Federal%20Railroad%20Administration'
                name='Federal Railroad Administration' branch='Executive' topic='Infrastructure' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/fta', "_self")}}><b>Federal Transit Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defFta'></div>
            {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Federal%20Transit%20Administration', 'defFta',
              'Federal Transit Administration Documents', 'Executive',
              'Infrastructure', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={transportationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTransportationModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Federal Transit Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=transportation&agency=Federal%20Transit%20Administration'
                name='Federal Transit Administration' branch='Executive' topic='Infrastructure' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/great-lakes', "_self")}}><b>Great Lakes St. Lawrence Seaway Development Corporation</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defGlsl'></div>
            {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Great%20Lakes%20St.%20Lawrence%20Seaway%20Development%20Corporation', 'defGlsl',
              'Great Lakes St. Lawrence Seaway Development Corporation Documents', 'Executive',
              'Infrastructure', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={transportationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTransportationModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Great Lakes St. Lawrence Seaway Development Corporation</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=transportation&agency=Great%20Lakes%20St.%20Lawrence%20Seaway%20Development%20Corporation'
                name='Great Lakes St. Lawrence Seaway Development Corporation' branch='Executive' topic='Infrastructure' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>


              <div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/highway-safety', "_self")}}><b>National Highway Traffic Safety Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defNhtsa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=National%20Highway%20Traffic%20Safety%20Administration', 'defNhtsa',
              'National Highway Traffic Safety Administration Documents', 'Executive',
              'Infrastructure', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={transportationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTransportationModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>National Highway Traffic Safety Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=transportation&agency=National%20Highway%20Traffic%20Safety%20Administration'
                name='National Highway Traffic Safety Administration' branch='Executive' topic='Infrastructure' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/motor-carrier-safetyy', "_self")}}><b>Office of Motor Carrier Safety</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defOmcs'></div>
            {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Office%20of%20Motor%20Carrier%20Safety', 'defOmcs',
              'Office of Motor Carrier Safety Documents', 'Executive',
              'Infrastructure', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={transportationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTransportationModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Office of Motor Carrier Safety</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=transportation&agency=Office%20of%20Motor%20Carrier%20Safety'
                name='Office of Motor Carrier Safety' branch='Executive' topic='Infrastructure' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/pipeline-hazardous-materials', "_self")}}><b>Pipeline and Hazardous Materials Safety Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defPhmsa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Pipeline%20and%20Hazardous%20Materials%20Safety%20Administration', 'defPhmsa',
              'Pipeline and Hazardous Materials Safety Administration Documents', 'Executive',
              'Infrastructure', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={transportationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTransportationModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Pipeline and Hazardous Materials Safety Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=transportation&agency=Pipeline%20and%20Hazardous%20Materials%20Safety%20Administration'
                name='Pipeline and Hazardous Materials Safety Administration' branch='Executive' topic='Infrastructure' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/innovative-technology', "_self")}}><b>Research and Innovative Technology Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defRita'></div>
            {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Research%20and%20Innovative%20Technology%20Administration', 'defRita',
              'Research and Innovative Technology Administration Documents', 'Executive',
              'Infrastructure', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={transportationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTransportationModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Research and Innovative Technology Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=transportation&agency=Research%20and%20Innovative%20Technology%20Administration'
                name='Research and Innovative Technology Administration' branch='Executive' topic='Infrastructure' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/research-special-programs', "_self")}}><b>Research and Special Programs Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defRspa'></div>
            {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Research%20and%20Special%20Programs%20Administration', 'defRspa',
              'Research and Special Programs Administration Documents', 'Executive',
              'Infrastructure', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={transportationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTransportationModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Research and Special Programs Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=transportation&agency=Research%20and%20Special%20Programs%20Administration'
                name='Research and Special Programs Administration' branch='Executive' topic='Infrastructure' buttonName='Doc' />
            </Modal>
          </div>
        </Col>

        <Col id='legislativeColLeft'>
          <div id='columnLeftPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/transportation-statistics', "_self")}}><b>Transportation Statistics Bureau</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defTsb'></div>
            {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Transportation%20Statistics%20Bureau', 'defTsb',
              'Transportation Statistics Bureau Documents', 'Executive',
              'Infrastructure', 'Doc', GenerateFeedDepartment)}
            <Modal id='whiteHouseDocsModal' show={transportationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTransportationModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Transportation Statistics Bureau</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=transportation&agency=Transportation%20Statistics%20Bureau'
                name='Transportation Statistics Bureau' branch='Executive' topic='Infrastructure' buttonName='Doc' />
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
</div>

<div id='branchPage'>
  <Container class='bgcolor'>
    <Container id='carouselBufferTwo'>
      <Row>
        <Col id='legislativeColRight'>
          <div id='columnRightPadding'>
            <h4 id='whBreifingFeedHeading' onClick={(e) => {
              e.preventDefault();
              window.open('/us/maritime', "_self")}}><b>Maritime Administration</b><ChevronRight id='clickChevron'></ChevronRight></h4>
            <div id='defMarad'></div>
            {CreateFeedAPIDepartmentUS('&table_name=transportation&agency=Maritime%20Administration', 'defMarad',
              'Maritime Administration Documents', 'Executive',
              'Infrastructure', 'Doc', GenerateFeedDepartment)}
              <Modal id='whiteHouseDocsModal' show={transportationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTransportationModalIsOpen(false)}>
              <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Maritime Administration</h3></Modal.Header>
              <GenerateFeedDepartment url='&table_name=transportation&agency=Maritime%20Administration'
                           name='Maritime Administration' branch='Executive' topic='Infrastructure' buttonName='Doc' />
              </Modal>
              </div>
              </Col>
              </Row>
              </Container>
              </Container>
              </div>
   

            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
   <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>



</div>
         </div>
          

              </BrowserView>

              <MobileView>

    <TransportationMobile/>

              </MobileView>
              
    </>
  )
}
 }



  






export default DepartmentOfTransportationDesk;