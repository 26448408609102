import React, { useState, } from 'react';
import { Navbar, Nav, Modal, Col, Row, Carousel, ModalBody, Dropdown, Image, Container, Button} from 'react-bootstrap';
import { ShieldShaded, Pen, People, ChevronRight, HeartFill, GeoAlt,} from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import ProvincesNavbar from '../../ProvincesNavbar';
import { CreateFeedItem, CreateFeedItemUKAtom } from '../../Functions';
import EnglandCourt from './EnglandCourt';
import PrimeMinisterNews from '../Executive/PrimeMinisterNews';
import EnglandMobile from './EnglandMobile';
import DesktopNavbar from '../../DesktopNavbar';
import KingdomDesktopNavbar from './KingdomDesktopNavbar';
var carouselInterval = 30000;


export const England = () =>   {
  render()
  {
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
  const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
  return (
    <>
      <div className="App">
        <BrowserView>
      <div id='homePage'>
    
        <div id='homebg'>
        <KingdomDesktopNavbar kingdom='England' kingdomFlag='/englandFlag.png'/>
         <div id='branchesTab'>   
<Row id='branchesRow'>

    {/*Legislative Column */}
  <Col id='homeColumn'>

      <Row id="homePageBranchRow">

        
   
        <h3 id='branchHeading'> <Pen></Pen> <b id='branchHeadingBold'>Legislative</b><ChevronRight id='clickChevron'></ChevronRight></h3>
    </Row>

    {/*Legislative Desktop Carousel*/}
    
  <Carousel id='homeCarousel' controls={false}>
        <Carousel.Item id='legislativeCarousel' interval={carouselInterval} >
          <div id='legislativeCon'>
            <Col id='legislativeColRight'>
              <div id='columnLefttPadding'>
                <h4 id='feedHeadingBillsEnrolled'><b>Coming Soon</b></h4>
              </div>
            </Col>
          </div>
        </Carousel.Item>
        </Carousel>
  </Col>

  <Col id='homeColumn'>
<Row id="homePageBranchRow">
<h3 id='branchHeading' ><People></People> <b id='branchHeadingBold'>Executive</b><ChevronRight id='clickChevron'></ChevronRight></h3>
</Row>
<Carousel id='homeCarousel' controls={false} >
<Carousel.Item id='legislativeCarousel' interval={carouselInterval} >
<div id='legislativeCon'>
<Col id='legislativeColRight'>
<div id='columnLefttPadding'>

<h4 id='feedHeadingBillsEnrolled' onClick={() => setTrendingBillsOptionTenModalIsOpen(true)}><b>Prime Minister</b></h4>
<div id='primeMinister'></div>

{/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
{CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/prime-ministers-office-10-downing-street.atom',
  'primeMinister', 'Prime Minister News', 'Executive', 'N/A', PrimeMinisterNews)}

<Modal id='mobileFullBillModal' show={trendingBillsOptionTen}
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setTrendingBillsOptionTenModalIsOpen(false)}>
  <Modal.Header id='mobileBillModalHeader' closeButton><h3>Prime Minister</h3></Modal.Header>
  <PrimeMinisterNews />
</Modal>
</div>
</Col>
</div>
</Carousel.Item>
</Carousel>
</Col>

<Col id='homeColumn'>


<Row id="homePageBranchRow">
<h3 id='branchHeading'><svg xmlns="http://www.w3.org/2000/svg" height="27.54px" viewBox="0 -960 960 960" width="27.54px" fill="#FFFFFF"><path d="M160-120v-80h480v80H160Zm226-194L160-540l84-86 228 226-86 86Zm254-254L414-796l86-84 226 226-86 86Zm184 408L302-682l56-56 522 522-56 56Z"/></svg><b id='branchHeadingBold'>Judicial</b><ChevronRight id='clickChevron'></ChevronRight></h3>
</Row>
<Carousel id='homeCarousel' controls={false}>
<Carousel.Item id='legislativeCarousel' interval={carouselInterval} >
<div id='legislativeCon'>
<Col id='legislativeColRight'>
<div id='columnLefttPadding'>

<h4 id='feedHeadingBillsEnrolled' onClick={() => setCourtModalIsOpen(true)}><b>HM Court</b></h4>
<div id='engCourt'> {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/hm-courts-and-tribunals-service.atom', 
'engCourt', 'HM Court', 'Judicial', 'N/A', EnglandCourt)}</div>


<Modal id='mobileWhiteHouseFeedModal' show={courtModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCourtModalIsOpen(false)}>
<Modal.Header id='mobileWhiteHouseFeedModalHeader' closeButton><h3>HM Court</h3></Modal.Header>
<EnglandCourt/>
</Modal>
</div>
</Col>
</div>
</Carousel.Item>
</Carousel>
</Col>

      </Row>

      <br></br><br></br><br></br>

      <div id='homebg'>  
                      <Container>
                      <Col>
                      <h3 id='branchHeading' > <GeoAlt/> <b id='branchHeadingBold'> Select a Kingdom</b></h3>
                      <Row id='statesRow'>
                  <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/uk/england', '_self')
                      }}> <Image id='stateFlagMobile'  src='/englandFlag.png'></Image>England</Button>
                        <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/uk/northernIreland', '_self')
                      }}><Image id='stateFlagMobile'  src='/northernIrelandFlag.png'></Image>Northern Ireland</Button>
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/uk/scotland', '_self')
                      }}> <Image id='stateFlagMobile'  src='/scotlandFlag.png'></Image>Scotland</Button>
                       <Button size='sm' id='statesModalButtons'  onClick={()  => {
                        
                        window.open('/uk/wales', '_self')
                      }}> <Image id='stateFlagMobile'  src='/walesFlag.png'></Image>Wales</Button>
                      </Row>
               
                
   
                     </Col>
                     </Container>
                     </div>

</div>
     
          <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance was created to drive civic engagement and support a 
              healthier democracy by helping citizens get official Government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official Government 
              sources. Gov Glance is structured after the U.S. government that is broken into three branches Legislative, 
              Executive, and Judicial. To learn more about the structure and functions of The U.S. Government go to <a href='https://www.usa.gov/branches-of-government'>https://www.usa.gov/branches-of-government</a>.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit that was created to operate govglance.org and continue to create educational tools that help people learn about the workings of society
              </p>
            <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>
 
   <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>

          </div>
  

    </div>
      </BrowserView>
     

      <MobileView> 
        
        
                      <EnglandMobile/>

     
      </MobileView>
        </div>
      


       
    </>

     
    
  );







}
}


export default England;
