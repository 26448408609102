import App from "./App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, FolderPlus } from 'react-bootstrap-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';






function AddNoteModal()
 {

  var collectionCounter = localStorage.getItem('collectionNumber')
  var noteModal = true 

function saveNote() {
  var searchItem = document.getElementById('noteModalFeedItem').value


 var indexStore = localStorage.getItem('collectionNumber')
 var itemNote = 'itemNote'.concat(collectionCounter)
 var itemDescription = 'itemDescription'.concat(collectionCounter)



 localStorage.setItem(itemNote,searchItem)

 window.location.reload()
//window.location.reload()

 //Override note value null -> "input"







}



  return(
    <>
    
<Modal id='fullBillModal' show={noteModal} size="xl" aria-labelledby="contained-modal-title-vcenter"centered onHide={noteModal} >
                         <Modal.Header id='billModalHeader'   closeButton    onClick={() => { noteModal = false; window.location.reload() }}  ><h3>{localStorage.getItem('title'.concat(collectionCounter))}</h3></Modal.Header>
<div id='fullBill'>


                         <Form id='noteModalForm'>
        
         <Form.Group id='noteModalForm'  className="mb-3" >

         <Card.Body id='billBodyText' >{localStorage.getItem('itemDescription'.concat(collectionCounter))}</Card.Body>
          


         <Card.Body id='billBodyText' >{localStorage.getItem('itemDate'.concat(collectionCounter))}</Card.Body>
          


           <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
             type="text"
             as={"textarea"}
             rows={2}
             
             />

             <br></br>

           


         </Form.Group>
         </Form>

       <Modal.Footer id='addNoteModalFooter'> 

         <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => saveNote(localStorage.getItem('itemDate'.concat(collectionCounter)))  } >
             Save To Collection
           </Button>
           </Modal.Footer> 
           </div>


                       </Modal>


    </>
  )



  
}





export default AddNoteModal;