import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, Link45deg } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';

import DepartmentOfJustice from './DepartmentOfJustice';

import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
    createWhiteHouseFeedMobile,createDepartmentOfState,
    createDepartmentOfDefense, 
    createDepartmentOfJustice, createDepartmentOfTheInterior,
    createDepartmentOfAgriculture, createDepartmentOfCommerce,
    createDepartmentOfCommerceMobile, createDepartmentOfLabor,
    createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
    ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
    , createEnvironmentalProtectionAgency,
    createDepartmentOfTreasury, createNationalScienceFoundation, 
     createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, 
     createFederalRegisterStateDepartment, createFederalRegisterDefense,
    CreateFeedItem, CreateFeedItemPresidentialDocuments, CreateExecutiveOrders,
    CreateFeedItemExecutiveOrders,
    CreateFeedItemBillUpdates,
    CreateFeedItemExecutiveOffice,
    CreateFeedItemBillWithDescription,
    CreateFeedAPI,
    CreateFeedAPIDepartmentUS,  } from './Functions'
import NationalScienceFoundation from './NationalScienceFoundation';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';

import DepartmentOfJusticeDocs from './DepartmentOfJusticeDocs';
import AlcoholTobaccoFireArms from './AlcoholTobaccoFireArms';
import AntitrustDivision from './AntitrustDivision';
import DrugEnforcementAdministration from './DrugEnforcementAdministration';
import ExecutiveOfficeForImmigrationReview from './ExecutiveOfficeForImmigrationReview';
import FederalBureauOfInvestigation from './FederalBureauOfInvestigation';
import ForeignClaimsSettlement from './ForeignClaimsSettlement';
import JusticeProgramsOffice from './JusticeProgramsOffice';
import JuvenileJusticeAndDellinquencyPreventionOfficee from './JuvenileJusticeAndDellinquencyPreventionOffice';
import NationalInstituteOfCorrections from './NationalInstituteOfCorrections';
import NationalInstituteOfJustice from './NationalInstituteOfJustice';
import ParoleCommission from './ParoleCommission';
import PrisonBureau from './PrisonBureau';
import UnitedStatesMarshalsService from './UnitedStatesMarshalsService';
import GenerateFeed from './GenerateFeed';
import DepartmentOfDefense from './DepartmentOfDefense';
import GenerateFeedDepartment from './GenerateFeedDepartment';



var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 30000;



  

 


  

export const JusticeMobile = () =>   {
    render()
    
    {
        const [show, setShow] = useState(true);

        
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
     
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
      
      
        const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

        const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
        const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
        const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
        const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
        const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
        const [securityModalIsOpen, setSecurityModalIsOpen] = useState(false)
        const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
        const [drugModalIsOpen, setDrugModalIsOpen] = useState(false)
        const [publicModalIsOpen, setPublicModalIsOpen] = useState(false)
        const [scienceModalIsOpen, setScienceModalIsOpen] = useState(false)
        const [cyberModalIsOpen, setCyberModalIsOpen] = useState(false)
        const [presidentialModalIsOpen, setPresidentialModalIsOpen] = useState(false)

        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
        const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
        const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
        const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
        const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
        const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
        const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
        const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
        const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)
        const [armyCorpsModalIsOpen, setArmyCorpsModalIsOpen] = useState(false)
        const [showBioModal, setBioModal] = useState(false);
        const [modalInfo, setModalInfo] = useState({
          isOpen: false,
          title: '',
          content: null
        });
      
        const openModal = (title, content) => {
          setModalInfo({
            isOpen: true,
            title,
            content
          });
        };
      
        const closeModal = () => {
          setModalInfo({
            isOpen: false,
            title: '',
            content: null
          });
        };

        var mobileCarouselInterval = 60000;
        const CustomModal = ({ isOpen, onClose, title, content }) => (
          <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
            <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
            {content}
          </Modal>
        );
    var branch = "all";
  
    return(
       <>
   <MobileTopNavbar/>
  
  
  
  
  <div id='homebg'>

               
  
  <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div id="profileImageOnPageDepartment">
                <Image id="profileImage-imageOnPageDepartment" src="/departmentOfJustice.png" />
              </div>
              <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                <h5 id="branchHeadingMobile" > Department of Justice</h5>
              </div>
            </div>
            <Dropdown drop="down-centered">
              <Dropdown.Toggle id="searchCountry"><Link45deg /></Dropdown.Toggle>
              <Dropdown.Menu id="docsDropdown" drop="down-centered">
                <Dropdown.Item id="docsDropdownItem" onClick={(e) => { e.preventDefault(); window.open("https://www.justice.gov/", "_blank") }}>
                  Official Site
                </Dropdown.Item>

                <Dropdown.Item id="docsDropdownItem" onClick={(e) => { e.preventDefault(); window.open("https://www.govinfo.gov/content/pkg/STATUTE-16/pdf/STATUTE-16-Pg162.pdf", "_blank") }}>
                  Establishing Act
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
          
          The mission of the Department of Justice (DOJ) is to enforce the law and defend the interests of the United States according to the law; to ensure public safety against threats foreign and domestic; to provide federal leadership in preventing and controlling crime; to seek just punishment for those guilty of unlawful behavior; and to ensure fair and impartial administration of justice for all Americans.
          </div>

          {/* <div style={{height: '20px',}}></div>
          <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
          <b>Establishing Act</b>
          </div>
          <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
         
          Be it enacted by the Senate and House of Representatives of the United
States of America in Congress assembled, That there shall be, and is hereby,
established an executive ...
          </div>
<Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
        </div>
  
            
        <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> Establishing Act</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>Section 1. Be it enacted by the Senate and House of Representatives of the United
States of America in Congress assembled, That there shall be, and is hereby,
established an executive department of the government of the United
States, to be called the Department of Justice, of which the AttorneyGeneral shall be the head. His duties, salary, and tenure of office shall
remain as now fixed by law, except so far as they may be modified by
this act. </p>

<p>Sec. 2. And be it further enacted, That there shall be in said Department an officer learned in the law, to assist the Attorney-General in the
performance of his duties, to be called the solicitor-general, and who,
in case of a vacancy in the office of Attorney-General, or in his absence
or disability, shall have power to exercise all the duties of that office.
There shall also be continued in said Department the two other officers,
learned in the law, called the assistants of the Attorney-General, whose
duty it shall be to assist the Attorney-General and solicitor-general in
the performance of their duties, as now required by law.</p>

<p>Sec. 3. And be it further enacted, That from and after the time when
this act takes effect, the solicitor of the treasury and His assistants, the
solicitor of internal revenue, the solicitor and naval judge advocate general, who shall hereafter be known as the naval solicitor, and the clerks,
messengers, and laborers employed in the office of the Attorney-General,
and in the offices of the solicitor of the treasury, naval solicitor, and solicitor of internal revenue, and the law officer in the Department of State,
now designated as the examiner of claims in said Department, shall be
transferred from the Departments with which they are now associated to
the Department of Justice ; and said officers shall exercise their functions under the supervision and control of the head of the Department
of Justice.</p>


<p>Sec. 4. And be it further enacted, That questions of law submitted to
the Attorney-General for his opinion, except questions involving a construction of the Constitution of the United States, may be by him referred to such of his subordinates as he may deem appropriate, and he
may require the written opinion thereon of the officer to whom the same
may be referred ; and if the opinion given by such officer shall be approved by the Attorney-General, such approval so indorsed thereon shall
give the opinion the same force and effect as belong to the opinions of the
Attorney-General.</p>

<p>Sec 5. And be it further enacted, That whenever the Attorney-General deems it necessary, he may require the solicitor-general to argue
any case in which the government is interested before the court of
claims; and as to cases coming by appeal from the court of claims to the Supreme Court of the United States, it shall be the duty of the
Attorney-General and solicitor-general to conduct and argue them before that court as in other cases in which the United States is interested.
And the Attorney-General may, whenever he deems it for the interest
of the United States, conduct and argue any case in which the government is interested, in any court of the United States, or may require the
solicitor-general or any officer of his Department to. do so. And the
solicitor-general, or any officer of the Department of Justice, may be
sent by the Attorney-General to any State or district in the United States
to attend to the interests of the United States in any suit pending in any
of the courts of the United States, or in the courts of any State, or to
attend to any other interest of the United States ; for which service they
shall receive, in addition to their salaries, their actual and necessary expenses, while so absent from the seat of government, the account thereof
to be verified by affidavit</p>

<p>Sec 6. And be it further enacted, That whenever a question of law
arises in the administration, either of the War or Navy Department, the
cognizance of which is not given by statute to some other officer from
whom the head of either of these Departments may require advice, the
same shall be sent to the Attorney-General, to be by him referred to the
proper officer in his Department provided for in this act, or otherwise
disposed of as he may deem proper; and each head of any Department
of the government may require the opinion of the Attorney-General on
all questions of law arising in the administration of their respective Departments;</p>

<p>Sec. 7. And be it further enacted, That the duties enjoined upon the
auditor of the Post-Office Department by the fourteenth section of the
act entitled “ An act to change the organization of the Post-Office Department, and to provide more effectually for the settlement of the accounts thereof,” passed July two, eighteen hundred and thirty-six, shall
hereafter be performed by some officer of the Department of Justice, to
Attorney-General, who
depredations and penal
offenses against the postal laws.
</p>

<p>
Sec. 8. And be it further enacted, That the Attorney-General is hereby empowered to make all necessary rules and regulations for the government of said Department of Justice, and for the management and distribution of its business.
</p>

<p>
Sec. 9. And be it further enacted, That the several officers hereinbefore
transferred from the other Departments to the Department of Justice shall
hold their respective offices until their successors are duly qualified; and
the solicitor-general, and whenever vacancies occur, the assistants of the
Attorney-General, and all the solicitors and assistant solicitors mentioned
in this act, shall be appointed by the President, by and with the advice
and consent of the Senate. All the other officers, clerks, and employees
in the said Department shall be appointed and be removable by the Attorney-General.
</p>

<p>
Sec. 10. And be it further enacted, That the following annual salaries
shall be paid to the officers hereinbefore mentioned : To the solicitorgeneral, seven thousand five hundred dollars; to each of the assistants
of the Attorney-General, five thousand dollars each ; to the solicitor of
the internal revenue, five thousand dollars ; and to the other officers the
salaries and fees now allowed by law ; and the Attorney-General shall
be allowed a stenographic clerk, with an annual salary of two thousand
dollars, and he may appoint three additional clerks of the fourth class.
</p>

<p>
Sec. 11. And be it further enacted, That all moneys hereafter drawn
out of the treasury upon the requisition of the Attorney-General, shall
be disbursed by such one of the clerks herein provided for the Attorney General as he may designate; and so much of the first section of the act making appropriations, passed March three, eighteen hundred and
fifty-nine, as provides that moneys drawn out of the treasury upon the
requisition of the Attorney-General shad be disbursed by such disbursing officer as the Secretary of the Treasury may designate, is hereby repealed.
</p>

<p>
Sec. 12. And be it further enacted, That it shall be the duty of the
Attorney-General to make an annual report to Congress, in January
each year, of the business of the said Department of Justice, and any
other matters appertaining thereto that he may deem proper, including
the statistics of crime under the laws of the United States, and, as far as
practicable, under the laws of the several States.
</p>

<p>
Sec. 13. And be it further enacted, That the superintendent of the
treasury building shall provide such suitable rooms in the treasury building as may be necessary to accommodate the officers and clerks of the
said Department, or, to the extent that that may be found impracticable, to
provide such rooms in some other building in the vicinity of said treasury
building.</p>

<p>Sec. 14. And be it further enacted, That the Attorney-General may
require any solicitor or officers of the Department of Justice to perform
any duty required of said Department or any officer thereof; and the
officers of the law department, under the direction of the Attorney-General, shall give all opinions and render all services requiring the skill of
persons learned in the law, necessary to enable the President and heads
of the executive Departments, and the heads of bureaus and other officers
in such Departments to discharge their respective duties; and shall, for
and on behalf of the United States, procure the proper evidence for, and
conduct, prosecute, or defend all suits and proceedings in the Supreme
Court of the United States and in the court of claims, in which the
United States, or any officer thereof, is a party or may be interested.
And no fees shall be allowed or paid to any other attorney or counsel[l]or
at law for any service herein required of the officers of the Department
of Justice.</p>

<p>Sec. 15. And be it further enacted, That the supervisory powers now
exercised by the Secretary of the Interior over the accounts of the district
attorneys, marshals, clerks, and other officers of the courts of the United
States, shall be exercised by the Attorney-General, who shall sign all
requisitions for the advance or payment of moneys out of the treasury, on
estimates or accounts, subject to the same control now exercised on like
estimates or accounts by the first auditor or first comptroller of the treasury.
</p>

<p>Sec. 16. And be it further enacted, That the Attorney-General shall
have supervision of the conduct and proceedings of the various attorneys
for the United States in the respective judicial districts, who shall make
report to him of their proceedings, and also of all other attorneys and
counsel [l]ors employed in any cases or business in which the United States
may be concerned.
</p>
<p>
Sec. 17. And be it further enacted, That it shall not be lawful for the
Secretary of either of the executive Departments to employ attorneys or
counsel at the expense of the United States ; but such Departments, when
in need of counsel or advice, shall call upon the Department of Justice,
the officers of which shall attend to the same; and no counsel or attorney
fees shall hereafter be allowed to any person or persons, besides the
respective district attorneys and assistant district attorneys, for services in
such capacity to the United States, or any branch or department of the
government thereof, unless hereafter authorized by law, and then only on
the certificate of the Attorney-General that such services were actually
rendered, and that the same could not be performed by the AttorneyGeneral, or solicitor-general, or the officers of the department of justice,
 or by the district attorneys. And every attorney and counsel[l]or who shall be specially retained, under the authority of the Department of
Justice, to assist in the trial of any case in which the government is
interested, shall receive a commission from the head of said Department,
as a special assistant to the Attorney-General, or to some one of the
district attorneys, as the nature of the appointment may require, and shall
take the oath required by law to be taken by the district attorneys, and
shall be subject to all the liabilities imposed upon such officers by law.
</p>

<p>
Sec. 18. And be it further enacted, That the Attorney-General shall
from time to time cause to be edited and printed an edition of one thousand copies, at the government printing office, of such of the opinions of
the law officers herein authorized to be given as he may deem valuable for
preservation, in volumes which shall be as to the size, quality of paper,
printing, and binding, of uniform style and appearance, as nearly as practicable, with the eighth volume of said opinions, published by Robert
Farnham, in the year eighteen hundred and sixty-eight, which volumes
shall contain proper head-notes, a complete and full index, and such footnotes as the Attorney-General may approve. Such volumes shall be
distributed in such manner as the Attorney-General may from time to
time prescribe.
</p>

<p>Sec. 19. And be it further enacted, That this act shall take effect and
be in force from and after the first day of July, eighteen hundred and
seventy.
Approved , June 22, 1870.</p>

<Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open("https://www.govinfo.gov/content/pkg/STATUTE-16/pdf/STATUTE-16-Pg162.pdf"); }}>
          <FileText></FileText> View Document
        </Button>

 </div>

       </Modal> */}
       </div>
       <Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>


       
<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
<Modal.Header id='billModalHeader' closeButton><h3> Department of Justice</h3></Modal.Header>
       
       <div id='fullBillFeed' >
       <p>The mission of the Department of Justice (DOJ) is to enforce the law and defend the interests of the United States according to the law; to ensure public safety against threats foreign and domestic; to provide federal leadership in preventing and controlling crime; to seek just punishment for those guilty of unlawful behavior; and to ensure fair and impartial administration of justice for all Americans.</p>

<p>The DOJ is comprised of 40 component organizations, including the Drug Enforcement Administration, the Federal Bureau of Investigation, the U.S. Marshals Service, and the Federal Bureau of Prisons. The Attorney General is the head of the DOJ and chief law enforcement officer of the federal government. The Attorney General represents the United States in legal matters, advises the President and the heads of the executive departments of the government, and occasionally appears in person before the Supreme Court.</p>

<p>With a budget of approximately $25 billion, the DOJ is the world’s largest law office and the central agency for the enforcement of federal laws.</p>

<br></br>
<Link onClick={(e) => {
   e.preventDefault();
   window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=and%20land%20sales.-,DEPARTMENT%20OF%20JUSTICE,-The%20mission%20of", "_blank");
 }}>
   https://www.whitehouse.gov/about-the-white-house/our-government/the-executive-branch/#:~:text=and%20land%20sales.-,DEPARTMENT%20OF%20JUSTICE,-The%20mission%20of
   </Link>
</div>

     </Modal>
       
       
<CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />


 
            <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfDefenseModalIsOpen(true)}>News</h5>




  <div class='courtNewsMobile' id='justiceNews'></div>


  {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=justice&order_by=created_at', 'justiceNews', 
                        'Department of Justice News', 'Executive', 
                        'N/A', 'Document', GenerateFeed)}

<Modal id='whiteHouseDocsModal' show={departmentOfDefenseModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfDefenseModalIsOpen(false)}>
                <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Justice News</h3></Modal.Header>
                <GenerateFeed
                                url='&skip=0&schema=united_states_of_america&table=justice&order_by=created_at'
                                name='Department of Justice News' branch='Executive' topic='N/A' buttonName='Document' />
              </Modal>

  </Col>


  <Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDefenseDocumentsModalIsOpen(true)}>Documents</h5>


  <div class='courtNewsMobile' id='frDefense'></div>

  {CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=justice-department', 'frDefense', 'Department of Justice Documents', 'Executive', 'N/A', DepartmentOfJusticeDocs)}

</Col>
              
                <Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
                  <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Justice Documents</h3></Modal.Header>
                  <DepartmentOfJusticeDocs/>
                </Modal>


                <Col>
  <Link  id='pageLink' to="/us/atf">
    <h5 id='presidentialFeedHeadingMobile'>Alcohol, Tobacco, Firearms, and Explosives Bureau<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Alcohol, Tobacco, Firearms, and Explosives Bureau',
                <GenerateFeedDepartment url='&table_name=justice&agency=Alcohol%20Tobacco%20Firearms%20and%20Explosives%20Bureau'
                  name='Alcohol, Tobacco, Firearms, and Explosives Bureau' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defAtf'></div>
          {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Alcohol%20Tobacco%20Firearms%20and%20Explosives%20Bureau', 'defAtf',
            'Alcohol, Tobacco, Firearms, and Explosives Bureau Documents', 'Executive',
            'Justice', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/atf' >View all Alcohol, Tobacco, Firearms, and Explosives Bureau news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/antitrust-division">
    <h5 id='presidentialFeedHeadingMobile'>Antitrust Division<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Antitrust Division',
                <GenerateFeedDepartment url='&table_name=justice&agency=Antitrust%20Division'
                  name='Antitrust Division' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defAd'></div>
          {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Antitrust%20Division', 'defAd',
            'Antitrust Division Documents', 'Executive',
            'Justice', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/antitrust-division' >View all Antitrust Division news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/dea">
    <h5 id='presidentialFeedHeadingMobile'>Drug Enforcement Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Drug Enforcement Administration',
                <GenerateFeedDepartment url='&table_name=justice&agency=Drug%20Enforcement%20Administration'
                  name='Drug Enforcement Administration' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defDea'></div>
          {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Drug%20Enforcement%20Administration', 'defDea',
            'Drug Enforcement Administration Documents', 'Executive',
            'Justice', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/dea' >View all Drug Enforcement Administration news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/immigration-review">
    <h5 id='presidentialFeedHeadingMobile'>Executive Office for Immigration Review<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Executive Office for Immigration Review',
                <GenerateFeedDepartment url='&table_name=justice&agency=Executive%20Office%20for%20Immigration%20Review'
                  name='Executive Office for Immigration Review' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defEoir'></div>
          {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Executive%20Office%20for%20Immigration%20Review', 'defEoir',
            'Executive Office for Immigration Review Documents', 'Executive',
            'Justice', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/immigration-review' >View all Executive Office for Immigration Review news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/fbi">
    <h5 id='presidentialFeedHeadingMobile'>Federal Bureau of Investigation<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Federal Bureau of Investigation',
                <GenerateFeedDepartment url='&table_name=justice&agency=Federal%20Bureau%20of%20Investigation'
                  name='Federal Bureau of Investigation' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFbi'></div>
          {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Federal%20Bureau%20of%20Investigation', 'defFbi',
            'Federal Bureau of Investigation Documents', 'Executive',
            'Justice', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/fbi' >View all Federal Bureau of Investigation news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/federal-prison-industries">
    <h5 id='presidentialFeedHeadingMobile'>Federal Prison Industries<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Federal Prison Industries',
                <GenerateFeedDepartment url='&table_name=justice&agency=Federal%20Prison%20Industries'
                  name='Federal Prison Industries' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFpi'></div>
          {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Federal%20Prison%20Industries', 'defFpi',
            'Federal Prison Industries Documents', 'Executive',
            'Justice', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/federal-prison-industries' >View all Federal Prison Industries news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/foreign-claims">
    <h5 id='presidentialFeedHeadingMobile'>Foreign Claims Settlement Commission<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Foreign Claims Settlement Commission',
                <GenerateFeedDepartment url='&table_name=justice&agency=Foreign%20Claims%20Settlement%20Commission'
                  name='Foreign Claims Settlement Commission' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defFcsc'></div>
          {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Foreign%20Claims%20Settlement%20Commission', 'defFcsc',
            'Foreign Claims Settlement Commission Documents', 'Executive',
            'Justice', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/foreign-claims' >View all Foreign Claims Settlement Commission news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/immigration-naturalization">
    <h5 id='presidentialFeedHeadingMobile'>Immigration and Naturalization Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Immigration and Naturalization Service',
                <GenerateFeedDepartment url='&table_name=justice&agency=Immigration%20and%20Naturalization%20Service'
                  name='Immigration and Naturalization Service' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defIns'></div>
          {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Immigration%20and%20Naturalization%20Service', 'defIns',
            'Immigration and Naturalization Service Documents', 'Executive',
            'Justice', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/immigration-naturalization' >View all Immigration and Naturalization Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/justice-programs">
    <h5 id='presidentialFeedHeadingMobile'>Justice Programs Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Justice Programs Office',
                <GenerateFeedDepartment url='&table_name=justice&agency=Justice%20Programs%20Office'
                  name='Justice Programs Office' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defJpo'></div>
          {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Justice%20Programs%20Office', 'defJpo',
            'Justice Programs Office Documents', 'Executive',
            'Justice', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/justice-programs' >View all Justice Programs Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/juvenile-justice">
    <h5 id='presidentialFeedHeadingMobile'>Juvenile Justice and Delinquency Prevention Office<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Juvenile Justice and Delinquency Prevention Office',
                <GenerateFeedDepartment url='&table_name=justice&agency=Juvenile%20Justice%20and%20Delinquency%20Prevention%20Office'
                  name='Juvenile Justice and Delinquency Prevention Office' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defJjdpo'></div>
          {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Juvenile%20Justice%20and%20Delinquency%20Prevention%20Office', 'defJjdpo',
            'Juvenile Justice and Delinquency Prevention Office Documents', 'Executive',
            'Justice', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/juvenile-justice' >View all Juvenile Justice and Delinquency Prevention Office news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/national-institute-corrections">
    <h5 id='presidentialFeedHeadingMobile'>National Institute of Corrections<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('National Institute of Corrections',
                <GenerateFeedDepartment url='&table_name=justice&agency=National%20Institute%20of%20Corrections'
                  name='National Institute of Corrections' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defNic'></div>
          {CreateFeedAPIDepartmentUS('&table_name=justice&agency=National%20Institute%20of%20Corrections', 'defNic',
            'National Institute of Corrections Documents', 'Executive',
            'Justice', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/national-institute-corrections' >View all National Institute of Corrections news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/national-institute-justice">
    <h5 id='presidentialFeedHeadingMobile'>National Institute of Justice<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('National Institute of Justice',
                <GenerateFeedDepartment url='&table_name=justice&agency=National%20Institute%20of%20Justice'
                  name='National Institute of Justice' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defNij'></div>
          {CreateFeedAPIDepartmentUS('&table_name=justice&agency=National%20Institute%20of%20Justice', 'defNij',
            'National Institute of Justice Documents', 'Executive',
            'Justice', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/national-institute-justice' >View all National Institute of Justice news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/parole-commission">
    <h5 id='presidentialFeedHeadingMobile'>Parole Commission<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Parole Commission',
                <GenerateFeedDepartment url='&table_name=justice&agency=Parole%20Commission'
                  name='Parole Commission' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defPc'></div>
          {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Parole%20Commission', 'defPc',
            'Parole Commission Documents', 'Executive',
            'Justice', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/parole-commission' >View all Parole Commission news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/prisons-bureau">
    <h5 id='presidentialFeedHeadingMobile'>Prisons Bureau<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('Prisons Bureau',
                <GenerateFeedDepartment url='&table_name=justice&agency=Prisons%20Bureau'
                  name='Prisons Bureau' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defPb'></div>
          {CreateFeedAPIDepartmentUS('&table_name=justice&agency=Prisons%20Bureau', 'defPb',
            'Prisons Bureau Documents', 'Executive',
            'Justice', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/prisons-bureau' >View all Prisons Bureau news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>

<Col>
  <Link  id='pageLink' to="/us/marshals-service">
    <h5 id='presidentialFeedHeadingMobile'>United States Marshals Service<ChevronRight id='clickChevron'></ChevronRight></h5>
  </Link>
  <Carousel controls={false}>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <Row id='mobileFeedHeadingRow'>
            <h5 id='billFeedHeadingMobileHome' onClick={() =>
              openModal('United States Marshals Service',
                <GenerateFeedDepartment url='&table_name=justice&agency=United%20States%20Marshals%20Service'
                  name='United States Marshals Service' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
          </Row>
          <div class='courtNewsMobile' id='defUsms'></div>
          {CreateFeedAPIDepartmentUS('&table_name=justice&agency=United%20States%20Marshals%20Service', 'defUsms',
            'United States Marshals Service Documents', 'Executive',
            'Justice', 'Doc', GenerateFeedDepartment)}
        </Col>
      </div>
    </Carousel.Item>
    <Carousel.Item interval={mobileCarouselInterval}>
      <div id='legislativeConMobile'>
        <Col>
          <div id='viewFullPageButton'>
            <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/marshals-service' >View all United States Marshals Service news</Button>
          </div>
        </Col>
      </div>
    </Carousel.Item>
  </Carousel>
</Col>
<br></br>
          
          
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
      
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
          </div>

          </>
  )
}
 }



  






export default JusticeMobile;

