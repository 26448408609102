import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Axios from 'axios'
import { render } from '@testing-library/react';
import FullBillFeed from './FullBillFeed';
import FullWhiteHouseFeed from './FullWhiteHouseFeed';
import FullUSCourtNews from './FullUSCourtNews';
import FullWhiteHouseDocuments from './FullWhiteHouseDocuments';
import DailyDigest from './DailyDigest';
import State from './State';
import Illinois from './state/Illinois';
import CollectionPage from './CollectionPage';
import NewLaws from './NewLaws';
import BillsEnrolled from './BillsEnrolled';
import DepartmentOfState from './DepartmentOfState';
import DepartmentOfTreasury from './DepartmentOfTreasury';
import CourtAppeals from './CourtAppeals';
import Reports from './Reports';
import DepartmentOfDefense from './DepartmentOfDefense';
import DepartmentOfJustice from './DepartmentOfJustice';
import DepartmentOfTheInterior from './DepartmentOfTheInterior';
import DepartmentOfAgriculture from './DepartmentOfAgriculture';
import DepartmentOfCommerce from './DepartmentOfCommerce';
import DepartmentOfLabor from './DepartmentOfLabor';
import DepartmentOfHealthAndHumanServices from './DepartmentOfHealthAndHumanServices';
import DepartmentOfHousingAndUrbanDevelopment from './DepartmentOfHousingAndUrbanDevelopment';
import DepartmentOfTransportation from './DepartmentOfTransportation';
import DepartmentOfEnergy from './DepartmentOfEnergy';
import DepartmentOfEducation from './DepartmentOfEducation';
import DepartmentOfVeteranAffairs from './DepartmentOfVeteranAffairs';
import DepartmentOfHomelandSecurity from './DepartmentOfHomelandSecurity';
import EnvironmentalProtectionAgency from './EnvironmentalProtectionAgency';
import NationalAeronauticsAndSpaceAdministration from './NationalAeronauticsAndSpaceAdministration';
import TrendingBills from './TrendingBills';
import GAOReports from './GAOReports';
import LegislativeBranch from './LegislativeBranch';
import HouseComitteeOnAgriculture from './HouseComitteeOnAgriculture';
import HouseCommitteeOnAppropriations from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnOversightAndReform from './HouseCommitteeOnOversightAndReform';

import App from './App';
import Overlay from 'react-overlays/esm/Overlay';
import HouseCommitteeOnArmedServices from './HouseCommitteeOnArmedServices';
import HouseCommitteeOnTheBudget from './HouseCommitteeOnTheBudget';
import HouseCommitteeOnEducationAndLabor from './HouseCommitteeOnEducationAndLabor';
import HouseFloor from './HouseFloor';
import HouseCommitteeOnEnergyAndCommerce from './HouseCommitteeOnEnergyAndCommerce';
import HouseCommitteeOnEthics from './HouseCommitteeOnEthics';
import CongressionalHearings from './CongressionalHearings';
import HouseCommitteeOnFinacialServices from './HouseCommitteeOnFinacialServices';
import HouseCommitteeOnForeignAffairs from './HouseCommitteeOnForeignAffairs';
import HouseCommitteeOnHomelandSecurity from './HouseCommitteeOnHomelandSecurity';
import HouseCommitteeOnTheJudiciary from './HouseCommitteeOnTheJudiciary';
import HouseCommitteeOnNaturalResources from './HouseCommitteeOnNaturalResources';
import HouseCommitteeOnHouseAdministration from './HouseCommitteeOnHouseAdministration';
import CongressionalBudgetOffice from './CongressionalBudgetOffice';
import PresidentialProclamations from './PresidentialProclamations';
import ExecutiveOrders from './ExecutiveOrders';
import{ whiteHouseSearch, createPresidentialProclamations, createExecutiveOrders, createWhiteHouseDocuments, createWhiteHouseFeed,
    createWhiteHouseFeedMobile,createDepartmentOfState,
    createDepartmentOfDefense, 
    createDepartmentOfJustice, createDepartmentOfTheInterior,
    createDepartmentOfAgriculture, createDepartmentOfCommerce,
    createDepartmentOfCommerceMobile, createDepartmentOfLabor,
    createDepartmentOfHealthAndHumanServices, createDepartmentOfHousingAndUrbanDevelopment, createDepartmentOfEducation
    ,  createDepartmentOfVeteransAffairs, createDepartmentOfHomelandSecurity
    , createEnvironmentalProtectionAgency,
    createDepartmentOfTreasury, createNationalScienceFoundation, 
     createEnvironmentalProtectionAgencyMobile, createNationalAeronauticsAndSpaceAdministration, Speak, createFederalRegisterDefenseDepartment, 
     createFederalRegisterStateDepartment, createFederalRegisterDefense,
    CreateFeedItem, CreateFeedItemPresidentialDocuments, CreateExecutiveOrders,
    CreateFeedItemExecutiveOrders,
    CreateFeedItemBillUpdates,
    CreateFeedItemExecutiveOffice, CreateFeedItemMaterials, CreateFeedAPICommittee } from './Functions'
import NationalScienceFoundation from './NationalScienceFoundation';
import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
import FederalRegisterStateDepartment from './FederalRegisterStateDepartment';
import FederalRegisterTreasuryDepartment from './FederalRegisterTreasuryDepartment';
import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import BillUpdates from './BillUpdates';
import CouncilOfEconomicAdvisers from './CouncilOfEconomicAdvisers';
import CouncilOnEnvironmentQuality from './CouncilOnEnvironmentQuality';
import DomesticPolicyCouncil from './DomesticPolicyCouncil';
import GenderPolicyCouncil from './GenderPolicyCouncil';
import NationalEconomicCouncil from './NationalEconomicCouncil';
import NationalSecurityCouncil from './NationalSecurityCouncil';
import OfficeOfIntergovernmentalAffairs from './OfficeOfIntergovernmentalAffairs';
import OfficeOfManagmentAndBudget from './OfficeOfManagmentAndBudget';
import OfficeOfNationalDrugControlPolicy from './OfficeOfNationalDrugControlPolicy';
import OfficeOfPublicEngagement from './OfficeOfPublicEngagement';
import OfficeOfScienceAndTechnologyPolicy from './OfficeOfScienceAndTechnologyPolicy';
import OfficeOfNationalCyberDirector from './OfficeOfNationalCyberDirector';
import PresidentialPersonnelOffice from './PresidentialPersonnelOffice';
import SenateAgricultureNutritionAndForestryCommittee from './SenateAgricultureNutritionAndForestryCommittee';
import SenateAppropriationCommittee from './SenateAppropriationCommittee';
import SenateArmedServicesCommittee from './SenateArmedServicesCommittee';
import SenateBankingHousingUrbanCommittee from './SenateBankingHousingUrbanCommittee';
import SenateCommerceScienceCommittee from './SenateCommerceScienceCommittee';
import SenateEnergyAndNRCommittee from './SenateEnergyAndNRCommittee';
import SenateEnvironmentAndPublicCommittee from './SenateEnvironmentAndPublicCommittee';
import SenateFinanceCommittee from './SenateFinanceCommittee';
import SenateForeignRelationsCommittee from './SenateForeignRelationsCommittee';
import SenateHealthEducationLaborCommittee from './SenateHealthEducationLaborCommittee';
import SenateHomelandSecCommittee from './SenateHomelandSecCommittee';
import SenateIndianAffairsCommittee from './SenateIndianAffairsCommittee';
import SenateRulesAndAdminCommittee from './SenateRulesAndAdminCommittee';
import SenateSmallBusinessCommittee from './SenateSmallBusinessCommittee';
import SenateVeteransAffairsCommittee from './SenateVeteransAffairsCommittee';
import SenateBudgetCommittee from './SenateBudgetCommittee';
import SenateJudiciaryCommittee from './SenateJudiciaryCommittee';
import SenateIntelligenceCommittee from './SenateIntelligenceCommittee';
import SenateFeed from './SenateFeed';
import HouseFeed from './HouseFeed';
import SenateDisplay from './SenateDisplay';
import GenerateFeedCommittee from './GenerateFeedCommittee';



var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 30000;


const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);

  

 


  

export const SenateMobile = () =>   {
    render()
    
    {
        const [show, setShow] = useState(true);

       
        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
      
      
        const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

        const [agricultureModalIsOpen, setAgricultureModalIsOpen] = useState(false)
        const [appropriationsModalIsOpen, setAppropriationsModalIsOpen] = useState(false)
        const [armedServicesModalIsOpen, setArmedServicesModalIsOpen] = useState(false)
        const [bankingModalIsOpen, setBankingsModalIsOpen] = useState(false)
        const [commerceModalIsOpen, setCommerceModalIsOpen] = useState(false)
        const [energyModalIsOpen, setEnergyModalIsOpen] = useState(false)
        const [environmentModalIsOpen, setEnvironmentModalIsOpen] = useState(false)
        const [financeModalIsOpen, setFinanceModalIsOpen] = useState(false)
        const [foreignModalIsOpen, setForeignModalIsOpen] = useState(false)
        const [healthModalIsOpen, setHealthtModalIsOpen] = useState(false)
        const [homelandModalIsOpen, setHomelandModalIsOpen] = useState(false)
        const [indianModalIsOpen, setIndianModalIsOpen] = useState(false)
        const [rulesModalIsOpen, setRulesModalIsOpen] = useState(false)
        const [smallBusinessModalIsOpen, setSmallBusinessModalIsOpen] = useState(false)
        const [veteransModalIsOpen, setVeteransModalIsOpen] = useState(false)
        const [budgetModalIsOpen, setBudgetModalIsOpen] = useState(false)
        const [judiciaryModalIsOpen, setJudiciaryModalIsOpen] = useState(false)
        const [intelligenceModalIsOpen, setIntelligenceModalIsOpen] = useState(false)
        const [modalInfo, setModalInfo] = useState({
          isOpen: false,
          title: '',
          content: null
        });
      
        const openModal = (title, content) => {
          setModalInfo({
            isOpen: true,
            title,
            content
          });
        };
      
        const closeModal = () => {
          setModalInfo({
            isOpen: false,
            title: '',
            content: null
          });
        };
    
    var branch = "all";
  
    return(
       <>
   <MobileTopNavbar/>
  
  
   <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />

  
  <div id='homebg'>

               
 
            <h5 id='branchHeadingMobile' onClick={() => window.open('/us/executiveoffice', "_self")}>Senate</h5>

       
            <Col>  
            <h5  onClick={() => window.open('/us/senators', "_self")} id='presidentialFeedHeadingMobile'>Senators<ChevronRight id='clickChevron'></ChevronRight></h5>
                        
                          
          <SenateDisplay/>
                       </Col>    

                       <Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Agriculture, Nutrition, and Forestry Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Agriculture,%20Nutrition,%20and%20Forestry&chamber=SENATE'
          name='Committee on Agriculture, Nutrition, and Forestry Materials' 
          branch='Legislative' 
          topic='Environment' 
          buttonName='Bill' />
      )}>
      Committee on Agriculture, Nutrition, and Forestry Materials
  </h5>
  <div className='courtNewsMobile' id='senateAgricultureNutritionAndForestCommittee'></div>
  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Agriculture,%20Nutrition,%20and%20Forestry&chamber=SENATE', 
    'senateAgricultureNutritionAndForestCommittee', 
    'Senate Committee on Agriculture, Nutrition, and Forestry', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Appropriations Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Appropriations&chamber=SENATE'
          name='Committee on Appropriations Materials' 
          branch='Legislative' 
          topic='N/A' 
          buttonName='Bill' />
      )}>
      Committee on Appropriations Materials
  </h5>
  <div className='courtNewsMobile' id='senateAppropriationsCommittee'></div>
  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Appropriations&chamber=SENATE', 
    'senateAppropriationsCommittee', 
    'Senate Committee on Appropriations', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Armed Services Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Armed%20Services&chamber=SENATE'
          name='Committee on Armed Services Materials' 
          branch='Legislative' 
          topic='Foreign Affairs' 
          buttonName='Bill' />
      )}>
      Committee on Armed Services Materials
  </h5>
  <div className='courtNewsMobile' id='senateArmedServicesCommittee'></div>
  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Armed%20Services&chamber=SENATE', 
    'senateArmedServicesCommittee', 
    'Senate Committee on Armed Services', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Banking, Housing, and Urban Affairs Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Banking,%20Housing,%20and%20Urban%20Affairs&chamber=SENATE'
          name='Committee on Banking, Housing, and Urban Affairs Materials' 
          branch='Legislative' 
          topic='Economy' 
          buttonName='Bill' />
      )}>
      Committee on Banking, Housing, and Urban Affairs Materials
  </h5>
  <div className='courtNewsMobile' id='senateBankingHousingUrbanCommittee'></div>
  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Banking,%20Housing,%20and%20Urban%20Affairs&chamber=SENATE', 
    'senateBankingHousingUrbanCommittee', 
    'Senate Committee on Banking, Housing, and Urban Affairs', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Commerce, Science, and Transportation Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Commerce,%20Science,%20and%20Transportation&chamber=SENATE'
          name='Committee on Commerce, Science, and Transportation Materials' 
          branch='Legislative' 
          topic='Science & Tech' 
          buttonName='Bill' />
      )}>
      Committee on Commerce, Science, and Transportation Materials
  </h5>
  <div className='courtNewsMobile' id='senateCommerceScienceAndTransportationCommittee'></div>
  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Commerce,%20Science,%20and%20Transportation&chamber=SENATE', 
    'senateCommerceScienceAndTransportationCommittee', 
    'Senate Committee on Commerce, Science, and Transportation', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Energy and Natural Resources Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Energy%20and%20Natural%20Resources&chamber=SENATE'
          name='Committee on Energy and Natural Resources Materials' 
          branch='Legislative' 
          topic='Environment' 
          buttonName='Bill' />
      )}>
      Committee on Energy and Natural Resources Materials
  </h5>
  <div className='courtNewsMobile' id='senateEnergyCommittee'></div>
  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Energy%20and%20Natural%20Resources&chamber=SENATE', 
    'senateEnergyCommittee', 
    'Senate Committee on Energy and Natural Resources', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Environment and Public Works Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Environment%20and%20Public%20Works&chamber=SENATE'
          name='Committee on Environment and Public Works Materials' 
          branch='Legislative' 
          topic='Environment' 
          buttonName='Bill' />
      )}>
      Committee on Environment and Public Works Materials
  </h5>
  <div className='courtNewsMobile' id='senateEnvironmentAndPublicCommittee'></div>
  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Environment%20and%20Public%20Works&chamber=SENATE', 
    'senateEnvironmentAndPublicCommittee', 
    'Senate Committee on Environment and Public Works', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Finance Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Finance&chamber=SENATE'
          name='Committee on Finance Materials' 
          branch='Legislative' 
          topic='Economy' 
          buttonName='Bill' />
      )}>
      Committee on Finance Materials
  </h5>
  <div className='courtNewsMobile' id='senateFinanceCommittee'></div>
  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Finance&chamber=SENATE', 
    'senateFinanceCommittee', 
    'Senate Committee on Finance', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Foreign Relations Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Foreign%20Relations&chamber=SENATE'
          name='Committee on Foreign Relations Materials' 
          branch='Legislative' 
          topic='Foreign Affairs' 
          buttonName='Bill' />
      )}>
      Committee on Foreign Relations Materials
  </h5>
  <div className='courtNewsMobile' id='senateForeignRelationsCommittee'></div>
  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Foreign%20Relations&chamber=SENATE', 
    'senateForeignRelationsCommittee', 
    'Senate Committee on Foreign Relations', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Health, Education, Labor, and Pensions Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Health,%20Education,%20Labor,%20and%20Pensions&chamber=SENATE'
          name='Committee on Health, Education, Labor, and Pensions Materials' 
          branch='Legislative' 
          topic='Health' 
          buttonName='Bill' />
      )}>
      Committee on Health, Education, Labor, and Pensions Materials
  </h5>
  <div className='courtNewsMobile' id='senateHealthEducationLaborAndPensionsCommittee'></div>
  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Health,%20Education,%20Labor,%20and%20Pensions&chamber=SENATE', 
    'senateHealthEducationLaborAndPensionsCommittee', 
    'Senate Committee on Health, Education, Labor, and Pensions', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Homeland Security and Governmental Affairs Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Homeland%20Security%20and%20Governmental%20Affairs&chamber=SENATE'
          name='Committee on Homeland Security and Governmental Affairs Materials' 
          branch='Legislative' 
          topic='N/A' 
          buttonName='Bill' />
      )}>
      Committee on Homeland Security and Governmental Affairs Materials
  </h5>
  <div className='courtNewsMobile' id='senateHomelandCommittee'></div>
  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Homeland%20Security%20and%20Governmental%20Affairs&chamber=SENATE', 
    'senateHomelandCommittee', 
    'Senate Committee on Homeland Security and Governmental Affairs', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Indian Affairs Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Indian%20Affairs&chamber=SENATE'
          name='Committee on Indian Affairs Materials' 
          branch='Legislative' 
          topic='N/A' 
          buttonName='Bill' />
      )}>
      Committee on Indian Affairs Materials
  </h5>
  <div className='courtNewsMobile' id='senateIndianAffairsCommittee'></div>
  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Indian%20Affairs&chamber=SENATE', 
    'senateIndianAffairsCommittee', 
    'Senate Committee on Indian Affairs', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Rules and Administration Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Rules%20and%20Administration&chamber=SENATE'
          name='Committee on Rules and Administration Materials' 
          branch='Legislative' 
          topic='N/A' 
          buttonName='Bill' />
      )}>
      Committee on Rules and Administration Materials
  </h5>
  <div className='courtNewsMobile' id='senateRulesAndAdminCommittee'></div>
  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Rules%20and%20Administration&chamber=SENATE', 
    'senateRulesAndAdminCommittee', 
    'Senate Committee on Rules and Administration', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Small Business and Entrepreneurship Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Small%20Business%20and%20Entrepreneurship&chamber=SENATE'
          name='Committee on Small Business and Entrepreneurship Materials' 
          branch='Legislative' 
          topic='Economy' 
          buttonName='Bill' />
      )}>
      Committee on Small Business and Entrepreneurship Materials
  </h5>
  <div className='courtNewsMobile' id='senateSmallBusinessCommittee'></div>
  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Small%20Business%20and%20Entrepreneurship&chamber=SENATE', 
    'senateSmallBusinessCommittee', 
    'Senate Committee on Small Business and Entrepreneurship', 
    'Legislative', 
    'Economy', 
    GenerateFeedCommittee
  )}
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on Veterans Affairs Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20Veterans%27%20Affairs&chamber=SENATE'
          name='Committee on Veterans Affairs Materials' 
          branch='Legislative' 
          topic='N/A' 
          buttonName='Bill' />
      )}>
      Committee on Veterans Affairs Materials
  </h5>
  <div className='courtNewsMobile' id='senateVeteransAffairsCommittee'></div>
  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20Veterans%27%20Affairs&chamber=SENATE', 
    'senateVeteransAffairsCommittee', 
    'Senate Committee on Veterans Affairs', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on the Budget Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20the%20Budget&chamber=SENATE'
          name='Committee on the Budget Materials' 
          branch='Legislative' 
          topic='N/A' 
          buttonName='Bill' />
      )}>
      Committee on the Budget Materials
  </h5>
  <div className='courtNewsMobile' id='senateBudgetCommittee'></div>
  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20the%20Budget&chamber=SENATE', 
    'senateBudgetCommittee', 
    'Senate Committee on the Budget', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Committee on the Judiciary Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20the%20Judiciary&chamber=SENATE'
          name='Committee on the Judiciary Materials' 
          branch='Legislative' 
          topic='N/A' 
          buttonName='Bill' />
      )}>
      Committee on the Judiciary Materials
  </h5>
  <div className='courtNewsMobile' id='senateJudiciaryCommittee'></div>
  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20the%20Judiciary&chamber=SENATE', 
    'senateJudiciaryCommittee', 
    'Senate Committee on the Judiciary', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() =>
      openModal('Select Committee on Intelligence Materials',
        <GenerateFeedCommittee 
          url='&skip=0&committee=Select%20Committee%20on%20Intelligence&chamber=SENATE'
          name='Select Committee on Intelligence Materials' 
          branch='Legislative' 
          topic='Foreign Affairs' 
          buttonName='Bill' />
      )}>
      Select Committee on Intelligence Materials
  </h5>
  <div className='courtNewsMobile' id='senateIntelligenceCommittee'></div>
  {CreateFeedAPICommittee(
    '&skip=0&committee=Select%20Committee%20on%20Intelligence&chamber=SENATE', 
    'senateIntelligenceCommittee', 
    'Select Committee on Intelligence', 
    'Legislative', 
    'Foreign Affairs', 
    GenerateFeedCommittee
  )}
</Col>
<Col>

</Col>

<Col>



</Col>
          <br></br>
          
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
              
            </div>

           


           
 
             
 
 
 
 
 
 
</Navbar>
          </div>

          </>
  )
}
 }



  






export default SenateMobile;

