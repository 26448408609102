import React, { useEffect, useState } from 'react';
import { Button, Container, Modal, Spinner, Card, Form } from 'react-bootstrap';
import { Files, FileText } from 'react-bootstrap-icons';
import ReactDOM from 'react-dom';
import GenerateFeedStateRepsAll from './GenerateFeedStateRepsAll';
import UseHandleContainerClickCandidate from './UseHandleContainerClickCandidate';
require('dotenv').config();
const CreateFeedItemStateRepsForPage = ({ divID, candidateType, stateAbbreviation, skip }) => {
  const [feedItemModalIsOpen, setFeedItemModalIsOpen] = useState(false);
  const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [docLink, setDocLink] = useState('');
  const [textLink, setTextLink] = useState('');
  const [date, setDate] = useState('');
  const [state, setState] = useState('');
  const [district, setDistrict] = useState('');
  const [party, setParty] = useState('');
  const [office, setOffice] = useState('');
  const [id, setID] = useState('');
  const handleContainerClick = UseHandleContainerClickCandidate();
  const apiKey = process.env.REACT_APP_API_KEY;

  function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName) {
    var searchItem = document.getElementById('noteModalFeedItem').value;
    var collectionCounter = localStorage.getItem('collectionNumber');
    collectionCounter++;
    
    var title = 'title'.concat(collectionCounter);
    var detailLink = 'detailLink'.concat(collectionCounter);
    var docLink = 'docLink'.concat(collectionCounter);
    var itemNote = 'itemNote'.concat(collectionCounter);
    var itemDate = 'itemDate'.concat(collectionCounter);
    var feedName = 'feedName'.concat(collectionCounter);
    var branchName = 'branchName'.concat(collectionCounter);
    var topicName = 'topicName'.concat(collectionCounter);
  
    localStorage.setItem(title, saveCollectionTitle);
    localStorage.setItem(detailLink, saveCollectionDetailLink);
    localStorage.setItem(docLink, saveCollectionDocLink);
    localStorage.setItem(itemNote, searchItem);
    localStorage.setItem(itemDate, saveCollectionDate);
    localStorage.setItem(feedName, saveCollectionFeedName);
    localStorage.setItem(branchName, saveCollectionBranchName);
    localStorage.setItem(topicName, saveCollectionTopicName);
    localStorage.setItem('collectionNumber', collectionCounter);
    window.location.reload();
  }

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    const day = utcDate.getDate();
    const month = utcDate.getMonth() + 1;
    const year = utcDate.getFullYear();
    return `${month}-${day}-${year}`;
  }

  const requestOptions = {
    method: 'GET',
    headers: { 
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${apiKey}`,
    },
  };

  const displayData = (data) => {
    const sortedData = data.sort((a, b) => new Date(b.last_file_date) - new Date(a.last_file_date));
    const firstItem = sortedData[0];
    let title = firstItem.name || firstItem.name;
    if (title.length > 180) {
      title = title.substring(0, 177) + '...';
    }
    setTitle(title);
    setLink('');
    setDocLink('');
    setTextLink('');
    setDate(formatDate(firstItem.last_file_date));
    setParty(firstItem.party_full);
    setState(firstItem.state);
    setDistrict(firstItem.district);
    setOffice(firstItem.office_full);
    setID(firstItem.candidate_id);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let url;
        if (stateAbbreviation) {
          url = `https://api.govglance.org/elections/election_candidates?state=${stateAbbreviation}&election_type=${candidateType}&order_by=load_date&limit=1&skip=${skip}`;
        } else {
          url = `https://api.govglance.org/elections/election_candidates?election_type=${candidateType}&order_by=load_date&limit=1&skip=${skip}`;
        }
        const response = await fetch(url, requestOptions);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // console.log(stateAbbreviation)
        displayData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [stateAbbreviation, candidateType, skip]);

  const containerElement = document.getElementById(divID.toString());

  if (!containerElement) {
    return null; // Or return a fallback UI, like a spinner or an error message
  }

  if (title.length === 0) {
    return ReactDOM.createPortal(
      <Spinner animation="grow" variant="light" />,
      containerElement
    );
  }

  if (title === "Coming Soon") {
    return ReactDOM.createPortal(
      <Container id='feedContainerNormalView'>
        <Card.Title id='billText'>{title}</Card.Title>
        <br /><br /><br /><br /><br /><br />
      </Container>, containerElement
    );
  }

  const viewBillButton = docLink ? (
    <Button id='viewBillButton' onClick={(e) => { e.preventDefault(); window.open(docLink, "_blank"); }}>
      <FileText /> View Document
    </Button>
  ) : null;

  return ReactDOM.createPortal(
    <Container id='feedContainerNormalView'>
      <Card.Title id='billText' onClick={() => handleContainerClick(title, office, state, party, district, date, id)}>{title}</Card.Title>
      <Card.Body id='billBodyText'>{office} (Candidate)</Card.Body>
      <Card.Body id='billBodyText'>
        {candidateType !== 'President' && `State: ${state} `}
        {party}
        {candidateType !== 'Senate' && candidateType !== 'President' && ` - District: ${district}`}
      </Card.Body>
      <Card.Body id='billBodyText'>Filing date: {date}</Card.Body>
      <Button id='whDocsViewMoreButton' onClick={() => setFeedItemModalIsOpen(true)}><Files size={20}></Files> View More</Button>
      <Modal id='fullBillModal' show={feedItemModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setFeedItemModalIsOpen(false)}>
        <Modal.Header id='billModalHeader' closeButton><h3>2024 {candidateType} Candidates</h3></Modal.Header>
        <GenerateFeedStateRepsAll
          stateAbbreviation={stateAbbreviation}
          candidateType={candidateType} />
      </Modal>
      <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionModalIsOpen(false)}>
        <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
        <Modal.Body id='descriptionModalBody'>
          {date}
          <div id='fullBill'>
            <Form id='noteModalForm'>
              <Form.Group id='noteModalForm' className="mb-3">
                <Form.Control id="noteModalFeedItem" placeholder="Add a note here" type="text" as="textarea" rows={2} />
                <br />
              </Form.Group>
            </Form>
            <Modal.Footer id='addNoteModalFooter'>
              <Button variant="primary" id='noteSaveToCollectionButton' onClick={() => { 
                saveNote(title, link, "", date); 
                setCollectionModalIsOpen(false); 
              }}>
                Save To Collection
              </Button>
            </Modal.Footer>
          </div>
        </Modal.Body>
      </Modal>
    </Container>, containerElement
  );
};

export default CreateFeedItemStateRepsForPage;
