import App from "../../../App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, Coin, PauseCircle, PlayCircle, FolderPlus, ChevronRight, Files, Folder, Pen, ChatLeftQuote, ShieldShaded, People, HeartFill } from 'react-bootstrap-icons'
import '../../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import TemplateMobileTopNavbar from "../../UKMobileTopNavbar";
import MobileOffCanvas from "../../../MobileOffCanvas";
import { CreateFeedItemUKAtom } from "../../../Functions";
import DepartmentForBusiness from "../../Executive/DepartmentForBusiness";
import DepartmentForWork from "./DepartmentForWork";
import DepartmentForHMTreasury from "./DepartmentForHMTreasury";
import ExportFinance from "./ExportFinance";
import OffCanvasUK from "../../OffCanvasUK";


var collectionCounter = 0  
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;



export const UKEconomyMobile = () =>   {
  const [modal1, setModal1IsOpen] = useState(false);
const [modal2, setModal2IsOpen] = useState(false);
const [modal3, setModal3IsOpen] = useState(false);
const [modal4, setModal4IsOpen] = useState(false);
const [modal5, setModal5IsOpen] = useState(false);
const [modal6, setModal6IsOpen] = useState(false);
const [modal7, setModal7IsOpen] = useState(false);
const [modal8, setModal8IsOpen] = useState(false);
const [modal9, setModal9IsOpen] = useState(false);
const [modal10, setModal10IsOpen] = useState(false);
const [modal11, setModal11IsOpen] = useState(false);
const [modal12, setModal12IsOpen] = useState(false);
const [modal13, setModal13IsOpen] = useState(false);
const [modal14, setModal14IsOpen] = useState(false);
const [modal15, setModal15IsOpen] = useState(false);
const [modal16, setModal16IsOpen] = useState(false);
const [modal17, setModal17IsOpen] = useState(false);
const [modal18, setModal18IsOpen] = useState(false);
const [modal19, setModal19IsOpen] = useState(false);
const [modal20, setModal20IsOpen] = useState(false);
const [modal23, setModal23IsOpen] = useState(false);
    render()

    {
  
          const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
      
        localStorage.setItem('currentPage', 'uk')
    return(
       <>
    <TemplateMobileTopNavbar/>
  
  
  
  
  <div id='homebg'>

<h5 id='branchHeadingMobile'><Coin></Coin> Economy</h5>
{/*onClick={() => setModalIsOpen(true)}*/}

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setModal2IsOpen(true)}>Department for Business and Trade</h5>

  <div id='deptOfBusinessAndTrade'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-business-and-trade.atom',
     'deptOfBusinessAndTrade', 'Department of Business and Trade', 'Executive', 'Economy', DepartmentForBusiness)}

  <Modal id='mobileFullBillModal' show={modal2} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal2IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Business and Trade</h3></Modal.Header>
<DepartmentForBusiness/>
</Modal>

    
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setModal10IsOpen(true)}>Department for Work & Pensions</h5>

  <div id='deptOfWork'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/department-for-work-pensions.atom',
     'deptOfWork', 'Department for Work & Pensions', 'Executive', 'Economy', DepartmentForWork)}

  <Modal id='mobileFullBillModal' show={modal10} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal10IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Department for Work & Pensions</h3></Modal.Header>
<DepartmentForWork/>
</Modal>

    
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setModal13IsOpen(true)}>HM Treasury</h5>

  <div id='deptOfTreasury'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/hm-treasury.atom',
     'deptOfTreasury', 'HM Treasury', 'Executive', 'Economy', DepartmentForHMTreasury)}

  <Modal id='mobileFullBillModal' show={modal13} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal13IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>HM Treasury</h3></Modal.Header>
<DepartmentForHMTreasury/>
</Modal>

    
</Col>

<Col>
  <h5 id='presidentialFeedHeadingMobile' onClick={() => setModal23IsOpen(true)}>Export Finance</h5>

  <div id='exportFinance'></div>
                          
                          {/*url, divID, feedName, feedBranch, feedTopic,  FeedFunction */}
    {CreateFeedItemUKAtom('https://www.gov.uk/government/organisations/uk-export-finance.atom',
     'exportFinance', 'Export Finance ', 'Executive', 'Economy', ExportFinance)}

  <Modal id='mobileFullBillModal' show={modal23} 
  size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModal23IsOpen(false)}>
<Modal.Header id='mobileBillModalHeader' closeButton><h3>Export Finance</h3></Modal.Header>
<ExportFinance/>
</Modal>

    
</Col>

<Col>

<br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>

<br></br><br></br><br></br><br></br><br></br><br></br>

<Navbar id='navbarMobileBottom' fixed="bottom">  



       
<div id='changeBranchDropdownMobile'>

                  <OffCanvasUK/>
              
            </div>

</Navbar>
 
             
 
 
 
 
 
 

</div>



</>
)
}}
export default UKEconomyMobile;


