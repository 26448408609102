
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand,  ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Tree, FileMedical, FileMedicalFill, ChevronRight  } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import { CreateFeedAPI, CreateFeedAPIDepartmentUS } from './Functions';
import GenerateFeed from './GenerateFeed';
import RegulationsMobile from './RegulationsMobile';
import DesktopNavbar from './DesktopNavbar';
import ImmigrationMobile from './ImmigrationMobile';
import GenerateFeedDepartment from './GenerateFeedDepartment';
var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);
export const Immigration = () =>
 {
   render()
   
   {
    const [homelandModalIsOpen, setHomelandModalIsOpen] = useState(false);
    const [modalInfo, setModalInfo] = useState({
      isOpen: false,
      title: '',
      content: null
    });
  
    const openModal = (title, content) => {
      setModalInfo({
        isOpen: true,
        title,
        content
      });
    };
  
    const closeModal = () => {
      setModalInfo({
        isOpen: false,
        title: '',
        content: null
      });
    };
  return(
    <>
      <BrowserView>
        <div id='homePage'>
        <div id='homebg'>
        <DesktopNavbar/>
      
               <div id='sectionHeading'>      
               <Row>
              <h3 id='branchHeading' ><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-passport" viewBox="0 0 16 16">
  <path d="M8 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6M6 8a2 2 0 1 1 4 0 2 2 0 0 1-4 0m-.5 4a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z"/>
  <path d="M3.232 1.776A1.5 1.5 0 0 0 2 3.252v10.95c0 .445.191.838.49 1.11.367.422.908.688 1.51.688h8a2 2 0 0 0 2-2V4a2 2 0 0 0-1-1.732v-.47A1.5 1.5 0 0 0 11.232.321l-8 1.454ZM4 3h8a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1"/>
</svg><b>Immigration</b></h3> 
              
              </Row>
              </div> 
         
                     
              <div id='branchPage'>
        <Container className='bgcolor'>
          <Container id='carouselBufferTwo'>
            <Row>
            <Col id='legislativeColLeft'>
                      <div id='columnLefttPadding'>


                        <h4 id='departmentOfDefenseHeadingInCol' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/ice', "_self")
                  }}><b>U.S. Immigration and Customs Enforcement</b><ChevronRight id='clickChevron'></ChevronRight></h4>


                        <Carousel id='carouselInColumn' controls={false}  >





<Carousel.Item interval={carouselInterval}>

<h4 id='departmentOfJusticeHeading'><b> News</b></h4>

<div id='iceNews'></div>
                {CreateFeedAPI('&schema=united_states_of_america&table=immigration&order_by=created_at&filter_column=agency&filter_string=U.S.%20Immigration%20and%20Customs%20Enforcement', 'iceNews', 'ICE News', 'Executive', 'Immigration', 'Doc', GenerateFeed)}



</Carousel.Item>

<Carousel.Item interval={carouselInterval}>

<h4 id='departmentOfJusticeHeading'><b>Documents</b></h4>

<div id='defIce'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=U.S.%20Immigration%20and%20Customs%20Enforcement', 'defIce',
                    'U.S. Immigration and Customs Enforcement Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>U.S. Immigration and Customs Enforcement</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=U.S.%20Immigration%20and%20Customs%20Enforcement'
                      name='U.S. Immigration and Customs Enforcement' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>




</Carousel.Item>





</Carousel>  
                    </div>
                  </Col>
                  <Col id='legislativeColLeft'>
                <div id='columnLeftPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/cbp', "_self")
                  }}>
                    <b>U.S. Customs and Border Protection</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defCbp'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=U.S.%20Customs%20and%20Border%20Protection', 'defCbp',
                    'U.S. Customs and Border Protection Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>U.S. Customs and Border Protection</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=U.S.%20Customs%20and%20Border%20Protection'
                      name='U.S. Customs and Border Protection' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <div id='branchPage'>
        <Container className='bgcolor'>
          <Container id='carouselBufferTwo'>
            <Row>
              <Col id='legislativeColRight'>
                <div id='columnRightPadding'>
                  <h4 id='whBreifingFeedHeading' onClick={(e) => {
                    e.preventDefault();
                    window.open('/us/uscis', "_self")
                  }}>
                    <b>U.S. Citizenship and Immigration Services</b>
                    <ChevronRight id='clickChevron' />
                  </h4>
                  <div id='defUscis'></div>
                  {CreateFeedAPIDepartmentUS('&table_name=homeland_security&agency=U.S.%20Citizenship%20and%20Immigration%20Services', 'defUscis',
                    'U.S. Citizenship and Immigration Services Documents', 'Executive',
                    'Defense', 'Doc', GenerateFeedDepartment)}
                  <Modal id='whiteHouseDocsModal' show={homelandModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setHomelandModalIsOpen(false)}>
                    <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton>
                      <h3>U.S. Citizenship and Immigration Services</h3>
                    </Modal.Header>
                    <GenerateFeedDepartment url='&table_name=homeland_security&agency=U.S.%20Citizenship%20and%20Immigration%20Services'
                      name='U.S. Citizenship and Immigration Services' branch='Executive' topic='Defense' buttonName='Doc' />
                  </Modal>
                </div>
              </Col>

              <Col id='legislativeColLeft'>
                <div id='columnLeftPadding'>
               
                </div>
              </Col>

      
            </Row>
          </Container>
        </Container>
      </div>

    

 

      


            

   

            <footer id='homeFooter'>
      <Navbar>
        <Row id='footerNav'>
      <Nav variant='pills'>
        
     <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
</Nav.Link>
<Nav.Item>
<Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
</Nav.Item>
<Nav.Item>
<Nav.Link id='footerContent' onClick={(e) => {
                   e.preventDefault();
                   window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                 }}> <HeartFill id='donateIcon'/>    Donate</Nav.Link>
</Nav.Item>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>About</Nav.Link>
<Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/privacy', "_self")}} id='footerContent'>Privacy</Nav.Link>
    
     
 </Nav>
 </Row>
 </Navbar>
      </footer>
</div>

         </div>
          

              </BrowserView>

              <MobileView>

<ImmigrationMobile/>
              </MobileView>
              
    </>
  )
}
 }



  






export default Immigration;