import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Spinner } from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
require('dotenv').config();

const apiKey = process.env.REACT_APP_API_KEY;

function FeedPageById() {
  const { package_id, schema, table } = useParams(); // Get schema, table, and package_id from the URL

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Log the extracted parameters to verify
  console.log('FeedPageById Parameters:', { schema, table, package_id });

  useEffect(() => {
    if (!package_id || !schema || !table) return;

    console.log('Fetching data with:', {
      schema,
      table,
      package_id,
    });

    const url = `https://api.govglance.org/posts/recent?limit=10&skip=0&schema=${schema}&table=${table}&order_by=created_at&filter_column=package_id&filter_string=${package_id}`;

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiKey}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        return response.json();
      })
      .then((data) => {
        console.log('Fetched data:', data);
        setData(data);
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [package_id, schema, table]);

  if (loading) {
    return (
      <div id="fullBillFeed">
        <Spinner animation="grow" variant="light" />
      </div>
    );
  }
  if (error) {
    return (
      <div id="fullBillFeed">
        <p>Error fetching data.</p>
      </div>
    );
  }

  return (
    <Container id="feedContainer">
      {data.map((item, index) => (
        <div key={index}>
          <h2 id="billText">{item.title || item.status_title}</h2>
          <p id="billBodyText">{item.summary}</p>
        </div>
      ))}
    </Container>
  );
}

export default FeedPageById;
