
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import { ArrowsAngleExpand,  ArrowsFullscreen, InfoCircle, FileText, ShieldShaded, PlayCircle, PauseCircle, HeartFill, Pen, People, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, Tree, FileMedical, FileMedicalFill, ChevronRight, Bricks  } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import {createDepartmentOfHealthAndHumanServices, 
  createCDCNewsroom, createCDCOutbreaks, 
  createCDCTravelNotices, createFoodSafety, 
  createFoodRecalls, createMedWatch, 
  createHealthyLivingFeatures, 
  createPreventingChronicDiseases, 
  createCovid19, createNovelCoronavirus, 
  createVaccineUpdates, createSeasonalFlu, Speak, CreateFeedItem, CreateFeedItemMaterials, CreateFeedAPI, CreateFeedItemBillWithDescription, CreateFeedAPIDepartmentUS, CreateFeedAPICommittee} from "./Functions";
  import FederalRegisterDefenseDepartment from './FederalRegisterDefenseDepartment';
  import MobileOffCanvas from './MobileOffCanvas';
import MobileTopNavbar from './MobileTopNavbar';
import DepartmentOfJusticeDocs from './DepartmentOfJusticeDocs';
import AlcoholTobaccoFireArms from './AlcoholTobaccoFireArms';
import AntitrustDivision from './AntitrustDivision';
import DrugEnforcementAdministration from './DrugEnforcementAdministration';
import ExecutiveOfficeForImmigrationReview from './ExecutiveOfficeForImmigrationReview';
import FederalBureauOfInvestigation from './FederalBureauOfInvestigation';
import ForeignClaimsSettlement from './ForeignClaimsSettlement';
import JusticeProgramsOffice from './JusticeProgramsOffice';
import JuvenileJusticeAndDellinquencyPreventionOfficee from './JuvenileJusticeAndDellinquencyPreventionOffice';
import NationalInstituteOfCorrections from './NationalInstituteOfCorrections';
import NationalInstituteOfJustice from './NationalInstituteOfJustice';
import ParoleCommission from './ParoleCommission';
import PrisonBureau from './PrisonBureau';
import UnitedStatesMarshalsService from './UnitedStatesMarshalsService';
import DepartmentOfJustice from './DepartmentOfJustice';
import SenateJudiciaryCommittee from './SenateJudiciaryCommittee';
import HouseCommitteeOnTheJudiciaryMaterials from './HouseCommitteeOnTheJudiciaryMaterials';
import HouseCommitteeOnTheJudiciary from './HouseCommitteeOnTheJudiciary';
import GenerateFeed from './GenerateFeed';
import GenerateFeedDepartment from './GenerateFeedDepartment';
import GenerateFeedCommittee from './GenerateFeedCommittee';

var mobileCarouselInterval = 60000;

export const JusticeTopicMobile = () =>
 {
   render()
   {
    const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
    const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
    const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
    const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
    const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
    const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
    const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
    const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
    const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
    const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
    const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
    const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
    const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
    const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
    const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
    const [judiciaryModalIsOpen, setJudiciaryModalIsOpen] = useState(false)
    const [committeeOnTheJudiciaryModalIsOpen, setCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
      const [judiciaryMaterialsModalIsOpen, setJudiciaryMaterialsModalIsOpen] = useState(false)
      const [immigrationModalIsOpen, setImmigrationModalIsOpen] = useState(false)
      const [modalInfo, setModalInfo] = useState({
        isOpen: false,
        title: '',
        content: null
      });
    
      const openModal = (title, content) => {
        setModalInfo({
          isOpen: true,
          title,
          content
        });
      };
    
      const closeModal = () => {
        setModalInfo({
          isOpen: false,
          title: '',
          content: null
        });
      };

      var mobileCarouselInterval = 60000;
      const CustomModal = ({ isOpen, onClose, title, content }) => (
        <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
          <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
          {content}
        </Modal>
      );
        return(
    <>

      


<MobileTopNavbar/>


        <div id='homebg'>
    

            <h5 id='branchHeadingMobile' ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-arms-up" viewBox="0 0 16 16">
  <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
  <path d="m5.93 6.704-.846 8.451a.768.768 0 0 0 1.523.203l.81-4.865a.59.59 0 0 1 1.165 0l.81 4.865a.768.768 0 0 0 1.523-.203l-.845-8.451A1.5 1.5 0 0 1 10.5 5.5L13 2.284a.796.796 0 0 0-1.239-.998L9.634 3.84a.7.7 0 0 1-.33.235c-.23.074-.665.176-1.304.176-.64 0-1.074-.102-1.305-.176a.7.7 0 0 1-.329-.235L4.239 1.286a.796.796 0 0 0-1.24.998l2.5 3.216c.317.316.475.758.43 1.204Z"/>
</svg> Justice </h5>

  
<h5 onClick={() => window.open('/us/departmentofjustice', "_self")} id='presidentialFeedHeadingMobile'>Department of Justice<ChevronRight id='clickChevron'></ChevronRight></h5>

            
<Col>

<Carousel controls={false} >
            
      
            
            <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDepartmentOfJusticeModalIsOpen(true)}>News</h5>




  <div class='courtNewsMobile' id='justiceNews'></div>


{CreateFeedAPI('&skip=0&schema=united_states_of_america&table=justice&order_by=created_at', 'justiceNews', 
                      'Department of Justice News', 'Executive', 
                      'N/A', 'Document', GenerateFeed)}
</Col>



<Modal id='whiteHouseDocsModal' show={departmentOfJusticeModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDepartmentOfJusticeModalIsOpen(false)}>
                <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Justice News</h3></Modal.Header>
                <GenerateFeed
                                url='&skip=0&schema=united_states_of_america&table=justice&order_by=created_at'
                                name='Department of Justice News' branch='Executive' topic='N/A' buttonName='Document' />
              </Modal>


    



</div>



</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setImmigrationModalIsOpen(true)}>Documents</h5>


<div class='courtNewsMobile' id='justiceDocs'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=justice-department', 'justiceDocs', 'Department of Justice Documents', 'Executive', 'N/A', DepartmentOfJusticeDocs)}

</Col>

<Modal id='whiteHouseDocsModal' show={immigrationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setImmigrationModalIsOpen(false)}>
      <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Justice Documents</h3></Modal.Header>
      <DepartmentOfJusticeDocs/>
    </Modal>
  





</div>



</Carousel.Item>






<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/departmentofjustice' >View all Department of Justice news</Button>
</div>
</Col>

</div>



</Carousel.Item>

            
            
            </Carousel>

    </Col>


    <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />




<Col>


<h5 id='presidentialFeedHeadingMobile' onClick={() => setDefenseDocumentsModalIsOpen(true)}>Documents</h5>


<div class='courtNewsMobile' id='frDefense'></div>

{CreateFeedItem('https://www.federalregister.gov/api/v1/documents.rss?conditions%5Bagencies%5D%5B%5D=justice-department', 'frDefense', 'Department of Justice Documents', 'Executive', 'N/A', DepartmentOfJusticeDocs)}

</Col>
  
    <Modal id='whiteHouseDocsModal' show={defenseDocumentsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDefenseDocumentsModalIsOpen(false)}>
      <Modal.Header id='mobileWhiteHouseDocsModalHeader' closeButton><h3>Department of Justice Documents</h3></Modal.Header>
      <DepartmentOfJusticeDocs/>
    </Modal>


    {/* <Col>
<Link  id='pageLink' to="/us/atf">
<h5 id='presidentialFeedHeadingMobile'>Alcohol, Tobacco, Firearms, and Explosives Bureau<ChevronRight id='clickChevron'></ChevronRight></h5>
</Link>
<Carousel controls={false}>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<Row id='mobileFeedHeadingRow'>
<h5 id='billFeedHeadingMobileHome' onClick={() =>
  openModal('Alcohol, Tobacco, Firearms, and Explosives Bureau',
    <GenerateFeedDepartment url='&table_name=justice&agency=Alcohol%20Tobacco%20Firearms%20and%20Explosives%20Bureau'
      name='Alcohol, Tobacco, Firearms, and Explosives Bureau' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
</Row>
<div class='courtNewsMobile' id='defAtf'></div>
{CreateFeedAPIDepartmentUS('&table_name=justice&agency=Alcohol%20Tobacco%20Firearms%20and%20Explosives%20Bureau', 'defAtf',
'Alcohol, Tobacco, Firearms, and Explosives Bureau Documents', 'Executive',
'Justice', 'Doc', GenerateFeedDepartment)}
</Col>
</div>
</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/atf' >View all Alcohol, Tobacco, Firearms, and Explosives Bureau news</Button>
</div>
</Col>
</div>
</Carousel.Item>
</Carousel>
</Col> */}

<Col>
<Link  id='pageLink' to="/us/antitrust-division">
<h5 id='presidentialFeedHeadingMobile'>Antitrust Division<ChevronRight id='clickChevron'></ChevronRight></h5>
</Link>
<Carousel controls={false}>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<Row id='mobileFeedHeadingRow'>
<h5 id='billFeedHeadingMobileHome' onClick={() =>
  openModal('Antitrust Division',
    <GenerateFeedDepartment url='&table_name=justice&agency=Antitrust%20Division'
      name='Antitrust Division' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
</Row>
<div class='courtNewsMobile' id='defAd'></div>
{CreateFeedAPIDepartmentUS('&table_name=justice&agency=Antitrust%20Division', 'defAd',
'Antitrust Division Documents', 'Executive',
'Justice', 'Doc', GenerateFeedDepartment)}
</Col>
</div>
</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/antitrust-division' >View all Antitrust Division news</Button>
</div>
</Col>
</div>
</Carousel.Item>
</Carousel>
</Col>

<Col>
<Link  id='pageLink' to="/us/dea">
<h5 id='presidentialFeedHeadingMobile'>Drug Enforcement Administration<ChevronRight id='clickChevron'></ChevronRight></h5>
</Link>
<Carousel controls={false}>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<Row id='mobileFeedHeadingRow'>
<h5 id='billFeedHeadingMobileHome' onClick={() =>
  openModal('Drug Enforcement Administration',
    <GenerateFeedDepartment url='&table_name=justice&agency=Drug%20Enforcement%20Administration'
      name='Drug Enforcement Administration' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
</Row>
<div class='courtNewsMobile' id='defDea'></div>
{CreateFeedAPIDepartmentUS('&table_name=justice&agency=Drug%20Enforcement%20Administration', 'defDea',
'Drug Enforcement Administration Documents', 'Executive',
'Justice', 'Doc', GenerateFeedDepartment)}
</Col>
</div>
</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/dea' >View all Drug Enforcement Administration news</Button>
</div>
</Col>
</div>
</Carousel.Item>
</Carousel>
</Col>

{/* <Col>
<Link  id='pageLink' to="/us/immigration-review">
<h5 id='presidentialFeedHeadingMobile'>Executive Office for Immigration Review<ChevronRight id='clickChevron'></ChevronRight></h5>
</Link>
<Carousel controls={false}>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<Row id='mobileFeedHeadingRow'>
<h5 id='billFeedHeadingMobileHome' onClick={() =>
  openModal('Executive Office for Immigration Review',
    <GenerateFeedDepartment url='&table_name=justice&agency=Executive%20Office%20for%20Immigration%20Review'
      name='Executive Office for Immigration Review' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
</Row>
<div class='courtNewsMobile' id='defEoir'></div>
{CreateFeedAPIDepartmentUS('&table_name=justice&agency=Executive%20Office%20for%20Immigration%20Review', 'defEoir',
'Executive Office for Immigration Review Documents', 'Executive',
'Justice', 'Doc', GenerateFeedDepartment)}
</Col>
</div>
</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/immigration-review' >View all Executive Office for Immigration Review news</Button>
</div>
</Col>
</div>
</Carousel.Item>
</Carousel>
</Col> */}

<Col>
<Link  id='pageLink' to="/us/fbi">
<h5 id='presidentialFeedHeadingMobile'>Federal Bureau of Investigation<ChevronRight id='clickChevron'></ChevronRight></h5>
</Link>
<Carousel controls={false}>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<Row id='mobileFeedHeadingRow'>
<h5 id='billFeedHeadingMobileHome' onClick={() =>
  openModal('Federal Bureau of Investigation',
    <GenerateFeedDepartment url='&table_name=justice&agency=Federal%20Bureau%20of%20Investigation'
      name='Federal Bureau of Investigation' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
</Row>
<div class='courtNewsMobile' id='defFbi'></div>
{CreateFeedAPIDepartmentUS('&table_name=justice&agency=Federal%20Bureau%20of%20Investigation', 'defFbi',
'Federal Bureau of Investigation Documents', 'Executive',
'Justice', 'Doc', GenerateFeedDepartment)}
</Col>
</div>
</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/fbi' >View all Federal Bureau of Investigation news</Button>
</div>
</Col>
</div>
</Carousel.Item>
</Carousel>
</Col>

<Col>
<Link  id='pageLink' to="/us/federal-prison-industries">
<h5 id='presidentialFeedHeadingMobile'>Federal Prison Industries<ChevronRight id='clickChevron'></ChevronRight></h5>
</Link>
<Carousel controls={false}>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<Row id='mobileFeedHeadingRow'>
<h5 id='billFeedHeadingMobileHome' onClick={() =>
  openModal('Federal Prison Industries',
    <GenerateFeedDepartment url='&table_name=justice&agency=Federal%20Prison%20Industries'
      name='Federal Prison Industries' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
</Row>
<div class='courtNewsMobile' id='defFpi'></div>
{CreateFeedAPIDepartmentUS('&table_name=justice&agency=Federal%20Prison%20Industries', 'defFpi',
'Federal Prison Industries Documents', 'Executive',
'Justice', 'Doc', GenerateFeedDepartment)}
</Col>
</div>
</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/federal-prison-industries' >View all Federal Prison Industries news</Button>
</div>
</Col>
</div>
</Carousel.Item>
</Carousel>
</Col>

<Col>
<Link  id='pageLink' to="/us/foreign-claims">
<h5 id='presidentialFeedHeadingMobile'>Foreign Claims Settlement Commission<ChevronRight id='clickChevron'></ChevronRight></h5>
</Link>
<Carousel controls={false}>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<Row id='mobileFeedHeadingRow'>
<h5 id='billFeedHeadingMobileHome' onClick={() =>
  openModal('Foreign Claims Settlement Commission',
    <GenerateFeedDepartment url='&table_name=justice&agency=Foreign%20Claims%20Settlement%20Commission'
      name='Foreign Claims Settlement Commission' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
</Row>
<div class='courtNewsMobile' id='defFcsc'></div>
{CreateFeedAPIDepartmentUS('&table_name=justice&agency=Foreign%20Claims%20Settlement%20Commission', 'defFcsc',
'Foreign Claims Settlement Commission Documents', 'Executive',
'Justice', 'Doc', GenerateFeedDepartment)}
</Col>
</div>
</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/foreign-claims' >View all Foreign Claims Settlement Commission news</Button>
</div>
</Col>
</div>
</Carousel.Item>
</Carousel>
</Col>

{/* <Col>
<Link  id='pageLink' to="/us/immigration-naturalization">
<h5 id='presidentialFeedHeadingMobile'>Immigration and Naturalization Service<ChevronRight id='clickChevron'></ChevronRight></h5>
</Link>
<Carousel controls={false}>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<Row id='mobileFeedHeadingRow'>
<h5 id='billFeedHeadingMobileHome' onClick={() =>
  openModal('Immigration and Naturalization Service',
    <GenerateFeedDepartment url='&table_name=justice&agency=Immigration%20and%20Naturalization%20Service'
      name='Immigration and Naturalization Service' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
</Row>
<div class='courtNewsMobile' id='defIns'></div>
{CreateFeedAPIDepartmentUS('&table_name=justice&agency=Immigration%20and%20Naturalization%20Service', 'defIns',
'Immigration and Naturalization Service Documents', 'Executive',
'Justice', 'Doc', GenerateFeedDepartment)}
</Col>
</div>
</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/immigration-naturalization' >View all Immigration and Naturalization Service news</Button>
</div>
</Col>
</div>
</Carousel.Item>
</Carousel>
</Col> */}

<Col>
<Link  id='pageLink' to="/us/justice-programs">
<h5 id='presidentialFeedHeadingMobile'>Justice Programs Office<ChevronRight id='clickChevron'></ChevronRight></h5>
</Link>
<Carousel controls={false}>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<Row id='mobileFeedHeadingRow'>
<h5 id='billFeedHeadingMobileHome' onClick={() =>
  openModal('Justice Programs Office',
    <GenerateFeedDepartment url='&table_name=justice&agency=Justice%20Programs%20Office'
      name='Justice Programs Office' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
</Row>
<div class='courtNewsMobile' id='defJpo'></div>
{CreateFeedAPIDepartmentUS('&table_name=justice&agency=Justice%20Programs%20Office', 'defJpo',
'Justice Programs Office Documents', 'Executive',
'Justice', 'Doc', GenerateFeedDepartment)}
</Col>
</div>
</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/justice-programs' >View all Justice Programs Office news</Button>
</div>
</Col>
</div>
</Carousel.Item>
</Carousel>
</Col>

<Col>
<Link  id='pageLink' to="/us/juvenile-justice">
<h5 id='presidentialFeedHeadingMobile'>Juvenile Justice and Delinquency Prevention Office<ChevronRight id='clickChevron'></ChevronRight></h5>
</Link>
<Carousel controls={false}>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<Row id='mobileFeedHeadingRow'>
<h5 id='billFeedHeadingMobileHome' onClick={() =>
  openModal('Juvenile Justice and Delinquency Prevention Office',
    <GenerateFeedDepartment url='&table_name=justice&agency=Juvenile%20Justice%20and%20Delinquency%20Prevention%20Office'
      name='Juvenile Justice and Delinquency Prevention Office' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
</Row>
<div class='courtNewsMobile' id='defJjdpo'></div>
{CreateFeedAPIDepartmentUS('&table_name=justice&agency=Juvenile%20Justice%20and%20Delinquency%20Prevention%20Office', 'defJjdpo',
'Juvenile Justice and Delinquency Prevention Office Documents', 'Executive',
'Justice', 'Doc', GenerateFeedDepartment)}
</Col>
</div>
</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/juvenile-justice' >View all Juvenile Justice and Delinquency Prevention Office news</Button>
</div>
</Col>
</div>
</Carousel.Item>
</Carousel>
</Col>

<Col>
<Link  id='pageLink' to="/us/national-institute-corrections">
<h5 id='presidentialFeedHeadingMobile'>National Institute of Corrections<ChevronRight id='clickChevron'></ChevronRight></h5>
</Link>
<Carousel controls={false}>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<Row id='mobileFeedHeadingRow'>
<h5 id='billFeedHeadingMobileHome' onClick={() =>
  openModal('National Institute of Corrections',
    <GenerateFeedDepartment url='&table_name=justice&agency=National%20Institute%20of%20Corrections'
      name='National Institute of Corrections' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
</Row>
<div class='courtNewsMobile' id='defNic'></div>
{CreateFeedAPIDepartmentUS('&table_name=justice&agency=National%20Institute%20of%20Corrections', 'defNic',
'National Institute of Corrections Documents', 'Executive',
'Justice', 'Doc', GenerateFeedDepartment)}
</Col>
</div>
</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/national-institute-corrections' >View all National Institute of Corrections news</Button>
</div>
</Col>
</div>
</Carousel.Item>
</Carousel>
</Col>

<Col>
<Link  id='pageLink' to="/us/national-institute-justice">
<h5 id='presidentialFeedHeadingMobile'>National Institute of Justice<ChevronRight id='clickChevron'></ChevronRight></h5>
</Link>
<Carousel controls={false}>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<Row id='mobileFeedHeadingRow'>
<h5 id='billFeedHeadingMobileHome' onClick={() =>
  openModal('National Institute of Justice',
    <GenerateFeedDepartment url='&table_name=justice&agency=National%20Institute%20of%20Justice'
      name='National Institute of Justice' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
</Row>
<div class='courtNewsMobile' id='defNij'></div>
{CreateFeedAPIDepartmentUS('&table_name=justice&agency=National%20Institute%20of%20Justice', 'defNij',
'National Institute of Justice Documents', 'Executive',
'Justice', 'Doc', GenerateFeedDepartment)}
</Col>
</div>
</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/national-institute-justice' >View all National Institute of Justice news</Button>
</div>
</Col>
</div>
</Carousel.Item>
</Carousel>
</Col>

<Col>
<Link  id='pageLink' to="/us/parole-commission">
<h5 id='presidentialFeedHeadingMobile'>Parole Commission<ChevronRight id='clickChevron'></ChevronRight></h5>
</Link>
<Carousel controls={false}>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<Row id='mobileFeedHeadingRow'>
<h5 id='billFeedHeadingMobileHome' onClick={() =>
  openModal('Parole Commission',
    <GenerateFeedDepartment url='&table_name=justice&agency=Parole%20Commission'
      name='Parole Commission' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
</Row>
<div class='courtNewsMobile' id='defPc'></div>
{CreateFeedAPIDepartmentUS('&table_name=justice&agency=Parole%20Commission', 'defPc',
'Parole Commission Documents', 'Executive',
'Justice', 'Doc', GenerateFeedDepartment)}
</Col>
</div>
</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/parole-commission' >View all Parole Commission news</Button>
</div>
</Col>
</div>
</Carousel.Item>
</Carousel>
</Col>

{/* <Col>
<Link  id='pageLink' to="/us/prisons-bureau">
<h5 id='presidentialFeedHeadingMobile'>Prisons Bureau<ChevronRight id='clickChevron'></ChevronRight></h5>
</Link>
<Carousel controls={false}>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<Row id='mobileFeedHeadingRow'>
<h5 id='billFeedHeadingMobileHome' onClick={() =>
  openModal('Prisons Bureau',
    <GenerateFeedDepartment url='&table_name=justice&agency=Prisons%20Bureau'
      name='Prisons Bureau' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
</Row>
<div class='courtNewsMobile' id='defPb'></div>
{CreateFeedAPIDepartmentUS('&table_name=justice&agency=Prisons%20Bureau', 'defPb',
'Prisons Bureau Documents', 'Executive',
'Justice', 'Doc', GenerateFeedDepartment)}
</Col>
</div>
</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/prisons-bureau' >View all Prisons Bureau news</Button>
</div>
</Col>
</div>
</Carousel.Item>
</Carousel>
</Col> */}

<Col>
<Link  id='pageLink' to="/us/marshals-service">
<h5 id='presidentialFeedHeadingMobile'>United States Marshals Service<ChevronRight id='clickChevron'></ChevronRight></h5>
</Link>
<Carousel controls={false}>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<Row id='mobileFeedHeadingRow'>
<h5 id='billFeedHeadingMobileHome' onClick={() =>
  openModal('United States Marshals Service',
    <GenerateFeedDepartment url='&table_name=justice&agency=United%20States%20Marshals%20Service'
      name='United States Marshals Service' branch='Executive' topic='Justice' buttonName='Doc' />)}>Documents</h5>
</Row>
<div class='courtNewsMobile' id='defUsms'></div>
{CreateFeedAPIDepartmentUS('&table_name=justice&agency=United%20States%20Marshals%20Service', 'defUsms',
'United States Marshals Service Documents', 'Executive',
'Justice', 'Doc', GenerateFeedDepartment)}
</Col>
</div>
</Carousel.Item>
<Carousel.Item interval={mobileCarouselInterval}>
<div id='legislativeConMobile'>
<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/marshals-service' >View all United States Marshals Service news</Button>
</div>
</Col>
</div>
</Carousel.Item>
</Carousel>
</Col>
        
                <Col>

<h5 id='presidentialFeedHeadingMobile' onClick={() => setJudiciaryModalIsOpen(true)}>Committee on the Judiciary Materials</h5>
                      <div class='courtNewsMobile'id='senateJudiciaryCommittee'></div>
                
                      {CreateFeedAPICommittee(
              '&skip=0&committee=Committee%20on%20the%20Judiciary&chamber=SENATE', 
              'senateJudiciaryCommittee', 
              'Senate Committee on the Judiciary', 
              'Legislative', 
              'N/A', 
              'Bill', 
              GenerateFeedCommittee
            )}
                      
                        <Modal id='fullBillModal' show={judiciaryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
                    centered onHide={() => setJudiciaryModalIsOpen(false)}>
            <Modal.Header id='billModalHeader' closeButton><h3>Senate Committee on the Judiciary Materials</h3></Modal.Header>
            <GenerateFeedCommittee 
                  url='&skip=0&committee=Committee%20on%20the%20Judiciary&chamber=SENATE'
                  name='Senate Committee on the Judiciary Materials' 
                  branch='Legislative' 
                  topic='N/A' 
                  buttonName='Bill' />
          </Modal>

</Col>

<Col>
                    
                    <h5 id='presidentialFeedHeadingMobile' onClick={() => setCommitteeOnTheJudiciaryModalIsOpen(true)}>Committee on the Judiciary</h5>
                  <div class='courtNewsMobile'id='houseCommitteeOnTheJudiciary'></div>
                

                  {CreateFeedItem('https://docs.house.gov/Committee/RSS.ashx?Code=JU00', 'houseCommitteeOnTheJudiciary', 'House Committee on the Judiciary', 'Legislative', 'N/A', HouseCommitteeOnTheJudiciary)}


                    </Col>

                    <Modal id='fullBillModal' show={committeeOnTheJudiciaryModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCommitteeOnTheJudiciaryModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on the Judiciary</h3></Modal.Header>

<HouseCommitteeOnTheJudiciary/>
</Modal>




                    <Col>
                    <h5 id='presidentialFeedHeadingMobile' onClick={() => setJudiciaryMaterialsModalIsOpen(true)}>Committee on the Judiciary Materials</h5>
                  <div class='courtNewsMobile'id='houseCommitteeOnTheJudiciaryMaterials'></div>
                

                  {CreateFeedAPICommittee(
    '&skip=0&committee=Committee%20on%20the%20Judiciary&chamber=HOUSE', 
    'houseCommitteeOnTheJudiciaryMaterials', 
    'House Committee on the Judiciary Materials', 
    'Legislative', 
    'N/A', 
    'Bill', 
    GenerateFeedCommittee
  )}
                    </Col>

                    <Modal id='fullBillModal' show={judiciaryMaterialsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setJudiciaryMaterialsModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3>Committee on the Judiciary Materials</h3></Modal.Header>

<GenerateFeedCommittee 
          url='&skip=0&committee=Committee%20on%20the%20Judiciary&chamber=HOUSE'
          name='Committee on the Judiciary Materials' 
          branch='Legislative' 
          topic='N/A' 
          buttonName='Bill' />
    
</Modal>



          <Col>

            </Col>


            <Col>
            <br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
 
            </div>
</Navbar>
</div>




             
              
    </>
  )
}
 }



  






export default JusticeTopicMobile;